import { assign } from 'lodash'

import { track, userEventProps } from 'lib/analytics'
import { SET_USER } from '../actions/index'

const userReducer = (state = {}, action) => {
  const user = { ...state }
  switch (action.type) {
    case SET_USER:
      assign(user, action.obj)
      return user

    default:
      return state
  }
}

export const getLoggedIn = (state) => !!(state && state.id)

export default userReducer
