import PropTypes from 'prop-types'
import Modal from 'components/modal/modal'
import { ModalTitle, ModalHeader, ModalBody } from 'react-bootstrap'
import GiftMessageForm from 'cart/components/gift-message-form'

const GiftMessageModal = ({
  show,
  onHide,
  giftMessage,
  onSubmitGiftMessage,
}) => {
  const handleSubmit = (data) => {
    onSubmitGiftMessage(data)
  }

  return (
    <Modal show={show} onHide={onHide}>
      <ModalHeader closeButton onHide={onHide} className="with-title">
        <ModalTitle className="h3" style={{ textAlign: 'center' }}>
          Add your gift message! ✏️
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <GiftMessageForm
          formKey="giftMessage"
          giftMessage={giftMessage}
          onSubmit={handleSubmit}
        />
      </ModalBody>
    </Modal>
  )
}

GiftMessageModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  giftMessage: PropTypes.object,
  onSubmitGiftMessage: PropTypes.func,
}

GiftMessageModal.defaultProps = {
  onSubmitGiftMessage: () => {},
}

export default GiftMessageModal
