import { connect } from 'react-redux'
import { openRedirectModal } from '../../../modals/actions'
import { Offer } from './offer'

export interface OtherStoreOffersProps {
  registry: any
  regItem: Record<string, unknown>
  offers: Array<Record<string, unknown>>
  openRedirectModal: any
}

const OtherStoreOffers = ({
  registry,
  regItem,
  offers,
  openRedirectModal,
}: OtherStoreOffersProps) => {
  const babylistOffer = offers && offers.find((o: any) => o.isBabylist)
  const hasAdditionalOffers = babylistOffer && offers.length > 1
  const hasOtherOffers = !babylistOffer && offers.length >= 1

  const sectionTitle = () => {
    if (babylistOffer && offers.length > 1) {
      return 'Or Buy Directly From'
    }
    return 'Buy Directly From'
  }

  return (
    <div className={hasAdditionalOffers || hasOtherOffers ? 'ptl pbl' : 'pan'}>
      {(hasAdditionalOffers || hasOtherOffers) && (
        <div className="text-bold man">{sectionTitle()}</div>
      )}
      {offers.map((offer: Record<string, any>) => {
        if (!offer.isBabylist) {
          return (
            <Offer
              key={offer.id}
              offer={offer}
              openRedirectModal={openRedirectModal}
              regItem={regItem}
              registry={registry}
            />
          )
        }

        return null
      })}
    </div>
  )
}

export default connect(null, {
  openRedirectModal,
})(OtherStoreOffers)
