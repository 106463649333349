import React from 'react'
import MailingAddress from 'registry/components/CrowdfundReserveFormFlow/components/ContributionSuccess/components/MailingAddress/MailingAddress'
import PrintableLink from 'registry/components/CrowdfundReserveFormFlow/components/ContributionSuccess/components/PrintableLink/PrintableLink'
import { GiftingDetailsProps } from 'registry/components/CrowdfundReserveFormFlow/components/ContributionSuccess/components/GiftingDetails/GiftingDetails.types'
import css from './GiftingDetails.styles.scss'

const GiftingDetails: React.FC<GiftingDetailsProps> = ({ address }) => (
  <div className={css.GiftingDetails}>
    {address && address.streetAddress1 && (
      <>
        <MailingAddress address={address} />
        <hr />
      </>
    )}
    <PrintableLink />
  </div>
)

export default GiftingDetails
