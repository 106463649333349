import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { FETCH_REGISTRY_KEY, fetchRegistry } from 'src/api/queries'
import { ExtendedRegistry } from 'src/types/registry'

const registryQueryKey = (registryId: number | undefined) => [
  FETCH_REGISTRY_KEY,
  registryId,
]

const useCurrentRegistryId = () => {
  const [currentUser] = useCurrentUser()

  return currentUser?.currentRegistry?.id
}

export const usePrefetchCurrentRegistry = () => {
  const registryId = useCurrentRegistryId()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (registryId) {
      queryClient.prefetchQuery(registryQueryKey(registryId), () =>
        fetchRegistry(registryId)
      )
    }
  }, [queryClient, registryId])
}

export const useRegistryQuery = () => {
  const registryId = useCurrentRegistryId()

  const registryQuery = useQuery<ExtendedRegistry>({
    enabled: !!registryId,
    queryKey: registryQueryKey(registryId),
    queryFn: () => fetchRegistry(registryId),
  })

  return {
    registry: registryQuery.data,
    registryQuery,
  }
}

export const useRegistry = () => {
  return useRegistryQuery().registry
}
