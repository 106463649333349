import { lazy, useEffect, useState, Suspense } from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { queryClient } from './queryClient'

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
)

export const ReactQueryDevTools = () => {
  const [showDevtools, setShowDevtools] = useState(false)

  useEffect(() => {
    const toggleReactQueryDevtools = () => setShowDevtools((prev) => !prev)
    window.toggleReactQueryDevtools = toggleReactQueryDevtools

    return () => {
      delete window.toggleReactQueryDevtools
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      {showDevtools && (
        <Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </Suspense>
      )}
    </QueryClientProvider>
  )
}

export default ReactQueryDevTools
