import { useRef, useEffect, useState } from 'react'
import { Tooltip as ReactTooltip, TooltipRefProps } from 'react-tooltip'
import classNames from 'classnames'
import IconButton from '../IconButton/IconButton'

import { TooltipProps } from './Tooltip.types'
import css from './Tooltip.styles.scss'
import Close from '../../icons/Close.svg'

const Tooltip = (props: TooltipProps) => {
  const {
    additionalInfo,
    afterHide,
    afterShow,
    className = '',
    clickable = true,
    content,
    defaultIsOpen,
    hidden = false,
    isOpen,
    linkText,
    linkOnClick,
    noArrow = false,
    openOn = 'hover',
    position = 'top',
    showCloseButton = false,
    title,
    tooltipClassName = '',
    trigger,
    triggerClassName = '',
  } = props

  const tooltipRef = useRef<TooltipRefProps>(null)
  const [toolTipTriggerID, setToolTipTriggerID] = useState('')

  useEffect(() => {
    setToolTipTriggerID(`tooltip-${Math.random().toString(36).substring(2)}`)
  }, [])

  const renderTooltipContent = () => (
    <>
      {title && <div className={css.Tooltip__Title}>{title}</div>}
      {content}
      {(linkText || additionalInfo) && (
        <div className={css.Tooltip__BottomBar}>
          {additionalInfo && (
            <span className={css.Tooltip__AdditionalInfo}>
              {additionalInfo}
            </span>
          )}
          {linkText && (
            <div className={css.Tooltip__LinkWrapper}>
              <button
                className={css.Tooltip__Link}
                type="button"
                onClick={() => {
                  if (linkOnClick) {
                    if (tooltipRef.current) {
                      linkOnClick(tooltipRef.current.close)
                    }
                  } else {
                    tooltipRef.current?.close()
                  }
                }}
              >
                {linkText}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  )

  return (
    <div className={classNames(css.Tooltip, className)}>
      <span
        aria-details={`sr-content-${toolTipTriggerID}`}
        className={triggerClassName}
        id={toolTipTriggerID}
      >
        {trigger}
      </span>
      <span className="sr-only" id={`sr-content-${toolTipTriggerID}`}>
        {renderTooltipContent()}
      </span>
      <ReactTooltip
        afterHide={afterHide}
        afterShow={afterShow}
        anchorSelect={`#${toolTipTriggerID}`}
        className={classNames(css.Tooltip__Wrapper, tooltipClassName)}
        classNameArrow={css.Tooltip__Arrow}
        clickable={clickable}
        defaultIsOpen={defaultIsOpen}
        globalCloseEvents={
          openOn === 'click'
            ? { escape: true, clickOutsideAnchor: true }
            : undefined
        }
        hidden={hidden}
        isOpen={isOpen}
        noArrow={noArrow}
        offset={16}
        opacity={1}
        openOnClick={openOn === 'click'}
        place={position}
        ref={tooltipRef}
      >
        {showCloseButton && (
          <IconButton
            aria-label="Submit"
            className={css.Tooltip__CloseButton}
            data-testid="submit-icon"
            size="sm"
            variant="standard-inverted"
            onClick={() => tooltipRef.current?.close()}
          >
            <Close />
          </IconButton>
        )}
        {renderTooltipContent()}
      </ReactTooltip>
    </div>
  )
}

export default Tooltip
