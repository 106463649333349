import CategoryBathing from 'shared/svg/CategoryBathing.svg'
import CategoryClothing from 'shared/svg/CategoryClothing.svg'
import CategoryDiapering from 'shared/svg/CategoryDiapering.svg'
import CategoryFeeding from 'shared/svg/CategoryFeeding.svg'
import CategoryNurseryDecor from 'shared/svg/CategoryNurseryDecor.svg'
import CategorySleeping from 'shared/svg/CategorySleeping.svg'
import CategoryHealthSafety from 'shared/svg/CategoryHealthSafety.svg'
import CategoryPlaying from 'shared/svg/CategoryPlaying.svg'
import CategoryOnTheGo from 'shared/svg/CategoryOnTheGo.svg'
import CategoryNew from 'shared/svg/CategoryNew.svg'

interface CategoryIconProps {
  categoryId: number
  className?: string
}

type CategoryIconComponent = React.FC<React.SVGProps<SVGSVGElement>>

export const CategoryIcon = ({ categoryId, className }: CategoryIconProps) => {
  const renderIconWithProps = (Icon: React.FC<any>) => (
    <Icon className={className} />
  )

  // Display an icon for specific category ids
  const categoryIcons: Record<string, CategoryIconComponent> = {
    '-4': CategoryClothing, // clothing
    '-5': CategoryFeeding, // feeding
    '-6': CategoryBathing, // bathing
    '-7': CategoryDiapering, // diapering
    '-8': CategoryNurseryDecor, // nursery decor
    '-9': CategoryHealthSafety, // health safety
    '-10': CategoryPlaying, // playing
    '-13': CategoryNew, // cash & gift cardsd
    '-15': CategoryOnTheGo, // on the go
    '-17': CategorySleeping, // sleeping
  }

  const IconComponent =
    categoryIcons[String(categoryId)] ?? (categoryId >= 0 ? CategoryNew : null)

  return IconComponent ? renderIconWithProps(IconComponent) : null
}
