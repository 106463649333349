import React from 'react'
import { ReservationAttributeProps } from 'registry/components/CrowdfundReserveFormFlow/components/RecordContribution/components/ReservationAttribute/ReservationAttribute.types'
import css from './ReservationAttribute.styles.scss'

const ReservationAttribute: React.FC<ReservationAttributeProps> = ({
  label,
  primaryValue,
  secondaryValue,
}) => (
  <div className={css.ReservationAttribute}>
    <p className={css.ReservationAttribute__Label}>{label}</p>
    <div className={css.ReservationAttribute__Value}>
      <p className={css.ReservationAttribute__Primary}>{primaryValue}</p>
      {secondaryValue && (
        <p className={css.ReservationAttribute__Secondary}>{secondaryValue}</p>
      )}
    </div>
  </div>
)

export default ReservationAttribute
