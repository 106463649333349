import classNames from 'classnames'
import PropTypes from 'prop-types'
import { IconButton } from 'baby-design'
import { Menu } from 'baby-design/icons'
import css from '../global-nav.scss'

const ToggleNav = ({ onClick }) => (
  <IconButton
    className={classNames(css.navToggle, css.minimizedNavOnly)}
    size="md"
    type="button"
    variant="standard-default"
    onClick={onClick}
  >
    <span className="sr-only">Toggle navigation</span>
    <Menu />
  </IconButton>
)

ToggleNav.propTypes = {
  onClick: PropTypes.func,
}

export default ToggleNav
