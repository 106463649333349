import propTypes from 'prop-types'
import classNames from 'classnames'
import { HalfRoundCard } from 'components/card'
import Link from 'components/link'
import Image from 'components/image'
import HeartRating from 'components/heart-rating'
import PriceDisplay from 'store/components/price-display'
import { StarRating } from 'baby-design'
import { QuickAddRegItem, QuickAddButton } from './quick-add-button'
import { StoreOffersButton } from './components'

import css from '../card.scss'

const PRODUCT_PREVIEW_CARD_SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
}

const PRODUCT_PREVIEW_CARD_LAYOUT = {
  vertical: 'vertical',
  horizontal: 'horizontal',
  responsive: 'responsive',
}

const LAYOUT_CSS_LOOKUP = {
  vertical: 'mediaCardVertical',
  horizontal: 'mediaCardHorizontal',
  responsive: 'mediaCardResponsive',
}

export const ProductPreviewCard = ({
  size,
  layout,
  productUrl,
  imageUrl,
  rating,
  reviewCount,
  price,
  brand,
  name,
  storeOfferIconNames,
  QuickAddButtonComponent,
  onClick,
  onProductLinkClick,
  onStoreOffersClick,
  openInNewWindow,
  __forcePageLoad,
}) => {
  const linkProps = {
    onClick: onProductLinkClick,
    [__forcePageLoad ? 'url' : 'to']: productUrl,
    newWindow: openInNewWindow,
    title: `${brand} ${name}`,
  }

  return (
    <HalfRoundCard className={css[LAYOUT_CSS_LOOKUP[layout]]} onClick={onClick}>
      <Link
        {...linkProps}
        aria-label={`${brand} ${name}`}
        className={css.media}
      >
        <Image
          alt={`${brand} ${name}`}
          className="img-responsive"
          lazyLoad={false}
          src={imageUrl}
        />
      </Link>

      <div className="flex grow col pam">
        <Link {...linkProps} className="flex-grow">
          <div
            className={classNames(
              'text-brand-info text-bold man',
              size === PRODUCT_PREVIEW_CARD_SIZES.small ? 'h7' : 'h6 mvs'
            )}
          >
            {brand}
          </div>
          <div
            className={classNames(
              'text-body man',
              size === PRODUCT_PREVIEW_CARD_SIZES.small ? 'h6' : 'h4 mvs'
            )}
          >
            {name}
          </div>
          {!!rating && (
            <StarRating
              className="flex mts mbl grow"
              rating={rating}
              reviewCount={reviewCount}
              showRating={size !== PRODUCT_PREVIEW_CARD_SIZES.small}
            />
          )}
        </Link>

        <div className="flex align-end space-between">
          <div>
            <StoreOffersButton
              storeOfferIconNames={storeOfferIconNames}
              onClick={onStoreOffersClick}
            />
            <div className="flex-grow flex col">
              <PriceDisplay
                truncateZeroCents
                msrpClassName="xsmall mln"
                price={price}
                priceClassName="xsmall"
              />
            </div>
          </div>
          {QuickAddButtonComponent && <div>{QuickAddButtonComponent}</div>}
        </div>
      </div>
    </HalfRoundCard>
  )
}

ProductPreviewCard.propTypes = {
  size: propTypes.oneOf(Object.values(PRODUCT_PREVIEW_CARD_SIZES)),
  layout: propTypes.oneOf(Object.values(PRODUCT_PREVIEW_CARD_LAYOUT)),
  productUrl: propTypes.string.isRequired,
  imageUrl: propTypes.string.isRequired,
  rating: propTypes.number,
  reviewCount: propTypes.number,
  price: propTypes.shape({
    min: propTypes.number,
    max: propTypes.number,
    current: propTypes.number,
    msrp: propTypes.number,
  }).isRequired,
  brand: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  storeOfferIconNames: propTypes.arrayOf(propTypes.string),
  QuickAddButtonComponent: propTypes.node,
  onProductLinkClick: propTypes.func,
  onStoreOffersClick: propTypes.func,
  openInNewWindow: propTypes.bool,
  /**
   * Needed for quick Add Next fix. Can't navigate to PDP using `to` because
   * we haven't hydrated redux with enough product info.
   */
  __forcePageLoad: propTypes.bool,
}

ProductPreviewCard.defaultProps = {
  onClick: () => {},
  onProductLinkClick: () => {},
  onStoreOffersClick: () => {},
  size: PRODUCT_PREVIEW_CARD_SIZES.small,
  layout: PRODUCT_PREVIEW_CARD_LAYOUT.vertical,
  storeOfferIconNames: [],
  rating: 0,
  reviewCount: 0,
  openInNewWindow: false,
  __forcePageLoad: false,
}

ProductPreviewCard.QuickAddButton = QuickAddButton
ProductPreviewCard.QuickAddRegItem = QuickAddRegItem
