import PropTypes from 'prop-types'
import classNames from 'classnames'
import Card from 'components/card'
import css from './week-by-week-guide-card.scss'

const COLOR_VARIANTS = {
  BUBBLEGUM: 'bubblegum',
  EGGPLANT: 'eggplant',
  FOREST: 'forest',
  RUBYRED: 'rubyred',
  SALMON: 'salmon',
  SKY: 'sky',
}

export const WeekByWeekGuideCard = ({ imageUrl, gradient, teaser, title }) => {
  const colorClass = css[gradient]

  return (
    <Card className={classNames(css.guideLayout, css.week, colorClass, 'mbxl')}>
      <div
        className={classNames(css.thumbnail)}
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <div className={css.innerWave} />
      </div>
      <div className={css.info}>
        <div className="h6 tiny mbs text-bold text-uppercase">
          <span>Week by Week</span>
        </div>
        <div className="h3 text-bold mtn">{title}</div>
        {teaser && <p>{teaser}</p>}
      </div>
    </Card>
  )
}

WeekByWeekGuideCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  gradient: PropTypes.oneOf(Object.values(COLOR_VARIANTS)),
  teaser: PropTypes.string,
  title: PropTypes.string.isRequired,
}
