import classNames from 'classnames'
import { FlagsProvider } from 'flagged'
import { Button, TextLink } from 'baby-design'

import { loginPathWithRedirect, newRegistryPath } from 'lib/urls'
import { useScrollDirection } from 'lib/hooks'
import { useTracking, track, withContextualizedTracking } from 'lib/analytics'
import css from './styles.scss'

const MicroUtilityBar = () => {
  const tracker = useTracking()

  const scrollDirection = useScrollDirection()

  const handleLoginClick = () => {
    tracker.trackEvent({
      event: track.navbarClicked,
      eventLocation: track.EventLocation.MOBILE_WEB_TOP_UTILITY_BAR,
      eventCta: 'Login',
    })
    window.location.href = loginPathWithRedirect(window.location.pathname)
  }

  const handleSignUpClick = () => {
    tracker.trackEvent({
      event: track.navbarClicked,
      eventLocation: track.EventLocation.MOBILE_WEB_TOP_UTILITY_BAR,
      eventCta: 'Sign Up',
    })
    window.location.href = `${newRegistryPath}?loc=${track.EventLocation.MOBILE_WEB_TOP_UTILITY_BAR}`
  }

  const rootClassName = classNames(css.root, {
    [css.pinned]: scrollDirection === 'down',
  })

  return (
    <div className={rootClassName}>
      <div className={css.left} />
      <div className={css.right}>
        <TextLink size="sm" onClick={handleLoginClick}>
          Log in
        </TextLink>
        <Button size="sm" variant="primary" onClick={handleSignUpClick}>
          Sign up
        </Button>
      </div>
    </div>
  )
}

const FeatureFlagProvidedMicroUtilityBar = (props: any) => (
  <FlagsProvider features={props.featureFlags}>
    <MicroUtilityBar {...props} />
  </FlagsProvider>
)

export default withContextualizedTracking({
  eventLocation: track.EventLocation.MOBILE_WEB_TOP_UTILITY_BAR,
})(FeatureFlagProvidedMicroUtilityBar)
