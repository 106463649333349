import { matchPath } from 'react-router-dom-v5-compat'

import {
  PLACEMENT_SCREEN_HOME,
  PLACEMENT_SCREEN_REGISTRY_HOME,
  PLACEMENT_SCREEN_REGISTRY_EDIT,
  PLACEMENT_SCREEN_REGISTRY_GIFT_GIVER_VIEW,
  PLACEMENT_SCREEN_SHOP_HOME,
  PLACEMENT_SCREEN_SHOP_BRAND,
  PLACEMENT_SCREEN_SHOP_CATEGORY,
  PLACEMENT_SCREEN_SHOP_PDP,
  PLACEMENT_SCREEN_GUIDES,
} from 'shared/constants'

import { REGISTRY_SUBDOMAIN_PREFIX } from 'lib/urls'
/*
  The purpose of this function is to determine the screen on which the placement
  slot is being rendered.
  The same placement slot may be rendered on multiple screens
  eg: PlacementGlobalBanner is rendered on Shop, Registry etc.
      See: packs/placements/app/controllers/concerns/placements/global_banner_concern.rb
  An ad may be configured to be shown on some or all screens.
  The PlacementComponent will match the current screen with the screens configured for the ad.
*/
export const getPlacementScreen = (pathname: string, host: string) => {
  if (host.startsWith(REGISTRY_SUBDOMAIN_PREFIX)) {
    return getRegistryGiftGiverViewScreen(pathname)
  }
  return getScreenByPath(pathname)
}

const getRegistryGiftGiverViewScreen = (pathname: string) => {
  // Maps to registry#show
  if (matchPath('/:url_slug', pathname)) {
    return PLACEMENT_SCREEN_REGISTRY_GIFT_GIVER_VIEW
  }
  return ''
}

const getScreenByPath = (pathname: string) => {
  // Maps to pages#home and pages#landing
  if (matchPath('/', pathname) || matchPath('/index', pathname)) {
    return PLACEMENT_SCREEN_HOME
  }
  // Maps to registry#home
  if (matchPath('/registry/home', pathname)) {
    return PLACEMENT_SCREEN_REGISTRY_HOME
  }
  // Maps to registry#show
  if (matchPath('/list/:url_slug', pathname)) {
    return PLACEMENT_SCREEN_REGISTRY_EDIT
  }
  // Maps to store#index
  if (matchPath('/store', pathname)) {
    return PLACEMENT_SCREEN_SHOP_HOME
  }
  // Maps to store#brand
  if (matchPath('/store/b/:brand', pathname)) {
    return PLACEMENT_SCREEN_SHOP_BRAND
  }
  // Maps to store#category
  if (matchPath('/store/:category', pathname)) {
    return PLACEMENT_SCREEN_SHOP_CATEGORY
  }
  // Maps to store#product
  if (matchPath('/gp/:slug/*', pathname)) {
    return PLACEMENT_SCREEN_SHOP_PDP
  }
  // Maps to guides#index
  if (matchPath('/hello-baby/*', pathname)) {
    return PLACEMENT_SCREEN_GUIDES
  }
  return ''
}
