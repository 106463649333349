import { track } from 'lib/analytics'
import removeQueryParam from '../utils/removeQueryParam/removeQueryParam'

const getSearchResultsPageEventType = (fallbackEventType: string) => {
  let eventType = fallbackEventType

  if (typeof window !== undefined) {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('unified_search')) {
      eventType = track.EventType.UNIFIED
      removeQueryParam('unified_search')
    }
  }

  return eventType
}

export default getSearchResultsPageEventType
