import React from 'react'
import css from './BannerStrip.styles.scss'

import { BannerStripProps } from './BannerStrip.types'

// TODO:
// - Dismiss button(?)

const BannerStrip: React.FC<BannerStripProps> = ({ children }) => (
  <div className={css.BannerStrip} data-testid="BannerStrip">
    {children}
  </div>
)

export default BannerStrip
