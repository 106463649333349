import React, { useState } from 'react'
import { AccountAttributeFieldProps } from 'registry/components/CrowdfundReserveFormFlow/components/ContributionDetails/components/AccountAttributeField/AccountAttributeField.types'
import Copy from '../../../../../../../baby-design/icons/Copy.svg'
import css from './AccountAttributeField.styles.scss'
import { IconButton } from '../../../../../../../baby-design'

const AccountAttributeField: React.FC<AccountAttributeFieldProps> = ({
  canCopy = false,
  label,
  prefix,
  value,
}) => {
  const [copied, setCopied] = useState(false)

  const handleCopy = (text?: string | null) => {
    if (canCopy && text) {
      navigator.clipboard.writeText(text).then(() => {
        setCopied(true)
        setTimeout(() => setCopied(false), 5000)
      })
    }
  }

  return (
    <div className={css.AccountAttributeDisplay}>
      <div className={css.AccountAttributeDisplay__Content}>
        <p className={css.AccountAttributeDisplay__Label}>{label}</p>
        <p className={css.AccountAttributeDisplay__Value}>
          {prefix ? `${prefix}${value}` : value}
        </p>
      </div>
      {canCopy && (
        <>
          {copied ? (
            <span className={css.AccountAttributeDisplay__CopiedLabel}>
              Copied!
            </span>
          ) : (
            <span data-testid="copy-icon">
              <IconButton
                className={css.AccountAttributeDisplay__Copy}
                variant="standard-inverted"
                size="sm"
                onClick={() => handleCopy(value)}
              >
                <Copy />
              </IconButton>
            </span>
          )}
        </>
      )}
    </div>
  )
}

export default AccountAttributeField
