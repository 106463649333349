import { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'components/modal/modal'
import { Col, ModalBody, ModalHeader, ModalTitle, Row } from 'react-bootstrap'
import { InfoButton } from 'components/buttons'
import {
  ChecklistIcon,
  ShippingIcon,
  ArrowRightIcon,
  OtherStoresIcon,
  ReturnToBabylistIcon,
} from 'components/icons'
import { getRegistry } from 'cart/reducers'
import {
  getIsShippingAddressPrivate,
  getShippingAddress,
} from 'registry/reducers'
import { RegistryShape } from 'cart/lib/prop-types'
import Link from 'components/link'
import Spinner from 'shared/spinner'
import classnames from 'classnames'
import FTCDisclosureTag from 'components/FTCDisclosureTag'
import GoogleRecaptchaDisclosureTag from 'components/google-recaptcha-disclosure-tag'
import { setIsShippingAddressButtonVisible as importedSetIsShippingAddressButtonVisible } from 'registry/actions'

import AddressDisplay from './address-display'

import {
  NoAddressProvidedDisclosure,
  useContactInfoAddress,
} from '../ContactInfoModal'
import css from './redirect-modal.scss'

export const BaseRedirectModal = ({
  isShippingAddressPrivate,
  isShopRegistryView,
  onHide,
  onRedirectSuccess,
  recipientFirstName,
  registry,
  setIsShippingAddressButtonVisible,
  storeDisplayName,
  url,
}) => {
  const [showAddress, setShowAddress] = useState(false)

  const hasPublicAddress = !isShippingAddressPrivate && !!registry.addressId
  const shouldLoadAddress = hasPublicAddress && showAddress

  const { data: addressData, isFetching: isAddressFetching } =
    useContactInfoAddress({
      addressId: registry.addressId,
      enabled: shouldLoadAddress,
      reCaptchaAction: 'redirect_modal',
    })

  function handleRedirect() {
    window.open(url)
    onHide()
    onRedirectSuccess()
    setIsShippingAddressButtonVisible(true)
  }

  const continueToStoreButton = (
    <InfoButton pill className={css.continueButton} onClick={handleRedirect}>
      Continue to {storeDisplayName}
    </InfoButton>
  )

  if (isShopRegistryView) {
    return (
      <Modal show size="small">
        <ModalHeader
          closeButton
          className={classnames(css.headerContent, 'with-title')}
          onHide={onHide}
        >
          <ModalTitle className="h3" style={{ textAlign: 'center' }}>
            You're headed to {storeDisplayName} 🚀
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="flex col center">
            <div className="flex" style={{ justifyContent: 'center' }}>
              <ReturnToBabylistIcon className={css.icon} />
              <ArrowRightIcon />
              <OtherStoresIcon className={css.icon} />
            </div>
            <div className="mbm">
              <p className={classnames(css.instructionText, 'text-center h5')}>
                <strong>If you buy at {storeDisplayName},</strong> return to
                Babylist and click `Purchased` to update your registry
              </p>
            </div>
            <div className="mvxl">{continueToStoreButton}</div>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  return (
    <Modal show size="small">
      <ModalHeader closeButton className="with-title" onHide={onHide}>
        <ModalTitle className="h3" style={{ textAlign: 'center' }}>
          You're headed to {storeDisplayName}&#8230;
        </ModalTitle>
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col sm={6} xs={12}>
            <div className="flex col align-center mbxl">
              <ChecklistIcon className="mbl" width={77} />

              <p className="text-center mhl">
                After purchase, return to Babylist and click{' '}
                <strong>I've Purchased This</strong>
              </p>
            </div>
          </Col>

          <Col sm={6} xs={12}>
            <div className="flex col align-center mbl">
              <ShippingIcon className="mbl" width={77} />

              {isAddressFetching ? (
                <Spinner />
              ) : (
                <AddressDisplay
                  addressData={addressData}
                  hasPublicAddress={hasPublicAddress}
                  loadAddressComponent={
                    <p className="text-center mhl">
                      <strong>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link onClick={() => setShowAddress(true)}>
                          Need {recipientFirstName || registry.ownerFirstName}'s
                          shipping address?
                        </Link>
                      </strong>
                    </p>
                  }
                  noAddressComponent={
                    <NoAddressProvidedDisclosure
                      name={recipientFirstName || registry.ownerFirstName}
                    />
                  }
                  showAddress={showAddress}
                />
              )}
            </div>
          </Col>
        </Row>

        <div className="flex center col mtl">
          {continueToStoreButton}

          <FTCDisclosureTag
            className="mtl"
            disclaimerContext={FTCDisclosureTag.Constants.MODAL}
          />
          <GoogleRecaptchaDisclosureTag className={css.grecaptchaLegalNotice} />
        </div>
      </ModalBody>
    </Modal>
  )
}

BaseRedirectModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onRedirectSuccess: PropTypes.func,
  recipientFirstName: PropTypes.string,
  registry: PropTypes.shape(RegistryShape),
  isShopRegistryView: PropTypes.bool,
  storeDisplayName: PropTypes.string,
  url: PropTypes.string,
  setIsShippingAddressButtonVisible: PropTypes.func,
  isShippingAddressPrivate: PropTypes.bool,
}

BaseRedirectModal.defaultProps = {
  onRedirectSuccess: () => {},
  recipientFirstName: undefined,
  registry: null,
  isShopRegistryView: false,
  storeDisplayName: 'the store',
  url: undefined,
  setIsShippingAddressButtonVisible: () => {},
  isShippingAddressPrivate: false,
}

const mapStateToProps = (state) => ({
  registry: getRegistry(state),
  recipientFirstName: state.registry?.attributeData?.firstName,
  shippingAddress: getShippingAddress(state),
  isShippingAddressPrivate: !!getIsShippingAddressPrivate(state),
})

const mapDispatchToProps = (dispatch) => ({
  setIsShippingAddressButtonVisible: (props) =>
    dispatch(importedSetIsShippingAddressButtonVisible(props)),
})

export const RedirectModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseRedirectModal)
