import React, { useState } from 'react'
import { Select } from 'components/forms'
import NewCategoryForm from './new-category-form'

const NEW_CATEGORY = 'new-category'

export interface CategorySelectorProps {
  categories: Array<{ id: number; title: string; position: number }>
  groupClassName?: string
  onCategoryAdd?: any
  name: string
  label?: string
  value?: string
  onCategoryChange: any
}

const CategorySelector = ({
  categories,
  groupClassName,
  onCategoryAdd,
  name,
  label = 'Category',
  value,
  onCategoryChange,
}: CategorySelectorProps) => {
  const [selectValue, setSelectValue] = React.useState(value)
  const [showNewCategoryForm, setShowNewCategoryForm] = useState(false)

  React.useEffect(() => {
    setSelectValue(value)
  }, [value])

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const shouldShowNewCategoryForm = e.target.value === NEW_CATEGORY
    onCategoryChange({
      [name]: shouldShowNewCategoryForm ? null : e.target.value,
    })
    setSelectValue(e.target.value)
    setShowNewCategoryForm(shouldShowNewCategoryForm)
  }

  return (
    <>
      <Select
        label={label}
        name={name}
        value={selectValue}
        wrapperClassName={groupClassName}
        onChange={handleChange}
      >
        {onCategoryAdd ? (
          <option value={NEW_CATEGORY}>+ New Category</option>
        ) : null}

        {categories
          .sort((a, b) => a.position - b.position)
          .map((category) => (
            <option key={category.id} value={category.id}>
              {category.title}
            </option>
          ))}
      </Select>

      {showNewCategoryForm && onCategoryAdd ? (
        <NewCategoryForm onChange={onCategoryAdd} />
      ) : null}
    </>
  )
}
export default CategorySelector
