import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import Modal from 'components/modal/modal'
import { ModalBody } from 'react-bootstrap'
import Quantity from 'components/quantity'
import { InfoButton } from 'components/buttons'
import { getUserFullName, getRegistryId } from 'registry/reducers'
import { createReservationAsOwner } from 'registry/actions'

export const OwnerMarkAsPurchasedModal = ({
  createReservationAsOwner,
  defaultQuantity,
  name,
  onHide,
  show,
  regItemId,
  registryId,
  onSuccess,
}) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      quantity: defaultQuantity,
    },
  })
  const [quantity, setQuantity] = useState(defaultQuantity)
  const [submitting, setSubmitting] = useState(false)
  const handleSave = (data) => {
    setSubmitting(true)
    const reservation = {
      regItemId,
      registryId,
      name,
      quantity: data.quantity,
      isPurchased: true,
    }
    return createReservationAsOwner(reservation).then(() => {
      setSubmitting(false)
      onSuccess()
      onHide()
    })
  }

  return (
    <Modal title="How many did you purchase?" onHide={onHide} show={show}>
      <ModalBody>
        <form className="text-center" onSubmit={handleSubmit(handleSave)}>
          <Quantity
            name="quantity"
            inputRef={register}
            label={false}
            onChange={(value) => setQuantity(value)}
            value={quantity}
            max={10}
          />
          <InfoButton
            type="submit"
            className="mtl"
            pill
            block
            submitting={submitting}
          >
            Mark {quantity} Purchased
          </InfoButton>
        </form>
      </ModalBody>
    </Modal>
  )
}

OwnerMarkAsPurchasedModal.propTypes = {
  createReservationAsOwner: PropTypes.func.isRequired,
  defaultQuantity: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  regItemId: PropTypes.number.isRequired,
  registryId: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
}

OwnerMarkAsPurchasedModal.defaultProps = {
  onSuccess: () => {},
}

const mapStateToProps = (state) => ({
  name: getUserFullName(state),
  registryId: getRegistryId(state),
})

export default connect(mapStateToProps, {
  createReservationAsOwner,
})(OwnerMarkAsPurchasedModal)
