import PropTypes from 'prop-types'
import { BundleComponentShape } from 'cart/lib/prop-types'

export const RegItemCategoryShape = {
  description: PropTypes.string,
  id: PropTypes.number.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  position: PropTypes.number.isRequired,
  regItems: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export const StoreShape = {
  displayName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export const FiltersShape = {
  favoritesView: PropTypes.bool,
  groupGiftView: PropTypes.bool,
  priceRange: PropTypes.arrayOf(
    PropTypes.shape({
      max: PropTypes.number,
      min: PropTypes.number,
    })
  ),
  storeName: PropTypes.arrayOf(PropTypes.string),
}

export const RegItemShape = {
  description: PropTypes.any,
  id: PropTypes.number.isRequired,
  imgUrl: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isBonusGift: PropTypes.bool.isRequired,
  isCashGift: PropTypes.bool.isRequired,
  isCrowdfund: PropTypes.bool.isRequired,
  isDigitalItem: PropTypes.bool.isRequired,
  isElectronicGiftCard: PropTypes.bool.isRequired,
  isFavor: PropTypes.bool.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  isGiftCard: PropTypes.bool.isRequired,
  isGroupGift: PropTypes.bool.isRequired,
  isPurchasable: PropTypes.bool.isRequired,
  isPurchasableOnBabylist: PropTypes.bool.isRequired,
  isTaxable: PropTypes.bool.isRequired,
  offers: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  purchaseQuantityLimit: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  quantityNeeded: PropTypes.number.isRequired,
  registry: PropTypes.string.isRequired,
  registryId: PropTypes.number.isRequired,
  showPurchaseQuantityLimit: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

export const ReservationShape = {
  alert: PropTypes.any,
  bundleComponents: PropTypes.arrayOf(PropTypes.shape(BundleComponentShape)),
  canCancelOrder: PropTypes.bool,
  createdAt: PropTypes.string,
  daysSincePurchase: PropTypes.any,
  displayTitle: PropTypes.string,
  email: PropTypes.string,
  giftAmount: PropTypes.any,
  giftCardPrintableUrl: PropTypes.any,
  giftMessage: PropTypes.any,
  id: PropTypes.number,
  isActive: PropTypes.bool,
  isEligibleForRegistryDiscount: PropTypes.bool,
  isGroupGiftContribution: PropTypes.bool,
  isPurchasableOnBabylist: PropTypes.bool,
  isPurchaseVerified: PropTypes.bool,
  isPurchased: PropTypes.bool,
  name: PropTypes.string,
  orderCancelUntil: PropTypes.any,
  orderNumber: PropTypes.any,
  orderUuid: PropTypes.any,
  prevStore: PropTypes.any,
  printableUrl: PropTypes.any,
  productId: PropTypes.number,
  purchasedAt: PropTypes.any,
  quantity: PropTypes.number,
  regItem: PropTypes.shape(RegItemShape),
  regItemId: PropTypes.number,
  registryId: PropTypes.number,
  returnWebUrl: PropTypes.any,
  returnlyDeepLink: PropTypes.any,
  store: PropTypes.string,
  storeName: PropTypes.any,
  token: PropTypes.string,
  trackingInfos: PropTypes.arrayOf(PropTypes.any),
  unpurchasable: PropTypes.bool,
  url: PropTypes.string,
}

export const UserRegistryShape = {
  babies: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      gender: PropTypes.string,
      birthdate: PropTypes.string,
      relationship: PropTypes.string,
      giftGiverNickname: PropTypes.string,
    })
  ).isRequired,
}
