import { Button } from 'baby-design'
import Form, { FormSubmitData } from './Form'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useContext, useEffect, useState } from 'react'
import { convertToRegistryItems, submitCheckout } from '../../api'
import { setGiftGiverCookie } from 'lib/cookies'
import { buildCartItems, getIsFsaHsaOrder } from '../../lib'
import EZAddToCartAlertsContext from 'src/contexts/EZAddToCartAlertsContext'
import ScrollLockModal from 'shared/ScrollLockModal'
import { track, useTracking } from 'lib/analytics'
import { useWindowSize } from 'usehooks-ts'
import { BREAKPOINT_TABLET } from 'src/constants'

const formId = 'ez-add-gift-giver-info-form'

interface TrackCheckoutStartedProps {
  // leaving this type pretty generic for now until we do deeper cart work
  cartItems: Array<Record<string, any>>
  tracker: any
}

const trackCheckoutStarted = ({
  cartItems,
  tracker,
}: TrackCheckoutStartedProps) => {
  const isFsaHsaOrder = getIsFsaHsaOrder(cartItems)
  tracker.trackEvent({
    event: track.checkoutStarted,
    eventLocation: track.EventLocation.GIFT_GIVER_FORM,
    cartItems: cartItems,
    regDiscountApplied: false,
    isFsaHsaOrder,
  })
}

export interface EZAddToCartGiftGiverInfoModalProps {
  reservationRegistryIds?: number[]
}

const EZAddToCartGiftGiverInfoModal =
  NiceModal.create<EZAddToCartGiftGiverInfoModalProps>(
    ({ reservationRegistryIds = [] }) => {
      const modal = useModal()
      const { addAlert, clearAlerts } = useContext(EZAddToCartAlertsContext)
      const [submiting, setSubmitting] = useState(false)
      const windowSize = useWindowSize()
      const isMobile = windowSize.width < BREAKPOINT_TABLET
      const tracker = useTracking()

      useEffect(() => {
        if (modal.visible) {
          tracker.trackEvent({
            event: track.giftGiverFormViewed,
          })
        }
      }, [modal.visible])

      const onSubmit = (formProps: FormSubmitData) => {
        setSubmitting(true)
        const { name, email, subscribeToMarketingEmail } = formProps
        setGiftGiverCookie({ name, email, subscribeToMarketingEmail })
        clearAlerts()
        convertToRegistryItems({ ...formProps, reservationRegistryIds })
          .then((updatedCart) => {
            const cartItems = buildCartItems(
              updatedCart.amazonCart,
              updatedCart.cart,
              updatedCart.reservations,
              reservationRegistryIds,
              false
            )
            trackCheckoutStarted({ cartItems, tracker })
            submitCheckout(cartItems)
              .then((resp) => {
                modal.resolve(resp)
                modal.hide()
              })
              .catch((response) => {
                modal.reject({ alertsSet: false, response })
                modal.hide()
              })
          })
          .catch((response) => {
            let alertsSet = false
            if (response?.error?.fields?.cart) {
              Object.values(response.error.fields.cart).forEach(
                (value: unknown) => {
                  const message = value as string
                  addAlert({ message })
                  alertsSet = true
                }
              )
            }
            if (response?.error?.fields?.storeItems) {
              response.error.fields.storeItems.forEach(
                (storeItemErrors: Record<string, string>) =>
                  Object.values(storeItemErrors).forEach((message: string) => {
                    addAlert({ message })
                    alertsSet = true
                  })
              )
            }
            modal.reject({ alertsSet, response })
            modal.hide()
          })
          .finally(() => setSubmitting(false))
      }

      const submitButton = (
        <Button
          size={isMobile ? 'md' : 'lg'}
          variant="primary"
          type="submit"
          form={formId}
          loading={submiting}
        >
          Continue to checkout
        </Button>
      )

      return (
        <ScrollLockModal
          showCloseButton
          handleClose={modal.hide}
          isOpen={modal.visible}
          mobileVariant="basic"
          primaryButton={submitButton}
          title="Who is this from?"
        >
          <Form id={formId} onSubmit={onSubmit} />
        </ScrollLockModal>
      )
    }
  )

export default EZAddToCartGiftGiverInfoModal
