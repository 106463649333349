import PropTypes from 'prop-types'
import HtmlContent from 'shared/html-content'
import Image from 'components/image'

const RegItemNote = ({ className, description }, { registry }) => {
  const { photoUrl } = registry
  const formattedNote = description
    ? `${description.split('\n').join('<br/>')}`
    : null

  return (
    <>
      <div className={className}>
        <div className="flex">
          {photoUrl && (
            <Image
              className="circle-mask mrm"
              src={photoUrl}
              width={40}
              height={40}
              alt="Registry Profile Pic"
            />
          )}
          <div className="well pam">
            <HtmlContent html={formattedNote} />
          </div>
        </div>
      </div>
    </>
  )
}

RegItemNote.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
}

RegItemNote.defaultProps = {
  className: undefined,
  description: '',
}

RegItemNote.contextTypes = {
  registry: PropTypes.object,
}

export default RegItemNote
