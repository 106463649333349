import { Node } from '@contentful/rich-text-types'
import EmbedCollapsingSection from './EmbedCollapsingSection'
import WhyTrustUsSection from './WhyTrustUsSection'
import SocialMedia from './SocialMedia'
import Image from './Image'
import Video from './Video'
import EmbedPrerendered from './EmbedPrerendered'
import MethodologySection from './MethodologySection'
import FaqsAndTipsSection from './FaqsAndTipsSection'
import TableSection from './TableSection'
import ImageGallery from './ImageGallery'
import ProductCardSection from './ProductCardSection'
import ProductComparison from './ProductComparison'
import RealParentReviews from './RealParentReviews'
import WinningProductRecommendations from './WinningProductRecommendations'
import EmbedInsuranceEligibilityValidatorTool from './EmbedInsuranceEligibilityValidatorTool'
import CriteriaSection from './CriteriaSection'

// Mapping from contentful contentTypes to components
const EMBEDDED_ENTRY_COMPONENTS: Record<
  string,
  React.FC<EmbeddedComponentProps>
> = {
  video: Video,
  postEmbedSocialMedia: SocialMedia,
  postEmbedImage: Image,
  postEmbedCollapsingSection: EmbedCollapsingSection,
  postEmbedMethodologySection: MethodologySection,
  postEmbedWhyTrustUsSection: WhyTrustUsSection,
  postEmbedFaqs: FaqsAndTipsSection,
  postEmbedTable: TableSection,
  postEmbedImageGallery: ImageGallery,
  postEmbedProductCard: ProductCardSection,
  postEmbedProductComparison: ProductComparison,
  postEmbedParentReviews: RealParentReviews,
  postEmbedWinningProductRecommendations: WinningProductRecommendations,
  postEmbedInsuranceEligibilityValidatorTool:
    EmbedInsuranceEligibilityValidatorTool,
  postEmbedCriteriaSection: CriteriaSection,
}

export type AllowedEmbeds = keyof typeof EMBEDDED_ENTRY_COMPONENTS

interface EmbeddedComponentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: any
  id?: string
  allowedEmbeds?: AllowedEmbeds[] | null
}

export interface EmbeddedEntryProps {
  node: Node
  serverData?: ServerData
  allowedEmbeds?: string[] | null
}

const EmbeddedEntry: React.FC<EmbeddedEntryProps> = ({
  node,
  serverData,
  allowedEmbeds,
}: EmbeddedEntryProps) => {
  const entryId = node.data.target?.sys?.id
  if (!entryId) return null

  try {
    const contentType = node.data.target.sys?.contentType?.sys?.id as
      | string
      | undefined

    if (contentType && allowedEmbeds && !allowedEmbeds.includes(contentType)) {
      return null
    }

    if (contentType && !!EMBEDDED_ENTRY_COMPONENTS[contentType]) {
      const EmbeddedEntryComponent = EMBEDDED_ENTRY_COMPONENTS[contentType]
      return (
        <EmbeddedEntryComponent
          fields={node.data.target.fields}
          id={entryId}
          key={`embed-${contentType}-${entryId}`}
        />
      )
    }

    return (
      <EmbedPrerendered
        id={entryId}
        key={`embed-prerendered-${entryId}`}
        serverData={serverData}
      />
    )
  } catch (e) {
    console.error(`Caught exception rendering EmbeddedEntry`, node)
    console.log(e)
    return null
  }
}

export default EmbeddedEntry
