import React from 'react'

interface RightChevronProps {
  className?: string
}

const RightChevron: React.FC<RightChevronProps> = ({
  className,
}: RightChevronProps) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M8.46967 18.5303C8.17678 18.2374 8.17678 17.7626 8.46967 17.4697L13.3383 12.601C13.5451 12.3943 13.6678 12.2707 13.7517 12.1719C13.8028 12.1116 13.8203 12.0822 13.8246 12.074C13.8395 12.0258 13.8395 11.9742 13.8246 11.926C13.8203 11.9178 13.8028 11.8884 13.7517 11.8281C13.6678 11.7293 13.5451 11.6057 13.3383 11.399L8.46967 6.53033C8.17678 6.23744 8.17678 5.76256 8.46967 5.46967C8.76256 5.17678 9.23744 5.17678 9.53033 5.46967L14.4175 10.3568C14.5996 10.5389 14.7668 10.7061 14.8952 10.8573C15.0336 11.0203 15.1703 11.2134 15.2501 11.4592C15.3643 11.8107 15.3643 12.1893 15.2501 12.5408C15.1703 12.7866 15.0336 12.9797 14.8952 13.1427C14.7668 13.2939 14.5996 13.4611 14.4175 13.6432L9.53033 18.5303C9.23744 18.8232 8.76256 18.8232 8.46967 18.5303Z"
      fill="#252324"
      fillRule="evenodd"
    />
  </svg>
)

export default RightChevron
