import { connect } from 'react-redux'
import { getReservationsByCurrentVisitor } from 'registry/reducers'
import { getIsGiftGiverThankYouAddressProvided } from '../../reducers/reservations-reducer'
import { getRegistry } from '../../reducers'
import {
  AddToCartModal as BaseAddToCartModal,
  AddToCartModalProps,
} from './add-to-cart-modal'
import { Registry } from 'src/types/registry'

interface StateProps {
  registry: Registry | undefined
}

interface StateValues {
  registry: Registry | undefined
  regItems: any
}

const mapStateToProps = (state: StateValues) => {
  const registry = getRegistry(state)

  let reservations: any[] = []
  if (state?.regItems?.reservationsByCurrentVisitor) {
    reservations = Object.values(getReservationsByCurrentVisitor(state))
  }

  return {
    registry,
    isGiftGiverThankYouAddressProvided:
      getIsGiftGiverThankYouAddressProvided(reservations),
  }
}

export default connect<StateProps, null, AddToCartModalProps, StateValues>(
  mapStateToProps
)(BaseAddToCartModal)
