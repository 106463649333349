import { Component } from 'react'

import DateSelect from 'components/date-select'
import { daysUntil, parseLocalISODate } from 'lib/date'
import { dateString as isInvalidDateString } from 'lib/form-validation'

class DueDateSelect extends Component {
  static propTypes = DateSelect.propTypes

  state = {
    value: this.props.value || null,
  }

  render() {
    const { id, ...dateSelectProps } = this.props
    return (
      <div id={id} className={this.props.className}>
        <DateSelect
          {...dateSelectProps}
          value={this.state.value}
          onChange={this.handleDateChange.bind(this)}
          help={this.dueText()}
        />
      </div>
    )
  }

  getDueDate() {
    if (!this.state.value || isInvalidDateString(this.state.value)) {
      return null
    }
    return parseLocalISODate(this.state.value)
  }

  dueText() {
    let dueDate = this.getDueDate()
    if (!dueDate) {
      return ''
    }
    let days = daysUntil(dueDate)
    if (days > 40 * 7) {
      return "You're trying to get pregnant."
    } else if (days > 3 * 30) {
      return `You're due in ${Math.round(days / 30)} months... yay!`
    } else if (days > 1) {
      return `You're due in ${days} days... yay!`
    } else if (days == 1) {
      return `You're due tomorrow... yay!`
    } else if (days == 0) {
      return `You're due today... yay!`
    } else if (days < -365) {
      let years = Math.floor(Math.abs(days) / 365)
      return `You have a${
        years == 8 || years == 18 ? 'n' : ''
      } ${years} year old.`
    } else if (days < -30) {
      let months = Math.floor(Math.abs(days) / 30)
      return `You have a${months == 8 ? 'n' : ''} ${months} month old.`
    } else if (days < -7) {
      return `You have a ${Math.floor(Math.abs(days) / 7)} week old.`
    } else {
      return `You have a ${Math.abs(days)} day old.`
    }
  }

  handleDateChange(newDate) {
    this.setState({ value: newDate })
    if (this.props.onChange) {
      this.props.onChange(newDate)
    }
  }
}

export default DueDateSelect
