import { settingsShippingAddressPath } from 'lib/urls'
import Link from 'components/link'
import css from './banner.scss'

const InputAddressBanner = () => {
  return (
    <div className={css.infoBanner}>
      <div className="container">
        <div className={css.flexRow}>
          <div className={css.middle}>
            <p className="h5 text-bold mvn pan">
              Heads up! Babylist only ships to US Addresses
            </p>
            <p className="pvn mvn">
              Please enter your registry shipping address{' '}
              <Link
                className="text-inverse text-underline"
                url={settingsShippingAddressPath}
              >
                here
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InputAddressBanner
