import { Component } from 'react'
import PropTypes from 'prop-types'
import { shortSize } from 'store/lib'
import {
  difference,
  get,
  intersection,
  isEmpty,
  isNull,
  keys,
  map,
  mapKeys,
} from 'lodash'
import { Select } from 'components/forms'
import OptionSelector from 'components/option-selector'
import ThumbnailSelector from 'components/thumbnail-selector'

const normalizeAttribute = (attr) =>
  attr.toString().toLowerCase().replace(/\W/g, '')

class SelectionControls extends Component {
  static propTypes = {
    allAttributes: PropTypes.object.isRequired,
    attributeLabelOverrides: PropTypes.object,
    selectedAttributes: PropTypes.object.isRequired,
    productAttributeMap: PropTypes.object.isRequired,
    onProductChange: PropTypes.func.isRequired,
    productPath: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div className="mtm">
        {map(this.props.allAttributes, (options, name) => {
          switch (name) {
            case 'style':
            case 'color':
              return this.thumbnailSelector(
                name,
                options,
                this.props.selectedAttributes[name]
              )
            case 'amount':
              if (options[0] === 0) {
                options.push(options.shift())
              } // Reorder so 0 is last
              return this.radioSelector(
                name,
                options,
                this.props.selectedAttributes[name]
              )
            case 'size':
            case 'count':
              return this.radioSelector(
                name,
                options,
                this.props.selectedAttributes[name]
              )
            default:
              return this.defaultSelector(
                name,
                options,
                this.props.selectedAttributes[name]
              )
          }
        })}
      </div>
    )
  }

  defaultSelector(name, attributes, selectedAttribute) {
    return (
      <div key={name} className="mbm">
        {this.renderLabel(name, selectedAttribute)}
        {attributes.length > 1 ? (
          <Select
            wrapperClassName="inline-block mbn"
            variant="muted"
            value={selectedAttribute}
            onChange={(e) => this.handleAttributeChange(name, e.target.value)}
          >
            {map(attributes, (attr) => (
              <option
                key={attr}
                value={attr}
                selected={name == selectedAttribute}
                disabled={!this.optionEnabled({ [name]: attr })}
              >
                {attr}
              </option>
            ))}
          </Select>
        ) : null}
      </div>
    )
  }

  radioSelector(name, attributes, selectedAttribute) {
    let options = map(attributes, (attr) => ({
      value: attr,
      label: name == 'size' ? shortSize(attr) : attr,
      disabled: !this.optionEnabled({ [name]: attr }),
    }))
    return (
      <div key={name} className="mbm">
        {this.renderLabel(name, selectedAttribute)}
        {options.length > 1 ? (
          <OptionSelector
            value={selectedAttribute}
            options={options}
            name={name}
            hrefFor={this.productPath.bind(this)}
            labelDecorator={(value) =>
              this.decorateAttributeByName(value, name)
            }
            onClick={this.handleAttributeChange.bind(this, name)}
            titleFor={this.productSEOtitle.bind(this)}
          />
        ) : null}
      </div>
    )
  }

  thumbnailSelector(name, attributes, selectedAttribute) {
    let thumbnails = map(attributes, (attr) => {
      return {
        value: attr.value,
        url: attr.thumbnailUrl,
        disabled: !this.optionEnabled({ [name]: attr.value }),
      }
    })
    return (
      <div key={name} className="clearfix mbm">
        {this.renderLabel(name, selectedAttribute)}
        {thumbnails.length > 1 ? (
          <ThumbnailSelector
            name={name}
            thumbnails={thumbnails}
            onClick={this.handleAttributeChange.bind(this, name)}
            hrefFor={this.productPath.bind(this)}
            titleFor={this.productSEOtitle.bind(this)}
            value={selectedAttribute}
          />
        ) : null}
      </div>
    )
  }

  renderLabel(name, value) {
    const { attributeLabelOverrides } = this.props

    return (
      <h2 className="man pan mbs">
        <span className="h7 text-bold text-capitalize">
          {get(attributeLabelOverrides, name) || name}:{' '}
        </span>
        <span className="h6">{this.decorateAttributeByName(value, name)}</span>
      </h2>
    )
  }

  optionEnabled(option) {
    // It's disabled if we can't resolve to a specific id
    let selectedAttributes = { ...this.props.selectedAttributes, ...option }
    return !isNull(this.productIdForSelectedAttributes(selectedAttributes))
  }

  productIdForSelectedAttributes(selectedAttributes) {
    let potentialProducts = map(selectedAttributes, (value, name) => {
      let normalizedVariantMap = mapKeys(
        this.props.productAttributeMap[name],
        (value, key) => normalizeAttribute(key)
      )
      return normalizedVariantMap[normalizeAttribute(value)]
    })

    return intersection(...potentialProducts)[0] || null
  }

  handleAttributeChange(attr, value) {
    this.props.onProductChange(this.mappedProductId(attr, value))
  }

  productPath(attr, value) {
    return this.props.productPath(this.mappedProductId(attr, value))
  }

  productSEOtitle(attr, value) {
    const productId = this.mappedProductId(attr, value)
    return this.props.products[productId]?.seoTitle || ''
  }

  mappedProductId(attr, value) {
    let selectedAttributes = { ...this.props.selectedAttributes, [attr]: value }
    let mappedProductId =
      this.productIdForSelectedAttributes(selectedAttributes)

    // If no mappedProductId for selected attrs
    // Reset selectors, moving backwards, until we find a valid combo
    while (isNull(mappedProductId) && !isEmpty(selectedAttributes)) {
      let attrDiff = difference(keys(selectedAttributes), [attr])
      // there is no difference between the attrsCombo we are stepping through and our single attr
      // therefore clear everything and call it day
      if (!attrDiff.length) {
        selectedAttributes = {}
      } else {
        let attributeToReset = attrDiff.pop()
        delete selectedAttributes[attributeToReset]
      }
      mappedProductId = this.productIdForSelectedAttributes(selectedAttributes)
    }
    return mappedProductId
  }

  decorateAttributeByName = (attrValue, name) => {
    switch (name) {
      case 'amount':
        return attrValue > 0 ? `$${attrValue}` : 'No Limit'
      default:
        return attrValue
    }
  }
}

export default SelectionControls
