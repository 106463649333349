import React from 'react'
import { QuantityPicker } from 'baby-design'
import { PurchasedQuantityProps } from './PurchasedQuantity.types'
import css from './PurchasedQuantity.styles.scss'

const DEFAULT_LABEL = 'Quantity purchased'

const PurchasedQuantity: React.FC<PurchasedQuantityProps> = ({
  label = DEFAULT_LABEL,
  quantity,
  max,
  onQuantityChanged,
}) => {
  const handleMinusClicked = () => onQuantityChanged?.(quantity - 1)

  const handlePlusClicked = () => onQuantityChanged?.(quantity + 1)

  return (
    <div className={css.PurchasedQuantity}>
      <span className={css.PurchasedQuantity__Label}>{label}</span>
      <QuantityPicker
        max={max}
        min={1}
        size="sm"
        type="pdp"
        value={quantity}
        variant="fill"
        onMinusClick={handleMinusClicked}
        onPlusClick={handlePlusClicked}
      />
    </div>
  )
}

export default PurchasedQuantity
