const styleMaps = {
  COLORS: {
    // Old Colors
    brandPrimary: '#f27d74',
    brandSecondary: '#037695',
    brandTertiary: '#65bc97',
    brandQuaternary: '#6B285B',
    brandQuinary: '#dbb411',

    // Rebrand
    rubyRed: '#ED0035',
    eggplant: '#6e3264',
    forest: '#00b496',
    salmon: '#ff7a5e',
    apricot: '#fac2b8',
    sky: '#00a3c7',
    seafoam: '#b5edd1',
    bubblegum: '#ff7aab',
    goldenYellow: '#ffd200',
    hotPink: '#ff478e',
  },
}

export default styleMaps
