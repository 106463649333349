import { useMutation } from '@tanstack/react-query'
import { errorMessage } from 'lib/flash-message'
import fetch from 'lib/fetch'
import {
  apiV3CancelCashFundContributionsPath,
  apiV3ConfirmCashFundContributionsPath,
} from 'lib/urls'
import { Reservation } from 'registry/components/LegacyCrowdfundReserveForm/LegacyCrowdfundReserveForm.types'

interface UseCashFundContributionActionsProps {
  onCancel?: () => void
  onConfirm?: (reservation: Reservation) => void
}

type CashFundContributionAction = 'confirm' | 'cancel'

const ACTION_CANCEL = 'cancel'
const ACTION_CONFIRM = 'confirm'

const confirmContribution = (token: string, payload: any) =>
  fetch(apiV3ConfirmCashFundContributionsPath(token), {
    method: 'POST',
    body: JSON.stringify(payload),
  })

const cancelContribution = (token: string) =>
  fetch(apiV3CancelCashFundContributionsPath(token), { method: 'POST' })

const buildErrorMessage = (
  action: CashFundContributionAction,
  message?: string
) =>
  message ||
  `Unable to ${action} contribution. If the problem persists, contact support.`

const useCashFundContributionActions = ({
  onCancel,
  onConfirm,
}: UseCashFundContributionActionsProps) => {
  const { mutate: confirmCashFundContribution, isLoading: isConfirming } =
    useMutation({
      mutationFn: (payload: any) => confirmContribution(payload.token, payload),
      onSuccess: (res) => onConfirm?.(res),
      onError: (error: any) => {
        errorMessage(buildErrorMessage(ACTION_CONFIRM, error?.error?.message))
      },
    })

  const { mutate: cancelCashFundContribution, isLoading: isCanceling } =
    useMutation({
      mutationFn: (token: string) => cancelContribution(token),
      onSuccess: () => onCancel?.(),
      onError: (error: any) => {
        errorMessage(buildErrorMessage(ACTION_CANCEL, error?.error?.message))
      },
    })

  return {
    cancelCashFundContribution,
    confirmCashFundContribution,
    isCanceling,
    isConfirming,
  }
}

export default useCashFundContributionActions
