import { FREE_SHIPPING_THRESHOLD } from 'shared/constants'

export const freeShippingText = (price) => {
  if (price < FREE_SHIPPING_THRESHOLD) {
    return `Free Shipping on orders $${FREE_SHIPPING_THRESHOLD}+ & Free Returns`
  } else {
    return 'Free Shipping & Free Returns'
  }
}

export default ({ price }) => (
  <div className="pls pbm h7">
    <p>{freeShippingText(price)}</p>
  </div>
)
