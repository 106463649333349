export interface AddedByNoteProps {
  className: string
  text: string
}

export const AddedByNote = ({ className, text }: AddedByNoteProps) => {
  return (
    <div className={className}>
      <i className="fa fa-icn fa-plus text-success prs" />
      {text}
    </div>
  )
}

export default AddedByNote
