import React from 'react'

interface EyeProps {
  className?: string
  filled?: boolean
}

const Eye: React.FC<EyeProps> = ({ className, filled }: EyeProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {filled ? (
      <>
        <path
          d="M9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12C14.25 13.2426 13.2426 14.25 12 14.25C10.7574 14.25 9.75 13.2426 9.75 12Z"
          fill="#252324"
        />
        <path
          clipRule="evenodd"
          d="M1.25 12C1.25 10 4 3.25 12 3.25C20 3.25 22.75 10 22.75 12C22.75 14 20 20.75 12 20.75C4 20.75 1.25 14 1.25 12ZM12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25Z"
          fill="#252324"
          fillRule="evenodd"
        />
      </>
    ) : (
      <path
        clipRule="evenodd"
        d="M5.42407 7.33505C3.69922 8.85109 2.75 10.663 2.75 12C2.75 13.337 3.69922 15.1489 5.42407 16.665C7.12285 18.1581 9.45271 19.25 12 19.25C14.5473 19.25 16.8772 18.1581 18.5759 16.665C20.3008 15.1489 21.25 13.337 21.25 12C21.25 10.663 20.3008 8.85109 18.5759 7.33505C16.8772 5.84192 14.5473 4.75 12 4.75C9.45271 4.75 7.12285 5.84192 5.42407 7.33505ZM4.4338 6.20839C6.35431 4.52037 9.02444 3.25 12 3.25C14.9756 3.25 17.6457 4.52037 19.5662 6.20839C21.4606 7.87349 22.75 10.0616 22.75 12C22.75 13.9384 21.4606 16.1265 19.5662 17.7916C17.6457 19.4796 14.9756 20.75 12 20.75C9.02444 20.75 6.35431 19.4796 4.4338 17.7916C2.53936 16.1265 1.25 13.9384 1.25 12C1.25 10.0616 2.53936 7.87349 4.4338 6.20839ZM12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75ZM8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12Z"
        fill="#252324"
        fillRule="evenodd"
      />
    )}
  </svg>
)

export default Eye
