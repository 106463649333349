export const GIFT_GIVER_INFO_STEP = 1
export const PAYMENT_OPTION_STEP = 2
export const PAYMENT_INFO_STEP = 3
export const CONFIRM_DETAILS_STEP = 4
export const THANK_YOU_ADDRESS_STEP = 5
export const SUCCESS_STEP = 6
export const CANCEL_STEP = 7

// payment option keys
export const CASH = 'cash'
