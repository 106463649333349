import { Document } from '@contentful/rich-text-types'
import RichTextBody from '../../RichTextBody/RichTextBody'

import { AnchoredH } from '../Utilities'

interface PostEmbedMethodologySectionFields {
  headerText: string
  methodologyText: Document
}

export interface MethodologySectionProps {
  fields: PostEmbedMethodologySectionFields
}

const MethodologySection: React.FC<MethodologySectionProps> = ({
  fields,
}: MethodologySectionProps) => (
  <section>
    <AnchoredH explicitAnchor="editorial-methodology" headingType="h2">
      {fields.headerText}
    </AnchoredH>
    <div>
      <RichTextBody richTextBody={fields.methodologyText} />
    </div>
  </section>
)

export default MethodologySection
