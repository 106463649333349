import { connect } from 'react-redux'
import { deleteRegItem } from '../../actions/reg-items-actions'
import Modal from 'components/modal/modal'
import { Col, ModalBody, Row } from 'react-bootstrap'
import { DefaultButton, InfoButton } from 'components/buttons'

const RegItemDeleteModal = ({ show, onHide, deleteRegItem, regItemId }) => {
  const handleDelete = () => {
    deleteRegItem(regItemId)
    onHide()
  }

  return (
    <Modal show={show} onHide={onHide} title="Just Checking!">
      <ModalBody>
        <Row>
          <Col xs={12}>
            Are you sure you want to delete this item? Someone already said that
            they will purchase it, and they may get confused if it disappears
            from your list.
          </Col>
        </Row>

        <Row className="mtl">
          <Col sm={6}>
            <DefaultButton
              empty
              onClick={handleDelete}
              className="mbm full-width"
            >
              Yes, Delete It
            </DefaultButton>
          </Col>

          <Col sm={6}>
            <InfoButton onClick={onHide} className="full-width">
              No, Go Back
            </InfoButton>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
export default connect(undefined, { deleteRegItem })(RegItemDeleteModal)
