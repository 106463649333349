import PropTypes from 'prop-types'
import OfferList from 'components/offer-list/offer-list'
import { regItemProps } from '../reg-item-props'
import FundingProgress from './funding-progress'
import css from './group-gift-reg-item-card.scss'

const GroupGiftOfferPanel = ({
  withOfferList,
  regItem: {
    id,
    eligibleForDeferredPayment,
    fundedAmount,
    goalAmount,
    offers,
    quantityNeeded,
  },
  isRegistryDiscountView,
  isContributorView,
  openRedirectModal,
}) => (
  <>
    {withOfferList && (
      <>
        <OfferList
          offers={offers}
          isRegistryDiscountView={isRegistryDiscountView}
          isGroupGift
          regItemId={id}
          className="mtl"
          isContributorView={isContributorView}
          openRedirectModal={openRedirectModal}
        />
        <hr className="mvm" style={{ display: 'block' }} />
      </>
    )}
    {quantityNeeded > 0 && (
      <FundingProgress
        className={css.fundingProgress}
        goalAmount={goalAmount}
        fundedAmount={fundedAmount}
        isContributorView={isContributorView}
      />
    )}
  </>
)

GroupGiftOfferPanel.propTypes = {
  isRegistryDiscountView: PropTypes.bool,
  isContributorView: PropTypes.bool,
  regItem: PropTypes.shape(regItemProps).isRequired,
  withOfferList: PropTypes.bool,
  openRedirectModal: PropTypes.func,
}

GroupGiftOfferPanel.defaultProps = {
  isRegistryDiscountView: false,
  isContributorView: false,
  withOfferList: false,
  openRedirectModal: null,
}

export default GroupGiftOfferPanel
