import fetch from 'lib/fetch'
import { apiV3UserPath, apiV3RestrictedFamiliesPath } from 'lib/urls'

export const createUser = (data) => {
  return fetch(apiV3UserPath, {
    body: JSON.stringify(data),
    method: 'POST',
  })
}

export const saveBirthday = (data, restrictedAccessToken) => {
  return fetch(
    data.family.id
      ? `${apiV3RestrictedFamiliesPath}/${data.family.id}`
      : apiV3RestrictedFamiliesPath,
    {
      body: JSON.stringify(data),
      headers: { token: restrictedAccessToken },
      method: data.family.id ? 'PUT' : 'POST',
    }
  )
}
