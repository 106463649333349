import css from './SearchResultComponents.scss'
import { AdBanner } from '../../types'
import Markdown from 'markdown-to-jsx'
import { useTracking } from 'lib/analytics'
import { trackUnifiedSearchEvent } from '../../SearchBar.utils'

function SearchResultAdBanner({ result }: { result: AdBanner }) {
  const tracker = useTracking()
  const trackSearchEvents = () => {
    const event = result.tracking_event
    if (!event) return

    trackUnifiedSearchEvent(event, tracker.trackEvent)
  }

  const handleButtonClick = () => {
    trackSearchEvents()
    window.location.href = result.url
  }

  return (
    <div className={css.SearchResults__adBanner}>
      <Markdown>{result.text}</Markdown>
      <button onClick={handleButtonClick}>{result.cta}</button>
    </div>
  )
}

export default SearchResultAdBanner
