import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import { weeksFromToday } from 'lib/date'

const useMaxWishlistBirthdate = () => {
  const { flagValue: allowFutureBirthdates } = useFeatureFlag(
    '24e_ff_regex_wishlist_updates',
    false
  )

  const currentDate = new Date()
  const maxBirthDate = weeksFromToday(40)

  const maxDate = allowFutureBirthdates ? maxBirthDate : currentDate
  const maxYear = maxDate.getFullYear()

  return {
    allowFutureBirthdates,
    maxDate,
    maxYear,
  }
}

export default useMaxWishlistBirthdate
