import React from 'react'

interface CloseProps {
  className?: string
}

const Close: React.FC<CloseProps> = ({ className }: CloseProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Close Icon</title>
    <path
      d="M6 18L18 6M6 6L18 18"
      stroke="#252324"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
)

export default Close
