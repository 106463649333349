import { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import Cookies from 'js-cookie'
import { SaveButton, InfoButton } from 'components/buttons'
import { ModalFooter } from 'components/modal'
import { createValidator, required } from 'lib/form-validation'
import GiftMessageFields from './gift-message-fields'

const validate = (data) => {
  let rules = {
    name: [required],
  }
  return createValidator(rules)(data)
}
class GiftMessageForm extends Component {
  static propTypes = {
    name: PropTypes.string,
    note: PropTypes.string,
  }

  static defaultProps = {
    cancelText: 'Cancel',
  }

  render() {
    const {
      fields: { note, name },
      handleSubmit,
      submitting,
      onCancel,
      submitText,
      cancelText,
    } = this.props
    return (
      <form onSubmit={handleSubmit}>
        <GiftMessageFields note={note} name={name} />
        <ModalFooter>
          <SaveButton block pill submitting={submitting} type="submit">
            {note.value.length === 0 && 'Skip'}
          </SaveButton>
          {onCancel && (
            <InfoButton empty pill block className="mtm" onClick={onCancel}>
              {cancelText}
            </InfoButton>
          )}
        </ModalFooter>
      </form>
    )
  }
}

export default reduxForm(
  {
    form: 'giftMessage',
    fields: ['name', 'note'],
    validate,
  },
  (state, props) => {
    let { name, note } = props.giftMessage
    return {
      initialValues: {
        name: Cookies.get('name'),
        note,
      },
    }
  }
)(GiftMessageForm)
