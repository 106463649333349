import { createRef, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { HelpBlock } from 'react-bootstrap'
import classNames from 'classnames'
import { sizesProp } from './prop-types/size-props'

const SELECT_VARIANT_MUTED = 'muted'
const SELECT_VARIANT_SUCCESS = 'success'
const SELECT_VARIANTS = [SELECT_VARIANT_MUTED, SELECT_VARIANT_SUCCESS]

export default class Select extends PureComponent {
  constructor(props) {
    super(props)
    this.inputRef = createRef()
  }
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    id: PropTypes.string,
    inline: PropTypes.bool,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    size: sizesProp,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.oneOf(SELECT_VARIANTS),
    wrapperClassName: PropTypes.string,
    'aria-label': PropTypes.string,
  }

  static defaultProps = {
    variant: SELECT_VARIANT_SUCCESS,
  }

  render() {
    const {
      disabled,
      name,
      onChange,
      onFocus,
      onBlur,
      value: rawValue,
      children,
      wrapperClassName,
      error,
      inline,
      label,
      size,
      fullWidth,
      variant,
      className,
      style,
      help,
    } = this.props
    const value = rawValue === null ? '' : rawValue // Satisfy react null-value-for-controlled-component errors

    const sizeClasses = {
      small: 'input-sm',
      medium: 'input-md',
      large: 'input-lg',
    }
    const props = { disabled, name, onChange, onFocus, onBlur, value, children }

    return (
      <div
        className={classNames('form-group', wrapperClassName, {
          'has-error': error,
          'inline-block': inline,
          'no-label': !label,
        })}
      >
        {label ? (
          <label
            className={classNames(
              sizeClasses[size],
              'control-label select-label'
            )}
            for={this.props.id}
            style={fullWidth ? { width: '100%' } : {}}
          >
            {label}
          </label>
        ) : null}
        <div
          className={classNames(sizeClasses[size], 'select-wrapper', {
            'select-muted': variant === SELECT_VARIANT_MUTED,
            'select-success': variant === SELECT_VARIANT_SUCCESS,
            disabled,
          })}
        >
          <select
            aria-label={this.props['aria-label']}
            {...props}
            className={classNames(className, sizeClasses[size], 'form-control')}
            ref={this.props.inputRef || this.inputRef}
            key="input"
            id={this.props.id}
            style={style}
          >
            {children}
          </select>
          {error || help ? (
            <HelpBlock className={classNames('h6', { 'text-muted': !error })}>
              {/* if an element has help text and an error just show the error. otherwise show help text */}
              {error || help}
            </HelpBlock>
          ) : null}
        </div>
      </div>
    )
  }
}
