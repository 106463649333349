import { Cart } from 'src/types/cart'
import { RegistryDiscount } from 'src/types/registryDiscount'
import css from './CartSummary.styles.scss'
import { CompactCartSummary } from './CompactSummary/CompactSummary'
import { FullSummary } from './FullSummary/FullSummary'

interface CartSummaryProps extends Cart, RegistryDiscount {
  isCompact?: boolean
  totalQuantity?: number
}

export const CartSummary = ({
  isCompact,
  checkoutTotals,
  isEligibleForRegistryDiscount,
  eligibleForMultiuseDiscount,
  totalQuantity,
}: CartSummaryProps) => (
  <div className={css.CartSummary}>
    <div className={css.CartSummary__list}>
      {isCompact ? (
        <CompactCartSummary checkoutTotals={checkoutTotals} />
      ) : (
        <FullSummary
          checkoutTotals={checkoutTotals}
          eligibleForMultiuseDiscount={eligibleForMultiuseDiscount}
          isEligibleForRegistryDiscount={isEligibleForRegistryDiscount}
          totalQuantity={totalQuantity}
        />
      )}
    </div>
  </div>
)
