import PropTypes from 'prop-types'
import {
  ELECTRONIC_GIFT_CARD_MAX,
  ELECTRONIC_GIFT_CARD_MIN,
} from 'shared/constants'
import { giftCardPDPPath } from 'lib/urls'
import PriceRangeOffer from 'components/offer/price-range-offer'

const GiftCardOffer = ({ disableLink, regItemId }) => (
  <>
    <PriceRangeOffer
      disableLink={disableLink}
      rangeEnd={ELECTRONIC_GIFT_CARD_MAX}
      rangeStart={ELECTRONIC_GIFT_CARD_MIN}
      storeDisplayName="Babylist"
      storeIconName="babylist"
      url={
        regItemId
          ? `${giftCardPDPPath}?reg_item_id=${regItemId}`
          : giftCardPDPPath
      }
    />
    <small className="xsmall text-muted text-italic">
      Valid on purchases from the Babylist Shop.
    </small>
  </>
)

GiftCardOffer.propTypes = {
  disableLink: PropTypes.bool,
  regItemId: PropTypes.number,
}

GiftCardOffer.defaultProps = {
  disableLink: false,
  regItemId: false,
}

export default GiftCardOffer
