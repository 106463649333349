import React from 'react'
import Link from 'components/link'
import { cashFundPrintablePath } from 'lib/urls'

const CashFundPrintableLink: React.FC = () => (
  <div className="well paxl">
    Bring cash or your check to the baby shower or mail it to them at this
    address.
    <br />
    <br />
    We'll let them know that you plan to give them this gift.
    <br />
    <br />
    Grab the{' '}
    <Link newWindow url={cashFundPrintablePath}>
      Cash Fund printable
    </Link>{' '}
    to bring in person.
  </div>
)

export default CashFundPrintableLink
