import { CurrentUser } from '../../global'
import { Registry } from 'src/types/registry'

export const isRegistryOwnerOrPartner = (
  currentUser?: CurrentUser,
  registry?: Registry
) =>
  currentUser?.registries.some(
    (r) => r.id === registry?.id && (r.role === 'owner' || r.role === 'partner')
  )
