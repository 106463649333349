import PropTypes from 'prop-types'
import classNames from 'classnames'
import { InfoButton } from 'components/buttons'

const ExternalRegistryAction = (
  { primaryOfferUrl, viglinkEnabled, title },
  context
) => {
  const { isFacebookApp, isMobile } = context
  const rel = `nofollow noindex${viglinkEnabled ? '' : ' norewrite'}`
  // Facebook's in-app browser does not support opening links in new windows
  const target = isFacebookApp ? undefined : '_blank'

  return (
    <InfoButton
      className="guest-action mtl pam"
      href={primaryOfferUrl}
      rel={rel}
      target={target}
      pill
      block
      size={isMobile ? 'small' : undefined}
    >
      {title}
    </InfoButton>
  )
}

ExternalRegistryAction.propTypes = {
  primaryOfferUrl: PropTypes.string.isRequired,
  viglinkEnabled: PropTypes.bool.isRequired,
  title: PropTypes.string,
}

ExternalRegistryAction.defaultProps = {
  title: 'View Registry',
}

ExternalRegistryAction.contextTypes = {
  isFacebookApp: PropTypes.bool,
  isMobile: PropTypes.bool,
}

export default ExternalRegistryAction
