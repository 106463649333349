import React from 'react'
import { ContributionSuccessProps } from 'registry/components/CrowdfundReserveFormFlow/components/ContributionSuccess/ContributionSuccess.types'
import SuccessMessage from 'registry/components/CrowdfundReserveFormFlow/components/ContributionSuccess/components/SuccessMessage/SuccessMessage'
import {
  cashSuccessSubtitle,
  cashSuccessTitle,
  otherSuccessSubtitle,
  otherSuccessTitle,
} from 'registry/components/CrowdfundReserveFormFlow/components/ContributionSuccess/utils/helpers'
import GiftingDetails from 'registry/components/CrowdfundReserveFormFlow/components/ContributionSuccess/components/GiftingDetails/GiftingDetails'
import { Button, TextLink } from '../../../../../baby-design'
import css from './ContributionSuccess.styles.scss'
import CrowdfundReserveModalTitle from 'registry/components/CrowdfundReserveFormFlow/components/CrowdfundReserveModalTitle/CrowdfundReserveModalTitle'

const CASH = 'cash'

const ContributionSuccess: React.FC<ContributionSuccessProps> = ({
  address,
  names,
  onCancel,
  onDone,
  paymentPreference,
  reservation,
}) => {
  const isCashContribution = paymentPreference.key === CASH
  const amount = reservation.giftAmount as number
  const title = isCashContribution
    ? cashSuccessTitle(amount)
    : otherSuccessTitle(names, amount)
  const subtitle = isCashContribution
    ? cashSuccessSubtitle(address)
    : otherSuccessSubtitle(paymentPreference.name)

  return (
    <div className={css.ContributionSuccess}>
      <CrowdfundReserveModalTitle />
      <div className={css.ContributionSuccess__Content}>
        <SuccessMessage subtitle={subtitle} title={title} />
        {isCashContribution && <GiftingDetails address={address} />}
      </div>
      <div className={css.ContributionSuccess__Buttons}>
        <Button
          className={css.ContributionSuccess__DoneButton}
          size="md"
          variant="primary"
          onClick={() => onDone?.()}
        >
          Done
        </Button>
        <TextLink underline onClick={() => onCancel?.()}>
          Cancel this gift
        </TextLink>
      </div>
    </div>
  )
}

export default ContributionSuccess
