import { Component } from 'react'
import RegItemFilters from '../reg-item-filters/reg-item-filters'
import { Modal, ModalBody, ModalFooter } from 'components/modal'
import { SaveButton } from 'components/buttons'
import { addFilter, setFilters } from '../../actions'
import {
  getCategorizedRegItems,
  getCategorizedRegItemsCount,
  getDistinctStores,
  getFilters,
  getIsRegistryDiscountView,
  getPopulatedCategories,
} from '../../reducers'
import { connect } from 'react-redux'

class MobileFiltersModal extends Component {
  state = {}

  componentDidMount() {
    this.setState({ originalFilters: this.props.filters })
  }

  handleCancel = () => {
    // Revert filter changes to their state when this modal was opened.
    this.props.setFilters(this.state.originalFilters)
    this.props.onHide()
  }

  render() {
    const { show, onHide, ...filterProps } = this.props

    const regItemsCount = this.props.isRegistryDiscountView
      ? this.props.categorizedRegItems.reduce(
          (sum, category) =>
            sum +
            category.regItems.filter((regItem) => regItem.registryDiscount)
              .length,
          0
        )
      : this.props.filteredRegItemCount
    return (
      <Modal
        affixedFooter={true}
        show={show}
        onHide={this.handleCancel}
        title={`Filters (${regItemsCount})`}
      >
        <ModalBody>
          <RegItemFilters {...filterProps} />

          {regItemsCount === 0 ? (
            <div className="text-center mbl">
              <em>No gifts matched the filters you selected</em>
            </div>
          ) : null}
        </ModalBody>

        <ModalFooter>
          <SaveButton tappable onClick={onHide} className="center-block mbl">
            Apply Filters
          </SaveButton>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const isRegistryDiscountView = getIsRegistryDiscountView(state)
  return {
    categorizedRegItems: getCategorizedRegItems(state),
    stores: getDistinctStores(state),
    categories: getPopulatedCategories(state),
    isRegistryDiscountView,
    filteredRegItemCount: getCategorizedRegItemsCount(state),
    filters: getFilters(state),
  }
}

export default connect(mapStateToProps, { addFilter, setFilters })(
  MobileFiltersModal
)
