import { Checkbox, QuantityPicker } from 'baby-design'
import { RegItem } from 'src/types/regItem'
import css from './QuantitySetting.style.scss'

interface EditItemDetailsQuantitySettingProps {
  regItemFields: RegItem
  updateRegItemFields: (changes: object) => void
  handleDelete: () => void
  isGroupGift?: boolean
}

export const EditItemDetailsQuantitySetting = ({
  regItemFields,
  updateRegItemFields,
  handleDelete,
  isGroupGift = false,
}: EditItemDetailsQuantitySettingProps) => {
  const { quantity } = regItemFields

  return (
    <div className={css.quantity_setting}>
      <div className={css.quantity_setting__controls}>
        <div className={css.quantity_setting__heading}>Quantity requested</div>
        <QuantityPicker
          max={isGroupGift ? 1 : undefined}
          size="sm"
          type="cart"
          value={quantity}
          variant="fill"
          onMinusClick={() =>
            updateRegItemFields({
              quantity: quantity === null ? quantity : quantity - 1,
            })
          }
          onPlusClick={() =>
            updateRegItemFields({ quantity: (quantity || 0) + 1 })
          }
          onTrashClick={handleDelete}
        />
      </div>
      {!isGroupGift && (
        <div className={css.quantity_setting__unlimited}>
          <Checkbox
            inputProps={{
              id: 'quantityUnlimited',
              checked: quantity === null,
              disabled: isGroupGift,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  updateRegItemFields({ quantity: null })
                } else {
                  updateRegItemFields({ quantity: 1 })
                }
              },
            }}
            size="md"
            type="secondary"
          />
          <label htmlFor="quantityUnlimited">Unlimited</label>
        </div>
      )}
    </div>
  )
}
