import AddressDoesNotTransferDisclaimer from 'registry/components/modals/ContactInfoModal/AddressDoesNotTransferDisclaimer'
import { ContactInfoCopyFieldsProps } from '../../../../widgets/ContactInfoCopyFields'

interface AddressDisplayProps {
  noAddressComponent: React.ReactElement
  loadAddressComponent: React.ReactElement
  hasPublicAddress: boolean
  addressData?: ContactInfoCopyFieldsProps
  showAddress: boolean
}

const AddressDisplay: React.FC<AddressDisplayProps> = ({
  noAddressComponent,
  loadAddressComponent,
  hasPublicAddress,
  addressData,
  showAddress,
}: AddressDisplayProps) => {
  if (!hasPublicAddress) {
    return noAddressComponent
  }

  if (!showAddress) {
    return loadAddressComponent
  }

  return addressData ? (
    <div>
      <AddressDoesNotTransferDisclaimer />
      <div className="phl mtm mlm">
        {addressData.fullName}
        <br />
        {addressData.address.streetAddress1}{' '}
        {addressData.address.streetAddress2 ? (
          <span>, {addressData.address.streetAddress2}</span>
        ) : null}
        <br />
        {addressData.address.city}, {addressData.address.state}{' '}
        {addressData.address.postalCode}
      </div>
    </div>
  ) : (
    noAddressComponent
  )
}

export default AddressDisplay
