/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames'
import { includes } from 'lodash'
import PropTypes from 'prop-types'
import { cloneElement, Component } from 'react'
import { Collapse } from 'react-bootstrap'
import Tappable from 'react-tappable'
import { useTracking, track } from 'lib/analytics'

import { Drawer } from 'components/transitions'
import { Modal, ModalBody, ModalFooter } from 'components/modal'
import { InfoButton } from 'components/buttons'
import Link from 'components/link'
import Image from 'components/image'
import * as blUrls from 'lib/urls'
import * as BLConstants from 'shared/constants'

import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import ExternalLink from 'shared/svg/external-link.svg'
import { HIDE_MOBILE_NAV_MODAL_EVENT } from '../constants'
import css from './mobile-nav-modal.scss'
import {
  aboutUsMenu,
  guidesMenu,
  healthMenu,
  homeMenu,
  productsMenu,
  registryMenu,
  userMenu,
} from './mobile-nav-menus'
import { TextLink } from 'baby-design'

const topLevelLinkClasses = 'mvn'
const topLevelLinkClassesLarge = classNames(topLevelLinkClasses, 'h5 pvs')
const topLevelLinkClassesSmall = classNames(
  topLevelLinkClasses,
  css.smallLinkPadding,
  'h7'
)

export const isUrlClientRoutable = (nextUrl) => {
  const currentUrl = window.location.href
  const routingWithinHelloBaby =
    includes(nextUrl, blUrls.helloBabyPath) &&
    includes(currentUrl, blUrls.helloBabyPath)
  return routingWithinHelloBaby
}

function makeMenuCollapsible(MenuComponent) {
  class CollapsibleMenu extends Component {
    constructor(props) {
      super(props)
      this.state = {
        openPanel: props.activeNavItem || '',
      }
    }

    handleCollapse = (panelName) => {
      const { openPanel } = this.state
      this.setState({
        openPanel: openPanel === panelName ? '' : panelName,
      })
    }

    render() {
      const { openPanel } = this.state
      return (
        <MenuComponent
          {...this.props}
          handleCollapse={this.handleCollapse}
          openPanel={openPanel}
        />
      )
    }
  }

  CollapsibleMenu.propTypes = {
    activeNavItem: PropTypes.string,
  }

  CollapsibleMenu.defaultProps = {
    activeNavItem: undefined,
  }

  return CollapsibleMenu
}

const MobileNavMenu = ({
  handleCollapse,
  openPanel,
  productCategories,
  guideTopics,
}) => {
  const [currentUser] = useCurrentUser()
  const tracker = useTracking()
  const menuItems = [
    homeMenu(currentUser),
    registryMenu(currentUser),
    productsMenu(productCategories),
    guidesMenu(guideTopics),
    healthMenu,
    aboutUsMenu,
    ...userMenu(currentUser),
  ]

  const trackClick = (eventCta) => {
    tracker.trackEvent({
      event: track.navbarClicked,
      eventCta,
      eventLocation: track.EventLocation.MOBILE_WEB_HAMBURGER,
    })
  }
  return (
    <ul className="list-unstyled mbn">
      {menuItems.map((menuItem) => {
        if (menuItem.type === 'node') {
          return (
            <li className={css.menuItem} key={menuItem.title}>
              {cloneElement(menuItem.node, { key: menuItem.title })}
            </li>
          )
        }
        if (menuItem.subMenuItems && menuItem.subMenuItems.length > 0) {
          return (
            <CollapsibleMenuItem
              handleCollapse={handleCollapse}
              isOpen={
                openPanel === 'Store'
                  ? menuItem.title === 'Shop'
                  : openPanel === menuItem.title
              }
              key={menuItem.title}
              subMenuItems={menuItem.subMenuItems}
              title={menuItem.title}
            />
          )
        }
        return (
          <MenuItem
            external={!!menuItem.external}
            href={menuItem.href}
            key={menuItem.title}
            size={menuItem.size}
            title={menuItem.title}
            onClick={() => trackClick(menuItem.title)}
          />
        )
      })}
    </ul>
  )
}

MobileNavMenu.propTypes = {
  currentUser: PropTypes.object,
  handleCollapse: PropTypes.func.isRequired,
  openPanel: PropTypes.string,
  productCategories: PropTypes.array.isRequired,
  guideTopics: PropTypes.object.isRequired,
}

MobileNavMenu.defaultProps = {
  currentUser: undefined,
  openPanel: '',
}

const MenuItem = ({ href, onClick, size, title, external }) => {
  const linkClass =
    size === 'small' ? topLevelLinkClassesSmall : topLevelLinkClassesLarge
  const isHoliday = title === 'Holiday'

  return (
    <li className={css.menuItem}>
      <Tappable
        className={linkClass}
        component="a"
        href={blUrls.absoluteUrl(href)}
        onClick={onClick}
      >
        <strong>{title}</strong>
        {external && (
          <ExternalLink
            className={classNames('pull-right', css.externalLinkIcon)}
          />
        )}
      </Tappable>
    </li>
  )
}

MenuItem.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['large', 'small']),
  title: PropTypes.string.isRequired,
  external: PropTypes.bool,
}

MenuItem.defaultProps = {
  href: undefined,
  onClick: undefined,
  size: 'large',
}

const CollapsibleMenuItem = ({
  isOpen,
  handleCollapse,
  subMenuItems,
  title,
}) => (
  <li className={classNames(css.menuItem, { [css.active]: isOpen })}>
    <Tappable
      className={topLevelLinkClassesLarge}
      component="a"
      onClick={() => handleCollapse(title)}
    >
      <strong>{title}</strong>
      <span className="h3">
        <span
          className={classNames('fa fa-angle-right pull-right', {
            [css.activeChevron]: isOpen,
          })}
        />
      </span>
    </Tappable>
    <Collapse in={isOpen}>
      <div>
        <ul className="list-unstyled ptm pbl">
          {subMenuItems.map((subMenuItem) => {
            if (subMenuItem.type === 'node') {
              return subMenuItem.node
            }
            return (
              <SubMenuItem
                href={subMenuItem.href}
                key={subMenuItem.title}
                title={subMenuItem.title}
              />
            )
          })}
        </ul>
      </div>
    </Collapse>
  </li>
)

CollapsibleMenuItem.propTypes = {
  handleCollapse: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  subMenuItems: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}

const SubMenuItem = ({ href, title }) => {
  let onClick = () => {}
  if (isUrlClientRoutable(href)) {
    onClick = (event) => {
      event.preventDefault()
      PubSub.publish(BLConstants.ROUTE_CHANGE, href)
      PubSub.publish(HIDE_MOBILE_NAV_MODAL_EVENT)
    }
  }

  return (
    <li className={css.menuItem}>
      <Tappable
        className={topLevelLinkClassesSmall}
        component="a"
        href={blUrls.absoluteUrl(href)}
        onClick={onClick}
      >
        <span className="plm">{title}</span>
      </Tappable>
    </li>
  )
}

SubMenuItem.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const MobileNavModal = (props) => {
  const { onHide, show } = props
  const [currentUser] = useCurrentUser()

  // Decorate the menu with collapsing capabilities
  const CollapsibleMobileNavMenu = makeMenuCollapsible(MobileNavMenu)
  const tracker = useTracking()

  const handleLoginClick = () => {
    onHide()
    window.location.href = blUrls.loginPathWithRedirect(
      window.location.pathname
    )
  }

  const handleSignUpClick = () => {
    window.location.href = blUrls.absoluteUrl(
      `${blUrls.newRegistryPath}/?loc=${track.EventLocation.MOBILE_WEB_HAMBURGER}`
    )
  }

  const logoLink = currentUser
    ? blUrls.absoluteUrl(blUrls.registryHomePath)
    : blUrls.absoluteUrl(blUrls.indexPath)

  return (
    <Modal
      className={classNames(css.navModal, 'mobile-menu-modal')}
      closeButton={false}
      show={show}
      size="fullscreen"
      transition={Drawer}
      onHide={onHide}
    >
      <ModalBody>
        <div
          className={classNames(css.modalHeader, 'flex align-center ptxl pbl')}
        >
          <button
            className={classNames('close', css.closeBtn)}
            type="button"
            onClick={onHide}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close alert</span>
          </button>
          <TextLink className="flex align-center" href={logoLink}>
            <Image
              alt="Babylist Logo"
              src="//images.babylist.com/image/upload/f_auto,q_auto/v1547580354/Babylist_logo_horizontal_oawglq.svg"
              width={122}
            />
            <span className="sr-only">
              Link to home page with Babylist's logo.
            </span>
          </TextLink>
        </div>
        <CollapsibleMobileNavMenu {...props} />
      </ModalBody>
      {currentUser ? (
        <div className={css.bottomHeightPlaceholder}>
          {/* placeholder to go behind the modal footer and ensure all links are accessible */}
        </div>
      ) : (
        <>
          <div className={css.signUpCta}>
            <div className="h4 text-bold mbs">Add Anything From Anywhere</div>
            <div className="xsmall text-bold">The only registry you need</div>
            <InfoButton pill className="mtm phxl" onClick={handleSignUpClick}>
              Sign up
            </InfoButton>
            <div className="h6">
              Already have an account?{' '}
              <Link className="text-bold" onClick={handleLoginClick}>
                Log In
              </Link>
            </div>
          </div>
        </>
      )}
      <ModalFooter className={css.navFooter}>
        <Tappable component="a" href={blUrls.absoluteUrl(blUrls.findPath)}>
          Find a Registry
        </Tappable>
        {' | '}
        <Tappable component="a" href={blUrls.faqPath}>
          Help Center
        </Tappable>
        {' | '}
        <Tappable component="a" href={blUrls.contactUsPath}>
          Contact Us
        </Tappable>
      </ModalFooter>
    </Modal>
  )
}

MobileNavModal.propTypes = {
  activeNavItem: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  productCategories: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  guideTopics: PropTypes.object.isRequired,
}

MobileNavModal.defaultProps = {
  activeNavItem: undefined,
}

export { MenuItem, MobileNavMenu }
export default MobileNavModal
