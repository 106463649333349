import React from 'react'
import { Exclamation2 } from '../../../../../../../baby-design/icons'
import css from './ReturnToBabylistReminder.styles.scss'

const ReturnToBabylistReminder: React.FC = () => (
  <div className={css.ReturnToBabylistReminder}>
    <Exclamation2 className={css.ReturnToBabylistReminder__Icon} />
    <p className={css.ReturnToBabylistReminder__Text}>
      Once you send your gift, come back and let us know!
    </p>
  </div>
)

export default ReturnToBabylistReminder
