export interface LeftArrowProps {
  className?: string
}

export default function LeftArrow({ className }: LeftArrowProps) {
  return (
    <svg
      className={className}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1644_2103)">
        <path
          clipRule="evenodd"
          d="M4.22456 10.4419C3.98048 10.1979 3.98048 9.80214 4.22456 9.55806L9.22456 4.55806C9.46864 4.31398 9.86437 4.31398 10.1084 4.55806C10.3525 4.80214 10.3525 5.19786 10.1084 5.44194L6.17539 9.375L16.3332 9.375C16.6783 9.375 16.9582 9.65482 16.9582 10C16.9582 10.3452 16.6783 10.625 16.3332 10.625L6.17539 10.625L10.1084 14.5581C10.3525 14.8021 10.3525 15.1979 10.1084 15.4419C9.86437 15.686 9.46864 15.686 9.22456 15.4419L4.22456 10.4419Z"
          fill="#252324"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_1644_2103">
          <rect
            fill="white"
            height="20"
            transform="translate(0.5)"
            width="20"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
