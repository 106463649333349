import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'react-bootstrap'

export const Accordion = ({
  children,
  trigger: Trigger,
  defaultOpen,
  collapsedContent,
}) => {
  const [open, setOpen] = useState(defaultOpen)
  useEffect(() => {
    setOpen(defaultOpen)
  }, [defaultOpen])
  return (
    <>
      <Trigger onClick={() => setOpen(!open)} isOpen={open} />
      <Collapse in={open}>{children}</Collapse>
      {!open && collapsedContent}
    </>
  )
}

Accordion.propTypes = {
  defaultOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  trigger: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  collapsedContent: PropTypes.node,
}

Accordion.defaultProps = {
  defaultOpen: false,
  collapsedContent: undefined,
}
