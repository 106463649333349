import React from 'react'
import CountrySelectorUtils from 'components/forms/CountrySelector/CountrySelectorUtils'
import { Select } from '../index'

const TOP_COUNTRIES = ['United States', 'Canada']

export interface CountrySelectorProps {
  countries: string[]
  value: string
  onChange: (value: string) => void
  isDisabled?: boolean
}

const CountrySelector = ({
  countries,
  value,
  onChange,
  isDisabled,
}: CountrySelectorProps) => {
  const rankedCountries = React.useMemo(() => {
    const utils = new CountrySelectorUtils({ countries })
    return utils.makeRankedCountries(TOP_COUNTRIES)
  }, [countries.toString()])

  return (
    <Select
      disabled={isDisabled}
      label="Country"
      value={value}
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        onChange(event.target.value)
      }}
    >
      {rankedCountries.map((country, i) => {
        const key = country + i
        return (
          <option key={key} value={country}>
            {country}
          </option>
        )
      })}
    </Select>
  )
}

export default CountrySelector
