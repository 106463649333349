/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react'
import PropTypes from 'prop-types'

import { SecondaryButton, InfoButton } from 'components/buttons'
import * as BLConstants from 'shared/constants'

import * as api from 'store/api'
import { parseErrorMessage } from 'lib/api-utils'
import { Button } from 'baby-design'

const AddToCartButton = ({
  buttonStyle,
  disabled,
  productId,
  quantity,
  useInfoButtonStyle,
  style,
  onSuccess,
  useHeartbeatDS,
  productPrice,
  productType,
  productSaleType,
}) => {
  const [added, setAdded] = useState(false)
  const [addingItemToCart, setAddingItemToCart] = useState(false)

  const text = added ? '✓ Added!' : 'Add to Cart'

  const handleClick = (e) => {
    e.preventDefault()
    setAddingItemToCart(true)
    api
      .addCartItem({ productId, quantity })
      .then((resp) => {
        setAdded(true)
        onSuccess(
          productId,
          quantity,
          productPrice,
          productType,
          productSaleType
        )
        PubSub.publish(BLConstants.UPDATE_CART_SUMMARY, {
          animateBadge: true,
          showCartDropdown: true,
        })
      })
      .catch((resp) => {
        PubSub.publish(BLConstants.FLASH_MESSAGE, {
          message: parseErrorMessage(resp),
          variant: 'danger',
        })
      })
      .finally((resp) => setAddingItemToCart(false))
  }
  if (useHeartbeatDS) {
    return (
      <Button
        loading={addingItemToCart}
        size="sm"
        variant="de-emphasized"
        onClick={handleClick}
      >
        {text}
      </Button>
    )
  }

  return useInfoButtonStyle ? (
    <InfoButton
      block
      disabled={disabled}
      {...buttonStyle}
      style={style}
      submitting={addingItemToCart}
      onClick={handleClick}
    >
      {text}
    </InfoButton>
  ) : (
    <SecondaryButton
      block
      {...buttonStyle}
      style={style}
      submitting={addingItemToCart}
      onClick={handleClick}
    >
      {text}
    </SecondaryButton>
  )
}

AddToCartButton.propTypes = {
  buttonStyle: PropTypes.object,
  disabled: PropTypes.bool,
  productId: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  useInfoButtonStyle: PropTypes.bool,
  onSuccess: PropTypes.func,
  useHeartbeatDS: PropTypes.bool,
  productPrice: PropTypes.number,
  productType: PropTypes.string,
  productSaleType: PropTypes.string,
}

AddToCartButton.defaultProps = {
  buttonStyle: {},
  disabled: false,
  useInfoButtonStyle: false,
  onSuccess: () => {},
  useHeartbeatDS: false,
}

export default AddToCartButton
