import { InfoIcon } from 'components/icons'

export default function AddressDoesNotTransferDisclaimer() {
  return (
    <p className="text-bold small">
      <InfoIcon height={24} width={24} style={{ marginRight: 4 }} /> Address
      doesn't transfer automatically
    </p>
  )
}
