import { forEach, find, values, cloneDeep } from 'lodash'

import {
  CHANGE_STORE,
  REMOVE_RESERVATION,
  SET_RESERVATIONS,
  SHOW_PURCHASE_INFO_FORM,
  UPDATE_RESERVATION,
} from '../actions'

import { GIFT_MESSAGE_SEPARATOR } from '../constants'
import { initializePurchasingStore } from '../lib'

const initReservations = (reservations) => {
  forEach(reservations, (res, idx) => {
    res = initializePurchasingStore(res)
  })
  return reservations
}

export const getGiftMessageReservation = (reservations) => {
  const res = find(reservations, (res) => res.giftMessage)
  if (res) {
    return res
  }
  if (values(reservations)[0]) {
    return values(reservations)[0]
  }
  return null
}

export const getGiftMessage = (reservations) => {
  const res = getGiftMessageReservation(reservations)
  let note
  let name = null
  if (res && res.giftMessage) {
    const splitGiftMessage = res.giftMessage.split(GIFT_MESSAGE_SEPARATOR)
    switch (splitGiftMessage.length) {
      case 1:
        name = res.name
        note = splitGiftMessage[0].trim()
        break
      case 2:
        name = splitGiftMessage[1].trim()
        note = splitGiftMessage[0].trim()
        break
      default:
        break
    }
  } else {
    name = res?.name
    note = res?.note
  }
  return {
    name,
    note,
  }
}

export const getIsGiftGiverThankYouAddressProvided = (reservations) =>
  reservations.some(
    (reservation) => reservation.giftGiverThankYouAddressProvided
  )

const reservationsReducer = (state = {}, action) => {
  const newState = { ...state }

  switch (action.type) {
    case SET_RESERVATIONS:
      return initReservations(cloneDeep(action.reservations))

    case UPDATE_RESERVATION:
      newState[action.obj.id] = {
        ...(newState[action.obj.id] || {}),
        ...action.obj,
      }
      return newState

    case REMOVE_RESERVATION:
      delete newState[action.id]
      return newState

    case SHOW_PURCHASE_INFO_FORM:
      newState[action.id].showPurchaseInfoForm = action.show
      return newState

    case CHANGE_STORE:
      newState[action.id].prevStore = action.prevStore
      newState[action.id].store = action.store
      return newState

    default:
      return state
  }
}

export default reservationsReducer
