/* eslint-disable */
import { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import useSimpleShippingByDefault from 'src/hooks/useSimpleShippingByDefault'
import { Form, FormButtonGroup, Radio, RadioGroup } from 'components/forms'
import { SaveButton } from 'components/buttons'
import { ModalFooter } from 'components/modal'
import { createValidator, EMOJI_REGEX } from 'lib/form-validation'
import {
  getShippingAddress,
  getShippingConsolidation,
  getUserFullName,
} from 'registry/reducers'
import { COUNTRIES, STATES_AND_PROVINCES } from 'shared/constants'
import { connect } from 'react-redux'
import ShippingFields, { createValidationRules } from './shipping-fields'
import ConsolidatedShippingFormFields from 'registry/forms/consolidated-shipping-form-fields'
import css from './shipping-address-form.scss'

const ShippingAddressForm = ({
  fields,
  submitting,
  handleSubmit,
  onSubmitSuccess,
  onSubmitFail,
  showCancelButton,
  onSave,
  onCancel,
  cancelText,
  renderSubText,
  excludedFields,
  ctaSectionClassName,
  secondaryButtonComponent,
  isDisabled,
  renderCustomSubmit,
  showConsolidationChoice,
}) => {
  const { consolidationChoice } = fields

  const renderDefaultSubmit = () => (
    <>
      {showCancelButton ? (
        <FormButtonGroup
          onClickSecondary={onCancel}
          secondaryButtonDisabled={submitting}
          textPrimary="Save"
          textSecondary={cancelText ? cancelText : undefined}
          className={ctaSectionClassName}
          secondaryButtonComponent={secondaryButtonComponent}
        />
      ) : (
        <div className={ctaSectionClassName}>
          <SaveButton submitting={submitting} />
        </div>
      )}
    </>
  )

  const isSimpleShippingByDefaultExperiment = useSimpleShippingByDefault()
  const showConsolidatedShippingCallout =
    isSimpleShippingByDefaultExperiment &&
    consolidationChoice?.initialValue === 'rolling'

  return (
    <Form className={css.ShippingAddressForm} onSubmit={handleSubmit(onSave)}>
      <ShippingFields
        {...fields}
        countries={COUNTRIES}
        excludedFields={excludedFields}
        isDisabled={isDisabled}
        showConsolidatedShippingCallout={showConsolidatedShippingCallout}
        states={STATES_AND_PROVINCES}
      />

      {showConsolidationChoice && !showConsolidatedShippingCallout && (
        <ConsolidatedShippingFormFields
          consolidationChoice={consolidationChoice}
        />
      )}

      <ModalFooter>
        <div className={css.ShippingAddressForm__footerWithSubtext}>
          {renderCustomSubmit && renderCustomSubmit()}
          {!renderCustomSubmit && renderDefaultSubmit()}
          <div className={css.ShippingAddressForm__subtext}>
            {renderSubText && renderSubText()}
          </div>
        </div>
      </ModalFooter>
    </Form>
  )
}

ShippingAddressForm.propTypes = {
  fields: PropTypes.shape({
    city: PropTypes.object,
    country: PropTypes.object,
    name: PropTypes.object,
    phone: PropTypes.object,
    state: PropTypes.object,
    streetAddress1: PropTypes.object,
    streetAddress2: PropTypes.object,
    zip: PropTypes.object,
  }),
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  showCancelButton: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  cancelText: PropTypes.string,
  renderSubText: PropTypes.func,
  excludedFields: PropTypes.arrayOf(PropTypes.string),
  ctaSectionClassName: PropTypes.string,
  secondaryButtonComponent: PropTypes.func,
  isDisabled: PropTypes.bool,
  renderCustomSubmit: PropTypes.func,
  showConsolidationChoice: PropTypes.bool,
}

const DecoratedComponent = connect()(ShippingAddressForm)

export default reduxForm(
  {
    form: 'shippingAddressForm',
    fields: [
      'city',
      'country',
      'name',
      'phone',
      'state',
      'streetAddress1',
      'streetAddress2',
      'zip',
      'privateAddress',
      'consolidationChoice',
    ],
  },
  (state, props) => {
    const { showConsolidationChoice } = props
    const address = props.address || getShippingAddress(state) || {}
    const consolidationChoice =
      getShippingConsolidation(state)?.consolidationChoice

    return {
      initialValues: {
        city: address.city,
        country: address.country || 'United States',
        name: address.name || getUserFullName(state).replace(EMOJI_REGEX, ''),
        phone: address.phone,
        state: address.state,
        streetAddress1: address.streetAddress1,
        streetAddress2: address.streetAddress2,
        zip: address.zip,
        privateAddress: address.privateAddress || false,
        consolidationChoice: consolidationChoice,
      },
      validate: createValidator(createValidationRules({})),
    }
  }
)(DecoratedComponent)
