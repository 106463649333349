import React from 'react'

interface ExclamationProps {
  className?: string
  filled?: boolean
}

const Exclamation: React.FC<ExclamationProps> = ({
  className,
  filled,
}: ExclamationProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {filled ? (
      <path
        clipRule="evenodd"
        d="M2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM12 14.75C11.5858 14.75 11.25 14.4142 11.25 14V8.5C11.25 8.08579 11.5858 7.75 12 7.75C12.4142 7.75 12.75 8.08579 12.75 8.5V14C12.75 14.4142 12.4142 14.75 12 14.75ZM12 17.2891C11.5858 17.2891 11.25 16.9533 11.25 16.5391V16.5C11.25 16.0858 11.5858 15.75 12 15.75C12.4142 15.75 12.75 16.0858 12.75 16.5V16.5391C12.75 16.9533 12.4142 17.2891 12 17.2891Z"
        fill="#252324"
        fillRule="evenodd"
      />
    ) : (
      <path
        clipRule="evenodd"
        d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM12 7.75C12.4142 7.75 12.75 8.08579 12.75 8.5L12.75 14C12.75 14.4142 12.4142 14.75 12 14.75C11.5858 14.75 11.25 14.4142 11.25 14L11.25 8.5C11.25 8.08579 11.5858 7.75 12 7.75ZM12 15.75C12.4142 15.75 12.75 16.0858 12.75 16.5V16.5391C12.75 16.9533 12.4142 17.2891 12 17.2891C11.5858 17.2891 11.25 16.9533 11.25 16.5391V16.5C11.25 16.0858 11.5858 15.75 12 15.75Z"
        fill="#252324"
        fillRule="evenodd"
      />
    )}
  </svg>
)

export default Exclamation
