/* eslint-disable react/jsx-props-no-spreading */
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { offerProps } from 'components/offer/offer'
import { tracking, track, useTracking } from 'lib/analytics'
import { openRedirectModal } from 'registry/modals/actions'
import { RegItemShape } from 'registry/lib/prop-types'

export const BaseBestOfferLink = ({
  offers,
  children,
  openRedirectModal,
  isContributorView,
  regItem,
  isShopRegistryView,
}) => {
  const bestOffer =
    offers && (offers.find((o) => o.isBabylist) || offers.find((o) => o.url))
  const isBabylist = bestOffer?.isBabylist

  const tracker = useTracking()

  if (bestOffer) {
    const linkProps = {
      className: 'link-info',
      href: isBabylist || isContributorView ? bestOffer.url : undefined,
      target: isBabylist || isContributorView ? '_blank' : undefined,
      onClick: () => {
        tracker.trackEvent({ event: track.productClicked })
        if (!isContributorView) {
          if (!isBabylist) {
            openRedirectModal({
              url: bestOffer.url,
              storeDisplayName: bestOffer.storeDisplayName,
              regItem,
              isShopRegistryView,
            })
          }
        }
      },
    }

    linkProps.rel = classNames({
      nofollow: !bestOffer.isBabylist,
      norewrite: bestOffer.isBabylist || !bestOffer.viglinkEnabled,
    })

    return (
      <a style={{ cursor: 'pointer' }} {...linkProps}>
        {children}
      </a>
    )
  }
  return children
}

BaseBestOfferLink.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.shape(offerProps)),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  isContributorView: PropTypes.bool,
  regItem: PropTypes.shape(RegItemShape),
  isShopRegistryView: PropTypes.bool,
}

BaseBestOfferLink.defaultProps = {
  isContributorView: false,
  regItem: null,
  isShopRegistryView: false,
}

export const BestOfferLink = tracking({ event: track.productClicked })(
  connect(null, { openRedirectModal })(BaseBestOfferLink)
)
