import classNames from 'classnames'
import Lottie from 'react-lottie'
import css from './LoadingHeart.styles.scss'
import LoadingHeartAnimation from './LoadingHeart.lottie.json'
import { LoadingHeartProps } from './LoadingHeart.types'

const LoadingHeart = ({ className }: LoadingHeartProps) => (
  <div className={classNames(css.LoadingHeart, className)}>
    <Lottie
      isClickToPauseDisabled
      options={{ animationData: LoadingHeartAnimation }}
      title="Loading"
    />
  </div>
)

export default LoadingHeart
