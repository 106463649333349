import { useEffect, useRef, useState } from 'react'

import {
  HIDE_UNIVERSAL_NOTIFICATION_MODAL_EVENT,
  SET_UNIVERSAL_NOTIFICATION_MODAL_CONFIG_EVENT,
  SHOW_UNIVERSAL_NOTIFICATION_MODAL_EVENT,
} from 'shared/constants'
import { withContextualizedTracking } from 'lib/analytics'
import css from './UniversalNotificationModal.styles.scss'

interface UniversalNotificationModalConfig {
  renderContent: (closeModal: () => void) => JSX.Element
  onModalOpen?: () => void
  onModalClose?: () => void
}

const UniversalNotificationModal = () => {
  const [isVisible, setIsVisible] = useState(false)

  // Set up state and ref for modal config
  // This ensures that the modal config is always up to date even when it is called from closures
  const [modalConfig, _setModalConfig] =
    useState<UniversalNotificationModalConfig>()
  const modalConfigRef = useRef(modalConfig)
  const setModalConfig = (data: UniversalNotificationModalConfig) => {
    modalConfigRef.current = data
    _setModalConfig(data)
  }

  const openModal = () => {
    if (modalConfigRef.current?.onModalOpen)
      modalConfigRef.current?.onModalOpen()
    setIsVisible(true)
  }
  const closeModal = () => {
    if (modalConfigRef.current?.onModalClose)
      modalConfigRef.current?.onModalClose()
    setIsVisible(false)
  }

  useEffect(() => {
    const escFunction = (event: KeyboardEvent) => {
      if (event.key === 'Escape') closeModal()
    }
    document.addEventListener('keydown', escFunction, false)

    PubSub.subscribe(
      SET_UNIVERSAL_NOTIFICATION_MODAL_CONFIG_EVENT,
      setModalConfig
    )
    PubSub.subscribe(SHOW_UNIVERSAL_NOTIFICATION_MODAL_EVENT, openModal)
    PubSub.subscribe(HIDE_UNIVERSAL_NOTIFICATION_MODAL_EVENT, closeModal)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
      PubSub.unsubscribe(
        SET_UNIVERSAL_NOTIFICATION_MODAL_CONFIG_EVENT,
        setModalConfig
      )
      PubSub.unsubscribe(SHOW_UNIVERSAL_NOTIFICATION_MODAL_EVENT, openModal)
      PubSub.unsubscribe(HIDE_UNIVERSAL_NOTIFICATION_MODAL_EVENT, closeModal)
    }
  }, [])

  if (!isVisible) return null

  return (
    <div className={css.UniversalNotificationModal}>
      {modalConfig?.renderContent && modalConfig?.renderContent(closeModal)}
      <div
        aria-label="Close Modal"
        className={css.UniversalNotificationModal__background}
        role="button"
        tabIndex={0}
        onClick={closeModal}
        onKeyDown={closeModal}
      />
    </div>
  )
}

export default withContextualizedTracking()(UniversalNotificationModal)
