import {
  ADD_FEED_ITEMS,
  SET_FEED_ITEMS,
  SET_FEED_SESSION,
  SET_IS_FETCHING_FEED,
  SET_HAS_MORE_FEED_ITEMS,
} from 'registry/actions/feed-actions'

export const initialState = {
  feedItems: [],
  hasMoreFeedItems: true,
  isFetchingFeed: false,
  sessionNumber: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FEED_ITEMS:
      return { ...state, feedItems: action.feedItems }
    case ADD_FEED_ITEMS:
      return {
        ...state,
        feedItems: [...state.feedItems, ...action.feedItems],
      }
    case SET_FEED_SESSION:
      return { ...state, sessionNumber: parseInt(action.sessionNumber) }
    case SET_IS_FETCHING_FEED:
      return { ...state, isFetchingFeed: action.isFetchingFeed }
    case SET_HAS_MORE_FEED_ITEMS:
      return { ...state, hasMoreFeedItems: action.hasMoreFeedItems }
    default:
      return state
  }
}

export const getFeedItems = (state) => state.feedItems

export const getFeedSessionNumber = (state) => state.sessionNumber

export const getIsFetchingFeed = (state) => state.isFetchingFeed

export const getHasMoreFeedItems = (state) => state.hasMoreFeedItems
