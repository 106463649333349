import React from 'react'

interface LocationProps {
  dangerouslyOverrideClassName?: string
  filled?: boolean
}

const Location: React.FC<LocationProps> = ({
  dangerouslyOverrideClassName,
  filled,
}: LocationProps) => (
  <svg
    className={dangerouslyOverrideClassName}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {filled ? (
      <path
        clipRule="evenodd"
        d="M3.25 10.2051C3.25 5.27736 7.14972 1.25 12 1.25C16.8503 1.25 20.75 5.27736 20.75 10.2051C20.75 13.172 19.5352 16.2677 17.8969 18.6157C17.0747 19.7942 16.127 20.8119 15.1374 21.542C14.1571 22.2653 13.0752 22.75 12 22.75C10.9248 22.75 9.84286 22.2653 8.86262 21.542C7.87303 20.8119 6.92534 19.7942 6.10306 18.6157C4.4648 16.2677 3.25 13.172 3.25 10.2051ZM12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
        fill="#252324"
        fillRule="evenodd"
      />
    ) : (
      <path
        clipRule="evenodd"
        d="M12 2.75C8.01372 2.75 4.75 6.06976 4.75 10.2051C4.75 12.7954 5.82607 15.5972 7.33323 17.7574C8.08367 18.8329 8.92234 19.722 9.75321 20.335C10.5934 20.955 11.3661 21.25 12 21.25C12.6339 21.25 13.4066 20.955 14.2468 20.335C15.0777 19.722 15.9163 18.8329 16.6668 17.7574C18.1739 15.5972 19.25 12.7954 19.25 10.2051C19.25 6.06976 15.9863 2.75 12 2.75ZM3.25 10.2051C3.25 5.27736 7.14972 1.25 12 1.25C16.8503 1.25 20.75 5.27736 20.75 10.2051C20.75 13.172 19.5352 16.2677 17.8969 18.6157C17.0747 19.7942 16.127 20.8119 15.1374 21.542C14.1571 22.2653 13.0752 22.75 12 22.75C10.9248 22.75 9.84286 22.2653 8.86262 21.542C7.87303 20.8119 6.92534 19.7942 6.10306 18.6157C4.4648 16.2677 3.25 13.172 3.25 10.2051ZM12 7.75C10.7574 7.75 9.75 8.75736 9.75 10C9.75 11.2426 10.7574 12.25 12 12.25C13.2426 12.25 14.25 11.2426 14.25 10C14.25 8.75736 13.2426 7.75 12 7.75ZM8.25 10C8.25 7.92893 9.92893 6.25 12 6.25C14.0711 6.25 15.75 7.92893 15.75 10C15.75 12.0711 14.0711 13.75 12 13.75C9.92893 13.75 8.25 12.0711 8.25 10Z"
        fill="#252324"
        fillRule="evenodd"
      />
    )}
  </svg>
)

export default Location
