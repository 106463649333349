import { createRef, Component } from 'react'
import classNames from 'classnames'
import css from '../product-card/product-card.scss'
import cssGhost from './product-card-ghost.scss'

class ProductCardGhost extends Component {
  constructor(props) {
    super(props)
    this.productCardRef = createRef()
  }

  render() {
    return (
      <div
        className={classNames(
          this.props.className,
          cssGhost.productCardGhost,
          css.productCard
        )}
        ref={this.productCardRef}
      >
        <div className={classNames(css.imageWrapper, cssGhost.imageWrapper)}>
          <div className={cssGhost.shimmer} />
          <div className={cssGhost.image} />
        </div>
        <div className={css.contentWrapper}>
          <div
            className={classNames(
              css.brand,
              cssGhost.brand,
              cssGhost.bar,
              cssGhost.shimmer,
              'mbs'
            )}
          />
          <div
            className={classNames(
              css.title,
              cssGhost.title,
              cssGhost.bar,
              cssGhost.shimmer,
              'mbs'
            )}
          />
          <div
            className={classNames(
              cssGhost.bar,
              cssGhost.price,
              cssGhost.shimmer,
              'mbs'
            )}
          />
          <div
            className={classNames(
              css.availabilityMessage,
              cssGhost.bar,
              cssGhost.shimmer
            )}
          />
        </div>
      </div>
    )
  }
}
export default ProductCardGhost
