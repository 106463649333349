import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Modal } from 'react-overlays'
import Image from 'components/image'
import { CloseButton } from 'components/buttons'

import { Backdrop } from './modal'
import css from './group-gift-modal.scss'

export const GroupGiftModal = ({
  children,
  title,
  onHide,
  backdropProps = {},
  ...modalProps
}) => {
  return (
    <Modal
      renderBackdrop={(props) => <Backdrop {...props} {...backdropProps} />}
      className={css.modal}
      {...modalProps}
    >
      <div className={css.container}>
        <div className={classNames(css.contentWrapper, 'animated nudgeInUp')}>
          <div className={css.headerContent}>
            <Image
              className={css.giftIcon}
              src="//images.babylist.com/image/upload/v1595875684/gift_ffgmzh.svg"
              role="presentation"
            />
            <div className={css.roundedTop}>
              <CloseButton className="mtl mrl" onClick={onHide} />
            </div>
          </div>
          <div className={classNames(css.giftContent)}>
            <div className="text-center h4 mtm mbl lh-md">{title}</div>
            {children}
          </div>
        </div>
      </div>
    </Modal>
  )
}

GroupGiftModal.propTypes = {
  children: PropTypes.node,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

GroupGiftModal.defaultProps = {
  children: undefined,
  show: false,
  onHide: () => {},
}

export default GroupGiftModal
