/* eslint-disable */
import { ContributorGiftCardRegItemCard } from './gift-card-reg-item-card/contributor-gift-card-reg-item-card'
import { ReservableGiftCardRegItemCard } from './gift-card-reg-item-card/reservable-gift-card-reg-item-card'

export const GiftCardRegItemCard = (props) => {
  return (
    <>
      {props.isContributorView ? (
        <ContributorGiftCardRegItemCard {...props} />
      ) : (
        <ReservableGiftCardRegItemCard {...props} />
      )}
    </>
  )
}

export default GiftCardRegItemCard
