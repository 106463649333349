import { ReactNode } from 'react'
import CartItemAddedToast from 'shared/CartItemAddedToast/CartItemAddedToast'
import { showToast } from 'shared/toast/showToast'
import { Product } from 'src/types/product'

interface ShowCartItemAddedToastProps {
  product: Pick<Product, 'name' | 'images'>
  image?: ReactNode
  timeout?: number
}

export const showCartItemAddedToast = ({
  product,
  image,
  timeout = 10000,
}: ShowCartItemAddedToastProps) =>
  showToast({
    render: (isOpen, handleDismiss) => (
      <CartItemAddedToast
        image={image}
        isOpen={isOpen}
        product={product}
        onDismiss={handleDismiss}
      />
    ),
    timeout,
  })
