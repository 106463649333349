import classNames from 'classnames'
import css from './curves.scss'

const Wave = ({ color = '#02B496', position }: WaveProps) => (
  <svg
    className={classNames(position === 'bottom' ? css.waveBottom : css.wave)}
    viewBox="0 0 1230 111"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M0,0.50390625 C61.0807292,46.3372396 153.278646,71.7734375 276.59375,  76.8125 C461.566406,84.3710938 593.441406,54.8984375 758.800781,38.6582031  C924.160156,22.4179688 1132.29687,26.96875 1230,110.570313 L0,110.570313 L0, 0.50390625 Z"
        fill={color}
      />
    </g>
  </svg>
)

interface WaveProps {
  color?: string
  position?: string
}

const Bowed = ({
  color = '#ffffff',
  inverted = false,
  position,
}: BowedProps) => {
  const styles: Array<'bowedBottom' | 'bowedInverse'> = []
  if (position === 'bottom') styles.push('bowedBottom')
  if (inverted) styles.push('bowedInverse')

  return (
    <svg
      className={classNames(
        styles.length ? styles.map((style) => css[style]) : css.bowed
      )}
      viewBox="0 0 1632 160"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="m0 4423.20703c226.015625 115.91406 542.398437 167.99479 949.148437 156.24219 315.351563-12.4349 542.968753-38.20478 682.851563-77.30966v80.86044h-1632z"
        fill={color}
        fillRule="evenodd"
        transform="translate(0 -4423)"
      />
    </svg>
  )
}

interface BowedProps {
  color?: string
  position?: string
  inverted?: boolean
}

export { Wave, Bowed }
