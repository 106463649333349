import PropTypes from 'prop-types'
import { regItemProps } from '../reg-item-props'
import ContributorProductRegItemCard from './contributor-product-reg-item-card'
import ReservableProductRegItemCard from './reservable-product-reg-item-card'

export const ProductRegItemCard = ({
  giftStatusContent,
  isContributorView,
  regItem,
  reservationByCurrentVisitor,
}) =>
  isContributorView ? (
    <ContributorProductRegItemCard
      giftStatusContent={giftStatusContent}
      regItem={regItem}
    />
  ) : (
    <ReservableProductRegItemCard
      giftStatusContent={giftStatusContent}
      regItem={regItem}
      reservationByCurrentVisitor={reservationByCurrentVisitor}
    />
  )

ProductRegItemCard.propTypes = {
  isContributorView: PropTypes.bool,
  giftStatusContent: PropTypes.string,
  regItem: PropTypes.shape(regItemProps).isRequired,
  reservationByCurrentVisitor: PropTypes.object,
}

ProductRegItemCard.defaultProps = {
  giftStatusContent: null,
  isContributorView: false,
  reservationByCurrentVisitor: null,
}

export default ProductRegItemCard
