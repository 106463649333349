import PropTypes from 'prop-types'
import classNames from 'classnames'
import { times } from 'lodash'
import styleMaps from 'shared/style-maps'

const HeartRatingIcon = ({ style, filled }) => {
  return (
    <div style={style}>
      <svg
        width="14"
        height="12"
        viewBox="0 0 352 288"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Heart</title>
        <path
          fill={filled ? styleMaps.COLORS.hotPink : '#f2f2f2'}
          d="M0.280373832,83.0411126 C2.20101823,57.1939364 14.0429856,35.3573054 36.9766355,18.8411215 C66.6542056,-2.53271028 99.0841121,-4.63084112 132.285047,10.728972 C153.065421,20.3411215 166.46729,36.5420561 175.766355,56.3271028 L175.82243,56.3271028 C185.121495,36.5420561 198.523364,20.3411215 219.303738,10.728972 C252.504673,-4.63084112 284.934579,-2.53271028 314.61215,18.8411215 C343.873832,39.911215 355.074766,69.6401869 350.658879,105.308411 C344.953271,151.415888 319.546729,186.285047 286.514019,216.542056 C253.700935,246.607477 215.929907,269.168224 175.799065,287.96729 L175.78972,287.96729 C135.654206,269.168224 97.8878505,246.607477 65.0747664,216.542056 C32.0420561,186.285047 6.63551402,151.415888 0.929906542,105.308411 C0.650772925,103.053779 0.434057446,100.822879 0.28037383,98.6163967 L0.280373832,83.0411126 Z"
        />
      </svg>
    </div>
  )
}

HeartRatingIcon.propTypes = {
  style: PropTypes.string,
  filled: PropTypes.bool,
}

HeartRatingIcon.defaultProps = {
  style: undefined,
  filled: false,
}

// The TurnTo stars round between X.25 and X.75. Between X.25 and X.75,
// we display a half star. Below X.25, we show X stars, and above X.75
// we show X+1 stars. In other words So 4.25 would show four and a half stars.
// 4.75 would show five full stars.
const roundingThreshold = { min: 0.25, max: 0.75 }

const HeartRating = ({ className, rating, reviewCount }) => {
  const hearts = times(5, (idx) => {
    let icon

    // Full heart
    if (rating >= idx + roundingThreshold.max) {
      icon = <HeartRatingIcon filled />
    }
    // Half-heart
    else if (
      rating >= idx + roundingThreshold.min &&
      rating < idx + roundingThreshold.max
    ) {
      icon = (
        <span style={{ position: 'relative' }}>
          <HeartRatingIcon style={{ position: 'absolute' }} />
          <HeartRatingIcon
            filled
            style={{
              position: 'relative',
              width: '50%',
              overflow: 'hidden',
              zIndex: 1,
            }}
          />
        </span>
      )
    }
    // No heart
    else {
      icon = <HeartRatingIcon />
    }

    return (
      <span key={idx} style={{ marginRight: 3 }}>
        {icon}
      </span>
    )
  })

  return (
    <div className={classNames(className, 'flex align-center')}>
      <span className="visually-hidden">Average rating: {rating} out of 5</span>
      <span aria-hidden className="flex align-center">
        {hearts}
      </span>
      {reviewCount ? (
        <>
          <span className="visually-hidden">Total reviews: {reviewCount}.</span>
          <span aria-hidden className="h7 mls text-muted">
            {reviewCount}
          </span>
        </>
      ) : null}
    </div>
  )
}

HeartRating.propTypes = {
  className: PropTypes.string,
  rating: PropTypes.number.isRequired,
  reviewCount: PropTypes.number.isRequired,
}

HeartRating.defaultProps = {
  className: undefined,
}

export default HeartRating
