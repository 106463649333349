import React from 'react'

import { Document } from '@contentful/rich-text-types'
import { bestBabyProductsPath, showroomPath } from 'lib/urls'
import css from './WhyTrustUsSection.styles.scss'
import RichTextBody from '../../RichTextBody/RichTextBody'

interface PostEmbedWhyTrustUsSectionFields {
  howWePicked?: Document
}

export interface WhyTrustUsSectionProps {
  fields: PostEmbedWhyTrustUsSectionFields
}

const WhyTrustUsSection: React.FC<WhyTrustUsSectionProps> = ({
  fields,
}: WhyTrustUsSectionProps) => {
  const { howWePicked } = fields

  return (
    <section className={css.WhyTrustUsSection}>
      <h2 className={css.WhyTrustUsSection__header}>Why Trust Babylist?</h2>
      <p>
        Babylist is the expert in baby; we know baby gear inside and out. We
        talk with parents face-to-face in our{' '}
        <a href={showroomPath}>showroom</a> and on our user panels and hear from
        thousands annually via our{' '}
        <a href={bestBabyProductsPath}>Best Baby Products</a> online survey. We
        know what items millions of expecting parents are adding to their baby
        registries.
      </p>
      {howWePicked && (
        <div>
          <RichTextBody richTextBody={howWePicked as Document} />
        </div>
      )}
    </section>
  )
}

export default WhyTrustUsSection
