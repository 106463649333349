import classNames from 'classnames'
import PropTypes from 'prop-types'
import { RegistryProfileAltIcon, RegistryProfileIcon } from 'components/icons'
import Image from 'components/image'
import Clickable from 'components/clickable'

import css from './registry-avatar.scss'

export const EmptyProfile = ({ editable, onEdit }) => {
  return editable ? (
    <Clickable onClick={onEdit}>
      <div
        className={classNames(css.avatar, {
          [css.editableProfileIcon]: editable,
        })}
      >
        <RegistryProfileIcon
          className={classNames(css.emptyProfileIcon, {
            [css.avatar]: !editable,
          })}
          width="100%"
        />

        <div className={css.link}>Add Photo</div>
      </div>
    </Clickable>
  ) : (
    <RegistryProfileAltIcon
      className={classNames(css.emptyProfileIcon, css.avatar, 'img-responsive')}
      svgClassName={css.registryProfile}
    />
  )
}

EmptyProfile.propTypes = {
  editable: PropTypes.bool,
  onEdit: PropTypes.func,
}

EmptyProfile.defaultProps = {
  editable: false,
}

export const PopulatedProfile = ({ editable, url, onEdit, altText }) => {
  const profileImage = (
    <Image
      alt={altText}
      className={classNames(css.avatar, 'img-responsive')}
      src={url}
      lazyLoad={false}
    />
  )

  return editable ? (
    <Clickable onClick={onEdit}>
      <div className="hover-overlay hover-lighten">{profileImage}</div>
    </Clickable>
  ) : (
    profileImage
  )
}

PopulatedProfile.propTypes = {
  editable: PropTypes.bool,
  url: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  altText: PropTypes.string.isRequired,
}

PopulatedProfile.defaultProps = {
  editable: false,
  onEdit: () => {},
}

const RegistryAvatar = ({ className, editable, url, onEdit, altText }) => {
  return (
    <div className={className}>
      {url ? (
        <PopulatedProfile
          editable={editable}
          url={url}
          onEdit={onEdit}
          altText={altText}
        />
      ) : (
        <EmptyProfile editable={editable} onEdit={onEdit} />
      )}
    </div>
  )
}

RegistryAvatar.propTypes = {
  className: PropTypes.string,
  editable: PropTypes.bool,
  url: PropTypes.string,
  onEdit: PropTypes.func,
  altText: PropTypes.string,
}

RegistryAvatar.defaultProps = {
  className: '',
  editable: false,
  url: null,
  onEdit: () => {},
  altText: 'Babylist Registry Photo',
}

export default RegistryAvatar
