import React from 'react'

interface DragHandleProps {
  className?: string
}

const DragHandle: React.FC<DragHandleProps> = ({
  className,
}: DragHandleProps) => (
  <svg
    className={className}
    fill="none"
    height="21"
    viewBox="0 0 20 21"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.10039 2.7002C5.43765 2.7002 4.90039 3.23745 4.90039 3.9002V5.1002C4.90039 5.76294 5.43765 6.3002 6.10039 6.3002H7.30039C7.96313 6.3002 8.50039 5.76294 8.50039 5.1002V3.9002C8.50039 3.23745 7.96313 2.7002 7.30039 2.7002H6.10039Z"
      fill="#B2AEB0"
    />
    <path
      d="M6.10039 8.7002C5.43765 8.7002 4.90039 9.23745 4.90039 9.9002V11.1002C4.90039 11.7629 5.43765 12.3002 6.10039 12.3002H7.30039C7.96313 12.3002 8.50039 11.7629 8.50039 11.1002V9.9002C8.50039 9.23745 7.96313 8.7002 7.30039 8.7002H6.10039Z"
      fill="#B2AEB0"
    />
    <path
      d="M4.90039 15.9002C4.90039 15.2375 5.43765 14.7002 6.10039 14.7002H7.30039C7.96313 14.7002 8.50039 15.2375 8.50039 15.9002V17.1002C8.50039 17.7629 7.96313 18.3002 7.30039 18.3002H6.10039C5.43765 18.3002 4.90039 17.7629 4.90039 17.1002V15.9002Z"
      fill="#B2AEB0"
    />
    <path
      d="M12.7004 2.7002C12.0376 2.7002 11.5004 3.23745 11.5004 3.9002V5.1002C11.5004 5.76294 12.0376 6.3002 12.7004 6.3002H13.9004C14.5631 6.3002 15.1004 5.76294 15.1004 5.1002V3.9002C15.1004 3.23745 14.5631 2.7002 13.9004 2.7002H12.7004Z"
      fill="#B2AEB0"
    />
    <path
      d="M11.5004 9.9002C11.5004 9.23745 12.0376 8.7002 12.7004 8.7002H13.9004C14.5631 8.7002 15.1004 9.23745 15.1004 9.9002V11.1002C15.1004 11.7629 14.5631 12.3002 13.9004 12.3002H12.7004C12.0376 12.3002 11.5004 11.7629 11.5004 11.1002V9.9002Z"
      fill="#B2AEB0"
    />
    <path
      d="M12.7004 14.7002C12.0376 14.7002 11.5004 15.2375 11.5004 15.9002V17.1002C11.5004 17.7629 12.0376 18.3002 12.7004 18.3002H13.9004C14.5631 18.3002 15.1004 17.7629 15.1004 17.1002V15.9002C15.1004 15.2375 14.5631 14.7002 13.9004 14.7002H12.7004Z"
      fill="#B2AEB0"
    />
  </svg>
)

export default DragHandle
