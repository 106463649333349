import { InfoButton, LinkButton } from 'components/buttons'

export const ConfirmAddressForm = ({
  onConfirm,
  onEdit,
  fieldErrors,
  enteredAddress: {
    name,
    line1,
    line2,
    streetAddress1,
    streetAddress2,
    city,
    state,
    country,
    zip,
  },
}) => (
  <div className="text-center">
    <p className="text-left">
      This address doesn't match postal records. Please check to make sure it's
      correct.
    </p>
    {fieldErrors.base ? (
      <p className="text-center small">{fieldErrors.base}</p>
    ) : null}
    <div className="mvl text-capitalize">
      <span>{name}</span>
      <br />
      <span
        className={
          (fieldErrors.address1 || fieldErrors.line1) && 'text-danger text-bold'
        }
      >
        {streetAddress1 || line1}
      </span>
      <br />
      <span
        className={
          (fieldErrors.address2 || fieldErrors.line2) && 'text-danger text-bold'
        }
      >
        {streetAddress2 || line2}
      </span>
      {(streetAddress2 || line2) && <br />}
      <span className={fieldErrors.city && 'text-danger text-bold'}>
        {city}
      </span>
      <span className={fieldErrors.state && 'text-danger text-bold'}>
        , {state}
      </span>
      <span className={fieldErrors.zip && 'text-danger text-bold'}> {zip}</span>
      <br />
      <span>{country}</span>
    </div>
    <InfoButton className="mbm" block onClick={onEdit}>
      Edit Your Address
    </InfoButton>
    <LinkButton onClick={onConfirm}>
      <small>I'm sure this address is correct</small>
    </LinkButton>
  </div>
)
