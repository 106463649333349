import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setBookmarkletAsUsed } from '../../actions/index'
import Modal from 'components/modal/modal'
import { ModalBody } from 'react-bootstrap'
import Link from 'components/link'
import { InfoButton } from 'components/buttons'
import css from './bookmarklet-instructions-modal.scss'
import UsageInstructionsByBrowser from 'shared/add-babylist-button/usage-instructions-by-browser'

const BookmarkletUsageInstructionsModal = ({
  show,
  onHide,
  setBookmarkletAsUsed,
  url,
  storeDisplayName,
}) => {
  const handleClick = () => {
    setBookmarkletAsUsed(true)
    onHide()
  }

  return (
    <Modal show={show} onHide={onHide} title="Woot! You got it!">
      <ModalBody>
        <div className={css.topPanel}>
          <div className="h3">Now you're ready to rock!</div>
          <p>
            When you see something you like, just click on the Add to Babylist
            button to add it to your registry! Easy peasy.
          </p>

          <Link url={url} newWindow>
            <InfoButton onClick={handleClick}>
              Continue to {storeDisplayName}
            </InfoButton>
          </Link>
        </div>

        <hr className={css.line} />

        <UsageInstructionsByBrowser />
      </ModalBody>
    </Modal>
  )
}

BookmarkletUsageInstructionsModal.propTypes = {
  show: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  setBookmarkletAsInstalled: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  storeDisplayName: PropTypes.string.isRequired,
}

export default connect(undefined, { setBookmarkletAsUsed })(
  BookmarkletUsageInstructionsModal
)
