import { useState } from 'react'
import { fetchUnifiedSearchResults } from './fetchUnifiedSearchResults'
import { useDebounce } from 'lib/hooks'
import { useQuery } from '@tanstack/react-query'
import { UNIFIED_SEARCH_DEBOUNCE_DELAY } from 'shared/constants'
import { SearchResult } from '../types'
import { useTracking } from 'lib/analytics'
import { trackUnifiedSearchEvent } from '../SearchBar.utils'

const useSearch = (
  initialQuery: string,
  eventLocation: string,
  searchEnabled: boolean
) => {
  const [searchQuery, setSearchQuery] = useState(initialQuery)
  const debouncedSearchQuery = useDebounce(
    searchQuery,
    UNIFIED_SEARCH_DEBOUNCE_DELAY
  )

  const [searchResults, setSearchResults] = useState<SearchResult[]>([])

  const tracker = useTracking()
  const trackEvents = (events: any) => {
    events.forEach((event: any) => {
      trackUnifiedSearchEvent(event, tracker.trackEvent)
    })
  }

  const { isLoading } = useQuery({
    queryKey: ['unifiedSearch', debouncedSearchQuery, eventLocation],
    queryFn: () =>
      fetchUnifiedSearchResults(debouncedSearchQuery, eventLocation),
    enabled: searchEnabled && debouncedSearchQuery?.length >= 3,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setSearchResults(data.results)
      trackEvents(data.events)
    },
  })

  const updateSearchQuery = (newQuery: string) => {
    setSearchQuery(newQuery)
  }

  const clearSearchQuery = () => {
    setSearchQuery('')
  }

  return {
    searchResults,
    searchQuery,
    isLoading,
    updateSearchQuery,
    clearSearchQuery,
  }
}

export default useSearch
