import PropTypes from 'prop-types'
import { StoreOfferIconStack } from 'components/icons'

const StoreOffersButton = ({ onClick, storeOfferIconNames }) => (
  <button
    type="button"
    className="pan"
    onClick={onClick}
    aria-label="View buying options"
    style={{ background: 'none', border: 'none' }}
  >
    <StoreOfferIconStack storeOfferIconNames={storeOfferIconNames} />
  </button>
)

StoreOffersButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  storeOfferIconNames: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default StoreOffersButton
