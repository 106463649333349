import PropTypes from 'prop-types'
import Card from 'components/card'

interface OfferCardProps {
  isMobile: boolean | undefined
  children: any | undefined
}

export const OfferCard = ({ isMobile, children }: OfferCardProps) => {
  if (!isMobile) {
    // @ts-ignore
    return <Card className="pal">{children}</Card>
  }
  return <div className="mtl">{children}</div>
}

OfferCard.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  children: PropTypes.node,
}
