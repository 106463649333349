import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ModalBody, MultiStepModal } from 'components/modal'
import {
  MultiStepWizard,
  TwoColumnResponsiveLayout,
} from 'components/presentation'
import RegItem from 'cart/components/reg-item'
import { RadioGroup, Radio } from 'components/forms'
import PurchaseInfoForm from 'cart/components/purchase-info-form'
import GiftMessageForm from 'cart/components/gift-message-form'
import withBlinkyTitleBar from 'components/with-blinky-title-bar'

class PurchaseInfoModal extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: 1,
      alreadyPurchased: props.reservation?.isPurchased || false,
      // Only autofill the storeName inputed by the user.
      selectedStoreName: props.reservation?.storeName
        ? props.reservation.storeName
        : null,
    }
  }

  renderTitle = () => {
    const { activeStep } = this.state
    let title = null

    switch (activeStep) {
      case 2:
        title = 'Personalize Gift Message'
        break
      default:
        title = ''
    }
    return title
  }

  render() {
    const {
      autofillStore,
      show,
      onHide,
      regItem,
      reservation,
      registry,
      onMarkAsPurchased,
      onSubmitGiftMessage,
      giftMessage,
      needsGiftMessage,
    } = this.props
    const { activeStep, alreadyPurchased, selectedStoreName } = this.state

    return (
      <MultiStepModal
        title={this.renderTitle()}
        show={show}
        onHide={onHide}
        size={activeStep === 1 ? 'large' : 'small'}
        affixedFooter={false}
      >
        <ModalBody>
          <MultiStepWizard activeStep={activeStep} animateInitialStep={false}>
            {/* Step 1 */}
            <TwoColumnResponsiveLayout>
              <RegItem className="mbl" regItem={regItem} registry={registry} />
              <>
                {!alreadyPurchased ? (
                  <RadioGroup
                    className="mbl"
                    title="Did you already purchase this gift?"
                  >
                    <Radio
                      block
                      label="Yes"
                      name="alreadyPurchased"
                      onChange={() => {
                        onMarkAsPurchased({ isPurchased: true })
                        this.setState({ alreadyPurchased: true })
                      }}
                      tappable
                    />
                    <Radio
                      block
                      label="No"
                      name="alreadyPurchased"
                      onChange={onHide}
                      tappable
                    />
                  </RadioGroup>
                ) : null}
                {alreadyPurchased ? (
                  <PurchaseInfoForm
                    autofillStore={autofillStore}
                    formKey={`${reservation.id}-reserved`}
                    reservation={reservation}
                    registry={registry}
                    onSubmit={(e) => {
                      onMarkAsPurchased(e)
                      if (needsGiftMessage) {
                        this.setState({ activeStep: 2 })
                      } else {
                        onHide()
                      }
                    }}
                    submitBtnText="Save"
                    hideStoreInput={false}
                    storeName={selectedStoreName}
                  />
                ) : null}
              </>
            </TwoColumnResponsiveLayout>

            {/* Step 2 */}
            {needsGiftMessage ? (
              <GiftMessageForm
                formKey="giftMessage"
                giftMessage={giftMessage}
                onSubmit={onSubmitGiftMessage}
                onCancel={onHide}
                submitText="Add Gift Message"
                cancelText="Maybe Later"
              />
            ) : null}
          </MultiStepWizard>
        </ModalBody>
      </MultiStepModal>
    )
  }
}

PurchaseInfoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  regItem: PropTypes.object.isRequired,
  reservation: PropTypes.object.isRequired,
  onMarkAsPurchased: PropTypes.func.isRequired,
  autofillStore: PropTypes.bool,
  needsGiftMessage: PropTypes.bool.isRequired,
  giftMessage: PropTypes.string,
  registry: PropTypes.object.isRequired,
  onSubmitGiftMessage: PropTypes.func.isRequired,
}

PurchaseInfoModal.defaultProps = {
  autofillStore: true,
}

export default withBlinkyTitleBar(PurchaseInfoModal)
