import { FC } from 'react'
import { GROUP_GIFT_ACTIONS } from 'registry/constants'
import { InfoButton } from 'components/buttons'
import { GroupGiftAction } from '../../GroupGiftRedemptionModal'

interface CTAButtonProps {
  action: GroupGiftAction
  disabled: boolean
  isBabylistProductDiscontinued: boolean
  hasUnredeemedFunds: boolean
  onClick: () => void
  submitting: boolean
}

const CTAButton: FC<CTAButtonProps> = ({
  action,
  disabled,
  isBabylistProductDiscontinued,
  hasUnredeemedFunds,
  onClick,
  submitting,
}) => {
  let ctaText = `${
    hasUnredeemedFunds ? 'Apply Funds and ' : ''
  }Continue to Cart`

  if (isBabylistProductDiscontinued || action === GROUP_GIFT_ACTIONS.DELETE) {
    ctaText = `${hasUnredeemedFunds ? 'Apply Funds and ' : ''}Remove Item`
  }

  if (action === GROUP_GIFT_ACTIONS.DISABLE) {
    ctaText = `${
      hasUnredeemedFunds ? 'Apply Funds and ' : ''
    }Disable Group Gifting`
  }

  if (action === GROUP_GIFT_ACTIONS.QUICKADD) {
    ctaText = `${hasUnredeemedFunds ? 'Apply Funds and ' : ''}Add to Cart`
  }

  return (
    <InfoButton
      pill
      disabled={disabled}
      submitting={submitting}
      onClick={onClick}
    >
      {ctaText}
    </InfoButton>
  )
}

export default CTAButton
