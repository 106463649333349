import { combineReducers } from 'redux'
import { keyBy } from 'lodash'

import { SET_INSERT_CARD_ORDERS } from '../actions'

const byId = (state = {}, action) => {
  switch (action.type) {
    case SET_INSERT_CARD_ORDERS:
      return keyBy(action.insertCardOrders, 'id')
    default:
      return state
  }
}

const allIds = (state = [], action) => {
  switch (action.type) {
    case SET_INSERT_CARD_ORDERS:
      return action.insertCardOrders.map(
        (insertCardOrder) => insertCardOrder.id
      )
    default:
      return state
  }
}

const insertCardOrders = combineReducers({
  byId,
  allIds,
})

export default insertCardOrders

export const getInsertCardOrders = (state) =>
  state.allIds.map((id) => state.byId[id])
