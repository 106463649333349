const CategoryFeeding = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 258.35 258.35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M191.5,155.76c9.49,1.9,14,10.78,12.16,20.31s-9.27,16.11-18.79,14.39l-.47,2.45a11,11,0,0,1-12.77,8.67l-14.86-2.84a10.86,10.86,0,0,1-7.84-6.14,35.69,35.69,0,0,0,20.68-32.28v-3a2,2,0,0,0-2-2h-2.59c-.46-1.87-1.19-5.07-2-8.53l29,5.54h.08c0,.13,0,.25-.06.38Zm5.79,19c1-5.36-1.08-10.27-6.41-11.36l-3.73,19.55C192.5,183.85,196.27,180.06,197.29,174.71Z"
      fill="#d8d8d8"
    />
    <path
      d="M201.06,112.67a1.67,1.67,0,0,1-.88,2.19l-11.25,4.79a12.46,12.46,0,0,0-7.37,9.14l-2,10.49-7.3-1.39,2.17-11.37a18.27,18.27,0,0,1,10.78-13.37L197.27,108a1.66,1.66,0,0,1,2.18.88Z"
      fill="#d8d8d8"
    />
    <path
      d="M172.25,137.89l7.3,1.39,3.85.74a10.94,10.94,0,0,1,8.73,12.39h-.08l-29-5.54-.24-1.07a58.64,58.64,0,0,1-1.2-7.66,10.93,10.93,0,0,1,6.89-.95Z"
      fill="#d8d8d8"
    />
    <path
      d="M169.61,157.35v3a35.69,35.69,0,0,1-20.68,32.28,10.81,10.81,0,0,1-.83-6.63l.47-2.46h-.1c-9.58-1.84-14.16-10.76-12.32-20.35a20.65,20.65,0,0,1,3.15-7.76h28.35A2,2,0,0,1,169.61,157.35Zm-20.45,18.56,3.74-19.54-.08,0c-5.11-1-8.83,2.38-10.09,7.32-.07.28-.13.56-.19.85-1,5.4,1.14,10.35,6.54,11.38Z"
      fill="#d8d8d8"
    />
    <path
      d="M165.06,155.39H139.3a15.12,15.12,0,0,1,15.81-6.59l.1,0,.3-1.61.26-1.36c0-.13,0-.25.08-.38l.05,0,7.19,1.37C163.87,150.32,164.6,153.52,165.06,155.39Z"
      fill="#d8d8d8"
    />
    <path
      d="M163.09,146.86l-7.19-1.37-.05,0a10.92,10.92,0,0,1,5.08-6.95q.36-.21.72-.39a58.64,58.64,0,0,0,1.2,7.66Z"
      fill="#d8d8d8"
    />
    <path
      d="M148.93,192.6a35.29,35.29,0,0,1-14.88,3.28H93.85a35.66,35.66,0,0,1-35.56-35.56v-3a2,2,0,0,1,2-2h2.59"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M161.65,138.13a61.64,61.64,0,0,1,1-18.22c2.11-10.15,4-24.81.52-35.21-5.89-17.43-30.43-29.43-40.85-28-3.89.53-5.64,2.31-6.31,4.33a.06.06,0,0,0,0,0,6.37,6.37,0,0,0,4.72,8.09c12.17,2.84,21.18,13.07,21.18,25.26a24.24,24.24,0,0,1-1.43,8.22c0,.06,0,.12-.07.19a26.55,26.55,0,0,1-11.95,13.83,29.19,29.19,0,0,1-10.17,3.47,30.47,30.47,0,0,1-4.31.31h-.11a30.72,30.72,0,0,1-4.32-.31,29.26,29.26,0,0,1-10.17-3.47,26.59,26.59,0,0,1-12-13.83,1.07,1.07,0,0,0-.07-.19A24.48,24.48,0,0,1,86,94.42C86,82.23,95,72,107.15,69.16a6.38,6.38,0,0,0,4.72-8.09.06.06,0,0,1,0,0c-.67-2-2.42-3.8-6.31-4.33-10.43-1.43-35,10.57-40.85,28-3.51,10.4-1.6,25.06.52,35.21a61.36,61.36,0,0,1-.17,25.88c-.86,3.86-1.7,7.54-2.21,9.6H139.3"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M172.1,138.65l.15-.76,2.17-11.37a18.27,18.27,0,0,1,10.78-13.37L197.27,108a1.66,1.66,0,0,1,2.18.88l1.61,3.77a1.67,1.67,0,0,1-.88,2.19l-11.25,4.79a12.46,12.46,0,0,0-7.37,9.14l-2,10.49-.14.77"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M148.93,192.6a10.86,10.86,0,0,0,7.84,6.14l14.86,2.84a11,11,0,0,0,12.77-8.67l.47-2.45c9.52,1.72,17-4.87,18.79-14.39S201,157.66,191.5,155.76l.57-3c0-.13.05-.25.06-.38A10.94,10.94,0,0,0,183.4,140l-3.85-.74-7.3-1.39-3.71-.71a10.93,10.93,0,0,0-6.89.95q-.36.18-.72.39a10.92,10.92,0,0,0-5.08,6.95c0,.13,0,.25-.08.38l-.26,1.36-.3,1.61-.1,0a15.12,15.12,0,0,0-15.81,6.59,20.65,20.65,0,0,0-3.15,7.76c-1.84,9.59,2.74,18.51,12.32,20.35h.1L148.1,186A10.81,10.81,0,0,0,148.93,192.6Z"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M142.73,163.67c1.26-4.94,5-8.29,10.09-7.32l.08,0-3.74,19.54h-.08c-5.4-1-7.57-6-6.54-11.38C142.6,164.23,142.66,164,142.73,163.67Z"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M197.29,174.71c-1,5.35-4.79,9.14-10.14,8.19l3.73-19.55C196.21,164.44,198.31,169.35,197.29,174.71Z"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <polyline
      fill="none"
      points="155.9 145.49 163.09 146.85 192.05 152.4"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
  </svg>
)

export default CategoryFeeding
