import { MouseEventHandler } from 'react'
import { MenuItem } from 'react-bootstrap'
import { TextLink } from 'baby-design'
import Chip, { ChipVariants } from 'components/Chip'

import css from './AccountMenuItem.styles.scss'

export interface AccountMenuItemProps {
  href?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  label?: string
  chipLabel?: string
  chipVariant?: (typeof ChipVariants)[keyof typeof ChipVariants]
  type?: string
}

export const AccountMenuItem = ({
  href,
  onClick,
  label,
  chipLabel,
  chipVariant = ChipVariants.DEFAULT,
  type,
}: AccountMenuItemProps) => {
  if (type === 'divider') {
    return <MenuItem divider />
  }
  return (
    <li className={css.AccountMenuItem}>
      <TextLink
        className={css.AccountMenuItem__link}
        href={href}
        size="sm"
        onClick={onClick}
      >
        <span className="flex flex-grow">{label}</span>
        {chipLabel && (
          <Chip
            className="mlm"
            label={chipLabel}
            size="small"
            variant={chipVariant}
          />
        )}
      </TextLink>
    </li>
  )
}
