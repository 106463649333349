interface ElapsedTimeProps {
  since: Date | string
}

const timeUnits = {
  y: 31556952000,
  mo: 2629746000,
  w: 604800000,
  d: 86400000,
  h: 3600000,
  m: 60000,
  s: 1000,
}

const ElapsedTime = ({ since }: ElapsedTimeProps) => {
  const getElapsedTime = () => {
    const start =
      typeof since === 'string' ? Date.parse(since) : since.getTime()
    const timeElapsed = Date.now() - start

    const conversions = Object.entries(timeUnits)
      .map(([unit, millis]) => {
        const units = Math.floor(timeElapsed / millis)
        return units > 0 ? `${units}${unit}` : null
      })
      .filter(Boolean)

    return conversions.length > 0 ? conversions.shift() : 'now'
  }

  return <p data-testid="elapsed-time">{getElapsedTime()}</p>
}

export default ElapsedTime
