import { cloneElement, Children, Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { keys } from 'lodash'

import BSNavbar from 'react-bootstrap/lib/Navbar'
import NavbarBrand from 'react-bootstrap/lib/NavbarBrand'
import NavbarCollapse from 'react-bootstrap/lib/NavbarCollapse'
import NavbarHeader from 'react-bootstrap/lib/NavbarHeader'
import NavbarToggle from 'react-bootstrap/lib/NavbarToggle'
import Nav from 'react-bootstrap/lib/Nav'
import NavDropdown from 'react-bootstrap/lib/NavDropdown'

import { Input } from 'components/forms'
import Link from 'components/link'

import css from './navbar.scss'

const COLLAPSE_CLASS = {
  xs: 'navbar-collapse-xs',
  sm: 'navbar-collapse-sm',
}

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: props.expanded,
      searchText: props.searchText,
    }
  }

  render() {
    const searchPlaceholder = this.props.searchPlaceholder || 'Search'

    return (
      <BSNavbar
        expanded={this.state.expanded}
        className={classNames(
          this.props.className,
          COLLAPSE_CLASS[this.props.collapseAtSize],
          { 'navbar-secondary': this.props.secondary }
        )}
        onToggle={this.toggleExpanded.bind(this)}
      >
        <div>
          {this.props.collapse ? (
            <NavbarHeader>
              {this.props.collapseToggleElement ? (
                <div className="navbar-custom-toggle">
                  {cloneElement(this.props.collapseToggleElement, {
                    onClick: this.toggleExpanded.bind(
                      this,
                      !this.state.expanded
                    ),
                  })}
                </div>
              ) : (
                <NavbarToggle
                  onClick={this.toggleExpanded.bind(this, !this.state.expanded)}
                />
              )}
            </NavbarHeader>
          ) : null}

          <NavbarCollapse
            className={classNames({
              'navbar-nav-centered': this.props.centerItems,
            })}
          >
            {this.props.hasSearch ? (
              <div
                className={
                  this.props.globalSearchBarEnabled && css.searchBarHidden
                }
              >
                <form
                  className="input-search"
                  onSubmit={this.handleSearch.bind(this)}
                >
                  <Input
                    addonBefore={<i className="fa fa-search" />}
                    aria-label={searchPlaceholder}
                    className={classNames(
                      this.props.searchClassName,
                      css.searchInput
                    )}
                    onChange={this.handleUpdateSearchText.bind(this)}
                    placeholder={searchPlaceholder}
                    value={this.state.searchText}
                  />
                </form>
              </div>
            ) : null}
            <Nav
              className={classNames('mbn', {
                'navbar-nav-centered': this.props.centerItems,
              })}
            >
              {this.props.collapseOnClick
                ? Children.map(this.props.children, (child) =>
                    cloneElement(child, {
                      onClick: this.toggleExpanded.bind(this, false),
                    })
                  )
                : this.props.children}
            </Nav>
          </NavbarCollapse>
        </div>
      </BSNavbar>
    )
  }

  handleSearch(event) {
    event.preventDefault()
    if (this.state.searchText) {
      this.props.onSearch(this.state.searchText)
    }
  }

  handleUpdateSearchText(event) {
    this.setState({ searchText: event.target.value })
  }

  toggleExpanded(expanded) {
    this.setState({ expanded })
  }
}

Navbar.propTypes = {
  className: PropTypes.string,
  collapse: PropTypes.bool,
  collapseOnClick: PropTypes.bool,
  collapseAtSize: PropTypes.oneOf(keys(COLLAPSE_CLASS)),
  collapseToggleElement: PropTypes.element,
  expanded: PropTypes.bool, // When menu is collapsed down for mobile, show or hide it
  hasSearch: PropTypes.bool,
  onSearch: PropTypes.func,
  searchClassName: PropTypes.string,
  searchText: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  secondary: PropTypes.bool,
  centerItems: PropTypes.bool,
  globalSearchBarEnabled: PropTypes.bool,
}

Navbar.defaultProps = {
  collapse: true,
  collapseAtSize: 'xs',
  collapseOnClick: false,
  expanded: false,
}

const NavItem = (props) => (
  <li
    className={classNames(props.className, { active: props.active })}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
  >
    {props.router ? (
      <Link onClick={props.onClick} to={props.to || props.href}>
        {props.children}
      </Link>
    ) : (
      <a href={props.href} onClick={props.onClick}>
        {props.children}
      </a>
    )}
  </li>
)

NavItem.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  router: PropTypes.bool,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.object,
    }),
  ]),
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
}

export default Navbar
export {
  NavbarBrand,
  NavbarCollapse,
  NavDropdown,
  NavbarHeader,
  NavbarToggle,
  Nav,
  NavItem,
}
