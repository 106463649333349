import { useEffect, useState } from 'react'
import css from './ProgressRing.styles.scss'

enum Variants {
  Primary = 'primary',
  Secondary = 'secondary',
}
export interface ProgressRingProps {
  label?: string
  progress?: number | 0
  variant?: Variants | string
}

export const ProgressRing = ({
  label,
  progress = 0,
  variant = 'primary',
}: ProgressRingProps) => {
  const [progressDisplay, setProgressDisplay] = useState(progress)
  const stroke = 12
  const radius = 42
  const normalizedRadius = radius - stroke / 2
  const circumference = normalizedRadius * 2 * Math.PI
  const trackLength = circumference / 1.64
  const strokeDashoffset = circumference - (progress / 100) * trackLength

  // Animate progress value
  useEffect(() => {
    const timer = setInterval(() => {
      setProgressDisplay((displayValue) => {
        // Stop animation when progress value is reached
        if (progress === displayValue) {
          clearInterval(timer)
          return progress
        }
        // Decrease display value by 1
        if (progress <= displayValue) {
          return displayValue - 1
        }
        // Increase display value by 1
        return displayValue + 1
      })
      return () => clearInterval(timer)
    }, 10)
  }, [progress])

  return (
    <span className={css.root}>
      <svg height={radius * 2} width={radius * 2}>
        <linearGradient id="primary">
          <stop offset="0%" stopColor="#FF7AAB" />
          <stop offset="100%" stopColor="#6E3264" />
        </linearGradient>
        <linearGradient id="secondary">
          <stop offset="0%" stopColor="#6E3264" />
          <stop offset="100%" stopColor="#00A3C7" />
        </linearGradient>
        <circle
          className={css.track}
          cx={radius}
          cy={radius}
          fill="none"
          r={normalizedRadius}
          stroke="#E9E8E8"
          strokeDasharray={trackLength}
          strokeWidth={stroke * 0.5}
        />
        <circle
          className={css.progress}
          cx={radius}
          cy={radius}
          fill="none"
          r={normalizedRadius}
          stroke={`url(#${variant})`}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeLinecap="round"
          strokeWidth={stroke}
          style={{ strokeDashoffset }}
        />
      </svg>
      <div className={css.progressText}>{progressDisplay}%</div>
      {label && <div className={css.label}>{label}</div>}
    </span>
  )
}
