import React from 'react'
import { MailingAddressProps } from 'registry/components/CrowdfundReserveFormFlow/components/ContributionSuccess/components/MailingAddress/MailingAddress.types'
import css from './MailingAddress.styles.scss'

const MailingAddress: React.FC<MailingAddressProps> = ({ address }) => (
  <div className={css.MailingAddress}>
    <p className={css.MailingAddress__Label}>Mailing address</p>
    <p className={css.MailingAddress__Address}>
      {address.streetAddress1}
      {', '}
      {address.streetAddress2 && `${address.streetAddress2}, `}
      {address.city}, {address.state} {address.zip}
    </p>
  </div>
)

export default MailingAddress
