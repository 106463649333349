import { Component } from 'react'
import PropTypes from 'prop-types'
import Input from './input'

export default class CurrencyInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      value: this.formatValue(props.value),
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.value !== prevProps.value &&
      this.props.value !== this.unFormatValue(this.state.value) && // Don't update unless the value has a material change.
      this.isValueNumeric(this.props.value)
    ) {
      this.setState({ value: this.props.value })
    }
  }

  handleBlur = () => {
    if (!this.isValueNumeric(this.state.value)) {
      return
    } // Don't try to format and persist non-numeric values

    this.setState({ value: this.formatValue(this.state.value) })
  }

  handleChange = (e) => {
    const valueFromInput = e.target.value
    this.setState({ value: valueFromInput })

    const value =
      valueFromInput !== '' ? this.unFormatValue(valueFromInput) : ''
    const event = { target: { name: this.props.name, value } }

    this.props.onChange(event)
  }

  isValueNumeric = (actual) => !Number.isNaN(this.unFormatValue(actual))

  formatValue = (value) => {
    if (!value) {
      return null
    }

    const valueAsFloat = this.unFormatValue(value).toFixed(2)
    return `$${valueAsFloat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  }

  unFormatValue = (value) =>
    parseFloat(value && value.toString().replace(/(\$|,)/g, ''))

  render() {
    const inputProps = {
      ...this.props,
      value: this.state.value,
      onChange: this.handleChange,
      onBlur: this.handleBlur,
    }

    return <Input {...inputProps} />
  }
}
