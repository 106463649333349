import { useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useQuery } from '@tanstack/react-query'
import { Responses } from '../../../../global'
// @ts-ignore
import { getAddress } from '../../../api'
import { ContactInfoCopyFieldsProps } from '../../../../widgets/ContactInfoCopyFields'

const RECAPTCHA_STALE_TIME = 120000 // 2 minutes

function groomData(data?: Responses.Address | null) {
  if (data) {
    return {
      address: {
        country: data.country,
        postalCode: data.zip,
        city: data.city,
        state: data.state,
        streetAddress1: data.streetAddress1,
        streetAddress2: data.streetAddress2,
      },
      fullName: data.name,
    } as ContactInfoCopyFieldsProps
  }

  return null
}

export interface UseContactInfoAddressProps {
  /**
   * Needed to locate the address.
   */
  addressId: string
  /**
   * If false, it won't attempt to fetch the address.
   * @default true
   */
  enabled?: boolean
  /**
   * Name for the reCaptcha action
   */
  reCaptchaAction: 'contact_info_modal' | 'redirect_modal'
}

/**
 * Hook to control the retrieval of the registry owner's address information, with some performance optimizations
 * as well as data reshaping.
 */
export default function useContactInfoAddress({
  addressId,
  reCaptchaAction,
  enabled = true,
}: UseContactInfoAddressProps) {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available')
      return
    }

    return await executeRecaptcha(reCaptchaAction)
  }, [executeRecaptcha])

  const query = useQuery({
    queryKey: [addressId],
    queryFn: async (): Promise<Responses.Address | null> => {
      // Attempt to fetch address (GG may already be whitelisted)
      let address
      try {
        address = await getAddress(addressId)
      } catch (error) {
        // If not whitelisted, authenticate with recaptcha token
        const token = await handleReCaptchaVerify()
        try {
          address = await getAddress(addressId, token)
        } catch (error) {
          // Address may be nil. When it is we respond 404. Our implementation of
          // fetch() throws all non-200-level statuses. Catching here to prevent
          // error from getting reported in application monitoring.
          address = null
        }
      }
      return address
    },
    enabled,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: RECAPTCHA_STALE_TIME,
  })

  return { ...query, data: groomData(query.data) }
}
