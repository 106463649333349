import { numberToCurrency } from 'lib/money'
import { Address } from 'registry/components/CrowdfundReserveFormFlow/components/ContributionSuccess/components/MailingAddress/MailingAddress.types'

const amountAsString = (amount?: number): string | null =>
  amount
    ? numberToCurrency(amount, { truncateZeroCents: true, unit: '$' })
    : null

export const cashSuccessTitle = (amount?: number) => {
  const substrings = [
    'Great! Your',
    amountAsString(amount),
    'contribution has been recorded',
  ].filter((str) => !!str)
  return substrings.join(' ')
}

export const cashSuccessSubtitle = (address: Address | null | undefined) => {
  let subtitle = 'Bring your gift to the baby shower'
  if (address && address.streetAddress1) {
    subtitle += ' or mail it to them'
  }
  return `${subtitle}.`
}

export const otherSuccessSubtitle = (paymentMethod: string) =>
  `We'll let them know you used ${paymentMethod} to send this gift.`

export const otherSuccessTitle = (names: string, amount?: number) =>
  [
    'You successfully contributed',
    amountAsString(amount),
    'to',
    `${names}'s`,
    'cash fund',
  ]
    .filter((str) => !!str)
    .join(' ')
