import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Input from './input'

const VisibilityToggleButton = ({ isVisible, onClick }) => (
  <button
    label={isVisible ? `Hide Input` : `Show Input`}
    type="button"
    onClick={onClick}
    tabIndex="-1"
    className={classNames(
      'btn btn-light pam fa fa-icn link-body-text',
      isVisible ? 'fa-eye-slash' : 'fa-eye'
    )}
  />
)

VisibilityToggleButton.defaultProps = {
  isVisible: false,
  onClick: () => {},
}

VisibilityToggleButton.propTypes = {
  isVisible: PropTypes.bool,
  onClick: PropTypes.func,
}

const SecretInput = ({ initiallyVisible = false, ...props }) => {
  const [showSecret, setShowSecret] = useState(initiallyVisible)

  // When depending on screensize, this component can mount with the wrong value
  // Updating if initial value changes, though control of the state should remain in this component alone
  useEffect(() => {
    setShowSecret(initiallyVisible)
  }, [initiallyVisible])

  return (
    <Input
      {...props}
      addonAfter={
        <VisibilityToggleButton
          isVisible={showSecret}
          onClick={() => setShowSecret(!showSecret)}
        />
      }
      addonStyle={{ padding: 0 }}
      type={showSecret ? 'text' : 'password'}
    />
  )
}

SecretInput.propTypes = {
  initiallyVisible: PropTypes.bool,
}

SecretInput.defaultProps = {
  initiallyVisible: false,
}

export default SecretInput
