import { FC } from 'react'

interface AcquisitionViewSiteNavSectionProps {
  className?: string
}

const AcquisitionViewSiteNavSection: FC<AcquisitionViewSiteNavSectionProps> = ({
  className,
}) => <ul className={className} />

export default AcquisitionViewSiteNavSection
