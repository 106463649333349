/* eslint-disable camelcase */
import { getFeed } from 'registry/api'

export const fetchLatestFeedSession = (params) => {
  const { session, feed_overrides } = params
  return (dispatch) => {
    dispatch(startFetchingFeed)

    const isAdminSession = session || feed_overrides
    if (isAdminSession) {
      dispatch(setFeedItems([]))
    }

    return getFeed(params).then((resp) => {
      // Admin session override should only display a
      // single session aka don't try to fetch more
      // Session is always present for v1 overrides while
      // feed_overrides is always present for v2 overrides
      if (isAdminSession) {
        dispatch(setHasMoreFeedItems(false))
      }
      dispatch(setFeedItems(resp.items))
      dispatch(setFeedSession(resp.sessionNumber))
      dispatch(finishedFetchingFeed)
    })
  }
}

export const fetchFeedSession = (params) => {
  return (dispatch) => {
    dispatch(startFetchingFeed)
    return getFeed(params).then((resp) => {
      if (resp.items && resp.items.length == 0) {
        dispatch(setHasMoreFeedItems(false))
      } else {
        dispatch(addFeedItems(resp.items))
      }
      dispatch(finishedFetchingFeed)
    })
  }
}

export const SET_FEED_ITEMS = 'SET_FEED_ITEMS'
export const setFeedItems = (feedItems) => ({
  type: SET_FEED_ITEMS,
  feedItems,
})

export const ADD_FEED_ITEMS = 'ADD_FEED_ITEMS'
export const addFeedItems = (feedItems) => ({
  type: ADD_FEED_ITEMS,
  feedItems,
})

export const SET_FEED_SESSION = 'SET_FEED_SESSION'
export const setFeedSession = (sessionNumber) => ({
  type: SET_FEED_SESSION,
  sessionNumber,
})

export const SET_IS_FETCHING_FEED = 'SET_IS_FETCHING_FEED'
export const startFetchingFeed = {
  type: SET_IS_FETCHING_FEED,
  isFetchingFeed: true,
}
export const finishedFetchingFeed = {
  type: SET_IS_FETCHING_FEED,
  isFetchingFeed: false,
}

export const SET_HAS_MORE_FEED_ITEMS = 'SET_HAS_MORE_FEED_ITEMS'
export const setHasMoreFeedItems = (hasMoreFeedItems) => ({
  type: SET_HAS_MORE_FEED_ITEMS,
  hasMoreFeedItems,
})
