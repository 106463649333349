import React from 'react'
import { CancelReservationProps } from 'registry/components/CrowdfundReserveFormFlow/components/CancelReservation/CancelReservation.types'
import { Button } from '../../../../../baby-design'
import css from './CancelReservation.styles.scss'
import CrowdfundReserveModalTitle from 'registry/components/CrowdfundReserveFormFlow/components/CrowdfundReserveModalTitle/CrowdfundReserveModalTitle'

type ActionButtonProps = Pick<
  CancelReservationProps,
  'onBack' | 'onConfirm' | 'isLoading'
> & {
  size: 'sm' | 'md' | 'lg'
}

const ActionButtons: React.FC<ActionButtonProps> = ({
  isLoading = false,
  onBack,
  onConfirm,
  size,
}) => (
  <div className={css.CancelReservation__Buttons}>
    <Button
      className={css.CancelReservation__Button}
      size={size}
      variant="secondary"
      onClick={() => onBack?.()}
    >
      Go back
    </Button>
    <Button
      className={css.CancelReservation__Button}
      loading={isLoading}
      size={size}
      variant="destructive"
      onClick={() => onConfirm?.()}
    >
      Cancel my contribution
    </Button>
  </div>
)

const CancelReservation: React.FC<CancelReservationProps> = ({
  isLoading = false,
  onBack,
  onConfirm,
  paymentMethod,
  registryFirstNames,
}) => (
  <div className={css.CancelReservation}>
    <CrowdfundReserveModalTitle />
    <div className={css.CancelReservation__Content}>
      <h3 className={css.CancelReservation__Header}>
        Are you sure you want to cancel your contribution?
      </h3>
      <p className={css.CancelReservation__Body}>
        Canceling will remove this contribution from {registryFirstNames}'s
        registry. Please note that this will not reverse transactions sent via
        {` ${paymentMethod}`}.
      </p>
    </div>
    <div className="MobileOnly">
      <ActionButtons
        isLoading={isLoading}
        size="md"
        onConfirm={onConfirm}
        onBack={onBack}
      />
    </div>
    <div className="HiddenMobile">
      <ActionButtons
        isLoading={isLoading}
        size="lg"
        onConfirm={onConfirm}
        onBack={onBack}
      />
    </div>
  </div>
)

export default CancelReservation
