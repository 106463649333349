import Proptypes from 'prop-types'
import { registryDiscountPath } from 'lib/urls'

const DiscountDisclaimer = () => (
  <a
    className="tiny"
    href={registryDiscountPath}
    rel="noopener noreferrer"
    target="_blank"
  >
    **Restrictions may apply.
  </a>
)

export const ShippingDisclaimer = ({ className }) => (
  <div className={`tiny text-muted ${className}`}>
    *Shipping{' '}
    <a
      className="text-muted text-underline"
      href="https://help.babylist.com/hc/en-us/articles/217912848-How-much-is-shipping-at-the-Babylist-store-"
      rel="noopener noreferrer"
      style={{ display: 'inline-block' }}
      target="_blank"
    >
      restrictions may apply
    </a>
    . Not all products are eligible.
  </div>
)

ShippingDisclaimer.propTypes = {
  className: Proptypes.string,
}

ShippingDisclaimer.defaultProps = {
  className: '',
}

const Disclaimers = () => (
  <div style={{ textAlign: 'center' }}>
    <DiscountDisclaimer />
    <br />
    <ShippingDisclaimer />
  </div>
)

export default Disclaimers
