import { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { Form, Input, FormButtonGroup } from 'components/forms'
import { createValidator, required } from 'lib/form-validation'
import { createReservationAsOwner, fetchRegItem } from 'registry/actions'
import { NumberSelect } from 'components/forms'

export const validate = createValidator({ name: [required] })

export class OwnerReserveForm extends Component {
  constructor(props) {
    super(props)
    this.quantityRef = createRef()
  }

  static defaultProps = {
    createReservationAsOwner: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    regItem: PropTypes.shape({
      id: PropTypes.number.isRequired,
      registryId: PropTypes.number.isRequired,
      quantityNeeded: PropTypes.number.isRequired,
    }).isRequired,
    submitting: PropTypes.boolean,
  }

  render() {
    const {
      error,
      handleSubmit,
      onHide,
      submitting,
      regItem: { quantityNeeded },
      fields: { name, quantity },
    } = this.props
    const shouldDisplayQuantity = quantityNeeded > 1
    return (
      <Form error={error} onSubmit={handleSubmit(this.handleSubmit)}>
        <Input
          {...name}
          label="Gift Giver's Name"
          error={name.touched ? name.error : null}
        />
        {shouldDisplayQuantity ? (
          <NumberSelect
            {...quantity}
            label="Quantity"
            error={quantity.touched ? quantity.error : null}
            ref={this.quantityRef}
            min={1}
            max={quantityNeeded}
          />
        ) : null}
        <FormButtonGroup
          onClickSecondary={onHide}
          submitting={submitting}
          textPrimary="Submit"
        />
      </Form>
    )
  }

  handleSubmit = ({ name, quantity }) => {
    const { createReservationAsOwner, fetchRegItem, registry, regItem } =
      this.props
    const reservation = {
      regItemId: regItem.id,
      registryId: regItem.registryId,
      name,
      quantity: quantity || 1,
      isPurchased: true,
    }
    return (
      createReservationAsOwner(reservation)
        // TODO-IAN: should reg item just come down nested in reservation?
        // requires some extra consideration because it would need to be v4 json.
        // currently update reservation returns a nested reg item but it's v3 json.
        .then(({ regItemId }) => fetchRegItem(regItemId))
        .then(this.props.onSubmitSuccess)
    )
  }
}

export default reduxForm(
  {
    form: 'ownerReserveForm',
    fields: ['name', 'quantity'],
    validate,
  },
  null,
  { createReservationAsOwner, fetchRegItem }
)(OwnerReserveForm)
