import { Component } from 'react'
import PropTypes from 'prop-types'
import FundingInformation from './funding-information'
import EditRegItemLink from './edit-reg-item-link'
import CashFundContributionModalLink from './cash-fund-contribution-modal-link'
import { regItemProps } from './reg-item-props'
import RegItemCardLayout from './reg-item-card-layout'
import OwnerActions from './owner-actions/owner-actions'
import CashFundAction from './guest-actions/cash-fund-action'

export default class CashFundRegItemCard extends Component {
  static propTypes = {
    regItem: PropTypes.shape(regItemProps),
    isContributorView: PropTypes.bool,
  }

  static contextTypes = {
    isMobile: PropTypes.bool,
  }

  actions = () => {
    const {
      regItem: { id },
      regItem,
    } = this.props
    if (this.props.isContributorView) {
      return <OwnerActions showEditAction regItemId={id} />
    } else {
      return <CashFundAction regItem={regItem} />
    }
  }

  regItemLink = (child) => {
    const { regItem, isContributorView } = this.props

    if (!isContributorView) {
      return (
        <CashFundContributionModalLink regItem={regItem}>
          {child}
        </CashFundContributionModalLink>
      )
    } else if (this.context.isMobile) {
      // contributor view
      return <EditRegItemLink regItemId={regItem.id}>{child}</EditRegItemLink>
    } else {
      return child
    }
  }

  render() {
    const { isContributorView } = this.props
    const { amountContributed, goalAmount } = this.props.regItem

    return (
      <RegItemCardLayout
        {...this.props}
        regItemLinkDecorator={this.regItemLink}
        actions={this.actions()}
        offerPanel={
          <FundingInformation
            amountContributed={amountContributed}
            goalAmount={goalAmount}
            displayAmountContributed={isContributorView}
          />
        }
      />
    )
  }
}
