import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'components/link'
import { openCrowdfundReservationModal } from 'registry/modals/actions'
import { RegItemShape } from 'registry/lib/prop-types'

const ModalLink = ({ children, regItem, openCrowdfundReservationModal }) => {
  return (
    <Link
      className="link-info"
      onClick={() => openCrowdfundReservationModal(regItem)}
    >
      {children}
    </Link>
  )
}

ModalLink.propTypes = {
  children: PropTypes.node.isRequired,
  openCrowdfundReservationModal: PropTypes.func.isRequired,
  regItem: PropTypes.shape(RegItemShape).isRequired,
}

export const CrowdfundContributionModalLink = connect(undefined, {
  openCrowdfundReservationModal,
})(ModalLink)
