const CategoryDiapering = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 258.35 258.35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M201.76,119a3,3,0,0,1,2.11,3.83L187.73,172a3,3,0,0,1-3.57,2l-17.56-4.42a3,3,0,0,1-2.21-3.43l1.86-10.38a67.4,67.4,0,0,0,14.33-18.38,67.18,67.18,0,0,0,7.14-21.95Z"
      fill="#d8d8d8"
    />
    <path
      d="M177.2,112.78l10.52,2.65a66.28,66.28,0,0,1-2.58,11.16,30.6,30.6,0,0,0-8.12-.09,30.94,30.94,0,0,0-5.75,1.25l2.26-12.6A3,3,0,0,1,177.2,112.78Z"
      fill="#d8d8d8"
    />
    <path
      d="M177,126.5a30.6,30.6,0,0,1,8.12.09,66.62,66.62,0,0,1-4.56,10.79,67.4,67.4,0,0,1-14.33,18.38l5-28A30.94,30.94,0,0,1,177,126.5Z"
      fill="#d8d8d8"
    />
    <path
      d="M157.59,104.31a3.74,3.74,0,0,1-3.27,4.16L141.77,110l-4.14.49a3.75,3.75,0,0,1-4.17-3.27l-.68-5.63a3.77,3.77,0,0,1,3.27-4.17l4.58-.55,12.12-1.46a3.76,3.76,0,0,1,4.16,3.27Z"
      fill="#d8d8d8"
    />
    <path
      d="M109.88,110.06a3.74,3.74,0,0,1-3.27,4.16l-4.14.5-12.55,1.51A3.76,3.76,0,0,1,85.75,113l-.67-5.64a3.74,3.74,0,0,1,3.27-4.16l12.11-1.46,4.58-.56a3.76,3.76,0,0,1,4.16,3.27Z"
      fill="#d8d8d8"
    />
    <path
      d="M151.07,166.06a67.34,67.34,0,0,1-44.38,5.37l-.07,0"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M64.28,141.15a67.3,67.3,0,0,1-8.74-47.89A10.22,10.22,0,0,1,66.36,85,199.18,199.18,0,0,0,89,85.22c9-.36,19-1.16,29.54-2.43s20.48-2.88,29.27-4.66a198.36,198.36,0,0,0,22-5.59A10.21,10.21,0,0,1,182.26,78a67.45,67.45,0,0,1,5.46,37.44"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M89.45,142.06a30.87,30.87,0,0,1,17.06,24,31.58,31.58,0,0,1,.18,5.31,67.29,67.29,0,0,1-42.41-30.26,31.45,31.45,0,0,1,7.88-2,30.78,30.78,0,0,1,17.28,2.93"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M89.44,142.06h0a38.2,38.2,0,0,0,5-5.28,37.21,37.21,0,0,0,8-22.06"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M89,85.22a37.21,37.21,0,0,1,11.44,16.49"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M166.25,155.76a66.66,66.66,0,0,1-15.11,10.29h0a30.43,30.43,0,0,1-1.08-5.19,30.83,30.83,0,0,1,10.86-27.4,30.39,30.39,0,0,1,7.4-4.59,29.8,29.8,0,0,1,3-1.11"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M140.63,96.86a37.21,37.21,0,0,1,7.2-18.73"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M141.77,110a37.39,37.39,0,0,0,19.15,23.46"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M100.46,101.71l4.58-.56a3.76,3.76,0,0,1,4.16,3.27l.68,5.64a3.74,3.74,0,0,1-3.27,4.16l-4.14.5-12.55,1.51A3.76,3.76,0,0,1,85.75,113l-.67-5.64a3.74,3.74,0,0,1,3.27-4.16Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M140.63,96.86l12.12-1.46a3.76,3.76,0,0,1,4.16,3.27l.68,5.64a3.74,3.74,0,0,1-3.27,4.16L141.77,110l-4.14.49a3.75,3.75,0,0,1-4.17-3.27l-.68-5.63a3.77,3.77,0,0,1,3.27-4.17Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M187.72,115.43l14,3.54a3,3,0,0,1,2.11,3.83L187.73,172a3,3,0,0,1-3.57,2l-17.56-4.42a3,3,0,0,1-2.21-3.43l1.86-10.38,5-28,2.26-12.6a3,3,0,0,1,3.67-2.37Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M186.2,176.28,184,185.1a1.62,1.62,0,0,1-1.95,1.17l-19.37-4.89a1.61,1.61,0,0,1-1.16-1.95l2.22-8.81a1.6,1.6,0,0,1,1.95-1.17L185,174.33A1.61,1.61,0,0,1,186.2,176.28Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <line
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
      x1="179.63"
      x2="178.86"
      y1="120.77"
      y2="123.83"
    />
    <line
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
      x1="187.84"
      x2="187.06"
      y1="122.44"
      y2="125.5"
    />
    <line
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
      x1="196.04"
      x2="195.27"
      y1="124.67"
      y2="127.73"
    />
  </svg>
)

export default CategoryDiapering
