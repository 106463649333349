import { TypeCloudinaryImageJSON } from 'hello_baby/types/guides'

export const cloudinaryPdfToJpg = (url: string) => {
  if (!url) return url
  return url.replace('.pdf', '.jpg')
}

export const cloudinaryImageURL = (
  cloudinaryJSON?: TypeCloudinaryImageJSON | TypeCloudinaryImageJSON[]
) => {
  if (!cloudinaryJSON) return undefined
  if (Array.isArray(cloudinaryJSON)) {
    if (cloudinaryJSON.length > 0) {
      if ('secureUrl' in cloudinaryJSON[0]) {
        return cloudinaryPdfToJpg(cloudinaryJSON[0].secureUrl)
      }
    }
    return undefined
  }
  if ('secureUrl' in cloudinaryJSON)
    return cloudinaryPdfToJpg(cloudinaryJSON.secureUrl)
  return undefined
}

export const cloudinaryThumbnailUrl = (
  cloudinaryJSON?: TypeCloudinaryImageJSON | TypeCloudinaryImageJSON[],
  transformGif = false
) => {
  if (!cloudinaryJSON) return undefined

  let secureUrl = Array.isArray(cloudinaryJSON)
    ? cloudinaryJSON[0].secureUrl
    : cloudinaryJSON.secureUrl

  if (!secureUrl) return undefined

  if (transformGif && secureUrl.includes('.gif')) {
    secureUrl = secureUrl.replace('.gif', '.jpg')
  }

  if (secureUrl.includes('.pdf')) {
    secureUrl = cloudinaryPdfToJpg(secureUrl)
  }

  return secureUrl.replace('upload/', 'upload/t_MessageCenter-Thumbnail/')
}

export const cloudinaryAspectRatioTransform = (
  url: string,
  ratio: number
): string => url.replace('upload/', `upload/ar_${ratio},c_thumb/`)

export const cloudinaryGifStillFrameUrl = (url: string, frame = 1): string => {
  if (!url || !url.includes('.gif')) return url

  return url.replace('upload/', `upload/pg_${frame}/`)
}
