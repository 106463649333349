const SearchFillSuggestion = ({ className }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99999 0.5L0.999999 0.5C0.447715 0.5 -4.21468e-07 0.910405 0 1.41667V9.66666C-3.37174e-07 10.1729 0.447715 10.5833 0.999999 10.5833C1.55228 10.5833 2 10.1729 2 9.66666L2 3.62969L10.2929 11.2315C10.6834 11.5895 11.3166 11.5895 11.7071 11.2315C12.0976 10.8735 12.0976 10.2931 11.7071 9.93515L3.41421 2.33333L9.99999 2.33333C10.5523 2.33333 11 1.92293 11 1.41667C11 0.910405 10.5523 0.5 9.99999 0.5Z"
      fill="#D1D1D1"
    />
  </svg>
)

export default SearchFillSuggestion
