/* eslint-disable import/no-extraneous-dependencies */
import RichTextBody from 'hello_baby/components/RichTextBody'
import { Button, TextLink } from 'baby-design'
import MinusSign from 'baby-design/icons/Minus.svg'
import { Document } from '@contentful/rich-text-types'
import { Check, RightChevron } from 'baby-design/icons'
import classNames from 'classnames'
import { handleAddToRegistry } from 'hello_baby/components/Embeds/ProductCardSection/ProductCard'
import { sectionAnchor } from 'hello_baby/lib/helpers'
import { ComparisonProductProps } from './ComparisonProduct.types'
import css from './ComparisonProduct.styles.scss'

const ComparisonProduct = ({
  comparisonAttributes,
  fields,
}: ComparisonProductProps) => {
  const { comparisonFields, product, recommendationLabel } = fields

  const getFieldValueByAttributeName = (attributeName: string) => {
    const field = comparisonFields.find(
      (comparisonField) =>
        comparisonField.fields.attributeName === attributeName
    )

    if (!field) {
      return <MinusSign className={css.ComparisonProduct__minus} />
    }

    switch (field.sys.contentType.sys.id) {
      case 'postEmbedPartComparisonTextField':
        return field.fields.attributeValue
      case 'postEmbedPartComparisonRichTextField':
        return (
          <RichTextBody
            richTextBody={field.fields.attributeValue as Document}
          />
        )
      case 'postEmbedPartComparisonBooleanField':
        return field.fields.attributeValue ? (
          <Check className={css.ComparisonProduct__check} />
        ) : (
          <MinusSign className={css.ComparisonProduct__minus} />
        )
      default:
        return <MinusSign className={css.ComparisonProduct__minus} />
    }
  }

  return (
    <>
      <div
        className={classNames(
          css.ComparisonProduct__productHighlightContainer,
          {
            [css.ComparisonProduct__productHighlightContainer_bestOverall]:
              recommendationLabel === 'Best Overall',
          }
        )}
      >
        <div
          className={classNames(
            css.ComparisonProduct__productHighlightInnerContainer,
            {
              [css.ComparisonProduct__productHighlightInnerContainer_bestOverall]:
                recommendationLabel === 'Best Overall',
            }
          )}
        >
          {recommendationLabel && (
            <div className={css.ComparisonProduct__recommendationLabel}>
              {recommendationLabel}
            </div>
          )}
          <div className={css.ComparisonProduct__productCell}>
            <div className={css.ComparisonProduct__productCellInnerContainer}>
              <div className={css.ComparisonProduct__productImage}>
                <img alt={product.fields.name} src={product.fields.image.url} />
              </div>
              <div className={css.ComparisonProduct__brandName}>
                {product.fields.brand}
              </div>
              <div className={css.ComparisonProduct__productName}>
                {product.fields.genericProductName}
              </div>
            </div>
          </div>
          {comparisonAttributes.map((comparisonAttribute) => (
            <div
              className={css.ComparisonProduct__attributeCell}
              key={comparisonAttribute}
            >
              {getFieldValueByAttributeName(comparisonAttribute)}
            </div>
          ))}
        </div>
      </div>
      <div className={css.ComparisonProduct__button}>
        <Button
          size="sm"
          variant="primary"
          onClick={() => handleAddToRegistry(product.fields)}
        >
          Add to Babylist
        </Button>
      </div>
      <div className={css.ComparisonProduct__link}>
        <TextLink
          href={`#${sectionAnchor(product.fields.name)}`}
          icon={{
            icon: <RightChevron />,
            side: 'right',
            size: 'xs',
          }}
          size="xs"
        >
          Learn More
        </TextLink>
      </div>
    </>
  )
}

export default ComparisonProduct
