import PropTypes from 'prop-types'
import { categoryProps } from '../reg-item-category/reg-item-category'
import StoreFilters from './store-filters'
import PriceFilter from './price-filter'
import CategoryFilter from './category-filter'
import ReservationFilter from './reservation-filters'
import FavoriteFilters from './favorite-filters'
import GroupGiftFilter from './group-gift-filter'

const RegItemFilters = ({
  filters,
  addFilter,
  stores,
  isRegistryDiscountView,
  categories,
}) => {
  return (
    <div className="registry-filters animated fadeIn animate-250ms">
      <div className="filters-wrapper">
        {isRegistryDiscountView ? null : (
          <ReservationFilter
            addFilter={addFilter}
            selected={filters.giftView}
            label="View"
          />
        )}

        <FavoriteFilters
          addFilter={addFilter}
          className="mtm mbl"
          selected={filters.favoritesView}
          label="Show Only"
        />

        <GroupGiftFilter
          addFilter={addFilter}
          className="mtm mbl mlm"
          selected={filters.groupGiftView}
        />

        <PriceFilter
          addFilter={addFilter}
          selected={filters.priceRange}
          label="Price"
        />

        {isRegistryDiscountView ? null : (
          <StoreFilters
            stores={stores}
            addFilter={addFilter}
            selected={filters.storeNames}
            label="Stores"
          />
        )}

        <CategoryFilter
          categories={categories}
          addFilter={addFilter}
          selected={filters.categoryId}
        />
      </div>
    </div>
  )
}

RegItemFilters.propTypes = {
  isRegistryDiscountView: PropTypes.bool.isRequired,
  stores: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.shape(categoryProps)),
  addFilter: PropTypes.func.isRequired,
  filters: PropTypes.object,
}

RegItemFilters.defaultProps = {
  categories: [],
  stores: [],
  filters: {},
}

export default RegItemFilters
