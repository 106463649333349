import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { GroupGiftModal } from 'components/modal'
import { MultiStepWizard } from 'components/presentation'
import { InfoButton } from 'components/buttons'
import Link from 'components/link'
import { groupGiftFaqPath, settingsGiftCardsAndPromotionsPath } from 'lib/urls'
import { saveRegItem } from 'registry/actions/reg-items-actions'
import { openRegItemEditModal } from 'registry/modals/actions'
import * as api from 'store/api'
import {
  FREE_SHIPPING_THRESHOLD,
  GROUP_GIFT_GOAL_AMOUNT_BUFFER_PERCENT_DISPLAY,
} from 'shared/constants'
import GroupGiftProductPreviewCard from './components/group-gift-product-preview-card'
import GroupGiftGoalAmountForm from './components/group-gift-goal-amount-form'
import css from './enable-group-gift-modal.scss'

const getProduct = async (productId) => {
  return api.getProduct(productId)
}

const EnableGroupGiftModal = ({
  isFirstTime,
  saveRegItem,
  onHide,
  openRegItemEditModal,
  regItem,
  show,
}) => {
  const [activeStep, setActiveStep] = useState(isFirstTime ? 1 : 2)
  const [product, setProduct] = useState(false)
  const [error, setError] = useState()
  const { canEnableGroupGift, groupGiftingDisabledMessage } = regItem

  let title =
    'Group Gifting allows family and friends to contribute toward a higher priced item on your registry'

  if (activeStep === 2) {
    title = 'Group Gift is Available Only For Items Babylist Sells Directly'
  }

  if (activeStep === 3) {
    title = `Add ${GROUP_GIFT_GOAL_AMOUNT_BUFFER_PERCENT_DISPLAY} to Group Gift Goal to Help Cover Any Taxes${
      product?.price < FREE_SHIPPING_THRESHOLD ? ' and Shipping?' : '?'
    }`
  }

  useEffect(() => {
    async function getProductAsync() {
      const resp = await getProduct(regItem.productId)
      const { genericProduct } = resp
      const price = get(genericProduct, [
        'products',
        genericProduct.activeProductId,
        'price',
        'msrp',
      ])
      const image = get(genericProduct, [
        'products',
        genericProduct.activeProductId,
        'images',
        [0],
        'thumbnailUrl',
      ])
      setProduct({
        name: genericProduct?.name,
        image,
        price,
      })
    }
    getProductAsync()
  }, [])

  const handleHide = () => {
    setActiveStep(2)
    onHide()
    openRegItemEditModal(regItem.id)
  }

  // eslint-disable-next-line react/prop-types
  const ContinueButton = ({ disabled, text }) => (
    <div className="text-center">
      <InfoButton
        className={css.continueBtn}
        disabled={disabled}
        onClick={() => setActiveStep(activeStep + 1)}
        pill
        block
      >
        {text}
      </InfoButton>
    </div>
  )

  const handleGiftAmountForm = (regItemFields) => {
    const { id } = regItem
    saveRegItem(id, regItemFields)
      .then(handleHide())
      .catch((resp) => setError(resp)) // TODO: do something with this error
  }

  if (!canEnableGroupGift) {
    return (
      <GroupGiftModal
        show={show}
        onHide={handleHide}
        title={groupGiftingDisabledMessage}
      >
        <InfoButton
          className={css.continueBtn}
          onClick={() => {
            onHide()
            openRegItemEditModal(regItem.id)
          }}
          pill
          block
        >
          Continue
        </InfoButton>
      </GroupGiftModal>
    )
  }

  return (
    <GroupGiftModal show={show} onHide={handleHide} title={title}>
      <MultiStepWizard activeStep={activeStep} animateInitialStep={false}>
        {/* Step 1 */}
        <div className="h6">
          <p>
            Each group gift contribution is a Babylist Shop gift card. Gift
            cards are not returnable, transferable to another account, or
            refundable after purchase (except as required by law).
          </p>

          <p>
            Group gift contributions can be used towards this item (or any other
            product Babylist sells).
          </p>

          <p>
            Once the group gift is fully funded, it will not automatically ship.
            It needs to be purchased by the registry owner.
          </p>

          <p>
            <Link
              className="text-bold text-brand-info"
              url={groupGiftFaqPath}
              newWindow
            >
              See the full Group Gift FAQ
            </Link>
          </p>
          <ContinueButton text="Got It" />
        </div>

        {/* Step 2 */}
        <div className="h6">
          <p className="text-center">
            <strong>Yes</strong>, make this item group giftable (this will
            remove other retailer links from the item)
          </p>
          <div className="mtl mbxl">
            <GroupGiftProductPreviewCard product={product} />
          </div>
          <ContinueButton disabled={!product} text="Continue" />
        </div>

        {/* Step 3 */}
        <div>
          <GroupGiftGoalAmountForm
            basePrice={product?.price}
            onSubmit={handleGiftAmountForm}
          />
        </div>
      </MultiStepWizard>
    </GroupGiftModal>
  )
}

EnableGroupGiftModal.propTypes = {
  isFirstTime: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  openRegItemEditModal: PropTypes.func.isRequired,
  regItem: PropTypes.object.isRequired,
  saveRegItem: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

export default connect(undefined, { saveRegItem, openRegItemEditModal })(
  EnableGroupGiftModal
)
