import { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { Col, HelpBlock as BSHelpBlock, Row } from 'react-bootstrap'
import { Select } from 'components/forms'
import { map, range } from 'lodash'
import classNames from 'classnames'

const today = new Date()
const currentYear = today.getFullYear()
const defaultYears = [currentYear, currentYear + 1]
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
]
const days = range(1, 32)
const [PLACEHOLDER_DAY, PLACEHOLDER_MONTH, PLACEHOLDER_YEAR] = [
  'Day',
  'Month',
  'Year',
]
const PLACEHOLDER_VALUE = `${PLACEHOLDER_YEAR}-${PLACEHOLDER_MONTH}-${PLACEHOLDER_DAY}`

class DateSelect extends Component {
  constructor(props) {
    super(props)
    this.monthRef = createRef()
    this.dayRef = createRef()
    this.yearRef = createRef()
  }

  static propTypes = {
    className: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.string,
    help: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    preferFutureDates: PropTypes.bool,
    years: PropTypes.arrayOf(PropTypes.number),
  }

  static defaultProps = {
    preferFutureDates: true,
    years: defaultYears,
    fastForward: true,
  }

  render() {
    const { years, error, label, value, className, help } = this.props

    let dueYear, dueMonth, dueDay
    if (value) {
      ;[dueYear, dueMonth, dueDay] = value.split('-')
    }

    return (
      <div className={classNames('input-container', className)}>
        <div>
          <div className={classNames({ 'has-error': error })}>
            <fieldset>
              {label ? <label className="control-label">{label}</label> : null}
              {/*
                  row now has the bottom margin instead of the individual selects.
                  This let's us position the error message in a consistent fashion.
                */}
              <Row style={{ margin: 0 }} className="mbl">
                <Col xs={4} className="prs pln">
                  <Select
                    wrapperClassName="mbn"
                    ref={this.monthRef}
                    onChange={this.handleChange.bind(this, 'month')}
                    value={dueMonth || PLACEHOLDER_MONTH}
                  >
                    <option value={PLACEHOLDER_MONTH}>
                      {PLACEHOLDER_MONTH}
                    </option>
                    {map(months, (month, idx) => {
                      return (
                        <option
                          value={idx + 1 > 9 ? idx + 1 : '0' + (idx + 1)}
                          key={month}
                        >
                          {month}
                        </option>
                      )
                    })}
                  </Select>
                </Col>
                <Col xs={4} className="phs">
                  <Select
                    wrapperClassName="mbn"
                    ref={this.dayRef}
                    onChange={this.handleChange.bind(this, 'day')}
                    value={dueDay || PLACEHOLDER_DAY}
                  >
                    <option value={PLACEHOLDER_DAY}>{PLACEHOLDER_DAY}</option>
                    {map(days, (day) => {
                      return (
                        <option value={day > 9 ? day : '0' + day} key={day}>
                          {day}
                        </option>
                      )
                    })}
                  </Select>
                </Col>
                <Col xs={4} className="pls prn">
                  <Select
                    wrapperClassName="mbn"
                    ref={this.yearRef}
                    onChange={this.handleChange.bind(this, 'year')}
                    value={dueYear || PLACEHOLDER_YEAR}
                  >
                    <option value={PLACEHOLDER_YEAR}>{PLACEHOLDER_YEAR}</option>
                    {map(years, (year) => {
                      return (
                        <option value={year} key={year}>
                          {year}
                        </option>
                      )
                    })}
                  </Select>
                </Col>
                <Col xs={12} className="phn">
                  {error || help ? (
                    <BSHelpBlock
                      className={classNames('h6', { 'text-muted': !error })}
                    >
                      {/* if an element has help text and an error just show the error. otherwise show help text */}
                      {error || help}
                    </BSHelpBlock>
                  ) : null}
                </Col>
              </Row>
            </fieldset>
          </div>
        </div>
      </div>
    )
  }

  handleChange(fieldKey) {
    let year = ReactDOM.findDOMNode(this.yearRef.current).querySelectorAll(
      'select'
    )[0].value
    let month = ReactDOM.findDOMNode(this.monthRef.current).querySelectorAll(
      'select'
    )[0].value
    let day = ReactDOM.findDOMNode(this.dayRef.current).querySelectorAll(
      'select'
    )[0].value

    if (this.props.preferFutureDates) {
      if (fieldKey == 'month' && month != PLACEHOLDER_MONTH) {
        const currentDate = new Date()
        const currentMonth = currentDate.getMonth() + 1
        const currentYear = currentDate.getFullYear()

        const nSelectedMonth = parseInt(month)
        const nSelectedYear = parseInt(year)

        // if no year value is set make a best guess since this is commonly used for due date
        if (year == PLACEHOLDER_YEAR) {
          // If the month being selected is less than the current month in the current year,
          // then fast-forward to next year
          if (
            nSelectedMonth < currentMonth &&
            (nSelectedYear == currentYear || year == PLACEHOLDER_YEAR) &&
            this.props.fastForward
          ) {
            year = (currentYear + 1).toString()
          } else {
            // select the current year
            year = currentYear.toString()
          }
        }
      }
    }

    if (day.length == 1) day = '0' + day
    if (month.length == 1) month = '0' + month

    const newValue = `${year}-${month}-${day}`
    this.props.onChange(newValue == PLACEHOLDER_VALUE ? null : newValue)
  }
}

export default DateSelect
