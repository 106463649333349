import React from 'react'

interface Exclamation2Props {
  className?: string
  filled?: boolean
}

const Exclamation2: React.FC<Exclamation2Props> = ({ className, filled }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {filled ? (
      <path
        clipRule="evenodd"
        d="M7.82999 5.07897C9.63032 1.77836 14.3697 1.77837 16.17 5.07897L21.4318 14.7257C23.1583 17.8909 20.8673 21.7502 17.2618 21.7502H6.73816C3.13262 21.7502 0.841636 17.8909 2.56815 14.7257L7.82999 5.07897ZM12 14.7502C11.5858 14.7502 11.25 14.4144 11.25 14.0002V9.00021C11.25 8.586 11.5858 8.25021 12 8.25021C12.4142 8.25021 12.75 8.586 12.75 9.00021V14.0002C12.75 14.4144 12.4142 14.7502 12 14.7502ZM12 17.2893C11.5858 17.2893 11.25 16.9535 11.25 16.5393V16.5002C11.25 16.086 11.5858 15.7502 12 15.7502C12.4142 15.7502 12.75 16.086 12.75 16.5002V16.5393C12.75 16.9535 12.4142 17.2893 12 17.2893Z"
        fill="#252324"
        fillRule="evenodd"
      />
    ) : (
      <path
        clipRule="evenodd"
        d="M7.82986 5.07897C9.63019 1.77836 14.3695 1.77837 16.1699 5.07897L21.4317 14.7257C23.1582 17.8909 20.8672 21.7502 17.2617 21.7502H6.73803C3.1325 21.7502 0.841514 17.8909 2.56803 14.7257L7.82986 5.07897ZM14.853 5.79725C13.6212 3.53894 10.3785 3.53894 9.14671 5.79724L3.88487 15.4439C2.70357 17.6097 4.27109 20.2502 6.73803 20.2502H17.2617C19.7286 20.2502 21.2962 17.6097 20.1149 15.4439L14.853 5.79725ZM11.9999 8.25021C12.4141 8.25021 12.7499 8.58599 12.7499 9.00021L12.7499 14.0002C12.7499 14.4144 12.4141 14.7502 11.9999 14.7502C11.5857 14.7502 11.2499 14.4144 11.2499 14.0002L11.2499 9.00021C11.2499 8.58599 11.5857 8.25021 11.9999 8.25021ZM11.9999 15.7502C12.4141 15.7502 12.7499 16.086 12.7499 16.5002V16.5393C12.7499 16.9535 12.4141 17.2893 11.9999 17.2893C11.5857 17.2893 11.2499 16.9535 11.2499 16.5393V16.5002C11.2499 16.086 11.5857 15.7502 11.9999 15.7502Z"
        fill="#252324"
        fillRule="evenodd"
      />
    )}
  </svg>
)

export default Exclamation2
