import { FC } from 'react'
import { Collapse } from 'react-bootstrap'
import classNames from 'classnames'
import Link from 'components/link'
import { StoreNavCategory } from 'src/types/storeNavCategory'
import css from './side-nav.scss'
import { OnCategoryClick } from './side-nav'

interface CategoryWithChildrenProps {
  activeSlugSet: string[]
  category: StoreNavCategory
  depth?: number
  isChild?: boolean
  parentSlugSet: (string | null)[]
  onCategoryClick?: OnCategoryClick
}

export const CategoryWithChildren: FC<CategoryWithChildrenProps> = ({
  activeSlugSet = [],
  category,
  depth = 1,
  isChild = false,
  parentSlugSet,
  onCategoryClick,
}) => {
  const { children = [], hidden, path, title, urlSlug } = category
  const leafCategory = !children || !children.length
  const active = activeSlugSet.at(-1) === urlSlug
  const open =
    !leafCategory &&
    category.urlSlug &&
    activeSlugSet.includes(category.urlSlug)
  const currentSlugSet = [...parentSlugSet, urlSlug]

  if (hidden) return null

  return (
    <li className={classNames(css.category, { [css.subCategory]: isChild })}>
      <Link
        className={classNames({ [css.active]: active })}
        to={{ pathname: path, state: { slugSet: currentSlugSet } }}
        onClick={() => (onCategoryClick ? onCategoryClick(category) : null)}
      >
        {title}
        {!leafCategory && isChild && (
          <span className="pls">
            <span
              className={classNames('fa fa-angle-right', css.chevron, {
                [css.activeChevron]: open,
              })}
            />
          </span>
        )}
      </Link>
      {open && !leafCategory && (
        <Collapse in={open}>
          <ul className="list-unstyled pbs">
            {children
              .filter((child: StoreNavCategory) => !child.hidden)
              .map((child: StoreNavCategory) => (
                <CategoryWithChildren
                  isChild
                  activeSlugSet={activeSlugSet}
                  category={child}
                  depth={depth + 1}
                  key={child.urlSlug}
                  parentSlugSet={currentSlugSet}
                  onCategoryClick={onCategoryClick}
                />
              ))}
          </ul>
        </Collapse>
      )}
    </li>
  )
}
