import React from 'react'

interface LinkProps {
  className?: string
}

const Link: React.FC<LinkProps> = ({ className }: LinkProps) => (
  <svg
    className={className}
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M9.55773 4.03525C11.3269 2.26608 14.1953 2.26608 15.9645 4.03525C17.7336 5.80442 17.7336 8.67281 15.9645 10.442L14.8599 11.5466C14.6158 11.7906 14.2201 11.7906 13.976 11.5466C13.7319 11.3025 13.7319 10.9067 13.976 10.6627L15.0806 9.5581C16.3616 8.27709 16.3616 6.20015 15.0806 4.91913C13.7996 3.63812 11.7226 3.63812 10.4416 4.91913L9.33705 6.0237C9.09297 6.26778 8.69724 6.26778 8.45316 6.0237C8.20908 5.77963 8.20908 5.3839 8.45316 5.13982L9.55773 4.03525ZM13.203 6.79668C13.4471 7.04075 13.4471 7.43648 13.203 7.68056L7.68019 13.2034C7.43611 13.4475 7.04039 13.4475 6.79631 13.2034C6.55223 12.9593 6.55223 12.5636 6.79631 12.3195L12.3192 6.79668C12.5632 6.5526 12.959 6.5526 13.203 6.79668ZM6.02334 8.45353C6.26742 8.69761 6.26742 9.09334 6.02334 9.33741L4.91877 10.442C3.63775 11.723 3.63775 13.7999 4.91877 15.0809C6.19978 16.362 8.27672 16.362 9.55773 15.0809L10.6623 13.9764C10.9064 13.7323 11.3021 13.7323 11.5462 13.9764C11.7903 14.2205 11.7903 14.6162 11.5462 14.8603L10.4416 15.9648C8.67245 17.734 5.80405 17.734 4.03488 15.9648C2.26572 14.1957 2.26572 11.3273 4.03488 9.5581L5.13945 8.45353C5.38353 8.20945 5.77926 8.20945 6.02334 8.45353Z"
      fill="#252324"
      fillRule="evenodd"
    />
  </svg>
)

export default Link
