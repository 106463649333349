import PropTypes from 'prop-types'
import Card from 'components/card'
import RegItemImage from 'registry/components/reg-item-image'
import { numberToCurrency } from 'lib/money'

const GroupGiftProductPreviewCard = ({ product }) => {
  const { image, name, price } = product
  return (
    <Card className="pal flex bg-white">
      <RegItemImage src={image} width={100} />
      <div className="mlm" style={{ minHeight: 100 }}>
        <div className="h4 mtn">{name}</div>
        {price && (
          <div className="flex">
            <span className="icon-offer icon-offer-babylist mrs" />
            <span>Babylist</span>
            <span className="phs text-muted">|</span>
            <span>{numberToCurrency(price)}</span>
          </div>
        )}
      </div>
    </Card>
  )
}

GroupGiftProductPreviewCard.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
  }),
}

GroupGiftProductPreviewCard.defaultProps = {
  product: {},
}

export default GroupGiftProductPreviewCard
