import { Component } from 'react'
import PropTypes from 'prop-types'
import Tappable from 'react-tappable'
import { Checkbox as BSCheckbox } from 'react-bootstrap'
import classNames from 'classnames'
import { buildComponent } from './build-component'
import { sizesProp } from './prop-types/size-props'

class ArrayCheckbox extends Component {
  render() {
    const { field, value, defaultChecked, ...rest } = this.props

    return (
      <BSCheckbox
        checked={field.value.indexOf(value) >= 0 || defaultChecked}
        onChange={(event) => {
          const index = field.value.indexOf(value)
          if (index < 0) {
            // wasn't selected
            if (event.target.checked) {
              // was checked
              field.onChange(field.value.concat(value))
            }
          } else {
            if (!event.target.checked) {
              // was unchecked
              const copy = [...field.value] // make copy to not mutate value
              copy.splice(index, 1) // remove item at index
              field.onChange(copy)
            }
          }
        }}
        {...rest}
      >
        {this.props.children}
      </BSCheckbox>
    )
  }
}

class Checkbox extends Component {
  static propTypes = {
    block: PropTypes.bool,
    inputClassName: PropTypes.string,
    checked: PropTypes.bool,
    tappable: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    groupClassName: PropTypes.string,
    help: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    size: sizesProp,
    value: PropTypes.any,
    style: PropTypes.object,
  }

  render() {
    let inputProps = {
      block: this.props.block,
      bsClass: classNames('checkbox', this.props.inputClassName),
      bsSize: this.props.size,
      checked: this.props.checked,
      defaultChecked: this.props.defaultChecked,
      disabled: this.props.disabled,
      inputRef: this.props.inputRef,
      label: this.props.label,
      name: this.props.name,
      onChange: this.props.onChange,
      placeholder: this.props.placeholder,
      readOnly: this.props.readOnly,
      size: this.props.size,
      value: this.props.value,
    }

    let checkboxComponent = buildComponent(
      <BSCheckbox
        className={classNames(this.props.className, {
          'checkbox--block': inputProps.block,
        })}
        inputRef={this.props.inputRef || this.setInputRef.bind(this)}
        {...inputProps}
      >
        <div className="label-text">{inputProps.label}</div>
      </BSCheckbox>,
      this.props
    )

    return this.props.tappable ? (
      <Tappable
        component="div"
        classBase="input-tappable"
        className="input-tappable"
      >
        {checkboxComponent}
      </Tappable>
    ) : (
      checkboxComponent
    )
  }

  setInputRef(ref) {
    this._input = ref
  }
}

export { ArrayCheckbox, Checkbox as default }
