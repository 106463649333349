import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'baby-design'
import { errorMessage, successMessage } from 'lib/flash-message'
import * as api from 'store/api'

const InStockAlertButton = ({ productId, text }) => {
  const [addingInStockAlert, setAddingInStockAlert] = useState(false)

  const handleClick = () => {
    setAddingInStockAlert(true)
    api
      .addProductAvailabilitySubscription(productId)
      .then(() => {
        successMessage("We'll email you when this item is back in stock!")
        setAddingInStockAlert(false)
      })
      .catch(() => {
        errorMessage()
        setAddingInStockAlert(false)
      })
  }

  return (
    <Button
      loading={addingInStockAlert}
      size="sm"
      variant="tertiary"
      onClick={handleClick}
    >
      {text}
    </Button>
  )
}

InStockAlertButton.propTypes = {
  buttonStyle: PropTypes.object,
  productId: PropTypes.number.isRequired,
  text: PropTypes.string,
}

InStockAlertButton.defaultProps = {
  text: 'Get in stock alert',
}

export default InStockAlertButton
