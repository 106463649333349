import { useState, useEffect } from 'react'
import Confetti from 'react-confetti'
import useWindowSize from 'lib/hooks/useWindowSize'

// brand colors pulled from variables.scss
const brandColors = [
  '#ff3b40',
  '#6e3264',
  '#00b496',
  '#ff7a5e',
  '#fac2b8',
  '#00a3c7',
  '#b5edd1',
  '#ff7aab',
  '#ffd200',
]

const withConfetti =
  ({ numberOfPieces, onConfettiComplete = () => {} }, recycle) =>
  (Component) => {
    const ConfettiWrapper = (componentProps) => {
      const [renderComponent, setRenderComponent] = useState(false)
      const { width, height } = useWindowSize()

      useEffect(() => {
        setRenderComponent(true)
      }, [])

      return (
        <>
          {renderComponent && (
            <Confetti
              width={width}
              height={height}
              recycle={recycle || false}
              numberOfPieces={numberOfPieces || 200}
              colors={brandColors}
              onConfettiComplete={() => {
                onConfettiComplete()
                setRenderComponent(false)
              }}
            />
          )}
          <Component {...componentProps} />
        </>
      )
    }
    return ConfettiWrapper
  }

export default withConfetti

// see props in the react-confetti docs: https://github.com/alampros/react-confetti
