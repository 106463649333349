/* eslint-disable camelcase */ /* TODO: should the API be camelCase? */
import React, { useEffect, useMemo } from 'react'

import { track } from 'lib/analytics'
import { BannerStrip } from 'baby-design'

import { getPlacementScreen } from 'lib/placements/getPlacementScreen'

interface AdContent {
  title: string
  cta_url?: string
  cta_text?: string
}

interface PlacementAd {
  ad_id: string
  content_type: string
  content: AdContent
  screens?: string[]
}

interface AdProps {
  ad: PlacementAd
  unitId: string
}

export interface PlacementsGlobalBannerProps {
  unitId: string
  initialAds?: PlacementAd[]
  initialPath: string
  initialHost: string
}

const Ad: React.FC<AdProps> = ({ unitId, ad }) => {
  const trackingContext = useMemo(
    () => ({
      eventLocation: track.EventLocation.WEB_TOP_NAV,
      adId: ad.ad_id,
      adTitle: ad.content.title,
      adUnitId: unitId,
      adCtaText: ad.content.cta_text || '',
    }),
    [ad, unitId]
  )

  useEffect(() => {
    track.targetedPlacementViewed(trackingContext)
  }, [trackingContext])

  const handleCtaClick = () => {
    track.targetedPlacementClicked(trackingContext)
  }

  const { ad_id, content } = ad
  return (
    <span data-testid={`adContent-${ad_id}`} key={`adContent-${ad_id}`}>
      {content.title}
      {content.cta_url && (
        <>
          {' '}
          <a href={content.cta_url} onClick={handleCtaClick}>
            {content.cta_text}
          </a>
        </>
      )}
    </span>
  )
}

/*
  **LIMITATION**
  The current implementation does not support refiltering on client side navigation.
  This is a design limitation since we do not want to have a page shift.
  For our near term usecase (black friday etc.), we are okay with this limitation as we do
  not have client side navigation across different domains of the website (eg: shop, registry, guides.)
  We do have client side navigation within the same domain (eg: shop category, shop brand, shop pdp)
  And for now we will want to make sure all those pages can show a banner.
*/
const filteredAds = (ads: PlacementAd[] | undefined, currentScreen: string) => {
  if (!ads || ads.length === 0) {
    return []
  }
  return ads.filter((ad) => {
    if (!ad.screens || ad.screens.length === 0) {
      return true
    }
    return ad.screens.some((screen) => screen === currentScreen)
  })
}

const PlacementsGlobalBanner: React.FC<PlacementsGlobalBannerProps> = ({
  unitId,
  initialAds,
  initialPath,
  initialHost,
}) => {
  let pathname = initialPath
  let host = initialHost
  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
    // eslint-disable-next-line prefer-destructuring
    host = window.location.host
  }

  const currentScreen = getPlacementScreen(pathname, host)
  const ads = filteredAds(initialAds, currentScreen)

  if (!ads || ads.length === 0) {
    return null
  }

  const adIndex = 0 // TODO: multiple rotating ads

  // TODO: dismissable banner
  return (
    <BannerStrip data-testid={`globalBanner-${unitId}`}>
      <Ad ad={ads[adIndex]} unitId={unitId} />
    </BannerStrip>
  )
}

export default PlacementsGlobalBanner
