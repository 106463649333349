/* This is sad, but doing this until we have a declaration file */
// @ts-nocheck
import { useState } from 'react'
import classNames from 'classnames'
import { getBooleanCookie, setCookies } from 'lib/cookies'
import Cookies from 'js-cookie'
import { ModalBody, ModalHeader, ModalTitle, Col, Row } from 'react-bootstrap'

import { Modal } from 'components/modal'
import { MultiStepWizard } from 'components/presentation'
import { InfoButton } from 'components/buttons'
import { GiftGiverInfoForm } from 'cart/components/gift-giver-info-form'
import { useTracking, track } from 'lib/analytics'
import { NEW_RESERVATION_ID, RESERVE_ACTION_SHOP } from 'cart/constants'
import RegItemImage from 'registry/components/reg-item-image'
import { UPDATE_CART_SUMMARY } from 'shared/constants'
import { absoluteUrl, reservationsPath } from 'lib/urls'
import { InfoIcon } from 'components/icons'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import css from './add-to-cart-modal.scss'
import ThankYouAddressModal from '../../../registry/components/modals/ThankYouAddressModal'
import { Registry } from 'src/types/registry'

const FIRST_STEP = 1
const SECOND_STEP = 2
const THIRD_STEP = 3
const FOURTH_STEP = 4

export interface AddToCartModalProps {
  regItem: {
    id: number
    registryId: number
    price: string | number
    imgUrl: string
    isGiftCard: boolean
  }
  onHide(): void
  onSubmit(reservation: any): any
  registry?: Registry
  isGiftGiverThankYouAddressProvided?: boolean
}

interface FormValues {
  email: string
  giftAmount?: string
  name: string
  productId: number
  quantity?: string
}
const shouldSkipFirstStep = (email, regItem) => !!email || regItem.isGiftCard
export const AddToCartModal = ({
  regItem,
  onHide,
  onSubmit,
  registry,
  isGiftGiverThankYouAddressProvided = false,
}: AddToCartModalProps) => {
  const [currentUser] = useCurrentUser()
  const email = Cookies.get('email')
  const name = Cookies.get('name')
  const [activeStep, setActiveStep] = useState(() =>
    shouldSkipFirstStep(email, regItem) ? SECOND_STEP : FIRST_STEP
  )
  const { imgUrl, title } = regItem

  const { firstNames: registryNames } = registry || {}

  const isRegistryOwnerOrPartner = currentUser?.registries.some(
    (r) => r.id === registry?.id && (r.role === 'owner' || r.role === 'partner')
  )

  const tracker = useTracking()

  const nextStep = () => {
    setActiveStep(SECOND_STEP)
  }

  const handleSubmit = ({
    email,
    giftAmount,
    name,
    productId,
    quantity,
    isSurprise,
  }: FormValues): void => {
    const reservation = {
      id: NEW_RESERVATION_ID,
      regItemId: regItem.id,
      regItem,
      registryId: regItem.registryId,
      email,
      name,
      productId,
      quantity: quantity || 1,
      giftAmount,
      subscribeToMarketingEmail: getBooleanCookie({
        name: 'subscribeToMarketingEmail',
        defaultValue: true,
      }),
    }

    if (isSurprise) {
      reservation['preferences'] = { surprise: { isSurprise } }
    }

    const trackAddToCart = () => {
      tracker.trackEvent({
        cartItemQuantity: quantity ? parseInt(quantity) : 1,
        cartTypes: [track.CartTypes.STORE],
        cartStores: [track.CartStores.BABYLIST_STORE],
        storeAdded: track.StoreAdded.BABYLIST,
        productPrice:
          (giftAmount && parseFloat(giftAmount)) || parseFloat(regItem.price),
        productType: giftAmount
          ? track.ProductType.GIFT_CARD
          : track.ProductType.PRODUCT,
        regItem,
        eventCta: RESERVE_ACTION_SHOP,
        event: track.addedToCart,
        eventType: track.EventType.ADDED_TO_STORE_RESERVATION,
        subscribeToMarketingEmail: reservation.subscribeToMarketingEmail,
      })
    }

    return onSubmit(reservation).then(() => {
      setCookies({ name, email })
      trackAddToCart()

      if (
        regItem.isFavor &&
        !isGiftGiverThankYouAddressProvided &&
        !isRegistryOwnerOrPartner
      ) {
        setActiveStep(THIRD_STEP)
      } else {
        setActiveStep(FOURTH_STEP)
      }

      /* @ts-ignore */
      PubSub.publish(UPDATE_CART_SUMMARY, {
        showCartDropdown: false,
        animateBadge: true,
      })
    })
  }

  return (
    <Modal show size="large" onHide={onHide}>
      <MultiStepWizard activeStep={activeStep} animateInitialState={false}>
        {/* Step 1 */}
        <div>
          <ModalHeader
            closeButton
            onHide={onHide}
            className={classNames('with-title')}
          >
            <ModalTitle className={classNames('h3')}>
              <strong>Heads-up!</strong>
            </ModalTitle>
          </ModalHeader>
          <ModalBody className={css.commitText}>
            <p className="mbl h5">
              Adding an item to your cart will make it unavailable for others to
              purchase.
            </p>
            <p className="mbl h5">
              <span style={{ paddingRight: '10px' }}>🤓︎</span>
              Items in your cart will be removed in 2 days so that someone else
              can buy them
              {registryNames ? ` for ${registryNames}.` : '.'}
            </p>
            <InfoButton onClick={nextStep} pill className={css.continueButton}>
              Got it
            </InfoButton>
          </ModalBody>
        </div>
        {/* Step 2 */}
        <div>
          <ModalHeader
            closeButton
            onHide={onHide}
            className={classNames('with-title')}
          >
            <ModalTitle className="h3" style={{ textAlign: 'center' }}>
              Who is this from?
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div>
              <GiftGiverInfoForm
                onFormSubmit={handleSubmit}
                regItem={regItem}
                registrantNames={registryNames}
              />
            </div>
          </ModalBody>
        </div>
        {/* Step 3 */}
        <ThankYouAddressModal
          afterSubmit={() => setActiveStep(FOURTH_STEP)}
          giftGiverInfo={{ name, email }}
          onHide={onHide}
        />
        {/* Step 4 */}
        <div>
          <ModalHeader
            closeButton
            onHide={onHide}
            className={classNames('with-title')}
          >
            <ModalTitle
              className={classNames('h3')}
              style={{ textAlign: 'center' }}
            >
              Added to cart!
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={6}>
                <RegItemImage className="mbl" src={imgUrl} width={300} />
              </Col>
              <Col xs={6}>
                <div>{title}</div>
              </Col>
            </Row>
            <div className="flex well text-left man mbm">
              <InfoIcon height={30} width={30} className="mrm" />
              <span>
                Heads up! Items in your cart will be removed in 2 days so that
                someone else can buy them.
              </span>
            </div>
            <div className="flex">
              <InfoButton
                className="mrs"
                empty
                pill
                style={{ width: '50%' }}
                onClick={onHide}
              >
                Keep Shopping
              </InfoButton>
              <InfoButton
                href={absoluteUrl(reservationsPath)}
                className="mls"
                pill
                style={{ width: '50%' }}
              >
                Checkout
              </InfoButton>
            </div>
          </ModalBody>
        </div>
      </MultiStepWizard>
    </Modal>
  )
}
