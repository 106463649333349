import { Dispatch, SetStateAction } from 'react'
// @ts-ignore
import { reduxForm } from 'redux-form'
// @ts-ignore
import { AddressSuggestionForm } from 'shared/address-forms/address-suggestion-form'
// @ts-ignore
import { GiftGiverInfoValues } from '../../../../cart/components/reserve-purchased-gift-modal/reserve-purchased-gift-modal'

export interface AddressSuggestion {
  name?: string
  streetAddress1?: string
  streetAddress2?: string
  city?: string
  zip?: string
  state?: string
  country?: string
  phone?: string
}

export type FieldErrors = { [key: string]: string }[]

export const getWrappedSuggestionForm = (
  setActiveStep: Dispatch<SetStateAction<number>>,
  handleAfterSubmit: () => void,
  handleSubmit: (address: {
    [key: string]: string | number | boolean
  }) => Promise<any>,
  fieldErrors: FieldErrors | undefined,
  enteredAddress: AddressSuggestion,
  recommendedAddress: AddressSuggestion | undefined
) =>
  reduxForm(
    {
      form: 'addressSuggestionForm',
      fields: ['addressOption'],
    },
    () => ({
      initialValues: { addressOption: 'recommended' },
      onEdit: () => setActiveStep(1),
      onSubmitSuccess: handleAfterSubmit,
      onConfirm: handleSubmit,
      fieldErrors,
      enteredAddress,
      recommendedAddress,
    })
  )(AddressSuggestionForm)

export const getHandleSubmit =
  (
    dispatch: (action: () => any) => Promise<any>,
    saveGiftGiverAddress: (payload: any) => any,
    giftGiverInfo: GiftGiverInfoValues
  ) =>
  (address: { [key: string]: number | string | boolean }) =>
    dispatch(
      saveGiftGiverAddress({
        userConfirmed: false,
        email: giftGiverInfo.email,
        ...address,
      })
    )

export const getHandleSubmitFail =
  (
    setRecommendedAddress: Dispatch<
      SetStateAction<AddressSuggestion | undefined>
    >,
    setEnteredAddress: Dispatch<SetStateAction<AddressSuggestion>>,
    setFieldErrors: Dispatch<SetStateAction<FieldErrors | undefined>>,
    setActiveStep: Dispatch<SetStateAction<number>>
  ) =>
  (resp: { [key: string]: any }) => {
    const { address, recommendedAddress: ra, fieldErrors: fe } = resp
    if (ra) {
      setRecommendedAddress({
        name: address.name,
        streetAddress1: ra.address1,
        streetAddress2: ra.address2,
        city: ra.city,
        zip: ra.zip,
        state: ra.state,
        country: address.country,
      })
    }

    if (fe) {
      setFieldErrors(fe)
    }

    if (address) {
      setEnteredAddress({
        name: address.name,
        streetAddress1: address.address1,
        streetAddress2: address.address2,
        city: address.city,
        zip: address.zip,
        state: address.state,
        country: address.country,
      })

      setActiveStep(2)
    }
  }
