/* eslint-disable complexity */
/* eslint-disable react/static-property-placement */
import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  ControlLabel as BSControlLabel,
  FormControl as BSFormControl,
  FormGroup as BSFormGroup,
  HelpBlock as BSHelpBlock,
  InputGroup as BSInputGroup,
} from 'react-bootstrap'
import classNames from 'classnames'
import { forEach, isUndefined } from 'lodash'
import { sizesProp } from './prop-types/size-props'

const sizeClasses = {
  small: 'input-sm',
  medium: 'input-md',
  large: 'input-lg',
  sm: 'input-sm',
  md: 'input-md',
  lg: 'input-lg',
}

const validateLabel = (propVals, propName, componentName) => {
  const { label, type } = propVals

  if (type === 'hidden') return null

  const ariaLabel = propVals['aria-label']
  if (!label && !ariaLabel) {
    return new Error(
      `Invalid props supplied to '${componentName}'. One of props 'label' or 'aria-label' is required.`
    )
  }
  if (propVals[propName] && typeof propVals[propName] !== 'string') {
    return new Error(
      `Invalid prop, ${propName} supplied to '${componentName}'. Expecting type 'string', received ${propVals[propName]}`
    )
  }
  return null
}

const randomID = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)

const wrapWithInputGroup = (
  formControl,
  { addonStyle, addonBefore, addonAfter, inputGroupProps }
) => (
  <BSInputGroup {...inputGroupProps}>
    {addonBefore && (
      <BSInputGroup.Addon
        className="input-group-addon--before"
        style={addonStyle}
      >
        {addonBefore}
      </BSInputGroup.Addon>
    )}
    {formControl}
    {addonAfter && (
      <BSInputGroup.Addon
        className="input-group-addon--after"
        style={addonStyle}
      >
        {addonAfter}
      </BSInputGroup.Addon>
    )}
  </BSInputGroup>
)

export default class Input extends PureComponent {
  static propTypes = {
    addonAfter: PropTypes.node,
    addonBefore: PropTypes.node,
    addonStyle: PropTypes.object,
    autoFocus: PropTypes.bool,
    'aria-label': validateLabel,
    autoComplete: PropTypes.string,
    autoCapitalize: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
    className: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.node,
    ]),
    groupClassName: PropTypes.string,
    help: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.node,
    ]),
    groupStyle: PropTypes.object,
    id: PropTypes.string,
    label: validateLabel,
    labelAddon: PropTypes.node,
    labelClassName: PropTypes.string,
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxLength: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    pattern: PropTypes.string,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    rows: PropTypes.number,
    size: sizesProp,
    step: PropTypes.number,
    style: PropTypes.object,
    type: PropTypes.string,
    validationState: PropTypes.string, // Deprecated.  See error instead
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    wrapperClassName: PropTypes.string,
  }

  static defaultProps = {
    type: 'text',
  }

  render() {
    let inputClasses = classNames(
      this.props.className,
      sizeClasses[this.props.size || 'medium'],
      {
        'input--underline': !!this.props.underline,
        'input--addonBefore': this.props.addonBefore,
        'input--addonAfter': this.props.addonAfter,
      }
    )
    let value = this.props.value === null ? '' : this.props.value // Satisfy react null-value-for-controlled-component errors
    let inputProps = {
      'aria-label': this.props['aria-label'],
      autoFocus: this.props.autoFocus,
      autoComplete: this.props.autoComplete,
      autoCapitalize: this.props.autoCapitalize,
      bsSize: this.props.size,
      bsStyle: this.props.validationState || this.props.error ? 'error' : null,
      children: this.props.children,
      className: inputClasses,
      defaultValue: this.props.defaultValue,
      disabled: this.props.disabled,
      id: this.props.id,
      inputRef: this.props.inputRef,
      label: this.props.label,
      min: this.props.min,
      max: this.props.max,
      maxLength: this.props.maxLength,
      name: this.props.name,
      onChange: this.props.onChange,
      onBlur: this.props.onBlur,
      onFocus: this.props.onFocus,
      onKeyDown: this.props.onKeyDown,
      pattern: this.props.pattern,
      placeholder: this.props.placeholder,
      readOnly: this.props.readOnly,
      required: this.props.required,
      rows: this.props.rows,
      step: this.props.step,
      style: this.props.style,
      type: this.props.type,
      value,
    }

    const inputID = this.props.id || randomID()

    let formControl = (
      <BSFormControl
        {...inputProps}
        id={inputID}
        inputRef={this.props.inputRef || this.setInputRef.bind(this)}
        type={this.props.type || 'text'}
        componentClass={this.props.type === 'textarea' ? 'textarea' : 'input'}
      />
    )

    if (this.props.type == 'hidden') return formControl

    return (
      <BSFormGroup
        className={classNames(this.props.groupClassName, {
          'input--disabled': this.props.disabled,
        })}
        validationState={this.props.error ? 'error' : null}
        style={this.props.groupStyle}
        aria-hidden={this.props['aria-hidden']}
      >
        {this.props.label ? (
          <BSControlLabel htmlFor={inputID}>
            {this.props.label}
            {this.props.labelAddon ? (
              <div className="pull-right">{this.props.labelAddon}</div>
            ) : null}
          </BSControlLabel>
        ) : null}
        {this.hasAddons()
          ? wrapWithInputGroup(formControl, {
              addonStyle: this.props.addonStyle,
              addonBefore: this.props.addonBefore,
              addonAfter: this.props.addonAfter,
              inputGroupProps: { bsSize: this.props.size },
            })
          : formControl}
        {this.props.error || this.props.help ? (
          <BSHelpBlock
            className={classNames('h7', { 'text-muted': !this.props.error })}
          >
            {/* if an element has help text and an error just show the error. otherwise show help text */}
            {this.props.error || this.props.help}
          </BSHelpBlock>
        ) : null}
      </BSFormGroup>
    )
  }

  hasAddons() {
    return (
      !isUndefined(this.props.addonBefore) ||
      !isUndefined(this.props.addonAfter)
    )
  }

  setInputRef(ref) {
    this._input = ref
  }
}
