import { Component } from 'react'
import PropTypes from 'prop-types'
import { map, range } from 'lodash'
import { Select } from './index'

export default class NumberSelect extends Component {
  static propTypes = {
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    optionPrefix: PropTypes.string,
    optionSuffix: PropTypes.string,
  }

  render() {
    const { min, max, optionPrefix, optionSuffix, ...props } = this.props
    return (
      <Select {...props}>
        {map(range(min, max + 1), (num) => (
          // Use string interpolation to built the option text b/c of an issue with v 0.14.9 of the React Developer Tools
          <option key={num} value={num}>
            {`${optionPrefix ? optionPrefix : ''}${num}${
              optionSuffix ? optionSuffix : ''
            }`}
          </option>
        ))}
      </Select>
    )
  }
}
