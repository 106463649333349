import { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/modal/modal'
import { ModalBody } from 'react-bootstrap'
import OwnerReserveForm from '../owner-reserve-form'

export default class OwnerReserveModal extends Component {
  static defaultProps = {
    onHide: PropTypes.func.isRequired,
    regItem: PropTypes.object.isRequired,
  }

  render() {
    return (
      <Modal show onHide={this.props.onHide} title="Reservation Information">
        <ModalBody>
          <OwnerReserveForm
            regItem={this.props.regItem}
            onSubmitSuccess={this.props.onHide}
          />
        </ModalBody>
      </Modal>
    )
  }
}
