import {
  SET_APPLY_REGISTRY_DISCOUNT,
  SET_CART,
  SET_AMAZON_CART,
  SET_PRODUCT_RECOMMENDATIONS,
} from '../actions'

const cartReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_APPLY_REGISTRY_DISCOUNT:
      return { ...state, applyRegistryDiscount: action.applyRegistryDiscount }
    case SET_CART:
      return {
        ...state,
        ...action.cart,
      }
    case SET_AMAZON_CART:
      return {
        ...state,
        amazonCart: action.cart,
      }
    case SET_PRODUCT_RECOMMENDATIONS:
      return {
        ...state,
        productRecommendations: action.productRecommendations,
      }
    default:
      return state
  }
}

export const getCartItems = (state) => state.items
export const getProductRecommendations = (state) => state.productRecommendations

export default cartReducer
