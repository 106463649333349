import { parseErrors } from '../../lib/api-utils'
import * as api from '../api'

export const SET_CASH_FUNDS_CONFIG = 'SET_CASH_FUNDS_CONFIG'
export const setCashFundsConfig = (cashFunds) => ({
  type: SET_CASH_FUNDS_CONFIG,
  cashFunds,
})

export const SET_IS_FETCHING_CASH_FUNDS_CONFIG =
  'SET_IS_FETCHING_CASH_FUNDS_CONFIG'

export const startFetchingCashFundsConfig = {
  type: SET_IS_FETCHING_CASH_FUNDS_CONFIG,
  isFetchingCashFundsConfig: true,
}
export const finishedFetchingCashFundsConfig = {
  type: SET_IS_FETCHING_CASH_FUNDS_CONFIG,
  isFetchingCashFundsConfig: false,
}

export const SET_CATALOG_CATEGORIES_CONFIG = 'SET_CATALOG_CATEGORIES_CONFIG'
export const setCatalogCategoriesConfig = (catalogCategories) => ({
  type: SET_CATALOG_CATEGORIES_CONFIG,
  catalogCategories,
})

export const SET_IS_FETCHING_CATALOG_CATEGORIES_CONFIG =
  'SET_IS_FETCHING_CATALOG_CATEGORIES_CONFIG'

export const startFetchingCatalogCategoriesConfig = {
  type: SET_IS_FETCHING_CATALOG_CATEGORIES_CONFIG,
  isFetchingCatalogCategoriesConfig: true,
}
export const finishedFetchingCatalogCategoriesConfig = {
  type: SET_IS_FETCHING_CATALOG_CATEGORIES_CONFIG,
  isFetchingCatalogCategoriesConfig: false,
}

export const SET_BOOKMARKLET_JS = 'SET_BOOKMARKLET_JS'
export const setBookmarkletJS = (bookmarkletJS) => ({
  type: SET_BOOKMARKLET_JS,
  bookmarkletJS,
})

export const SET_SHOW_EXTENSION_INSTALL = 'SET_SHOW_EXTENSION_INSTALL'
export const setShowExtensionInstall = (value) => ({
  type: SET_SHOW_EXTENSION_INSTALL,
  value,
})

// TODO-MAX: Methods that fetch config should only fetch if the data is not already in the
// store and is not already fetching the requested data.
// This data is static and will not change between fetches, So it is unnecessary to re-fetch.

export const fetchCashFundsConfig = () => {
  return (dispatch) => {
    dispatch(startFetchingCashFundsConfig)
    return api
      .getCashFunds()
      .then((resp) => {
        dispatch(setCashFundsConfig(resp.cashFunds))
        dispatch(finishedFetchingCashFundsConfig)
      })
      .catch((resp) => {
        dispatch(finishedFetchingCashFundsConfig)
        Promise.reject(parseErrors(resp))
      })
  }
}

export const fetchCatalogCategoriesConfig = () => {
  return (dispatch) => {
    dispatch(startFetchingCatalogCategoriesConfig)
    return api
      .getCatalogCategories()
      .then((resp) => {
        dispatch(setCatalogCategoriesConfig(resp.catalogCategories))
        dispatch(finishedFetchingCatalogCategoriesConfig)
      })
      .catch((resp) => {
        dispatch(finishedFetchingCatalogCategoriesConfig)
        Promise.reject(parseErrors(resp))
      })
  }
}
