/* eslint-disable complexity */
import AuthorByline from 'hello_baby/components/AuthorByline'
import { sectionAnchor } from 'hello_baby/lib/helpers'
import FTCDisclosureTag from 'components/FTCDisclosureTag'
import { endOfDay } from 'date-fns'
import classnames from 'classnames'
import dateformat from 'dateformat'
import { SectionHeading } from 'components/headings'
import TopicList from 'hello_baby/components/TopicList'
// @ts-ignore
import Tappable from 'react-tappable'
import { useState } from 'react'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
// eslint-disable-next-line import/no-extraneous-dependencies
import { IconButton, Image } from 'baby-design'
import { Chevron } from 'baby-design/icons'
import css from './AboveTheFold.styles.scss'

export interface AboveTheFoldProps {
  post: Post
}

// eslint-disable-next-line complexity
export const AboveTheFold = ({ post }: AboveTheFoldProps) => {
  const isRebrand = useFeatureFlag('guides-above-the-fold', false).flagValue
  const { featuredImage, medicallyReviewedBy, factCheckedBy } = post
  const [showContributers, setShowContributers] = useState(false)
  const hasContributers = medicallyReviewedBy || factCheckedBy
  const handleCollapse = () => {
    setShowContributers(!showContributers)
  }
  const publishedDate = dateformat(
    endOfDay(new Date(post.publishedAt)),
    'mmm d, yyyy'
  )
  const updatedDate = dateformat(
    endOfDay(new Date(post.updatedAt as string)),
    'mmm d, yyyy'
  )

  return (
    <section className={css.AboveTheFold__container}>
      <div className={css.maxWidth}>
        <div className={css.aboveTheFold__imageContainer}>
          {featuredImage && (
            <Image
              className={css.titleImage}
              src={featuredImage?.url}
              style={{ aspectRatio: '3/2' }}
            />
          )}
          {featuredImage?.attribution && (
            <div
              className={classnames(
                css.aboveTheFold__attributionText,
                css.desktopAttributionText,
                'h7 mts'
              )}
            >
              {`Photo by `}
              {featuredImage?.attributionUrl ? (
                <a
                  href={featuredImage?.attributionUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  {featuredImage?.attribution}
                </a>
              ) : (
                featuredImage?.attribution
              )}
            </div>
          )}
        </div>
        <div className={css.articleInformationContainer}>
          <div className={css.displayTopics}>
            <TopicList
              className={classnames(css.topics, 'hidden-xs')}
              topics={post.meta.topics}
            />
          </div>
          <SectionHeading
            className={css.AboveTheFold__title}
            id={sectionAnchor(post.title)}
          >
            {post.title}
          </SectionHeading>
          {post.teaser && <h2 className={css.postTeaser}>{post.teaser}</h2>}
          <div className={css.contributionsContainer}>
            {post.author && post.author.length > 0 && (
              <img
                alt={`author-${post.author[0].name}`}
                className={css.AboevTheFold__authorImage}
                src={post.author[0].photo.url}
              />
            )}
            <div className={css.contributers_margin}>
              {post.author && (
                <AuthorByline
                  post={post}
                  showContributersMobile={showContributers}
                />
              )}
            </div>
            <div className={css.dates}>
              <span className={css.publishedAt}>
                <>Published </> {publishedDate}
              </span>
              {post.updatedAt && (
                <span className={css.publishedAt}>
                  <>Updated On </> {updatedDate}
                </span>
              )}
            </div>
            {hasContributers && (
              <div className={css.showOnMobile}>
                <IconButton
                  aria-label="Toggle Contributers"
                  className={classnames(css.chevron, {
                    [css.activeChevron]: showContributers,
                  })}
                  size="xs"
                  variant="fill-tertiary"
                  onClick={handleCollapse}
                >
                  <Chevron />
                </IconButton>
              </div>
            )}
          </div>
          <FTCDisclosureTag
            align="left"
            className="mbl"
            disclaimerContext={
              isRebrand
                ? (FTCDisclosureTag.Constants.GUIDEREBRAND as 'guideRebrand')
                : (FTCDisclosureTag.Constants.GUIDE as 'guide')
            }
          />
        </div>
      </div>
      {post.featuredImage && (
        <Image
          className={css.mobileTitleImage}
          src={post.featuredImage?.url}
          style={{ aspectRatio: '3/2' }}
        />
      )}
      {featuredImage?.attribution && (
        <div
          className={classnames(
            css.aboveTheFold__attributionText,
            css.mobileAttributionText,
            'h7'
          )}
        >
          {`Photo by `}
          {featuredImage?.attributionUrl ? (
            <a
              href={featuredImage?.attributionUrl}
              rel="noreferrer"
              target="_blank"
            >
              {featuredImage?.attribution}
            </a>
          ) : (
            featuredImage?.attribution
          )}
        </div>
      )}
    </section>
  )
}
