import { InfoButton } from 'components/buttons'
import { absoluteUrl, storePath } from 'lib/urls'
import css from './EmptyInbox.scss'

const EmptyInbox = () => (
  <div className={css.EmptyInboxContainer}>
    <h5 className={css.EmptyInboxHeader}>No messages, yet.</h5>
    <p className={css.EmptyInboxCopy}>Window shopping in the meantime?</p>
    <InfoButton
      pill
      className={css.EmptyInboxCTA}
      href={absoluteUrl(storePath)}
    >
      Browse Babylist Shop
    </InfoButton>
  </div>
)

export default EmptyInbox
