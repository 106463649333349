import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from 'components/link'

export const BackLink = ({
  children,
  className,
  onClick,
  to,
  url,
  routerVersion = 3,
}) => {
  return (
    <Link
      className={classNames('h7', className)}
      onClick={onClick}
      to={to}
      url={url}
      routerVersion={routerVersion}
    >
      <i
        className={classNames('fa fa-angle-left')}
        style={{ fontSize: 18, marginRight: 7, position: 'relative', top: 1 }}
      ></i>
      {children}
    </Link>
  )
}

BackLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  url: PropTypes.string,
}

export default BackLink
