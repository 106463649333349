import { dehydrate, Hydrate, QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from 'src/queryClient'
import Header from './header'

const Component = (props) => (
  <QueryClientProvider client={queryClient}>
    <Hydrate state={dehydrate(queryClient)}>
      <Header {...props} />
    </Hydrate>
  </QueryClientProvider>
)

export { Component as default }
