import {
  SET_BOOKMARKLET_JS,
  SET_CASH_FUNDS_CONFIG,
  SET_IS_FETCHING_CASH_FUNDS_CONFIG,
  SET_CATALOG_CATEGORIES_CONFIG,
  SET_IS_FETCHING_CATALOG_CATEGORIES_CONFIG,
  SET_SHOW_EXTENSION_INSTALL,
} from '../actions/application-configuration'

const initialState = {
  cashFunds: [],
  catalogCategories: [],
}

export default (state = initialState, action) => {
  const newState = { ...state }

  switch (action.type) {
    case SET_CASH_FUNDS_CONFIG:
      newState.cashFunds = action.cashFunds
      return newState

    case SET_CATALOG_CATEGORIES_CONFIG:
      newState.catalogCategories = action.catalogCategories
      return newState

    case SET_BOOKMARKLET_JS:
      newState.bookmarkletJS = action.bookmarkletJS
      return newState

    case SET_SHOW_EXTENSION_INSTALL:
      newState.showExtensionInstall = action.value
      return newState

    case SET_IS_FETCHING_CASH_FUNDS_CONFIG:
      return {
        ...state,
        isFetchingCashFundsConfig: action.isFetchingCashFundsConfig,
      }

    case SET_IS_FETCHING_CATALOG_CATEGORIES_CONFIG:
      return {
        ...state,
        isFetchingCatalogCategoriesConfig:
          action.isFetchingCatalogCategoriesConfig,
      }

    default:
      return newState
  }
}

export const getCashFunds = (state) => state.cashFunds
export const getCatalogCategoriesConfig = (state) => state.catalogCategories
export const getBookmarkletJS = (state) => state.bookmarkletJS
export const getShowExtensionInstall = (state) => state.showExtensionInstall
export const getIsFetchingCashFundsConfig = (state) =>
  state.isFetchingCashFundsConfig
export const getIsFetchingCatalogCategoriesConfig = (state) =>
  state.isFetchingCatalogCategoriesConfig
