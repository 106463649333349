import React from 'react'

interface ThumbKnobProps {
  className?: string
}

const ThumbKnob: React.FC<ThumbKnobProps> = ({ className }: ThumbKnobProps) => (
  <svg
    className={className}
    fill="none"
    height="36"
    viewBox="0 0 36 36"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_9930_6094)">
      <circle cx="17.9944" cy="16" fill="white" r="14" />
      <circle cx="17.9944" cy="16" r="13.5" stroke="#E9E8E8" />
    </g>
    <defs>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="36"
        id="filter0_d_9930_6094"
        width="36"
        x="-0.00561523"
        y="0"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.286275 0 0 0 0 0.27451 0 0 0 0 0.278431 0 0 0 0.24 0"
        />
        <feBlend
          in2="BackgroundImageFix"
          mode="normal"
          result="effect1_dropShadow_9930_6094"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_9930_6094"
          mode="normal"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default ThumbKnob
