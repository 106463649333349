import { Component } from 'react'
import PropTypes from 'prop-types'
import ImageUploadPreviewer from 'shared/image-upload-previewer/image-upload-previewer'
import { Input } from 'components/forms'
import FavoriteToggle from 'shared/reg-item-form/components/favorite-toggle'
import classNames from 'classnames'
import Quantity from 'components/quantity'
import CategorySelector from './CategorySelector'
import css from './reg-item-form.scss'

export default class FavorFields extends Component {
  static propTypes = {
    categories: PropTypes.array,
    regItem: PropTypes.object,
    onRegItemChange: PropTypes.func.isRequired,
    onNewCategoryChange: PropTypes.func.isRequired,
  }

  onImageChange = ({ imgUrl }) => {
    this.props.onRegItemChange({ imgUrl })
  }

  handleChange = (e) => {
    this.props.onRegItemChange({ [e.target.name]: e.target.value })
  }

  onFavoriteChange = (isChecked) => {
    this.props.onRegItemChange({ isFavorite: isChecked })
  }

  handleQuantityChange = (value) => {
    this.props.onRegItemChange({ quantity: value })
  }

  render() {
    const regItemFields = this.props.regItem

    return (
      <>
        <div className={css.item}>
          <div className={classNames(css.imageContainer, 'mtm')}>
            <ImageUploadPreviewer
              imgUrl={regItemFields.imgUrl}
              onChange={this.onImageChange}
            />
          </div>

          <div className={css.itemFields}>
            <Input
              error={regItemFields.title ? null : 'Title cannot be blank'}
              label="Title"
              name="title"
              value={regItemFields.title}
              onChange={this.handleChange}
            />

            <div className="inline-form mbl">
              <Quantity
                unlimited
                className={css.flexGrow}
                value={regItemFields.quantity}
                onChange={this.handleQuantityChange}
              />
            </div>

            <div className="inline-form">
              <CategorySelector
                categories={this.props.categories}
                groupClassName={css.flexGrow}
                name="categoryId"
                value={regItemFields.categoryId}
                onCategoryAdd={this.props.onNewCategoryChange}
                onCategoryChange={this.props.onRegItemChange}
              />
              <FavoriteToggle
                className={css.flexGrow}
                defaultOn={this.props.regItem.isFavorite}
                onClick={this.onFavoriteChange}
              />
            </div>
          </div>
        </div>

        <Input
          error={regItemFields.url ? null : 'Url cannot be blank'}
          groupClassName="mbl"
          label="Url"
          name="url"
          value={regItemFields.url}
          onChange={this.handleChange}
        />

        <Input
          className={css.notes}
          label="Notes for friends and family"
          name="description"
          placeholder="Let your Gift Givers know how they can help!"
          type="textarea"
          value={regItemFields.description || ''}
          onChange={this.handleChange}
        />
      </>
    )
  }
}
