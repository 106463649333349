/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types'
import { numberToCurrency } from 'lib/money'
import Offer, { offerProps } from './offer'

const PriceOffer = ({
  discountedPrice,
  discountedPriceFirst,
  price,
  ...rest
}) => {
  let priceComps = []
  if (discountedPrice) {
    priceComps.push(
      <span className="text-muted" key="base-price">
        <s>{numberToCurrency(price)}</s>
      </span>,
      <span className="mrs" key="spacer" />
    )
  }
  priceComps.push(
    <span key="advertised-price" className="advertised-price">
      {numberToCurrency(discountedPrice || price)}
    </span>
  )

  if (discountedPriceFirst) {
    priceComps = priceComps.reverse()
  }

  return <Offer {...rest}>{priceComps}</Offer>
}

PriceOffer.propTypes = {
  ...offerProps,
  discountedPrice: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
    .isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  decorator: PropTypes.func.isRequired,
}

export default PriceOffer
