const Close = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2785 19.7048C18.6719 20.0982 19.3097 20.0982 19.703 19.7048C20.0964 19.3115 20.0964 18.6737 19.703 18.2804L13.4236 12.0009L19.705 5.71949C20.0983 5.32613 20.0983 4.68837 19.705 4.29502C19.3116 3.90166 18.6739 3.90166 18.2805 4.29502L11.9991 10.5764L5.71949 4.29682C5.32613 3.90347 4.68837 3.90347 4.29502 4.29682C3.90166 4.69018 3.90166 5.32794 4.29502 5.72129L10.5746 12.0009L4.29718 18.2784C3.90382 18.6717 3.90382 19.3095 4.29718 19.7028C4.69054 20.0962 5.32829 20.0962 5.72165 19.7028L11.9991 13.4254L18.2785 19.7048Z"
      fill="white"
      fillOpacity="0.5"
    />
  </svg>
)

export default Close
