/* eslint-disable react/static-property-placement */
import { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Select } from './index'

export default class StateSelector extends Component {
  static propTypes = {
    country: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    states: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    value: PropTypes.string,
    isDisabled: PropTypes.bool,
  }

  render() {
    let countryStates = this.props.states[this.props.country] || []
    const { error, country, value, onChange, isDisabled } = this.props
    if (countryStates.length) {
      return (
        <Select
          ref={(component) => (this._input = component)}
          error={error}
          label={country === 'United States' ? 'State' : 'Province'}
          value={value}
          disabled={isDisabled}
          onChange={(event) => onChange(event.target.value)}
        >
          <option key={0} value="" />
          {countryStates.map((state, idx) => (
            <option key={idx + 1} value={state}>
              {state}
            </option>
          ))}
        </Select>
      )
    } else {
      return (
        <Input
          error={error}
          label="Region"
          onChange={(event) => onChange(event.target.value)}
          type="text"
          value={value}
        />
      )
    }
  }
}
