import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { browserName, bowserFlags } from 'lib/bowser'
import Image from 'components/image'
import Link from 'components/link'
import classNames from 'classnames'
import { instructionsForBrowser } from './browser-specific-instructions'
import AlternateInstructions from './alternate-instructions'
import css from './instructions-by-browser.scss'

export const InstallInstructionsByBrowser = ({ hideTryItOut }) => {
  const { windows } = bowserFlags
  const [browser, setBrowser] = useState()
  const browserSpecific = instructionsForBrowser({
    browser,
    isWindows: windows,
  })
  useEffect(() => {
    setBrowser(browserName)
  }, [])

  return (
    <div className="ms-full-width">
      <div className="mhl">
        <div className="mbm h5 text-muted text-bold">
          <span className="secondary-badge mrs">1</span> Make sure your{' '}
          {browserSpecific.bookmarksBarTitle} bar is visible
        </div>
        <p className="mbl">{browserSpecific.instructions}</p>

        <div className="mbm h5 text-muted text-bold">
          <span className="secondary-badge mrs">2</span> Click and drag the Add
          to Babylist button to your {browserSpecific.bookmarksBarTitle} bar
        </div>

        <div className="phl">
          <Image
            alt="Instructions for browser"
            className={classNames(css.instructionsGif, 'img-responsive mbxl')}
            src={browserSpecific.installationGif}
          />
        </div>

        {!hideTryItOut && (
          <>
            <div className="mtxl mbm h5 text-muted bold">
              <span className="secondary-badge mrs">3</span> Now try it out!
            </div>
            <p className="mbxl">
              Visit Amazon to add the
              <Link
                newWindow
                className="phs bold"
                url="https://www.amazon.com/Vulli-616324-Sophie-la-Girafe/dp/B000IDSLOG?tag=babyli-20"
              >
                Sophie the Giraffe Teether
              </Link>
              to your registry using your new Babylist button. You can easily
              remove this later if you choose.
            </p>
          </>
        )}

        <AlternateInstructions browser={browser} onClick={setBrowser} />
      </div>
    </div>
  )
}

InstallInstructionsByBrowser.propTypes = {
  hideTryItOut: PropTypes.bool,
}

InstallInstructionsByBrowser.defaultProps = {
  hideTryItOut: false,
}

export default InstallInstructionsByBrowser
