import classNames from 'classnames'
import css from './GhostLine.styles.scss'

interface GhostLineProps {
  className?: string
}

const GhostLine = (props: GhostLineProps) => {
  const { className } = props

  return <div className={classNames(css.GhostLine, className)} />
}

export default GhostLine
