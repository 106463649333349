import { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import RegItemNote from 'shared/reg-item-note'
import RegItemImage from 'registry/components/reg-item-image'
import css from './reg-item.scss'

export default class RegItem extends Component {
  static propTypes = {
    className: PropTypes.string,
    registry: PropTypes.object,
    regItem: PropTypes.object.isRequired,
  }

  render() {
    let { registry, regItem } = this.props

    return (
      <div className={classNames(this.props.className, css.regItem)}>
        <RegItemImage
          className="center-block img-responsive media-left"
          isFavorite={regItem.isFavorite}
          isGroupGift={regItem.isGroupGift}
          src={regItem.imgUrl}
        />
        <div className={css.meta}>
          <div
            className={classNames(
              css.title,
              { mbn: !regItem.description },
              'h6'
            )}
          >
            {regItem.title}
          </div>
          {regItem.description && !regItem.isCrowdfund && (
            <RegItemNote
              className="h6 mvn"
              description={regItem.description}
              firstNames={registry && registry.firstNames}
            />
          )}
        </div>
      </div>
    )
  }
}
