import { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ImageUploadPreviewer from 'shared/image-upload-previewer/image-upload-previewer'
import { CurrencyInput, Input } from 'components/forms'
import FavoriteToggle from 'shared/reg-item-form/components/favorite-toggle'
import Checkbox from 'components/forms/checkbox'
import css from './reg-item-form.scss'
import CategorySelector from './CategorySelector'

export default class CashFundFields extends Component {
  static propTypes = {
    categories: PropTypes.array,
    regItem: PropTypes.object,
    onRegItemChange: PropTypes.func.isRequired,
    onNewCategoryChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      originalGoalAmount: props.regItem.goalAmount || 100,
    }
  }

  clearGoalAmount = (e) => {
    if (e.target.checked) {
      this.props.onRegItemChange({ goalAmount: null })
    } else {
      this.props.onRegItemChange({ goalAmount: this.state.originalGoalAmount })
    }
  }

  onFavoriteChange = (isChecked) => {
    this.props.onRegItemChange({ isFavorite: isChecked })
  }

  onImageChange = ({ imgUrl }) => {
    this.props.onRegItemChange({ imgUrl })
  }

  handleChange = (e) => {
    this.props.onRegItemChange({ [e.target.name]: e.target.value })
  }

  render() {
    const regItemFields = this.props.regItem

    return (
      <>
        <div className={css.item}>
          <div className={css.imageContainer}>
            <ImageUploadPreviewer
              imgUrl={regItemFields.imgUrl}
              onChange={this.onImageChange}
            />
          </div>

          <div className={css.itemFields}>
            <Input
              error={regItemFields.title ? null : 'Title cannot be blank'}
              label="Title"
              name="title"
              value={regItemFields.title}
              onChange={this.handleChange}
            />

            <div className="inline-form">
              <CurrencyInput
                groupClassName={css.flexGrow}
                label="Goal"
                name="goalAmount"
                value={
                  regItemFields.goalAmount != null
                    ? regItemFields.goalAmount
                    : 0
                }
                onChange={this.handleChange}
              />
              <Checkbox
                checked={!regItemFields.goalAmount}
                groupClassName={classNames(css.flexGrow, css.noGoal, 'mll')}
                label="No Goal"
                name="noGoal"
                style={{ whiteSpace: 'nowrap' }}
                onChange={this.clearGoalAmount}
              />
            </div>

            <div className="inline-form">
              <CategorySelector
                categories={this.props.categories}
                groupClassName={css.flexGrow}
                name="categoryId"
                value={regItemFields.categoryId}
                onCategoryAdd={this.props.onNewCategoryChange}
                onCategoryChange={this.props.onRegItemChange}
              />
              <FavoriteToggle
                className={css.flexGrow}
                defaultOn={this.props.regItem.isFavorite}
                onClick={this.onFavoriteChange}
              />
            </div>
          </div>
        </div>

        <Input
          className={classNames(css.notes)}
          label="Notes for friends and family"
          name="description"
          placeholder="Let everyone know why this is important."
          type="textarea"
          value={regItemFields.description || ''}
          onChange={this.handleChange}
        />
      </>
    )
  }
}
