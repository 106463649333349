/**
 * This is code sepcifically for an experimenet and shouldn't
 * be considered blessed/structural until over. Will probably want to
 * refactor AppModalWave in to something more intentional if we keep
 * the experience/UI over the control.
 *
 * https://github.com/babylist/web/pull/8496
 */
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Modal, ModalBody } from 'components/modal'

import {
  AppPromoWave,
  AppPromoStartBuilding,
  AppPromoStartBuildingActEx2Variant,
  AppPromoNextStep,
  AppPromoCollaborator,
} from '../app-promo/app-promo-wave'
import css from '../app-promo/app-promo.scss'

export const AppModalWave = ({
  isVisible,
  hideModal,
  children,
  customClassNames,
}) => {
  return (
    <Modal
      className={classNames(css.promoWaveModal, customClassNames)}
      size="fullscreen"
      show={isVisible}
      onHide={hideModal}
    >
      <ModalBody>{children}</ModalBody>
    </Modal>
  )
}

AppModalWave.propTypes = {
  hideModal: PropTypes.func,
  isVisible: PropTypes.bool,
  children: PropTypes.node,
  customClassNames: PropTypes.string,
}

AppModalWave.defaultProps = {
  isVisible: false,
  customClassNames: '',
}

export const AppPromoAddItemModal = ({
  hideModal,
  trackAttributes,
  ...props
}) => (
  <AppModalWave {...props}>
    <AppPromoWave trackAttributes={trackAttributes} onClose={hideModal} />
  </AppModalWave>
)

export const AppPromoStartBuildingModal = ({
  showMobileInterstitialExperiment,
  trackAttributes,
  ...props
}) => {
  let className = css.AppPromoStartBuildingModal
  let component = <AppPromoStartBuilding trackAttributes={trackAttributes} />

  if (showMobileInterstitialExperiment) {
    className = css.promoWaveModalVariant
    component = (
      <AppPromoStartBuildingActEx2Variant trackAttributes={trackAttributes} />
    )
  }

  return (
    <AppModalWave customClassNames={className} {...props}>
      {component}
    </AppModalWave>
  )
}

export const AppPromoNextStepModal = ({ hideModal, ...props }) => (
  <AppModalWave {...props}>
    <AppPromoNextStep onClose={hideModal} />
  </AppModalWave>
)

export const AppPromoCollaboratorModal = ({ registrantNames, ...props }) => (
  <AppModalWave customClassNames={css.AppPromoStartBuildingModal} {...props}>
    <AppPromoCollaborator registrantNames={registrantNames} />
  </AppModalWave>
)
