import PropTypes from 'prop-types'
import { RegItemShape } from 'registry/lib/prop-types'
import AddOrBuy from '../add-or-buy/add-or-buy'
import EditRegItemLink from './edit-reg-item-link'
import RegItemCardLayout from './reg-item-card-layout'
import OwnerActions from './owner-actions/owner-actions'
import { CrowdfundContributionModalLink } from './crowdfund-contribution-modal-link'

export const CrowdfundRegItemCard = (
  { regItem, isContributorView },
  { isMobile, registry }
) => {
  const actions = () => {
    if (isContributorView) {
      return <OwnerActions showEditAction regItemId={regItem.id} />
    }
    return <AddOrBuy regItem={regItem} registry={registry} />
  }

  const regItemLink = (child) => {
    if (!isContributorView) {
      return (
        <CrowdfundContributionModalLink regItem={regItem}>
          {child}
        </CrowdfundContributionModalLink>
      )
    }
    if (isMobile) {
      return <EditRegItemLink regItemId={regItem.id}>{child}</EditRegItemLink>
    }
    return child
  }

  return (
    <RegItemCardLayout
      isContributorView={isContributorView}
      regItem={regItem}
      regItemLinkDecorator={regItemLink}
      actions={actions()}
    />
  )
}

CrowdfundRegItemCard.propTypes = {
  regItem: PropTypes.shape(RegItemShape).isRequired,
  isContributorView: PropTypes.bool.isRequired,
}

CrowdfundRegItemCard.contextTypes = {
  isMobile: PropTypes.bool,
  registry: PropTypes.object,
}
