import React from 'react'

interface GarbageBinProps {
  className?: string
  filled?: boolean
}

const GarbageBin: React.FC<GarbageBinProps> = ({
  className,
  filled,
}: GarbageBinProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {filled ? (
      <path
        clipRule="evenodd"
        d="M7.83284 3.13037C8.20716 2.00743 9.25804 1.25 10.4417 1.25H13.5587C14.7424 1.25 15.7933 2.00743 16.1676 3.13037L16.5408 4.25L22 4.25C22.4142 4.25 22.75 4.58578 22.75 5C22.75 5.41421 22.4142 5.75 22 5.75H20.667L19.1605 18.555C18.8791 20.9472 16.8517 22.75 14.4431 22.75H9.55707C7.14841 22.75 5.12104 20.9472 4.8396 18.555L3.33313 5.75H2C1.58579 5.75 1.25 5.41421 1.25 5C1.25 4.58579 1.58579 4.25 2 4.25L7.45963 4.25L7.83284 3.13037ZM14.7445 3.60472L14.9596 4.25L9.04077 4.25L9.25587 3.60472C9.42601 3.09429 9.90368 2.75 10.4417 2.75H13.5587C14.0967 2.75 14.5744 3.09429 14.7445 3.60472ZM10 9.25C10.4142 9.25 10.75 9.58579 10.75 10V16C10.75 16.4142 10.4142 16.75 10 16.75C9.58579 16.75 9.25 16.4142 9.25 16V10C9.25 9.58579 9.58579 9.25 10 9.25ZM14 9.25C14.4142 9.25 14.75 9.58579 14.75 10V16C14.75 16.4142 14.4142 16.75 14 16.75C13.5858 16.75 13.25 16.4142 13.25 16V10C13.25 9.58579 13.5858 9.25 14 9.25Z"
        fill="#252324"
        fillRule="evenodd"
      />
    ) : (
      <path
        clipRule="evenodd"
        d="M7.83264 3.13037C8.20695 2.00743 9.25784 1.25 10.4415 1.25H13.5585C14.7422 1.25 15.793 2.00743 16.1674 3.13037L16.5406 4.25L19.9886 4.25C19.9956 4.2499 20.0025 4.2499 20.0095 4.25L22 4.25C22.4142 4.25 22.75 4.58579 22.75 5C22.75 5.41421 22.4142 5.75 22 5.75H20.6669L19.1605 18.555C18.879 20.9472 16.8517 22.75 14.443 22.75H9.557C7.14834 22.75 5.12096 20.9472 4.83953 18.555L3.33306 5.75H2C1.58579 5.75 1.25 5.41422 1.25 5C1.25 4.58579 1.58579 4.25 2 4.25L3.99039 4.25C3.99744 4.2499 4.00446 4.2499 4.01147 4.25L7.45943 4.25L7.83264 3.13037ZM7.98276 5.75C7.99435 5.75027 8.00592 5.75027 8.01745 5.75L15.9825 5.75C15.9941 5.75027 16.0057 5.75027 16.0173 5.75L19.1566 5.75L17.6707 18.3797C17.4782 20.0165 16.091 21.25 14.443 21.25H9.557C7.90897 21.25 6.52182 20.0165 6.32926 18.3797L4.84341 5.75L7.98276 5.75ZM14.9594 4.25L9.04057 4.25L9.25566 3.60472C9.42581 3.09429 9.90348 2.75 10.4415 2.75H13.5585C14.0965 2.75 14.5742 3.09429 14.7443 3.60472L14.9594 4.25ZM10 9.25C10.4142 9.25 10.75 9.58579 10.75 10V16C10.75 16.4142 10.4142 16.75 10 16.75C9.58579 16.75 9.25 16.4142 9.25 16V10C9.25 9.58579 9.58579 9.25 10 9.25ZM14 9.25C14.4142 9.25 14.75 9.58579 14.75 10V16C14.75 16.4142 14.4142 16.75 14 16.75C13.5858 16.75 13.25 16.4142 13.25 16V10C13.25 9.58579 13.5858 9.25 14 9.25Z"
        fill="#252324"
        fillRule="evenodd"
      />
    )}
  </svg>
)

export default GarbageBin
