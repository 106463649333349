import { Checkbox } from 'components/forms'

export interface PurchasedActionProps {
  openReservePurchasedGiftModal(): void
}
export const PurchasedAction = ({
  openReservePurchasedGiftModal,
}: PurchasedActionProps) => {
  return (
    <div
      className="mtn mhl bts ptl flex col align-center"
      onClick={openReservePurchasedGiftModal}
      onKeyDown={openReservePurchasedGiftModal}
    >
      <Checkbox
        label="I've Purchased This"
        className="text-center text-bold"
        checked={false}
        readOnly
      />
    </div>
  )
}
