import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FavoriteBadge, GroupGiftBadge } from 'components/badges'
import Image from 'components/image'
import css from './reg-item-image.scss'

const RegItemImage = ({
  className,
  isContributorView,
  isGroupGift,
  isFavorite,
  bannerContent,
  linkDecorator,
  src,
  title,
  width,
  height,
}) => {
  const favoriteBadgeProps = {
    isOn: isFavorite,
    isContributorView,
    overlay: true,
    position: 'tl',
    shadow: true,
  }

  const groupGiftBadgeProps = {
    isOn: isGroupGift,
    isContributorView,
    overlay: true,
    position: 'tr',
    shadow: true,
  }

  return (
    <div className={classNames(className, css.wrapper)}>
      {isFavorite ? <FavoriteBadge {...favoriteBadgeProps} /> : null}
      {isGroupGift ? <GroupGiftBadge {...groupGiftBadgeProps} /> : null}

      {linkDecorator(
        <Image
          alt={title}
          role="presentation"
          className={classNames('img-responsive')}
          src={src}
          width={width}
          height={height}
        />
      )}

      {bannerContent && (
        <div className={classNames('text-inverse xsmall', css.bannerWrapper)}>
          <div>{bannerContent}</div>
        </div>
      )}
    </div>
  )
}

RegItemImage.propTypes = {
  bannerContent: PropTypes.node,
  className: PropTypes.string,
  isContributorView: PropTypes.bool,
  isGroupGift: PropTypes.bool,
  isFavorite: PropTypes.bool,
  linkDecorator: PropTypes.func,
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

RegItemImage.defaultProps = {
  bannerContent: null,
  className: null,
  isContributorView: false,
  isGroupGift: false,
  isFavorite: false,
  linkDecorator: (component) => component,
  title: '',
  width: undefined,
  height: undefined,
}

export default RegItemImage
