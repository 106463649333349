import PropTypes from 'prop-types'
import classNames from 'classnames'
import Image from 'components/image'
import RegItemNote from 'shared/reg-item-note'
import { regItemProps } from './reg-item-props'
import css from './reserved-reg-item-card.scss'
import ReservedAction from './guest-actions/reserved-action'
import { ReservationStatusAction } from './guest-actions/reservation-status-action'
import FundingProgress from './group-gift-reg-item-card/funding-progress'

// ReservedRegItemCard is displayed only when a reg item is completely reserved, meaning that there quantity >= quantityNeeded
export const ReservedRegItemCard = (
  { reservationByCurrentVisitor, regItem, reservation },
  { registry }
) => {
  const {
    description,
    title,
    imgUrl,
    id,
    goalAmount,
    quantityPurchased,
    offers,
  } = regItem
  const isPurchased = quantityPurchased > 0
  const RESERVED_TEXT = isPurchased ? 'Purchased' : 'On Hold'

  const renderCard = () => {
    if (reservationByCurrentVisitor) {
      const { displayTitle, isPurchased, orderNumber, quantity, storeName } =
        reservationByCurrentVisitor
      return (
        <div className={css.reservedRegItem}>
          <div className={css.regItemImage}>
            <Image role="presentation" src={imgUrl} />
          </div>
          <div className={css.regItem}>
            <div>
              <div className={css.title}>{displayTitle || title}</div>
              {isPurchased && (
                <div className="h6">
                  <div className="text-bold">Purchase Details</div>
                  <div>Qty: {quantity}</div>
                  <div>Store: {storeName}</div>
                  <div>Order #: {orderNumber}</div>
                </div>
              )}
              {description ? (
                <RegItemNote
                  description={description}
                  className="hidden-xs h6 mtl"
                />
              ) : null}
            </div>

            <div className={css.actions}>
              <div className="visible-xs-block pts" />
              <ReservationStatusAction
                reservation={reservationByCurrentVisitor}
                regItem={regItem}
                offers={offers}
                registry={registry}
                showAddOrBuy={false}
              />
            </div>
          </div>
        </div>
      )
    }

    if (reservation?.isGroupGiftContribution) {
      return (
        <ReservedAction
          className={classNames(css.reservedRegItem)}
          regItemId={id}
        >
          <div className={css.regItemImage}>
            <Image role="presentation" src={imgUrl} />
          </div>
          <div className={css.regItem}>
            <div>
              <div className={css.title}>{title}</div>
              <FundingProgress
                className={css.fundingProgress}
                fundedAmount={reservation.giftAmount}
                goalAmount={goalAmount}
              />
            </div>
          </div>
        </ReservedAction>
      )
    }

    return (
      <ReservedAction
        className={classNames(css.reservedRegItem)}
        regItemId={id}
      >
        <div className={css.regItemImage}>
          <Image role="presentation" src={imgUrl} />
        </div>
        <div className={css.regItem}>
          <div>
            <div className={css.title}>{title}</div>
            {description ? (
              <RegItemNote description={description} className="h6 mtl" />
            ) : null}
          </div>

          <div className={css.actions}>
            <div className="visible-xs-block pts" />
            <div className="guest-action mvn h6 text-bold text-brand-info">
              {RESERVED_TEXT}
            </div>
          </div>
        </div>
      </ReservedAction>
    )
  }

  return renderCard()
}

ReservedRegItemCard.propTypes = {
  regItem: PropTypes.shape(regItemProps).isRequired,
  reservation: PropTypes.object,
  reservationByCurrentVisitor: PropTypes.object,
}

ReservedRegItemCard.contextTypes = {
  registry: PropTypes.object,
}

ReservedRegItemCard.defaultProps = {
  reservation: null,
  reservationByCurrentVisitor: null,
}
