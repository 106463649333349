import React from 'react'
import Toast from 'baby-design/components/Toast/Toast'
import Trash from 'baby-design/icons/Trash.svg'
import { ItemDeletedToastProps } from './ItemDeletedToast.types'
import css from './ItemDeletedToast.styles.scss'

const ItemDeletedToast: React.FC<ItemDeletedToastProps> = ({ isOpen }) => (
  <Toast
    imageProps={{
      image: <Trash filled className={css.ItemDeletedToast__Image} />,
    }}
    isOpen={isOpen}
    text="Item removed."
    variant="basic"
  />
)

export default ItemDeletedToast
