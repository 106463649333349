import classnames from 'classnames'
// eslint-disable-next-line
import { Discount } from 'baby-design/icons'
import css from './PromoMessage.styles.scss'

export interface PromoMessageProps {
  className?: string
  size?: 'xs' | 'sm' | 'md' | 'lg'
  text: string
  align?: 'left' | 'right'
  variant?: 'default' | 'secondary'
}

const PromoMessage = ({
  className,
  size = 'sm',
  text,
  align = 'left',
  variant = 'default',
}: PromoMessageProps) => (
  <div
    className={classnames(
      className,
      css.PromoMessage,
      css[`PromoMessage--size-${size}`],
      css[`PromoMessage--align-${align}`],
      css[`PromoMessage--variant-${variant}`]
    )}
    data-testid="component"
  >
    <Discount className={css.PromoMessage__icon} />
    <span className={css.PromoMessage__text}>{text}</span>
  </div>
)

export default PromoMessage
