import RadioGroup from 'components/forms/radio-group'
import Radio from 'components/forms/radio'
import {
  REG_ITEM_RESERVATION_STATUS_AVAILABLE,
  REG_ITEM_RESERVATION_STATUS_RESERVED,
} from '../../actions/reg-items-actions'

const ReservationFilter = ({
  addFilter,
  className,
  decorator,
  label,
  selected,
}) => (
  <>
    {label && (
      <>
        <hr className="mbl" />
        <div className="h6 text-uppercase text-bold text-light tiny mtn mbs">
          {label}
        </div>
      </>
    )}

    {decorator(
      <RadioGroup className={className}>
        <Radio
          tappable
          defaultChecked={!selected}
          label="All Gifts"
          name="gift-view"
          onChange={() => addFilter({ giftView: null })}
        />

        <Radio
          tappable
          defaultChecked={selected === REG_ITEM_RESERVATION_STATUS_AVAILABLE}
          label="Available Gifts"
          name="gift-view"
          onChange={() =>
            addFilter({ giftView: REG_ITEM_RESERVATION_STATUS_AVAILABLE })
          }
        />

        <Radio
          tappable
          defaultChecked={selected === REG_ITEM_RESERVATION_STATUS_RESERVED}
          label="Reserved &amp; Purchased Gifts"
          name="gift-view"
          onChange={() =>
            addFilter({ giftView: REG_ITEM_RESERVATION_STATUS_RESERVED })
          }
        />
      </RadioGroup>
    )}
  </>
)

ReservationFilter.defaultProps = {
  decorator: (component) => component,
}

export default ReservationFilter
