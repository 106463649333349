/* eslint-disable */
import { connect } from 'react-redux'
import { getActiveModal, getActiveModalProps } from '../reducers'
import { setActiveModal } from '../actions'

export const openModal = (modalId, data = {}) => setActiveModal(modalId, data)

const renderIfShown =
  (ModalComponent) =>
  ({ show, ...props }) =>
    show ? <ModalComponent show {...props} /> : null

export const modalConnect = (ModalComponent, modalId) => {
  const mapStateToProps = (state) => ({
    show: getActiveModal(state) === modalId,
    ...getActiveModalProps(state),
  })

  return connect(mapStateToProps, { onHide: () => setActiveModal(null) })(
    renderIfShown(ModalComponent),
    modalId
  )
}
