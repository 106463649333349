import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap'
import classNames from 'classnames'
import { Checkbox } from 'components/forms'

import { InfoButton, SaveButton } from 'components/buttons'
// @ts-ignore
import { getFirstNames, getRegistryId } from 'registry/reducers'
// @ts-ignore
import { Modal } from 'components/modal'
// @ts-ignore
import ShippingAddressForm from 'registry/forms/shipping-address-form'
import {
  saveGiftGiverAddress,
  saveIsThankYouNoteAllowed,
  // @ts-ignore
} from 'registry/actions'
// @ts-ignore
import { MultiStepWizard } from 'components/presentation'
// @ts-ignore
import { ConfirmAddressForm } from 'shared/address-forms/confirm-address-form'
import css from './ThankYouAddressModal.scss'
import { GiftGiverInfoValues } from '../../../../cart/components/reserve-purchased-gift-modal/reserve-purchased-gift-modal'
import {
  AddressSuggestion,
  FieldErrors,
  getHandleSubmit,
  getHandleSubmitFail,
  getWrappedSuggestionForm,
} from './ThankYouAddress.utils'
import { getIsGiftGiverThankYouAddressProvided } from '../../../../cart/reducers/reservations-reducer'
import { getReservationsByCurrentVisitor } from '../../../reducers'

interface ThankYouAddressModalProps {
  registryFirstNames: string
  afterSubmit: () => void
  onHide: () => void
  giftGiverInfo: GiftGiverInfoValues
  dispatch: (action: () => any) => Promise<any>
  registryId: number
  isGiftGiverThankYouAddressProvided: boolean
}

const EXCLUDED_FIELDS = ['phone', 'privateAddress']

export const ThankYouAddressModal = (props: ThankYouAddressModalProps) => {
  const {
    registryFirstNames,
    onHide,
    afterSubmit = onHide,
    giftGiverInfo,
    dispatch,
    registryId,
    isGiftGiverThankYouAddressProvided,
  } = props

  // Immediately fire afterSubmit if thank you address is already provided. This handles the
  // business logic of only displaying this modal if the user hasn't provided an address
  useEffect(() => {
    if (isGiftGiverThankYouAddressProvided) {
      afterSubmit()
    }
  }, [])

  const [fieldErrors, setFieldErrors] = useState<FieldErrors | undefined>()
  const [activeStep, setActiveStep] = useState<number>(1)
  const [isThankYouNoteAllowed, setIsThankYouNoteAllowed] =
    useState<boolean>(true)
  const [recommendedAddress, setRecommendedAddress] = useState<
    AddressSuggestion | undefined
  >()
  const [enteredAddress, setEnteredAddress] = useState<AddressSuggestion>({
    name: giftGiverInfo.name || '',
  })
  const handleSubmit = getHandleSubmit(
    dispatch,
    saveGiftGiverAddress,
    giftGiverInfo
  )
  const handleSubmitFail = getHandleSubmitFail(
    setRecommendedAddress,
    setEnteredAddress,
    setFieldErrors,
    setActiveStep
  )
  const handleAfterSubmit = () => {
    saveIsThankYouNoteAllowed({
      is_thank_you_note_allowed: isThankYouNoteAllowed,
      registry_id: registryId,
      email: giftGiverInfo.email,
    })
    afterSubmit()
  }
  const WrappedSuggestionForm = getWrappedSuggestionForm(
    setActiveStep,
    handleAfterSubmit,
    handleSubmit,
    fieldErrors,
    enteredAddress,
    recommendedAddress
  )

  const renderCustomSubmit = () => () => (
    <SaveButton
      className={css.ctaSection}
      type={isThankYouNoteAllowed ? 'submit' : 'button'}
      onClick={isThankYouNoteAllowed ? undefined : handleAfterSubmit}
    >
      Save
    </SaveButton>
  )

  return (
    <Modal show size="large" onHide={onHide}>
      <ModalHeader closeButton className="with-title" onHide={onHide}>
        <ModalTitle className={classNames('h3', css.ModalTitle)}>
          Where can {registryFirstNames || 'the family'} send a thank you note?
          💌
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <MultiStepWizard activeStep={activeStep}>
          {/* step 1 */}
          <div>
            <ShippingAddressForm
              address={enteredAddress}
              ctaSectionClassName={css.ctaSection}
              excludedFields={EXCLUDED_FIELDS}
              isDisabled={!isThankYouNoteAllowed}
              renderCustomSubmit={renderCustomSubmit()}
              secondaryButtonComponent={InfoButton}
              onSave={handleSubmit}
              onSubmitFail={handleSubmitFail}
              onSubmitSuccess={handleAfterSubmit}
            />
            <Checkbox
              checked={!isThankYouNoteAllowed}
              label="Please do not send me a thank you note."
              name="thankyounotedeclined"
              onChange={() => setIsThankYouNoteAllowed(!isThankYouNoteAllowed)}
            />
          </div>
          {/* step 2 */}
          {recommendedAddress ? (
            <WrappedSuggestionForm />
          ) : (
            <ConfirmAddressForm
              enteredAddress={enteredAddress as any}
              fieldErrors={fieldErrors}
              onConfirm={() => {
                handleSubmit({
                  ...enteredAddress,
                  userConfirmed: true,
                }).then(() => handleAfterSubmit())
              }}
              onEdit={() => setActiveStep(1)}
            />
          )}
        </MultiStepWizard>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = (state: any) => {
  const reservations = Object.values(getReservationsByCurrentVisitor(state))
  return {
    registryFirstNames: getFirstNames(state),
    registryId: getRegistryId(state),
    isGiftGiverThankYouAddressProvided:
      getIsGiftGiverThankYouAddressProvided(reservations),
  }
}

export default connect(mapStateToProps, null)(ThankYouAddressModal)
