import Card from 'components/card'
import Link from 'components/link'
import css from './gift-message-card.scss'

const GiftMessageCard = ({ children, onEdit }) => {
  return (
    <Card className={css.message}>
      <label>Gift Message</label>
      <p>{children}</p>
      {onEdit ? (
        <Link onClick={onEdit} className="mtxl xsmall text-uppercase text-bold">
          Edit
        </Link>
      ) : null}
    </Card>
  )
}

export default GiftMessageCard
