import { Component } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
import { isEmpty, isUndefined } from 'lodash'
import { Col, Row } from 'react-bootstrap'
import { Input } from 'components/forms'
import { SHOW_SEARCH_BAR } from 'shared/constants'
import Link from 'components/link'
import Tappable from 'react-tappable'
import css from './mobile-search-bar.scss'
import {
  SEARCH_HISTORY_TYPE_GUIDES,
  addToSearchHistory,
} from 'shared/utils/searchHistory/searchHistory'

class MobileSearchBar extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    submitPath: PropTypes.func,
    onSubmit: PropTypes.func,
  }

  state = {
    searchText: '',
    show: false,
  }

  componentDidMount() {
    PubSub.subscribe(SHOW_SEARCH_BAR, () => {
      this.toggle()
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.input) {
      if (!prevState.show && this.state.show && this.input) {
        this.input.focus()
      } else if (prevState.show && !this.state.show) {
        this.input.blur()
      }
    }
  }

  render() {
    const inputAddon = this.state.searchText.length ? (
      <Link onClick={this.clear.bind(this)}>
        <i className={classNames('fa text-muted', css.clearButtonIcon)} />
      </Link>
    ) : (
      <i className="fa fa-search" />
    )
    return (
      <CSSTransition
        classNames={{
          appear: css.appear,
          appearActive: css.appearActive,
          enter: css.enter,
          enterActive: css.enterActive,
          exit: css.leave,
          exitActive: css.leaveActive,
        }}
        appear
        timeout={400}
      >
        {this.state.show ? (
          <div className={css.searchBar}>
            <div className="container">
              <Row>
                <Col sm={8} smOffset={2}>
                  <form
                    className={classNames(
                      'input-search input-search-sm',
                      css.form
                    )}
                    onSubmit={this.handleSearch}
                  >
                    <Input
                      ref={(input) => {
                        this.input = input && input._input
                      }}
                      groupClassName="mvm"
                      addonAfter={inputAddon}
                      onChange={this.handleUpdateSearchText}
                      placeholder={this.props.placeholder}
                      name="search"
                      aria-label="Search"
                      value={this.state.searchText}
                    />
                  </form>
                  <CancelButton
                    className="visible-xs"
                    onClick={this.handleClickCancel}
                  />
                </Col>
                <Col sm={2} className="hidden-xs">
                  <CancelButton onClick={this.handleClickCancel} />
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <span />
        )}
      </CSSTransition>
    )
  }

  clear = () => {
    this.setState({ searchText: '' })
  }

  toggle = () => {
    this.setState({ show: !this.state.show })
  }

  hide = () => {
    this.setState({ show: false })
  }

  handleClickCancel = () => this.hide()

  show = () => {
    this.setState({ show: true })
  }

  handleUpdateSearchText = (e) => {
    this.setState({ searchText: e.target.value })
  }

  handleSearch = (e) => {
    e.preventDefault()
    const cleanSearchTerm = this.state.searchText?.trim()

    if (isUndefined(cleanSearchTerm) || isEmpty(cleanSearchTerm)) {
      return
    }

    addToSearchHistory(cleanSearchTerm, SEARCH_HISTORY_TYPE_GUIDES)

    if (this.props.submitPath) {
      window.location.href = this.props.submitPath(cleanSearchTerm)
    } else if (this.props.onSubmit) {
      this.props.onSubmit(cleanSearchTerm)
    }

    this.hide()
  }
}

const CancelButton = ({ className, onClick }) => (
  <Tappable
    component={Link}
    className={classNames(css.cancelButton, className)}
    onClick={onClick}
  >
    Cancel
  </Tappable>
)

export default MobileSearchBar
