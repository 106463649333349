import React, { useState } from 'react'
import { CollapsiblePaymentInfoProps } from 'registry/components/CrowdfundReserveFormFlow/components/RecordContribution/components/CollapsiblePaymentInfo/CollapsiblePaymentInfo.types'
import AccountAttributeField from 'registry/components/CrowdfundReserveFormFlow/components/ContributionDetails/components/AccountAttributeField/AccountAttributeField'
import classNames from 'classnames'
import css from './CollapsiblePaymentInfo.styles.scss'
import { Chevron } from '../../../../../../../baby-design/icons'

const CollapsiblePaymentInfo: React.FC<CollapsiblePaymentInfoProps> = ({
  preference,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const chevronClasses = classNames(
    css.CollapsiblePaymentInfo__Toggle__Chevron,
    {
      [css.CollapsiblePaymentInfo__Toggle__Chevron__Expanded]: expanded,
    }
  )

  return (
    <div className={css.CollapsiblePaymentInfo}>
      <button
        className={css.CollapsiblePaymentInfo__Toggle}
        type="button"
        onClick={() => setExpanded(!expanded)}
      >
        <span className={css.CollapsiblePaymentInfo__Toggle__Text}>
          {expanded ? 'Hide' : 'View'} payment info
        </span>
        <Chevron className={chevronClasses} />
      </button>
      {expanded && (
        <div className={css.CollapsiblePaymentInfo__Fields}>
          {preference.fields
            ?.filter((field) => !!field.value)
            ?.map((field) => (
              <AccountAttributeField
                canCopy
                label={field.label}
                prefix={field.prefix}
                value={field.value}
              />
            ))}
        </div>
      )}
    </div>
  )
}

export default CollapsiblePaymentInfo
