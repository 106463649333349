import React from 'react'

interface LightBulbProps {
  className?: string
}

const LightBulb: React.FC<LightBulbProps> = ({ className }: LightBulbProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M12 2.75C7.99594 2.75 4.75 5.99594 4.75 10C4.75 12.5868 6.72957 14.7445 8.18904 15.9656C8.62892 16.3336 9.01101 16.7895 9.28707 17.3071C9.30146 17.3131 9.31575 17.3195 9.32993 17.3265C9.33112 17.327 9.33231 17.3276 9.33349 17.3282L9.32993 17.3265C9.33195 17.3274 9.33787 17.3302 9.34749 17.3344C9.36672 17.3428 9.40117 17.3573 9.45084 17.3759C9.55015 17.4131 9.71048 17.467 9.9319 17.5224C10.2464 17.601 10.6856 17.6829 11.25 17.7237V12C11.25 11.5858 11.5858 11.25 12 11.25C12.4142 11.25 12.75 11.5858 12.75 12V17.7237C13.3144 17.6829 13.7536 17.601 14.0681 17.5224C14.2895 17.467 14.4498 17.4131 14.5492 17.3759C14.5988 17.3573 14.6333 17.3428 14.6525 17.3344C14.6576 17.3321 14.6616 17.3303 14.6646 17.329C14.6672 17.3278 14.669 17.327 14.67 17.3265L14.6665 17.3282L14.6656 17.3287C14.6854 17.3188 14.705 17.3101 14.7253 17.3021C15.0055 16.785 15.3916 16.3307 15.8338 15.9648C17.2869 14.7625 19.25 12.6296 19.25 10C19.25 5.99594 16.0041 2.75 12 2.75ZM14.2516 19.0203C14.2505 19.0557 14.25 19.0912 14.25 19.1268C14.25 20.2994 13.2994 21.25 12.1268 21.25H11.8877C10.7071 21.25 9.75 20.2929 9.75 19.1123C9.75 19.0816 9.74961 19.051 9.74885 19.0204C10.2958 19.143 11.0465 19.25 12 19.25C12.9537 19.25 13.7045 19.143 14.2516 19.0203ZM3.25 10C3.25 5.16751 7.16751 1.25 12 1.25C16.8325 1.25 20.75 5.16751 20.75 10C20.75 13.3494 18.2996 15.8715 16.79 17.1206C16.1456 17.6537 15.75 18.3871 15.75 19.1268C15.75 21.1278 14.1278 22.75 12.1268 22.75H11.8877C9.87865 22.75 8.25 21.1214 8.25 19.1123C8.25 18.3764 7.86169 17.6475 7.22652 17.116C5.72304 15.8581 3.25 13.3097 3.25 10ZM9.3349 17.3289C9.33484 17.3289 9.33496 17.329 9.3349 17.3289V17.3289Z"
      fill="#252324"
      fillRule="evenodd"
    />
  </svg>
)

export default LightBulb
