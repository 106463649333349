import {
  SET_ORDERS,
  SET_IS_FETCHING_USER_ORDERS,
  SET_HAS_ERROR,
} from 'registry/actions/orders-actions'

export const initialState = {
  orders: [],
}

const userOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERS: {
      return { ...state, orders: action.orders }
    }
    case SET_HAS_ERROR: {
      return { ...state, hasError: action.hasError }
    }
    case SET_IS_FETCHING_USER_ORDERS:
      return { ...state, isFetchingUserOrders: action.isFetchingUserOrders }
    default:
      return state
  }
}

export const getOrders = (state) => state.orders
export const getHasError = (state) => state.hasError
export const getIsFetchingUserOrders = (state) => state.isFetchingUserOrders

export default userOrdersReducer
