// Ensure a number is within a given range
export const clampValue = (
  value: number,
  lowerLimit: number,
  upperLimit: number
) => Math.min(Math.max(value, lowerLimit), upperLimit)

// Rounds the value down to the nearest step
export const enforceStep = (value: number, step: number) =>
  Math.floor(value / step) * step

// Turn our input value into a string that can be displayed in the input field
// Rounds down to nearest step or integer
// Adds commas, and optional dollar sign, percentage sign, and plus sign
interface CompileInputValueArgs {
  value: number | null
  showDollarSign: boolean
  roundToStep: boolean
  step: number
  showPercentageSign: boolean
  showPlus: boolean
}
export const compileInputValue = ({
  value,
  showDollarSign,
  roundToStep,
  step,
  showPercentageSign,
  showPlus,
}: CompileInputValueArgs) => {
  let result = ''
  if (showDollarSign) result += '$'
  if (value !== null) {
    const roundedValue = roundToStep
      ? enforceStep(value, step)
      : Math.floor(value)
    // Adds commas to the number
    result += roundedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  if (showPercentageSign) result += '%'
  if (showPlus) result += '+'
  return result
}

// Remove commas and dollar signs from a string
export const cleanString = (value: string) => {
  let result = value.replace(/,/g, '')
  if (result[0] === '$') {
    result = result.slice(1)
  }
  return result
}
