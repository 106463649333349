import React from 'react'

interface PlusProps {
  className?: string
}

const Plus: React.FC<PlusProps> = ({ className }: PlusProps) => (
  <svg
    className={className}
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M7.99992 2.83333C8.27606 2.83333 8.49992 3.05719 8.49992 3.33333V7.5H12.6666C12.9427 7.5 13.1666 7.72385 13.1666 8C13.1666 8.27614 12.9427 8.5 12.6666 8.5H8.49992V12.6667C8.49992 12.9428 8.27606 13.1667 7.99992 13.1667C7.72378 13.1667 7.49992 12.9428 7.49992 12.6667V8.5H3.33325C3.05711 8.5 2.83325 8.27614 2.83325 8C2.83325 7.72385 3.05711 7.5 3.33325 7.5H7.49992V3.33333C7.49992 3.05719 7.72378 2.83333 7.99992 2.83333Z"
      fill="#494647"
      fillRule="evenodd"
    />
  </svg>
)

export default Plus
