import {
  CashFundPaymentPreference,
  CashFundPreferences,
  PaymentAccountField,
} from 'src/types/registry'
import { PaymentOption } from 'registry/components/CrowdfundReserveFormFlow/components/PaymentOptions/PaymentOptions.types'

export const selectedPaymentPreferences = (
  cashFundPreferences?: CashFundPreferences
): CashFundPaymentPreference[] =>
  cashFundPreferences?.paymentPreferences?.filter(
    (preference) => !!preference.selected
  ) || []

export const preferencesToPaymentOptions = (
  preferences: CashFundPaymentPreference[]
): PaymentOption[] =>
  preferences.map((preference) => ({
    name: preference.key,
    label: preference.name,
  }))

export const selectedPaymentOptions = (
  cashFundPreferences: CashFundPreferences
): PaymentOption[] =>
  preferencesToPaymentOptions(selectedPaymentPreferences(cashFundPreferences))

// payment URL building
const paymentAttributeValue = (
  preference: CashFundPaymentPreference,
  attributeName: string
): string | undefined =>
  preference.fields?.find(
    (field: PaymentAccountField) => field.name === attributeName
  )?.value

const amountKey = 'amount'
const mustacheParamRegex = /\{\{([^\}]+)\}\}/g
export const formattedUrl = (
  preference?: CashFundPaymentPreference,
  // @ts-ignore
  amount?: string | number
): string | undefined => {
  if (!preference || !preference.urlFormat) return undefined

  const matches = Array.from(preference.urlFormat.matchAll(mustacheParamRegex))
  if (matches.length === 0) return preference.urlFormat

  // @ts-ignore
  return matches.reduce((url, match) => {
    if (!url) return url
    const [mustacheParamStr, paramName] = match
    const value =
      paramName === amountKey
        ? amount
        : paymentAttributeValue(preference, paramName)
    if (value) {
      return url.replace(mustacheParamStr, encodeURIComponent(value))
    }
    return undefined
  }, preference.urlFormat)
}
