import { Block, Hyperlink, Text } from '@contentful/rich-text-types'
import { ReactNode } from 'react'
import { AnchoredH } from '../Embeds/Utilities'

export interface HeadingProps {
  block: Block
  children: ReactNode
  headingType: 'h2' | 'h3'
}

const Heading: React.FC<HeadingProps> = ({
  block,
  children,
  headingType,
}: HeadingProps) => {
  const { content } = block
  let anchorText = ''
  content.forEach((text) => {
    if (text.nodeType === 'text') {
      anchorText += (text as Text)?.value
    } else {
      anchorText += (text as Hyperlink)?.content[0]?.value
    }
  })

  return (
    <AnchoredH headingType={headingType} textForAnchor={anchorText}>
      {children}
    </AnchoredH>
  )
}

export default Heading
