import { showToast } from 'shared/toast/showToast'
import CartQuantityUpdateSuccessToast from './CartQuantityUpdateSuccessToast'

export const showCartQuantityUpdateSuccessToast = (newQuantity: number) =>
  showToast({
    render: (isOpen) => (
      <CartQuantityUpdateSuccessToast
        isOpen={isOpen}
        newQuantity={newQuantity}
      />
    ),
    timeout: 2000,
  })
