import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormButtonGroup } from 'components/forms'
import { updateCart } from 'cart/api'
import { setApplyRegistryDiscount } from 'cart/actions'
import { UPDATE_CART_SUMMARY } from 'shared/constants'
import { DiscountStatusIcon } from 'components/icons'
import { SHOULD_SAVE_REGISTRY_DISCOUNT_KEY } from 'cart/constants'

export const ConfirmUseRegistryDiscountForm = ({
  onHide,
  onConfirm,
  setApplyRegistryDiscount,
}) => {
  const [submitting, setSubmitting] = useState(false)
  const handleCancel = () => {
    localStorage.setItem(SHOULD_SAVE_REGISTRY_DISCOUNT_KEY, true)
    updateCart({ applyRegistryDiscount: false }).then(() => {
      setApplyRegistryDiscount(false)
      PubSub.publish(UPDATE_CART_SUMMARY)
    })
    onHide()
  }
  const handleConfirm = () => {
    setSubmitting(true)
    onConfirm()
  }
  return (
    <>
      <div className="text-center pal">
        <DiscountStatusIcon className="ptl" width={100} />
        <div className="h3">Registry Discount Applied</div>
        Are you sure you want to use your discount? (Stock up! You only get to
        use it once!)
      </div>
      <FormButtonGroup
        onClickSecondary={handleCancel}
        onClickPrimary={handleConfirm}
        submitting={submitting}
        textPrimary="Yep!"
        textSecondary="No, Save it for later."
      />
    </>
  )
}

ConfirmUseRegistryDiscountForm.propTypes = {
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  setApplyRegistryDiscount: PropTypes.func.isRequired,
}

export default connect(undefined, { setApplyRegistryDiscount })(
  ConfirmUseRegistryDiscountForm
)
