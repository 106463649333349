/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { LinkButton } from 'components/buttons'
import { Collapse } from 'react-bootstrap'
import PriceOffer from 'components/offer/price-offer'
import FreeShippingOnBabylistMessage from 'components/free-shipping-on-babylist-message'
import { offerProps } from 'components/offer/offer'
import { getRegistryDiscountPrice } from 'cart/lib'
import { RegItemShape } from 'registry/lib/prop-types'
import InStockAlertLink from '../../store/components/in-stock-alert-link'

export const OfferList = ({
  className,
  decorator,
  disableLink,
  isAvailableOnBabylist,
  isBabylistProductDiscontinued,
  isContributorView,
  isGroupGift,
  isRegistryDiscountView,
  limit,
  offers,
  openRedirectModal,
  productId,
  showFreeShippingMessage,
  responsive,
  regItem,
}) => {
  const [showAll, setShowAll] = useState(false)

  const renderOffer = (offer) => {
    const shouldShowFreeShippingMessage =
      showFreeShippingMessage && offer.price && offer.isBabylist
    const showOutOfStockMessage =
      offer.isBabylist &&
      isContributorView &&
      !isAvailableOnBabylist &&
      !isBabylistProductDiscontinued &&
      !isGroupGift &&
      offers?.length === 1

    return (
      <>
        <PriceOffer
          {...offer}
          key={offer.storeName}
          discountedPrice={
            // eslint-disable-next-line no-restricted-globals
            isRegistryDiscountView && offer.price && !isNaN(offer.price)
              ? getRegistryDiscountPrice(offer.price)
              : false
          }
          decorator={decorator}
          disableLink={disableLink}
          isContributorView={isContributorView}
          regItem={regItem}
          openRedirectModal={openRedirectModal}
          responsive={responsive}
        />
        {shouldShowFreeShippingMessage && (
          <FreeShippingOnBabylistMessage price={offer.price} />
        )}
        {showOutOfStockMessage && (
          <>
            <div className="small mtm">In Stock Soon</div>
            <InStockAlertLink productId={productId} />{' '}
          </>
        )}
      </>
    )
  }

  const renderOffersWithoutLimit = (
    <div className={className}>{offers.map(renderOffer)}</div>
  )

  const renderOffersWithLimit = () => {
    const firstStores = offers.slice(0, limit)
    const additionalStores = offers.slice(limit)

    const hiddenCount = additionalStores.length
    const showMoreLink = (
      <LinkButton
        onClick={() => {
          setShowAll(true)
        }}
        className="xsmall man pan"
      >
        + {hiddenCount} More Store{hiddenCount > 1 ? 's' : ''}
      </LinkButton>
    )

    return (
      <div className={className}>
        {firstStores.map(renderOffer)}

        <Collapse in={showAll}>
          <div>{additionalStores.map(renderOffer)}</div>
        </Collapse>

        {!showAll && hiddenCount > 0 ? showMoreLink : null}
      </div>
    )
  }

  return limit ? renderOffersWithLimit() : renderOffersWithoutLimit
}

OfferList.propTypes = {
  decorator: PropTypes.func,
  disableLink: PropTypes.bool,
  isAvailableOnBabylist: PropTypes.bool,
  isBabylistProductDiscontinued: PropTypes.bool,
  isContributorView: PropTypes.bool,
  isRegistryDiscountView: PropTypes.bool,
  offers: PropTypes.arrayOf(PropTypes.shape(offerProps)).isRequired,
  productId: PropTypes.number,
  limit: PropTypes.number,
  regItem: PropTypes.shape(RegItemShape),
  showFreeShippingMessage: PropTypes.bool,
  openRedirectModal: PropTypes.func,
}

OfferList.defaultProps = {
  decorator: (component) => component,
  disableLink: false,
  isAvailableOnBabylist: undefined,
  isContributorView: false,
  isGroupGift: undefined,
  isRegistryDiscountView: false,
  limit: undefined,
  regItem: null,
  responsive: undefined,
  showFreeShippingMessage: false,
  openRedirectModal: null,
}

export default OfferList
