import React from 'react'
import { ContributionDetailsProps } from 'registry/components/CrowdfundReserveFormFlow/components/ContributionDetails/ContributionDetails.types'
import AccountAttributes from 'registry/components/CrowdfundReserveFormFlow/components/ContributionDetails/components/AccountAttributes/AccountAttributes'
import { formattedUrl } from 'registry/components/CrowdfundReserveFormFlow/utils/helpers'
import ReturnToBabylistReminder from 'registry/components/CrowdfundReserveFormFlow/components/ContributionDetails/components/ReturnToBabylistReminder/ReturnToBabylistReminder'
import { Button } from '../../../../../baby-design'
import css from './ContributionDetails.styles.scss'
import LinkOut from '../../../../../baby-design/icons/LinkOut.svg'
import CrowdfundReserveModalTitle from 'registry/components/CrowdfundReserveFormFlow/components/CrowdfundReserveModalTitle/CrowdfundReserveModalTitle'

const ContributionDetails: React.FC<ContributionDetailsProps> = ({
  giftAmount,
  onMakePayment,
  preference,
}) => {
  const handleMakePayment = () => onMakePayment && onMakePayment()

  return (
    <div className={css.ContributionDetails}>
      <CrowdfundReserveModalTitle>
        Contribution details
      </CrowdfundReserveModalTitle>
      <div className={css.ContributionDetails__Content}>
        <p>Head over to {preference.name} to give your gift!</p>
        <AccountAttributes expanded preference={preference} />
      </div>
      <ReturnToBabylistReminder />
      <Button
        href={formattedUrl(preference, giftAmount)}
        icon={{
          side: 'right',
          icon: <LinkOut className={css.ContributionDetails__ButtonIcon} />,
        }}
        size="md"
        target="_blank"
        variant="primary"
        onClick={handleMakePayment}
      >
        Take me to {preference.name}
      </Button>
    </div>
  )
}

export default ContributionDetails
