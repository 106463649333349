import CashFundPiggy from './CashFundPiggy.svg'
import Venmo from './Venmo.svg'
import Paypal from './Paypal.svg'
import Zelle from './Zelle.svg'
import Check from './Check.svg'
import Checklist from './Checklist.svg'
import Close from './Close.svg'
import Discount from './Discount.svg'
import EditPencil from './EditPencil.svg'
import Heart from './Heart.svg'
import Link from './Link.svg'
import LinkOut from './LinkOut.svg'
import LeftArrow from './LeftArrow.svg'
import Filter from './Filter.svg'
import Note from './Note.svg'
import Star from './Star.svg'
import DragHandle from './DragHandle.svg'
import GarbageBin from './GarbageBin.svg'
import GarbageBinInfo from './GarbageBinInfo.svg'
import GiftTracker1 from './GiftTracker1.svg'
import LightBulb from './LightBulb.svg'
import Minus from './Minus.svg'
import Plus from './Plus.svg'
import AppleLogo from './AppleLogo.svg'
import GooglePlayLogo from './GooglePlayLogo.svg'
import ChromeExtension from './ChromeExtension.svg'
import Divider from './Divider.svg'
import Eye from './Eye.svg'
import LeftChevron from './LeftChevron.svg'
import Lock from './Lock.svg'
import MagnifyingGlass from './MagnifyingGlass.svg'
import RightArrow from './RightArrow.svg'
import RightChevron from './RightChevron.svg'
import Share from './Share.svg'
import Chevron from './Chevron.svg'
import CashFundIcon from './CashFundIcon.svg'
import GroupGiftIcon from './GroupGiftIcon.svg'
import LockOpen from './LockOpen.svg'
import UserOutline from './UserOutline.svg'
import Play from './Play.svg'
import Info from './Info.svg'
import Exclamation from './Exclamation.svg'
import SimpleShipping from './SimpleShipping.svg'
import SimpleShipping1 from './SimpleShipping1.svg'
import ThumbKnob from './ThumbKnob.svg'
import Exclamation2 from './Exclamation2.svg'
import Copy from './Copy.svg'
import NotificationBell from './NotificationBell.svg'
import ShoppingCartEmpty from './ShoppingCartEmpty.svg'
import Menu from './Menu.svg'
import MostWanted from './MostWanted.svg'
import MostWantedFilled from './MostWantedFilled.svg'
import CheckCircle from './CheckCircle.svg'
import HelpFavors from './HelpFavors.svg'
import GiftCard4 from './GiftCard4.svg'
import GiftCard2 from './GiftCard2.svg'
import Shipping1 from './Shipping1.svg'
import Calendar from './Calendar.svg'
import PriceRange from './PriceRange.svg'
import Store from './Store.svg'
import Sort from './Sort.svg'
import Bag from './Bag.svg'
import Discount2 from './Discount2.svg'
import Image from './Image.svg'
import ArrowBottom from './ArrowBottom.svg'
import ArrowLeft from './ArrowLeft.svg'
import ArrowRight from './ArrowRight.svg'
import ArrowTop from './ArrowTop.svg'
import AddOutline from './AddOutline.svg'
import FlowerShades from './FlowerShades.svg'
import Location from './Location.svg'

export {
  AddOutline,
  AppleLogo,
  ArrowBottom,
  ArrowLeft,
  ArrowRight,
  ArrowTop,
  Bag,
  Calendar,
  CashFundIcon,
  CashFundPiggy,
  Check,
  CheckCircle,
  Checklist,
  Chevron,
  ChromeExtension,
  Close,
  Copy,
  Discount,
  Discount2,
  Divider,
  DragHandle,
  EditPencil,
  Eye,
  Exclamation,
  Exclamation2,
  Filter,
  FlowerShades,
  GarbageBin,
  GarbageBinInfo,
  GiftCard2,
  GiftCard4,
  GiftTracker1,
  GooglePlayLogo,
  GroupGiftIcon,
  Heart,
  HelpFavors,
  Image,
  Info,
  LeftArrow,
  LeftChevron,
  Link,
  LinkOut,
  LightBulb,
  Location,
  Lock,
  LockOpen,
  MagnifyingGlass,
  Menu,
  Minus,
  MostWanted,
  MostWantedFilled,
  Note,
  NotificationBell,
  Play,
  Plus,
  PriceRange,
  RightArrow,
  RightChevron,
  Share,
  Shipping1,
  ShoppingCartEmpty,
  SimpleShipping,
  SimpleShipping1,
  Sort,
  Star,
  Store,
  ThumbKnob,
  UserOutline,
  Venmo,
  Paypal,
  Zelle,
}
