import { useEffect, useState } from 'react'
import { BrowserRouter, MemoryRouter, StaticRouter } from 'react-router-dom'
import {
  BrowserRouter as BrowserRouterV6,
  MemoryRouter as MemoryRouterV6,
  StaticRouter as StaticRouterV6,
} from 'react-router-dom-v5-compat'

interface UniversalRouterProps {
  isMountedCallback?: (mounted: boolean) => void
  useV6Router?: boolean
  [routerProps: string]: any
}

const UniversalRouter = ({
  isMountedCallback = (isMounted) => isMounted,
  useV6Router = false,
  ...routerProps
}: UniversalRouterProps) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)

    if (isMountedCallback) {
      isMountedCallback(true)
    }
  }, [])

  const Router = getRouterType(mounted, useV6Router)

  // @ts-ignore
  return <Router {...routerProps} />
}

const getRouterType = (mounted: boolean, useV6Router: boolean) => {
  if (process.env.NODE_ENV == 'test') {
    return useV6Router ? MemoryRouterV6 : MemoryRouter
  }

  if (mounted) {
    return useV6Router ? BrowserRouterV6 : BrowserRouter
  } else {
    return useV6Router ? StaticRouterV6 : StaticRouter
  }
}

export default UniversalRouter
