const InfoIcon = ({
  className,
  title,
}: {
  className?: string
  title?: string
}) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title || 'More Info'}</title>
    <g clipPath="url(#clip0_417_5912)">
      <circle
        cx="7.99967"
        cy="8.00016"
        r="6.66667"
        stroke="#6B6669"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33301 6.6665V6.6665C7.7012 6.6665 7.99967 6.96498 7.99967 7.33317V9.99984C7.99967 10.368 8.29815 10.6665 8.66634 10.6665V10.6665"
        stroke="#6B6669"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4.97412V5.00016"
        stroke="#6B6669"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_5912">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default InfoIcon
