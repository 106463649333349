import React from 'react'
import PurchasesSection from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/PurchasesSection/PurchasesSection'
import PurchaseBullet from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/PurchasesSection/components/PurchaseBullet/PurchaseBullet'
import Gift from 'baby-design/icons/Gift.svg'
import { purchasesStatus } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/PurchasesSection/utils/helpers'
import RegItemPurchaseModal from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/RegItemPurchaseModal/RegItemPurchaseModal'
import { RegItemPurchasesSectionProps } from './RegItemPurchasesSection.types'

const TITLE = 'Purchases'
const MARK_AS_PURCHASED = 'Mark as purchased'
const MARK_ANOTHER_PURCHASE = 'Mark another purchase'

const RegItemPurchasesSection: React.FC<RegItemPurchasesSectionProps> = ({
  onRegItemUpdated,
  regItem,
  reservations = [],
}) => {
  const currentQuantity = reservations.reduce(
    (sum, reservation) => sum + reservation.quantity,
    0
  )

  return (
    <PurchasesSection
      bullet={PurchaseBullet}
      icon={Gift}
      initialButtonText={MARK_AS_PURCHASED}
      markAsPurchasedModal={RegItemPurchaseModal}
      purchasedButtonText={MARK_ANOTHER_PURCHASE}
      regItem={regItem}
      reservations={reservations}
      status={purchasesStatus(currentQuantity, regItem.quantity)}
      title={TITLE}
      onRegItemUpdated={onRegItemUpdated}
    />
  )
}

export default RegItemPurchasesSection
