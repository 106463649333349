/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types'
import { Button } from './buttons'
import Link from './link'

const strippedStyles = {
  border: 0,
  padding: 0,
  color: 'inherit',
  display: 'block',
  background: 'none',
  fontSize: 'inherit',
  fontFamily: 'inherit',
}

export const UnstyledButton = ({ style, children, ...rest }) => (
  <Button type="button" style={{ ...strippedStyles, ...style }} {...rest}>
    {children}
  </Button>
)

export const UnstyledLink = ({ style, children, ...rest }) => (
  <Link style={{ ...strippedStyles, ...style }} {...rest}>
    {children}
  </Link>
)

UnstyledButton.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
}

UnstyledButton.defaultProps = {
  style: undefined,
}

UnstyledLink.propTypes = UnstyledButton.propTypes
UnstyledLink.defaultProps = UnstyledButton.defaultProps

const Clickable = ({ onClick, url, to, children, ...rest }) => {
  const isLink = !!url || !!to
  if (isLink && onClick) {
    return (
      <UnstyledLink url={url} to={to} {...rest}>
        <UnstyledButton onClick={onClick}>{children}</UnstyledButton>
      </UnstyledLink>
    )
  }
  if (isLink) {
    return (
      <UnstyledLink url={url} to={to} {...rest}>
        {children}
      </UnstyledLink>
    )
  }
  return (
    <UnstyledButton onClick={onClick} {...rest}>
      {children}
    </UnstyledButton>
  )
}

Clickable.propTypes = {
  to: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
}

Clickable.defaultProps = {
  to: undefined,
  url: undefined,
  onClick: undefined,
}

export default Clickable
