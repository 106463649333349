import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { filter, pickBy, sumBy } from 'lodash'
import { toDate, format } from 'date-fns'
import { numberToCurrency } from 'lib/money'
import { GroupGiftModal } from 'components/modal'
import { Checkbox } from 'components/forms'
import { UPDATE_CART_SUMMARY } from 'shared/constants'
import { reservationsPath } from 'lib/urls'
import { GROUP_GIFT_ACTIONS } from 'registry/constants'
import { RegItem } from 'src/types/regItem'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { errorMessage, successMessage } from 'lib/flash-message'
import {
  addCartItem,
  deleteRegItem,
  getRegItemGroupGiftContributions,
  redeemGroupGifts,
  saveRegItem,
} from 'src/api/queries'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import css from './GroupGiftRedemptionModal.styles.scss'
import CTAButton from './components/CTAButton/CTAButton'
import {
  invalidateRegItemsQuery,
  optimisticallyDeleteRegItem,
  optimisticallyUpdateRegItem,
} from '../../utils/registryHelpers'

export const GROUP_GIFT_REDEMPTION_MODAL = 'GROUP_GIFT_REDEMPTION_MODAL'

const getContributions = async (regItem: RegItem) =>
  getRegItemGroupGiftContributions(regItem?.registryId, regItem?.id)

export type GroupGiftAction =
  | 'add-to-cart'
  | 'disable-group-gift'
  | 'delete-reg-item'
  | 'quick-add-to-cart'

interface Fund {
  id: number
  amount: number
  fundedAt: string
  funderName: string
}

interface GroupGiftRedemptionModalProps {
  regItem: RegItem
  action: GroupGiftAction
}

export default NiceModal.create(
  ({ regItem, action }: GroupGiftRedemptionModalProps) => {
    const modal = useModal()

    const queryClient = useQueryClient()
    const [unredeemedFundsToDisplay, setUnredeemedFundsToDisplay] = useState<
      Fund[]
    >([])
    const [fundedAmount, setFundedAmount] = useState(0)
    const [fundsRedeemedAmount, setFundsRedeemedAmount] = useState(0)
    const [isFetching, setIsFetching] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [creditBalance, setCreditBalance] = useState(0)
    const [totalFundsToRedeem, setTotalFundsToRedeem] = useState(0)
    const [allUnredeemedFundsChecked, setAllUnredeemedFundsChecked] =
      useState(true)
    const { register, getValues } = useForm()

    const { isBabylistProductDiscontinued, registryId } = regItem
    const hasUnredeemedFunds = unredeemedFundsToDisplay.length > 0
    const creditBalanceAfterRedemption = creditBalance + totalFundsToRedeem

    async function getContributionsAsync() {
      const { groupGift } = await getContributions(regItem)
      const {
        fundedAmount,
        fundsRedeemedAmount,
        unredeemedFunds,
        userCreditBalance,
      } = groupGift
      setIsFetching(false)
      setCreditBalance(userCreditBalance)
      setFundsRedeemedAmount(fundsRedeemedAmount)
      setUnredeemedFundsToDisplay(unredeemedFunds)
      setTotalFundsToRedeem(
        sumBy(unredeemedFunds, (f: { amount: number }) => f.amount)
      )
      setFundedAmount(fundedAmount)
    }

    useEffect(() => {
      if (!modal.visible) {
        setUnredeemedFundsToDisplay([])
        setFundedAmount(0)
        setFundsRedeemedAmount(0)
        setIsFetching(false)
        setIsSubmitting(false)
        setCreditBalance(0)
        setTotalFundsToRedeem(0)
        setAllUnredeemedFundsChecked(true)
      } else {
        setIsFetching(true)
        getContributionsAsync()
      }
    }, [modal.visible])

    const onClose = () => {
      if (!isSubmitting) {
        modal.resolve(undefined)
        modal.remove()
      }
    }

    const handleAddToCart = (options = { gotoCartAfterAdding: false }) => {
      const { gotoCartAfterAdding } = options
      addCartItem({
        productId: regItem?.productId,
        quantity: 1,
      })
        .then(() => {
          successMessage(`${regItem?.title} added to cart!`)
          PubSub.publish(UPDATE_CART_SUMMARY, { showCartDropdown: true })
          modal.resolve(true)
          modal.remove()
          if (gotoCartAfterAdding) {
            window.location.href = reservationsPath
          }
        })
        .catch((resp: any) => {
          console.error(resp)
          errorMessage(`Failed to add ${regItem?.title} to cart.`)
        })
    }

    const { mutate: saveRegItemMutation } = useMutation({
      mutationFn: ({
        itemId,
        regItemChanges: { isGroupGift },
      }: {
        itemId: number
        regItemChanges: { isGroupGift: boolean }
      }) => {
        optimisticallyUpdateRegItem({
          queryClient,
          updatedRegItem: { ...regItem, isGroupGift },
        })

        return saveRegItem(registryId, itemId, { isGroupGift })
      },
      onSuccess: ({ regItem: newRegItem }: { regItem: RegItem }) => {
        invalidateRegItemsQuery({
          queryClient,
          registryId: newRegItem.registry.id,
        })
        successMessage(`${newRegItem.title} was successfully saved!`)
        modal.resolve(newRegItem)
        modal.remove()
      },
      onError: (error: any) => {
        console.error(error)
        errorMessage(
          error?.error?.message ||
            'Failed to save. Try refreshing the page. If the problem persists, contact support.'
        )
      },
    })

    const handleDisableGroupGift = () => {
      saveRegItemMutation({
        itemId: regItem?.id,
        regItemChanges: { isGroupGift: false },
      })
    }

    const { mutate: deleteRegItemMutation } = useMutation({
      mutationFn: (itemId: number) => {
        optimisticallyDeleteRegItem({
          queryClient,
          deletedRegItem: regItem,
        })

        return deleteRegItem(registryId, itemId)
      },
      onSuccess: () => {
        invalidateRegItemsQuery({
          queryClient,
          registryId: regItem.registry.id,
        })
        successMessage(`${regItem?.title} was successfully deleted!`)
        modal.resolve(true)
        modal.remove()
      },
      onError: (error: any) => {
        console.error(error)
        errorMessage(
          error?.error?.message ||
            'Failed to delete. Try refreshing the page. If the problem persists, contact support.'
        )
      },
    })

    const handleRemoveRegItem = () => {
      deleteRegItemMutation(regItem?.id)
    }

    const getCheckedFundIds = () =>
      Object.keys(
        pickBy(getValues(), (checked, fundId) => {
          if (checked) {
            return fundId
          }
          return false
        })
      )

    const handleOnChange = () => {
      const checkedFundIds = getCheckedFundIds()
      const selectedFunds = filter(unredeemedFundsToDisplay, ({ id }) =>
        checkedFundIds.includes(id.toString())
      )
      const totalSelectedFundsAmount = sumBy(selectedFunds, (f) => f.amount)
      setTotalFundsToRedeem(totalSelectedFundsAmount)
      setAllUnredeemedFundsChecked(
        selectedFunds.length == unredeemedFundsToDisplay.length
      )
    }

    const handleSubmit = () => {
      const groupGiftIdsToRedeem = getCheckedFundIds()
      setIsSubmitting(true)

      if (groupGiftIdsToRedeem.length > 0) {
        redeemGroupGifts({ fundIds: groupGiftIdsToRedeem })
      }

      // CTA action to perform after redeeming available funds
      switch (action) {
        case GROUP_GIFT_ACTIONS.CART:
          handleAddToCart({ gotoCartAfterAdding: true })
          break
        case GROUP_GIFT_ACTIONS.DELETE:
          handleRemoveRegItem()
          break
        case GROUP_GIFT_ACTIONS.DISABLE:
          handleDisableGroupGift()
          break
        case GROUP_GIFT_ACTIONS.QUICKADD:
          handleAddToCart()
          break
        default:
          break
      }
      setIsSubmitting(false)
    }

    return (
      <GroupGiftModal
        backdropProps={{
          style: { zIndex: 9995 },
        }}
        className={css.group_gift_modal__modal}
        show={modal.visible}
        title="Gift Funds For This Item Will Be Applied at Checkout"
        onHide={onClose}
      >
        {fundsRedeemedAmount > 0 && (
          <p className="h6">
            <em>
              {numberToCurrency(fundsRedeemedAmount)} of the{' '}
              {numberToCurrency(fundedAmount)} funded for this item has already
              been applied.
            </em>
          </p>
        )}

        {hasUnredeemedFunds && (
          <>
            <p className="h6">
              <strong>
                Gift funds will be converted to gift card credit
                {creditBalance > 0 && (
                  <>
                    {' '}
                    and combined with your existing{' '}
                    {numberToCurrency(creditBalance)} gift card credit
                  </>
                )}
                .
              </strong>{' '}
              Any unused funds after checkout will automatically be applied to
              your next purchase.
            </p>

            <div className={classNames(css.fundDisplay, 'mvl')}>
              {unredeemedFundsToDisplay.map(
                ({ amount, fundedAt, funderName, id }) => {
                  const date = toDate(new Date(fundedAt))
                  return (
                    <Checkbox
                      defaultChecked
                      groupClassName={css.fund}
                      help={
                        <div className="xsmall man">{`Funded on ${format(
                          date,
                          'M/dd/yyyy'
                        )}`}</div>
                      }
                      inputRef={register}
                      key={id}
                      label={
                        <div className="h6 man text-bold">{`$${amount} from ${funderName}`}</div>
                      }
                      name={id.toString()}
                      onChange={handleOnChange}
                    />
                  )
                }
              )}
            </div>
          </>
        )}

        <div className="text-center">
          <CTAButton
            action={action}
            disabled={isFetching || !allUnredeemedFundsChecked}
            hasUnredeemedFunds={hasUnredeemedFunds}
            isBabylistProductDiscontinued={isBabylistProductDiscontinued}
            submitting={isSubmitting}
            onClick={handleSubmit}
          />

          {creditBalanceAfterRedemption > 0 && allUnredeemedFundsChecked && (
            <div className="h6 mbn pbn text-bold">
              You'll have {numberToCurrency(creditBalanceAfterRedemption)} in
              total gift card credit at checkout.
            </div>
          )}
          {!allUnredeemedFundsChecked &&
            action == GROUP_GIFT_ACTIONS.DELETE && (
              <div className="h6 mbn pbn text-bold">
                You must apply all gift funds to remove this item.
              </div>
            )}
        </div>
      </GroupGiftModal>
    )
  }
)
