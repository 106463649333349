import { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Radio, RadioGroup } from 'components/forms'
import css from './address-suggestion-form.scss'
import { InfoButton, LinkButton } from 'components/buttons'

const AddressBlock = ({
  title,
  name,
  streetAddress1,
  streetAddress2,
  city,
  state,
  zip,
  country,
  phone,
  fieldErrors = {},
}) => (
  <div className="text-uppercase mhs">
    <span className="text-capitalize text-bold mbm">{title}</span>
    <br />
    <span>{name}</span>
    <br />
    <span className={fieldErrors.address1 && 'text-bold'}>
      {streetAddress1}
    </span>
    <br />
    <span className={fieldErrors.address2 && 'text-bold'}>
      {streetAddress2}
    </span>
    {streetAddress2 && <br />}
    <span className={fieldErrors.city && 'text-bold'}>{city}</span>
    <span className={fieldErrors.state && 'text-bold'}>, {state}</span>
    <span className={fieldErrors.zip && 'text-bold'}> {zip}</span>
    <br />
    <span>{country}</span>
  </div>
)

export class AddressSuggestionForm extends Component {
  static propTypes = {
    recommendedAddress: PropTypes.shape({
      name: PropTypes.string,
      streetAddress1: PropTypes.string,
      streetAddress2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      country: PropTypes.string,
      phone: PropTypes.string,
    }),
    enteredAddress: PropTypes.shape({
      name: PropTypes.string,
      streetAddress1: PropTypes.string,
      streetAddress2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      country: PropTypes.string,
      phone: PropTypes.string,
    }),
    validationErrors: PropTypes.object,
    fields: PropTypes.shape({
      addressOption: PropTypes.object,
    }),
    fieldErrors: PropTypes.object,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    onEdit: PropTypes.func,
    onConfirm: PropTypes.func,
    onSubmitSuccess: PropTypes.func,
  }

  handleSubmit = ({ addressOption }) => {
    const address =
      addressOption == 'recommended'
        ? this.props.recommendedAddress
        : this.props.enteredAddress
    address.userConfirmed = true
    this.props.onConfirm(address)
  }

  render() {
    let {
      fields: { addressOption },
      fieldErrors,
      submitting,
      handleSubmit,
      onEdit,
      recommendedAddress,
      enteredAddress,
    } = this.props
    const recommendedAddressText = (
      <AddressBlock
        title="Recommended Address"
        {...recommendedAddress}
        fieldErrors={fieldErrors}
      />
    )
    const enteredAddressText = (
      <AddressBlock title="Original Address" {...enteredAddress} />
    )

    return (
      <Form
        className="mbxl"
        onSubmit={handleSubmit(this.handleSubmit.bind(this))}
      >
        <p className="text-left">
          This address doesn't match postal records. We've provided a suggestion
          below.
        </p>
        {fieldErrors.base ? (
          <div className="h7 text-center small">{fieldErrors.base}</div>
        ) : null}
        <RadioGroup horizontal>
          <Radio
            {...addressOption}
            defaultChecked={true}
            className={css.addressOption}
            label={recommendedAddressText}
            value="recommended"
          />
          <Radio
            {...addressOption}
            className={css.addressOption}
            label={enteredAddressText}
            value="original"
          />
        </RadioGroup>
        <InfoButton block type="submit" submitting={submitting}>
          Use this address
        </InfoButton>
        <LinkButton className="center-x mbm" onClick={onEdit}>
          <small>Edit your address</small>
        </LinkButton>
      </Form>
    )
  }
}
