import { sectionAnchor } from 'hello_baby/lib/helpers'
import React, { ReactNode } from 'react'

interface AnchoredHProps {
  explicitAnchor?: string
  textForAnchor?: string
  children: ReactNode
  headingType: 'h2' | 'h3'
}

const AnchoredH: React.FC<AnchoredHProps> = ({
  textForAnchor,
  explicitAnchor,
  children,
  headingType,
}: AnchoredHProps) => {
  const anchor = sectionAnchor(explicitAnchor || textForAnchor)

  return React.createElement(
    headingType,
    { id: anchor, style: { scrollMarginTop: '100px' } },
    children
  )
}

export default AnchoredH
