/* eslint-disable import/no-extraneous-dependencies */
import { FC } from 'react'
import classNames from 'classnames'
import {
  IconButton,
  StoreIcon,
  LoadingDots,
  PriceTag,
  Button,
  QuantityPicker,
} from 'baby-design'
import { EditPencil, LinkOut, ShoppingCartEmpty } from 'baby-design/icons'
import { Offer } from 'src/types/offer'
import { getPriceTagPropsFromPriceDetails } from 'src/utils/productHelpers'
import { track } from 'lib/analytics/track'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { formatDisplayPrice } from '../../../../utils/productHelpers'

import css from './SelfPurchaseBuyingOption.styles.scss'

const LegacyPriceTag = ({ price }: { price: string | number | undefined }) => (
  <div className={css.SelfPurchaseBuyingOption__price}>
    {formatDisplayPrice(price)}
  </div>
)

interface SelfPurchaseBuyingOptionProps {
  editOnClick?: () => void
  handleAddToCart: () => void
  handleUpdateCartItemQuantity: (quantity: number) => void
  handleRemoveCartItem: () => void
  quantityInCart: number
  isLoading: boolean
  offer: Offer
  regItemId: number
  useHorizontalPadding: boolean
}

export const SelfPurchaseBuyingOption: FC<SelfPurchaseBuyingOptionProps> = ({
  editOnClick,
  handleAddToCart,
  handleUpdateCartItemQuantity,
  handleRemoveCartItem,
  quantityInCart,
  isLoading,
  offer,
  regItemId,
  useHorizontalPadding,
}) => {
  const [currentUser] = useCurrentUser()
  let priceTagProps = {}
  if (offer.priceDetails) {
    priceTagProps = getPriceTagPropsFromPriceDetails(offer.priceDetails)
  }

  const babylistOfferControl =
    quantityInCart > 0 ? (
      <QuantityPicker
        className={css.SelfPurchaseBuyingOption__quantityPicker}
        size="sm"
        type="cart"
        value={quantityInCart}
        variant="fill"
        onMinusClick={() => handleUpdateCartItemQuantity(quantityInCart - 1)}
        onPlusClick={() => handleUpdateCartItemQuantity(quantityInCart + 1)}
        onTrashClick={handleRemoveCartItem}
      />
    ) : (
      <Button
        className={classNames(
          css.SelfPurchaseBuyingOption__purchaseButton,
          css.SelfPurchaseBuyingOption__addToCartButton
        )}
        icon={{ side: 'right', icon: <ShoppingCartEmpty />, size: 'xl' }}
        loading={isLoading}
        size="sm"
        variant="de-emphasized"
        onClick={(e) => {
          e.stopPropagation()
          trackOfferClicked()
          handleAddToCart()
        }}
      >
        Add
      </Button>
    )

  // Need to differentiate between the modal and the edit item drawer
  const isInMobileBuyingSheet = useHorizontalPadding

  const trackOfferClicked = () => {
    track.registryProductOfferClicked({
      eventLocation: isInMobileBuyingSheet
        ? track.EventLocation.REGISTRY_MINUS_BUYING_OPTIONS_MODAL
        : track.EventLocation.REG_ITEM_EDIT_SCREEN,
      registryId: currentUser?.currentRegistry.id,
      registryOwnerId: currentUser?.currentRegistry.ownerId,
      listType: currentUser?.currentRegistry.type || 'baby_registry',
      isRegistryOwner: currentUser?.currentRegistry.currentUserRole === 'owner',
      store: offer.storeDisplayName,
      regItemId,
      offerIds: [offer.id],
    })
  }

  const handleLinkClick = (url: string) => {
    trackOfferClicked()
    window.open(url, '_blank')
  }

  return (
    <div
      className={classNames(css.SelfPurchaseBuyingOption, {
        [css['SelfPurchaseBuyingOption--horizontalPadding']]:
          useHorizontalPadding,
      })}
    >
      <div className={css.SelfPurchaseBuyingOption__icon}>
        <StoreIcon store={{ name: offer.storeIconName }} />
      </div>
      <div className={css.SelfPurchaseBuyingOption__info}>
        <div className={css.SelfPurchaseBuyingOption__nameAndPrice}>
          <button
            className={css.SelfPurchaseBuyingOption__name}
            role="link"
            type="button"
            onClick={() => handleLinkClick(offer.url || '')}
          >
            {offer.storeDisplayName || <LoadingDots />}
          </button>
          {offer.priceDetails ? (
            <PriceTag {...priceTagProps} size="2xs" variant="offer" />
          ) : (
            <LegacyPriceTag price={offer.price} />
          )}
        </div>
        {editOnClick && (
          <IconButton
            className={css.SelfPurchaseBuyingOption__secondaryLinkEditButton}
            size="xs"
            variant="standard-default"
            onClick={editOnClick}
          >
            <EditPencil />
          </IconButton>
        )}
        {offer.isBabylist ? (
          babylistOfferControl
        ) : (
          <Button
            className={css.SelfPurchaseBuyingOption__purchaseButton}
            icon={{ side: 'right', icon: <LinkOut /> }}
            size="sm"
            variant="tertiary"
            onClick={() => handleLinkClick(offer.url || '')}
          >
            View
          </Button>
        )}
      </div>
    </div>
  )
}
