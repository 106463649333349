import css from './SearchResultComponents.scss'
import { Text } from '../../types'

const SearchResultText = ({ result }: { result: Text }) => {
  return (
    <div className={css.SearchResults__result}>
      <span className={css.SearchResults__resultText}>{result.text}</span>
    </div>
  )
}

export default SearchResultText
