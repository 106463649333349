import React from 'react'
import { TwoColumnResponsiveLayout } from 'components/presentation'
import { InfoButton, SecondaryButton } from 'components/buttons'
import { HELLO_EMAIL } from 'shared/constants'
import SecondaryActionLinks from 'registry/components/LegacyCrowdfundReserveForm/components/SecondaryActionLinks/SecondaryActionLinks'
import { PayWithPaypalProps } from 'registry/components/LegacyCrowdfundReserveForm/components/PayWithPaypal/PayWithPaypal.types'
import css from './PayWithPaypal.styles.scss'

const PayWithPaypal: React.FC<PayWithPaypalProps> = ({
  onCancel,
  onHide,
  onReset,
  preferences,
}) => (
  <div>
    <TwoColumnResponsiveLayout className="ban">
      <div className="text-center">
        <div className="well paxl">
          {preferences?.paypalme ? (
            <>
              <p>
                Click the Paypal link below to make a contribution to their cash
                fund.
              </p>
              <SecondaryButton
                newWindow
                pill
                className="mtl"
                href={`https://paypal.me/${preferences.paypalme}`}
              >
                Go to Paypal to Pay
              </SecondaryButton>
              <br />
            </>
          ) : (
            <p>
              We don't have a Paypal link for this registry. If you need it,
              email us at <a href={`mailto:${HELLO_EMAIL}`}>{HELLO_EMAIL}</a>{' '}
              and we'll request it from them.
            </p>
          )}
        </div>
      </div>
      <div>
        <p>We'll let them know that you plan to give them this gift!</p>
        <InfoButton
          pill
          className={css.PayWithPaypal__SubmitButton}
          onClick={onHide}
        >
          I'm finished
        </InfoButton>
      </div>
    </TwoColumnResponsiveLayout>
    <SecondaryActionLinks onCancel={onCancel} onReset={onReset} />
  </div>
)

export default PayWithPaypal
