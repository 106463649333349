const Overlay = ({ show }: { show: boolean }) => {
  return show ? (
    <div
      style={{
        position: 'absolute',
        top: '100%',
        left: 0,
        width: '100%',
        height: '100vh',
        background: 'rgba(28, 13, 25, 0.50)',
        zIndex: -1,
      }}
      data-testid="background-overlay"
    />
  ) : null
}

export default Overlay
