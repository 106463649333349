import classNames from 'classnames'
import { FormGroup, HelpBlock } from 'react-bootstrap'

export const buildComponent = (
  input,
  { block, groupClassName, size, error, help, style }
) => {
  return (
    <FormGroup
      style={style}
      className={classNames(groupClassName, `radio-${size || 'medium'}`, {
        'form-group--block': block,
      })}
      validationState={error ? 'error' : null}
    >
      {input}
      {error || help ? (
        <HelpBlock className={classNames('h6', { 'text-muted': !error })}>
          {/* if an element has help text and an error just show the error. otherwise show help text */}
          {error || help}
        </HelpBlock>
      ) : null}
    </FormGroup>
  )
}
