import React from 'react'
import {
  CASH,
  CASH_CHECK_INSTRUCTIONS_STEP,
  CASH_PAYMENT_OPTION,
  PAYMENT_OPTION_STEP,
  PAYPAL,
  PAYPAL_PAYMENT_OPTION,
  PAYPALME_INSTRUCTIONS_STEP,
  RESERVATION_INFO_STEP,
  THANK_YOU_ADDRESS_STEP,
} from 'registry/components/LegacyCrowdfundReserveForm/constants'
import { CashFundPreferences } from 'src/types/registry'
import { useGiftGiverCookie } from 'lib/cookies'
import { UPDATE_CART_SUMMARY } from 'shared/constants'
import { MultiStepWizard } from 'components/presentation'
import PaymentOptions from 'registry/components/CrowdfundReserveFormFlow/components/PaymentOptions/PaymentOptions'
import GiftGiverInfoForm from 'registry/components/LegacyCrowdfundReserveForm/components/GiftGiverInfoForm/GiftGiverInfoForm'
import ThankYouAddressModal from 'registry/components/modals/ThankYouAddressModal'
import CashContributionConfirmation from 'registry/components/LegacyCrowdfundReserveForm/components/CashContributionConfirmation/CashContributionConfirmation'
import PayWithPaypal from 'registry/components/LegacyCrowdfundReserveForm/components/PayWithPaypal/PayWithPaypal'
import { CrowdfundReserveFormFlowProps } from 'registry/components/LegacyCrowdfundReserveForm/LegacyCrowdfundReserveForm.types'
import { GiftGiverInfoFormState } from 'registry/components/LegacyCrowdfundReserveForm/components/GiftGiverInfoForm/GiftGiverInfoForm.types'
import useCrowdfundReservation from 'registry/hooks/useCrowdfundReservation'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { isRegistryOwnerOrPartner } from 'registry/lib/registryHelpers'

const eligiblePaymentOptions = (preferences: CashFundPreferences) => {
  const options = []
  if (preferences?.direct) {
    options.push(CASH_PAYMENT_OPTION)
  }

  if (preferences?.paypalme) {
    options.push(PAYPAL_PAYMENT_OPTION)
  }

  return options
}

const LegacyCrowdfundReserveForm: React.FC<CrowdfundReserveFormFlowProps> = ({
  regItem,
  preferences,
  onCreateReservation,
  onUpdateReservation,
  onRemoveReservation,
  onHide,
  isGiftGiverThankYouAddressProvided,
  registry,
}) => {
  const [currentUser] = useCurrentUser()
  const { name, email } = useGiftGiverCookie()

  const crowdfundReservationState = useCrowdfundReservation({
    initialStep: PAYMENT_OPTION_STEP,
    onCreateReservation,
    onUpdateReservation,
    onRemoveReservation,
    regItem,
  })

  const {
    activeStep,
    currentReservation,
    paymentOption,
    cancelReservation,
    saveReservation,
    setActiveStep,
    setPaymentOption,
  } = crowdfundReservationState

  const handleReset = () => {
    setActiveStep(PAYMENT_OPTION_STEP)
    setPaymentOption(undefined)
  }

  const handleReservation = (formState: GiftGiverInfoFormState) => {
    saveReservation(formState)
    if (
      !isGiftGiverThankYouAddressProvided &&
      !isRegistryOwnerOrPartner(currentUser, registry)
    ) {
      setActiveStep(THANK_YOU_ADDRESS_STEP)
    } else {
      if (paymentOption === CASH) {
        setActiveStep(CASH_CHECK_INSTRUCTIONS_STEP)
      }
      if (paymentOption === PAYPAL) {
        setActiveStep(PAYPALME_INSTRUCTIONS_STEP)
      }
    }
  }

  const handleCancelReservation = () => {
    cancelReservation().then(() => {
      PubSub.publish(UPDATE_CART_SUMMARY, {
        showCartDropdown: false,
        animateBadge: true,
      })
    })
    onHide()
  }

  const handlePaymentOptionSelected = (option: string) => {
    setActiveStep(RESERVATION_INFO_STEP)
    setPaymentOption(option)
  }

  return (
    <MultiStepWizard activeStep={activeStep} animateInitialState={false}>
      {/* Step 1 */}
      <PaymentOptions
        paymentOptions={eligiblePaymentOptions(preferences)}
        regItem={regItem}
        title="Payment options"
        onPaymentOptionSelected={handlePaymentOptionSelected}
      />

      {/* Step 2: Reservation Info */}
      <GiftGiverInfoForm
        regItem={regItem}
        reservation={currentReservation}
        onFormSubmitted={handleReservation}
      />

      {/* Step 3: Thank You Address */}
      {/* @ts-ignore */}
      <ThankYouAddressModal
        afterSubmit={() => {
          if (paymentOption === CASH) {
            setActiveStep(CASH_CHECK_INSTRUCTIONS_STEP)
          }
          if (paymentOption === PAYPAL) {
            setActiveStep(PAYPALME_INSTRUCTIONS_STEP)
          }
        }}
        giftGiverInfo={{
          name: name || null,
          email: email || null,
          productId: null,
        }}
        onHide={onHide}
      />

      {/* Step 4: Address */}
      <CashContributionConfirmation
        preferences={preferences}
        onCancel={handleCancelReservation}
        onHide={onHide}
        onReset={handleReset}
      />

      {/* Step 5: Paypal */}
      <PayWithPaypal
        preferences={preferences}
        onCancel={handleCancelReservation}
        onHide={onHide}
        onReset={handleReset}
      />
    </MultiStepWizard>
  )
}

export default LegacyCrowdfundReserveForm
