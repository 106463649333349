import Link from 'components/link'
import { socialLinks } from './constants'
import css from './SocialLinks.scss'

interface SocialLinksProps {
  className?: string
}

const SocialLinks = ({ className }: SocialLinksProps) => (
  <div className={className}>
    <div className="h7 text-uppercase mvl mbs text-bold">Connect with us</div>
    <ul className="list-inline">
      {socialLinks.map((link) => (
        <li key={link.name} className="prn mbs mhs" style={{ paddingLeft: 4 }}>
          <Link
            url={link.href}
            className={css.socialLink}
            newWindow
            aria-label={link.name}
          >
            {link.Component && <link.Component height="20" width="20" />}
            {link.icon && (
              <i className={`fa ${link.icon}`} aria-hidden="true" />
            )}
          </Link>
        </li>
      ))}
    </ul>
  </div>
)

export default SocialLinks
