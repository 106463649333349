import React from 'react'

interface AppleLogoProps {
  className?: string
}

const AppleLogo: React.FC<AppleLogoProps> = ({ className }: AppleLogoProps) => (
  <svg
    className={className}
    fill="none"
    height="21"
    viewBox="0 0 20 21"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.955 1.89453C13.955 1.72083 13.8827 1.55496 13.7555 1.43671C13.6282 1.31846 13.4575 1.2585 13.2843 1.27121C12.286 1.34443 11.1587 1.87537 10.4893 2.53729C9.7503 3.2606 9.29689 4.21186 9.38628 5.27402C9.41217 5.58167 9.65883 5.82432 9.96686 5.84517C11.1121 5.92271 12.1282 5.41185 12.9267 4.60367L12.9333 4.59692C13.4914 4.01469 13.7556 3.4307 13.8726 2.92565C13.9703 2.50423 13.9608 2.13774 13.9563 1.96328C13.9556 1.93521 13.955 1.91208 13.955 1.89453Z"
      fill="#252324"
    />
    <path
      d="M13.312 6.28074C12.5437 6.21902 11.7796 6.42503 11.1989 6.61361C11.0506 6.66181 10.9096 6.71049 10.7815 6.75503L10.7349 6.77125C10.6237 6.8099 10.525 6.84424 10.434 6.87426C10.33 6.90854 10.2485 6.93294 10.1846 6.94847C10.1358 6.96035 10.1115 6.96342 10.1053 6.9642C10.1044 6.96431 10.1039 6.96438 10.1037 6.96442C10.0687 6.96361 9.97136 6.94808 9.7759 6.88922C9.64317 6.84925 9.5017 6.80123 9.34076 6.74661C9.27011 6.72263 9.19566 6.69735 9.11659 6.67095C8.63671 6.51068 8.00529 6.31307 7.41151 6.31307L7.40072 6.31316C6.26124 6.33283 5.08522 6.7651 4.19085 7.6397C3.28812 8.52248 2.7085 9.8203 2.7085 11.4977C2.7085 12.4572 2.86151 13.4561 3.2431 14.4897C3.49154 15.1728 4.04091 16.3146 4.75834 17.2893C5.11771 17.7775 5.53717 18.2485 6.0033 18.5979C6.46345 18.9428 7.01948 19.206 7.63661 19.185C8.16222 19.1824 8.61903 19.0112 8.98523 18.8739C9.01837 18.8615 9.05079 18.8494 9.08242 18.8376C9.48617 18.6882 9.85105 18.568 10.3193 18.568C10.7646 18.568 11.0986 18.6847 11.4989 18.8361L11.5583 18.8586C11.9413 19.0042 12.4174 19.1851 13.0143 19.1851C13.6216 19.1851 14.1668 18.9395 14.6252 18.6069C15.0837 18.2743 15.4893 17.8311 15.8335 17.3697C16.5201 16.4496 17.0279 15.3692 17.2594 14.6792C17.3608 14.3767 17.2182 14.0464 16.9285 13.9128C15.0329 13.0388 15.0893 11.4385 15.0931 11.3315L15.0932 11.3263C15.0932 10.797 15.2173 10.3644 15.4474 9.99245C15.6807 9.61545 16.0437 9.26763 16.5688 8.94457C16.7172 8.8533 16.8207 8.70425 16.8544 8.53337C16.8882 8.36249 16.8491 8.18527 16.7466 8.04444C15.942 6.93898 14.7621 6.39453 13.312 6.28074Z"
      fill="#252324"
    />
  </svg>
)

export default AppleLogo
