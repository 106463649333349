import PropTypes from 'prop-types'

// Use this component in conjunction with BL::StringUtils.sanitize_html on the backend to ensure that the HTML is safe to render.
const HtmlContent = ({ html, as: Wrapper, ...restprops }) => (
  <Wrapper dangerouslySetInnerHTML={{ __html: html }} {...restprops} />
)

HtmlContent.defaultProps = {
  as: 'span',
  restprops: null,
}

HtmlContent.propTypes = {
  html: PropTypes.string.isRequired,
  restprops: PropTypes.object,
  as: PropTypes.string,
}

export default HtmlContent
