import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  createRegistryCategory,
  saveRegItem,
  deleteRegItem,
  fetchRegItem,
} from 'registry/actions/reg-items-actions'
import Modal from 'components/modal/modal'
import { ModalBody } from 'react-bootstrap'
import RegItemForm from 'shared/reg-item-form'
import {
  openGroupGiftRedemptionModal,
  openOwnerReserveModal,
  openRegItemDeleteModal,
  openEnableGroupGiftModal,
} from 'registry/modals/actions'
import { LinkButton } from 'components/buttons'
import {
  getCashFundPreferences,
  getIsMobile,
  getRegistryCategories,
  getRegItem,
  getGroupGiftRegItemsCount,
} from 'registry/reducers'
import { GROUP_GIFT_ACTIONS } from 'registry/constants'

class RegItemEditModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSubmit = ({ regItem: regItemFields, newCategory }) => {
    const { createRegistryCategory, saveRegItem, regItemId, onHide } =
      this.props

    if (newCategory) {
      // Create the new category before adding the regItem to it
      createRegistryCategory(newCategory)
        .then((category) => category.id)
        .then((categoryId) =>
          saveRegItem(regItemId, { ...regItemFields, categoryId })
        )
        .then(onHide)
        .catch((error) => {
          this.setState({ error })
        })
    } else {
      saveRegItem(regItemId, regItemFields)
        .then(onHide)
        .catch((error) => {
          this.setState({ error })
        })
    }
  }

  handleRemove = () => {
    const {
      fetchRegItem,
      deleteRegItem,
      openRegItemDeleteModal,
      regItemId,
      onHide,
    } = this.props
    // Check if reg item is reserved right before we delete it
    fetchRegItem(regItemId).then((regItem) => {
      if (regItem.isSomewhatReserved && !regItem.isGroupGift) {
        openRegItemDeleteModal(regItemId)
        // Show the redemption modal if a group gift has funds
      } else if (regItem.isGroupGift && regItem.fundedAmount > 0) {
        openGroupGiftRedemptionModal(regItem, GROUP_GIFT_ACTIONS.DELETE)
      } else {
        onHide()
        deleteRegItem(regItemId)
      }
    })
  }

  renderAdditionalControls = () => {
    const { regItem, openOwnerReserveModal } = this.props
    const controls = []

    const shouldDisplayOwnerReserveButton =
      !regItem.isReserved &&
      !regItem.isCashGift &&
      !regItem.isCrowdfund &&
      !regItem.isFavor &&
      !regItem.isGiftCard &&
      !regItem.isExternalRegistry

    if (shouldDisplayOwnerReserveButton) {
      controls.push(
        <LinkButton
          onClick={() => openOwnerReserveModal(regItem)}
          key="owner-reserve"
        >
          Already received this?
        </LinkButton>
      )
    }

    return controls.length > 0 ? (
      <p className="text-center h6 mvm flex row space-around">{controls}</p>
    ) : null
  }

  render() {
    const {
      cashFundPreferences,
      show,
      onHide,
      regItem,
      regItemId,
      categories,
      openEnableGroupGiftModal,
      openGroupGiftRedemptionModal,
      showFirstTimeGroupGiftUX,
    } = this.props

    const { error } = this.state

    return (
      <Modal show={show} onHide={onHide} title="Edit Item" size="large">
        <ModalBody>
          <RegItemForm
            key={regItemId}
            cashFundPreferences={cashFundPreferences}
            error={error}
            submitBtnText="Save"
            removeBtnText="Remove From Registry"
            onCancel={onHide}
            onSubmit={this.handleSubmit}
            onRemove={this.handleRemove}
            regItem={regItem}
            allowAddCategory
            categories={categories}
            openEnableGroupGiftModal={() =>
              openEnableGroupGiftModal(regItem, showFirstTimeGroupGiftUX)
            }
            openDisableGroupGiftModal={() =>
              openGroupGiftRedemptionModal(regItem, GROUP_GIFT_ACTIONS.DISABLE)
            }
          />

          {this.renderAdditionalControls()}
        </ModalBody>
      </Modal>
    )
  }
}

RegItemEditModal.propTypes = {
  cashFundPreferences: PropTypes.object,
  categories: PropTypes.array.isRequired,
  createRegistryCategory: PropTypes.func.isRequired,
  deleteRegItem: PropTypes.func.isRequired,
  fetchRegItem: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  openEnableGroupGiftModal: PropTypes.func.isRequired,
  openRegItemDeleteModal: PropTypes.func.isRequired,
  openOwnerReserveModal: PropTypes.func.isRequired,
  openGroupGiftRedemptionModal: PropTypes.func.isRequired,
  regItem: PropTypes.object.isRequired,
  regItemId: PropTypes.number.isRequired,
  saveRegItem: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  showFirstTimeGroupGiftUX: PropTypes.bool.isRequired,
}

RegItemEditModal.defaultProps = {
  cashFundPreferences: undefined,
}

const mapStateToProps = (state, ownProps) => ({
  cashFundPreferences: getCashFundPreferences(state),
  regItem: getRegItem(state, ownProps.regItemId),
  categories: getRegistryCategories(state),
  isMobile: getIsMobile(state),
  showFirstTimeGroupGiftUX: getGroupGiftRegItemsCount(state) === 0,
})

export default connect(mapStateToProps, {
  saveRegItem,
  createRegistryCategory,
  openGroupGiftRedemptionModal,
  openOwnerReserveModal,
  openRegItemDeleteModal,
  openEnableGroupGiftModal,
  fetchRegItem,
  deleteRegItem,
})(RegItemEditModal)
