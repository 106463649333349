import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { openRegItemEditModal } from 'registry/modals/actions'
import { InfoButton } from 'components/buttons'
import RemoveRegItemAction from './remove-reg-item-action'

export const OwnerActions = ({
  openRegItemEditModal,
  showEditAction,
  regItemId,
}) => {
  return (
    <div className="hidden-xs flex justify-end">
      {showEditAction ? (
        <InfoButton
          pill
          onClick={() => openRegItemEditModal(regItemId)}
          className="mvn phxl-lg text-bold"
        >
          Edit
        </InfoButton>
      ) : null}
      <RemoveRegItemAction className="mlm" regItemId={regItemId} />
    </div>
  )
}

OwnerActions.propTypes = {
  showEditAction: PropTypes.bool.isRequired,
  regItemId: PropTypes.number.isRequired,
  openRegItemEditModal: PropTypes.func.isRequired,
}

export default connect(undefined, { openRegItemEditModal })(OwnerActions)
