import { useState } from 'react'
import { Accordion } from 'components/accordion'
import classNames from 'classnames'
import { Question } from './FrequentlyAskedQuestions.types'
import css from './FrequentlyAskedQuestions.styles.scss'
import { Markup } from 'interweave'

interface FrequentlyAskedQuestionsProps {
  questions: Question[]
  headerVariant: 'primary' | 'secondary'
  accordion?: boolean
}

interface AccordionTriggerProps {
  onClick: () => void
  isOpen: boolean
}

const FrequentlyAskedQuestions = ({
  questions = [],
  headerVariant = 'primary',
  accordion = false,
}: FrequentlyAskedQuestionsProps) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<number | null>(
    null
  )

  const handleTriggerClick = (index: number, callback: () => void) => {
    // Ensure only one question is open at a time
    if (activeQuestionIndex === index) setActiveQuestionIndex(null)
    else setActiveQuestionIndex(index)
    callback()
  }

  return (
    <div className={css.FrequentlyAskedQuestionSection__container}>
      <div>
        <h2
          className={classNames(
            css.FrequentlyAskedQuestionSection__sectionHeader,
            {
              [css['FrequentlyAskedQuestionSection__sectionHeader--secondary']]:
                headerVariant === 'secondary',
            }
          )}
        >
          Frequently Asked Questions
        </h2>
        {headerVariant === 'secondary' && <hr />}
      </div>
      {questions.map((question, index) => (
        <div
          className={css.FrequentlyAskedQuestionSection__questionContainer}
          key={question.id}
        >
          {accordion ? (
            <Accordion
              defaultOpen={activeQuestionIndex === index}
              trigger={({ onClick, isOpen }: AccordionTriggerProps) => (
                <button
                  className={
                    css.FrequentlyAskedQuestionSection__collapsibleHeader
                  }
                  type="button"
                  onClick={() => handleTriggerClick(index, onClick)}
                >
                  <h3 className={css.FrequentlyAskedQuestionSection__question}>
                    {question.question}
                  </h3>
                  <img
                    alt={`Collapsible question ${
                      isOpen ? 'closed' : 'open'
                    } icon`}
                    className={classNames(
                      css.FrequentlyAskedQuestionSection__chevron,
                      {
                        [css.FrequentlyAskedQuestionSection__rotate]: isOpen,
                      }
                    )}
                    src="https://images.babylist.com/image/upload/v1686326413/Dynamic%20Landing%20Pages/FAQ_chevron_down.svg"
                  />
                </button>
              )}
            >
              <div
                className={classNames(
                  css.FrequentlyAskedQuestionSection__answer,
                  css.FrequentlyAskedQuestionSection__accordionAnswer
                )}
              >
                <Markup content={question.answer} />
              </div>
            </Accordion>
          ) : (
            <>
              <h3 className={css.FrequentlyAskedQuestionSection__question}>
                {question.question}
              </h3>
              <div className={css.FrequentlyAskedQuestionSection__answer}>
                <Markup content={question.answer} />
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  )
}

export default FrequentlyAskedQuestions
