import React from 'react'
import Toast from 'baby-design/components/Toast/Toast'
import { CartItemAddedToastProps } from 'shared/CartItemAddedToast/CartItemAddedToast.types'
import { DEFAULT_PRODUCT_IMAGE_PATH } from 'shared/constants'
import { reservationsPath } from 'lib/urls'

const CartItemAddedToast: React.FC<CartItemAddedToastProps> = ({
  isOpen,
  image,
  onDismiss,
  product,
}) => {
  const defaultImage = product.images[0]?.url || DEFAULT_PRODUCT_IMAGE_PATH
  const handleClick = () => {
    window.location.href = reservationsPath
  }

  const imageProps = image
    ? { image }
    : { imageSrc: defaultImage, imageAlt: product.name }

  return (
    <Toast
      cta={{
        text: 'View cart',
        onClick: handleClick,
      }}
      eyebrow="Added to cart"
      imageProps={imageProps}
      isOpen={isOpen}
      title={product.name}
      variant="rich"
      onDismiss={onDismiss}
    />
  )
}

export default CartItemAddedToast
