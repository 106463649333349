import React from 'react'
import { Radio, RadioGroup } from 'components/forms'
import { TwoColumnResponsiveLayout } from 'components/presentation'
import { PaymentOptionsProps } from 'registry/components/CrowdfundReserveFormFlow/components/PaymentOptions/PaymentOptions.types'
import PaymentPreferenceRequiredCallout from 'src/routes/(registry)/list/[slug]/components/CashFundPreferencesDrawer/components/PaymentPreferenceRequiredCallout/PaymentPreferenceRequiredCallout'
import RegItem from '../../../../../cart/components/reg-item'
import { Button } from '../../../../../baby-design'
import css from './PaymentOptions.styles.scss'

const DEFAULT_RADIO_GROUP_TITLE = 'Select how you want to contribute:'

const PaymentOptions: React.FC<PaymentOptionsProps> = ({
  isLoading = false,
  onAdvance,
  onPaymentOptionSelected,
  paymentOptions,
  regItem,
  showNextButton = false,
  showPreferenceRequired = false,
  title = DEFAULT_RADIO_GROUP_TITLE,
}) => {
  const handlePaymentOptionSelected = (name: string) =>
    onPaymentOptionSelected && onPaymentOptionSelected(name)

  const handleNextClicked = () => onAdvance && onAdvance()

  return (
    <TwoColumnResponsiveLayout>
      <RegItem className="mbl" regItem={regItem} />
      <>
        {/* @ts-ignore */}
        <RadioGroup className="mbl" title={title}>
          {paymentOptions.map((option) => (
            <Radio
              tappable
              key={option.name}
              label={option.label}
              name="paymentOption"
              onChange={() => handlePaymentOptionSelected(option.name)}
            />
          ))}
        </RadioGroup>
        {showPreferenceRequired && (
          <PaymentPreferenceRequiredCallout message="Select a payment option" />
        )}
        {showNextButton && (
          <Button
            className={css.PaymentOptions__Button}
            loading={isLoading}
            size="md"
            variant="primary"
            onClick={handleNextClicked}
          >
            Next
          </Button>
        )}
      </>
    </TwoColumnResponsiveLayout>
  )
}

export default PaymentOptions
