import PropTypes from 'prop-types'

export const ProgressBar = ({
  color,
  backgroundColor: bgColor,
  percent,
  height: rawHeight,
  radius: rawRadius,
  className,
  wrapperClassName,
}) => {
  const height = parseInt(rawHeight) || 3
  const radius = parseInt(rawRadius) || 0

  const wrapperStyle = {
    backgroundColor: bgColor,
    borderRadius: radius,
    height: height,
    overflow: 'hidden',
  }

  const style = {
    height: height,
    backgroundColor: color,
    borderRadius: radius,
    width: `${percent}%`,
    transition: 'width 0.25s',
  }

  return (
    <div className={wrapperClassName} style={wrapperStyle}>
      <div className={className} style={style} />
    </div>
  )
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  percent: PropTypes.number.isRequired,
  wrapperClassName: PropTypes.string,
}

ProgressBar.defaultProps = {
  color: '#d3d3d3',
  backgroundColor: '#fff',
  height: 3,
  radius: 0,
}
