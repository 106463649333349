import css from './SearchResultComponents.scss'
import { TypeAheadSuggestion } from '../../types'
import SearchFillSuggestion from 'shared/svg/search-fill-suggestion.svg'
import Markdown from 'markdown-to-jsx'
import { useTracking } from 'lib/analytics'
import {
  setSearchPreviewIds,
  trackUnifiedSearchEvent,
} from '../../SearchBar.utils'
import {
  addToSearchHistory,
  SEARCH_HISTORY_TYPE_PRODUCTS,
} from 'shared/utils/searchHistory/searchHistory'
import React from 'react'

interface SearchResultTypeAheadSuggestionProps {
  result: TypeAheadSuggestion
  setQuery: (query: string) => void
}

const SearchResultTypeAheadSuggestion = ({
  result,
  setQuery,
}: SearchResultTypeAheadSuggestionProps) => {
  const tracker = useTracking()
  const trackSearchEvents = () => {
    const event = result.tracking_event
    if (!event) return

    trackUnifiedSearchEvent(event, tracker.trackEvent)
  }

  const handleFill = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setQuery(result.query)
  }

  const handleResultClick = () => {
    const searchPreviewIds = result.search_preview_ids

    setQuery(result.query)
    searchPreviewIds && setSearchPreviewIds(searchPreviewIds)
    trackSearchEvents()
    addToSearchHistory(
      result.query || result.title,
      SEARCH_HISTORY_TYPE_PRODUCTS
    )
    window.location.href = result.url
  }

  return (
    <div
      className={css.SearchResults__typeAheadResult}
      onClick={handleResultClick}
    >
      <span className={css.SearchResults__resultText}>
        <Markdown>{result.title}</Markdown>
      </span>
      <button
        onClick={handleFill}
        className={css.SearchResults__searchFillSuggestionButton}
        aria-label={`Append ${result.query} to search`}
      >
        <SearchFillSuggestion />
      </button>
    </div>
  )
}

export default SearchResultTypeAheadSuggestion
