import React from 'react'

interface LinkedInProps {
  className?: string
}

const LinkedIn: React.FC<LinkedInProps> = ({ className }: LinkedInProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>LinkedIn Icon</title>
    <path
      clipRule="evenodd"
      d="M13.7836 1.25H9.71644C8.34472 1.25 7.26929 1.24999 6.40494 1.32061C5.52479 1.39252 4.79769 1.54138 4.13955 1.87671C3.05762 2.42798 2.17798 3.30762 1.62671 4.38955C1.29138 5.04768 1.14252 5.77479 1.07061 6.65494C0.999992 7.51929 0.999996 8.59472 1 9.96644V14.0336C0.999996 15.4053 0.999992 16.4807 1.07061 17.3451C1.14252 18.2252 1.29138 18.9523 1.62671 19.6104C2.17798 20.6924 3.05762 21.572 4.13956 22.1233C4.79768 22.4586 5.52479 22.6075 6.40494 22.6794C7.26925 22.75 8.34468 22.75 9.71632 22.75H9.71637H13.7836H13.7837C15.1553 22.75 16.2308 22.75 17.0951 22.6794C17.9752 22.6075 18.7023 22.4586 19.3604 22.1233C20.4424 21.572 21.322 20.6924 21.8733 19.6104C22.2086 18.9523 22.3575 18.2252 22.4294 17.3451C22.5 16.4808 22.5 15.4053 22.5 14.0337V14.0336V9.96637V9.96632C22.5 8.59469 22.5 7.51925 22.4294 6.65494C22.3575 5.77479 22.2086 5.04768 21.8733 4.38956C21.322 3.30762 20.4424 2.42798 19.3604 1.87671C18.7023 1.54138 17.9752 1.39252 17.0951 1.32061C16.2307 1.24999 15.1553 1.25 13.7836 1.25ZM7.36318 6.96696C7.12106 7.19102 7 7.47541 7 7.82013C7 8.16485 7.1176 8.45096 7.3528 8.67848C7.588 8.90599 7.89583 9.01975 8.2763 9.01975H8.28668C8.68098 9.01975 8.9992 8.90599 9.24131 8.67848C9.47652 8.45785 9.59411 8.18209 9.59411 7.85116C9.59411 7.48575 9.47305 7.1893 9.23094 6.96179C8.99573 6.74116 8.68791 6.63086 8.30743 6.63086C7.92005 6.63086 7.60529 6.74289 7.36318 6.96696ZM7.13489 9.96082V16.869H9.43847V9.96082H7.13489ZM13.0183 16.869H10.7148C10.7286 14.7869 10.7355 13.1701 10.7355 12.0188C10.7355 10.8674 10.7286 10.1814 10.7148 9.96082H13.0183V10.9433L13.008 10.964H13.0183V10.9433C13.5164 10.178 14.2116 9.79536 15.104 9.79536C15.8995 9.79536 16.5394 10.0591 17.0236 10.5865C17.5079 11.1139 17.75 11.8878 17.75 12.9082V16.869H15.4464V13.177C15.4464 12.136 15.059 11.6155 14.2843 11.6155C13.9868 11.6155 13.7395 11.6965 13.5423 11.8585C13.3452 12.0205 13.1982 12.2187 13.1014 12.4531C13.046 12.5841 13.0183 12.7703 13.0183 13.0116V16.869Z"
      fill="#4A2143"
      fillRule="evenodd"
    />
  </svg>
)

export default LinkedIn
