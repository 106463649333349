import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody } from 'components/modal'
import InaccuracyReportForm from 'components/offer/inaccuracy-report-form'
import { offerProps } from 'components/offer/offer'

class InaccuracyReportModal extends PureComponent {
  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    offer: PropTypes.shape(offerProps),
    regItemId: PropTypes.number,
    productId: PropTypes.number,
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        title={`What's wrong with this link?`}
        onHide={this.props.onHide}
      >
        <ModalBody>
          <InaccuracyReportForm
            offer={this.props.offer}
            regItemId={this.props.regItemId}
            onSubmit={this.props.onHide}
          />
        </ModalBody>
      </Modal>
    )
  }
}

export default InaccuracyReportModal
