import { ReactNode } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { addCartItem, deleteCartItem, updateCartItem } from 'src/api/queries'
import { RegItem } from 'src/types/regItem'
import { showCartItemAddedToast } from 'shared/CartItemAddedToast/showCartItemAddedToast'
import { FLASH_MESSAGE, UPDATE_CART_SUMMARY } from 'shared/constants'
import { parseErrorMessage } from 'lib/api-utils'
import { track } from 'lib/analytics'
import { optimisticallyUpdateRegItem } from './registryHelpers'
import { showCartQuantityUpdateSuccessToast } from '../components/CartQuantityUpdateSuccessToast/showCartQuantityUpdateSuccessToast'

interface CartResponse {
  cart: {
    items: Array<{ uuid: string; quantity: number; regItem: RegItem }>
    checkoutTotals?: {
      itemsSubtotal: number
    }
  }
}

interface UseCartItemManagerProps {
  regItem: RegItem
  imageOverride?: ReactNode
  unredeemedFundsCallback: () => void
  trackingEventLocation: string
}
export const useCartItemManager = ({
  regItem,
  imageOverride,
  unredeemedFundsCallback,
  trackingEventLocation,
}: UseCartItemManagerProps) => {
  const queryClient = useQueryClient()

  const addToCart = () =>
    addCartItem({
      productId: regItem.productId,
      quantity: 1,
      regItemId: regItem.id,
    })
      .then((response: CartResponse) => {
        const cartItem = response.cart.items.find(
          (item) => item.regItem.id === regItem.id
        )
        const cartItemId = parseInt(cartItem?.uuid || '')

        const images = [{ url: regItem.imgUrl, thumbnailUrl: '' }]
        showCartItemAddedToast({
          product: {
            name: regItem.title,
            images,
          },
          image: imageOverride,
          timeout: 2000,
        })
        if (
          regItem.fundsUnredeemedAmount &&
          regItem.fundsUnredeemedAmount > 0
        ) {
          unredeemedFundsCallback()
        }

        const updatedRegItem = {
          ...regItem,
          cartInfo: {
            cartItemId,
            quantity: cartItem?.quantity,
          },
        }
        optimisticallyUpdateRegItem({
          queryClient,
          updatedRegItem,
        })

        PubSub.publish(UPDATE_CART_SUMMARY, { animateBadge: true })

        track.addedToCart({
          cartItemQuantity: cartItem?.quantity || 1,
          eventLocation: trackingEventLocation,
          storeAdded: track.StoreAdded.BABYLIST,
          cartTypes: [track.CartTypes.STORE],
          cartStores: [track.CartStores.BABYLIST_STORE],
          cartTotalValue: response.cart.checkoutTotals?.itemsSubtotal,
          productPrice: regItem.price,
          // no gift cards currently supported
          productType: track.ProductType.PRODUCT,
          // we don't subscribe at this point so sneding false
          subscribeToMarketingEmail: false,
          registryId: regItem.registryId,
          productIds: [regItem.productId],
          regItemIds: [regItem.id],
        })
      })
      .catch((resp: string) => {
        PubSub.publish(FLASH_MESSAGE, {
          message: parseErrorMessage(resp),
          variant: 'danger',
        })
      })

  const updateCartItemQuantity = (quantity: number): Promise<any> => {
    const cartItemId = regItem.cartInfo?.cartItemId
    if (!cartItemId) return Promise.reject('No matching cart item found')

    return updateCartItem(cartItemId, {
      quantity,
    })
      .then((response: CartResponse) => {
        const cartItem = response.cart.items.find(
          (item) => item.regItem.id === regItem.id
        )

        showCartQuantityUpdateSuccessToast(quantity)

        const updatedRegItem = {
          ...regItem,
          cartInfo: {
            cartItemId,
            quantity: cartItem?.quantity || 0,
          },
        }
        optimisticallyUpdateRegItem({
          queryClient,
          updatedRegItem,
        })

        PubSub.publish(UPDATE_CART_SUMMARY, { animateBadge: true })

        return updatedRegItem
      })
      .catch((resp: string) => {
        PubSub.publish(FLASH_MESSAGE, {
          message: parseErrorMessage(resp),
          variant: 'danger',
        })
      })
  }

  const removeCartItem = (): Promise<any> => {
    const cartItemId = regItem.cartInfo?.cartItemId
    if (!cartItemId) return Promise.reject('No matching cart item found')

    return deleteCartItem(cartItemId)
      .then(() => {
        showCartQuantityUpdateSuccessToast(0)

        const updatedRegItem = {
          ...regItem,
          cartInfo: null,
        }
        optimisticallyUpdateRegItem({
          queryClient,
          updatedRegItem,
        })

        PubSub.publish(UPDATE_CART_SUMMARY, { animateBadge: true })

        return updatedRegItem
      })
      .catch((resp: string) => {
        PubSub.publish(FLASH_MESSAGE, {
          message: parseErrorMessage(resp),
          variant: 'danger',
        })
      })
  }

  return {
    addToCart,
    updateCartItemQuantity,
    removeCartItem,
  }
}
