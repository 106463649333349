import fetch from './fetch'
import * as Urls from './urls'

export const refreshCache = (resources) =>
  fetch(Urls.blAdminApiCacheRefreshesPath, {
    method: 'POST',
    body: JSON.stringify({
      resources,
    }),
  })
