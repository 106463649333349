import React, { useContext, useEffect } from 'react'
import { CrowdfundReserveModalTitleProps } from 'registry/components/CrowdfundReserveFormFlow/components/CrowdfundReserveModalTitle/CrowdfundReserveModalTitle.types'
import CrowdfundReservationModalContext from 'registry/contexts/crowdfundReservationModal'

const CrowdfundReserveModalTitle: React.FC<CrowdfundReserveModalTitleProps> = ({
  children,
}) => {
  const { setModalTitle } = useContext(CrowdfundReservationModalContext)

  useEffect(() => {
    setModalTitle(children)
  }, [children])

  return null
}

export default CrowdfundReserveModalTitle
