import { isValidElement } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Card, { COLOR_VARIANTS } from 'components/card'
import { GuideIcon } from 'components/icons'
import css from './featured-guide-card.scss'
import bgColors from '../card.scss'

export const FeaturedGuideCard = ({
  bgColor,
  guideLabel,
  imageUrl,
  playButtonOverlay,
  teaser,
  title,
}) => {
  const bgColorClass = bgColors[bgColor]

  const thumbnailStyle = {
    backgroundImage: `url(${imageUrl}`,
  }

  if (playButtonOverlay) {
    thumbnailStyle.backgroundImage = `url('//images.babylist.com/image/upload/v1568235803/play_button_3x_sokilf.png'), url(${imageUrl})`
  }

  return (
    <Card
      className={classNames(
        css.guideLayout,
        'mbxl',
        playButtonOverlay && css.video
      )}
    >
      <div className={classNames(css.thumbnail)} style={thumbnailStyle}>
        <div className={classNames(css.innerCurve, bgColorClass)}></div>
      </div>
      <div className={classNames(css.info, bgColorClass)}>
        {guideLabel && <GuideLabel label={guideLabel} />}
        <h2 className="h3 mtn">{title}</h2>
        {teaser && <p>{teaser}</p>}
      </div>
    </Card>
  )
}

FeaturedGuideCard.defaultProps = {
  bgColor: 'sky-gradient',
  playButtonOverlay: false,
}

FeaturedGuideCard.propTypes = {
  bgColor: PropTypes.oneOf(Object.values(COLOR_VARIANTS)),
  guideLabel: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  teaser: PropTypes.string,
  title: PropTypes.string.isRequired,
}

const GuideLabel = ({ label }) => {
  if (isValidElement(label)) {
    return label
  }

  return (
    <div className="tiny mbs text-bold flex align-center h6">
      <GuideIcon className="mrs" width={12} />
      <span>{label}</span>
    </div>
  )
}

GuideLabel.propTypes = {
  label: PropTypes.string.isRequired,
}
