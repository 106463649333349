import { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { compact } from 'lodash'

import classnames from 'classnames'

export class MultiStepWizard extends Component {
  static propTypes = {
    activeStep: PropTypes.number.isRequired,
    animateInitialStep: PropTypes.bool,
  }

  render() {
    const animateStep =
      (this.props.activeStep == 1 && this.props.animateInitialStep) ||
      this.props.activeStep > 1
    const steps = compact(this.props.children)
    const stepComponent = steps[this.props.activeStep - 1]

    return (
      <div
        className={animateStep ? 'animated animate-250ms fadeIn' : ''}
        key={this.props.activeStep}
      >
        {stepComponent}
      </div>
    )
  }
}

export class TwoColumnResponsiveLayout extends Component {
  static propTypes = {
    leftColClassName: PropTypes.string,
    rightColClassName: PropTypes.string,
    withoutGridBorder: PropTypes.bool,
    className: PropTypes.string,
  }

  render() {
    const { withoutGridBorder } = this.props
    const containerStyle =
      'grid-border-between grid-border-between-sm grid-border-between-full-gutter'

    return (
      <>
        <Row
          className={classnames(containerStyle, this.props.className, {
            'grid--without-border ': withoutGridBorder,
          })}
        >
          <Col className={this.props.leftColClassName} sm={6}>
            {this.props.children[0]}
          </Col>
          <Col className={this.props.rightColClassName} sm={6}>
            {this.props.children[1]}
          </Col>
        </Row>
      </>
    )
  }
}

export const FixedButtonBar = (props) => (
  <div className="fixed-button-bar">
    <div
      className={classnames('container button-container', props.containerClass)}
    >
      <Row>
        <Col xs={6}>{props.children[0]}</Col>
        <Col xs={6}>{props.children[1]}</Col>
      </Row>
    </div>
  </div>
)
