import PropTypes from 'prop-types'
import classNames from 'classnames'
import Link from 'components/link'
import { SHOW_UNRESERVE_MODAL } from 'shared/constants'

const UnreserveLink = ({ className, reservation }) => {
  const handleOpenUnreserveModal = (e) => {
    e.preventDefault()
    PubSub.publish(SHOW_UNRESERVE_MODAL, {
      reservation,
    })
  }

  return (
    <Link
      className={classNames(className, 'h7')}
      size="small"
      onClick={handleOpenUnreserveModal}
    >
      Cancel Reservation
    </Link>
  )
}

UnreserveLink.propTypes = {
  className: PropTypes.string,
  reservation: PropTypes.object.isRequired,
}

UnreserveLink.defaultProps = {
  className: undefined,
}

export default UnreserveLink
