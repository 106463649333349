import { currentUserLoadedPromise } from 'shared/utils/currentUserLoadedPromise/currentUserLoadedPromise'
import { track } from 'lib/analytics'

let resolveInboxPromise: (id: number | undefined) => unknown

const inboxIdPromise = new Promise((resolve) => {
  resolveInboxPromise = resolve
})

const resolveInboxId = (id: number | undefined) => resolveInboxPromise(id)

const trackMessageCenterEvent = (trackEvent: (basePayload: any) => unknown) => {
  Promise.all([currentUserLoadedPromise, inboxIdPromise]).then(
    ([currentUser, inboxId]) => {
      trackEvent({
        eventLocation: track.EventLocation.MESSAGE_CENTER_INBOX,
        inboxId: inboxId?.toString(),
        isRegistryOwner:
          currentUser?.currentRegistry?.currentUserRole === 'owner',
        registryOwnerId: currentUser?.currentRegistry?.ownerId,
        registryId: currentUser?.currentRegistry?.id,
      })
    }
  )
}

export { resolveInboxId, inboxIdPromise, trackMessageCenterEvent }
