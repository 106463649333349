import { Component } from 'react'
import PropTypes from 'prop-types'
import Input from '../../components/forms/input'
import Checkbox from '../../components/forms/checkbox'

export default class NewCategoryForm extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  }

  state = {}

  handleChange = (state) => {
    const newState = { ...this.state, ...state }
    this.setState(newState)
    this.props.onChange(newState)
  }

  render() {
    return (
      <div>
        <Input
          label="New Category Name"
          name="new-category-name"
          value={this.state.title}
          onChange={(e) => this.handleChange({ title: e.target.value })}
        />
        <Checkbox
          label="Make it private?"
          value={this.state.isPrivate}
          onChange={(e) => this.handleChange({ isPrivate: e.target.checked })}
        />
      </div>
    )
  }
}
