import classNames from 'classnames'
import { Col, Row } from 'react-bootstrap'
import css from './reg-item-card-ghost.scss'

const RegItemCardGhost = () => (
  <Row className="mvl">
    <Col xs={3}>
      <div className={css.imageWrapper}>
        <div className={css.shimmer} />
        <div className={css.image} />
      </div>
    </Col>

    <Col xs={9}>
      <Row>
        <Col sm={7}>
          <div className={classNames(css.title, css.bar, 'mbl mtn')}>
            <div className={css.shimmer} />
          </div>
          <div className="mbl">
            <div
              className={classNames(css.description, css.bar, 'mbs')}
              style={{ width: '70%' }}
            >
              <div className={css.shimmer} />
            </div>
            <div
              className={classNames(css.description, css.bar, 'mbs')}
              style={{ width: '40%' }}
            >
              <div className={css.shimmer} />
            </div>
          </div>
        </Col>

        <Col sm={5} className="hidden-xs hidden-sm">
          <div className={classNames(css.action, css.bar, 'mbs')}>
            <div className={css.shimmer} />
          </div>
        </Col>
      </Row>
    </Col>
  </Row>
)

export default ({ length = 6 }) => {
  let regItemCardGhosts = []

  for (let i = 0; i < length; i++) {
    regItemCardGhosts.push(
      <div key={`reg-item-ghost-${i}`} className="mtm mbxl">
        <hr className="mbl mts" />
        <RegItemCardGhost />
      </div>
    )
  }

  return <div className="mtl">{regItemCardGhosts}</div>
}
