import css from './SurpriseWarningDialog.styles.scss'

export interface SurpriseWarningDialogProps {
  onBypass: () => void
}

const SurpriseWarningDialog = ({ onBypass }: SurpriseWarningDialogProps) => (
  <div className={css.SurpriseWarningDialog}>
    <div className={css.SurpriseWarningDialog__title}>Spoiler alert! 🤫</div>
    <div className={css.SurpriseWarningDialog__description}>
      This person wants to surprise you.
      <br />
      Are you sure you want to spoil the surprise?
    </div>
    <a className={css.SurpriseWarningDialog__bypassLink} onClick={onBypass}>
      Yes, show me the gift
    </a>
  </div>
)

export default SurpriseWarningDialog
