import classNames from 'classnames'

const FaIcon = (props) => {
  const { icon, className, fixedWidth, large, ...rest } = props
  const styles = classNames(
    className,
    'fa',
    `fa-${icon}`,
    fixedWidth && 'fa-fw',
    large && 'fa-lg'
  )

  return <i {...rest} className={styles} aria-hidden={true}></i>
}

export default FaIcon
