import { map, sortBy } from 'lodash'
import classNames from 'classnames'
import { helloBabyPostPath } from 'lib/urls'
import Link from 'components/link'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'

// pregnancy and week-by-week topics should always display before trimester topic
// topics that don't appear below will display AFTER these topics
const SORTED_TOPIC_SLUGS = ['week-by-week', 'pregnancy-week-by-week']
const EXCLUDED_SLUGS = [
  'gear',
  'sample-registries',
  'pregnancy',
  'parenting',
  'friends-and-family',
]

interface Topic {
  name: string
  slug: string
}

interface TopicListProps {
  topics: Topic[]
  className: string
}

const sortTopics = (topics: Topic[], isRebrand: boolean | string | null) => {
  const sortedTopics = sortBy(topics, ({ slug }) =>
    [...SORTED_TOPIC_SLUGS].reverse().indexOf(slug)
  ).reverse()

  const filteredTopics = sortedTopics.filter(
    ({ slug }) => !EXCLUDED_SLUGS.includes(slug)
  )
  return isRebrand
    ? [{ name: 'Guides', slug: '' }, ...filteredTopics]
    : sortedTopics
}

const TopicList = ({ topics, className }: TopicListProps) => {
  const { flagValue: isRebrand } = useFeatureFlag(
    'guides-above-the-fold',
    false
  )
  const sortedTopics = sortTopics(topics, isRebrand)

  return (
    <ul className={classNames('list-unstyled', className)}>
      {map(sortedTopics, (topic, idx) =>
        isRebrand ? (
          <li key={topic.slug} style={{ display: 'inline-block' }}>
            <Link
              className={classNames('h7 above-the-fold-text', {
                'above-the-fold-breadcrumbs': idx === sortedTopics.length - 1,
              })}
              to={helloBabyPostPath(topic.slug)}
            >
              {topic.name}
            </Link>
            {idx < sortedTopics.length - 1 && (
              <span className="fa fa-angle-right above-the-fold-chevron-padding" />
            )}
          </li>
        ) : (
          <li key={topic.slug} style={{ display: 'inline-block' }}>
            <Link
              to={helloBabyPostPath(topic.slug)}
              className="text-uppercase h7 text-bold"
            >
              {topic.name}
            </Link>
            {idx < topics.length - 1 && <span className="h7 phs">/</span>}
          </li>
        )
      )}
    </ul>
  )
}

export default TopicList
