/* eslint-disable react/static-property-placement */
/* eslint-disable react/destructuring-assignment */
import { Component } from 'react'
import PropTypes from 'prop-types'
import { debounce, isFunction } from 'lodash'
import Mailcheck from 'mailcheck'
import { Input } from 'components/forms'
import Link from 'components/link'
import styles from './mailchecked-input.module.scss'

class MailcheckedInput extends Component {
  static propTypes = {
    value: PropTypes.string,

    // WARNING: This onChange MUST be able to accept either an Event object or a raw value, as redux-form onChange does
    onChange: PropTypes.func.isRequired,

    mailcheckOnBlur: PropTypes.bool,
    mailcheckOnChange: PropTypes.bool,
    mailcheckDelay: PropTypes.number,
  }

  static defaultProps = {
    mailcheckOnBlur: true,
    mailcheckOnChange: false,
    mailcheckDelay: 0,
  }

  state = {
    emailSuggestion: null,
  }

  render() {
    return (
      <Input
        {...this.props}
        type="text"
        noValidate
        help={this.helpText()}
        onChange={this.handleChange.bind(this)}
        onBlur={this.handleBlur.bind(this)}
      />
    )
  }

  handleChange(e, ...args) {
    this.props.onChange(e, ...args)
    if (this.props.mailcheckOnChange) {
      this.mailcheck(e.target.value)
    }
  }

  handleBlur(e, ...args) {
    if (isFunction(this.props.onBlur)) {
      this.props.onBlur(e, ...args)
    }
    if (this.props.mailcheckOnBlur) {
      this.mailcheck(e.target.value)
    }
  }

  changeEmail(email) {
    this.props.onChange(email)
    this.setState({ emailSuggestion: null })
  }

  setSuggestion(suggestion) {
    this.setState({ emailSuggestion: suggestion })
  }

  clearSuggestion() {
    this.setSuggestion(null)
  }

  debouncedSetSuggestion = debounce(
    (suggestion) => this.setSuggestion(suggestion),
    this.props.mailcheckDelay
  )

  mailcheck(value) {
    const handleNoSuggestion = () => {
      this.debouncedSetSuggestion.cancel()
      this.clearSuggestion()
    }

    // If we have at least one character after a . after the @, we should run mailcheck
    const regex = /@[a-zA-Z0-9_-]{0,}\.[a-zA-Z0-9_-]{1,}/
    const shouldMailcheck = regex.test(value)
    if (!shouldMailcheck) {
      handleNoSuggestion()
      return
    }

    Mailcheck.run({
      email: value,
      suggested: (suggestion) => {
        const { domain, full } = suggestion

        const canadaRegex = /\.ca$/
        const isCanadaDomain = canadaRegex.test(value)
        if (isCanadaDomain) {
          // If they have explicitly typed .ca, we should show the normal suggestion
          this.debouncedSetSuggestion(suggestion)
        } else {
          // We want .com suggestions to come before any .ca suggestions
          // So we're replacing any .ca suggestions with .com
          const noCanadaSuggestion = {
            ...suggestion,
            domain: domain.replace(canadaRegex, '.com'),
            full: full.replace(canadaRegex, '.com'),
          }
          this.debouncedSetSuggestion(noCanadaSuggestion)
        }
      },
      empty: handleNoSuggestion,
    })
  }

  helpText() {
    return this.state.emailSuggestion ? (
      <div className={styles.suggestionContainer}>
        <span>
          Did you mean <strong>{this.state.emailSuggestion.full}</strong>?
        </span>
        <button
          className={styles.suggestionButton}
          type="button"
          onClick={() => this.changeEmail(this.state.emailSuggestion.full)}
        >
          Yes
        </button>
      </div>
    ) : (
      this.props.help
    )
  }
}

export default MailcheckedInput
