import PropTypes from 'prop-types'
import BackLink from 'shared/back-link'
import classNames from 'classnames'
import css from './banner.scss'

const BackBanner = ({ backUrl, fixed, backText, helpText }) => {
  return (
    <div className={classNames(css.infoBanner, { [css.fixed]: fixed })}>
      <div className="container">
        <div className={css.flexRow}>
          {backUrl && (
            <div className={classNames(css.farLeft)}>
              <BackLink url={backUrl}>{backText ? backText : 'Back'}</BackLink>
            </div>
          )}
          {helpText && (
            <div
              className={classNames(
                backUrl ? css.farRight : css.middle,
                backUrl ? 'hidden-xs' : null
              )}
            >
              <span>{helpText}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

BackBanner.propTypes = {
  backUrl: PropTypes.string,
  backText: PropTypes.string,
  helpText: PropTypes.string,
}

export default BackBanner
