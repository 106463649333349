import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as api from 'store/api'
import { InfoButton } from 'components/buttons'
import Image from 'components/image'
import { errorMessage, successMessage } from 'lib/flash-message'
import { singleLineString } from 'lib/single-line-string'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { SUPPORT_EMAIL } from 'shared/constants'
import { track, tracking, useTracking } from 'lib/analytics'
import useCategorizeAnyRegAdd from 'src/hooks/useCategorizeAnyRegAdd'
import { showRegItemAddedToast } from 'shared/RegItemAddedToast/showRegItemAddedToast'
import { getCollaboratorRole } from '../../../utils'

export const QUICK_ADD_STATUS_OPTIONS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
}

// https://github.com/nytimes/react-tracking/issues/138
// overload the track event handler context to set specific quick add event type
const TrackedInfoButton = tracking({ eventType: track.EventType.QUICK_ADD })(
  InfoButton
)

export const QuickAddButton = ({
  status,
  successImgAlt,
  onClick,
  title,
  ...props
}) => {
  if (status === QUICK_ADD_STATUS_OPTIONS.SUCCESS) {
    return (
      <Image
        alt={successImgAlt}
        lazyLoad={false}
        src="//images.babylist.com/image/upload/v1568670049/icn_check_va95ym.svg"
      />
    )
  }

  return (
    <TrackedInfoButton
      {...props}
      circle
      aria-label={title}
      size="small"
      submitting={status === QUICK_ADD_STATUS_OPTIONS.LOADING}
      title={title}
      onClick={onClick}
    >
      <Image
        lazyLoad={false}
        role="presentation"
        src="//images.babylist.com/image/upload/v1568653819/icn_add_fqc1nn.svg"
      />
    </TrackedInfoButton>
  )
}

QuickAddButton.propTypes = {
  status: PropTypes.oneOf(Object.values(QUICK_ADD_STATUS_OPTIONS)),
  successImgAlt: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
}

QuickAddButton.defaultProps = {
  onClick: () => null,
  successImgAlt: 'Success',
  status: null,
  title: 'Quick Add',
}

export const addToRegistry = async (
  productId,
  registryId,
  regItemFields = {},
  options
) =>
  api.addToBabylist(
    {
      ...regItemFields,
      product_id: productId,
      registryId,
    },
    options
  )

export const QuickAddRegItem = ({
  eventLocation,
  product,
  source,
  onSuccess,
  onFailure,
  showCategoryToast = true,
  ...props
}) => {
  const [currentUser] = useCurrentUser()
  const [quickAddStatus, setQuickAddStatus] = useState('idle')
  const isCategorizeAnyAddEnabled = useCategorizeAnyRegAdd()

  const tracker = useTracking()

  const addToRegistryAsync = async (isCanceled) => {
    const registryId = currentUser?.registryId

    if (!registryId) {
      setQuickAddStatus('idle')
      return
    }

    try {
      const { regItem } = await addToRegistry(
        product.id,
        currentUser?.registryId,
        {
          source,
        }
      )

      const trackingArgs = {
        regItem,
        product,
        eventType: track.EventType.QUICK_ADD,
        isFirstRegItem: regItem.isFirstRegItem,
        userId: currentUser?.id,
        email: currentUser?.email,
        collaboratorRole: getCollaboratorRole(currentUser),
      }

      if (eventLocation) {
        trackingArgs.eventLocation = eventLocation
      }

      track.addedToRegistry(trackingArgs)

      if (isCanceled) return

      setQuickAddStatus('success')

      const defaultSuccessMessage = `${regItem.title} added to your registry!`

      const flashSuccessMessage = (msg) => {
        if (isCategorizeAnyAddEnabled && showCategoryToast) {
          showRegItemAddedToast({
            currentUser,
            eventLocation,
            regItem: {
              ...regItem,
              imgUrl: product.image?.thumbnailUrl,
            },
            tracker,
          })
        } else {
          successMessage(msg)
        }
      }

      onSuccess({
        regItem,
        flashSuccessMessage,
        defaultMessage: defaultSuccessMessage,
      })
    } catch (error) {
      setQuickAddStatus('idle')

      if (isCanceled) return

      const defaultErrorMessage = singleLineString`
            Oops! There was an issue adding ${product.name} to your registry.
            Please contact <a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>
            if the problem continues.
          `

      onFailure({
        error,
        product,
        flashErrorMessage: (msg) => {
          errorMessage(msg)
        },
        defaultMessage: defaultErrorMessage,
      })
    }
  }
  useEffect(() => {
    if (quickAddStatus === 'loading') {
      let isCanceled = false

      addToRegistryAsync(isCanceled)

      return () => {
        isCanceled = true
      }
    }
    return null
  }, [quickAddStatus, product.id]) // eslint-disable-line

  return (
    <QuickAddButton
      status={quickAddStatus}
      successImgAlt={`${product.name} added to your registry.`}
      title={`Add ${product.name} to your registry`}
      onClick={() => setQuickAddStatus('loading')}
      {...props}
    />
  )
}

QuickAddRegItem.propTypes = {
  eventLocation: PropTypes.string,
  product: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  source: PropTypes.string,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  ...QuickAddButton.propTypes,
}

QuickAddRegItem.defaultProps = {
  eventLocation: null,
  onSuccess: ({ flashSuccessMessage, defaultMessage }) =>
    flashSuccessMessage(defaultMessage),
  onFailure: ({ flashErrorMessage, defaultMessage }) =>
    flashErrorMessage(defaultMessage),
  source: null,
}
