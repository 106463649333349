import { useContext, useEffect, useRef, useState } from 'react'
import fetch from 'lib/fetch'
import { apiV3InboxPath } from 'lib/urls'
import {
  Inbox,
  Message,
} from 'components/global-nav/components/MessageCenter/types'
// @ts-ignore
import InfiniteScroll from 'react-infinite-scroller'
import MessageCard from 'components/global-nav/components/MessageCenter/MessageCard'
import classNames from 'classnames'
import { track, useTracking } from 'lib/analytics'
import { resolveInboxId, trackMessageCenterEvent } from './MessageCenter.util'
import { MessageCenterContext } from './contexts'
import css from './MessageCenter.scss'
import EmptyInbox from './EmptyInbox'

interface MessageCenterProps {
  onInboxChanged?: (inbox: Inbox) => unknown
}

const MESSAGES_PER_PAGE = 20

const MessageCenter = ({ onInboxChanged }: MessageCenterProps) => {
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState<Array<Message>>([])
  const [cursor, setCursor] = useState<number | undefined>()
  const [hasMoreMessages, setHasMoreMessages] = useState(true)
  const [hasBeenOpened, setHasBeenOpened] = useState(false)

  const { isOpen } = useContext(MessageCenterContext)
  const messageCenterRef = useRef<HTMLLIElement>(null)

  const tracker = useTracking()

  const purgeArchivedMessages = () => {
    setMessages(messages.filter((message) => !message.archivedAt))
  }

  const fetchMessages = () => {
    if (!loading && hasMoreMessages) {
      setLoading(true)

      let url = `${apiV3InboxPath}?per_page=${MESSAGES_PER_PAGE}`
      if (cursor) {
        url = `${url}&cursor=${cursor}`
      }
      fetch(url)
        .then((response) => {
          const allMessages = [...messages, ...response.data.messages]
          resolveInboxId(response.data.id)
          setMessages(allMessages)
          setCursor(response.data.cursor)
          setHasMoreMessages(!!response.data.cursor)

          if (onInboxChanged) {
            onInboxChanged({
              messages: allMessages,
              lastViewedAt: response.data.lastViewedAt,
            })
          }
        })
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    fetchMessages()
  }, [])

  useEffect(() => {
    if (isOpen) {
      if (messageCenterRef.current) {
        messageCenterRef.current.scrollTop = 0
      }

      trackMessageCenterEvent((basePayload) => {
        tracker.trackEvent({
          ...basePayload,
          event: track.inboxOpened,
        })
      })

      // since we fetch pages of messages in descending order
      // the only way to get messages more recent than the ones
      // initially loaded is by refreshing the page.
      // therefore, we make sure that we only mark as read once per
      // page reload so that messages that come in between dropdown
      // opens don't get mistakenly counted as "seen"
      if (!hasBeenOpened) {
        fetch(apiV3InboxPath, {
          method: 'PATCH',
        }).then(() => {
          setHasBeenOpened(true)
          if (onInboxChanged) {
            onInboxChanged({
              messages,
              lastViewedAt: new Date().toString(),
            })
          }
        })
      }
    } else if (hasBeenOpened) {
      purgeArchivedMessages()
    }
  }, [isOpen, onInboxChanged])

  const findExistingMessageInData = (message: Message) =>
    messages.find((m) => m.id === message.id)

  const handleMessageRead = (message: Message) => {
    const existingMessage = findExistingMessageInData(message)
    if (!existingMessage || existingMessage.readAt) return

    const newMessage = { ...existingMessage, readAt: new Date().toString() }
    const existingMessagePos = messages.indexOf(message)
    const newMessagesArray = [...messages]
    newMessagesArray.splice(existingMessagePos, 1, newMessage)
    setMessages(newMessagesArray)
  }

  const handleMessageDeleted = (message: Message) => {
    const existingMessage = findExistingMessageInData(message)
    if (!existingMessage || existingMessage.archivedAt) return

    const newMessage = { ...existingMessage, archivedAt: new Date().toString() }
    const existingMessagePos = messages.indexOf(message)
    const newMessagesArray = [...messages]
    newMessagesArray.splice(existingMessagePos, 1, newMessage)
    setMessages(newMessagesArray)
  }

  const emptyInbox = !messages.some((message: Message) => !message.archivedAt)

  return (
    <li
      className={classNames(css.dropdownMenu, {
        [css.emptyInbox]: emptyInbox,
      })}
      ref={messageCenterRef}
    >
      <h5 className={css.dropdownHeader}>Notifications</h5>
      {emptyInbox && <EmptyInbox />}
      {!emptyInbox && (
        <InfiniteScroll
          hasMore={hasMoreMessages}
          loadMore={fetchMessages}
          useWindow={false}
        >
          {messages.map((message, position) => (
            <MessageCard
              key={message.id}
              message={message}
              position={position}
              onMessageDeleted={handleMessageDeleted}
              onMessageRead={handleMessageRead}
            />
          ))}
        </InfiniteScroll>
      )}
    </li>
  )
}

export default MessageCenter
