import {
  size,
  forEach,
  map,
  assign,
  compact,
  some,
  every,
  min,
  max,
} from 'lodash'
import { ATTRIBUTE_KEYS } from 'store/constants'
import { freeShippingText } from 'components/free-shipping-on-babylist-message'
import { pdpPath } from 'lib/urls'

export const categoryTreeToCategories = (categoryTree) => {
  const newCategories = {}
  const addCategories = (categories, currentSlugSet) => {
    currentSlugSet = currentSlugSet || []
    forEach(categories, (category) => {
      const thisSlugSet = currentSlugSet.concat([category.urlSlug])
      newCategories[category.urlSlug] = assign({}, category, {
        id: category.id,
        slugSet: thisSlugSet,
        children:
          category.children && category.children.length
            ? map(category.children, (c) => c.urlSlug)
            : null,
      })
      if (category.children && category.children.length) {
        addCategories(category.children, thisSlugSet)
      }
    })
  }
  addCategories(categoryTree)
  return newCategories
}

export const collectionKeyForSearchTerm = (searchTerm) =>
  `search:${searchTerm.toLowerCase()}`

export const filterByAvailable = (products) =>
  products.filter((product) => product.availableOnBabylist == true)

export const productCardRepresentation = (gp, products) => {
  const availableOnBabylist = some(products, 'availableOnBabylist')
  let { merchandisingMessage } = products[0]
  if (every(products, { merchandisingMessage: 'In Stock Soon' })) {
    merchandisingMessage = 'In Stock Soon'
  }

  return {
    title: titleForProductCard(gp, products),
    availableOnBabylist,
    isFavor: some(products, 'isFavor'),
    isCashFund: some(products, 'isCashFund'),
    isCrowdfund: some(products, 'isCrowdfund'),
    isGiftCard: some(products, 'isGiftCard'),
    brand: gp.brand,
    imageUrl: products[0].images[0] && products[0].images[0].url,
    gpId: gp.id,
    productId: products[0].id,
    path: pdpPath(gp.slug, gp.id, products[0].id),
    price: every(products, 'price.current') && {
      min: min(map(products, (p) => p.price.current)),
      max: max(map(products, (p) => p.price.current)),
      msrp: min(map(products, (p) => p.price.msrp)),
    },
    rating: gp.rating,
    reviewCount: gp.reviewCount,
    productType: products[0].productType,
    merchandisingMessage,
  }
}

export const titleForProductCard = (gp, products) => {
  if (size(gp.products) == products.length) {
    // Only one card for this generic product
    return gp.shortName
  }
  return compact([
    gp.shortName,
    // If we're splitting correctly we can just look at first product since rest should be the same
    compact([
      products[0].attributes[ATTRIBUTE_KEYS.color],
      products[0].attributes[ATTRIBUTE_KEYS.style],
      products[0].attributes.sizeOnCatalog &&
        products[0].attributes[ATTRIBUTE_KEYS.size],
    ]).join(', '),
  ]).join(' - ')
}

export const shortSize = (size) => {
  size = size.replace(/newborn/gi, 'NB')
  size = size.replace(/ months?/gi, 'M')
  size = size.replace(/ ounces?/gi, ' OZ')
  size = size.replace(/ grams?/gi, ' G')
  size = size.replace(/x-small\/+?/gi, 'XS/')
  size = size.replace(/\/+small?/gi, '/S')
  size = size.replace(/small\/+?/gi, 'S/')
  size = size.replace(/\/+medium?/gi, '/M')
  size = size.replace(/medium\/+?/gi, 'M/')
  size = size.replace(/\/+xxx-large?/gi, '/3XL')
  size = size.replace(/xx-large\/+?/gi, '2XL/')
  size = size.replace(/\/+xx-large?/gi, '/2XL')
  size = size.replace(/x-large\/+?/gi, 'XL/')
  size = size.replace(/\/+x-large?/gi, '/XL')
  size = size.replace(/x-large\/+?/gi, 'XL/')
  size = size.replace(/\/+large?/gi, '/L')
  size = size.replace(/large\/+?/gi, 'L/')
  size = size.replace(' - ', '-')
  return size
}

export const constructFilterQueryString = (selectedFilters) => {
  const filterParts = Object.entries(selectedFilters)
    .filter(
      ([, value]) =>
        value && value !== '' && !(Array.isArray(value) && value.length === 0)
    )
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `filters[${key}]=${value.join(':')}`
      } else if (typeof value === 'object' && value !== null) {
        return `filters[${key}]=${value.from}:${value.to}`
      } else {
        return `filters[${key}]=${encodeURIComponent(value)}`
      }
    })

  return filterParts.join('&')
}

export const parseFilterQueryParams = (query) => {
  const params = new URLSearchParams(query)
  const filters = {}

  for (const [key, value] of params.entries()) {
    if (key.startsWith('filters[')) {
      const filterKey = key.match(/filters\[(.*?)\]/)[1]
      if (value.includes(':')) {
        filters[filterKey] = value.split(':').map(decodeURIComponent)
      } else {
        filters[filterKey] = decodeURIComponent(value)
      }
    }
  }

  return filters
}
