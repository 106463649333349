import classNames from 'classnames'
import { Component } from 'react'
import PropTypes from 'prop-types'
import css from './draggable-bookmark-button.scss'
import BookmarkletButton from './bookmarklet-button'
import showIf from 'lib/show-if'
import Image from 'components/image'

export default class DraggableBookmarkButton extends Component {
  static propTypes = {
    onInstall: PropTypes.func,
    bookmarkletJS: PropTypes.string.isRequired,
  }

  state = {
    visibleOverlay: false,
  }

  showOverlay = (e) => {
    this.lowerNav()
    this.setState({ visibleOverlay: true })
  }

  hideOverlay = (e) => {
    this.setState({ visibleOverlay: false })
    this.raiseNav()
  }

  handleDragStop = (e) => {
    this.hideOverlay(e)
    document.body.addEventListener('mousemove', this.mouseMoved)
  }

  mouseMoved = (e) => {
    if (e.clientY < 18) {
      this.handleInstall()
    }
    document.body.removeEventListener(e.type, this.mouseMoved)
  }

  handleInstall = () => {
    if (this.props.onInstall) {
      this.props.onInstall()
    }
  }

  render() {
    const { bookmarkletJS } = this.props

    return (
      <div className={css.babylistButton}>
        {showIf(
          this.state.visibleOverlay,
          <div>
            <div className={css.overlay} onClick={this.hideOverlay} />
            <div className={classNames(css.arrow, css.bounce)}>
              <i className="fa fa-angle-up fa-5x" aria-hidden="true" />
            </div>
          </div>
        )}

        <div
          className={css.draggableButton}
          onDragStart={this.showOverlay}
          onDragEnd={this.handleDragStop}
        >
          <BookmarkletButton bookmarkletJS={bookmarkletJS} />
          <div className={classNames(css.dragMe, 'flex-vertical-center')}>
            <Image
              src="https://res.cloudinary.com/babylist/image/upload/v1537228011/discovery/cta-drag.svg"
              alt="Arrow with text 'Drag Me'"
            />
          </div>
          {showIf(
            this.state.visibleOverlay,
            <div className={css.overlayCutout} />
          )}
        </div>
      </div>
    )
  }

  raiseNav = () => {
    const nav = Array.from(document.getElementsByTagName('nav'))
    nav.forEach((el) => el.classList.remove(css.noZindex))
  }

  lowerNav = () => {
    const nav = Array.from(document.getElementsByTagName('nav'))
    nav.forEach((el) => el.classList.add(css.noZindex))
  }
}
