import css from './SearchBarInput.scss'
import Close from 'shared/svg/close.svg'
import SearchMagnifyingGlass from 'shared/svg/search-magnifying-glass.svg'
import { getInputPlaceholder } from '../SearchBar.utils'

export interface SearchBarInputProps {
  inputRef?: React.RefObject<HTMLInputElement>
  query: string
  setQuery: (newQuery: string) => void
  clearQuery: () => void
  onFocus: () => void
  isLoggedIn: boolean
}

const SearchBarInput = ({
  inputRef,
  query,
  setQuery,
  clearQuery,
  onFocus,
  isLoggedIn,
}: SearchBarInputProps) => {
  const inputPlaceholder = getInputPlaceholder(isLoggedIn)

  return (
    <div className={css.SearchBarInput}>
      <SearchMagnifyingGlass className={css.SearchBarInput__searchIcon} />
      <input
        className={css.SearchBarInput__input}
        type="text"
        placeholder={inputPlaceholder}
        ref={inputRef}
        value={query}
        onFocus={onFocus}
        onChange={(e) => setQuery(e.target.value)}
      />
      {query ? (
        <button
          className={css.SearchBarInput__clearButton}
          onClick={clearQuery}
          aria-label="Clear search"
        >
          <Close className={css.SearchBarInput__clearIcon} />
        </button>
      ) : null}
    </div>
  )
}

export default SearchBarInput
