import React from 'react'
import { SuccessMessageProps } from 'registry/components/CrowdfundReserveFormFlow/components/ContributionSuccess/components/SuccessMessage/SuccessMessage.types'
import CashFundGraphic from 'registry/components/CrowdfundReserveFormFlow/components/ContributionSuccess/components/CashFundGraphic/CashFundGraphic.svg'
import css from './SuccessMessage.styles.scss'

const SuccessMessage: React.FC<SuccessMessageProps> = ({ subtitle, title }) => (
  <div className={css.SuccessMessage}>
    <CashFundGraphic />
    <div className={css.SuccessMessage__TextContent}>
      <p className={css.SuccessMessage__Title}>{title}</p>
      <p className={css.SuccessMessage__Subtitle}>{subtitle}</p>
    </div>
  </div>
)

export default SuccessMessage
