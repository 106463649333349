import React from 'react'

interface CheckProps {
  className?: string
}

const Check: React.FC<CheckProps> = ({ className }: CheckProps) => (
  <svg
    className={className}
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M13.6712 3.63143C13.8747 3.81802 13.8885 4.13431 13.7019 4.33787L7.1092 11.5299C6.98845 11.6616 6.87796 11.7822 6.77753 11.875C6.66962 11.9747 6.54051 12.0743 6.37311 12.1343C6.13469 12.2199 5.8749 12.2256 5.63299 12.1504C5.46314 12.0977 5.32983 12.0038 5.21769 11.9089C5.11333 11.8205 4.99771 11.7049 4.87135 11.5785L2.31307 9.02022C2.11781 8.82496 2.11781 8.50838 2.31307 8.31312C2.50833 8.11785 2.82492 8.11785 3.02018 8.31312L5.56576 10.8587C5.709 11.0019 5.79504 11.0874 5.86379 11.1456C5.90574 11.1811 5.92629 11.1932 5.93206 11.1962C5.96508 11.2059 6.0003 11.2051 6.03288 11.194C6.0385 11.1907 6.05851 11.1778 6.09888 11.1405C6.16504 11.0794 6.24729 10.9902 6.38417 10.8409L12.9647 3.66214C13.1513 3.45858 13.4676 3.44483 13.6712 3.63143Z"
      fill="#2F743E"
      fillRule="evenodd"
    />
  </svg>
)

export default Check
