/* eslint-disable react/jsx-props-no-spreading */
function getDisplayName(component) {
  return component.displayName || component.name || 'Component'
}

export function withStaticProps(componentName, props) {
  return (Wrapee) => {
    const Wrapper = (componentProps) => (
      <Wrapee {...componentProps} {...props} />
    )

    Wrapper.displayName = `${componentName}(${getDisplayName(Wrapee)})`

    return Wrapper
  }
}
