import React from 'react'
import { HELLO_EMAIL } from 'shared/constants'
import { ContributionMailingAddressProps } from 'registry/components/LegacyCrowdfundReserveForm/components/CashContributionConfirmation/components/ContributionMailingAddress/ContributionMailingAddress.types'

const ContributionMailingAddress: React.FC<ContributionMailingAddressProps> = ({
  address,
}) => {
  if (!address || !address.name) {
    return (
      <p>
        We don't have a shipping address for this registry. If you need it,
        email us at <a href={`mailto:${HELLO_EMAIL}`}>{HELLO_EMAIL}</a> and
        we'll request it from them.
      </p>
    )
  }

  return (
    <address className="lh-base paxl text-center" id="address">
      {address.name}
      <br />
      {address.streetAddress1}{' '}
      {address.streetAddress2 ? <span>, {address.streetAddress2}</span> : null}
      <br />
      {address.city}, {address.state} {address.zip}
    </address>
  )
}

export default ContributionMailingAddress
