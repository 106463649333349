export const addOrReplaceImageTransform = (url, transform) => {
  if (!url) return null

  if (url.includes('cloudinary.com')) {
    return url.replace(
      /(.*?cloudinary.com\/babylist\/image\/upload)\/?\S*\/([v]\d+.+)/,
      `$1/${transform}/$2`
    )
  }
  if (url.includes('images.babylist')) {
    return url.replace(
      /(.*?babylist.com\/image\/upload)\/?\S*\/([v]\d+.+)/,
      `$1/${transform}/$2`
    )
  }
  return url
}
