import PropTypes from 'prop-types'
import classNames from 'classnames'
import Image from 'components/image'
import { isNumber, sample } from 'lodash'
import css from './image-with-placeholder-color.scss'

// Utility image component for when you know the image's aspect ratio.
// Provides a responsive image wrapped in a container with a placeholder color.
// Prevents page content from jumping when images are loaded.

const PLACEHOLDER_CLASSES = [
  css.placeholderPrimary,
  css.placeholderSecondary,
  css.placeholderTertiary,
  css.placeholderQuaternary,
  css.placeholderQuinary,
  // just a slightly darker shade of brand-tertiary
  // css.placeholderSenary
]

const ImageWithPlaceholderColor = ({
  alt,
  aspectWidth,
  aspectHeight,
  className,
  colorIndex = 0,
  src,
  ...props
}) => {
  const placeholderClassName = isNumber(colorIndex)
    ? PLACEHOLDER_CLASSES[colorIndex % PLACEHOLDER_CLASSES.length]
    : sample(PLACEHOLDER_CLASSES)
  const paddingBottom = `${(aspectHeight / aspectWidth) * 100}%`
  return (
    <div
      className={classNames(
        'embed-responsive',
        placeholderClassName,
        className
      )}
      style={{ paddingBottom }}
    >
      <Image
        alt={alt}
        className="img-responsive"
        src={src}
        {...props}
        lazyLoad={false}
      />
    </div>
  )
}

ImageWithPlaceholderColor.propTypes = {
  alt: PropTypes.string.isRequired,
  aspectWidth: PropTypes.number.isRequired,
  aspectHeight: PropTypes.number.isRequired,
  className: PropTypes.string,
  colorIndex: PropTypes.number,
  src: PropTypes.string.isRequired,
}

ImageWithPlaceholderColor.defaultProps = {
  className: null,
  colorIndex: 0,
}

export default ImageWithPlaceholderColor
