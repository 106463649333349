/* eslint-disable complexity */
/* This is sad, but doing this until we have a declaration file */
// @ts-nocheck
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { Tooltip } from 'baby-design'

import { email as isEmail } from 'lib/form-validation'
import { setGiftGiverCookie, useGiftGiverCookie } from 'lib/cookies'
import { find, includes, isString } from 'lodash'
import { DangerAlert } from 'components/alerts'
import { InfoButton } from 'components/buttons'
import { Checkbox, Input, Radio, RadioGroup } from 'components/forms'
import { getGiftCardsByType } from 'cart/lib'
import MailcheckedInput from 'components/mailchecked-input'
import GiftAmountSelect from './gift-amount-select'
import QuantityField from './quantity-field'

import {
  DEFAULT_GIFT_CARD_AMOUNT,
  GIFT_CARD_LABEL_DIGITAL,
  GIFT_CARD_LABEL_PHYSICAL,
  MARKETING_EMAIL_CHECKBOX_LABEL,
  PRODUCT_TYPE_DIGITAL,
  PRODUCT_TYPE_PHYSICAL_GIFT_CARD,
} from '../constants'
import InfoIcon from 'shared/svg/Info.svg'
import css from './gift-giver-info-form.styles.scss'

interface FormSubmitData {
  email: string
  giftAmount: string
  name: string
  productId?: number
  quantity?: number
}

interface GiftGiverInfoFormProps
  extends React.FormHTMLAttributes<HTMLFormElement> {
  ctaText?: string
  onFormSubmit: (json: FormSubmitData) => void
  regItem: {
    isGiftCard: boolean
    quantityNeeded: number
    giftCardOptions: any
    purchaseQuantityLimit: number
    registryId: number
    showPurchaseQuantityLimit: boolean
  }
  registrantNames?: string
}

export const GiftGiverInfoForm = ({
  className,
  ctaText,
  onFormSubmit,
  regItem,
  registrantNames,
}: GiftGiverInfoFormProps) => {
  const {
    isGiftCard,
    quantityNeeded,
    giftCardOptions,
    purchaseQuantityLimit,
    registryId,
    showPurchaseQuantityLimit,
  } = regItem

  const { name, email, subscribeToMarketingEmail } = useGiftGiverCookie()

  const defaultValues = {
    addToCart: {
      email,
      name,
      giftAmount: isGiftCard ? DEFAULT_GIFT_CARD_AMOUNT : null,
      giftCardType: PRODUCT_TYPE_DIGITAL,
      quantity: 1,
      subscribeToMarketingEmail,
    },
  }

  const { errors, handleSubmit, setValue, register, watch } = useForm({
    defaultValues,
  })

  const submitFunction = (json: FormSubmitData): void => onFormSubmit(json)

  const { mutate, error, isLoading, isError } = useMutation({
    mutationFn: submitFunction,
  })

  const watchGiftCardType = watch(
    'giftCardType',
    defaultValues.addToCart.giftCardType
  )

  const giftCardsByType = isGiftCard
    ? getGiftCardsByType(giftCardOptions)
    : null

  const giftCardTypes = isGiftCard
    ? giftCardsByType && Object.keys(giftCardsByType)
    : null

  const updateEmail = (eventOrValue) => {
    const value = isString(eventOrValue)
      ? eventOrValue
      : eventOrValue.target.value
    setValue('email', value)
  }

  const handleSubmitReservation = (values: {
    email: string
    giftAmount: string
    name: string
    giftCardType?: string
    subscribeToMarketingEmail: boolean
    isSurprise: boolean
  }) => {
    let json
    setGiftGiverCookie({
      subscribeToMarketingEmail: values.subscribeToMarketingEmail,
    })

    if (isGiftCard && values.giftCardType) {
      const giftCardProduct = find(
        giftCardsByType[values.giftCardType],
        (gc: { price: number }) => gc.price === parseFloat(values.giftAmount)
      )
      const productId = giftCardProduct ? giftCardProduct.id : null

      json = {
        email: values.email,
        giftAmount: values.giftAmount,
        name: values.name,
        productId,
        isSurprise: values.isSurprise,
      }
    } else {
      json = values
    }
    mutate(json)
  }

  return (
    <form
      className={className}
      /* @ts-ignore */
      onSubmit={handleSubmit(handleSubmitReservation)}
    >
      {/* @ts-ignore */}
      {isError && <DangerAlert>{error._error}</DangerAlert>}
      <fieldset className="mvn">
        <p className="h6 lh-base mbm mtn">
          {isGiftCard &&
            `Gift cards can be printed out and given in person or sent via email${
              includes(giftCardTypes, PRODUCT_TYPE_PHYSICAL_GIFT_CARD)
                ? ' or mail'
                : ''
            }.`}
        </p>
        <fieldset>
          <Input
            name="name"
            defaultValue={defaultValues.addToCart.name}
            /* @ts-ignore */
            error={errors?.name?.message}
            label="First &amp; Last Name"
            placeholder="First and last name"
            type="text"
            inputRef={register({
              required: 'Please add your name',
            })}
          />
          <MailcheckedInput
            defaultValue={defaultValues.addToCart.email}
            error={errors?.email?.message}
            label="Email"
            placeholder="Your email address"
            name="email"
            inputRef={register({
              required: 'Enter a valid email address',
              validate: isEmail,
            })}
            onChange={updateEmail}
            mailcheckOnBlur={false}
            mailcheckOnChange
            mailcheckDelay={300}
          />
        </fieldset>
      </fieldset>
      {quantityNeeded > 1 && (
        <fieldset className="mbl mtn">
          <QuantityField
            name="quantity"
            quantityNeeded={quantityNeeded}
            purchaseQuantityLimit={purchaseQuantityLimit}
            showPurchaseQuantityLimit={showPurchaseQuantityLimit}
            labelClassName="control-label"
            inputRef={register({
              required: true,
            })}
          />
        </fieldset>
      )}
      {isGiftCard && giftCardsByType[watchGiftCardType] && (
        <>
          <GiftAmountSelect
            name="giftAmount"
            defaultGiftAmount={DEFAULT_GIFT_CARD_AMOUNT}
            options={giftCardsByType[watchGiftCardType].map(
              (gc: { price: number }) => gc.price
            )}
            inputRef={register({
              required: true,
            })}
          />
          <RadioGroup
            title="Card Type"
            className={giftCardTypes.length === 1 ? 'hidden' : null}
            horizontal={false}
          >
            {giftCardTypes.map((cardType: string) => (
              <Radio
                defaultChecked={cardType === PRODUCT_TYPE_DIGITAL}
                name="giftCardType"
                inputRef={register({
                  required: true,
                })}
                label={
                  cardType === PRODUCT_TYPE_DIGITAL
                    ? GIFT_CARD_LABEL_DIGITAL
                    : GIFT_CARD_LABEL_PHYSICAL
                }
                tappable
                value={cardType}
              />
            ))}
          </RadioGroup>
        </>
      )}

      <Checkbox
        defaultChecked={false}
        name="isSurprise"
        label="Keep this gift a surprise."
        type="checkbox"
        inputRef={register}
        groupClassName={css.SurpriseCheckbox}
      />

      <Tooltip
        className={css.Tooltip}
        content={`If you check this box, we'll mark your gift as purchased and hide your info. ${
          registrantNames || 'The family'
        } can choose to reveal the surprise at any time.`}
        trigger={<InfoIcon />}
        clickable
      />

      <Checkbox
        defaultChecked={defaultValues.addToCart.subscribeToMarketingEmail}
        name="subscribeToMarketingEmail"
        label={MARKETING_EMAIL_CHECKBOX_LABEL}
        type="checkbox"
        inputRef={register}
        value="true"
      />

      <div className="text-center">
        <InfoButton
          pill
          submitting={isLoading}
          type="submit"
          className="mvl"
          block
        >
          {ctaText}
        </InfoButton>
      </div>
    </form>
  )
}

GiftGiverInfoForm.defaultProps = {
  ctaText: 'Submit',
}
