import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import Link from 'components/link'
import { openRecoverReservationModal } from '../../../modals/actions'

const ReservedAction = ({
  children,
  className,
  regItemId,
  openRecoverReservationModal,
}) => (
  <Link
    onClick={() => openRecoverReservationModal(regItemId)}
    className={classNames(className, 'link-body-text')}
  >
    {children}
  </Link>
)

ReservedAction.propTypes = {
  regItemId: PropTypes.number.isRequired,
  openRecoverReservationModal: PropTypes.func.isRequired,
}

export default connect(undefined, { openRecoverReservationModal })(
  ReservedAction
)
