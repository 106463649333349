const CategoryOnTheGo = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 258.35 258.35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M184.41,149.49l-24.57-2.56a4.64,4.64,0,0,0-4.09,1.72l-9.69,12.23a4.61,4.61,0,0,1-4.24,1.71l-4-.54A10.71,10.71,0,0,0,140,147.5a4.38,4.38,0,0,0,1-.83l11-12.33a4.65,4.65,0,0,1,4-1.51l13.94,1.7,21.65,2.64h0a4.62,4.62,0,0,1,4,5.41l-.67,3.71a4.61,4.61,0,0,1-5,3.77Z"
      fill="#d8d8d8"
    />
    <path
      d="M129.16,67.23c-.63,2.81-1.52,7-2.48,11.77l-.77,3.19.06.41c-2.63,13.64-5.36,30.75-4.15,38,1.72,10.31,9.18,19.45,12,22.43a.79.79,0,0,0,.24.15l0,.09a10.75,10.75,0,0,0-10.52,3l-6.64-1v0a87.3,87.3,0,0,1-11.45-26.79c-2.69-12.22,6.27-44.47,9.36-55a3.75,3.75,0,0,1,4.41-2.59l7.22,1.64A3.73,3.73,0,0,1,129.21,67C129.19,67.07,129.18,67.15,129.16,67.23Z"
      fill="#d8d8d8"
    />
    <path d="M129.5,67.29,126.68,79c1-4.8,1.85-9,2.48-11.77Z" fill="#f2ece5" />
    <path
      d="M125.91,82.19l.77-3.19c-.24,1.17-.47,2.37-.71,3.6Z"
      fill="#f2ece5"
    />
    <path
      d="M113.21,144.77l3.64.52,6.64,1a10.71,10.71,0,0,0-.87,13.75l-35.94-4.88a4.63,4.63,0,0,0-3.9,1.33L72.5,166.82a4.43,4.43,0,0,1-.63.53h0a4.62,4.62,0,0,1-6.33-1l-1.93-2.58a4.62,4.62,0,0,1,.19-5.78l2.27-2.64,11.62-13.51a4.65,4.65,0,0,1,4.16-1.56Z"
      fill="#d8d8d8"
    />
    <path
      d="M71.82,166.82s0,.19,0,.53c.32,3.25,2.7,20.52,17.82,26.22,16.7,6.31,56.24,3.9,67.93.93s17.63-13.18,19.3-18.93c1.58-5.42,6.89-23.93,7.5-26.08l.06-.2"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M140,147.5h0a4.38,4.38,0,0,0,1-.83l11-12.33a4.65,4.65,0,0,1,4-1.51l13.94,1.7,21.65,2.64h0a4.62,4.62,0,0,1,4,5.41l-.67,3.71a4.61,4.61,0,0,1-5,3.77l-5.5-.57-24.57-2.56a4.64,4.64,0,0,0-4.09,1.72l-9.69,12.23a4.61,4.61,0,0,1-4.24,1.71l-4-.54h0"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M122.62,160l-35.94-4.88a4.63,4.63,0,0,0-3.9,1.33L72.5,166.82a4.43,4.43,0,0,1-.63.53h0a4.62,4.62,0,0,1-6.33-1l-1.93-2.58a4.62,4.62,0,0,1,.19-5.78l2.27-2.64,11.62-13.51a4.65,4.65,0,0,1,4.16-1.56l31.37,4.5,3.64.52,6.64,1"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M140,147.5A10.71,10.71,0,0,1,122.62,160h0A10.71,10.71,0,1,1,140,147.5Z"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M191.62,137.17s6-13.29,3.24-27.74a34.9,34.9,0,0,0-8.22-16.89c-4.94-5.58-12.11-10.1-19.75-13.69-15.27-7.17-32.43-10.65-37.39-11.56l-.34-.06L128.09,67"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M65.78,155.1s-1.14-17.57,11.69-21.78c8-2.63,31.65,9.33,35.74,11.45l.61.31"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <polyline
      fill="none"
      points="195.51 108.79 194.87 109.43 170 134.3"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <line
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
      x1="166.72"
      x2="152.03"
      y1="79.27"
      y2="134.34"
    />
    <path
      d="M116.85,145.25a87.3,87.3,0,0,1-11.45-26.79c-2.69-12.22,6.27-44.47,9.36-55a3.75,3.75,0,0,1,4.41-2.59l7.22,1.64A3.73,3.73,0,0,1,129.21,67c0,.08,0,.16-.05.24-.63,2.81-1.52,7-2.48,11.77-.24,1.17-.47,2.37-.71,3.6-2.63,13.64-5.36,30.75-4.15,38,1.72,10.31,9.18,19.45,12,22.43a.79.79,0,0,0,.24.15.55.55,0,0,0,.7-.6L126,82.6l-.06-.41.77-3.19,2.82-11.71.09-.39"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
  </svg>
)

export default CategoryOnTheGo
