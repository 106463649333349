/* global FileReader */
import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import fetch from 'lib/fetch'

import css from './image-upload-previewer.scss'
import Link from 'components/link'
import RegItemImage from 'registry/components/reg-item-image'
import Spinner from 'shared/spinner'
import showIf from 'lib/show-if'
import { apiV2Images } from 'lib/urls'
import { DEFAULT_REG_ITEM_IMAGE_PATH } from 'shared/constants'

export default class ImageUploadPreviewer extends PureComponent {
  static propTypes = {
    imgUrl: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      imagePreview: props.imgUrl || DEFAULT_REG_ITEM_IMAGE_PATH,
    }
  }

  onImageRemove = () => {
    this.setState({ imagePreview: DEFAULT_REG_ITEM_IMAGE_PATH })
    this.props.onChange({ imgUrl: null })
  }

  onImageChange = (e) => {
    this.setState({ isUploadingImage: true })

    const file = e.target.files[0]
    let reader = new FileReader()
    reader.onload = (event) => {
      fetch(apiV2Images, {
        method: 'post',
        body: JSON.stringify({ data: event.target.result }),
      }).then(({ url }) => {
        if (url) {
          this.setState({ imagePreview: url })
          this.props.onChange({ imgUrl: url })
        } else {
          this.setState({ error: 'Failed to upload image.' })
        }

        this.setState({ isUploadingImage: false })
      })
    }
    reader.readAsDataURL(file)
  }

  render() {
    const { imagePreview, isUploadingImage } = this.state

    return (
      <div className={css.container}>
        <RegItemImage src={this.props.imgUrl} width={250} />
        {showIf(
          isUploadingImage,
          <Spinner size="large" className={css.spinner} />
        )}
        <div className={css.actions}>
          <a className={css.replace}>
            {this.state.imagePreview === DEFAULT_REG_ITEM_IMAGE_PATH
              ? 'Add an Image'
              : 'Replace'}
            <input onChange={this.onImageChange} type="file" id="image-file" />
          </a>
          {showIf(
            imagePreview !== DEFAULT_REG_ITEM_IMAGE_PATH,
            <span>
              {' '}
              |{' '}
              <Link className={css.remove} onClick={this.onImageRemove}>
                Remove
              </Link>
            </span>
          )}
        </div>
      </div>
    )
  }
}
