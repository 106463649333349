/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types'

import { numberToCurrency } from 'lib/money'
import Offer, { offerProps } from './offer'

const PriceRangeOffer = ({ rangeStart, rangeEnd, ...rest }) => (
  <Offer {...rest}>{`${numberToCurrency(rangeStart, {
    truncateZeroCents: true,
  })} - ${numberToCurrency(rangeEnd, { truncateZeroCents: true })}`}</Offer>
)

PriceRangeOffer.propTypes = {
  ...offerProps,
  rangeStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  rangeEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}

export default PriceRangeOffer
