import fetch from 'lib/fetch'
import { Cart } from 'src/types/cart'
import { useQuery } from '@tanstack/react-query'

const API_PATH = '/store/api/cart'
export const CACHE_KEY = ['cart']

const fetchShow = async (): Promise<Cart> => fetch(API_PATH)

const useShow = () =>
  useQuery<Cart, Error>({
    queryKey: CACHE_KEY,
    queryFn: fetchShow,
  })

export const CartAPI = {
  useShow,
}
