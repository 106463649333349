import { useState, useEffect } from 'react'

const withClientRender =
  ({ placeholder }) =>
  (Component) => {
    const ClientRenderWrapper = (componentProps) => {
      const [showComponent, setShowComponent] = useState(false)

      // Wait until after client-side hydration to show
      useEffect(() => {
        setShowComponent(true)
      }, [])

      if (!showComponent) {
        return placeholder
      }

      return <Component {...componentProps} />
    }
    return ClientRenderWrapper
  }

export default withClientRender
