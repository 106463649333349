import { sectionAnchor } from 'hello_baby/lib/helpers'
import { Document } from '@contentful/rich-text-types'
import RichTextBody from 'hello_baby/components/RichTextBody'
import ProductCard, { ProductLink } from './ProductCard'

import styles from './ProductCardSection.styles.scss'

interface PostEmbedProductCardSectionFields {
  title: string
  prosList?: string[]
  consList?: string[]
  product: ProductLink
  richTextBody?: Document
}

export interface ProductCardSectionProps {
  fields: PostEmbedProductCardSectionFields
}

const ALLOWED_EMBEDS = [
  'postEmbedImage',
  'postEmbedImageGallery',
  'postEmbedParentReviews',
  'postEmbedSocialMedia',
  'postEmbedTable',
  'video',
]

const skipEmbed = (fields: PostEmbedProductCardSectionFields) => {
  if (
    !fields.product ||
    !fields.product.fields ||
    !fields.product.fields.image
  ) {
    return true
  }
  return false
}

const ProductCardSection: React.FC<ProductCardSectionProps> = ({
  fields,
}: ProductCardSectionProps) => {
  if (skipEmbed(fields)) {
    return null
  }
  return (
    <section
      className={styles.ProductCardSection}
      id={sectionAnchor(fields.product.fields.name)}
      key={fields.product.fields.name}
    >
      <ProductCard product={fields.product} />
      {fields.prosList && fields.prosList.length > 0 && (
        <div className={styles.ProductCardSection__proscons}>
          {fields.prosList && fields.prosList.length > 0 && (
            <div className={styles.ProductCardSection__pros}>
              <h3>Pros</h3>
              <ul>
                {fields.prosList.map((pro) => (
                  <li key={pro}>{pro}</li>
                ))}
              </ul>
            </div>
          )}
          {fields.consList && fields.consList.length > 0 && (
            <div className={styles.ProductCardSection__cons}>
              <h3>Cons</h3>
              <ul>
                {fields.consList.map((con) => (
                  <li key={con}>{con}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
      {fields.richTextBody && (
        <RichTextBody
          allowedEmbeds={ALLOWED_EMBEDS}
          richTextBody={fields.richTextBody}
        />
      )}
    </section>
  )
}

export default ProductCardSection
