import React from 'react'

interface ChevronProps {
  className?: string
}

const Chevron: React.FC<ChevronProps> = ({ className }: ChevronProps) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M4.55806 7.05806C4.80214 6.81398 5.19786 6.81398 5.44194 7.05806L9.49913 11.1152C9.67143 11.2875 9.77442 11.3899 9.85673 11.4597C9.90696 11.5024 9.93149 11.5169 9.93834 11.5205C9.9785 11.5329 10.0215 11.5329 10.0617 11.5205C10.0685 11.5169 10.093 11.5024 10.1433 11.4597C10.2256 11.3899 10.3286 11.2875 10.5009 11.1152L14.5581 7.05806C14.8021 6.81398 15.1979 6.81398 15.4419 7.05806C15.686 7.30214 15.686 7.69786 15.4419 7.94194L11.3693 12.0146C11.2176 12.1663 11.0783 12.3057 10.9522 12.4126C10.8164 12.528 10.6555 12.6419 10.4506 12.7084C10.1578 12.8036 9.84225 12.8036 9.54935 12.7084C9.34449 12.6419 9.18361 12.528 9.04775 12.4126C8.92175 12.3057 8.78241 12.1663 8.63069 12.0146L4.55806 7.94194C4.31398 7.69786 4.31398 7.30214 4.55806 7.05806Z"
      fill="#252324"
      fillRule="evenodd"
    />
  </svg>
)

export default Chevron
