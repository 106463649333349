/* globals PubSub */

import classnames from 'classnames'
import { forEach, isString, map } from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'

import { Alert } from 'components/alerts'

import { FLASH_MESSAGE } from './constants'

const ANIMATION_DURATION_MS = 300
const RAILS_FLASH_KEYS = {
  notice: 'info',
  error: 'danger',
}
const FLASH_DEFAULTS = {
  sticky: false,
  dismissable: true,
  in: true,
  variant: 'info',
  timeOut: 5000,
  markedForExit: false,
  wide: false,
}

let flashKey = 0

class FlashMessage extends Component {
  constructor(props) {
    super(props)
    let flashes = {}
    // any passed in flash is coming from rails, create flash objects that match our API
    forEach(props.flashes, (flash) => {
      flashes[flashKey] = {
        variant: RAILS_FLASH_KEYS[flash[0]] || 'info',
        message: flash[1],
        sticky: true,
        in: true,
        transitioningOut: false,
        wide: true,
      }
      flashKey += 1
    })

    this.state = { flashes }
  }

  componentDidMount() {
    PubSub.subscribe(FLASH_MESSAGE, (flash) => {
      // allow a string to be passed in if we want to just use defaults
      if (isString(flash)) {
        flash = { message: flash }
      }

      // do nothing if there isn't a message
      if (!flash.message) {
        return
      }

      let state = { ...this.state }

      // merge with defaults
      flash = { ...FLASH_DEFAULTS, ...flash }

      // add it to our object
      state.flashes[flashKey] = flash

      this.setState(state)

      // update flashKey for the next
      flashKey += 1
    })
  }

  componentDidUpdate() {
    forEach(this.state.flashes, (flash, key) => {
      // if the item isn't supposed to be sticky, mark it now and start the countdown
      // NB: it's possible that this will get called multiple times for an individual alert
      // that's ok for now, we simply check for the key before trying to animate out.
      // later, if this doesn't work out we can simply add a flag to each flash and
      // and check that in the if statement and avoid calling setTimeout a second time
      if (!flash.sticky) {
        setTimeout(() => this.animateOut(key), flash.timeOut)
      }
    })
  }

  render() {
    return (
      <div
        className={classnames('alert-flash-message-container')}
        style={{ zIndex: 9999 }}
      >
        {map(this.state.flashes, (flash, key) => {
          let onDismiss =
            flash.sticky || flash.dismissable
              ? () => this.animateOut(key)
              : null
          var animationClass = ''
          switch (flash.variant) {
            case 'danger':
              animationClass = 'shakeIn'
              break
            case 'success':
              animationClass = 'popIn'
              break
            default:
              animationClass = 'fadeInDown'
          }
          return (
            <div
              key={key}
              className={classnames(animationClass, { fadeOutDown: !flash.in })}
            >
              <Alert
                className="alert-flash-message"
                variant={flash.variant}
                onDismiss={onDismiss}
              >
                {typeof flash.message == 'string' ? (
                  <div className="alert-container">
                    <div
                      className="alert-text-container h5 mvn text-left"
                      dangerouslySetInnerHTML={{ __html: flash.message }}
                    />
                  </div>
                ) : (
                  <div className="alert-container">
                    <p className="alert-text-container h5 mvn text-left">
                      {flash.message}
                    </p>
                  </div>
                )}
              </Alert>
            </div>
          )
        })}
      </div>
    )
  }

  animateOut(key) {
    // if the item has already been removed no need to set this again
    if (!this.state.flashes[key]) {
      return
    }

    // shhhh...harmless mutation
    this.state.flashes[key].in = false // eslint-disable-line
    this.setState({ flashes: this.state.flashes }, () => {
      // delete the item after the animation has finished
      setTimeout(() => this.deleteFlash(key), ANIMATION_DURATION_MS)
    })
  }

  deleteFlash(key) {
    delete this.state.flashes[key]
    this.setState({ flashes: this.state.flashes })
  }
}

FlashMessage.propTypes = {
  flashes: PropTypes.array,
}

export default FlashMessage
