import css from './SearchResultComponents.scss'
import { GenericResult } from '../../types'
import Markdown from 'markdown-to-jsx'
import { useTracking } from 'lib/analytics'
import {
  setSearchPreviewIds,
  trackUnifiedSearchEvent,
} from '../../SearchBar.utils'

const SearchResultSuggestedResultText = ({
  result,
}: {
  result: GenericResult
}) => {
  const tracker = useTracking()
  const trackSearchEvents = () => {
    const event = result.tracking_event
    if (!event) return

    trackUnifiedSearchEvent(event, tracker.trackEvent)
  }

  const handleTitleClick = () => {
    const searchPreviewIds = result.search_preview_ids

    searchPreviewIds && setSearchPreviewIds(searchPreviewIds)
    trackSearchEvents()
    window.location.href = result.url
  }

  return (
    <div className={css.SearchResults__result} onClick={handleTitleClick}>
      <span className={css.SearchResults__resultText}>
        <Markdown>{result.title}</Markdown>
      </span>
    </div>
  )
}

export default SearchResultSuggestedResultText
