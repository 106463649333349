import React from 'react'
import classNames from 'classnames'
import { PaymentPreferenceRequiredCalloutProps } from 'src/routes/(registry)/list/[slug]/components/CashFundPreferencesDrawer/components/PaymentPreferenceRequiredCallout/PaymentPreferenceRequiredCallout.types'
import { Exclamation } from '../../../../../../../../../baby-design/icons'
import css from './PaymentPreferenceRequiredCallout.scss'

const PaymentPreferenceRequiredCallout: React.FC<
  PaymentPreferenceRequiredCalloutProps
> = ({ className, message }) => (
  <div className={classNames(css.PaymentPreferenceRequiredCallout, className)}>
    <Exclamation className={css.PaymentPreferenceRequiredCallout__Icon} />
    <span className={css.PaymentPreferenceRequiredCallout__Text}>
      {message}
    </span>
  </div>
)

export default PaymentPreferenceRequiredCallout
