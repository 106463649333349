import { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Label } from 'components/forms'
import { GroupGiftToggle as Component } from 'components/badges'

const GroupGiftToggle = ({
  className,
  defaultOn,
  disabled,
  label,
  onClick,
  onDisabledClick,
}) => {
  const [isOn, setIsOn] = useState(defaultOn)

  const handleChange = () => {
    onClick(!isOn)
    setIsOn(!isOn)
  }

  return (
    <div className={classNames(className, 'mbm')}>
      {label && <Label>{label}</Label>}
      <Component
        ariaLabel={`${isOn ? 'Disable' : 'Enable'} ${label}`}
        isOn={isOn}
        onClick={handleChange}
        disabled={disabled}
        onDisabledClick={onDisabledClick}
      />
    </div>
  )
}

GroupGiftToggle.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  defaultOn: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDisabledClick: PropTypes.func,
}

GroupGiftToggle.defaultProps = {
  className: null,
  defaultOn: false,
  disabled: false,
  label: 'Group Gift',
  onDisabledClick: () => {},
}

export default GroupGiftToggle
