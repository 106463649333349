const ExternalLink = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="0.8em"
    height="1em"
    viewBox="0 0 24 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.0636 5.72011L4.93123 17.8525C4.59536 18.1883 4.59536 18.7329 4.93123 19.0688C5.2671 19.4046 5.81166 19.4046 6.14753 19.0688L18.2799 6.93641L18.2799 17.1005C18.2799 17.5755 18.6649 17.9606 19.1399 17.9606C19.6149 17.9606 20 17.5755 20 17.1005V4.86005C20 4.38506 19.6149 4 19.1399 4H6.89945C6.42445 4 6.03939 4.38506 6.03939 4.86005C6.03939 5.33505 6.42445 5.72011 6.89945 5.72011L17.0636 5.72011ZM4.86005 23.7208C4.38506 23.7208 4 24.1059 4 24.5809C4 25.0559 4.38506 25.441 4.86005 25.441H18.4606C18.9356 25.441 19.3207 25.0559 19.3207 24.5809C19.3207 24.1059 18.9356 23.7208 18.4606 23.7208H4.86005Z"
      fill="black"
    />
  </svg>
)

export default ExternalLink
