import React from 'react'
import { LinkButton } from 'components/buttons'
import { SecondaryActionLinksProps } from 'registry/components/LegacyCrowdfundReserveForm/components/SecondaryActionLinks/SecondaryActionLinks.types.d'

const SecondaryActionLinks: React.FC<SecondaryActionLinksProps> = ({
  onCancel,
  onReset,
}) => {
  const handleReset = () => onReset && onReset()

  const handleCancel = () => onCancel && onCancel()

  return (
    <div className="text-center">
      <LinkButton
        block
        className="h7 text-underline mvm pan"
        onClick={handleReset}
      >
        Change my payment option
      </LinkButton>
      <LinkButton
        block
        className="h7 text-underline text-center pan"
        onClick={handleCancel}
      >
        I've changed my mind, cancel this gift
      </LinkButton>
    </div>
  )
}

export default SecondaryActionLinks
