import { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Label } from 'components/forms'
import { FavoriteToggle as Component } from 'components/badges'

const FavoriteToggle = ({ className, defaultOn, label, onClick }) => {
  const [isOn, setIsOn] = useState(defaultOn)

  const handleChange = () => {
    onClick(!isOn)
    setIsOn(!isOn)
  }

  return (
    <div className={classNames(className, 'mbm')}>
      {label && <Label>{label}</Label>}
      <Component
        ariaLabel={`${isOn ? 'Disable' : 'Enable'} ${label}`}
        isOn={isOn}
        onClick={handleChange}
      />
    </div>
  )
}

FavoriteToggle.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  defaultOn: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

FavoriteToggle.defaultProps = {
  className: null,
  defaultOn: false,
  label: 'Must Have',
}

export default FavoriteToggle
