import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Col } from 'react-bootstrap'
import RegItemNote from 'shared/reg-item-note'
import { regItemProps } from './reg-item-props'
import css from './reg-item-card-layout.scss'
import RegItemImage from '../reg-item-image'
import AddedByNote from './added-by-note'

const RegItemCardLayout = (
  {
    actions,
    imageCTA,
    giftStatusContent,
    offerPanel,
    regItem,
    regItemLinkDecorator,
    quantityInformation,
    truncateTitle,
  },
  { isMobile }
) => {
  const {
    addedByText,
    title,
    imgUrl,
    description,
    isAvailableOnBabylist,
    isCrowdfund,
    isGroupGift,
    isFavor,
    isFavorite,
    isSomewhatReserved,
    offers,
    subTitle,
  } = regItem

  const dimProductImage =
    !isAvailableOnBabylist &&
    !isCrowdfund &&
    !isFavor &&
    !isSomewhatReserved &&
    !isGroupGift &&
    offers?.length === 1 &&
    offers?.find((o) => o.isBabylist)

  return (
    <div
      className={`${css.layout} flex justify-start pvl phm ${
        !isMobile && 'mvl'
      }`}
    >
      <Col md={3} xs={6}>
        <RegItemImage
          className={classNames(css.productImage, dimProductImage && css.dim)}
          src={imgUrl}
          title={title}
          isFavorite={isFavorite}
          isGroupGift={isGroupGift}
          linkDecorator={regItemLinkDecorator}
          bannerContent={giftStatusContent}
          width={360}
        />
        {imageCTA}
        <span className="h6">{quantityInformation}</span>
      </Col>

      <Col md={5} xs={6}>
        {regItemLinkDecorator(
          <h4
            className={classNames(
              css.title,
              truncateTitle && css.truncatedTitle,
              'mbs mtn'
            )}
          >
            {title}
          </h4>
        )}

        {description ? (
          <RegItemNote description={description} className="hidden-xs h6 mtl" />
        ) : null}
        {subTitle ? (
          <RegItemNote description={subTitle} className="h6 mtn" />
        ) : null}

        {addedByText && (
          <AddedByNote text={addedByText} className="xsmall visible-xs mvm" />
        )}

        {offerPanel}
      </Col>

      <Col xs={12} md={4} className="pll-md">
        {description ? (
          <RegItemNote
            description={description}
            className="visible-xs h6 mtl"
          />
        ) : null}
        {actions}
        {addedByText && (
          <AddedByNote
            text={addedByText}
            className="xsmall hidden-xs mvm pull-right"
          />
        )}
      </Col>
    </div>
  )
}

RegItemCardLayout.propTypes = {
  regItem: PropTypes.shape(regItemProps).isRequired,
  regItemLinkDecorator: PropTypes.func.isRequired,

  // Components
  actions: PropTypes.object,
  giftStatusContent: PropTypes.node,
  imageCTA: PropTypes.object,
  offerPanel: PropTypes.object,
  quantityInformation: PropTypes.object,
  truncateTitle: PropTypes.bool,
}

RegItemCardLayout.defaultProps = {
  actions: null,
  giftStatusContent: null,
  imageCTA: null,
  offerPanel: null,
  quantityInformation: null,
  truncateTitle: false,
}

RegItemCardLayout.contextTypes = {
  isMobile: PropTypes.bool,
}

export default RegItemCardLayout
