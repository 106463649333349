const DEFAULT_UNIT = '$'

export const numberToCurrency = (
  numberRaw,
  options = { truncateZeroCents: false, unit: DEFAULT_UNIT }
) => {
  if (numberRaw === null || numberRaw === undefined) {
    return null
  }

  let number
  if (typeof numberRaw === 'string' || numberRaw instanceof String) {
    // Regex replaces non-digit and non-(.) characters. $4,123.56 -> 4123.56
    number = parseFloat(numberRaw.replace(/[^0-9.]/g, ''))
  } else {
    number = numberRaw
  }

  let unit = DEFAULT_UNIT
  if (typeof options.unit === 'string') {
    unit = options.unit
  } else if (options.unit === null) {
    unit = ''
  }

  let currency
  if (number < 0) {
    currency = `-${unit}${(number * -1).toFixed(2)}`
  } else {
    currency = `${unit}${number.toFixed(2)}`
  }

  if (options.truncateZeroCents) {
    currency = currency.replace(/\.00$/, '')
  }

  return currency.replace(/\B(?=(\d{3})+(?!\d))/g, ',') // Add commas
}

export const convertDollarPriceToCents = (priceInDollars) => {
  if (typeof priceInDollars === 'string' || priceInDollars instanceof String) {
    // Regex replaces non-digit and non-(.) characters. $4,123.56 -> 4123.56
    priceInDollars = parseFloat(priceInDollars.replace(/[^0-9.]/g, ''))
  }

  return parseInt(priceInDollars * 100)
}
