import React from 'react'

interface RightArrowProps {
  className?: string
}

const RightArrow: React.FC<RightArrowProps> = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M16.2754 9.55806C16.5195 9.80214 16.5195 10.1979 16.2754 10.4419L11.2754 15.4419C11.0314 15.686 10.6356 15.686 10.3916 15.4419C10.1475 15.1979 10.1475 14.8021 10.3916 14.5581L14.3246 10.625L4.16683 10.625C3.82165 10.625 3.54183 10.3452 3.54183 10C3.54183 9.65482 3.82165 9.375 4.16683 9.375L14.3246 9.375L10.3916 5.44194C10.1475 5.19786 10.1475 4.80214 10.3916 4.55806C10.6356 4.31398 11.0314 4.31398 11.2754 4.55806L16.2754 9.55806Z"
      fill="#252324"
      fillRule="evenodd"
    />
  </svg>
)

export default RightArrow
