import { Button } from 'baby-design'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import RegItemImage from 'registry/components/reg-item-image'
import { absoluteUrl, reservationsPath } from 'lib/urls'
import ScrollLockModal from 'shared/ScrollLockModal'
import { useWindowSize } from 'usehooks-ts'
import { BREAKPOINT_TABLET } from 'src/constants'
import css from './EzAddToCartConfirmationModal.styles.scss'
import { EzAddToCartConfirmationModalProps } from './EzAddToCartConfirmationModal.types'

const EzAddToCartConfirmationModal = NiceModal.create(
  ({ regItem, quantity }: EzAddToCartConfirmationModalProps) => {
    const { title, imgUrl } = regItem
    const modal = useModal()
    const windowSize = useWindowSize()
    const buttonSize = windowSize.width < BREAKPOINT_TABLET ? 'md' : 'lg'

    const handleClose = () => {
      modal.hide()
    }

    const keepShoppingButton = (
      <Button size={buttonSize} variant="secondary" onClick={handleClose}>
        Keep shopping
      </Button>
    )

    const checkoutButton = (
      <Button
        href={absoluteUrl(reservationsPath)}
        size={buttonSize}
        variant="primary"
      >
        Checkout
      </Button>
    )

    return (
      <ScrollLockModal
        showCloseButton
        handleClose={handleClose}
        isOpen={modal.visible}
        mobileVariant="basic"
        primaryButton={checkoutButton}
        secondaryButton={keepShoppingButton}
        title="Added to cart!"
      >
        <div className={css.EzAddToCartConfirmationModal}>
          <div className={css.EzAddToCartConfirmationModal__column}>
            <RegItemImage src={imgUrl} />
          </div>
          <div className={css.EzAddToCartConfirmationModal__column}>
            <h2 className={css.EzAddToCartConfirmationModal__title}>{title}</h2>
            {quantity > 1 && (
              <div className={css.EzAddToCartConfirmationModal__quantity}>
                Qty: {quantity}
              </div>
            )}
          </div>
        </div>
      </ScrollLockModal>
    )
  }
)

export default EzAddToCartConfirmationModal
