/* eslint-disable import/no-extraneous-dependencies */
import { ReactNode, useState } from 'react'
import classNames from 'classnames'
import { IconButton } from 'baby-design'
import { AddOutline, Chevron, Plus } from 'baby-design/icons'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import { RegItem } from 'src/types/regItem'
import { Offer } from 'src/types/offer'
import { track } from 'lib/analytics'
import { SelfPurchaseBuyingOption } from './components/SelfPurchaseBuyingOption'
import { BuyingOption } from './components/BuyingOption'

import css from './BuyingOptions.style.scss'
import { OutOfStockOption } from './components/OutOfStockOption'
import { useCartItemManager } from '../../utils/addToCartHelpers'

interface EditItemDetailsBuyingOptionsProps {
  onAddToCartSuccess?: () => void
  onCartItemUpdateSuccess?: (regItem: RegItem) => void
  openAddSecondaryLinkDrawer: () => void
  openEditSecondaryLinkDrawer: (offer: Offer) => void
  regItem: RegItem
  selfContained?: boolean
  toastProductImageOverride?: ReactNode
  unredeemedFundsCallback: () => void
}

export const EditItemDetailsBuyingOptions = ({
  onAddToCartSuccess,
  onCartItemUpdateSuccess,
  openAddSecondaryLinkDrawer,
  openEditSecondaryLinkDrawer,
  regItem,
  selfContained = false,
  toastProductImageOverride,
  unredeemedFundsCallback,
}: EditItemDetailsBuyingOptionsProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { offers, secondaryOffers } = regItem
  const { flagValue } = useFeatureFlag(
    '24e_ex_dayzero_self_purchase',
    'Control'
  )
  const showBuyingButtons = flagValue === 'Treatment 1'

  const isInBuyingSheetModal = selfContained

  const { addToCart, updateCartItemQuantity, removeCartItem } =
    useCartItemManager({
      regItem,
      imageOverride: toastProductImageOverride,
      unredeemedFundsCallback,
      trackingEventLocation: isInBuyingSheetModal
        ? track.EventLocation.REGISTRY_MINUS_BUYING_OPTIONS_MODAL
        : track.EventLocation.REG_ITEM_EDIT_SCREEN,
    })

  const primaryOffers = offers.filter(
    (offer) =>
      !secondaryOffers.find(
        (secondaryOffer) =>
          secondaryOffer.id === offer.id &&
          secondaryOffer.normalUrl === offer.normalUrl
      )
  )

  const quantityInCart = regItem.cartInfo?.quantity || 0

  const handleAddToCart = () => {
    setIsLoading(true)
    addToCart()
      .then(() => {
        onAddToCartSuccess?.()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleUpdateCartItemQuantity = (quantity: number) => {
    setIsLoading(true)
    updateCartItemQuantity(quantity)
      .then((updatedRegItem: RegItem) => {
        onCartItemUpdateSuccess?.(updatedRegItem)
      })
      .finally(() => setIsLoading(false))
  }

  const handleDeleteCartItem = () => {
    setIsLoading(true)
    removeCartItem()
      .then((updatedRegItem: RegItem) => {
        onCartItemUpdateSuccess?.(updatedRegItem)
      })
      .finally(() => setIsLoading(false))
  }

  const renderPrimaryOffer = (offer: Offer) => {
    if (showBuyingButtons) {
      if (offer.isBabylist && !regItem.isAvailableOnBabylist) {
        return (
          <OutOfStockOption
            isBabylistProductDiscontinued={
              regItem.isBabylistProductDiscontinued
            }
            option={offer}
            productId={regItem.productId}
            useHorizontalPadding={selfContained}
          />
        )
      }

      return (
        <SelfPurchaseBuyingOption
          handleAddToCart={handleAddToCart}
          handleRemoveCartItem={handleDeleteCartItem}
          handleUpdateCartItemQuantity={handleUpdateCartItemQuantity}
          isLoading={isLoading}
          key={offer.id || offer.url}
          offer={offer as Offer}
          quantityInCart={quantityInCart}
          regItemId={regItem.id}
          useHorizontalPadding={selfContained}
        />
      )
    }
    return <BuyingOption key={offer.id || offer.url} offer={offer as Offer} />
  }

  const addOptionButton = showBuyingButtons ? (
    <button
      className={css.BuyingOptions__add_buying_option}
      type="button"
      onClick={openAddSecondaryLinkDrawer}
    >
      <AddOutline />
      <div className={css.BuyingOptions__buying_option_name}>
        Add a buying option
      </div>
    </button>
  ) : (
    <button
      className={css.BuyingOptions__add_buying_option}
      type="button"
      onClick={openAddSecondaryLinkDrawer}
    >
      <IconButton size="sm" variant="fill-tertiary">
        <Plus />
      </IconButton>
      <div className={css.BuyingOptions__buying_option_name}>
        Add a buying option
      </div>
      <Chevron className={css.BuyingOptions__buying_option_chevron} />
    </button>
  )

  return (
    <div
      className={classNames(css.BuyingOptions, {
        [css['BuyingOptions--withBuyingButtons']]: showBuyingButtons,
      })}
    >
      <div
        className={classNames(css.BuyingOptions__heading, {
          [css['BuyingOptions__heading--selfContained']]: selfContained,
        })}
      >
        {showBuyingButtons ? 'Buying options' : 'Buying Options'}
      </div>
      <div
        className={classNames({
          [css.BuyingOptions__selfContained]: selfContained,
        })}
      >
        {primaryOffers.map(renderPrimaryOffer)}
        {secondaryOffers.map((offer) =>
          showBuyingButtons ? (
            <SelfPurchaseBuyingOption
              editOnClick={() => openEditSecondaryLinkDrawer(offer)}
              handleAddToCart={handleAddToCart}
              handleRemoveCartItem={handleDeleteCartItem}
              handleUpdateCartItemQuantity={handleUpdateCartItemQuantity}
              isLoading={isLoading}
              key={offer.id || offer.url}
              offer={offer as Offer}
              quantityInCart={quantityInCart}
              regItemId={regItem.id}
              useHorizontalPadding={selfContained}
            />
          ) : (
            <BuyingOption
              editOnClick={() => openEditSecondaryLinkDrawer(offer)}
              key={offer.id || offer.url}
              offer={offer as Offer}
            />
          )
        )}
        {secondaryOffers.length < 2 && addOptionButton}
      </div>
    </div>
  )
}
