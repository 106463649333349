import { useState } from 'react'
import { CurrencyInput, Input } from 'components/forms'
import { LinkButton } from 'components/buttons'

interface BaseSecondaryLinkFields {
  url: string
  price: string | number | null
}

interface ExistingSecondaryLink extends BaseSecondaryLinkFields {
  id: number
}

interface NewSecondaryLink extends BaseSecondaryLinkFields {
  key: number
}

type SecondaryLink = ExistingSecondaryLink | NewSecondaryLink

const MAX_SECONDARY_LINKS = 2

export const isSecondaryLinkValid = (
  url: BaseSecondaryLinkFields['url'],
  price: BaseSecondaryLinkFields['price']
): boolean => {
  return !price || (!!url && url.length > 0)
}

const isExistingSecondaryLink = (
  link: SecondaryLink
): link is ExistingSecondaryLink => {
  return (link as ExistingSecondaryLink).id !== undefined
}

const getKey = (link: SecondaryLink): string => {
  return isExistingSecondaryLink(link) ? `id-${link.id}` : `key-${link.key}`
}

export default function SecondaryLinks({
  secondaryLinks = [],
  onSecondaryLinksChange,
}: {
  secondaryLinks: Array<SecondaryLink>
  onSecondaryLinksChange: (links: Array<SecondaryLink>) => void
}) {
  const [nextLinkKey, setNextLinkKey] = useState<number>(0)

  const addNewSecondaryLink = () => {
    const newSecondaryLinks = secondaryLinks.map((link) => ({ ...link }))
    newSecondaryLinks.push({ url: '', price: '', key: nextLinkKey })
    setNextLinkKey(nextLinkKey + 1)
    onSecondaryLinksChange(newSecondaryLinks)
  }

  const handleSecondaryLinksChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const [inputType, linkIndex] = e.target.name.split('-') as [
      'url' | 'price',
      number,
    ]

    const newSecondaryLinks = secondaryLinks.map((link) => ({ ...link }))
    newSecondaryLinks[linkIndex][inputType] = e.target.value
    onSecondaryLinksChange(newSecondaryLinks)
  }

  return (
    <>
      {secondaryLinks.map((secondaryLink, i) => {
        return (
          <div className="inline-form mts" key={getKey(secondaryLink)}>
            <Input
              error={
                isSecondaryLinkValid(
                  secondaryLink['url'],
                  secondaryLink['price']
                )
                  ? null
                  : 'Alternate URL is required.'
              }
              groupClassName="flex-grow"
              label="Alternate Url"
              name={`url-${i}`}
              value={secondaryLink['url'] || ''}
              onChange={handleSecondaryLinksChange}
            />
            <CurrencyInput
              groupClassName="flex-grow"
              label="Alternate Price"
              name={`price-${i}`}
              value={secondaryLink['price'] || ''}
              onChange={handleSecondaryLinksChange}
            />
          </div>
        )
      })}
      {secondaryLinks.length < MAX_SECONDARY_LINKS && (
        <div className="text-right">
          <LinkButton
            className="h7 text-uppercase ms-hidden pan man"
            onClick={addNewSecondaryLink}
          >
            Add another url
          </LinkButton>
        </div>
      )}
    </>
  )
}
