import { isEmpty, map, fromPairs } from 'lodash'

import { SYSTEM_ERROR_KEY, SYSTEM_ERROR_MESSAGE } from '../shared/constants'

export const parseErrors = (
  resp,
  systemErrorMessage = SYSTEM_ERROR_MESSAGE
) => {
  // v3 server responses can be structured in either JSON API format or the BabyList custom responder format

  if (resp.error) {
    // BL responder example:
    //     {
    //        error: {
    //          message: 'overall-error-message-or-concatenation-of-all-field-errors',
    //          title: 'http-status-code-title',
    //          fields: { 'field1': 'error-message', 'obj': { 'field2': 'error-message'}}
    //        }
    //     }

    if (!isEmpty(resp.error.fields)) {
      // NOTE: if these errors are for fields on a redux-form, the error object keys must
      // match the form object field keys
      const { base, ...errors } = resp.error.fields
      if (base) {
        errors[SYSTEM_ERROR_KEY] = base
      } else if (resp.error.message) {
        errors[SYSTEM_ERROR_KEY] = resp.error.message
      }
      return errors
    }
    if (resp.error.message) {
      return { [SYSTEM_ERROR_KEY]: resp.error.message }
    }
  } else {
    // v3 server resp example:
    //    {
    //      errors: [ { id: 'id-of-error', title: 'short-description-of-error' } ]
    //    }

    const errors = fromPairs(
      map(resp.errors, (error) => {
        if (error.id == 'base') {
          return [SYSTEM_ERROR_KEY, error.title]
        }
        return [error.id, error.title]
      })
    )

    if (!isEmpty(errors)) {
      return errors
    }
  }

  return { [SYSTEM_ERROR_KEY]: systemErrorMessage }
}
export const parseErrorMessage = (resp) => resp?.error?.message
