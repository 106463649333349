import { TextLink } from 'baby-design'
import { Chevron } from 'baby-design/icons'
import css from './MarkPurchased.style.scss'

interface EditItemDetailsMarkPurchasedSettingProps {
  isReserved?: boolean
  openMarkPurchasedDrawer: () => void
}

export const EditItemDetailsMarkPurchasedSetting = ({
  isReserved,
  openMarkPurchasedDrawer,
}: EditItemDetailsMarkPurchasedSettingProps) => (
  <div className={css.mark_purchased}>
    <div className={css.mark_purchased__label} id="markPurchasedLabel">
      <div className={css.mark_purchased__heading}>Mark item as purchased</div>
      <div className={css.mark_purchased__subheading}>
        Use when someone forgets to update your registry or to track a self
        purchase.
      </div>
    </div>
    <TextLink
      icon={{
        side: 'right',
        icon: <Chevron className={css.mark_purchased__chevron} />,
      }}
      size="md"
      variant="default"
      weight="regular"
      onClick={openMarkPurchasedDrawer}
    >
      {isReserved ? 'Purchased' : ''}
    </TextLink>
  </div>
)
