import PropTypes from 'prop-types'
import Image from 'components/image'

const BUTTON_SIZES_BY_NAME = {
  small: 20,
  medium: 28,
  large: 32,
}

const Pinnable = (props) => (
  <div className={props.pinContainerClassName} style={{ position: 'relative' }}>
    <a
      data-pin-custom="true"
      data-pin-do="buttonPin"
      // Note: this content is server rendered and then embedded in Hello Baby via dangerouslySetInnerHTML.  When
      // this happens, click handlers are no longer bound.  Don't try to provide an onClick here, instead just let
      // the pinit.js provide it's own click handler
      href={`https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
        props.url
      )}&media=${encodeURIComponent(
        props.media
      )}&description=${encodeURIComponent(props.description)}`}
      style={{
        cursor: 'pointer',
        position: 'absolute',
        bottom: 10,
        right: 10,
        zIndex: 1,
      }}
    >
      <Image
        alt="Pinterest logo"
        lazyLoad={false}
        pinterest
        src="//assets.pinterest.com/images/pidgets/pinit_fg_en_round_red_32.png"
        style={{ width: BUTTON_SIZES_BY_NAME[props.buttonSize] }}
      />
    </a>
    {props.children}
  </div>
)

Pinnable.propTypes = {
  children: PropTypes.node.isRequired,
  buttonSize: PropTypes.oneOf(['small', 'medium', 'large']),
  description: PropTypes.string.isRequired,
  media: PropTypes.string.isRequired,
  pinContainerClassName: PropTypes.string,
  url: PropTypes.string.isRequired,
}

Pinnable.defaultProps = {
  buttonSize: 'medium',
}

export default Pinnable
