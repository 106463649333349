import * as blUrls from 'lib/urls'
import { SocialLink } from 'shared/Footer/SocialLinks/SocialLinks.types'
import {
  FacebookIcon,
  InstagramIcon,
  PinterestIcon,
  TikTokIcon,
  XIcon,
  YouTubeIcon,
} from './icons'

export const socialLinks: SocialLink[] = [
  {
    Component: TikTokIcon,
    href: blUrls.tiktokUrl,
    name: 'TikTok',
  },
  {
    Component: InstagramIcon,
    href: blUrls.instagramUrl,
    name: 'Instagram',
  },
  {
    Component: FacebookIcon,
    href: blUrls.facebookUrl,
    name: 'Facebook',
  },
  {
    Component: PinterestIcon,
    href: blUrls.pinterestUrl,
    name: 'Pinterest',
  },
  {
    Component: XIcon,
    href: blUrls.twitterUrl,
    name: 'Twitter',
  },
  {
    Component: YouTubeIcon,
    href: blUrls.youtubeUrl,
    name: 'Youtube',
  },
]
