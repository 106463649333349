import css from './SearchResultComponents.scss'
import { Heading } from '../../types'

const SearchResultHeading = ({ result }: { result: Heading }) => {
  return (
    <div className={css.SearchResults__heading}>
      <span className={css.SearchResults__headingText}>{result.title}</span>
    </div>
  )
}

export default SearchResultHeading
