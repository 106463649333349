import PropTypes from 'prop-types'

import { GroupGiftModal } from 'components/modal'
import GroupGiftReserveForm from 'registry/components/group-gift-reserve-form'

const FundThisGiftModal = ({ show, onHide, regItem }) => (
  <GroupGiftModal show={show} onHide={onHide} title="Fund This Group Gift">
    <GroupGiftReserveForm regItem={regItem} onHide={onHide} />
  </GroupGiftModal>
)

FundThisGiftModal.propTypes = {
  show: PropTypes.bool,
  regItem: PropTypes.object.isRequired,
}

export default FundThisGiftModal
