import { Component } from 'react'
import PropTypes from 'prop-types'
import Checkbox from 'components/forms/checkbox'
import CheckboxGroup from 'components/forms/checkbox-group'

export default class StoreFilters extends Component {
  static propTypes = {
    addFilter: PropTypes.func.isRequired,
    stores: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
  }

  static defaultProps = {
    selected: [],
    decorator: (component) => component,
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedStores: [...props.selected],
    }
  }

  handleSelection = ({ target: { value: storeName } }) => {
    let selectedStores
    if (this.state.selectedStores.includes(storeName)) {
      selectedStores = this.state.selectedStores.filter(
        (el) => el !== storeName
      )
    } else {
      selectedStores = [...this.state.selectedStores, storeName]
    }

    this.setState({ selectedStores }, () => {
      this.props.addFilter({ storeNames: selectedStores })
    })
  }

  clearFilter = () => {
    this.setState({ selectedStores: [] })
    this.props.addFilter({ storeNames: [] })
  }

  selectedStoreNames = () => Object.values(this.state.selectedStores)

  render() {
    const { className, decorator, label, stores } = this.props
    return (
      <>
        {label && (
          <>
            <hr className="mbl" />
            <div className="h6 text-uppercase text-bold text-light tiny mtn mbs">
              {label}
            </div>
          </>
        )}

        {decorator(
          <CheckboxGroup className={className}>
            <Checkbox
              tappable
              checked={this.selectedStoreNames().length === 0}
              label="All Stores"
              onChange={this.clearFilter}
            />
            {stores.map((store) => (
              <Checkbox
                tappable
                checked={this.state.selectedStores.includes(store.name)}
                key={store.name}
                label={store.displayName.substring(0, 17)}
                value={store.name}
                onChange={this.handleSelection}
              />
            ))}
          </CheckboxGroup>
        )}
      </>
    )
  }
}
