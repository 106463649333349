import React from 'react'

interface MagnifyingGlassProps {
  className?: string
}

const MagnifyingGlass: React.FC<MagnifyingGlassProps> = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M8.16667 2.125C4.82995 2.125 2.125 4.82995 2.125 8.16667C2.125 11.5034 4.82995 14.2083 8.16667 14.2083C11.5034 14.2083 14.2083 11.5034 14.2083 8.16667C14.2083 4.82995 11.5034 2.125 8.16667 2.125ZM0.875 8.16667C0.875 4.13959 4.13959 0.875 8.16667 0.875C12.1937 0.875 15.4583 4.13959 15.4583 8.16667C15.4583 9.95519 14.8144 11.5933 13.7457 12.8619L16.9419 16.0581C17.186 16.3021 17.186 16.6979 16.9419 16.9419C16.6979 17.186 16.3021 17.186 16.0581 16.9419L12.8619 13.7457C11.5933 14.8144 9.95519 15.4583 8.16667 15.4583C4.13959 15.4583 0.875 12.1937 0.875 8.16667Z"
      fill="black"
      fillRule="evenodd"
    />
  </svg>
)

export default MagnifyingGlass
