import { FC, ReactElement, useContext, useEffect } from 'react'
import useClickAway from 'registry/components/useClickAway/useClickAway'
import classNames from 'classnames'
import { IconButton } from 'baby-design'
import { useWindowSize } from 'lib/hooks'
import { MessageCenterContext } from '../MessageCenter/contexts'

import css from './MessageCenterDropdownButton.styles.scss'

interface MessageCenterDropdownButtonProps {
  onClick: () => unknown
  onClickAway: () => unknown
  onMouseEnter: () => unknown
  onMouseLeave: () => unknown
  title: ReactElement
}

const MAX_MOBILE_SCREEN_WIDTH = 450

const MessageCenterDropdownButton: FC<MessageCenterDropdownButtonProps> = ({
  onClick,
  onClickAway,
  onMouseEnter,
  onMouseLeave,
  title,
  children,
}) => {
  const clickAwayRef = useClickAway<HTMLDivElement>(() => onClickAway?.())
  const { isOpen } = useContext(MessageCenterContext)
  const { height: windowHeight, width: windowWidth } = useWindowSize()

  useEffect(() => {
    if (clickAwayRef.current && windowHeight && windowWidth && isOpen) {
      const menu = clickAwayRef.current.querySelector('ul')
      if (menu && windowWidth < MAX_MOBILE_SCREEN_WIDTH) {
        const menuPositionFromTop = menu.getBoundingClientRect().top
        let height = windowHeight - menuPositionFromTop

        // ensure the mobile tab bar doesn't block the inbox
        const mobileTabBar: HTMLDivElement | null = document.querySelector(
          '[data-react-class="MobileTabBar"] > div'
        )

        if (mobileTabBar) {
          height -= mobileTabBar.offsetHeight
        }

        menu.style.height = `${height}px`
      } else if (menu) {
        menu.style.height = ''
      }
    }
  }, [clickAwayRef.current, windowHeight, windowWidth, isOpen])

  return (
    <div
      className={classNames(css.MessageCenterDropdownButton, 'btn-group', {
        open: isOpen,
      })}
      ref={clickAwayRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <IconButton
        aria-expanded={isOpen}
        aria-label="Open Message Center menu."
        className={css.MessageCenterDropdownButton__button}
        data-testid="message-center"
        id="message-center"
        size="md"
        variant="standard-default"
        onClick={onClick}
      >
        {title}
      </IconButton>
      <ul
        aria-labelledby="message-center"
        className={classNames(
          css.MessageCenterDropdownButton__dropdown,
          'dropdown-menu'
        )}
        id="account-menu"
        role="menu"
      >
        {children}
      </ul>
    </div>
  )
}

export default MessageCenterDropdownButton
