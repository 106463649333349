import { SET_ACTIVE_MODAL } from '../actions'

const initialState = {
  activeModal: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_MODAL:
      return {
        ...state,
        activeModal: action.activeModal,
        modalProps: action.modalProps,
      }
    default:
      return state
  }
}

export const getActiveModal = (state) => state.activeModal
export const getActiveModalProps = (state) => state.modalProps
