import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'

import css from './SelectorButton.styles.scss'

import { SelectorButtonProps } from './SelectorButton.types'

const SelectorButton: React.FC<SelectorButtonProps> = ({
  block,
  children,
  className,
  icon,
  onClick = () => null,
  selected,
  selectedVariant,
  size,
  unavailable,
  ...props
}) => {
  const strikeThrough = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (unavailable && strikeThrough.current) {
      const parent = strikeThrough.current.parentElement as HTMLDivElement
      const rootButton = parent.parentElement as HTMLButtonElement
      const hypotenuse = Math.sqrt(
        parent.clientWidth ** 2 + rootButton.clientHeight ** 2
      )
      strikeThrough.current.style.width = `${hypotenuse}px`
      const angle = Math.atan(rootButton.clientHeight / parent.clientWidth)
      strikeThrough.current.style.transform = `rotate(-${
        angle * (180 / Math.PI)
      }deg)`
    }
  }, [unavailable, children, size, icon])

  const SelectorButtonClasses = classNames(
    css.SelectorButton,
    css[`SelectorButton--size-${size}`],
    {
      [css[`SelectorButton--icon-${icon?.size}`]]: icon?.size,
      [css.SelectorButton__left_icon]: icon?.side === 'left',
      [css.SelectorButton__right_icon]: icon?.side === 'right',
      [css.SelectorButton__block]: block,
      [css[`SelectorButton--selected--${selectedVariant}`]]: selected,
      [css['SelectorButton--unavailable']]: unavailable,
    },
    className
  )

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.currentTarget.blur()
    onClick(
      e as React.MouseEvent<HTMLButtonElement> &
        React.MouseEvent<HTMLAnchorElement>
    )
  }

  const content = (
    <div className={css.SelectorButton__wrapper}>
      {icon?.side === 'left' && icon.icon}
      <div className={css.SelectorButton__label}>{children}</div>
      {icon?.side === 'right' && icon.icon}
      <div className={css.SelectorButton__strikeThrough} ref={strikeThrough} />
    </div>
  )

  const buttonProps = {
    ...props,
  } as React.ButtonHTMLAttributes<HTMLButtonElement>
  return (
    <button
      className={SelectorButtonClasses}
      data-testid="selectorButton"
      disabled={unavailable}
      type="button"
      onClick={handleClick}
      {...buttonProps}
    >
      {content}
    </button>
  )
}

export default SelectorButton
