import { getUserOrders } from 'registry/api'
import { parseErrors } from 'lib/api-utils'

export const SET_IS_FETCHING_USER_ORDERS = 'SET_IS_FETCHING_USER_ORDERS'
export const startFetchingUserOrders = {
  type: SET_IS_FETCHING_USER_ORDERS,
  isFetchingUserOrders: true,
}
export const finishFetchingUserOrders = {
  type: SET_IS_FETCHING_USER_ORDERS,
  isFetchingUserOrders: false,
}

export const SET_HAS_ERROR = 'SET_HAS_ERROR'
export const setHasError = (hasError) => {
  return {
    type: SET_HAS_ERROR,
    hasError,
  }
}

export const SET_ORDERS = 'SET_ORDERS'
export const setOrders = (orders) => {
  return {
    type: SET_ORDERS,
    orders,
  }
}

export const fetchUserOrders = () => {
  return (dispatch) => {
    dispatch(startFetchingUserOrders)
    dispatch(setHasError(false))
    return getUserOrders()
      .then((resp) => {
        dispatch(setOrders(resp.orders))
        dispatch(finishFetchingUserOrders)
      })
      .catch((resp) => {
        dispatch(setHasError(true))
        dispatch(finishFetchingUserOrders)
        Promise.reject(parseErrors(resp))
      })
  }
}
