const CategoryPlaying = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 258.35 258.35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M176.83,109.8A.65.65,0,0,1,177,111a15.15,15.15,0,0,0,0,27.05.65.65,0,0,1-.14,1.22,15.17,15.17,0,0,1-18.64-14.77v-.15a15.15,15.15,0,0,1,15.15-15A15.43,15.43,0,0,1,176.83,109.8Z"
      fill="#d8d8d8"
    />
    <path
      d="M146.61,109.19a1.61,1.61,0,0,1,.59,1.8l-1.44,4.42a1.67,1.67,0,0,0,0,1l1.44,4.42a1.61,1.61,0,0,1-.59,1.8l-.48.35-3.27,2.38a1.62,1.62,0,0,0-.59.8l-1.44,4.42a1.59,1.59,0,0,1-1.52,1.11h-4.65a1.63,1.63,0,0,0-.95.31L130,134.72a1.61,1.61,0,0,1-1.89,0L124.3,132a1.58,1.58,0,0,0-.94-.31h-4.65a1.6,1.6,0,0,1-1.53-1.11l-1.43-4.42a1.62,1.62,0,0,0-.59-.8L111.92,123l-.52-.37a1.61,1.61,0,0,1-.58-1.8l1.44-4.42a1.67,1.67,0,0,0,0-1L110.82,111a1.61,1.61,0,0,1,.58-1.8l3.76-2.73a1.62,1.62,0,0,0,.59-.8l1.43-4.42a1.61,1.61,0,0,1,1.53-1.12h4.65a1.57,1.57,0,0,0,.94-.3l3.76-2.73a1.61,1.61,0,0,1,1.89,0l3.76,2.73a1.62,1.62,0,0,0,.95.3h4.65a1.6,1.6,0,0,1,1.52,1.12l1.44,4.42a1.62,1.62,0,0,0,.59.8Zm-8.78,6.71a8.82,8.82,0,1,0-8.82,8.82A8.81,8.81,0,0,0,137.83,115.9Z"
      fill="#d8d8d8"
    />
    <path
      d="M100,118.52a1.41,1.41,0,0,1,.78,2.39L96.76,125h0l-.74.74a1.44,1.44,0,0,0-.39,1.22l1,6.72a1.42,1.42,0,0,1-2,1.48l-6.07-3.07a1.44,1.44,0,0,0-1.28,0l-6.07,3.07a1.42,1.42,0,0,1-2-1.48l.83-5.39.21-1.33a1.41,1.41,0,0,0-.4-1.22L75,120.91a1.41,1.41,0,0,1,.78-2.39l6.72-1.09a1.42,1.42,0,0,0,1-.75l3.11-6.05a1.42,1.42,0,0,1,2.52,0l3.11,6.05a1.4,1.4,0,0,0,1,.75Z"
      fill="#d8d8d8"
    />
    <path
      d="M222.72,160.31l-6.65-.39a6,6,0,0,1-5.64-5.59,86.53,86.53,0,0,0-2.09-14.08,92.35,92.35,0,0,0-21.93-41.32,84.13,84.13,0,0,0-26-19.29,72.21,72.21,0,0,0-62.49,0,80,80,0,0,0-10.08,5.68A86.76,86.76,0,0,0,71.94,98.93,92.35,92.35,0,0,0,50,140.25a86.53,86.53,0,0,0-2.09,14.08,6,6,0,0,1-5.64,5.59l-6.65.39"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M35.63,160.2c0-51.43,41.88-99.79,93.55-99.79s93.54,48.36,93.54,99.79"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M168.74,126.2c16.31,3.06,30.06,8,39.6,14"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M50,140.25c7.6-4.85,17.88-8.95,30-12"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M96.76,125c5-.75,9.59-1.33,14.95-1.72"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M158.19,124.52c-4-.53-7.6-1-11.86-1.26"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M35.63,160.31c0,20.78,41.88,37.63,93.55,37.63s93.54-16.85,93.54-37.63"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <line
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
      x1="129.18"
      x2="129.18"
      y1="72.53"
      y2="96.13"
    />
    <line
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
      x1="170.23"
      x2="170.23"
      y1="85.47"
      y2="109.08"
    />
    <line
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
      x1="88.12"
      x2="88.12"
      y1="85.74"
      y2="109.35"
    />
    <path
      d="M168.74,126.2A15.14,15.14,0,0,0,177,138.07a.65.65,0,0,1-.14,1.22,15.17,15.17,0,0,1-18.64-14.77v-.15a15.15,15.15,0,0,1,15.15-15,15.43,15.43,0,0,1,3.49.4A.65.65,0,0,1,177,111a15.16,15.16,0,0,0-8.23,15.18Z"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <circle
      cx="129.01"
      cy="115.9"
      fill="none"
      r="8.82"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M111.92,123l-.52-.37a1.61,1.61,0,0,1-.58-1.8l1.44-4.42a1.67,1.67,0,0,0,0-1L110.82,111a1.61,1.61,0,0,1,.58-1.8l3.76-2.73a1.62,1.62,0,0,0,.59-.8l1.43-4.42a1.61,1.61,0,0,1,1.53-1.12h4.65a1.57,1.57,0,0,0,.94-.3l3.76-2.73a1.61,1.61,0,0,1,1.89,0l3.76,2.73a1.62,1.62,0,0,0,.95.3h4.65a1.6,1.6,0,0,1,1.52,1.12l1.44,4.42a1.62,1.62,0,0,0,.59.8l3.75,2.73a1.61,1.61,0,0,1,.59,1.8l-1.44,4.42a1.67,1.67,0,0,0,0,1l1.44,4.42a1.61,1.61,0,0,1-.59,1.8l-.48.35-3.27,2.38a1.62,1.62,0,0,0-.59.8l-1.44,4.42a1.59,1.59,0,0,1-1.52,1.11h-4.65a1.63,1.63,0,0,0-.95.31L130,134.72a1.61,1.61,0,0,1-1.89,0L124.3,132a1.58,1.58,0,0,0-.94-.31h-4.65a1.6,1.6,0,0,1-1.53-1.11l-1.43-4.42a1.62,1.62,0,0,0-.59-.8Z"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M87.92,109.86a1.38,1.38,0,0,1,1.26.77l3.11,6.05a1.4,1.4,0,0,0,1,.75l6.72,1.09a1.41,1.41,0,0,1,.78,2.39L96.76,125h0l-.74.74a1.44,1.44,0,0,0-.39,1.22l1,6.72a1.42,1.42,0,0,1-2,1.48l-6.07-3.07a1.44,1.44,0,0,0-1.28,0l-6.07,3.07a1.42,1.42,0,0,1-2-1.48l.83-5.39.21-1.33a1.41,1.41,0,0,0-.4-1.22L75,120.91a1.41,1.41,0,0,1,.78-2.39l6.72-1.09a1.42,1.42,0,0,0,1-.75l3.11-6.05A1.38,1.38,0,0,1,87.92,109.86Z"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
  </svg>
)

export default CategoryPlaying
