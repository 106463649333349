import { RegItem } from 'src/types/regItem'
import Image from 'components/image'
import { IconButton } from 'baby-design'
import { EditPencil } from 'baby-design/icons'
import { useState } from 'react'
import { apiV2Images } from 'lib/urls'
import Spinner from 'shared/spinner'
import fetch from 'lib/fetch'
import css from './Image.style.scss'

interface ProductInfoImageProps {
  regItem: RegItem
  updateRegItemFields: (changes: object) => void
  allowEdit?: boolean
}

export function ProductInfoImage({
  regItem,
  updateRegItemFields,
  allowEdit = true,
}: ProductInfoImageProps) {
  const [isUploadingImage, setIsUploadingImage] = useState(false)

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setIsUploadingImage(true)

      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
        fetch(apiV2Images, {
          method: 'post',
          body: JSON.stringify({ data: event.target?.result }),
        })
          .then(({ url }: { url: string }) => {
            if (url) {
              updateRegItemFields({ imgUrl: url })
            }
          })
          .catch(() => {})
          .finally(() => {
            setIsUploadingImage(false)
          })
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <div className={css.product_info_image__wrapper}>
      <div className={css.product_info_image__image}>
        <Image alt={regItem.title} src={regItem.imgUrl} />
        {isUploadingImage && (
          <Spinner
            className={css.product_info_image__spinner}
            inverse={false}
            size="large"
          />
        )}
      </div>
      {allowEdit && (
        <IconButton
          className={css.product_info_image__edit}
          size="sm"
          variant="fill-inverted"
        >
          <EditPencil />
          <input id="image-file" type="file" onChange={onImageChange} />
        </IconButton>
      )}
    </div>
  )
}
