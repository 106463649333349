import React from 'react'

interface LockOpenProps {
  className?: string
}

const LockOpen: React.FC<LockOpenProps> = ({ className }: LockOpenProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M7 2.75C5.20507 2.75 3.75 4.20507 3.75 6V6.5C3.75 6.91421 3.41421 7.25 3 7.25C2.58579 7.25 2.25 6.91421 2.25 6.5V6C2.25 3.37665 4.37665 1.25 7 1.25C9.62335 1.25 11.75 3.37665 11.75 6V8C11.75 8.08766 11.735 8.17181 11.7073 8.25H17C19.6234 8.25 21.75 10.3766 21.75 13V18C21.75 20.6234 19.6234 22.75 17 22.75H10C7.37665 22.75 5.25 20.6234 5.25 18V13C5.25 10.3766 7.37665 8.25 10 8.25H10.2927C10.265 8.17181 10.25 8.08766 10.25 8V6C10.25 4.20507 8.79493 2.75 7 2.75ZM10 9.75C8.20507 9.75 6.75 11.2051 6.75 13V18C6.75 19.7949 8.20507 21.25 10 21.25H17C18.7949 21.25 20.25 19.7949 20.25 18V13C20.25 11.2051 18.7949 9.75 17 9.75H10ZM13.5 13.75C13.9142 13.75 14.25 14.0858 14.25 14.5V16.5C14.25 16.9142 13.9142 17.25 13.5 17.25C13.0858 17.25 12.75 16.9142 12.75 16.5V14.5C12.75 14.0858 13.0858 13.75 13.5 13.75Z"
      fill="#252324"
      fillRule="evenodd"
    />
  </svg>
)

export default LockOpen
