import { useEffect } from 'react'
import onCurrentUserLoaded, {
  OnCurrentUserLoadedCallback,
} from 'shared/utils/onCurrentUserLoaded/onCurrentUserLoaded'

/**
 * Custom hook to set callback for after the current user has been loaded from the server.
 * This is useful for when you need to wait until the current user data is loaded asynchronously
 * before firing a callback (page viewed events, etc.)
 */
export const useOnCurrentUserLoaded = (
  callback: OnCurrentUserLoadedCallback
) => {
  useEffect(() => {
    const removeEventListener = onCurrentUserLoaded(callback)
    return removeEventListener
  }, [])
}

export default useOnCurrentUserLoaded
