import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'components/modal/modal'
import { ModalBody } from 'react-bootstrap'
import { getCashFundPreferences, getShippingAddress } from 'registry/reducers'
import {
  createReservation,
  removeReservationByCurrentVisitor,
} from 'registry/actions'
import {
  createReservation as updateReservation,
  submitReservation,
  submitRemoveReservation,
  removeReservation,
} from 'cart/actions'
import CrowdfundReserveForm from '../crowdfund-reserve-form'
import CrowdfundReservationModalContext from 'registry/contexts/crowdfundReservationModal'

class CrowdFundReservationModal extends Component {
  static defaultProps = {
    onHide: PropTypes.func.isRequired,
    regItem: PropTypes.object.isRequired,
    preferences: PropTypes.object,
  }

  componentDidMount() {
    this.setState({
      title: 'Give a Cash Gift',
    })
  }

  handleRemoveReservation = (reservation) => {
    return submitRemoveReservation(reservation.token).then(() => {
      this.props.removeReservationByCurrentVisitor(reservation.regItemId)
    })
  }

  handleUpdateTitle(newTitle) {
    this.setState({
      ...this.state,
      title: newTitle,
    })
  }

  render() {
    if (!this.props.show) {
      return false
    }
    return (
      <CrowdfundReservationModalContext.Provider
        value={{ setModalTitle: this.handleUpdateTitle.bind(this) }}
      >
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          title={this.state?.title}
          size="large"
        >
          <ModalBody>
            <CrowdfundReserveForm
              regItem={this.props.regItem}
              onHide={this.props.onHide}
              onCreateReservation={this.props.createReservation}
              onUpdateReservation={this.props.updateReservation}
              onRemoveReservation={this.handleRemoveReservation}
              preferences={this.props.preferences}
            />
          </ModalBody>
        </Modal>
      </CrowdfundReservationModalContext.Provider>
    )
  }
}

const mapStateToProps = (state) => ({
  preferences: getCashFundPreferences(state),
  shippingAddress: getShippingAddress(state),
})

export default connect(mapStateToProps, {
  createReservation,
  submitReservation,
  removeReservation,
  removeReservationByCurrentVisitor,
  updateReservation,
})(CrowdFundReservationModal)
