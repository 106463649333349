import { PrimaryButton } from 'components/buttons'
import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import css from './bookmarklet-button.scss'

export default class BookmarkletButton extends PureComponent {
  static propTypes = {
    bookmarkletJS: PropTypes.string.isRequired,
  }

  handleButtonClick = (e) => {
    e.preventDefault()
    alert(
      "Almost! Simply drag and drop me to your bookmarks bar! (Don't click.)"
    )
  }

  render() {
    return (
      <PrimaryButton
        href={this.props.bookmarkletJS}
        onClick={this.handleButtonClick}
        className={css.bookmarkletButton}
      >
        <i className="fa fa-heart mrs" /> <span>Add to Babylist</span>
      </PrimaryButton>
    )
  }
}
