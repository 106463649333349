import PropTypes from 'prop-types'
import OfferList from 'components/offer-list/offer-list'
import QuantityInformation from '../quantity-information'
import EditRegItemLink from '../edit-reg-item-link'
import { BestOfferLink } from '../best-offer-link/best-offer-link'
import { regItemProps } from '../reg-item-props'
import RegItemCardLayout from '../reg-item-card-layout'
import OwnerActions from '../owner-actions/owner-actions'

export const ContributorProductRegItemCard = (props, context) => {
  const { isMobile } = context
  const {
    regItem: {
      id: regItemId,
      isAvailableOnBabylist,
      isBabylistProductDiscontinued,
      isGroupGift,
      offers,
      productId,
      quantity,
      quantityNeeded,
      isEditable,
    },
    giftStatusContent,
    regItem,
  } = props

  const regItemLink = (child) => {
    if (isMobile && isEditable) {
      return <EditRegItemLink regItemId={regItemId}>{child}</EditRegItemLink>
    }

    return (
      <BestOfferLink offers={offers} isContributorView>
        {child}
      </BestOfferLink>
    )
  }

  const actions = isEditable ? (
    <OwnerActions showEditAction regItemId={regItemId} />
  ) : null

  const offerPanel = (
    <OfferList
      offers={offers}
      isAvailableOnBabylist={isAvailableOnBabylist}
      isBabylistProductDiscontinued={isBabylistProductDiscontinued}
      isContributorView
      isRegistryDiscountView={false}
      isGroupGift={isGroupGift}
      productId={productId}
      regItemId={regItemId}
      showReportLink={false}
      limit={3}
      className="mtm"
    />
  )

  return (
    <RegItemCardLayout
      regItemLinkDecorator={regItemLink}
      actions={actions}
      giftStatusContent={giftStatusContent}
      quantityInformation={
        <QuantityInformation
          quantity={quantity}
          quantityNeeded={quantityNeeded}
        />
      }
      offerPanel={offerPanel}
      regItem={regItem}
    />
  )
}

ContributorProductRegItemCard.propTypes = {
  giftStatusContent: PropTypes.string,
  regItem: PropTypes.shape(regItemProps).isRequired,
}

ContributorProductRegItemCard.contextTypes = {
  isMobile: PropTypes.bool,
}

ContributorProductRegItemCard.defaultProps = {
  giftStatusContent: null,
}

export default ContributorProductRegItemCard
