import { cloneElement } from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import css from './transitions.scss'

const Transition = ({
  children,
  transitionClass,
  transitionInSuffix,
  ...props
}) => (
  <CSSTransition
    {...props}
    children={cloneElement(children, {
      className: classNames(children.props.className, css[transitionClass]),
    })}
    classNames={{
      appearActive: css[`${transitionClass}-${transitionInSuffix}`],
      enterDone: css[`${transitionClass}-${transitionInSuffix}`],
    }}
  />
)

Transition.defaultProps = {
  transitionInSuffix: 'in',
  timeout: 400,
}

export const Drawer = (props) => (
  <Transition {...props} transitionClass="drawer" />
)
