import PropTypes from 'prop-types'
import classNames from 'classnames'
import { bowserFlags } from 'lib/bowser'
import Card from 'components/card'
import RegItemImage from 'registry/components/reg-item-image'
import css from 'components/card/card.scss'

const GroupGiftPreviewCard = ({ imgUrl, isGroupGift, isFavorite, title }) => (
  <Card
    className={classNames(
      'pal flex space-between',
      css.tightCorner,
      css.mediaCardResponsive
    )}
  >
    <RegItemImage
      width={bowserFlags.mobile ? 200 : 300}
      src={imgUrl}
      isGroupGift={isGroupGift}
      isFavorite={isFavorite}
      className="center-block img-responsive media-center"
    />
    <p className="h5 wrap pll pln-md mbn">{title}</p>
  </Card>
)

GroupGiftPreviewCard.propTypes = {
  isFavorite: PropTypes.bool.isRequired,
  isGroupGift: PropTypes.bool.isRequired,
  imgUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default GroupGiftPreviewCard
