import { connect } from 'react-redux'
import OtherStoreOffers from './OtherStoreOffers'
import { PurchasedAction } from './purchased-action'
import { openReservePurchasedGiftModal } from '../../../modals/actions'

interface ExternalProductOfferProps {
  offers: Array<any>
  regItem: any
  registry: any
  trackedCreateReservation: any
  openReservePurchasedGiftModal: any
}

const ExternalProductOffer = ({
  offers,
  regItem,
  registry,
  trackedCreateReservation,
  openReservePurchasedGiftModal,
}: ExternalProductOfferProps) => {
  const handleOpenModal = () => {
    openReservePurchasedGiftModal(regItem, registry, trackedCreateReservation)
  }

  return (
    <>
      <OtherStoreOffers offers={offers} regItem={regItem} registry={registry} />
      <PurchasedAction openReservePurchasedGiftModal={handleOpenModal} />
    </>
  )
}

export default connect(null, {
  openReservePurchasedGiftModal,
})(ExternalProductOffer)
