import EmbedCollapsingSection, {
  TypePostEmbedCollapsingSectionFields,
} from '../EmbedCollapsingSection/EmbedCollapsingSection'

import { AnchoredH } from '../Utilities'

interface CollapsingSectionEntryReference {
  fields: TypePostEmbedCollapsingSectionFields
  sys: Record<string, unknown>
}

interface TypePostFaqsAndTipsSectionFields {
  heading: string
  collapsingItem: CollapsingSectionEntryReference[]
}

interface FaqsAndTipsSectionProps {
  fields: TypePostFaqsAndTipsSectionFields
}

const FaqsAndTipsSection: React.FC<FaqsAndTipsSectionProps> = ({
  fields,
}: FaqsAndTipsSectionProps) => (
  <section>
    <AnchoredH headingType="h2" textForAnchor={fields.heading}>
      {fields.heading}
    </AnchoredH>

    {fields.collapsingItem.map(({ fields: collapsingSectionFields }) => (
      <EmbedCollapsingSection
        fields={collapsingSectionFields}
        key={collapsingSectionFields.heading}
      />
    ))}
  </section>
)

export default FaqsAndTipsSection
