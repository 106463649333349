import React from 'react'

interface GiftProps {
  className?: string
  filled?: boolean
}

const Gift: React.FC<GiftProps> = ({ className, filled }: GiftProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {filled ? (
      <>
        <path
          clipRule="evenodd"
          d="M5.76091 6.25C5.4375 5.74485 5.25 5.14432 5.25 4.5C5.25 2.70507 6.70507 1.25 8.5 1.25H8.58023C10.0509 1.25 11.3474 2.21466 11.77 3.6233L12 4.38993L12.23 3.6233C12.6526 2.21466 13.9491 1.25 15.4198 1.25H15.5C17.2949 1.25 18.75 2.70507 18.75 4.5C18.75 5.14432 18.5625 5.74485 18.2391 6.25H20C21.5188 6.25 22.75 7.48122 22.75 9C22.75 10.2588 21.9043 11.32 20.75 11.6465V11.65H3.25V11.6465C2.09575 11.32 1.25 10.2588 1.25 9C1.25 7.48122 2.48122 6.25 4 6.25H5.76091ZM6.75 4.5C6.75 3.5335 7.5335 2.75 8.5 2.75H8.58023C9.38847 2.75 10.101 3.28016 10.3333 4.05432L10.992 6.25H8.5C7.5335 6.25 6.75 5.4665 6.75 4.5ZM15.5 6.25C16.4665 6.25 17.25 5.4665 17.25 4.5C17.25 3.5335 16.4665 2.75 15.5 2.75H15.4198C14.6115 2.75 13.899 3.28016 13.6667 4.05432L13.008 6.25H15.5Z"
          fill="#252324"
          fillRule="evenodd"
        />
        <path
          d="M3.25 17.2321L3.25 13.15H11.25V22.75H8.76788C7.95505 22.75 7.29944 22.75 6.76853 22.7066C6.22189 22.662 5.74175 22.5676 5.29754 22.3413C4.59193 21.9818 4.01825 21.4081 3.65873 20.7025C3.43238 20.2582 3.33803 19.7781 3.29336 19.2315C3.24999 18.7006 3.24999 18.0449 3.25 17.2321Z"
          fill="#252324"
        />
        <path
          d="M15.2321 22.75H12.75V13.15H20.75V17.2321C20.75 18.045 20.75 18.7006 20.7066 19.2315C20.662 19.7781 20.5676 20.2582 20.3413 20.7025C19.9817 21.4081 19.4081 21.9818 18.7025 22.3413C18.2582 22.5676 17.7781 22.662 17.2315 22.7066C16.7006 22.75 16.045 22.75 15.2321 22.75Z"
          fill="#252324"
        />
      </>
    ) : (
      <path
        clipRule="evenodd"
        d="M8.5 2.75C7.5335 2.75 6.75 3.5335 6.75 4.5C6.75 5.4665 7.5335 6.25 8.5 6.25H10.992L10.3333 4.05432C10.101 3.28016 9.38847 2.75 8.58023 2.75H8.5ZM11.25 7.75V10.25H4C3.30964 10.25 2.75 9.69036 2.75 9C2.75 8.30964 3.30964 7.75 4 7.75H11.25ZM5.76091 6.25C5.4375 5.74485 5.25 5.14432 5.25 4.5C5.25 2.70507 6.70507 1.25 8.5 1.25H8.58023C10.0509 1.25 11.3474 2.21466 11.77 3.6233L12 4.38993L12.23 3.6233C12.6526 2.21466 13.9491 1.25 15.4198 1.25H15.5C17.2949 1.25 18.75 2.70507 18.75 4.5C18.75 5.14432 18.5625 5.74485 18.2391 6.25H20C21.5188 6.25 22.75 7.48122 22.75 9C22.75 10.2588 21.9043 11.32 20.75 11.6465V17.2321C20.75 18.045 20.75 18.7006 20.7066 19.2315C20.662 19.7781 20.5676 20.2582 20.3413 20.7025C19.9818 21.4081 19.4081 21.9818 18.7025 22.3413C18.2582 22.5676 17.7781 22.662 17.2315 22.7066C16.7006 22.75 16.045 22.75 15.2321 22.75H8.76788C7.95505 22.75 7.29944 22.75 6.76853 22.7066C6.2219 22.662 5.74175 22.5676 5.29754 22.3413C4.59193 21.9818 4.01825 21.4081 3.65873 20.7025C3.43238 20.2582 3.33803 19.7781 3.29336 19.2315C3.24999 18.7006 3.24999 18.0449 3.25 17.2321L3.25 11.6465C2.09575 11.32 1.25 10.2588 1.25 9C1.25 7.48122 2.48122 6.25 4 6.25H5.76091ZM4.75 11.75V17.2C4.75 18.0525 4.75058 18.6467 4.78838 19.1093C4.82546 19.5632 4.8946 19.824 4.99524 20.0215C5.21095 20.4448 5.55516 20.789 5.97852 21.0048C6.17604 21.1054 6.4368 21.1745 6.89068 21.2116C7.35331 21.2494 7.94755 21.25 8.8 21.25H11.25L11.25 11.75H4.75ZM12.75 11.75L12.75 21.25H15.2C16.0525 21.25 16.6467 21.2494 17.1093 21.2116C17.5632 21.1745 17.824 21.1054 18.0215 21.0048C18.4448 20.789 18.789 20.4448 19.0048 20.0215C19.1054 19.824 19.1745 19.5632 19.2116 19.1093C19.2494 18.6467 19.25 18.0525 19.25 17.2V11.75H12.75ZM12.75 7.75V10.25H20C20.6904 10.25 21.25 9.69036 21.25 9C21.25 8.30964 20.6904 7.75 20 7.75H12.75ZM15.5 6.25C16.4665 6.25 17.25 5.4665 17.25 4.5C17.25 3.5335 16.4665 2.75 15.5 2.75H15.4198C14.6115 2.75 13.899 3.28016 13.6667 4.05432L13.008 6.25H15.5Z"
        fill="#252324"
        fillRule="evenodd"
      />
    )}
  </svg>
)

export default Gift
