import PropTypes from 'prop-types'
import classNames from 'classnames'
import { InfoButton } from 'components/buttons'
import { absoluteUrl, storePath } from 'lib/urls'
import css from './empty-cart.scss'

const EmptyCart = ({ className }) => {
  return (
    <div className={classNames('text-center', className)}>
      <p className="text-bold">Your cart is empty</p>
      <p className={css.subtitle}>
        Discover the best baby gear to give and get.
      </p>
      <InfoButton pill href={absoluteUrl(storePath)}>
        Browse our Shop
      </InfoButton>
    </div>
  )
}

EmptyCart.propTypes = {
  className: PropTypes.string,
}

export default EmptyCart
