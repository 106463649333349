import { Component } from 'react'
import PropTypes from 'prop-types'
import { differenceInMilliseconds } from 'date-fns'

const withExpiration = (WrappedComponent) => {
  return class HOC extends Component {
    constructor(props) {
      super(props)
      this.state = {
        expired: false,
      }
      this.timerId = null
    }

    expirationTimer = () => {
      const expires_at = new Date(this.props.expiresAt)
      const current_time = new Date()
      const expires_in = differenceInMilliseconds(expires_at, current_time)

      if (expires_in > 0) {
        this.timerId = setTimeout(() => {
          this.setState({ expired: true })
        }, expires_in)
      } else {
        this.setState({ expired: true })
      }
    }

    componentDidMount() {
      this.expirationTimer()
    }

    componentWillUnmount() {
      clearTimeout(this.timerId)
    }

    render() {
      return !this.state.expired ? <WrappedComponent {...this.props} /> : null
    }
  }
}

withExpiration.propTypes = {
  expiresAt: PropTypes.string.isRequired,
}

export default withExpiration
