import PropTypes from 'prop-types'
import * as urls from 'lib/urls'
import css from './banner.scss'

const OutOfAreaBanner = ({ country, showBabyListButton }) => (
  <div className={css.infoBanner}>
    <div className="container">
      <div className={css.flexRow}>
        <div className={css.middle}>
          Note: The Babylist shop does not ship to&nbsp;
          {country}.
          {showBabyListButton && (
            <>
              &nbsp;To add items that can be shipped to you, install the{' '}
              <a
                className="text-inverse text-underline"
                href={urls.addItemsPath}
              >
                Babylist Button
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  </div>
)

OutOfAreaBanner.propTypes = {
  country: PropTypes.string.isRequired,
  showBabyListButton: PropTypes.bool.isRequired,
}

export default OutOfAreaBanner
