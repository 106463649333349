import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { saveUserEvent } from '../../actions'
import Modal from 'components/modal/modal'
import { ModalBody } from 'react-bootstrap'

import { openBookmarkletUsageModal } from '../../modals/actions'
import DraggableBookmarkButton from 'shared/add-babylist-button/draggable-bookmark-button'
import { getBookmarkletJS } from '../../reducers'
import Link from 'components/link'

import css from './bookmarklet-instructions-modal.scss'
import InstallInstructionsByBrowser from 'shared/add-babylist-button/install-instructions-by-browser'
import { AddAnyStoreIcon } from 'components/icons'
import { ADD_BABYLIST_BUTTON_EVENT } from '../../constants'

const BookmarkletInstallInstructionsModal = ({
  show,
  onHide,
  setBookmarkletAsInstalled,
  openBookmarkletUsageModal,
  url,
  storeDisplayName,
  bookmarkletJS,
}) => {
  const handleInstall = () => {
    setBookmarkletAsInstalled()
    openBookmarkletUsageModal({ url, storeDisplayName })
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      icon={<AddAnyStoreIcon />}
      title="Wait a minute!"
    >
      <ModalBody>
        <div className={classNames(css.topPanel, css.promoBackground)}>
          <div className="h3">Don't forget the Babylist Button!</div>
          <p>
            You'll need it to add items to your registry while on the go. Drag
            the button below to your Bookmarks Bar to get started.
          </p>

          <DraggableBookmarkButton
            bookmarkletJS={bookmarkletJS}
            onInstall={handleInstall}
          />

          <Link onClick={handleInstall}>
            I've already added the Babylist button
          </Link>
        </div>

        <hr className={css.line} />

        <InstallInstructionsByBrowser hideTryItOut />
      </ModalBody>
    </Modal>
  )
}

BookmarkletInstallInstructionsModal.propTypes = {
  show: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  setBookmarkletAsInstalled: PropTypes.func.isRequired,
  openBookmarkletUsageModal: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  storeDisplayName: PropTypes.string.isRequired,
  bookmarkletJS: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
  bookmarkletJS: getBookmarkletJS(state),
})

export default connect(mapStateToProps, {
  setBookmarkletAsInstalled: () =>
    saveUserEvent(ADD_BABYLIST_BUTTON_EVENT, true),
  openBookmarkletUsageModal,
})(BookmarkletInstallInstructionsModal)
