import { browserName } from 'lib/bowser'
import Image from 'components/image'
import { CHROME, FIREFOX, MS_EDGE, SAFARI } from 'shared/constants'

const CLOUDINARY_BASE_URL = '//res.cloudinary.com/babylist/image/upload/q_auto'

const chromeWindowsInstructions = (
  <span>
    At the top right of your Chrome browser, click the "More" icon{' '}
    <Image
      src={`${CLOUDINARY_BASE_URL}/v1502212074/ic_more_vert_grey600_24dp_zghppr.svg`}
      width="18"
      height="18"
      alt="More"
    />
    . Select <b>Bookmarks</b> and make sure there's a checkmark next to
    <b>Show bookmarks bar</b>
  </span>
)
const chromeMacInstructions = (
  <span>
    In Chrome's top menu, go to <b>View</b> and make sure there's a checkmark
    next to <b>Always Show Bookmarks Bar</b>.
  </span>
)
const firefoxWindowsInstructions = (
  <span>
    At the top right of your Firefox browser, click the menu icon{' '}
    <Image
      src={`${CLOUDINARY_BASE_URL}/v1619102794/icons/firefox_hamburger_menu_button.png`}
      width="18"
      height="18"
      alt="Menu"
    />{' '}
    and choose <b>Bookmarks</b> If it says "Hide bookmarks toolbar" you're all
    set.
  </span>
)
const firefoxMacInstructions = (
  <span>
    In Firefox's menu, go to <b>View > Toolbars > Bookmarks Toolbar</b> and make
    sure there's a checkmark next to <b>Always Show</b>.
  </span>
)
const safariInstructions = (
  <span>
    In Safari's menu, go to <b>View</b> (if it says "Hide Favorites Bar" you're
    all set).
  </span>
)
const edgeInstructions = (
  <span>
    In Edge's menu, go to <b>View</b> and make sure there's a checkmark next to{' '}
    <b>Always Show Favorites Bar</b>
  </span>
)
const defaultInstructions = (
  <span>Check that your browser&apos;s Favorites bar is showing.</span>
)

const chromeInstallationGif = `${CLOUDINARY_BASE_URL}/install-bookmarklet/install-bookmarklet-chrome-2023`
const firefoxInstallationGif = `${CLOUDINARY_BASE_URL}/install-bookmarklet/install-bookmarklet-firefox-2023`
const safariInstallationGif = `${CLOUDINARY_BASE_URL}/install-bookmarklet/install-bookmarklet-safari-2023`
const edgeInstallationGif = `${CLOUDINARY_BASE_URL}/install-bookmarklet/install-bookmarklet-edge-2023`

const usageGif = `${CLOUDINARY_BASE_URL}/v1537392364/discovery/button-usage.gif`

export const instructionsForBrowser = ({ browser, isWindows }) => {
  switch (browser) {
    case CHROME:
      return {
        instructions: isWindows
          ? chromeWindowsInstructions
          : chromeMacInstructions,
        installationGif: chromeInstallationGif,
        usageGif,
        bookmarksBarTitle: 'Bookmarks',
      }
    case FIREFOX:
      return {
        instructions: isWindows
          ? firefoxWindowsInstructions
          : firefoxMacInstructions,
        installationGif: firefoxInstallationGif,
        usageGif,
        bookmarksBarTitle: 'Bookmarks',
      }
    case SAFARI:
      return {
        instructions: safariInstructions,
        installationGif: safariInstallationGif,
        usageGif,
        bookmarksBarTitle: 'Favorites',
      }
    case MS_EDGE:
      return {
        instructions: edgeInstructions,
        installationGif: edgeInstallationGif,
        usageGif,
        bookmarksBarTitle: 'Favorites',
      }
    default:
      return {
        instructions: defaultInstructions,
        installationGif: firefoxInstallationGif,
        usageGif,
        bookmarksBarTitle: 'Bookmarks',
      }
  }
}

export const bookmarksBarTitle = () =>
  instructionsForBrowser({ browser: browserName }).bookmarksBarTitle
