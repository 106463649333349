import { FC } from 'react'
import classNames from 'classnames'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { absoluteUrl, registryHomePath, indexPath } from 'lib/urls'

import css from './Logo.styles.scss'
import LogoSvg from './Logo.svg'

interface LogoProps {
  className?: string
  trackClick: (target: string) => void
}

const Logo: FC<LogoProps> = ({ className, trackClick }) => {
  const [currentUser] = useCurrentUser()

  const linkUrl = currentUser
    ? absoluteUrl(registryHomePath)
    : absoluteUrl(indexPath)

  return (
    <a
      className={classNames(css.Logo, className)}
      href={linkUrl}
      onClick={() => trackClick('Logo')}
    >
      <LogoSvg className={css.Logo__image} />
      <span className="sr-only">Babylist - Best Baby Registry</span>
    </a>
  )
}

export default Logo
