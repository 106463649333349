/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Link from 'components/link'
import { RegItemShape } from 'registry/lib/prop-types'

const StoreIcon = ({ storeIconName, className }) => (
  <span
    className={classNames(
      'icon-offer',
      `icon-offer-${storeIconName}`,
      className
    )}
  />
)

StoreIcon.propTypes = {
  storeIconName: PropTypes.string.isRequired,
  className: PropTypes.string,
}

StoreIcon.defaultProps = {
  className: undefined,
}

const StoreLink = ({
  url,
  viglinkEnabled,
  storeIconName,
  storeDisplayName,
  openRedirectModal,
  children,
  isContributorView,
  regItem,
  responsive,
}) => {
  if (
    storeDisplayName === 'Babylist' ||
    isContributorView ||
    !openRedirectModal ||
    !regItem
  ) {
    const linkRel = []

    if (!viglinkEnabled) {
      linkRel.push('norewrite')
    }

    if (url && !url.includes('babylist.com')) {
      linkRel.push('nofollow')
    }

    return (
      <Link
        newWindow
        className={classNames('link-offer mrs', {
          'offer-responsive': responsive,
        })}
        rel={linkRel.join(' ')}
        url={url}
      >
        <div className="offer-layout-wrapper">
          <StoreIcon storeIconName={storeIconName} />
          <span className="offer-store">{storeDisplayName}</span>
          <span className="offer-price">{children}</span>
        </div>
      </Link>
    )
  }

  return (
    <Link
      className="link-offer mrs"
      style={{ width: '100%', cursor: 'pointer' }}
      onClick={() =>
        openRedirectModal({
          url,
          storeDisplayName,
        })
      }
      onKeyDown={() =>
        openRedirectModal({
          url,
          storeDisplayName,
        })
      }
    >
      <div className="offer-layout-wrapper">
        <StoreIcon storeIconName={storeIconName} />
        <span className="offer-store">{storeDisplayName}</span>
        <span className="offer-price">{children}</span>
      </div>
    </Link>
  )
}

StoreLink.propTypes = {
  children: PropTypes.node.isRequired,
  openRedirectModal: PropTypes.func.isRequired,
  storeIconName: PropTypes.string.isRequired,
  storeDisplayName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  viglinkEnabled: PropTypes.bool.isRequired,
  isContributorView: PropTypes.bool,
  regItem: PropTypes.shape(RegItemShape),
}

StoreLink.defaultProps = {
  isContributorView: false,
  regItem: null,
}

const StoreDisplay = ({ storeIconName, storeDisplayName, children }) => (
  <div className="offer-layout-wrapper">
    <StoreIcon storeIconName={storeIconName} />
    <span className="offer-store">{storeDisplayName}</span>
    <span className="offer-price">{children}</span>
  </div>
)

StoreDisplay.propTypes = {
  storeIconName: PropTypes.string.isRequired,
  storeDisplayName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

const Offer = ({ decorator, disableLink, ...rest }) => {
  let display
  if (disableLink) {
    display = <StoreDisplay {...rest} />
  } else {
    display = <StoreLink {...rest} />
  }
  return <div className="flex-row">{decorator(display)}</div>
}

export const offerProps = {
  id: PropTypes.number,
  disableLink: PropTypes.bool,
  url: PropTypes.string,
  normalUrl: PropTypes.string,
  openRedirectModal: PropTypes.func,
  price: PropTypes.any,
  regItem: PropTypes.shape(RegItemShape),
  storeDisplayName: PropTypes.string.isRequired,
  storeIconName: PropTypes.string.isRequired,
  isBabylist: PropTypes.bool,
  isContributorView: PropTypes.bool,
  viglinkEnabled: PropTypes.bool,
  decorator: PropTypes.func,
}

Offer.propTypes = offerProps

Offer.defaultProps = {
  decorator: (component) => component,
  disableLink: false,
  id: undefined,
  isBabylist: false,
  isContributorView: false,
  regItem: null,
  openRedirectModal: null,
}

export default Offer
