import classNames from 'classnames'

import Link from 'components/link'
import { expectfulPostPath, helloBabyPostPath } from 'lib/urls'
import Image from 'components/image'
import css from './PostCard.styles.scss'

interface PostCardProps {
  cardSize: string
  className?: string
  content?: unknown
  image?: string
  navigateWithRouter?: boolean
  numColumns?: number
  post?: Post
  slug: string
  teaserText: string
  title: string
  topic?: Topic
}

const PostCard = ({
  post,
  navigateWithRouter = true,
  cardSize,
  className,
  slug,
  topic,
  image,
  title,
  teaserText,
}: PostCardProps) => {
  const targetUrl = post?.expectful
    ? expectfulPostPath(post && post.slug)
    : helloBabyPostPath((post && post.slug) || slug)
  const navigationProp = {
    [!navigateWithRouter ? 'url' : 'to']: targetUrl,
  }

  return (
    <div
      className={classNames(
        css.postCard,
        {
          [css.small]: cardSize !== 'medium',
          [css.medium]: cardSize === 'medium',
        },
        className
      )}
      title={title}
    >
      <div className={classNames(css.postCardContainer)}>
        <Image
          alt={title}
          className={classNames(
            css.featuredImage,
            'hover-overlay hover-lighten'
          )}
          src={image}
        />
        <div className={classNames(css.postInfo, 'flex-vertical-center')}>
          <div>
            <Link
              {...navigationProp}
              className={classNames(
                css.postTitle,
                'unset-all h6',
                'text-body',
                topic ? 'mtn mbn' : 'mts'
              )}
            >
              {title}
            </Link>
            {teaserText ? (
              <p className={classNames(css.textSerifGuides, 'mbn')}>
                {teaserText}
              </p>
            ) : null}
            {topic && topic.slug === 'sponsored' ? (
              <div
                className={classNames(
                  css.postTopic,
                  'tiny text-light text-bold mtn mbn text-uppercase'
                )}
              >
                {topic.name}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostCard
