import * as React from 'react'
import classnames from 'classnames'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import css from './styles.scss'

export const ChipVariants = {
  DEFAULT: 'default',
  SUCCESS: 'success',
} as const

export const ChipSizes = {
  DEFAULT: 'default',
  SMALL: 'small',
} as const

export interface ChipProps
  extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'children'> {
  /**
   * The text to display inside of the chip.
   */
  label: string
  /**
   * The background color of the chip.
   * @default ChipVariants.DEFAULT
   * @see ChipVariants
   */
  variant?: (typeof ChipVariants)[keyof typeof ChipVariants]
  /**
   * The size of the chip.
   * @default ChipSizes.DEFAULT
   * @see ChipSizes
   */
  size?: (typeof ChipSizes)[keyof typeof ChipSizes]
}

export default function Chip({
  className,
  label,
  size = ChipSizes.DEFAULT,
  variant = ChipVariants.DEFAULT,
  ...rest
}: ChipProps) {
  const { flagValue: isRebrand } = useFeatureFlag(
    'guides-above-the-fold',
    false
  )
  let chipStyle = {}
  let chipSize = {}

  switch (variant) {
    case ChipVariants.SUCCESS:
      chipStyle = css.success
      break
    default:
      break
  }

  switch (size) {
    case ChipSizes.SMALL:
      chipSize = css.small
      break
    default:
      break
  }

  return (
    <span
      className={classnames(
        'well man inline-block',
        css.root,
        chipStyle,
        chipSize,
        className,
        { wellRebrand: isRebrand }
      )}
      {...rest}
    >
      {label}
    </span>
  )
}
