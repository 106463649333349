/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import { isUndefined } from 'lodash'
import classnames from 'classnames'

import PostCard from '../PostCard'

import css from './post-card-row.scss'

const PostCardRow = (props) => {
  const {
    className,
    hideLastPostSm,
    navigateWithRouter,
    posts,
    showTopics,
    columns,
  } = props

  return (
    <div className={classnames(className, css.postCardRowContainer)}>
      {posts
        .filter((post) => post !== undefined)
        .map((post, idx) => [
          <PostCard
            className={classnames(
              css.postCardRowCard,
              css[`columns--${columns}`],
              {
                'visible-xs': hideLastPostSm && idx === posts.length - 1,
              }
            )}
            image={post.featuredImage ? post.featuredImage.url : null}
            key={`post_card_${post.id}`}
            navigateWithRouter={
              !isUndefined(navigateWithRouter) ? navigateWithRouter : true
            }
            post={post}
            teaserText={post.teaserText}
            title={post.title}
            topic={
              showTopics && post.meta.topics
                ? post.meta.topics.find((t) => t.slug === 'sponsored') ||
                  post.meta.topics[0]
                : null
            }
          />,
        ])}
    </div>
  )
}

PostCardRow.propTypes = {
  className: PropTypes.string,
  navigateWithRouter: PropTypes.bool,
  posts: PropTypes.array.isRequired,
  showTopics: PropTypes.bool,
  hideLastPostSm: PropTypes.bool,
  columns: PropTypes.number,
}

PostCardRow.defaultProps = {
  className: null,
  navigateWithRouter: false,
  hideLastPostSm: false,
  showTopics: true,
  columns: 4,
}

// eslint-disable-next-line import/no-default-export
export default PostCardRow
