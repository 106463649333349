import {
  REG_ITEM_RESERVATION_STATUS_AVAILABLE,
  REG_ITEM_RESERVATION_STATUS_RESERVED,
  REG_ITEM_RESERVATION_STATUS_PURCHASED,
  FAVORITE_REG_ITEM,
} from '../actions/reg-items-actions'

export const filterByPriceRange = (regItems, filters) => {
  // The Items within the range. Items that do NOT have prices should not be included
  const priceRanges = filters.priceRange
  regItems = regItems.filter(
    (regItem) =>
      regItem.isElectronicGiftCard ||
      regItem.isFavor ||
      regItem.isCashFund ||
      regItem.isCrowdfund ||
      regItem.isGiftCard ||
      regItem.isGroupGift ||
      regItem.offers.find((offer) => {
        if (priceRanges && priceRanges.length == 0) {
          return true
        }
        if (filters.babylistStoreView && !offer.isBabylist) {
          // only check prices for Babylist store offers on Babylist store view
          return false
        }
        if (
          filters.storeNames &&
          !filters.storeNames.includes(offer.storeName)
        ) {
          // only check prices for stores that have been filtered
          return false
        }
        return priceRanges.find(({ min, max }) => {
          if (min && max) {
            return offer.price && offer.price >= min && offer.price <= max
          }
          if (min) {
            return offer.price && offer.price >= min
          }
          if (max) {
            return offer.price && offer.price < max
          }
          return false
        })
      })
  )
  return regItems
}

export const filterByStoreNames = (regItems, storeNames) =>
  regItems.filter((regItem) =>
    regItem.offers.find((offer) => storeNames.includes(offer.storeName))
  )

export const filterByGiftView = (filteredRegItems, giftView) => {
  if (giftView === REG_ITEM_RESERVATION_STATUS_AVAILABLE) {
    // Items that are not completely reserved should be included
    filteredRegItems = filteredRegItems.filter(
      (regItem) =>
        (regItem.isGroupGift &&
          !regItem.isGoalComplete &&
          !regItem.isReserved) ||
        !regItem.isReserved
    )
  } else if (giftView === REG_ITEM_RESERVATION_STATUS_RESERVED) {
    // Items that are have at least reservation against them should be included
    filteredRegItems = filteredRegItems.filter(
      (regItem) =>
        (regItem.isGroupGift && regItem.fundedAmount > 0) ||
        regItem.isReserved ||
        regItem.isSomewhatReserved
    )
  } else if (giftView === REG_ITEM_RESERVATION_STATUS_PURCHASED) {
    // Items that are have at least reservation against them should be included
    filteredRegItems = filteredRegItems.filter(
      (regItem) =>
        (regItem.isGroupGift && regItem.fundedAmount > 0) ||
        regItem.quantityPurchased > 0
    )
  } else if (giftView === FAVORITE_REG_ITEM) {
    filteredRegItems = filteredRegItems.filter((regItem) => regItem.isFavorite)
  }
  return filteredRegItems
}

export const filterByFavorites = (filteredRegItems) => {
  return filteredRegItems.filter((regItem) => regItem.isFavorite)
}

export const filterByGroupGifts = (filteredRegItems) =>
  filteredRegItems.filter((regItem) => regItem.isGroupGift)

export const filterByBabylistStoreView = (filteredRegItems) =>
  filteredRegItems.filter((regItem) =>
    regItem.offers.some((offer) => offer.isBabylist)
  )
