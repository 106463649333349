import { apiV3UnifiedSearchPath } from 'lib/urls'
import * as Sentry from '@sentry/react'

export const fetchUnifiedSearchResults = async (
  query: string,
  eventLocation: string
) => {
  try {
    const path = apiV3UnifiedSearchPath(query, eventLocation)
    const response = await fetch(path)
    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    return { results: [], events: [] }
  }
}
