import React from 'react'
import { ContributionInfoProps } from 'registry/components/CrowdfundReserveFormFlow/components/RecordContribution/components/ContributionInfo/ContributionInfo.types'
import ReservationAttribute from 'registry/components/CrowdfundReserveFormFlow/components/RecordContribution/components/ReservationAttribute/ReservationAttribute'
import { numberToCurrency } from 'lib/money'
import { Button, IconButton } from 'baby-design'
import { EditPencil } from 'baby-design/icons'
import css from './ContributionInfo.styles.scss'
import CollapsiblePaymentInfo from 'registry/components/CrowdfundReserveFormFlow/components/RecordContribution/components/CollapsiblePaymentInfo/CollapsiblePaymentInfo'

const ContributionInfo: React.FC<ContributionInfoProps> = ({
  onEdit,
  paymentPreference,
  reservation,
}) => {
  const handleEdit = () => onEdit && onEdit()

  const amount = numberToCurrency(reservation.giftAmount?.toString(), {
    truncateZeroCents: true,
    unit: '$',
  })

  const showPaymentInfo = paymentPreference.key !== 'cash'

  return (
    <div className={css.ContributionInfo}>
      <div className={css.ContributionInfo__Content}>
        <ReservationAttribute
          label="From"
          primaryValue={reservation.name?.toString()}
          secondaryValue={reservation.email?.toString()}
        />
        <ReservationAttribute label="Gift amount" primaryValue={amount} />
        <ReservationAttribute
          label="Payment method"
          primaryValue={paymentPreference?.name}
        />
        {showPaymentInfo && (
          <CollapsiblePaymentInfo preference={paymentPreference} />
        )}
      </div>
      <div className={css.ContributionInfo__EditIconContainer}>
        <div className="HiddenMobile">
          <Button
            className={css.ContributionInfo__EditIcon}
            icon={{
              side: 'left',
              icon: <EditPencil />,
              size: 'sm',
            }}
            size="sm"
            variant="secondary"
            onClick={handleEdit}
          >
            Edit
          </Button>
        </div>
        <div className="MobileOnly">
          <IconButton
            className={css.ContributionInfo__EditIcon}
            variant="fill-secondary"
            size="sm"
            onClick={handleEdit}
          >
            <EditPencil />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default ContributionInfo
