import PropTypes from 'prop-types'
import classNames from 'classnames'
import { InfoButton } from 'components/buttons'
import { SHOW_GIFT_RETURN_MODAL } from 'shared/constants'

const ReturnLink = ({ className, reservation }) => {
  const {
    email,
    daysSincePurchase,
    regItem,
    registryId,
    returnWebUrl,
    name,
    orderNumber,
    storeName,
    token,
  } = reservation
  let returnLink = null

  const handleReturnClick = (event) => {
    event.preventDefault()
    PubSub.publish(SHOW_GIFT_RETURN_MODAL, {
      registryId: registryId,
      reservationToken: token,
      storeName: storeName,
      orderNum: orderNumber,
      regItem: regItem,
      imageUrl: regItem.imgUrl,
      title: regItem.title,
      giftGiverName: name,
      giftGiverEmail: email,
      daysSincePurchase: daysSincePurchase,
    })
  }
  // Open link to returnly if the item is eligible
  if (returnWebUrl) {
    returnLink = (
      <InfoButton
        className={classNames(className, 'h7 mts')}
        empty
        pill
        size="small"
        rel="norewrite"
        href={returnWebUrl}
        newWindow
      >
        Return
      </InfoButton>
    )
    // or show gift return instruction modal
  } else if (!regItem.isDigitalItem) {
    returnLink = (
      <InfoButton
        className={classNames(className, 'h7 mts')}
        empty
        pill
        size="small"
        onClick={handleReturnClick}
      >
        Return
      </InfoButton>
    )
  }

  return returnLink
}

ReturnLink.propTypes = {
  reservation: PropTypes.object,
}

export default ReturnLink
