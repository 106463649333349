import PropTypes from 'prop-types'
import classNames from 'classnames'
import { InfoButton } from 'components/buttons'
import { connect } from 'react-redux'
import {
  openGroupGiftRedemptionModal,
  openRegItemDeleteModal,
} from 'registry/modals/actions'
import { deleteRegItem, fetchRegItem } from 'registry/actions/reg-items-actions'
import { GROUP_GIFT_ACTIONS } from 'registry/constants'
import css from './remove-reg-item-action.scss'

const RemoveRegItemAction = ({
  deleteRegItem,
  openGroupGiftRedemptionModal,
  openRegItemDeleteModal,
  fetchRegItem,
  regItemId,
  onRemove,
  className,
}) => {
  const handleRemove = () => {
    // Check if reg item is reserved right before we delete it
    fetchRegItem(regItemId).then((regItem) => {
      if (regItem.isSomewhatReserved && !regItem.isGroupGift) {
        openRegItemDeleteModal(regItemId)
        // Show the redemption modal if a group gift has funds
      } else if (regItem.isGroupGift && regItem.fundedAmount > 0) {
        openGroupGiftRedemptionModal(regItem, GROUP_GIFT_ACTIONS.DELETE)
      } else {
        onRemove() // Call back before removing from redux, this is to allow modals to unmount so they are not re-rendered by the state change
        deleteRegItem(regItemId)
      }
    })
  }

  return (
    <InfoButton
      aria-label="Remove this gift from your registry"
      circle
      empty
      onClick={handleRemove}
      className={classNames(className, 'btn-circle btn-md flex center')}
      style={{ borderWidth: 1.5 }}
    >
      <div className={css.deleteBtn} />
    </InfoButton>
  )
}

RemoveRegItemAction.propTypes = {
  className: PropTypes.string,
  deleteRegItem: PropTypes.func.isRequired,
  fetchRegItem: PropTypes.func.isRequired,
  regItemId: PropTypes.number.isRequired,
  onRemove: PropTypes.func,
  openRegItemDeleteModal: PropTypes.func.isRequired,
}

RemoveRegItemAction.defaultProps = {
  className: '',
  onRemove: () => {},
}

export default connect(undefined, {
  openGroupGiftRedemptionModal,
  openRegItemDeleteModal,
  deleteRegItem,
  fetchRegItem,
})(RemoveRegItemAction)
