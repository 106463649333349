import { FlagsProvider } from 'flagged'

import GlobalNav from 'components/global-nav'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { OutOfAreaBanner, InputAddressBanner } from 'components/banners'
import { setHost } from 'lib/urls'

const RenderBanner = (props) => {
  const { activeNavItem, currentUserCountry } = props
  const [currentUser] = useCurrentUser()

  const shouldShowOutOfAreaBanner =
    currentUserCountry !== null && currentUserCountry !== 'United States'
  const shouldShowInputAddressBanner =
    currentUser && currentUserCountry === null

  if (activeNavItem === 'Store') {
    if (shouldShowOutOfAreaBanner) {
      return (
        <OutOfAreaBanner
          country={currentUserCountry}
          showBabyListButton={currentUser && !currentUser.addedBabyListButton}
        />
      )
    }

    if (shouldShowInputAddressBanner) {
      return <InputAddressBanner />
    }
  }

  return null
}

const Header = (props) => {
  const { featureFlags } = props
  setHost(props._canonicalHost) // Sets the host for SSR absolute URLs
  return (
    <>
      {RenderBanner(props)}
      <FlagsProvider features={featureFlags}>
        <GlobalNav {...props} />
      </FlagsProvider>
    </>
  )
}

export default Header
