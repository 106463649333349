import React from 'react'

interface PaypalProps {
  className?: string
  filled?: boolean
}

const Paypal: React.FC<PaypalProps> = ({ className }: PaypalProps) => (
  <svg
    className={className}
    width="62"
    height="20"
    viewBox="0 0 62 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_12107_10315)">
      <path
        d="M2.44643 1.66406C2.26659 1.66406 2.11385 1.79462 2.08572 1.97239L0.00408235 15.1736C-0.0034816 15.2218 -0.000509522 15.2711 0.0127909 15.318C0.0260913 15.365 0.0494106 15.4085 0.0811365 15.4456C0.112862 15.4827 0.152234 15.5125 0.196568 15.5329C0.240902 15.5534 0.28914 15.5639 0.337952 15.5639H2.80661C2.98619 15.5639 3.13919 15.4331 3.16732 15.2556L3.77881 11.379C3.80667 11.2015 3.95967 11.0707 4.13951 11.0707H6.38395C9.00639 11.0707 11.2325 9.15725 11.6392 6.56448C12.0491 3.94979 10.0065 1.66974 7.1113 1.66406H2.44643ZM4.84621 4.61107H6.64196C8.12038 4.61107 8.60184 5.48341 8.44961 6.44812C8.29738 7.41489 7.54966 8.127 6.1195 8.127H4.29173L4.84621 4.61107ZM16.6601 5.50844C16.0367 5.51076 15.3195 5.63848 14.5142 5.97389C12.6671 6.7438 11.78 8.33496 11.4033 9.49498C11.4033 9.49498 10.2044 13.0341 12.9132 14.9785C12.9132 14.9785 15.4255 16.8504 18.2536 14.8632L18.2048 15.1736C18.1972 15.2218 18.2002 15.271 18.2135 15.3179C18.2267 15.3648 18.25 15.4083 18.2817 15.4454C18.3133 15.4825 18.3527 15.5123 18.3969 15.5328C18.4412 15.5532 18.4894 15.5639 18.5381 15.5639H20.8817C21.0615 15.5639 21.2143 15.4331 21.2424 15.2556L22.6679 6.21539C22.6755 6.16718 22.6726 6.11788 22.6594 6.07088C22.6462 6.02389 22.6229 5.98033 22.5912 5.94318C22.5596 5.90603 22.5202 5.87619 22.4759 5.85571C22.4316 5.83522 22.3834 5.82458 22.3346 5.82451H19.991C19.8112 5.82451 19.6582 5.95532 19.6303 6.13283L19.5537 6.61944C19.5537 6.61944 18.5304 5.50147 16.6601 5.50844ZM16.7367 8.36386C17.0058 8.36386 17.252 8.40101 17.4728 8.47197C18.484 8.79655 19.0573 9.76796 18.8914 10.8209C18.6868 12.1174 17.6235 13.0721 16.2599 13.0721C15.9911 13.0721 15.7449 13.0352 15.5238 12.9642C14.5129 12.6396 13.936 11.6682 14.1019 10.6153C14.3065 9.31876 15.3731 8.36386 16.7367 8.36386Z"
        fill="#003087"
      />
      <path
        d="M36.1945 1.66406C36.0146 1.66406 35.8619 1.79462 35.8338 1.97239L33.7521 15.1736C33.7446 15.2218 33.7475 15.2711 33.7608 15.318C33.7741 15.365 33.7975 15.4085 33.8292 15.4456C33.8609 15.4827 33.9003 15.5125 33.9446 15.5329C33.989 15.5534 34.0372 15.5639 34.086 15.5639H36.5547C36.7342 15.5639 36.8872 15.4331 36.9154 15.2556L37.5269 11.379C37.5547 11.2015 37.7077 11.0707 37.8876 11.0707H40.132C42.7544 11.0707 44.9803 9.15725 45.3867 6.56448C45.7969 3.94979 43.7545 1.66974 40.8593 1.66406H36.1945ZM38.5943 4.61107H40.39C41.8684 4.61107 42.3499 5.48341 42.1977 6.44812C42.0454 7.41489 41.298 8.127 39.8675 8.127H38.0398L38.5943 4.61107ZM50.4079 5.50844C49.7845 5.51076 49.0672 5.63848 48.262 5.97389C46.4149 6.7438 45.5278 8.33496 45.1511 9.49498C45.1511 9.49498 43.9527 13.0341 46.6615 14.9785C46.6615 14.9785 49.1733 16.8504 52.0016 14.8632L51.9528 15.1736C51.9453 15.2218 51.9483 15.2711 51.9616 15.318C51.9749 15.365 51.9982 15.4085 52.0299 15.4456C52.0616 15.4827 52.101 15.5125 52.1453 15.5329C52.1897 15.5534 52.2379 15.5639 52.2867 15.5639H54.63C54.8098 15.5639 54.9626 15.4331 54.9907 15.2556L56.4167 6.21539C56.4244 6.16713 56.4215 6.11779 56.4082 6.07076C56.3949 6.02373 56.3716 5.98014 56.3399 5.94298C56.3082 5.90583 56.2688 5.876 56.2244 5.85554C56.18 5.83509 56.1317 5.8245 56.0829 5.82451H53.7393C53.5595 5.82451 53.4065 5.95532 53.3786 6.13283L53.302 6.61944C53.302 6.61944 52.2782 5.50147 50.4079 5.50844ZM50.4845 8.36386C50.7536 8.36386 50.9998 8.40101 51.2206 8.47197C52.2318 8.79655 52.8051 9.76796 52.6392 10.8209C52.4346 12.1174 51.3713 13.0721 50.0077 13.0721C49.7388 13.0721 49.4927 13.0352 49.2716 12.9642C48.2607 12.6396 47.6838 11.6682 47.8497 10.6153C48.0543 9.31876 49.1209 8.36386 50.4845 8.36386Z"
        fill="#0070E0"
      />
      <path
        d="M23.7803 5.82838C23.5945 5.82838 23.4624 6.00977 23.5192 6.1865L26.0884 14.1593L23.7653 17.9165C23.6525 18.0989 23.7836 18.3345 23.998 18.3345H26.7438C26.8223 18.3344 26.8995 18.3141 26.9679 18.2755C27.0363 18.2369 27.0935 18.1813 27.1342 18.1141L34.3098 6.24378C34.4199 6.06137 34.2883 5.82812 34.0752 5.82812H31.3297C31.2504 5.82819 31.1725 5.8489 31.1037 5.88821C31.0348 5.92753 30.9774 5.98409 30.937 6.05234L28.1125 10.8209L26.6788 6.08768C26.6321 5.93365 26.4899 5.82812 26.3292 5.82812L23.7803 5.82838Z"
        fill="#003087"
      />
      <path
        d="M59.0798 1.66406C58.9003 1.66406 58.7473 1.79487 58.7191 1.97239L56.637 15.1731C56.6293 15.2213 56.6322 15.2707 56.6455 15.3177C56.6588 15.3647 56.6821 15.4083 56.7138 15.4455C56.7455 15.4826 56.7849 15.5124 56.8293 15.5329C56.8737 15.5534 56.922 15.5639 56.9708 15.5639H59.4395C59.6191 15.5639 59.7721 15.4331 59.8002 15.2556L61.8819 2.05443C61.8894 2.00626 61.8865 1.95701 61.8732 1.91008C61.8599 1.86316 61.8366 1.81965 61.805 1.78257C61.7733 1.74548 61.734 1.71569 61.6897 1.69523C61.6454 1.67478 61.5973 1.66414 61.5485 1.66406H59.0798Z"
        fill="#0070E0"
      />
    </g>
    <defs>
      <clipPath id="clip0_12107_10315">
        <rect width="61.886" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Paypal
