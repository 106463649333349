import { upperFirst, orderBy } from 'lodash'
import Select from 'components/forms/select'

export default ({ categories, addFilter, selected }) => {
  const handleFilterChange = (e) => {
    const intId = parseInt(e.target.value) // value is coerced to a string, we need it as a number except in the case of "All Categories"
    addFilter({ categoryId: Number.isInteger(intId) ? intId : null })
  }

  const formattedCategories = categories.map((cat) => ({
    ...cat,
    title: upperFirst(cat.title),
  }))
  const sortedCategories = orderBy(formattedCategories, 'title')

  return (
    <>
      <hr className="mbl" />
      <div className="h6 text-uppercase tiny text-bold text-light mtn mbs">
        Categories
      </div>

      <Select
        name="category-filter"
        value={selected}
        onChange={handleFilterChange}
      >
        <option>All Categories</option>
        {sortedCategories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.title}
          </option>
        ))}
      </Select>
    </>
  )
}
