import { CartAPI } from 'src/routes/(shop)/api/cart'
import { RegistryDiscount } from 'src/types/registryDiscount'
import { CartSummary as Component } from './CartSummary'

interface CartSummaryProps extends RegistryDiscount {
  isCompact?: boolean
  totalQuantity?: number
}

export const CartSummary = ({
  isCompact,
  eligibleForMultiuseDiscount,
  totalQuantity,
}: CartSummaryProps) => {
  const { data, isLoading, error } = CartAPI.useShow()

  if (isLoading) return null
  if (error) return <div>Error loading cart data</div>
  if (!data || !data.checkoutTotals) return null

  return (
    <Component
      eligibleForMultiuseDiscount={eligibleForMultiuseDiscount}
      isCompact={isCompact}
      totalQuantity={totalQuantity}
      {...data}
    />
  )
}
