/* eslint-disable import/no-extraneous-dependencies */
import { FC } from 'react'
import { Button, Modal } from 'baby-design'
import { CashFundPiggy } from 'baby-design/icons'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { absoluteUrl, settingsGiftCardsAndPromotionsPath } from 'lib/urls'
import { RegItem } from 'src/types/regItem'

import css from './GroupGiftUnredeemedFundsModal.styles.scss'
import { formatDisplayPriceForWholeDollars } from '../../utils/productHelpers'

interface GroupGiftUnredeemedFundsModalProps {
  regItem: RegItem
}

const GroupGiftUnredeemedFundsModal: FC<GroupGiftUnredeemedFundsModalProps> = ({
  regItem,
}) => {
  const modal = useModal()

  const priceDisplay = formatDisplayPriceForWholeDollars(
    regItem.fundsUnredeemedAmount
  )

  return (
    <Modal showCloseButton handleClose={modal.hide} isOpen={modal.visible}>
      <div className={css.GroupGiftUnredeemedFundsModal}>
        <div className={css.GroupGiftUnredeemedFundsModal__mainContent}>
          <div className={css.GroupGiftUnredeemedFundsModal__image}>
            <CashFundPiggy />
          </div>
          <h2 className={css.GroupGiftUnredeemedFundsModal__title}>
            You have {priceDisplay} available from
            <br />
            Group Gift contributions
          </h2>
          <p className={css.GroupGiftUnredeemedFundsModal__details}>
            Convert your funds to Babylist Shop credit and apply them at
            checkout.
          </p>
        </div>
        <div className={css.GroupGiftUnredeemedFundsModal__cta}>
          <Button
            block
            className={css.GroupGiftUnredeemedFundsModal__desktopButton}
            href={absoluteUrl(settingsGiftCardsAndPromotionsPath)}
            size="lg"
            variant="primary"
          >
            Use my funds
          </Button>
          <Button
            block
            className={css.GroupGiftUnredeemedFundsModal__mobileButton}
            href={absoluteUrl(settingsGiftCardsAndPromotionsPath)}
            size="md"
            variant="primary"
          >
            Use my funds
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default NiceModal.create(GroupGiftUnredeemedFundsModal)
