import { Component } from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import classNames from 'classnames'
import css from './option-selector.scss'

// Currently works as a radio button group (where only one can be selected at a time)
class OptionSelector extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string,
        disabled: PropTypes.bool,
      })
    ).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    hrefFor: PropTypes.func,
    className: PropTypes.string,
    labelDecorator: PropTypes.func,
    titleFor: PropTypes.func,
  }

  render() {
    let {
      labelDecorator,
      options,
      name,
      onClick,
      className,
      value,
      hrefFor,
      titleFor,
    } = this.props

    return (
      <div
        aria-label={`${name} options`}
        className={classNames(css.optionsContainer, className)}
        role="radiogroup"
      >
        <input type="hidden" name={name} value={value} />
        {map(options, (option, idx) => (
          <Option
            disabled={option.disabled}
            href={hrefFor ? hrefFor(name, option.value) : null}
            key={idx}
            label={
              labelDecorator
                ? labelDecorator(option.label || option.value)
                : option.label || option.value
            }
            selected={value == option.value}
            title={titleFor ? titleFor(name, option.value) : null}
            value={option.value}
            onClick={onClick}
          />
        ))}
      </div>
    )
  }
}

const Option = ({ value, label, onClick, selected, disabled, href, title }) => (
  <OptionComponent
    aria-checked={selected}
    aria-label={value}
    className={classNames(
      'btn btn-info btn-empty',
      css.option,
      { [css.selected]: selected },
      { [css.disabled]: disabled }
    )}
    href={href}
    role="radio"
    tabIndex={selected ? 0 : -1}
    title={title}
    onClick={(e) => {
      e.preventDefault()
      onClick(value)
    }}
  >
    {label}
  </OptionComponent>
)

const OptionComponent = (props) => {
  if (props.href) return <a {...props}>{props.children}</a>
  else return <button {...props}>{props.children}</button>
}

Option.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
}

export default OptionSelector
