import React from 'react'
import Toast from 'baby-design/components/Toast/Toast'
import { CheckCircle } from 'baby-design/icons'
import { ChangesSavedToastProps } from './ChangesSavedToast.types'
import css from './ChangesSavedToast.styles.scss'

const ChangesSavedToast: React.FC<ChangesSavedToastProps> = ({ isOpen }) => (
  <Toast
    imageProps={{
      image: <CheckCircle filled className={css.ChangesSavedToast__Image} />,
    }}
    isOpen={isOpen}
    text="Changes saved."
    variant="basic"
  />
)

export default ChangesSavedToast
