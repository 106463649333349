import PropTypes from 'prop-types'
import CashFundContributionModalLink from '../cash-fund-contribution-modal-link'
import classNames from 'classnames'
import { SecondaryButton } from 'components/buttons'
import { regItemProps } from '../reg-item-props'

const CashFundAction = ({ regItem }, context) => {
  if (regItem.isGoalComplete) {
    return (
      <div className="h6 guest-action">
        <em>Goal Met</em>
      </div>
    )
  }

  return (
    <CashFundContributionModalLink regItem={regItem}>
      <SecondaryButton
        className="guest-action"
        pill
        rel="nofollow"
        size={context.isMobile ? 'small' : undefined}
      >
        Contribute to cash fund
      </SecondaryButton>
    </CashFundContributionModalLink>
  )
}

CashFundAction.propTypes = {
  regItem: PropTypes.shape(regItemProps).isRequired,
}

CashFundAction.contextTypes = {
  isMobile: PropTypes.bool,
}

export default CashFundAction
