import Link from 'components/link'
import { SHOW_CASH_FUND_CONTRIBUTE_MODAL } from 'shared/constants'

// TODO: Replace this with the ActiveModal and ModalProps pattern
const CashFundContributionModalLink = ({ children, className, regItem }) => {
  const cashFundParams = {
    id: regItem.id,
    title: regItem.title,
    description: regItem.description,
    goalAmount: regItem.goalAmount,
    amountContributed: regItem.amountContributed,
    imgUrl: regItem.imgUrl,
    imgLink: regItem.imgLink,
  }

  return (
    <Link
      className={className}
      onClick={() =>
        PubSub.publish(SHOW_CASH_FUND_CONTRIBUTE_MODAL, cashFundParams)
      }
      rel="nofollow noindex"
    >
      {children}
    </Link>
  )
}

export default CashFundContributionModalLink
