/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types'
import Link from 'components/link'
import Card from 'components/card'
import AddOrBuy from '../add-or-buy/add-or-buy'
import QuantityInformation from './quantity-information'
import EditRegItemLink from './edit-reg-item-link'
import { regItemProps } from './reg-item-props'
import RegItemCardLayout from './reg-item-card-layout'
import OwnerActions from './owner-actions/owner-actions'

export const FavorRegItemCard = (
  { regItem, isContributorView, reservationByCurrentVisitor },
  { isMobile, registry }
) => {
  const { id: regItemId, url, quantity, quantityNeeded, offers } = regItem

  const regItemLink = (child) => {
    if (!isContributorView) {
      return (
        <Link className="link-info" url={url} rel="nofollow" newWindow>
          {child}
        </Link>
      )
    }
    // contributor view
    if (isMobile) {
      return <EditRegItemLink regItemId={regItemId}>{child}</EditRegItemLink>
    }
    return (
      <Link url={url} newWindow>
        {child}
      </Link>
    )
  }

  const Wrapper = ({ children }) => {
    if (isMobile && !isContributorView) {
      return <Card>{children}</Card>
    }
    return children
  }

  const actions = () => {
    if (isContributorView) {
      return <OwnerActions showEditAction regItemId={regItemId} />
    }
    if (reservationByCurrentVisitor) {
      return <>Gifted By You</>
    }
    return (
      <AddOrBuy
        regItem={regItem}
        offers={offers}
        registry={registry}
        reservation={reservationByCurrentVisitor}
      />
    )
  }

  return (
    <Wrapper>
      <RegItemCardLayout
        regItem={regItem}
        regItemLinkDecorator={regItemLink}
        quantityInformation={
          <QuantityInformation
            quantity={quantity}
            quantityNeeded={quantityNeeded}
          />
        }
        actions={actions()}
      />
    </Wrapper>
  )
}

FavorRegItemCard.propTypes = {
  regItem: PropTypes.shape(regItemProps).isRequired,
  isContributorView: PropTypes.bool.isRequired,
  reservationByCurrentVisitor: PropTypes.object,
}

FavorRegItemCard.contextTypes = {
  isMobile: PropTypes.bool,
  registry: PropTypes.bool,
}

FavorRegItemCard.defaultProps = {
  reservationByCurrentVisitor: null,
}
