import { Modal, ModalBody } from 'components/modal'
import css from './surcharge-modal.scss'

interface SurchargeModalProps {
  show: boolean
  onHide(): void
}

const SurchargeModal = ({ show, onHide }: SurchargeModalProps) => (
  <Modal
    className={css.surchargeModal}
    show={show}
    size="small"
    title=" "
    onHide={onHide}
  >
    <ModalBody className={css.surchargeModalBody}>
      <p>
        Some items due to size and weight will include a flat rate delivery
        surcharge. Good news - this means additional items ship free! To learn
        more about flat rate shipping tiers,{' '}
        <a
          href="https://help.babylist.com/hc/en-us/articles/5381426515099"
          rel="noreferrer"
          target="_blank"
        >
          visit this guide
        </a>
        . Some exclusions apply.
      </p>
    </ModalBody>
  </Modal>
)

export default SurchargeModal
