const CategoryClothing = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 258.35 258.35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M170,176.16a2.67,2.67,0,0,1,3.08,2.17l1.48,8.43a2.68,2.68,0,0,1-2.17,3.09L122,198.66a2.68,2.68,0,0,1-3.09-2.17l-1.47-8.43A2.68,2.68,0,0,1,119.6,185l2-.34,46-8Z"
      fill="#d8d8d8"
    />
    <path
      d="M159.84,159.5a33.08,33.08,0,0,1,7.61,16.2c.05.29.1.59.14.88l-46,8h0c-.06-.29-.12-.58-.17-.87a33.78,33.78,0,0,1,.44-14c2.92.75,6.11,1.47,9.57,2.11,19,3.53,24.09,1.78,25.38.87Z"
      fill="#d8d8d8"
    />
    <path
      d="M139.72,151.39c7.44-1.21,14.79,2.05,20.12,8.11l-3,13.19c-1.29.91-6.35,2.66-25.38-.87-3.46-.64-6.65-1.36-9.57-2.11,2.07-8.27,7.32-14.88,14.43-17.43Z"
      fill="#d8d8d8"
    />
    <path
      d="M135.94,126.87a3.43,3.43,0,0,1,5-2.67c2,1.11,2.25,3.79,2.11,6.85-.17,3.75-3.06,8.55-4,10l-.47-.27c-.52-1.78-1.92-6.74-2.57-10.45A11.86,11.86,0,0,1,135.94,126.87Z"
      fill="#d8d8d8"
    />
    <path
      d="M139.73,142.52a5,5,0,0,1-1.87,9.09,4.89,4.89,0,0,1-1.87,0,5,5,0,1,1,3.16-9.39A6.65,6.65,0,0,1,139.73,142.52Z"
      fill="#d8d8d8"
    />
    <path
      d="M121.86,169.71c-14.52-3.74-22.49-8.41-24.78-9.89-.47-.3-.7-.47-.7-.47a24.83,24.83,0,0,1-10.2-2.81l9.28-46.82a1.65,1.65,0,0,0-2.69-1.59L70.69,124.48a6.24,6.24,0,0,1-8.81-.59l-6.71-6.3a6.25,6.25,0,0,1,.59-8.82L105.91,65.4a25.11,25.11,0,0,1,19.59-5.91q1,.13,2.07.36l30,6.41,1.24.27a25.07,25.07,0,0,1,17.49,13.93l28,60.09a6.24,6.24,0,0,1-3.07,8.28L192.11,154a6.24,6.24,0,0,1-8.28-3.07l-13-26.11a1.64,1.64,0,0,0-3.1.34l-7.89,34.33"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M97.08,159.82c3.79-18.36,7.41-35.24,9.56-43.78,6.16-24.47,10.12-26.4,16.12-29.91,2.53-1.47,9.12-5.17,15.88-8.95l18.85-10.49"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <polyline
      fill="none"
      points="125.43 59.4 125.5 59.49 138.61 77.13"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <line
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
      x1="188.27"
      x2="197"
      y1="145.82"
      y2="140.36"
    />
    <line
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
      x1="62.34"
      x2="68.02"
      y1="111.55"
      y2="117.44"
    />
    <path
      d="M136.29,152.28l3.43-.89"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M167.59,176.58l2.39-.42a2.67,2.67,0,0,1,3.08,2.17l1.48,8.43a2.68,2.68,0,0,1-2.17,3.09L122,198.66a2.68,2.68,0,0,1-3.09-2.17l-1.47-8.43A2.68,2.68,0,0,1,119.6,185l2-.34Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M121.59,184.62c-.06-.29-.12-.58-.17-.87a33.78,33.78,0,0,1,.44-14c2.07-8.27,7.32-14.88,14.43-17.43"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M139.72,151.39c7.44-1.21,14.79,2.05,20.12,8.11a33.08,33.08,0,0,1,7.61,16.2c.05.29.1.59.14.88"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M140.24,150.49a5,5,0,0,1-2.38,1.12,4.89,4.89,0,0,1-1.87,0,5,5,0,1,1,3.16-9.39,6.65,6.65,0,0,1,.58.33,5,5,0,0,1,.51,8Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M139.12,141c.9-1.43,3.79-6.23,4-10,.14-3.06-.12-5.74-2.11-6.85a3.43,3.43,0,0,0-5,2.67,11.86,11.86,0,0,0,.14,3.44c.65,3.71,2,8.67,2.57,10.45"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
  </svg>
)

export default CategoryClothing
