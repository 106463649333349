import PropTypes from 'prop-types'
import classNames from 'classnames'
import { truncate } from 'lodash'
import Markdown from 'markdown-to-jsx'
import Image from 'components/image'
import Card from 'components/card'
import dateformat from 'dateformat'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ReviewButton } from 'store/components/product-reviews'
import { ConsolidatedShippingLeafIcon } from 'components/icons'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import ReturnLink from './gift-returns/components/return-link'
import UnreserveLink from './unreserve-link'
import GiftActions from './gift-actions'
import css from './reservation-card.scss'

const ReservationCard = ({ reservation }, { registry }) => {
  const [currentUser] = useCurrentUser()
  const {
    regItem,
    isPurchased,
    createdAt,
    purchasedAt,
    quantity,
    trackingInfos,
    displayTitle,
    consolidationMessage,
    isSurprise,
  } = reservation

  const title = displayTitle

  return (
    <Card className={css.card}>
      <div className={css.thumbnail}>
        <Image role="presentation" src={regItem.imgUrl} width="100%" />
      </div>
      <div className={classNames(css.title)}>
        <span>{truncate(title, { length: 70 })}</span>
        <div className={classNames('hidden-xs', css.consolidationMessage)}>
          {consolidationMessage && (
            <>
              <span className={css.consolidatedShippingIcon}>
                <ConsolidatedShippingLeafIcon width={16} />
              </span>
              <ConsolidationMessage message={consolidationMessage} />
            </>
          )}
        </div>
        <GiftActions trackingInfos={trackingInfos} className="hidden-xs mbm" />
        {!regItem.isActive && (
          <div className={classNames(css.status)}>
            This item was reserved but removed from your registry.
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip" className="h7">
                  Gift givers will no longer see the option to buy this item,
                  but it may have been purchased already.
                </Tooltip>
              }
            >
              <i className="fa fa-info-circle mls text-muted" />
            </OverlayTrigger>
          </div>
        )}
        {regItem.isActive && !isPurchased && (
          <UnreserveLink reservation={reservation} />
        )}
        {isSurprise && (
          <div className={css.surprise}>
            Reminder! 🤫
            <br />
            This person requested to keep this a surprise.
          </div>
        )}
      </div>
      <div className={classNames('visible-xs', css.consolidationMessage)}>
        {consolidationMessage && (
          <>
            <span className={css.consolidatedShippingIcon}>
              <ConsolidatedShippingLeafIcon width={16} />
            </span>
            <span>
              <ConsolidationMessage message={consolidationMessage} />
            </span>
          </>
        )}
      </div>
      <div className={css.meta}>
        {isPurchased ? (
          <>
            <span>Purchased</span>{' '}
            <span>{dateformat(purchasedAt, 'mediumDate')}</span>
          </>
        ) : (
          <>
            <span>Reserved</span>{' '}
            <span>{dateformat(createdAt, 'mediumDate')}</span>
          </>
        )}
        <span>Qty</span> <span>{quantity}</span>
        {!regItem.isCrowdfund && <PurchaseDetails reservation={reservation} />}
      </div>
    </Card>
  )
}

ReservationCard.propTypes = {
  reservation: PropTypes.object.isRequired,
}

ReservationCard.contextTypes = {
  registry: PropTypes.object,
}

export default ReservationCard

const ConsolidationMessage = ({ message }) => (
  <Markdown
    options={{
      wrapper: React.Fragment,
      overrides: { a: { props: { target: '_blank' } } },
    }}
  >
    {message}
  </Markdown>
)

const ShippingStatus = ({ shippingStatus }) => {
  if (
    shippingStatus &&
    (shippingStatus.currentState === 'processing' ||
      shippingStatus.currentState === 'preparing')
  ) {
    const { estimatedShipDate } = shippingStatus
    const status = `Estimated to ship on ${dateformat(
      estimatedShipDate,
      'mediumDate'
    )}`
    return (
      <>
        <span>Status</span>
        <span>{status}</span>
      </>
    )
  }
  return null
}

const PurchaseDetails = ({ reservation }) => {
  const { isPurchased, orderNumber, storeName, trackingInfos, regItem } =
    reservation

  return isPurchased ? (
    <>
      <span>Store</span>{' '}
      <span className={classNames({ 'text-muted text-italic': !storeName })}>
        {storeName || 'Not yet provided'}
      </span>
      <span>Order #</span>{' '}
      <span className={classNames({ 'text-muted text-italic': !orderNumber })}>
        {orderNumber || 'Not yet provided'}
      </span>
      <ShippingStatus shippingStatus={reservation.shippingStatus} />
      <span className={css.spanColumn}>
        <GiftActions trackingInfos={trackingInfos} className="visible-xs" />
        <ReturnLink reservation={reservation} />
        <ReviewButton sku={regItem.turntoSku} />
      </span>
    </>
  ) : null
}

PurchaseDetails.propTypes = {
  reservation: PropTypes.object.isRequired,
}
