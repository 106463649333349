const CategorySleeping = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 258.35 258.35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M106.77,150.12h29.08l7.34,15.44a10.82,10.82,0,0,1-7,14L99.33,190.9a10.82,10.82,0,0,1-13.66-7.66l-4.79-31.11a20.9,20.9,0,0,1,8.92-2Z"
      fill="#d8d8d8"
    />
    <path
      d="M131.18,140.29l4.67,9.83H106.77l-3-9.83h6.89a1.85,1.85,0,0,0,1.85-1.85V101.63a4.71,4.71,0,0,1,1.06,4.9,9.53,9.53,0,0,0,5.94,12.28,4.08,4.08,0,0,1,1.2.64v19a1.85,1.85,0,0,0,1.85,1.85Z"
      fill="#d8d8d8"
    />
    <path
      d="M122.05,121.08l9.13,19.21h-8.59a1.85,1.85,0,0,1-1.85-1.85v-19A4.42,4.42,0,0,1,122.05,121.08Z"
      fill="#d8d8d8"
    />
    <path
      d="M112.54,101.63v36.81a1.85,1.85,0,0,1-1.85,1.85H103.8l-6.94-23-2.3-1.15a7.34,7.34,0,0,0,.81,0,8.76,8.76,0,0,0,1.35-.29,7.4,7.4,0,0,0,5-6l1.2-7.8,5.09-1.57.09,0A4.42,4.42,0,0,1,112.54,101.63Z"
      fill="#d8d8d8"
    />
    <path
      d="M103.8,140.29l3,9.83h-17a20.9,20.9,0,0,0-8.92,2l-1.83-11.84h5.12A1.85,1.85,0,0,0,86,138.44V110.69L89.4,114a7.43,7.43,0,0,0,4.82,2.17v22.29a1.85,1.85,0,0,0,1.85,1.85Z"
      fill="#d8d8d8"
    />
    <path
      d="M96.86,117.32l6.94,23H96.07a1.85,1.85,0,0,1-1.85-1.85V116.15a1.94,1.94,0,0,0,.34,0Z"
      fill="#d8d8d8"
    />
    <path
      d="M102.89,102l-1.2,7.8a7.4,7.4,0,0,1-5,6,8.76,8.76,0,0,1-1.35.29,7.34,7.34,0,0,1-.81,0,1.94,1.94,0,0,1-.34,0v-4.24a7.82,7.82,0,0,0,5.57-4Z"
      fill="#d8d8d8"
    />
    <path
      d="M102.89,102l-3.1,5.85a7.82,7.82,0,0,1-5.57,4v-7.24Z"
      fill="#d8d8d8"
    />
    <path
      d="M94.22,111.91v4.24A7.43,7.43,0,0,1,89.4,114L86,110.69h0v-1.21l3.13,1.66A7.86,7.86,0,0,0,94.22,111.91Z"
      fill="#d8d8d8"
    />
    <path
      d="M94.22,104.67v7.24a7.86,7.86,0,0,1-5.07-.78L86,109.47v-2.28Z"
      fill="#d8d8d8"
    />
    <path
      d="M86,110.68h0v27.75a1.85,1.85,0,0,1-1.85,1.85H79.05l-.88-5.71a4.31,4.31,0,0,1,.8-3.28,9.55,9.55,0,0,0-2-13.52,4.64,4.64,0,0,1,1.14-8.16l.09,0L83.3,108Z"
      fill="#d8d8d8"
    />
    <path
      d="M135.85,150.12h32.7c12.47,0,22.84,11.18,24.33,25.49a3,3,0,0,0,2.71,2.87h4.3a2.09,2.09,0,0,0,1.89-2.24v-107A2.09,2.09,0,0,0,199.89,67H58.46a2.09,2.09,0,0,0-1.89,2.24v107a2.09,2.09,0,0,0,1.89,2.24h4.31a2.94,2.94,0,0,0,2.7-2.84,31.35,31.35,0,0,1,7-17,24.58,24.58,0,0,1,8.39-6.52"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M79.05,140.29h-9.5a1.85,1.85,0,0,1-1.85-1.85V78a1.85,1.85,0,0,1,1.85-1.85H84.17A1.84,1.84,0,0,1,86,78v29.17"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M94.22,104.67V78a1.85,1.85,0,0,1,1.85-1.85h14.62A1.85,1.85,0,0,1,112.54,78v23.61"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M120.74,119.45V78a1.85,1.85,0,0,1,1.85-1.85h14.62A1.85,1.85,0,0,1,139.06,78v60.42a1.85,1.85,0,0,1-1.85,1.85h-6"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M163.72,140.29H149.11a1.85,1.85,0,0,1-1.85-1.85V78a1.84,1.84,0,0,1,1.85-1.85h14.61A1.85,1.85,0,0,1,165.58,78v60.42A1.85,1.85,0,0,1,163.72,140.29Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <rect
      fill="none"
      height="64.12"
      rx="1.85"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
      width="18.32"
      x="172.33"
      y="76.17"
    />
    <path
      d="M102.89,102l5.09-1.57.09,0a4.42,4.42,0,0,1,4.47,1.21,4.71,4.71,0,0,1,1.06,4.9,9.53,9.53,0,0,0,5.94,12.28,4.08,4.08,0,0,1,1.2.64,4.42,4.42,0,0,1,1.31,1.63l9.13,19.21,4.67,9.83,7.34,15.44a10.82,10.82,0,0,1-7,14L99.33,190.9a10.82,10.82,0,0,1-13.66-7.66l-4.79-31.11-1.83-11.84-.88-5.71a4.31,4.31,0,0,1,.8-3.28,9.55,9.55,0,0,0-2-13.52,4.64,4.64,0,0,1,1.14-8.16l.09,0L83.3,108"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M102.89,102l-1.2,7.8a7.4,7.4,0,0,1-5,6,8.76,8.76,0,0,1-1.35.29,7.34,7.34,0,0,1-.81,0,1.94,1.94,0,0,1-.34,0A7.43,7.43,0,0,1,89.4,114L86,110.69h0L83.3,108l2.72-.84,8.2-2.52Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <polyline
      fill="none"
      points="96.86 117.32 103.8 140.29 106.77 150.12 114.16 174.59"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
  </svg>
)

export default CategorySleeping
