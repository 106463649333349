import { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Collapse } from 'react-bootstrap'
import { connect } from 'react-redux'
import { InfoButton } from 'components/buttons'
import { Radio, RadioGroup } from 'components/forms'
import { cancelReservation } from 'cart/actions'
import { track, useTracking } from 'lib/analytics/track'
import {
  DID_NOT_BUY_CHANGED_MIND,
  DID_NOT_BUY_LOOKING,
  DID_NOT_BUY_NA,
} from 'cart/constants'

const DidNotBuyCartForm = ({
  onCancelReservation,
  onSubmitSuccess,
  registry,
  reservation,
}) => {
  const { register, handleSubmit, watch } = useForm()
  const [submitting, setSubmitting] = useState(false)
  const tracker = useTracking()
  const reasonSelection = watch('reason')

  const handleUnreserve = ({ reason }) => {
    setSubmitting(true)
    onCancelReservation(reason)
      .then(() => {
        onSubmitSuccess()
        tracker.trackEvent({
          event: track.reservationUpdated,
          registry,
          reservation,
          eventType: track.EventType.CANCELLED_RESERVATION,
          reservationCancellationReason:
            reason || track.ReservationCancellationReason.NONE,
        })
      })
      .then(() => setSubmitting(false))
  }
  return (
    <form onSubmit={handleSubmit(handleUnreserve)}>
      <RadioGroup className="mbl">
        <Radio
          name="reason"
          label="I was just looking"
          value={DID_NOT_BUY_LOOKING}
          inputRef={register}
        />
        <Radio
          name="reason"
          label="The item was not available"
          value={DID_NOT_BUY_NA}
          inputRef={register}
        />
        <Collapse
          in={reasonSelection === DID_NOT_BUY_NA}
          style={{ paddingLeft: 27 }}
        >
          <div>
            <p className="h6 man text-muted">
              Thanks for letting us know! We will remove the item from your cart
              and notify{' '}
              {registry?.firstNames ? registry.firstNames : 'the family'} that
              this item is no longer available.
            </p>
          </div>
        </Collapse>
        <Radio
          name="reason"
          label="I changed my mind"
          value={DID_NOT_BUY_CHANGED_MIND}
          inputRef={register}
        />
      </RadioGroup>
      <InfoButton className="mtxl" block type="submit" submitting={submitting}>
        Remove Item
      </InfoButton>
    </form>
  )
}

DidNotBuyCartForm.propTypes = {
  onCancelReservation: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  registry: PropTypes.object.isRequired,
  reservation: PropTypes.object.isRequired,
}

DidNotBuyCartForm.defaultProps = {
  onSubmitSuccess: () => {},
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    onCancelReservation: (reason) =>
      dispatch(cancelReservation(props.reservation, reason)),
  }
}

export default connect(null, mapDispatchToProps)(DidNotBuyCartForm)
