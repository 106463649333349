/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types'
import Card from 'components/card'
import AddOrBuy from '../../add-or-buy/add-or-buy'
import QuantityInformation from '../quantity-information'
import { BestOfferLink } from '../best-offer-link/best-offer-link'
import { regItemProps } from '../reg-item-props'
import RegItemCardLayout from '../reg-item-card-layout'
import { ReservationStatusAction } from '../guest-actions/reservation-status-action'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export const ReservableProductRegItemCard = (props, context) => {
  const {
    regItem: { offers, quantity, quantityNeeded },
    giftStatusContent,
    regItem,
    reservationByCurrentVisitor,
  } = props
  const { registry, isMobile } = context

  const Wrapper = ({ children }) => {
    if (isMobile) {
      return <Card>{children}</Card>
    }
    return children
  }

  const regItemLink = (child) => (
    <BestOfferLink offers={offers} regItem={regItem}>
      {child}
    </BestOfferLink>
  )

  const actions = () => {
    if (reservationByCurrentVisitor) {
      return (
        <ReservationStatusAction reservation={reservationByCurrentVisitor} />
      )
    }

    return (
      <LazyLoadComponent>
        <AddOrBuy
          regItem={regItem}
          offers={offers}
          registry={registry}
          reservation={reservationByCurrentVisitor}
        />
      </LazyLoadComponent>
    )
  }

  return (
    <Wrapper>
      <RegItemCardLayout
        actions={actions()}
        giftStatusContent={giftStatusContent}
        imageCTA={null}
        regItem={regItem}
        regItemLinkDecorator={regItemLink}
        quantityInformation={
          <QuantityInformation
            quantity={quantity}
            quantityNeeded={quantityNeeded}
          />
        }
        truncateTitle
      />
    </Wrapper>
  )
}

ReservableProductRegItemCard.propTypes = {
  giftStatusContent: PropTypes.string,
  regItem: PropTypes.shape(regItemProps).isRequired,
  reservationByCurrentVisitor: PropTypes.object,
}

ReservableProductRegItemCard.contextTypes = {
  isFacebookApp: PropTypes.bool,
  isMobile: PropTypes.bool,
  registry: PropTypes.object,
}

ReservableProductRegItemCard.defaultProps = {
  giftStatusContent: null,
  reservationByCurrentVisitor: null,
}

export default ReservableProductRegItemCard
