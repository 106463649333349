import classnames from 'classnames'
import css from './DiscountItem.styles.scss'

export const DiscountItem = ({
  label,
  amount,
  isCompact,
}: {
  label: string
  amount: string
  isCompact?: boolean
}) => (
  <div
    className={classnames(css.DiscountItem, {
      [css['DiscountItem--compact']]: isCompact,
    })}
  >
    <span>{label}</span>
    <span>-${amount}</span>
  </div>
)
