import { find, filter } from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Checkbox from 'components/forms/checkbox'
import CheckboxGroup from 'components/forms/checkbox-group'
import { InfoButton } from 'components/buttons'

const PRICE_RANGE = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
}

const RANGE_VALUES = {
  low: { min: 0, max: 50, labelText: 'Under $50' },
  medium: { min: 50, max: 100, labelText: '$50 to $100' },
  high: { min: 100, max: null, labelText: '$100+' },
}

export const PriceFilter = ({
  addFilter,
  className,
  decorator,
  expanded,
  label,
  selected,
}) => {
  const filterApplied = (range) => {
    if (!selected) return false

    return !!find(
      selected,
      (priceRange) =>
        priceRange.min === RANGE_VALUES[range].min &&
        priceRange.max === RANGE_VALUES[range].max
    )
  }

  const removeRangeFromFilters = (range) => {
    const filteredPriceRange = filter(selected, (priceFilter) => {
      return (
        priceFilter.min !== RANGE_VALUES[range].min &&
        priceFilter.max !== RANGE_VALUES[range].max
      )
    })
    addFilter({ priceRange: filteredPriceRange || [] })
  }

  const changePriceFilters = (range) => {
    // Range is "low", "medium", or "high"
    if (!selected) {
      // If there are no current price filters, add the price filter.
      addFilter({
        priceRange: [
          {
            min: RANGE_VALUES[range].min,
            max: RANGE_VALUES[range].max,
          },
        ],
      })
    } else if (filterApplied(range)) {
      // If this price filter is already applied, remove it.
      removeRangeFromFilters(range)
    } else {
      // If there are other price filters applied, add the price filter.
      selected.push({
        min: RANGE_VALUES[range].min,
        max: RANGE_VALUES[range].max,
      })
      addFilter({ priceRange: selected })
    }
  }

  return (
    <>
      {label && (
        <>
          <hr className="mbl" />
          <div className="h6 text-uppercase tiny text-bold text-light mtn mbs">
            Price
          </div>
        </>
      )}

      {decorator(
        <CheckboxGroup className={className}>
          <Checkbox
            name="price-filter"
            tappable
            label={RANGE_VALUES[PRICE_RANGE.LOW].labelText}
            defaultChecked={false}
            checked={filterApplied(PRICE_RANGE.LOW)}
            onChange={() => changePriceFilters(PRICE_RANGE.LOW)}
          />

          <Checkbox
            name="price-filter"
            tappable
            label={RANGE_VALUES[PRICE_RANGE.MEDIUM].labelText}
            defaultChecked={false}
            checked={filterApplied(PRICE_RANGE.MEDIUM)}
            onChange={() => changePriceFilters(PRICE_RANGE.MEDIUM)}
          />

          <Checkbox
            name="price-filter"
            tappable
            label={RANGE_VALUES[PRICE_RANGE.HIGH].labelText}
            defaultChecked={false}
            checked={filterApplied(PRICE_RANGE.HIGH)}
            onChange={() => changePriceFilters(PRICE_RANGE.HIGH)}
          />
        </CheckboxGroup>
      )}

      <div
        className={classNames('flex-row mrm mrl-md hidden-xs', {
          hidden: !expanded,
        })}
      >
        <InfoButton
          className="mrm mrl-md"
          empty={!filterApplied(PRICE_RANGE.LOW)}
          onClick={() => changePriceFilters(PRICE_RANGE.LOW)}
          pill
          size="small"
          selected={filterApplied(PRICE_RANGE.LOW)}
        >
          {RANGE_VALUES[PRICE_RANGE.LOW].labelText}
        </InfoButton>
        <InfoButton
          className="mrm mrl-md"
          empty={!filterApplied(PRICE_RANGE.MEDIUM)}
          onClick={() => changePriceFilters(PRICE_RANGE.MEDIUM)}
          pill
          size="small"
          selected={filterApplied(PRICE_RANGE.MEDIUM)}
        >
          {RANGE_VALUES[PRICE_RANGE.MEDIUM].labelText}
        </InfoButton>
        <InfoButton
          empty={!filterApplied(PRICE_RANGE.HIGH)}
          onClick={() => changePriceFilters(PRICE_RANGE.HIGH)}
          pill
          size="small"
          selected={filterApplied(PRICE_RANGE.HIGH)}
        >
          {RANGE_VALUES[PRICE_RANGE.HIGH].labelText}
        </InfoButton>
      </div>
    </>
  )
}

PriceFilter.propTypes = {
  addFilter: PropTypes.func.isRequired,
  className: PropTypes.string,
  decorator: PropTypes.func,
  expanded: PropTypes.bool,
  label: PropTypes.bool,
  selected: PropTypes.array,
}

PriceFilter.defaultProps = {
  className: null,
  decorator: (component) => component,
  label: false,
  expanded: false,
  selected: null,
}

export default PriceFilter
