import { useCallback, useEffect, useState } from 'react'
import { truncate } from 'lodash'
import { Button } from 'baby-design'
import classNames from 'classnames'
import { RegistryProfileIcon } from 'components/icons'
import Image from 'components/image'
import useClickAway from 'registry/components/useClickAway/useClickAway'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import GhostLine from 'shared/GhostLine/GhostLine'
import { AccountMenuItem } from '../AccountMenuItem/AccountMenuItem'
import { UPDATE_NAVBAR_USER_ICON } from '../../../../registry/constants'
import { useAccountMenuItems } from './AccountMenu.utils'

import css from './AccountMenu.styles.scss'

type ActiveMenu = 'account' | 'messageCenter' | 'cart'

interface AccountMenuProps {
  trackNavbarClicked: (eventCta: string) => void
  activeMenu: ActiveMenu
  onMenuOpen: () => void
}

export default function AccountMenu({
  trackNavbarClicked,
  activeMenu,
  onMenuOpen,
}: AccountMenuProps) {
  const [currentUser] = useCurrentUser()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [menuShouldClose, setMenuShouldClose] = useState(false)
  const [keepMenuOpen, setKeepMenuOpen] = useState(false)
  const { currentRegistry, firstName } = currentUser || {}
  const accountMenuItems = useAccountMenuItems({
    currentUser,
    trackNavbarClicked,
  })
  const useClickAwayRef = useClickAway<HTMLDivElement>(() => {
    setMenuIsOpen(false)
    setKeepMenuOpen(false)
  })

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (menuShouldClose && menuIsOpen) {
      timeout = setTimeout(() => {
        setMenuIsOpen(false)
      }, 500)
    }
    return () => clearTimeout(timeout)
  }, [menuShouldClose, menuIsOpen])

  const [photoUrl, setPhotoUrl] = useState(currentRegistry?.photoUrl)

  useEffect(() => {
    setPhotoUrl(currentRegistry?.photoUrl)
  }, [currentRegistry?.photoUrl])

  useEffect(() => {
    const updatePhotoUrl = ({ newUrl }: { newUrl: string }) =>
      setPhotoUrl(newUrl)
    PubSub.subscribe(UPDATE_NAVBAR_USER_ICON, updatePhotoUrl)
    return () => PubSub.unsubscribe(UPDATE_NAVBAR_USER_ICON, updatePhotoUrl)
  }, [])

  const avatar = photoUrl ? (
    <Image
      className="mrs circle-mask"
      height={34}
      lazyLoad={false}
      role="presentation"
      src={photoUrl}
      width={34}
    />
  ) : (
    <span aria-label="Baby Icon" className="mrs circle-mask bas pas" role="img">
      <RegistryProfileIcon height={20} width={20} />
    </span>
  )

  const handleMenuOpen = () => {
    setMenuShouldClose(false)
    setMenuIsOpen(true)
    onMenuOpen()
  }

  const handleMenuQuickClose = () => {
    setMenuIsOpen(false)
    setKeepMenuOpen(false)
  }

  const handleMenuClose = () => {
    setKeepMenuOpen(false)
    setMenuShouldClose(true)
  }

  const handleButtonClick = () => {
    if (!menuIsOpen) {
      handleMenuOpen()
      setKeepMenuOpen(true)
    } else if (!keepMenuOpen) {
      setKeepMenuOpen(true)
    } else {
      handleMenuQuickClose()
      setKeepMenuOpen(false)
    }
  }

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      handleMenuQuickClose()
    }
  }, [])

  useEffect(() => {
    // Close when escape is pressed anywhere on the page
    if (menuIsOpen) {
      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [menuIsOpen, handleKeyDown])

  useEffect(() => {
    if (menuIsOpen && activeMenu !== 'account') {
      handleMenuQuickClose()
    }
  }, [activeMenu, menuIsOpen])

  const handleMouseEnter = () => handleMenuOpen()
  const handleMouseLeave = () => !keepMenuOpen && handleMenuClose()
  const handleChildClick = () => handleMenuQuickClose()

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classNames(css.AccountMenu, 'btn-group', {
        open: menuIsOpen,
      })}
      ref={useClickAwayRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Button
        aria-expanded={menuIsOpen}
        aria-label="Open account menu."
        className={css.AccountMenu__button}
        id="account-menu-button"
        size="md"
        variant="text-primary"
        onClick={handleButtonClick}
      >
        <div className={css.AccountMenu__title}>
          {avatar}
          <span className={css.AccountMenu__userName}>
            {firstName === undefined && (
              <GhostLine className={css.AccountMenu__userNameLoadingState} />
            )}
            {firstName && truncate(firstName, { length: 13 })}
          </span>
        </div>
      </Button>
      <ul
        aria-labelledby="account-menu-button"
        className={classNames(css.AccountMenu__dropdown, 'dropdown-menu')}
        id="account-menu"
        role="menu"
      >
        {accountMenuItems.map((item) => (
          <AccountMenuItem
            {...item}
            key={item.label}
            onClick={(e) => {
              handleChildClick()
              item.onClick?.(e)
            }}
          />
        ))}
      </ul>
    </div>
  )
}
