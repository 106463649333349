import PropTypes from 'prop-types'
import classNames from 'classnames'
import { DefaultButton, InfoButton } from 'components/buttons'
import css from './form-button-group.scss'

const FormButtonGroup = ({
  className,
  onClickPrimary,
  onClickSecondary,
  primaryButtonDisabled,
  secondaryButtonDisabled,
  submit,
  submitting,
  textPrimary,
  textSecondary,
  primaryButtonComponent,
  secondaryButtonComponent,
}) => {
  if (!submit && !onClickPrimary) {
    throw new Error(
      'Must specify at least one of the submit or onClickPrimary props.'
    )
  }

  const PrimaryButtonComponent = primaryButtonComponent
  const SecondaryButtonComponent = secondaryButtonComponent

  return (
    <div
      className={classNames(
        css['form-button-group'],
        css.actionWrapper,
        className
      )}
    >
      <div className={css.actionContent}>
        <PrimaryButtonComponent
          className={classNames(
            css['form-button-group__primary-button'],
            'full-width'
          )}
          disabled={primaryButtonDisabled}
          onClick={onClickPrimary}
          submitting={submitting}
          pill
          type={submit ? 'submit' : false}
        >
          {textPrimary}
        </PrimaryButtonComponent>
      </div>

      <div className={css.actionContent}>
        {onClickSecondary && (
          <SecondaryButtonComponent
            className={classNames(
              css['form-button-group__secondary-button'],
              'full-width'
            )}
            disabled={secondaryButtonDisabled}
            empty
            pill
            onClick={onClickSecondary}
          >
            {textSecondary}
          </SecondaryButtonComponent>
        )}
      </div>
    </div>
  )
}

FormButtonGroup.propTypes = {
  className: PropTypes.string,
  onClickPrimary: PropTypes.func,
  onClickSecondary: PropTypes.func,
  submitting: PropTypes.bool,
  textPrimary: PropTypes.string,
  textSecondary: PropTypes.string,
  primaryButtonDisabled: PropTypes.bool,
  secondaryButtonDisabled: PropTypes.bool,
  submit: PropTypes.bool,
  primaryButtonComponent: PropTypes.func,
  secondaryButtonComponent: PropTypes.func,
}

FormButtonGroup.defaultProps = {
  className: undefined,
  submit: true,
  textPrimary: 'Save',
  textSecondary: 'Cancel',
  secondaryButtonDisabled: false,
  submitting: false,
  primaryButtonComponent: InfoButton,
  primaryButtonDisabled: false,
  onClickPrimary: undefined,
  onClickSecondary: undefined,
  secondaryButtonComponent: DefaultButton,
}

export default FormButtonGroup
