import React, { useState } from 'react'
import classNames from 'classnames'

import css from './TileSelector.styles.scss'

import { TileSelectorProps } from './TileSelector.types'
import { Checkbox, RadioButton } from 'baby-design'

const TileSelector: React.FC<TileSelectorProps> = ({
  variant = 'default',
  dangerouslyOverrideClassName,
  title,
  copy,
  selectedContent,
  control,
  icon,
  size = 'md',
  selected,
  align = 'left', // Default alignment to 'left'
  onClick,
  unavailable,
  logo,
}) => {
  const [formActive, setFormActive] = useState(false)

  const handleFormFocus = () => {
    setFormActive(true)
  }

  const handleFormBlur = () => {
    setFormActive(false)
  }

  const isHeavy =
    variant === 'heavy' || control === 'checkbox' || control === 'radio'

  const TileSelectorClasses = classNames(
    css.TileSelector,
    dangerouslyOverrideClassName,
    {
      [css.TileSelector__Default]: variant === 'default' && !isHeavy,
      [css.TileSelector__Logo]: variant === 'logo',
      [css.TileSelector__Heavy]: isHeavy, // Apply the heavy class if needed
      [css.TileSelector__Selected]: selected,
      [css.TileSelector__AlignLeft]: align === 'left',
      [css.TileSelector__AlignCenter]: align === 'center',
      [css.TileSelector__AlignRight]: align === 'right',
      [css.TileSelector__Checkbox]: control === 'checkbox',
      [css.TileSelector__Radio]: control === 'radio',
      [css.TileSelector__Small]: size === 'sm',
      [css.TileSelector__Medium]: size === 'md',
      [css.TileSelector__Large]: size === 'lg',
      [css.TileSelector__ExtraLarge]: size === 'xl',
      [css.TileSelector__FormActive]: formActive,
      [css.TileSelector__Unavailable]: unavailable,
    }
  )

  // Handles mouse click events for the button
  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    if (unavailable) {
      e.preventDefault()
      return
    }
    e.currentTarget.blur()
    onClick?.() // Call onClick without event arguments
  }

  // Handles click events for input elements (checkbox or radio)
  const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (unavailable) {
      e.preventDefault()
      return
    }
    e.currentTarget.blur()
    onClick?.()
  }

  // Handles change events for input elements
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (unavailable) {
      e.preventDefault()
      return
    }
    onClick?.() // Invoke handleClick-like behavior for change events
  }

  logo

  if (variant === 'logo') {
    return (
      <button
        className={TileSelectorClasses}
        data-testid="TileSelector"
        type="button"
        onClick={handleButtonClick} // Apply onClick for the button itself
      >
        {control === 'radio' && (
          <div data-testid="radio-input">
            <RadioButton
              className={css.TileSelector__Control}
              size="md"
              inputProps={{
                checked: selected,
                onChange: handleChange, // Attach handleChange to onChange
                onClick: handleInputClick, // Attach input-specific click handler
              }}
            />
          </div>
        )}
        {control === 'checkbox' && (
          <div data-testid="checkbox-input">
            <Checkbox
              className={css.TileSelector__Control}
              size="md"
              inputProps={{
                checked: selected,
                onChange: handleChange, // Attach handleChange to onChange
                onClick: handleInputClick, // Attach input-specific click handler
              }}
            />
          </div>
        )}
        <div className={css.TileSelector__Logo}>{logo}</div>
      </button>
    )
  }

  if (isHeavy) {
    return (
      <button
        className={TileSelectorClasses}
        data-testid="TileSelector"
        type="button"
        onClick={handleButtonClick} // Apply onClick for the button itself
      >
        <div className={classNames(css.TileSelector__MainContent, {})}>
          {control === 'radio' && (
            <div data-testid="radio-input">
              <RadioButton
                className={css.TileSelector__Control}
                size="md"
                inputProps={{
                  checked: selected,
                  onChange: handleChange, // Attach handleChange to onChange
                  onClick: handleInputClick, // Attach input-specific click handler
                }}
              />
            </div>
          )}
          {control === 'checkbox' && (
            <div data-testid="checkbox-input">
              <Checkbox
                className={css.TileSelector__Control}
                size="md"
                inputProps={{
                  checked: selected,
                  onChange: handleChange, // Attach handleChange to onChange
                  onClick: handleInputClick, // Attach input-specific click handler
                }}
              />
            </div>
          )}

          <div className={css.TileSelector__Content}>
            {title && <div className={css.TileSelector__Title}>{title}</div>}
            <div className={css.TileSelector__Copy}>{copy}</div>
          </div>
        </div>
        {selected && selectedContent && (
          <div
            className={css.TileSelector__SelectedContent}
            onClick={(e) => e.stopPropagation()}
            onFocus={handleFormFocus}
            onBlur={handleFormBlur}
          >
            {selectedContent}
          </div>
        )}
      </button>
    )
  }
  return (
    <button
      className={TileSelectorClasses}
      data-testid="TileSelector"
      type="button"
      onClick={handleButtonClick}
    >
      {icon && <div className={css.TileSelector__Icon}>{icon.icon}</div>}

      <div className={css.TileSelector__Content}>
        {title && <div className={css.TileSelector__Title}>{title}</div>}
        <div className={css.TileSelector__Copy}>{copy}</div>
      </div>
    </button>
  )
}

export default TileSelector
