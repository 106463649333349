import PropTypes from 'prop-types'
import classNames from 'classnames'
import { numberToCurrency } from 'lib/money'
import {
  ELECTRONIC_GIFT_CARD_MIN,
  ELECTRONIC_GIFT_CARD_MAX,
} from 'shared/constants'

const PriceDisplay = ({
  isElectronicGiftCard,
  isFavor,
  price,
  priceClassName,
  msrpClassName,
  truncateZeroCents,
  showPriceRange,
}) => {
  let showMsrp = false
  const options = { truncateZeroCents }

  // eslint-disable-next-line complexity
  const priceString = () => {
    if (isElectronicGiftCard) {
      return `$${ELECTRONIC_GIFT_CARD_MIN} - $${ELECTRONIC_GIFT_CARD_MAX}`
    }

    if (!price || isFavor) return null

    const min =
      price.min !== undefined && price.min !== null ? price.min : price.current
    const max =
      price.max !== undefined && price.max !== null ? price.max : price.current

    const priceDiffThreshold = 1.05
    const greaterThanCurrentPrice =
      price.msrp &&
      price.current &&
      price.msrp > price.current * priceDiffThreshold
    const greaterThanMaxPrice =
      min === max && price.msrp && price.msrp > max * priceDiffThreshold
    showMsrp = greaterThanCurrentPrice || greaterThanMaxPrice

    if (showPriceRange) {
      return min === max
        ? numberToCurrency(min, options)
        : `${numberToCurrency(min, options)} - ${numberToCurrency(
            max,
            options
          )}`
    }

    return `${numberToCurrency(min, options)}${max > min ? '+' : ''}`
  }

  const displayPrice = priceString()
  const msrpDisplayPrice = showMsrp
    ? numberToCurrency(price.msrp, options)
    : null

  return (
    <>
      <span aria-hidden className={classNames('text-bold', priceClassName)}>
        {displayPrice}
      </span>
      {showMsrp ? (
        <s
          aria-hidden
          className={classNames('text-muted', msrpClassName, {
            mls: !msrpClassName,
          })}
        >
          {msrpDisplayPrice}
        </s>
      ) : null}
      <span className="visually-hidden">
        {showMsrp ? `original price: ${msrpDisplayPrice}, sale price: ` : null}
        {displayPrice}.
      </span>
    </>
  )
}

PriceDisplay.propTypes = {
  isElectronicGiftCard: PropTypes.bool,
  isFavor: PropTypes.bool,
  price: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    current: PropTypes.number,
    msrp: PropTypes.number,
  }).isRequired,
  priceClassName: PropTypes.string,
  msrpClassName: PropTypes.string,
  truncateZeroCents: PropTypes.bool,
  showPriceRange: PropTypes.bool,
}

PriceDisplay.defaultProps = {
  priceClassName: '',
  msrpClassName: '',
  isElectronicGiftCard: false,
  isFavor: false,
  truncateZeroCents: false,
  showPriceRange: false,
}

export default PriceDisplay
