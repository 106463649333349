import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import { normalizeEmail } from '../../lib/form-normalization'

import cart, * as fromCart from './cart-reducer'
import registry from './registry-reducer'
import reservations, * as fromReservations from './reservations-reducer'
import ui, * as fromUi from './ui-reducer'
import user, * as fromUser from './user-reducer'

export const getActiveModal = (state) => fromUi.getActiveModal(state.ui)
export const getActiveModalProps = (state) =>
  fromUi.getActiveModalProps(state.ui)

export const getCartItems = (state) => fromCart.getCartItems(state.cart)
export const getProductRecommendations = (state) =>
  fromCart.getProductRecommendations(state.cart)

export const getGiftMessageReservation = (state) => {
  return fromReservations.getGiftMessageReservation(state.reservations)
}

export const getGiftMessage = (state) => {
  return fromReservations.getGiftMessage(state.reservations)
}

export const getRegistry = (state) => state.registry
export const getUser = (state) => state.user
export const getLoggedIn = (state) => fromUser.getLoggedIn(getUser(state))

export const giftGiverReducer = combineReducers({
  cart,
  registry,
  reservations,
  ui,
  user,
  form: formReducer.normalize({
    addToCart: {
      email: normalizeEmail,
    },
    didNotBuy: {
      email: normalizeEmail,
    },
    purchase: {
      email: normalizeEmail,
    },
  }),
})
