const SearchMagnifyingGlass = ({ className }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.85236 2.58214C9.30769 4.03747 9.30769 6.39703 7.85236 7.85236C6.39703 9.30769 4.03747 9.30769 2.58214 7.85236C1.12681 6.39703 1.12681 4.03747 2.58214 2.58214C4.03747 1.12681 6.39703 1.12681 7.85236 2.58214ZM9.39607 8.34202C10.9282 6.29848 10.7649 3.38662 8.9064 1.5281C6.86894 -0.509366 3.56556 -0.509366 1.5281 1.5281C-0.509366 3.56556 -0.509366 6.86894 1.5281 8.9064C3.38663 10.7649 6.29849 10.9282 8.34203 9.39606L10.7277 11.7817C11.0187 12.0728 11.4906 12.0728 11.7817 11.7817C12.0728 11.4906 12.0728 11.0187 11.7817 10.7277L9.39607 8.34202Z"
      fill="#949494"
    />
  </svg>
)

export default SearchMagnifyingGlass
