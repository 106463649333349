import Cookies from 'js-cookie'

const baseDomain = () => {
  return window.location.hostname.split('.').slice(-2).join('.')
}

export const setCookies = (
  data: any,
  expireDate = 'Fri, 31 Dec 9999 23:59:59 GMT'
) => {
  Object.entries(data).forEach(([k, v]: [any, any]) => {
    document.cookie = `${k}=${encodeURIComponent(
      v
    )}; expires=${expireDate}; path=/; domain=.${baseDomain()}`
  })
}

export const getBooleanCookie = ({
  name,
  defaultValue,
}: {
  name: string
  defaultValue: boolean
}) => {
  // cookie value is "true" or "false" but we want defaultValue by default
  let currentValue = Cookies.get(name)
  if (typeof currentValue === 'undefined') {
    currentValue = defaultValue ? 'true' : 'false'
  }
  return currentValue === 'true'
}

export const useGiftGiverCookie = () => {
  const name = Cookies.get('name')
  const email = Cookies.get('email')
  const subscribeToMarketingEmail = getBooleanCookie({
    name: 'subscribeToMarketingEmail',
    defaultValue: true,
  })

  return { name, email, subscribeToMarketingEmail }
}

export const setGiftGiverCookie = ({
  name,
  email,
  subscribeToMarketingEmail,
}: {
  name?: string
  email?: string
  subscribeToMarketingEmail?: boolean
}) => {
  const cookieData: any = {
    name,
    email,
    subscribeToMarketingEmail,
  }

  Object.keys(cookieData).forEach((key) =>
    cookieData[key] === undefined ? delete cookieData[key] : {}
  )
  setCookies(cookieData)
}

/**
 * Returns the name of the test group stored in Split cookie.
 * @param cookieName - Name of the cookie set in Split.
 * @returns { string | undefined } - Returns string value of control or experiment. Returns undefined if cookie is not found.
 */
export function getSplitCookieValue(cookieName: string): string | undefined {
  const cookie = Cookies.get('split')

  if (cookie) {
    const cookieObj = JSON.parse(cookie)
    const cookieNamePattern = `^${cookieName}[:0-9]*$`
    const cookieIndex = Object.keys(cookieObj)
      .filter((key) => new RegExp(cookieNamePattern).test(key))
      ?.pop()

    if (cookieIndex) {
      return cookieObj[cookieIndex]
    }
  }
}

export const setLastPageLoadCookie = () => {
  const currentDateInMilliseconds = new Date().getTime()
  const thirtyMinsInMilliseconds = 30 * 60 * 1000
  const expireDate = new Date(
    currentDateInMilliseconds + thirtyMinsInMilliseconds
  )
  setCookies(
    { lastPageLoad: currentDateInMilliseconds },
    expireDate.toUTCString()
  )
}

export const getLastPageLoadCookie = () => Cookies.get('lastPageLoad')

export const setSessionStartedUrl = (url: string) => {
  // This cookie is only read from the BE in app/helpers/bl_registry/referrer.rb
  setCookies({ session_started_url: url })
}
