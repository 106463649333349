import React from 'react'
import classNames from 'classnames'
import { BodyProps } from './Body.types'
import css from './Body.styles.scss'

const Body: React.FC<BodyProps> = ({ cta, text, title, variant }) => {
  const bodyClasses = classNames(css.Body, {
    [css[`Body--${variant}`]]: variant,
  })
  const titleClasses = classNames(
    css.Body__title,
    css[`Body__title--${variant}`]
  )
  const textClasses = classNames(css.Body__text, css[`Body__text--${variant}`])
  const textContainerClasses = classNames(css.Body__textContainer, {
    [css['Body__textContainer--no-title']]: !title,
  })

  return (
    <div className={bodyClasses} data-testid="callout-body">
      <div className={textContainerClasses}>
        {title && <span className={titleClasses}>{title}</span>}
        <span className={textClasses} data-testid="callout-body-text">
          {text}
        </span>
      </div>
      {cta && <div className={css.Body__cta}>{cta}</div>}
    </div>
  )
}

export default Body
