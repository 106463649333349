import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CrowdfundReserveFormFlow from 'registry/components/CrowdfundReserveFormFlow/CrowdfundReserveFormFlow'
import { getIsGiftGiverThankYouAddressProvided } from '../../cart/reducers/reservations-reducer'
import { getFirstNames, getReservationsByCurrentVisitor } from '../reducers'
import { getRegistry } from '../../cart/reducers'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import LegacyCrowdfundReserveForm from 'registry/components/LegacyCrowdfundReserveForm/LegacyCrowdfundReserveForm'

export const CrowdfundReserveForm = (props) => {
  const { flagValue: shouldUseNewContributionFlow } = useFeatureFlag(
    '24d_ff_regex_cash_fund_upgrades_web',
    false
  )

  if (shouldUseNewContributionFlow) {
    return <CrowdfundReserveFormFlow {...props} />
  }

  return <LegacyCrowdfundReserveForm {...props} />
}

CrowdfundReserveForm.propTypes = {
  preferences: PropTypes.shape({
    address: PropTypes.object,
    direct: PropTypes.bool,
    hasPaymentOption: PropTypes.bool,
    paypalme: PropTypes.string,
  }).isRequired,
  regItem: PropTypes.object.isRequired,
  registryFirstNames: PropTypes.string.isRequired,
  onCreateReservation: PropTypes.func.isRequired,
  onUpdateReservation: PropTypes.func.isRequired,
  onRemoveReservation: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  isGiftGiverThankYouAddressProvided: PropTypes.bool.isRequired,
  registry: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  const reservations = Object.values(getReservationsByCurrentVisitor(state))
  const registry = getRegistry(state)
  return {
    registry,
    registryFirstNames: getFirstNames(state),
    isGiftGiverThankYouAddressProvided:
      getIsGiftGiverThankYouAddressProvided(reservations),
  }
}

export default connect(mapStateToProps)(CrowdfundReserveForm)
