import React from 'react'

interface InfoProps {
  className?: string
  filled?: boolean
}

const Info: React.FC<InfoProps> = ({ className, filled }: InfoProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {filled ? (
      <path
        clipRule="evenodd"
        d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 6.71094C12.4142 6.71094 12.75 7.04672 12.75 7.46094V7.5C12.75 7.91421 12.4142 8.25 12 8.25C11.5858 8.25 11.25 7.91421 11.25 7.5V7.46094C11.25 7.04672 11.5858 6.71094 12 6.71094ZM10.25 10C10.25 9.58579 10.5858 9.25 11 9.25C11.9665 9.25 12.75 10.0335 12.75 11V15C12.75 15.1381 12.8619 15.25 13 15.25C13.4142 15.25 13.75 15.5858 13.75 16C13.75 16.4142 13.4142 16.75 13 16.75C12.0335 16.75 11.25 15.9665 11.25 15V11C11.25 10.8619 11.1381 10.75 11 10.75C10.5858 10.75 10.25 10.4142 10.25 10Z"
        fill="#252324"
        fillRule="evenodd"
      />
    ) : (
      <path
        clipRule="evenodd"
        d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 6.71094C12.4142 6.71094 12.75 7.04672 12.75 7.46094V7.5C12.75 7.91421 12.4142 8.25 12 8.25C11.5858 8.25 11.25 7.91421 11.25 7.5V7.46094C11.25 7.04672 11.5858 6.71094 12 6.71094ZM10.25 10C10.25 9.58579 10.5858 9.25 11 9.25C11.9665 9.25 12.75 10.0335 12.75 11V15C12.75 15.1381 12.8619 15.25 13 15.25C13.4142 15.25 13.75 15.5858 13.75 16C13.75 16.4142 13.4142 16.75 13 16.75C12.0335 16.75 11.25 15.9665 11.25 15V11C11.25 10.8619 11.1381 10.75 11 10.75C10.5858 10.75 10.25 10.4142 10.25 10Z"
        fill="#252324"
        fillRule="evenodd"
      />
    )}
  </svg>
)

export default Info
