import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Media, Row } from 'react-bootstrap'
import { Link } from '@material-ui/core'
import * as blUrls from 'lib/urls'
import { endOfDay } from 'date-fns'
import dateformat from 'dateformat'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'

const nameWithPostnominals = (author) => {
  if (!author) {
    return ''
  }
  let name = `${author.firstName} ${author.lastName}`
  if (!author.firstName && !author.lastName) {
    name = author.name
  }
  if (author.postnominals) {
    name += ` ${author.postnominals}`
  }
  if (!author.slug) {
    return name
  }
  return <Link href={blUrls.helloBabyAuthors(author.slug)}>{name}</Link>
}

const AuthorNames = (authors) => {
  // one author: X
  // just two authors:  X and Y
  // three + authors: X, Y and Z

  if (authors.length == 0) {
    return 'Babylist Team'
  }
  return (
    <>
      {authors.map((author, index) => {
        const authorName = nameWithPostnominals(author)
        return (
          <Fragment key={index}>
            {authors.length == 2 && index == 1 && ' and '}
            {authors.length > 2 &&
              index != authors.length - 1 &&
              index > 0 &&
              ', '}
            {authors.length > 2 && index == authors.length - 1 && ' and '}
            {authorName}
          </Fragment>
        )
      })}
    </>
  )
}

const AuthorByline = ({ post, className, showContributersMobile }) => {
  const isRebrand = useFeatureFlag('guides-above-the-fold', false).flagValue
  const { medicallyReviewedBy, factCheckedBy, publishedAt, updatedAt } = post
  const reviewerName = nameWithPostnominals(medicallyReviewedBy)
  const factCheckerName = nameWithPostnominals(factCheckedBy)
  const publishedDate = dateformat(
    endOfDay(new Date(publishedAt)),
    'mmm d, yyyy'
  )
  const updatedDate = dateformat(endOfDay(new Date(updatedAt)), 'mmm d, yyyy')

  let altText = `{authorName} {jobTitle}`

  const reviewer =
    medicallyReviewedBy &&
    (isRebrand ? (
      <span className="author-text-margin">
        Medically Reviewed by{' '}
        <span className="text-contributers">{reviewerName}</span>
      </span>
    ) : (
      <>| Medically Reviewed by {reviewerName}</>
    ))
  const factChecker =
    factCheckedBy &&
    (isRebrand ? (
      <span className="author-text-margin">
        Fact Checked by{' '}
        <span className="text-contributers">{factCheckerName}</span>
      </span>
    ) : (
      <>| Fact Checked by {factCheckerName}</>
    ))

  return isRebrand ? (
    <Row className={className}>
      <Media className="col-sm-12">
        <Media.Body>
          <h6 className="author-text-margin">
            By{' '}
            <span className="author-text text-contributers">
              {AuthorNames(post.author)}
            </span>
          </h6>
          <h6 className="mts h7 above-the-fold-desktop-contributers">
            {reviewer}
            {reviewer && <br />}
            {factChecker}
          </h6>
          <div className="above-the-fold-dates">
            <span className="above-the-fold-publishedAt">
              <>Published </> {publishedDate}
            </span>
            {post.updatedAt && (
              <>
                <span className="above-the-fold-text above-the-fold-seperator">
                  |
                </span>
                <span className="above-the-fold-publishedAt">
                  <>Updated On </> {updatedDate}
                </span>
              </>
            )}
          </div>
          {showContributersMobile && (
            <h6 className="mts h7 above-the-fold-mobile-contributers">
              {reviewer}
              {reviewer && <br />}
              {factChecker}
            </h6>
          )}
        </Media.Body>
      </Media>
    </Row>
  ) : (
    <Row className={className}>
      <Media className="col-sm-12">
        <Media.Body>
          <h6 className="text-muted mts h7">
            By {AuthorNames(post.author)} {reviewer} {factChecker}
          </h6>
        </Media.Body>
      </Media>
    </Row>
  )
}

AuthorByline.propTypes = {
  post: PropTypes.object.isRequired,
  showContributersMobile: PropTypes.bool,
  className: PropTypes.string,
}

export default AuthorByline
