import { uniq } from 'lodash'
import { CountrySelectorProps } from 'components/forms/CountrySelector/CountrySelector'

type Inputs = Pick<CountrySelectorProps, 'countries'>

export default class CountrySelectorUtils {
  private readonly countries: string[]

  constructor({ countries }: Inputs) {
    this.countries = countries
  }

  makeRankedCountries(topCountries: string[]) {
    // If no countries were provided
    if (this.countries.length === 0) {
      return []
    }

    const uniqueTopCountries = uniq(topCountries)
    // Add the top countries to the top of the list if they are present
    const rankedCountries: string[] = []
    uniqueTopCountries.forEach((country) => {
      if (this.countries.includes(country)) {
        // check for collisions by seeing if either the element after or before is the same
        rankedCountries.push(country)
      }
    })

    // If the countries provided are the same as the top countries
    if (
      uniq([...uniqueTopCountries, ...this.countries]).length ===
      uniqueTopCountries.length
    ) {
      return rankedCountries
    }

    return [...rankedCountries, ...this.countries]
  }
}
