import { Component } from 'react'
import PropTypes from 'prop-types'
import BSAlert from 'react-bootstrap/lib/Alert'
import { values } from 'lodash'
import classNames from 'classnames'
import { SuccessIcon, DangerIcon, InfoIcon } from 'components/icons'

/**
 * Standard bootstrap alert sizes. Defined here so we can customize if
 * needed and allow for introspection for building up the styleguide.
 */
const sizes = ['small', 'medium', 'large']

export const VARIANTS = {
  DANGER: 'danger',
  DEFAULT: 'default',
  INFO: 'info',
  SUCCESS: 'success',
}

const variantProp = PropTypes.oneOf(values(VARIANTS))
variantProp.values = values(VARIANTS)

const sizeProp = PropTypes.oneOf(sizes)
sizeProp.values = sizes

const AlertIcon = ({ variant }) => {
  switch (variant) {
    case VARIANTS.DANGER:
      return <DangerIcon height={30} width={30} className="mrm" />
    case VARIANTS.INFO:
      return <InfoIcon height={30} width={30} className="mrm" />
    case VARIANTS.SUCCESS:
      return <SuccessIcon height={30} width={30} className="mrm" />
    default:
      return <></>
  }
}

AlertIcon.propTypes = {
  variant: variantProp,
}

AlertIcon.defaultProps = {
  variant: VARIANTS.DEFAULT,
}

export const Alert = ({
  className,
  children,
  closeLabel,
  emptyBackground,
  icon,
  onClick,
  onDismiss,
  size,
  style,
  variant,
}) => {
  const alertClasses = classNames(className, {
    'bg-transparent': !!emptyBackground,
  })

  const alertProps = {
    bsStyle: variant,
    bsSize: size,
    children,
    className: alertClasses,
    closeLabel,
    icon,
    onDismiss,
    onClick,
    style,
  }

  return (
    <BSAlert {...alertProps}>
      <div className="flex flex-grow">
        <AlertIcon variant={variant} />
        <span style={{ alignSelf: 'center' }}>{children}</span>
      </div>
    </BSAlert>
  )
}

Alert.propTypes = {
  size: sizeProp,
  variant: variantProp,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeLabel: PropTypes.string,
  emptyBackground: PropTypes.bool,
  icon: PropTypes.node,
  onDismiss: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
}

Alert.defaultProps = {
  size: 'medium',
  variant: VARIANTS.DEFAULT,
  className: '',
  closeLabel: 'Close',
  emptyBackground: false,
  icon: false,
  onDismiss: null,
  onClick: null,
  style: null,
}

export const DefaultAlert = (props) => (
  <Alert {...props} variant={VARIANTS.DEFAULT} />
)

export const DangerAlert = (props) => (
  <Alert {...props} variant={VARIANTS.DANGER} />
)

export const SuccessAlert = (props) => (
  <Alert {...props} variant={VARIANTS.SUCCESS} />
)
export const InfoAlert = (props) => <Alert {...props} variant={VARIANTS.INFO} />

export class EaseInOutAlert extends Component {
  static propTypes = {
    animationDuration: PropTypes.number,
    onExited: PropTypes.func,
    timeoutUntilEaseOut: PropTypes.number,
  }

  static defaultProps = {
    animationDuration: 1000,
    timeoutUntilEaseOut: 3000,
  }

  easeOut = null
  state = {
    animationClass: 'fadeIn',
  }

  componentDidMount() {
    this.easeOut = this.startDelayedEaseOut()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.props.id) {
      this.setState({ animationClass: 'fadeIn' })
      clearTimeout(this.easeOut)
      this.easeOut = this.startDelayedEaseOut()
    }
  }

  startDelayedEaseOut = () => {
    let boundThis = this
    return setTimeout(function () {
      boundThis.setState({ animationClass: 'fadeOut' })

      setTimeout(function () {
        boundThis.props.onExited && boundThis.props.onExited()
      }, boundThis.props.animationDuration)
    }, this.props.timeoutUntilEaseOut + this.props.animationDuration)
  }

  render() {
    return (
      <Alert
        {...this.props}
        className={classNames(
          this.props.className,
          'animated',
          this.state.animationClass
        )}
        style={{
          animationDuration: `${this.props.animationDuration / 1000.0}s`,
        }}
      />
    )
  }
}
