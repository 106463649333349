import * as React from 'react'
// @ts-ignore
import Modal from 'components/modal/modal'
import { ModalHeader, ModalTitle, ModalBody } from 'react-bootstrap'
import { ShippingIcon, CloseIcon, ChevronLeftIcon } from 'components/icons'
import { PrimaryButton } from 'components/buttons'
// @ts-ignore
import Spinner from 'shared/spinner'
import NoAddressProvidedDisclosure from './NoAddressProvidedDisclosure'
import { Responses } from '../../../../global'
import useContactInfoAddress from './useContactInfoAddress'
import AddressDoesNotTransferDisclaimer from './AddressDoesNotTransferDisclaimer'
import GoogleRecaptchaDisclosureTag from 'components/google-recaptcha-disclosure-tag'
import css from './ContactInfoModal.scss'

export interface ContactInfoModalProps {
  /**
   * The button that allows the user to continue to the store.
   */
  continueToStoreButton: React.ReactNode
  /**
   * Callback fired when the back button is clicked.
   */
  onBackButtonClick?: () => void
  /**
   * Callback fired when the close button is clicked.
   */
  onHide?: () => void
  /**
   * A registry responder struct.
   */
  registry: Responses.Registry
}

// @ts-ignore
export default function ContactInfoModal({
  continueToStoreButton,
  onBackButtonClick,
  onHide,
  registry,
}: ContactInfoModalProps) {
  const recipientFirstName = registry?.attributeData?.firstName
  const { isFetching, data: contactInfo } = useContactInfoAddress({
    addressId: registry.addressId,
    enabled: !registry.isShippingAddressPrivate && !!registry.addressId,
    reCaptchaAction: 'contact_info_modal',
  })

  return (
    // @ts-ignore
    <Modal show>
      <ModalHeader className="flex space-between row baseline">
        {onBackButtonClick && (
          <PrimaryButton
            aria-label="back"
            className="btn-unstyled"
            onClick={onBackButtonClick}
          >
            <ChevronLeftIcon height="16" />
          </PrimaryButton>
        )}

        <ModalTitle className="h3" style={{ textAlign: 'center' }}>
          Copy Shipping Address
        </ModalTitle>

        <PrimaryButton
          aria-label="close"
          className="btn-unstyled"
          onClick={onHide}
        >
          <CloseIcon height="18" />
        </PrimaryButton>
      </ModalHeader>

      <ModalBody className="flex col align-center">
        {/* @ts-ignore */}
        <ShippingIcon className="hidden-xs hidden-sm" />

        {/* eslint-disable-next-line no-nested-ternary */}
        {isFetching ? (
          <div className="flex align-center" style={{ height: 180 }}>
            <Spinner inverse={false} />
          </div>
        ) : contactInfo ? (
          <div className="pvl">
            <AddressDoesNotTransferDisclaimer />
            <div className="phl mtm mlm">
              {contactInfo.fullName}
              <br />
              {contactInfo.address.streetAddress1}{' '}
              {contactInfo.address.streetAddress2 ? (
                <span>, {contactInfo.address.streetAddress2}</span>
              ) : null}
              <br />
              {contactInfo.address.city}, {contactInfo.address.state}{' '}
              {contactInfo.address.postalCode}
            </div>
          </div>
        ) : (
          <div className="mvl">
            <NoAddressProvidedDisclosure
              name={recipientFirstName || registry.ownerFirstName}
            />
          </div>
        )}

        {continueToStoreButton}

        <GoogleRecaptchaDisclosureTag className={css.grecaptchaLegalNotice} />
      </ModalBody>
    </Modal>
  )
}
