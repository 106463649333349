import { useState } from 'react'
import PropTypes from 'prop-types'
import { enUS } from 'date-fns/locale'
import { DatePicker } from 'react-nice-dates'
import { withClientRender } from 'components/wrappers'
import classnames from 'classnames'
import Input from './input'

const DatePickerInput = ({
  className,
  name,
  placeholder,
  minimumDate,
  maximumDate,
  handleChange,
  initialValue,
  ...rest
}) => {
  const [date, setDate] = useState(initialValue)

  const handleDateChange = (newDate) => {
    setDate(newDate)
    handleChange(newDate)
  }

  return (
    <DatePicker
      date={date}
      locale={enUS}
      format="MMMM d, yyyy"
      minimumDate={minimumDate}
      maximumDate={maximumDate}
      onDateChange={handleDateChange}
    >
      {({ inputProps, focused }) => (
        <div className="form-group">
          <input
            {...inputProps}
            name={name}
            autoComplete="off"
            placeholder={placeholder}
            className={classnames(
              className,
              `input form-control ${focused ? 'focused' : ''}`
            )}
            readOnly={false}
            {...rest}
          />
        </div>
      )}
    </DatePicker>
  )
}

DatePickerInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  minimumDate: PropTypes.instanceOf(Date),
  maximumDate: PropTypes.instanceOf(Date),
  initialValue: PropTypes.instanceOf(Date),
}

DatePickerInput.defaultProps = {
  className: undefined,
  handleChange: () => {},
  minimumDate: undefined,
  maximumDate: undefined,
  name: 'datepicker-input',
  placeholder: 'Please select a date...',
  initialValue: new Date(),
}

export default withClientRender({
  placeholder: <Input label="Disabled Date Selector" disabled />,
})(DatePickerInput)
