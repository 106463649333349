export interface NoAddressProvidedDisclosureProps {
  /**
   * The registrant's name.
   */
  name: string
}

export default function NoAddressProvidedDisclosure({
  name,
}: NoAddressProvidedDisclosureProps) {
  return (
    <p className="text-center mhl">
      <span className="text-bold">{name}'s shipping address is private.</span>{' '}
      We recommend reaching out to them directly to request it.
    </p>
  )
}
