import PropTypes from 'prop-types'
import { Input } from 'components/forms'

const GiftMessageFields = ({ name, note, handleChange }) => {
  return (
    <fieldset>
      <Input
        label="Gift Message"
        type="textarea"
        onChange={handleChange}
        name="description"
        placeholder="Add an optional message to include with these gifts."
        style={{ height: 150 }}
        {...note}
      />
      <Input
        label="From"
        groupClassName="mbs"
        name="from"
        placeholder="Include the name(s) you'd like to appear next your gift message."
        {...name}
      />
    </fieldset>
  )
}

GiftMessageFields.propTypes = {
  name: PropTypes.object,
  note: PropTypes.object,
  handleChange: PropTypes.func,
}

export default GiftMessageFields
