import PropTypes from 'prop-types'
import classNames from 'classnames'
import { InfoButton } from 'components/buttons'

const GroupGiftFilter = ({ addFilter, className, label, selected }) => (
  <>
    {label && (
      <>
        <hr className="mbl" />
        <div className="h6 text-uppercase text-bold text-light tiny mtn mbs">
          {label}
        </div>
      </>
    )}

    <InfoButton
      className={classNames(className)}
      empty={!selected}
      onClick={() => addFilter({ groupGiftView: !selected })}
      pill
      size="small"
      selected={!!selected}
    >
      Group Gift
    </InfoButton>
  </>
)

GroupGiftFilter.propTypes = {
  addFilter: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.bool,
}

GroupGiftFilter.defaultProps = {
  className: undefined,
  label: undefined,
  selected: false,
}

export default GroupGiftFilter
