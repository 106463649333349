import { connect } from 'react-redux'
import PurchaseInfoModal from 'shared/purchase-info-modal'
import { markAsPurchased } from 'cart/actions'
import { reservationPurchasedPath } from 'lib/urls'
import { markAsPurchasedByCurrentVisitor } from '../../actions/reg-items-actions'

const RegistryViewPurchaseInfoModal = ({
  markAsPurchased,
  markAsPurchasedByCurrentVisitor,
  ...props
}) => {
  const handleMarkAsPurchased = (reservation) => {
    return markAsPurchased(props.reservation, reservation, {
      showSuccessMessage: true,
    }).then(() => {
      markAsPurchasedByCurrentVisitor(props.reservation)
    })
  }

  return (
    <PurchaseInfoModal {...props} onMarkAsPurchased={handleMarkAsPurchased} />
  )
}

export default connect(undefined, {
  markAsPurchased,
  markAsPurchasedByCurrentVisitor,
})(RegistryViewPurchaseInfoModal)
