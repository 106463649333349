import { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { numberToCurrency } from 'lib/money'

class Offer extends Component {
  static propTypes = {
    discountedPrice: PropTypes.object,
    href: PropTypes.string,
    storeIconName: PropTypes.string,
    isLink: PropTypes.bool,
    linkClassName: PropTypes.string,
    onClick: PropTypes.func,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    storeName: PropTypes.string.isRequired,
    style: PropTypes.object,
    wrapperClassName: PropTypes.string,
  }

  static defaultProps = {
    isLink: false,
  }

  render() {
    let {
      discountedPrice,
      href,
      storeIconName,
      isLink,
      linkClassName,
      onClick,
      price,
      priceClassName,
      storeName,
      style,
      wrapperClassName,
    } = this.props

    if (isLink && !href) {
      throw new Error('Offer with isLink=true requires href prop')
    }

    let wrappedOnClick = (e) => {
      if (onClick) {
        e.preventDefault()
        onClick()
      }
    }

    let offer = (
      <div
        className={classNames('offer-layout-wrapper', wrapperClassName)}
        style={style}
      >
        <span
          className={classNames(
            'icon-offer',
            `icon-offer-${storeIconName}`,
            'mrs'
          )}
        />
        <span className="offer-store">{storeName}</span>
        {price ? (
          <span className="offer-price">
            <Price
              className={priceClassName}
              discountedPrice={discountedPrice}
              price={price}
            />
          </span>
        ) : null}
      </div>
    )

    if (!isLink) {
      return offer
    }

    return (
      <a
        className={classNames('link-offer', linkClassName)}
        href={href}
        target="_blank"
        onClick={wrappedOnClick}
      >
        {offer}
      </a>
    )
  }
}

const Price = ({ className, discountedPrice, discountedPriceFirst, price }) => {
  let priceComps = []
  if (discountedPrice) {
    priceComps.push(
      <span
        aria-label={`Regular Price: ${price}`}
        className="text-muted"
        key="base-price"
      >
        <s>{numberToCurrency(price)}</s>
      </span>,
      <span className="mrs" key="spacer" />
    )
  }
  priceComps.push(
    <span
      aria-label={discountedPrice ? `Sale Price: ${price}` : `Price: ${price}`}
      className={className}
      key="advertised-price"
    >
      {numberToCurrency(discountedPrice || price)}
    </span>
  )

  if (discountedPriceFirst) {
    priceComps = priceComps.reverse()
  }
  return <span>{priceComps}</span>
}

Price.propTypes = {
  discountedPrice: PropTypes.object,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export { Offer as default, Price }
