import PropTypes from 'prop-types'
import { BestOfferLink } from '../best-offer-link/best-offer-link'
import GiftCardOffer from '../gift-card-offer'
import EditRegItemLink from '../edit-reg-item-link'
import RegItemCardLayout from '../reg-item-card-layout'
import OwnerActions from '../owner-actions/owner-actions'

export const ContributorGiftCardRegItemCard = ({ regItem }, { isMobile }) => {
  const { id, offers, isEditable } = regItem

  const regItemLink = (child) => {
    if (isMobile && isEditable) {
      return <EditRegItemLink regItemId={id}>{child}</EditRegItemLink>
    }

    return (
      <BestOfferLink offers={offers} isContributorView>
        {child}
      </BestOfferLink>
    )
  }

  const actions = isEditable ? (
    <OwnerActions showEditAction regItemId={id} />
  ) : null

  return (
    <RegItemCardLayout
      regItem={regItem}
      regItemLinkDecorator={regItemLink}
      actions={actions}
      offerPanel={<GiftCardOffer />}
    />
  )
}

ContributorGiftCardRegItemCard.contextTypes = {
  isMobile: PropTypes.bool,
}
