import { USER_UPLOADED_CONTENT_CLOUDINARY_FOLDER_BASE } from 'src/constants'

const DEVELOPMENT_HOST_PATTERN =
  /(^localhost)|(^(www\.)?(my\.)?babylist\.test$)/i
const PRODUCTION_HOST_PATTERN = /^(www\.)?(my\.)?babylist\.com$/i

export const buildCloudinaryFolderName = (): string => {
  let folderName = USER_UPLOADED_CONTENT_CLOUDINARY_FOLDER_BASE
  if (!window?.location?.host) return folderName

  const {
    location: { host },
  } = window
  const domains = host.split('.')
  const stagingSubdomain = domains.find((domain) => domain.match(/staging/i))
  const canarySubdomain = domains.find((domain) => domain.match(/canary/i))
  const isProduction = window.location.host.match(PRODUCTION_HOST_PATTERN)
  const isDevelopment = window.location.host.match(DEVELOPMENT_HOST_PATTERN)

  if (stagingSubdomain) {
    folderName += `/${stagingSubdomain.replace('my-', '')}`
  } else if (canarySubdomain) {
    folderName += '/canary'
  } else if (isProduction) {
    folderName += '/production'
  } else if (isDevelopment) {
    folderName += '/development'
  }

  return folderName
}
