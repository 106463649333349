import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { filter, values, pickBy, sumBy } from 'lodash'
import { toDate, format } from 'date-fns'
import { numberToCurrency } from 'lib/money'
import { GroupGiftModal } from 'components/modal'
import { InfoButton } from 'components/buttons'
import * as api from 'registry/api'
import { addCartItem } from 'store/api'
import { Checkbox } from 'components/forms'
import { deleteRegItem, saveRegItem } from 'registry/actions/reg-items-actions'
import { regItemProps } from 'registry/components/reg-item-card/reg-item-props'
import { UPDATE_CART_SUMMARY, FLASH_MESSAGE } from 'shared/constants'
import { reservationsPath } from 'lib/urls'
import { parseErrorMessage } from 'lib/api-utils'
import { GROUP_GIFT_ACTIONS } from 'registry/constants'
import css from './group-gift-redemption-modal.scss'

const getContributions = async (regItem) => {
  return api.getRegItemGroupGiftContributions(regItem?.registryId, regItem?.id)
}

const CTAButton = ({
  action,
  disabled,
  isBabylistProductDiscontinued,
  hasUnredeemedFunds,
  onClick,
  submitting,
}) => {
  let ctaText = `${
    hasUnredeemedFunds ? 'Apply Funds and ' : ''
  }Continue to Cart`

  if (isBabylistProductDiscontinued || action === GROUP_GIFT_ACTIONS.DELETE) {
    ctaText = `${hasUnredeemedFunds ? 'Apply Funds and ' : ''}Remove Item`
  }

  if (action === GROUP_GIFT_ACTIONS.DISABLE) {
    ctaText = `${
      hasUnredeemedFunds ? 'Apply Funds and ' : ''
    }Disable Group Gifting`
  }

  if (action === GROUP_GIFT_ACTIONS.QUICKADD) {
    ctaText = `${hasUnredeemedFunds ? 'Apply Funds and ' : ''}Add to Cart`
  }

  return (
    <InfoButton
      onClick={onClick}
      pill
      submitting={submitting}
      disabled={disabled}
    >
      {ctaText}
    </InfoButton>
  )
}

CTAButton.propTypes = {
  action: PropTypes.oneOf(values(GROUP_GIFT_ACTIONS)).isRequired,
  isBabylistProductDiscontinued: PropTypes.bool.isRequired,
  hasUnredeemedFunds: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

const GroupGiftRedemptionModal = ({
  action,
  deleteRegItem,
  regItem,
  onHide,
  saveRegItem,
  show,
}) => {
  const [unredeemedFundsToDisplay, setUnredeemedFundsToDisplay] = useState([])
  const [fundedAmount, setFundedAmount] = useState(0)
  const [fundsRedeemedAmount, setFundsRedeemedAmount] = useState(0)
  const [isFetching, setIsFetching] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [creditBalance, setCreditBalance] = useState(0)
  const [totalFundsToRedeem, setTotalFundsToRedeem] = useState(0)
  const [allUnredeemedFundsChecked, setAllUnredeemedFundsChecked] =
    useState(true)
  const { register, getValues } = useForm()

  const { isBabylistProductDiscontinued } = regItem
  const hasUnredeemedFunds = unredeemedFundsToDisplay.length > 0
  const creditBalanceAfterRedemption = creditBalance + totalFundsToRedeem

  useEffect(() => {
    setIsFetching(true)
    async function getContributionsAsync() {
      const { groupGift } = await getContributions(regItem)
      const {
        fundedAmount,
        fundsRedeemedAmount,
        unredeemedFunds,
        userCreditBalance,
      } = groupGift
      setIsFetching(false)
      setCreditBalance(userCreditBalance)
      setFundsRedeemedAmount(fundsRedeemedAmount)
      setUnredeemedFundsToDisplay(unredeemedFunds)
      setTotalFundsToRedeem(sumBy(unredeemedFunds, (f) => f.amount))
      setFundedAmount(fundedAmount)
    }
    getContributionsAsync()
  }, [])

  const handleAddToCart = (options = {}) => {
    const { gotoCartAfterAdding } = options
    addCartItem({
      productId: regItem?.productId,
      quantity: 1,
    })
      .then(() => {
        PubSub.publish(FLASH_MESSAGE, {
          message: `${regItem?.title} added to cart!`,
          variant: 'success',
        })
        PubSub.publish(UPDATE_CART_SUMMARY, { showCartDropdown: true })
        if (gotoCartAfterAdding) {
          window.location.href = reservationsPath
        }
      })
      .catch((resp) => {
        PubSub.publish(FLASH_MESSAGE, {
          message: parseErrorMessage(resp),
          variant: 'danger',
        })
      })
  }

  const handleDisableGroupGift = () => {
    saveRegItem(regItem?.id, { isGroupGift: false })
  }

  const handleRemoveRegItem = () => {
    deleteRegItem(regItem?.id)
  }

  const getCheckedFundIds = () => {
    return Object.keys(
      pickBy(getValues(), (checked, fundId) => {
        if (checked) {
          return fundId
        }
        return false
      })
    )
  }

  const handleOnChange = () => {
    const checkedFundIds = getCheckedFundIds()
    const selectedFunds = filter(unredeemedFundsToDisplay, ({ id }) =>
      checkedFundIds.includes(id.toString())
    )
    const totalSelectedFundsAmount = sumBy(selectedFunds, (f) => f.amount)
    setTotalFundsToRedeem(totalSelectedFundsAmount)
    setAllUnredeemedFundsChecked(
      selectedFunds.length == unredeemedFundsToDisplay.length
    )
  }

  const handleSubmit = () => {
    const groupGiftIdsToRedeem = getCheckedFundIds()
    setIsSubmitting(true)

    if (groupGiftIdsToRedeem.length > 0) {
      api.redeemGroupGifts({ fundIds: groupGiftIdsToRedeem })
    }

    // CTA action to perform after redeeming available funds
    switch (action) {
      case GROUP_GIFT_ACTIONS.CART:
        handleAddToCart({ gotoCartAfterAdding: true })
        break
      case GROUP_GIFT_ACTIONS.DELETE:
        handleRemoveRegItem()
        break
      case GROUP_GIFT_ACTIONS.DISABLE:
        handleDisableGroupGift()
        break
      case GROUP_GIFT_ACTIONS.QUICKADD:
        handleAddToCart()
        break
      default:
        break
    }
    setIsSubmitting(false)
    onHide()
  }

  return (
    <GroupGiftModal
      show={show}
      onHide={onHide}
      title="Gift Funds For This Item Will Be Applied at Checkout"
    >
      {fundsRedeemedAmount > 0 && (
        <p className="h6">
          <em>
            {numberToCurrency(fundsRedeemedAmount)} of the{' '}
            {numberToCurrency(fundedAmount)} funded for this item has already
            been applied.
          </em>
        </p>
      )}

      {hasUnredeemedFunds && (
        <>
          <p className="h6">
            <strong>
              Gift funds will be converted to gift card credit
              {creditBalance > 0 && (
                <>
                  {' '}
                  and combined with your existing{' '}
                  {numberToCurrency(creditBalance)} gift card credit
                </>
              )}
              .
            </strong>{' '}
            Any unused funds after checkout will automatically be applied to
            your next purchase.
          </p>

          <div className={classNames(css.fundDisplay, 'mvl')}>
            {unredeemedFundsToDisplay.map(
              ({ amount, fundedAt, funderName, id }) => {
                const date = toDate(new Date(fundedAt))
                return (
                  <Checkbox
                    groupClassName={css.fund}
                    key={id}
                    name={id.toString()}
                    inputRef={register}
                    label={
                      <div className="h6 man text-bold">{`$${amount} from ${funderName}`}</div>
                    }
                    onChange={handleOnChange}
                    help={
                      <div className="xsmall man">{`Funded on ${format(
                        date,
                        'M/dd/yyyy'
                      )}`}</div>
                    }
                    defaultChecked
                  />
                )
              }
            )}
          </div>
        </>
      )}

      <div className="text-center">
        <CTAButton
          action={action}
          disabled={isFetching || !allUnredeemedFundsChecked}
          isBabylistProductDiscontinued={isBabylistProductDiscontinued}
          hasUnredeemedFunds={hasUnredeemedFunds}
          onClick={handleSubmit}
          submitting={isSubmitting}
        />

        {creditBalanceAfterRedemption > 0 && allUnredeemedFundsChecked && (
          <div className="h6 mbn pbn text-bold">
            You'll have {numberToCurrency(creditBalanceAfterRedemption)} in
            total gift card credit at checkout.
          </div>
        )}
        {!allUnredeemedFundsChecked && action == GROUP_GIFT_ACTIONS.DELETE && (
          <div className="h6 mbn pbn text-bold">
            You must apply all gift funds to remove this item.
          </div>
        )}
      </div>
    </GroupGiftModal>
  )
}

GroupGiftRedemptionModal.propTypes = {
  deleteRegItem: PropTypes.func.isRequired,
  regItem: PropTypes.shape(regItemProps).isRequired,
  onHide: PropTypes.func.isRequired,
  saveRegItem: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

export default connect(undefined, { saveRegItem, deleteRegItem })(
  GroupGiftRedemptionModal
)
