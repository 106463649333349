import { CURRENT_USER_LOCAL_STORAGE_KEY } from 'shared/hooks/useCurrentUser/useCurrentUser'
import { CurrentUser } from '../../../global'

/**
 * Dispatch a storage event to all tabs (including current tab) when a value is set in localStorage.
 * This is used to trigger a re-render of the app when the current user is updated.
 */
const dispatchSetStorageEvent = (value: string) => {
  if (typeof window !== 'undefined') {
    const oldValue = window.localStorage.getItem(CURRENT_USER_LOCAL_STORAGE_KEY)

    const event = new StorageEvent('storage', {
      key: CURRENT_USER_LOCAL_STORAGE_KEY,
      oldValue,
      newValue: value,
      storageArea:
        window.localStorage instanceof Storage
          ? window.localStorage
          : undefined,
      url: window.location.href,
    })

    window.dispatchEvent(event)
  }
}

/**
 * Set the current user in localStorage. For use outside of react hook flow.
 */
export const setCurrentUser = (currentUser?: CurrentUser) => {
  if (typeof window !== 'undefined') {
    const stringifiedCurrentUser = JSON.stringify(currentUser)

    window.localStorage.setItem(
      CURRENT_USER_LOCAL_STORAGE_KEY,
      stringifiedCurrentUser
    )

    // Update the currentUser value on the window object as well.
    // In the future we should move to using localStorage exclusively for current user.
    // Once all code directly calling window.currentUser is updated to use localStorage, we can remove this.
    if (typeof window !== 'undefined') {
      window.currentUser = currentUser as CurrentUser
    }

    dispatchSetStorageEvent(stringifiedCurrentUser)
  }
}
