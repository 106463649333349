import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Select } from 'components/forms'
import { numberToCurrency } from 'lib/money'

const GiftAmountSelect = ({
  className,
  defaultGiftAmount,
  inputRef,
  name,
  onChange,
  options,
  wrapperClassName,
  size,
}) => {
  return (
    <Select
      label="Gift Amount"
      className={classNames(className)}
      defaultValue={defaultGiftAmount}
      inputRef={inputRef}
      name={name}
      onChange={onChange ? (e) => onChange(e) : null}
      wrapperClassName={classNames(wrapperClassName)}
      size={size}
    >
      {Array.isArray(options) &&
        options.map((option) => (
          <option
            key={option}
            value={option}
            selected={option === defaultGiftAmount}
          >
            {option
              ? numberToCurrency(option, { truncateZeroCents: true })
              : null}
          </option>
        ))}
    </Select>
  )
}

GiftAmountSelect.defaultProps = {
  className: undefined,
  name: undefined,
  inputRef: undefined,
  wrapperClassName: undefined,
  onChange: undefined,
  size: undefined,
  defaultGiftAmount: undefined,
}

GiftAmountSelect.propTypes = {
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  inputRef: PropTypes.func,
  wrapperClassName: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  defaultGiftAmount: PropTypes.number,
}

export default GiftAmountSelect
