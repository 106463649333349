import React from 'react'

interface UserOutlineProps {
  className?: string
}

const UserOutline: React.FC<UserOutlineProps> = ({
  className,
}: UserOutlineProps) => (
  <svg
    className={className}
    fill="none"
    height="11"
    viewBox="0 0 10 11"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M5 1.375C4.10254 1.375 3.375 2.10254 3.375 3C3.375 3.89746 4.10254 4.625 5 4.625C5.89746 4.625 6.625 3.89746 6.625 3C6.625 2.10254 5.89746 1.375 5 1.375ZM2.625 3C2.625 1.68832 3.68832 0.625 5 0.625C6.31168 0.625 7.375 1.68832 7.375 3C7.375 4.31168 6.31168 5.375 5 5.375C3.68832 5.375 2.625 4.31168 2.625 3ZM0.625 8.83333C0.625 7.33756 1.83756 6.125 3.33333 6.125H6.66667C8.16244 6.125 9.375 7.33756 9.375 8.83333C9.375 9.68477 8.68477 10.375 7.83333 10.375H2.16667C1.31523 10.375 0.625 9.68477 0.625 8.83333ZM3.33333 6.875C2.25178 6.875 1.375 7.75178 1.375 8.83333C1.375 9.27056 1.72944 9.625 2.16667 9.625H7.83333C8.27056 9.625 8.625 9.27056 8.625 8.83333C8.625 7.75178 7.74822 6.875 6.66667 6.875H3.33333Z"
      fill="#6B6669"
      fillRule="evenodd"
    />
  </svg>
)

export default UserOutline
