import { Component } from 'react'
import PropTypes from 'prop-types'
import { CurrencyInput, Input } from 'components/forms'
import FavoriteToggle from 'shared/reg-item-form/components/favorite-toggle'
import GroupGiftToggle from 'shared/reg-item-form/components/group-gift-toggle'
import RegItemImage from 'registry/components/reg-item-image'
import { REG_ITEM_PLACEHOLDER_TEXT } from 'registry/constants'
import CategorySelector from './CategorySelector'
import css from './reg-item-form.scss'

export default class GroupGiftFields extends Component {
  handleFavoriteChange = (isOn) => {
    const { onRegItemChange } = this.props
    onRegItemChange({ isFavorite: isOn })
  }

  handleGroupGiftChange = (isOn) => {
    const { onRegItemChange, regItem, openDisableGroupGiftModal } = this.props
    const { fundedAmount } = regItem

    if (fundedAmount > 0 && regItem.isGroupGift) {
      openDisableGroupGiftModal()
    } else {
      onRegItemChange({ isGroupGift: isOn })
    }
  }

  handleChange = (e) => {
    const { onRegItemChange } = this.props
    onRegItemChange({ [e.target.name]: e.target.value })
  }

  render() {
    const { categories, regItem, onRegItemChange, onNewCategoryChange } =
      this.props
    const regItemFields = regItem
    const {
      description,
      imgUrl,
      isFavorite,
      isGroupGift,
      goalAmount,
      showGroupGiftToggle,
      quantity,
      title,
    } = regItemFields

    return (
      <>
        <div className={css.item}>
          <div className={css.imageContainer}>
            <RegItemImage src={imgUrl} width={250} />
          </div>

          <div className={css.itemFields}>
            <Input
              disabled
              error={title ? null : 'Title cannot be blank'}
              label="Title"
              name="title"
              value={title}
              onChange={this.handleChange}
            />

            <div className="inline-form mbl">
              <CurrencyInput
                groupClassName="flex-grow"
                label="Group Gift Goal"
                name="goalAmount"
                value={goalAmount || ''}
                onChange={this.handleChange}
              />
              <Input
                disabled
                className="flex-grow"
                label="Quantity"
                value={`${quantity} Per Group Gift`}
              />
            </div>

            <div className="flex inline-form">
              <CategorySelector
                categories={categories}
                groupClassName="flex-grow"
                name="categoryId"
                value={regItemFields.categoryId}
                onCategoryAdd={onNewCategoryChange}
                onCategoryChange={onRegItemChange}
              />
              <div className="flex flex-grow form-group">
                <FavoriteToggle
                  defaultOn={isFavorite}
                  onClick={this.handleFavoriteChange}
                />
                {showGroupGiftToggle && (
                  <div className="mll">
                    <GroupGiftToggle
                      defaultOn={isGroupGift}
                      onClick={this.handleGroupGiftChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Input
          className={css.notes}
          label="Notes for friends and family"
          name="description"
          placeholder={REG_ITEM_PLACEHOLDER_TEXT}
          type="textarea"
          value={description || ''}
          onChange={this.handleChange}
        />
      </>
    )
  }
}

GroupGiftFields.propTypes = {
  categories: PropTypes.array,
  regItem: PropTypes.object,
  onRegItemChange: PropTypes.func.isRequired,
  onNewCategoryChange: PropTypes.func.isRequired,
  openDisableGroupGiftModal: PropTypes.func.isRequired,
}
