import { FC } from 'react'
import { TextLink } from 'baby-design'
import { LinkOut } from 'baby-design/icons'
import { healthPath, helloBabyPath, indexPath, storePath } from 'lib/urls'
import ToggleNav from '../ToggleNav'
import css from '../../global-nav.scss'
import { isBot } from 'lib/bowser'
import { isPrerenderUserAgent } from 'lib/isPrerenderUserAgent'
import {
  registryMenu,
  productsMenu,
  guidesMenu,
} from 'shared/mobile-nav-modal/mobile-nav-menus'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'

interface HomePageSiteNavSectionProps {
  activeNavItem?: 'Home' | 'Registry' | 'Store' | 'Guides'
  className?: string
  handleToggleNav: () => void
  trackClick: (eventCta: string) => void
  productCategories?: any[]
  guideTopics?: any[]
}

const HomePageSiteNavSection: FC<HomePageSiteNavSectionProps> = ({
  activeNavItem,
  className,
  handleToggleNav,
  trackClick,
  productCategories,
  guideTopics,
}) => {
  const [currentUser] = useCurrentUser()
  const isBotOrPrerender = isBot || isPrerenderUserAgent()
  const registryMenuItems = registryMenu(currentUser)
  const productMenuItems = productsMenu(productCategories)
  const guideMenuItems = guidesMenu(guideTopics)

  return (
    <ul className={className}>
      <ToggleNav onClick={handleToggleNav} />
      <li className={css.fullNavOnly}>
        <TextLink
          href={indexPath}
          isSelected={activeNavItem === 'Registry'}
          size="md"
          weight={activeNavItem === 'Registry' ? 'medium' : 'regular'}
          onClick={() => trackClick('Registry')}
        >
          Registry
        </TextLink>
        {isBotOrPrerender && (
          <ul className={css.fullNavOnly}>
            {registryMenuItems.subMenuItems.map((item: any) => (
              <li key={item.title} className={css.fullNavOnly}>
                <TextLink
                  href={item.href}
                  isSelected={activeNavItem === item.title}
                  size="md"
                  weight={activeNavItem === item.title ? 'medium' : 'regular'}
                  onClick={() => trackClick(item.title)}
                >
                  {item.title}
                </TextLink>
              </li>
            ))}
          </ul>
        )}
      </li>
      <li className={css.fullNavOnly}>
        <TextLink
          href={storePath}
          isSelected={activeNavItem === 'Store'}
          size="md"
          weight={activeNavItem === 'Store' ? 'medium' : 'regular'}
          onClick={() => trackClick('Shop')}
        >
          Shop
        </TextLink>
        {isBotOrPrerender && (
          <ul className={css.fullNavOnly}>
            {productMenuItems.subMenuItems.map((item: any) => (
              <li key={item.title} className={css.fullNavOnly}>
                <TextLink
                  href={item.href}
                  isSelected={activeNavItem === item.title}
                  size="md"
                  weight={activeNavItem === item.title ? 'medium' : 'regular'}
                  onClick={() => trackClick(item.title)}
                >
                  {item.title}
                </TextLink>
              </li>
            ))}
          </ul>
        )}
      </li>
      <li className={css.fullNavOnly}>
        <TextLink
          href={helloBabyPath}
          isSelected={activeNavItem === 'Guides'}
          size="md"
          weight={activeNavItem === 'Guides' ? 'medium' : 'regular'}
          onClick={() => trackClick('Guides')}
        >
          Guides
        </TextLink>
        {isBotOrPrerender && (
          <ul className={css.fullNavOnly}>
            {guideMenuItems.subMenuItems.map((item: any) => (
              <li key={item.title} className={css.fullNavOnly}>
                <TextLink
                  href={item.href}
                  isSelected={activeNavItem === item.title}
                  size="md"
                  weight={activeNavItem === item.title ? 'medium' : 'regular'}
                  onClick={() => trackClick(item.title)}
                >
                  {item.title}
                </TextLink>
              </li>
            ))}
          </ul>
        )}
      </li>
      <li className={css.fullNavOnly}>
        <TextLink
          href={healthPath}
          icon={{
            side: 'right',
            icon: <LinkOut />,
          }}
          rel="noreferrer"
          size="md"
          target="_blank"
          weight="regular"
          onClick={() => trackClick('Health')}
        >
          Health
        </TextLink>
      </li>
    </ul>
  )
}

export default HomePageSiteNavSection
