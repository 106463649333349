import { PureComponent } from 'react'
import PropTypes from 'prop-types'

const withBlinkyTitleBar = (WrappedComponent) => {
  return class HOC extends PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        intervalId: null,
        isChangingTitle: false,
      }
      this.originalTitle = document.title
    }

    static propTypes = {
      altTitle: PropTypes.string,
      interval: PropTypes.number,
      show: PropTypes.bool,
    }

    static defaultProps = {
      altTitle: 'Did you purchase it?',
      interval: 1200,
      show: true,
    }

    componentDidMount() {
      window.onblur = () => {
        if (!this.state.isChangingTitle && this.props.show) {
          let intervalId = setInterval(this.changeTitle, this.props.interval)
          this.setState({
            intervalId: intervalId,
            isChangingTitle: true,
          })
        }
      }
      window.onfocus = () => {
        this.resetTitle()
      }
    }

    componentWillUnmount() {
      this.resetTitle
      // reset event listeners
      window.onblur = () => {}
      window.onfocus = () => {}
    }

    changeTitle = () => {
      const { altTitle } = this.props
      document.title =
        document.title == altTitle ? this.originalTitle : altTitle
    }

    resetTitle = () => {
      if (typeof this.state.intervalId == 'number') {
        clearInterval(this.state.intervalId)
        this.setState({ isChangingTitle: false })
      }
      document.title = this.originalTitle
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

export default withBlinkyTitleBar
