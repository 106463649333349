import { MouseEvent, MouseEventHandler } from 'react'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import { ChipVariants } from 'components/Chip'
import { track, useTracking } from 'lib/analytics'

import * as urls from 'lib/urls'

import { withEntryPointAccountMenu } from 'src/routes/(registry)/shipments/util/shipmentsEntryPoint'

interface UserProps {
  addedBabylistButton: boolean
  adminId?: number
  currentRegistry: {
    id: number
    ownerId: number
    photoUrl?: string
    urlSlug: string
  }
  firstName: string
  hasMultipleRegistries: boolean
  id: number
}

export const useAccountMenuItems = ({
  currentUser,
  trackNavbarClicked,
}: {
  currentUser?: UserProps
  trackNavbarClicked: (eventCta: string) => void
}) => {
  const { trackEvent } = useTracking()
  const { currentRegistry, addedBabylistButton, hasMultipleRegistries } =
    currentUser || {}

  const handleCreateNewRegistryClicked = () => {
    trackEvent({
      event: track.createNewRegistryClicked,
      eventLocation: track.EventLocation.SETTINGS,
    })
    window.location.href = urls.createAnotherRegistryPath
  }

  const handleListTypeClicked = (listType: string, redirectPath: string) => {
    trackEvent({
      event: track.listTypeClicked,
      eventLocation: track.EventLocation.SETTINGS,
      listType,
      registryId: currentRegistry?.id,
      registryOwnerId: currentRegistry?.ownerId,
    })
    window.location.href = redirectPath
  }

  const trackLogOut = (e: any) => {
    e.preventDefault()
    trackEvent({ event: track.logOutCompleted })
    window.location.href = urls.logoutPath
  }

  const { flagValue: shipmentViewFeatureFlag } = useFeatureFlag(
    '24c_ff_giftmanagement_shipment_view_web',
    false
  )
  const isShipmentViewEnabled =
    !!shipmentViewFeatureFlag || !!currentUser?.adminId

  const links = [
    {
      label: 'Your Registry',
      href: urls.registryPath(currentRegistry?.urlSlug),
    },
    !addedBabylistButton && {
      label: 'Install Babylist Button',
      href: urls.addItemsPath,
    },
    {
      label: 'Settings',
      href: urls.settingsPath,
    },
    isShipmentViewEnabled && {
      label: 'Shipments',
      href: withEntryPointAccountMenu(urls.shipmentViewPath),
    },
    {
      label: 'Your Orders',
      href: urls.settingsYourOrdersPath,
    },
    {
      label: 'Help',
      href: urls.faqPath,
      onClick: () => trackNavbarClicked('Help'),
    },
    {
      label: 'divider',
      type: 'divider',
    },
    {
      label: 'New Registry',
      href: urls.createAnotherRegistryPath,
      onClick: (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        handleCreateNewRegistryClicked()
      },
    },
    {
      label: 'New Birthday Wishlist',
      href: urls.createBirthdayWishlistPath,
      onClick: (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        handleListTypeClicked(
          track.ListType.BIRTHDAY,
          urls.createBirthdayWishlistPath
        )
      },
    },
    {
      label: 'New Holiday Wishlist',
      href: urls.createHolidayWishlistPath,
      onClick: (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        handleListTypeClicked(
          track.ListType.HOLIDAY,
          urls.createHolidayWishlistPath
        )
      },
    },
    hasMultipleRegistries && {
      label: 'Switch Registry',
      href: urls.listsPath,
    },
    {
      label: 'Log Out',
      href: urls.logoutPath,
      onClick: trackLogOut,
    },
  ].filter(Boolean) as {
    label: string
    href?: string
    onClick?: MouseEventHandler<HTMLAnchorElement>
    chipLabel?: string
    // eslint-disable-next-line prettier/prettier
    chipVariant?: (typeof ChipVariants)[keyof typeof ChipVariants]
  }[]

  return links
}
