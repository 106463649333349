import React from 'react'

interface CheckCircleProps {
  className?: string
  filled?: boolean
}

const CheckCircle: React.FC<CheckCircleProps> = ({ className, filled }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {filled ? (
      <path
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM16.9181 9.89224C17.2038 9.59229 17.1922 9.11756 16.8922 8.8319C16.5923 8.54623 16.1176 8.55781 15.8319 8.85776L10.6322 14.3175C10.5829 14.3692 10.5004 14.3692 10.4511 14.3175L8.1681 11.9203C7.88244 11.6203 7.40771 11.6087 7.10776 11.8944C6.80781 12.1801 6.79623 12.6548 7.0819 12.9547L9.36494 15.3519C10.0053 16.0244 11.078 16.0244 11.7184 15.3519L16.9181 9.89224Z"
        fill="#252324"
        fillRule="evenodd"
      />
    ) : (
      <path
        clipRule="evenodd"
        d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM17.0303 8.46967C17.3232 8.76256 17.3232 9.23744 17.0303 9.53033L11.0303 15.5303C10.7374 15.8232 10.2626 15.8232 9.96967 15.5303L7.46967 13.0303C7.17678 12.7374 7.17678 12.2626 7.46967 11.9697C7.76256 11.6768 8.23744 11.6768 8.53033 11.9697L10.5 13.9393L15.9697 8.46967C16.2626 8.17678 16.7374 8.17678 17.0303 8.46967Z"
        fill="#252324"
        fillRule="evenodd"
      />
    )}
  </svg>
)

export default CheckCircle
