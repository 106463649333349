import React from 'react'

interface DividerProps {
  className?: string
}

const Divider: React.FC<DividerProps> = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 1 20"
    width="1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#B2AEB0" height="20" width="1" />
  </svg>
)

export default Divider
