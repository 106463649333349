import React, { useMemo, useState } from 'react'
import { InfoIconOutline } from 'components/icons'
import { Modal, ModalBody } from 'components/modal'
import { track, useTracking } from 'lib/analytics'
import styles from './ProductCallouts.module.scss'
import { ProductCallout, ProductCalloutTooltip } from 'src/types/productCallout'

interface ProductCalloutsProps {
  callouts: ProductCallout[]
  eventLocation?: string
  textSize?: 13 | 15
}

export const ProductCallouts: React.FC<ProductCalloutsProps> = ({
  callouts = [],
  eventLocation,
  textSize = 15,
}) => {
  const tracker = useTracking()
  const [modalState, setModalState] = useState<{
    content: ProductCalloutTooltip | null
    isOpen: boolean
  }>({ content: null, isOpen: false })

  const openModal = (content: ProductCalloutTooltip | undefined) => {
    if (!content) return
    tracker.trackEvent({
      event: track.tooltipClicked,
      eventLocation,
      eventType: content.eventType,
    })
    setModalState({ content, isOpen: true })
  }

  const closeModal = () => {
    setModalState({ content: null, isOpen: false })
  }

  const textSizeClass = useMemo(() => {
    return textSize === 13
      ? styles['ProductCallouts__text--13']
      : styles['ProductCallouts__text--15']
  }, [textSize])

  return (
    <div>
      {callouts.map((callout, index) => (
        <div key={index} className={textSizeClass}>
          {callout.text}
          {callout.tooltip && (
            <button
              onClick={() => openModal(callout.tooltip)}
              className={styles['ProductCallouts__tooltip-button']}
              aria-label="More information"
            >
              <InfoIconOutline />
            </button>
          )}
        </div>
      ))}

      {/* Tooltip Modal */}
      {modalState.isOpen && modalState.content && (
        <Modal
          onHide={closeModal}
          show={modalState.isOpen}
          title={modalState.content?.title}
        >
          <ModalBody>
            <p>{modalState.content?.body}</p>
          </ModalBody>
        </Modal>
      )}

      {/* Hidden modal content for bots */}
      <div data-testid="seo-callouts" style={{ display: 'none' }}>
        {callouts.map((callout, index) =>
          callout.tooltip ? (
            <div key={index}>
              <h2>{callout.tooltip.title}</h2>
              <p>{callout.tooltip.body}</p>
            </div>
          ) : null
        )}
      </div>
    </div>
  )
}
