export const SEARCH_HISTORY_STORAGE_KEY = 'unifiedSearchHistory'
export const SEARCH_HISTORY_TYPE_PRODUCTS = 'products'
export const SEARCH_HISTORY_TYPE_GUIDES = 'guides'
export const SEARCH_HISTORY_TYPE_REGISTRIES = 'registries'
export const SEARCH_HISTORY_LIMIT = 5

export type SearchHistoryType =
  | typeof SEARCH_HISTORY_TYPE_PRODUCTS
  | typeof SEARCH_HISTORY_TYPE_GUIDES
  | typeof SEARCH_HISTORY_TYPE_REGISTRIES
export interface SearchHistoryResult {
  val: string
  type: SearchHistoryType
}

export type SearchHistory = SearchHistoryResult[]

export const getSearchHistory = (): SearchHistory => {
  if (typeof localStorage === 'undefined') return []

  let serializedHistory: string | null = localStorage.getItem(
    SEARCH_HISTORY_STORAGE_KEY
  )
  if (!serializedHistory) return []

  try {
    return JSON.parse(serializedHistory)
  } catch {
    return []
  }
}

export const getSearchHistoryOfType = (
  searchHistoryType: SearchHistoryType
): string[] => {
  let searchHistory: SearchHistory = getSearchHistory()

  searchHistory = searchHistory.filter(
    (entry: SearchHistoryResult) => entry.type === searchHistoryType
  )

  return searchHistory.map((entry: SearchHistoryResult) => entry.val)
}

export const addToSearchHistory = (
  searchString: string,
  searchHistoryType: SearchHistoryType
) => {
  if (typeof localStorage === 'undefined') return

  let serializedHistory: string | null =
    localStorage.getItem(SEARCH_HISTORY_STORAGE_KEY) || ''

  let searchHistory: SearchHistory = []
  try {
    searchHistory = JSON.parse(serializedHistory)
  } catch {
    searchHistory = []
  }

  const duplicateIndex = searchHistory.findIndex(
    (entry: SearchHistoryResult) =>
      entry.val === searchString && entry.type === searchHistoryType
  )
  if (duplicateIndex !== -1) {
    searchHistory.splice(duplicateIndex, 1)
  }

  searchHistory.unshift({
    val: searchString,
    type: searchHistoryType,
  } as SearchHistoryResult)

  const searchHistoryOfType = searchHistory.filter(
    (entry: SearchHistoryResult) => entry.type === searchHistoryType
  )
  if (searchHistoryOfType.length > SEARCH_HISTORY_LIMIT) {
    const lastEntryOfTypeIndex = searchHistory.findIndex(
      (entry: SearchHistoryResult) =>
        entry.val === searchHistoryOfType[searchHistoryOfType.length - 1].val &&
        entry.type === searchHistoryType
    )

    searchHistory.splice(lastEntryOfTypeIndex, 1)
  }

  localStorage.setItem(
    SEARCH_HISTORY_STORAGE_KEY,
    JSON.stringify(searchHistory)
  )
}
