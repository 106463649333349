import React from 'react'

interface GarbageBinInfoProps {
  className?: string
  filled?: boolean
}

const GarbageBinInfo: React.FC<GarbageBinInfoProps> = ({
  className,
  filled,
}: GarbageBinInfoProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 27 24"
    width="27"
    xmlns="http://www.w3.org/2000/svg"
  >
    {filled ? (
      <>
        <path
          clipRule="evenodd"
          d="M13.986 3.09855C13.7619 3.03415 13.5264 3 13.285 3H10.4078C9.31513 3 8.34509 3.69917 7.99957 4.73573L7.65507 5.76923L2.6154 5.76923C2.23305 5.76923 1.9231 6.07919 1.9231 6.46154C1.9231 6.84389 2.23305 7.15385 2.6154 7.15385H3.84599L5.23658 18.9739C5.49636 21.182 7.36778 22.8462 9.59116 22.8462H14.1013C16.3247 22.8462 18.1961 21.182 18.4559 18.9739L19.0364 14.0397C15.6925 13.1415 13.2308 10.089 13.2308 6.46154C13.2308 6.22825 13.241 5.99734 13.2609 5.76923H9.11458L9.31313 5.17358C9.47018 4.70242 9.91111 4.38462 10.4078 4.38462H13.285C13.3591 4.38462 13.4321 4.39171 13.503 4.40533C13.6257 3.95215 13.788 3.51524 13.986 3.09855ZM10.6923 11.0769C10.6923 10.6946 10.3824 10.3846 10 10.3846C9.61767 10.3846 9.30771 10.6946 9.30771 11.0769V16.6154C9.30771 16.9977 9.61767 17.3077 10 17.3077C10.3824 17.3077 10.6923 16.9977 10.6923 16.6154V11.0769ZM14.3846 11.0769C14.3846 10.6946 14.0747 10.3846 13.6923 10.3846C13.31 10.3846 13 10.6946 13 11.0769V16.6154C13 16.9977 13.31 17.3077 13.6923 17.3077C14.0747 17.3077 14.3846 16.9977 14.3846 16.6154V11.0769Z"
          fill="black"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M21.0769 12.9231C24.6455 12.9231 27.5385 10.0301 27.5385 6.46154C27.5385 2.89293 24.6455 0 21.0769 0C17.5083 0 14.6154 2.89293 14.6154 6.46154C14.6154 10.0301 17.5083 12.9231 21.0769 12.9231ZM21.7692 2.76923C21.7692 2.38688 21.4593 2.07692 21.0769 2.07692C20.6946 2.07692 20.3846 2.38688 20.3846 2.76923V6.46154C20.3846 6.84389 20.6946 7.15385 21.0769 7.15385C21.4593 7.15385 21.7692 6.84389 21.7692 6.46154V2.76923ZM21.0769 8.07692C20.4397 8.07692 19.9231 8.59352 19.9231 9.23077C19.9231 9.86802 20.4397 10.3846 21.0769 10.3846C21.7142 10.3846 22.2308 9.86802 22.2308 9.23077C22.2308 8.59352 21.7142 8.07692 21.0769 8.07692Z"
          fill="black"
          fillRule="evenodd"
        />
      </>
    ) : (
      <>
        <path
          d="M13.2167 3.09859C12.9926 3.03417 12.757 3 12.5155 3H9.63829C8.54566 3 7.57561 3.69917 7.23009 4.73573L6.88559 5.76923L1.84612 5.76923C1.46377 5.76923 1.15381 6.07919 1.15381 6.46154C1.15381 6.84389 1.46377 7.15385 1.84612 7.15385H3.07664L4.46722 18.9738C4.72701 21.182 6.59843 22.8462 8.82181 22.8462H13.332C15.5553 22.8462 17.4268 21.182 17.6865 18.9738L18.267 14.0397C17.8033 13.9151 17.3565 13.7492 16.931 13.5459L16.3114 18.8121C16.1337 20.3229 14.8532 21.4615 13.332 21.4615H8.82181C7.30055 21.4615 6.0201 20.3229 5.84235 18.8121L4.4708 7.15385L7.36867 7.15385C7.37936 7.1541 7.39004 7.1541 7.40068 7.15385L12.4916 7.15385C12.4717 6.92574 12.4615 6.69483 12.4615 6.46154C12.4615 6.22825 12.4717 5.99734 12.4916 5.76923L8.3451 5.76923L8.54365 5.17358C8.70071 4.70242 9.14164 4.38462 9.63829 4.38462H12.5155C12.5897 4.38462 12.6627 4.39172 12.7337 4.40537C12.8564 3.95219 13.0187 3.51528 13.2167 3.09859Z"
          fill="black"
        />
        <path
          d="M9.92304 11.0769C9.92304 10.6946 9.61308 10.3846 9.23073 10.3846C8.84838 10.3846 8.53842 10.6946 8.53842 11.0769V16.6154C8.53842 16.9977 8.84838 17.3077 9.23073 17.3077C9.61308 17.3077 9.92304 16.9977 9.92304 16.6154V11.0769Z"
          fill="black"
        />
        <path
          d="M13.6153 11.0769C13.6153 10.6946 13.3054 10.3846 12.923 10.3846C12.5407 10.3846 12.2307 10.6946 12.2307 11.0769V16.6154C12.2307 16.9977 12.5407 17.3077 12.923 17.3077C13.3054 17.3077 13.6153 16.9977 13.6153 16.6154V11.0769Z"
          fill="black"
        />
        <path
          clipRule="evenodd"
          d="M20.3077 12.9231C23.8763 12.9231 26.7692 10.0301 26.7692 6.46154C26.7692 2.89293 23.8763 0 20.3077 0C16.739 0 13.8461 2.89293 13.8461 6.46154C13.8461 10.0301 16.739 12.9231 20.3077 12.9231ZM21 2.76923C21 2.38688 20.69 2.07692 20.3077 2.07692C19.9253 2.07692 19.6153 2.38688 19.6153 2.76923V6.46154C19.6153 6.84389 19.9253 7.15385 20.3077 7.15385C20.69 7.15385 21 6.84389 21 6.46154V2.76923ZM20.3077 8.07692C19.6704 8.07692 19.1538 8.59352 19.1538 9.23077C19.1538 9.86802 19.6704 10.3846 20.3077 10.3846C20.9449 10.3846 21.4615 9.86802 21.4615 9.23077C21.4615 8.59352 20.9449 8.07692 20.3077 8.07692Z"
          fill="black"
          fillRule="evenodd"
        />
      </>
    )}
  </svg>
)

export default GarbageBinInfo
