export const ALERT_FADE_ANIMATION_DURATION = 500
export const DID_NOT_BUY_LATER = 'later'
export const DID_NOT_BUY_LOOKING = 'looking'
export const DID_NOT_BUY_NA = 'not_available'
export const DID_NOT_BUY_CHANGED_MIND = 'changed_mind'
export const DEFAULT_GIFT_CARD_AMOUNT = 100
export const NEW_RESERVATION_ID = '_new'
export const PURCHASED_ALERT = 'PURCHASED_ALERT'
export const RESERVE_ACTION_CHECKOUT = 'RESERVE_AND_CHECKOUT'
export const RESERVE_ACTION_SHOP = 'RESERVE_AND_SHOP'
export const RESERVE_ACTION_PURCHASED = 'RESERVE_PURCHASED'
export const UNPURCHASABLE_ALERT = 'UNPURCHASABLE_ALERT'
export const GIFT_MESSAGE_SEPARATOR = '\n\n— '
export const CANCEL_RESERVATION = 'CANCEL_RESERVATION'
export const MARK_AS_PURCHASED = 'MARK_AS_PURCHASED'
export const PRODUCT_TYPE_DIGITAL = 'digital'
export const PRODUCT_TYPE_PHYSICAL_GIFT_CARD = 'physical_gift_card'
export const GIFT_CARD_LABEL_DIGITAL = 'Digital: Print or email a gift card'
export const GIFT_CARD_LABEL_PHYSICAL = 'Mailed: Ship a gift card in the mail'
export const SHOULD_SAVE_REGISTRY_DISCOUNT_KEY =
  'save-registry-discount-for-later'
export const MARKETING_EMAIL_CHECKBOX_LABEL =
  'Send me gift ideas and deals from Babylist.'
