import PropTypes from 'prop-types'
import { regItemProps } from '../reg-item-props'
import ContributorGroupGiftRegItemCard from './contributor-group-gift-reg-item-card'
import ReservableGroupGiftRegItemCard from './reservable-group-gift-reg-item-card'

const GroupGiftRegItemCard = (props, { isMobile }) => {
  return (
    <>
      {props.isContributorView ? (
        <ContributorGroupGiftRegItemCard {...props} isMobile={isMobile} />
      ) : (
        <ReservableGroupGiftRegItemCard {...props} isMobile={isMobile} />
      )}
    </>
  )
}

GroupGiftRegItemCard.propTypes = {
  regItem: PropTypes.shape(regItemProps),
  isContributorView: PropTypes.bool,
}

GroupGiftRegItemCard.contextTypes = {
  isMobile: PropTypes.bool,
}

export default GroupGiftRegItemCard
