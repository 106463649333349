import css from './CriteriaSection.styles.scss'
import CriteriaCard, {
  PostEmbedCriteriaCard,
} from '../CriteriaCard/CriteriaCard'

interface PostEmbedCriteriaSectionFields {
  criteriaCards?: PostEmbedCriteriaCard[]
}

export interface CriteriaSectionProps {
  fields: PostEmbedCriteriaSectionFields
}
const CriteriaSection: React.FC<CriteriaSectionProps> = ({
  fields,
}: CriteriaSectionProps) => (
  <section className={css.CriteriaSection__criteriaCardContainer}>
    {fields.criteriaCards?.map((criteria) => (
      <CriteriaCard fields={criteria.fields} key={criteria.sys.id} />
    ))}
  </section>
)

export default CriteriaSection
