import { useState } from 'react'
import PropTypes from 'prop-types'
import { SaveButton } from 'components/buttons'
import { Checkbox, CheckboxGroup, Input } from 'components/forms'
import Link from 'components/link'

const CashFundPreferencesForm = ({ handleSubmit, preferences, isEditable }) => {
  const [direct, setDirect] = useState(preferences?.direct)
  const [paypalme, setPaypalme] = useState(preferences?.paypalme)
  const [paypalVisible, setPaypalVisible] = useState(preferences?.paypalme)
  const [submitting, setSubmitting] = useState(false)

  return (
    <>
      <CheckboxGroup title="Gift Giver Donation Options">
        <Checkbox
          disabled={!isEditable}
          label="Receive cash or check by mail or in-person."
          name="direct"
          onChange={() => setDirect(!direct)}
          checked={!!direct}
        />
        <Checkbox
          disabled={!isEditable}
          label="PayPal (Requires you to have a Paypal account)"
          name="paypalVisible"
          onChange={() => {
            setPaypalVisible(!paypalVisible)
            setPaypalme(!paypalVisible && null)
          }}
          checked={!!paypalVisible}
        />
      </CheckboxGroup>
      {!!paypalVisible && (
        <Input
          disabled={!isEditable}
          label="PayPal.Me Link"
          name="paypal"
          help={paypalmeHelpText(paypalme)}
          style={{ paddingLeft: 0, marginLeft: 0 }}
          addonBefore="paypal.me/"
          addonStyle={{ paddingRight: 0 }}
          type="text"
          value={paypalme || ''}
          onChange={(e) => setPaypalme(e.target.value)}
        />
      )}
      {isEditable && (
        <SaveButton
          disabled={!!paypalVisible && !paypalme}
          onClick={() => {
            setSubmitting(true)
            handleSubmit({ direct, paypalme })
              .then(() => setSubmitting(false))
              .catch(() => setSubmitting(false))
          }}
          pill
          submitting={submitting}
        />
      )}
    </>
  )
}

CashFundPreferencesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  preferences: PropTypes.shape({
    direct: PropTypes.bool,
    paypalme: PropTypes.string,
  }),
}

const paypalmeHelpText = (input) => {
  return input ? (
    <span>
      Please double-check that{' '}
      <Link url={`https://www.paypal.me/${input}`} newWindow>
        https://www.paypal.me/{input}
      </Link>{' '}
      is correct.
    </span>
  ) : (
    <span>
      Enter your PayPal.Me username or{' '}
      <Link url="https://www.paypal.me/" newWindow>
        Create a new one
      </Link>
    </span>
  )
}

export default CashFundPreferencesForm
