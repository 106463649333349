import { FC } from 'react'
import { TextLink } from 'baby-design'
import { LinkOut } from 'baby-design/icons'
import ToggleNav from '../ToggleNav'
import {
  healthPath,
  helloBabyPostPath,
  registryPath,
  registryHomePath,
  storePath,
} from 'lib/urls'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import css from '../../global-nav.scss'

interface LoggedInSiteNavSectionProps {
  activeNavItem?: 'Home' | 'Registry' | 'Store' | 'Guides'
  className?: string
  handleToggleNav: () => void
  trackClick: (eventCta: string) => void
}

const LoggedInSiteNavSection: FC<LoggedInSiteNavSectionProps> = ({
  activeNavItem,
  className,
  handleToggleNav,
  trackClick,
}) => {
  const [currentUser] = useCurrentUser()

  let helloBabyPost = null
  switch (currentUser?.currentRegistry?.type) {
    case 'birthday':
      helloBabyPost = 'toddler'
      break
    case 'holiday':
      helloBabyPost = 'holiday'
      break
    default:
      helloBabyPost = null
      break
  }

  return (
    <ul className={className}>
      <ToggleNav onClick={handleToggleNav} />
      <li className={css.fullNavOnly}>
        <TextLink
          href={registryHomePath}
          isSelected={activeNavItem === 'Home'}
          size="md"
          weight={activeNavItem === 'Home' ? 'medium' : 'regular'}
          onClick={() => trackClick('Home')}
        >
          Home
        </TextLink>
      </li>
      <li className={css.fullNavOnly}>
        <TextLink
          href={
            currentUser?.currentRegistry?.urlSlug
              ? registryPath(currentUser?.currentRegistry?.urlSlug)
              : registryHomePath
          }
          isSelected={activeNavItem === 'Registry'}
          size="md"
          weight={activeNavItem === 'Registry' ? 'medium' : 'regular'}
          onClick={() => trackClick('Registry')}
        >
          Registry
        </TextLink>
      </li>
      <li className={css.fullNavOnly}>
        <TextLink
          href={storePath}
          isSelected={activeNavItem === 'Store'}
          size="md"
          weight={activeNavItem === 'Store' ? 'medium' : 'regular'}
          onClick={() => trackClick('Shop')}
        >
          Shop
        </TextLink>
      </li>
      <li className={css.fullNavOnly}>
        <TextLink
          href={helloBabyPostPath(helloBabyPost)}
          isSelected={activeNavItem === 'Guides'}
          size="md"
          weight={activeNavItem === 'Guides' ? 'medium' : 'regular'}
          onClick={() => trackClick('Guides')}
        >
          Guides
        </TextLink>
      </li>
      <li className={css.fullNavOnly}>
        <TextLink
          href={healthPath}
          icon={{
            side: 'right',
            icon: <LinkOut />,
          }}
          rel="noreferrer"
          size="md"
          target="_blank"
          weight="regular"
          onClick={() => trackClick('Health')}
        >
          Health
        </TextLink>
      </li>
    </ul>
  )
}

export default LoggedInSiteNavSection
