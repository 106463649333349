import React from 'react'

interface ChromeExtensionProps {
  className?: string
}

const ChromeExtension: React.FC<ChromeExtensionProps> = ({
  className,
}: ChromeExtensionProps) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M11.5 4.5H15C15.5523 4.5 16 4.94772 16 5.5V9C17.3807 9 18.5 10.1193 18.5 11.5C18.5 12.8807 17.3807 14 16 14V17.5C16 18.0523 15.5523 18.5 15 18.5H3C2.44772 18.5 2 18.0523 2 17.5V13.372C2.23458 13.4549 2.48702 13.5 2.75 13.5C3.99264 13.5 5 12.4926 5 11.25C5 10.0074 3.99264 9 2.75 9C2.48702 9 2.23458 9.04512 2 9.12803V5.5C2 4.94772 2.44772 4.5 3 4.5H6.5C6.5 3.11929 7.61929 2 9 2C10.3807 2 11.5 3.11929 11.5 4.5Z"
      fill="#8F8A8C"
      fillRule="evenodd"
    />
  </svg>
)

export default ChromeExtension
