import classNames from 'classnames'

import Link from 'components/link'
import { helloBabyPostPath } from 'lib/urls'
import css from './HeroPost.scss'

interface HeroPostProps {
  navigateWithRouter?: boolean
  post: any
}

const HeroPost = ({ navigateWithRouter = true, post }: HeroPostProps) => {
  const navigationProp = {
    [!navigateWithRouter ? 'url' : 'to']: helloBabyPostPath(post && post.slug),
  }

  return (
    <div className={classNames(css.heroPostContainer)}>
      <div
        style={{ backgroundImage: 'url(' + post.featuredImage?.url + ')' }}
        className={classNames(css.featuredImage, 'hover-overlay hover-lighten')}
      ></div>
      <div className={classNames(css.postInfo, 'flex-vertical-center')}>
        <div>
          <Link
            className={classNames(css.postTitle, 'h3', 'text-body')}
            {...navigationProp}
          >
            {post.title}
          </Link>
          <p className={classNames(css.textSerifGuides, 'mbn')}>
            {post.teaser}
          </p>
        </div>
      </div>
    </div>
  )
}

export default HeroPost
