import { SET_USER_CREDIT } from '../actions'

const userCreditReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_USER_CREDIT: {
      return action.userCredit
    }
    default:
      return state
  }
}

export default userCreditReducer
