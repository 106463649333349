import PropTypes from 'prop-types'
import classNames from 'classnames'
import EditRegItemLink from './edit-reg-item-link'
import Link from 'components/link'
import { regItemProps } from './reg-item-props'
import RegItemCardLayout from './reg-item-card-layout'
import OwnerActions from './owner-actions/owner-actions'
import ExternalRegistryAction from './guest-actions/external-registry-action'
import Card from 'components/card'

export const ExternalRegistryRegItemCard = (props, { isMobile }) => {
  const { isContributorView, regItem } = props
  const {
    id,
    primaryOfferUrl,
    offers,
    url,
    isEditable,
    viglinkEnabled,
    title,
  } = regItem

  const regItemLink = (child) => {
    if (!isContributorView) {
      return (
        <Link
          className="link-info"
          url={primaryOfferUrl || url}
          rel="nofollow"
          newWindow
        >
          {child}
        </Link>
      )
    }
    // contributor view
    if (isMobile && isEditable) {
      return <EditRegItemLink regItemId={id}>{child}</EditRegItemLink>
    }
    return (
      <Link
        className="link-info"
        url={primaryOfferUrl || url}
        rel="nofollow"
        newWindow
      >
        {child}
      </Link>
    )
  }

  const actions = () => {
    if (isContributorView) {
      return <OwnerActions showEditAction regItemId={id} />
    }

    const Wrapper = ({ children }) => {
      if (!isMobile) {
        return <Card className="pal">{children}</Card>
      }
      return children
    }

    return (
      <Wrapper>
        <div>
          <div className="flex align-center">
            <span
              className={classNames(
                'icon-offer',
                `icon-offer-${offers[0].storeIconName}`
              )}
            />
            <div className="flex-grow col plm">
              <div className="flex">
                <div className="text-bold h5 flex grow mtn mbn">
                  {offers[0].storeDisplayName}
                </div>
              </div>
            </div>
          </div>
          <div>
            <ExternalRegistryAction
              primaryOfferUrl={primaryOfferUrl}
              viglinkEnabled={viglinkEnabled}
              title={title}
            />
          </div>
        </div>
      </Wrapper>
    )
  }

  return (
    <RegItemCardLayout
      {...props}
      regItemLinkDecorator={regItemLink}
      actions={actions()}
    />
  )
}

ExternalRegistryRegItemCard.propTypes = {
  regItem: PropTypes.shape(regItemProps),
  isContributorView: PropTypes.bool,
  reservationByCurrentVisitor: PropTypes.object,
  isRegistryDiscountView: PropTypes.bool,
}

ExternalRegistryRegItemCard.contextTypes = {
  isMobile: PropTypes.bool,
}

export default ExternalRegistryRegItemCard
