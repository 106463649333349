import { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isFinite } from 'lodash'
import css from './quantity.scss'

const NO_LIMIT_VALUE = -1

class Quantity extends Component {
  handleClickDown(e) {
    const { min, onChange, unlimited, value } = this.props

    if (unlimited && value === NO_LIMIT_VALUE) {
      // unlimited is supported and we're already at no limit
      // can't go down from here, do nothing
      return
    }

    if (unlimited && value === min) {
      // unlimited is supported and we're already at minimum value
      // change to no limit
      onChange(NO_LIMIT_VALUE)
      return
    }

    const nextValue = Math.max(value - 1, min)
    if (value !== nextValue) {
      onChange(nextValue)
    }
  }

  handleClickUp(e) {
    const { max, onChange, value } = this.props

    if (value === NO_LIMIT_VALUE) {
      // Skip 0, go from unlimited quantity (-1) to 1
      onChange(1)
    } else if (!isFinite(max) || value < max) {
      onChange((value || 0) + 1)
    }
  }

  render() {
    const { className, inputRef, label, limit, max, min, unlimited, value } =
      this.props
    return (
      <div className={classNames(css.container, className)}>
        {label && (
          <label
            htmlFor="bl-quantity"
            aria-label={`Quantity: ${value}`}
            className={classNames(css.label, 'control-label')}
          >
            Quantity
            {limit && <p className="h7">Limit of {limit} per customer</p>}
          </label>
        )}
        <div>
          <button
            id="bl-quantity-increment"
            key="btn-increment"
            type="button"
            onClick={this.handleClickDown.bind(this)}
            className={classNames([css.button], {
              [css.disabled]:
                (!unlimited && value <= min) ||
                (unlimited && value === NO_LIMIT_VALUE) ||
                value == null,
            })}
          >
            <svg
              className={css.subtract}
              width="13"
              height="13"
              viewBox="0 0 13 1"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
            >
              <title>decrease quantity</title>
              <path d="M0 0h13v1H0z" fill="%236e3264" fillRule="evenodd" />
            </svg>
          </button>
          <input
            ref={inputRef}
            type="hidden"
            name="quantity"
            id="bl-quantity"
            value={value}
          />
          <p aria-hidden="true" className={css.value}>
            {value > 0 ? value : <small>No limit</small>}
          </p>
          <button
            id="bl-quantity-decrement"
            key="btn-decrement"
            type="button"
            onClick={this.handleClickUp.bind(this)}
            className={classNames([css.button], {
              [css.disabled]: value >= max,
            })}
          >
            <svg
              className={css.add}
              width="13"
              height="13"
              viewBox="0 0 13 13"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
            >
              <title>increase quantity</title>
              <path
                d="M7 6V0H6v6H0v1h6v6h1V7h6V6"
                fill="%236e3264"
                fillRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    )
  }
}

Quantity.propTypes = {
  className: PropTypes.string,
  inputRef: PropTypes.func,
  label: PropTypes.string,
  limit: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  unlimited: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Quantity.defaultProps = {
  className: undefined,
  inputRef: () => {},
  label: 'Quantity',
  limit: null,
  max: undefined,
  min: 1,
  unlimited: false,
  value: undefined,
}

export default Quantity
