import { useEffect, useRef } from 'react'

const DEFAULT_VISIBILITY_THRESHOLD = 1

const useVisibilityTracking = (
  trackEvent: () => unknown,
  rootRef: React.RefObject<HTMLElement> | null | undefined,
  preview = false,
  threshold = DEFAULT_VISIBILITY_THRESHOLD
) => {
  const eventHasFired = useRef(false)
  const childRef = useRef(null)

  useEffect(() => {
    let observerOptions: any = {
      threshold,
    }

    if (rootRef?.current) {
      observerOptions = {
        ...observerOptions,
        root: rootRef?.current,
      }
    }

    const observer = new IntersectionObserver(([e]) => {
      if (
        e.intersectionRatio >= DEFAULT_VISIBILITY_THRESHOLD &&
        !eventHasFired.current
      ) {
        eventHasFired.current = true
        if (preview) return
        trackEvent()
      }
    }, observerOptions)

    if (childRef?.current) {
      observer.observe(childRef?.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [childRef, rootRef])

  const setEventHasFired = (fired: boolean) => {
    eventHasFired.current = fired
  }

  return [childRef, setEventHasFired]
}

export default useVisibilityTracking
