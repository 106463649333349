import { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Tappable from 'react-tappable'
import { Radio as BSRadio } from 'react-bootstrap'
import { buildComponent } from './build-component'
import { sizesProp } from './prop-types/size-props'

export default class Radio extends Component {
  static propTypes = {
    block: PropTypes.bool,
    checked: PropTypes.bool,
    className: PropTypes.string,
    tappable: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    groupClassName: PropTypes.string,
    help: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    size: sizesProp,
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
    wrapperClassName: PropTypes.string,
  }

  render() {
    const { label } = this.props

    let inputProps = {
      block: this.props.block,
      bsSize: this.props.size,
      checked: this.props.checked,
      className: this.props.className,
      defaultChecked: this.props.defaultChecked,
      disabled: this.props.disabled,
      name: this.props.name,
      onChange: this.props.onChange,
      placeholder: this.props.placeholder,
      readOnly: this.props.readOnly,
      size: this.props.size,
      value: this.props.value,
    }

    let radioComponent = buildComponent(
      <BSRadio
        role="radio"
        aria-checked={inputProps.checked}
        tabIndex={inputProps.checked ? '0' : '-1'}
        className={classNames(inputProps.className, {
          'radio--block': inputProps.block,
        })}
        inputRef={this.props.inputRef || this.setInputRef.bind(this)}
        {...inputProps}
      >
        <div className="label-text">{label}</div>
      </BSRadio>,
      this.props
    )

    return this.props.tappable ? (
      <Tappable
        component="div"
        classBase="input-tappable"
        className="input-tappable"
      >
        {radioComponent}
      </Tappable>
    ) : (
      radioComponent
    )
  }

  setInputRef(ref) {
    this._input = ref
  }
}
