import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from 'components/modal/modal'
import { Col, ModalBody, Row } from 'react-bootstrap'
import { recoverReservationFaqPath } from 'lib/urls'
import { successMessage } from 'lib/flash-message'
import Link from 'components/link'
import MailcheckedInput from 'components/mailchecked-input'
import FormButtonGroup from 'components/forms/form-button-group'
import { sendReservationRecoveryEmail } from '../../actions'

export const BaseRecoverReservationModal = ({
  onHide,
  regItemId,
  sendReservationRecoveryEmail,
  show,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState()
  const [email, setEmail] = useState()

  const handleSubmit = (e) => {
    e.preventDefault()

    setIsSubmitting(true)
    sendReservationRecoveryEmail(email, regItemId)
      .then(() => {
        successMessage(
          `We've sent you a link to manage this gift! Check your "${email}" email inbox.`
        )
        onHide()
      })
      .catch(() => {
        setError(
          `Sorry, this item is not associated with "${email}", please try a different email address.`
        )
        setIsSubmitting(false)
      })
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Did you previously select this gift?"
    >
      <ModalBody>
        <Row>
          <Col xs={12}>
            Confirm your email and we'll send you a link to manage this gift.
          </Col>
        </Row>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} className="mtl">
              <MailcheckedInput
                onChange={(e) => setEmail(e.target.value)}
                error={error}
                name="email"
                label="Email address"
                type="email"
                required
              />
            </Col>
          </Row>

          <FormButtonGroup
            className="mtl"
            onClickSecondary={onHide}
            submitting={isSubmitting}
            textPrimary="Send Email"
            textSecondary="Cancel"
          />
        </form>

        {error ? (
          <Row className="mtl">
            <Col xs={12} className="text-center mvm">
              <Link
                className="small text-uppercase"
                url={recoverReservationFaqPath}
                newWindow
              >
                Need Help?
              </Link>
            </Col>
          </Row>
        ) : null}
      </ModalBody>
    </Modal>
  )
}

BaseRecoverReservationModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  regItemId: PropTypes.number.isRequired,
  sendReservationRecoveryEmail: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

BaseRecoverReservationModal.defaultProps = {
  show: false,
}

export const RecoverReservationModalComponent = connect(undefined, {
  sendReservationRecoveryEmail,
})(BaseRecoverReservationModal)
