/* global PubSub:true*/
import { Component } from 'react'
import PropTypes from 'prop-types'
import { bowserFlags } from 'lib/bowser'
import { AppPromoAddItemModal } from 'shared/app-modal-wave'
import {
  HIDDEN_APP_MODAL_EVENT,
  HIDE_APP_MODAL_EVENT,
  SHOW_APP_MODAL_EVENT,
} from './constants'
import { mobileAppDownloadPath } from '../lib/urls'

export default class AppModal extends Component {
  static propTypes = {
    isPinterest: PropTypes.bool,
  }

  state = {
    isVisible: false,
  }

  componentDidMount() {
    PubSub.subscribe(SHOW_APP_MODAL_EVENT, (data) => {
      if (bowserFlags.ios || bowserFlags.android) {
        this.setState({
          isVisible: true,
        })
      }
    })

    PubSub.subscribe(HIDE_APP_MODAL_EVENT, (event) => {
      this.hideModal()
    })
  }

  hideModal = () => {
    this.setState({ isVisible: false }, () => {
      PubSub.publish(HIDDEN_APP_MODAL_EVENT)
    })
  }

  render() {
    return (
      <AppPromoAddItemModal
        isVisible={this.state.isVisible}
        hideModal={this.hideModal}
        downloadPath={mobileAppDownloadPath()}
      />
    )
  }
}
