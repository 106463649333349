import css from './SearchResults.scss'
import { SearchResult, ResultType } from '../types'
import classNames from 'classnames'
import SearchResultHeading from './components/SearchResultHeading'
import SearchResultTypeAheadSuggestion from './components/SearchResultTypeAheadSuggestion'
import SearchResultSuggestedResultText from './components/SearchResultSuggestedResultText'
import SearchResultSectionHeading from './components/SearchResultSectionHeading'
import SearchResultText from './components/SearchResultText'
import SearchResultAdBanner from './components/SearchResultAdBanner'

interface SearchResultsProps {
  searchResults: SearchResult[]
  setQuery: (query: string) => void
  searchQuery: string
}

const SearchResults = ({
  searchResults,
  setQuery,
  searchQuery,
}: SearchResultsProps) => {
  const renderResult = (result: any) => {
    switch (result.type) {
      case 'heading':
        return <SearchResultHeading result={result} />
      case 'type_ahead_suggestion':
        return (
          <SearchResultTypeAheadSuggestion
            result={result}
            setQuery={setQuery}
          />
        )
      case 'section_heading':
        return (
          <SearchResultSectionHeading
            result={result}
            searchQuery={searchQuery}
          />
        )
      case 'suggested_result_text':
        return <SearchResultSuggestedResultText result={result} />
      case 'text':
        return <SearchResultText result={result} />
      case 'ad_banner':
        return <SearchResultAdBanner result={result} />
      default:
        return <></>
    }
  }

  const renderResultListItem = (result: any, idx: number) => {
    const nextListItemType = searchResults[idx + 1]?.type
    const isLastItem =
      idx == searchResults.length - 1 ||
      [
        ResultType.HEADING,
        ResultType.SECTION_HEADING,
        ResultType.AD_BANNER,
      ].includes(nextListItemType)

    return (
      <li
        className={classNames(css.SearchResults__listItem, {
          [css.removeBottomBorder]: isLastItem,
        })}
        key={idx}
      >
        {renderResult(result)}
      </li>
    )
  }

  return (
    <div className={css.SearchResults__container}>
      <ul>
        {searchResults.map((result, idx) => renderResultListItem(result, idx))}
      </ul>
    </div>
  )
}

export default SearchResults
