const CategoryNew = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 258.35 258.35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon
      fill="#d8d8d8"
      points="135.06 188.44 135.06 189.88 114.87 189.88 114.87 189.03 114.87 114.43 114.87 113.73 135.06 113.73 135.06 113.99 135.06 188.44"
    />
    <path
      d="M114.87,189v.85H82.59a2.77,2.77,0,0,1-2.77-2.76V116.49a2.76,2.76,0,0,1,2.77-2.76h32.28V189Z"
      fill="#fff"
    />
    <path
      d="M169.71,189v.85H137.44a2.77,2.77,0,0,1-2.77-2.76V116.49a2.76,2.76,0,0,1,2.77-2.76h32.27V189Z"
      fill="#fff"
    />
    <path
      d="M169.83,116.49v70.63a2.76,2.76,0,0,1-2.77,2.76h-32V113.73h32A2.75,2.75,0,0,1,169.83,116.49Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4.38px"
    />
    <path
      d="M135.06,113.73h32a2.75,2.75,0,0,1,2.77,2.76v70.63a2.76,2.76,0,0,1-2.77,2.76H82.59a2.77,2.77,0,0,1-2.77-2.76V116.49a2.76,2.76,0,0,1,2.77-2.76h52.47Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4.38px"
    />
    <line
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4.38px"
      x1="114.87"
      x2="114.87"
      y1="114.43"
      y2="189.03"
    />
    <line
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4.38px"
      x1="135.06"
      x2="135.06"
      y1="113.99"
      y2="188.44"
    />
    <path
      d="M130.05,97.77c6-7.21,14.75-17.14,19.51-19.12a15,15,0,0,1,19.49,19.78H129.69c.12-.21.23-.42.33-.62C130,97.8,130,97.79,130.05,97.77Z"
      fill="#d8d8d8"
    />
    <path
      d="M119.86,97.75q.18.36.36.69H80.87a15,15,0,0,1,19.48-19.78C105.11,80.65,113.89,90.54,119.86,97.75Z"
      fill="#d8d8d8"
    />
    <path
      d="M124.94,58a15,15,0,0,1,15,15c0,5-5.54,16.54-9.87,24.78,0,0,0,0,0,0-.19.2-.36.42-.53.62h-9.07l-.56-.69C115.51,89.52,110,78,110,73A15,15,0,0,1,124.94,58Z"
      fill="#d8d8d8"
    />
    <polygon
      fill="#d8d8d8"
      points="135.19 98.44 135.19 98.71 135.2 115.97 135.19 117.42 115.01 117.42 115.02 116.58 115 99.16 115 98.45 120.22 98.44 120.42 98.44 129.49 98.44 129.69 98.44 135.19 98.44"
    />
    <path
      d="M173.87,98.43a2.77,2.77,0,0,1,2.77,2.75v13.45a2.76,2.76,0,0,1-2.76,2.77l-38.69,0v-19h38.68Z"
      fill="#fff"
      stroke="#adadad"
      strokeMiterlimit="10"
    />
    <path
      d="M80.87,98.46H115v.71l0,17.42v.84l-39,0a2.74,2.74,0,0,1-2.75-2.76l0-13.45a2.78,2.78,0,0,1,2.77-2.77Z"
      fill="#fff"
      stroke="#adadad"
      strokeMiterlimit="10"
    />
    <path
      d="M169.05,98.43h4.82a2.77,2.77,0,0,1,2.77,2.75v13.45a2.76,2.76,0,0,1-2.76,2.77l-38.69,0H115l-39,0a2.74,2.74,0,0,1-2.75-2.76l0-13.45a2.78,2.78,0,0,1,2.77-2.77h93Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4.38px"
    />
    <line
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4.38px"
      x1="115"
      x2="115.02"
      y1="99.16"
      y2="116.58"
    />
    <line
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4.38px"
      x1="135.19"
      x2="135.2"
      y1="98.71"
      y2="115.97"
    />
    <path
      className="cls-5"
      d="M130,97.82c-.1.2-.21.41-.33.62"
      stroke="#6e3264"
      strokeMiterlimit="10"
    />
    <path
      d="M120.22,98.44q-.18-.33-.36-.69C115.51,89.52,110,78,110,73a15,15,0,1,1,29.95,0c0,5-5.54,16.54-9.87,24.78"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4.38px"
    />
    <path
      d="M169.05,98.43a15,15,0,0,0-19.49-19.78c-4.76,2-13.55,11.91-19.51,19.12,0,0,0,0,0,0-.19.2-.36.42-.53.62"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4.38px"
    />
    <path
      d="M80.88,98.47h0a15,15,0,0,1,19.48-19.78c4.76,2,13.54,11.86,19.51,19.07l.56.69"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4.38px"
    />
  </svg>
)

export default CategoryNew
