import { FC, useCallback, useEffect, useState } from 'react'
import MessageCenter from 'components/global-nav/components/MessageCenter'
import { NotificationBell } from 'baby-design/icons'
import { Inbox } from 'components/global-nav/components/MessageCenter/types'
import { MessageCenterContext } from 'components/global-nav/components/MessageCenter/contexts'
import MessageCenterDropdownButton from 'components/global-nav/components/MessageCenterDropDownButton/MessageCenterDropdownButton'
import {
  lockBodyScroll,
  unlockBodyScroll,
} from 'shared/utils/lockBodyScroll/lockBodyScroll'
import css from './MessageCenterMenu.scss'

const parseDate = (val: Date | string | null | undefined) => {
  if (!val) return null
  if (val instanceof Date) return val.getTime()
  return Date.parse(val)
}

type ActiveMenu = 'account' | 'messageCenter' | 'cart'

interface MessageCenterMenuProps {
  activeMenu: ActiveMenu
  onMenuOpen: () => void
}

const MessageCenterMenu: FC<MessageCenterMenuProps> = ({
  activeMenu,
  onMenuOpen,
}) => {
  const [hasUnseenMessages, setHasUnseenMessages] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [shouldClose, setShouldClose] = useState(false)
  const [keepMenuOpen, setKeepMenuOpen] = useState(false)

  const handleInboxChanged = useCallback(
    (inbox: Inbox) => {
      const { lastViewedAt, messages } = inbox
      setHasUnseenMessages(
        !!messages?.some((message) => {
          if (!lastViewedAt) return true
          const lastViewed = parseDate(lastViewedAt)
          const createdAt = parseDate(message.createdAt)
          return lastViewed && createdAt && lastViewed < createdAt
        })
      )
    },
    [setHasUnseenMessages]
  )

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (shouldClose && menuOpen) {
      timeout = setTimeout(() => {
        handleInboxClosed()
        setShouldClose(false)
      }, 500)
    }
    return () => clearTimeout(timeout)
  }, [shouldClose, menuOpen])

  const handleInboxOpened = () => {
    lockBodyScroll('message-center')
    setMenuOpen(true)
    onMenuOpen()
  }

  const handleInboxClosed = () => {
    unlockBodyScroll('message-center')
    setMenuOpen(false)
    setKeepMenuOpen(false)
  }

  const handleButtonClick = () => {
    if (!menuOpen) {
      handleInboxOpened()
      setKeepMenuOpen(true)
    } else if (!keepMenuOpen) {
      setKeepMenuOpen(true)
    } else {
      handleInboxClosed()
      setKeepMenuOpen(false)
    }
  }

  const handleMouseEnter = () => {
    handleInboxOpened()
    setShouldClose(false)
  }

  const handleMouseLeave = () => {
    if (keepMenuOpen) return

    setShouldClose(true)
  }

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      handleInboxClosed()
    }
  }, [])

  useEffect(() => {
    // Close when escape is pressed anywhere on the page
    if (menuOpen) {
      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [menuOpen, handleKeyDown])

  useEffect(() => {
    if (menuOpen && activeMenu !== 'messageCenter') {
      handleInboxClosed()
    }
  }, [activeMenu, menuOpen])

  const title = (
    <div className={css.MessageCenterMenu__title}>
      <NotificationBell className={css.MessageCenterMenu__bellIcon} />
      {hasUnseenMessages && (
        <div
          className={css.MessageCenterMenu__unreadMessagesBadge}
          data-testid="unseen-messages-badge"
        />
      )}
    </div>
  )

  return (
    <MessageCenterContext.Provider value={{ isOpen: menuOpen }}>
      <MessageCenterDropdownButton
        title={title}
        onClick={handleButtonClick}
        onClickAway={handleInboxClosed}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <MessageCenter onInboxChanged={handleInboxChanged} />
      </MessageCenterDropdownButton>
    </MessageCenterContext.Provider>
  )
}

export default MessageCenterMenu
