import React from 'react'

interface CopyProps {
  className?: string
  filled?: boolean
}

const Copy: React.FC<CopyProps> = ({ className, filled = false }) => (
  <svg
    className={className}
    fill="none"
    height="22"
    viewBox="0 0 18 22"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    {filled ? (
      <>
        <path
          d="M15.2321 1.25H11.7679C10.9551 1.24999 10.2994 1.24999 9.76853 1.29336C9.2219 1.33803 8.74175 1.43239 8.29754 1.65873C7.59193 2.01825 7.01825 2.59193 6.65873 3.29754C6.43239 3.74175 6.33803 4.2219 6.29336 4.76853C6.24999 5.29944 6.24999 5.95505 6.25 6.76789V14.2321C6.24999 15.0449 6.24999 15.7006 6.29336 16.2315C6.33803 16.7781 6.43239 17.2582 6.65873 17.7025C7.01825 18.4081 7.59193 18.9817 8.29754 19.3413C8.74175 19.5676 9.2219 19.662 9.76853 19.7066C10.2994 19.75 10.955 19.75 11.7679 19.75H15.2321C16.045 19.75 16.7006 19.75 17.2315 19.7066C17.7781 19.662 18.2582 19.5676 18.7025 19.3413C19.4081 18.9817 19.9818 18.4081 20.3413 17.7025C20.5676 17.2582 20.662 16.7781 20.7066 16.2315C20.75 15.7006 20.75 15.045 20.75 14.2321V6.76788C20.75 5.95505 20.75 5.29944 20.7066 4.76853C20.662 4.2219 20.5676 3.74175 20.3413 3.29754C19.9818 2.59193 19.4081 2.01825 18.7025 1.65873C18.2582 1.43239 17.7781 1.33803 17.2315 1.29336C16.7006 1.24999 16.0449 1.24999 15.2321 1.25Z"
          fill="#252324"
        />
        <path
          clipRule="evenodd"
          d="M4 4.25C4.41421 4.25 4.75 4.58579 4.75 5L4.75 17C4.75 19.3472 6.65279 21.25 9 21.25H18C18.4142 21.25 18.75 21.5858 18.75 22C18.75 22.4142 18.4142 22.75 18 22.75H9C5.82436 22.75 3.25 20.1756 3.25 17L3.25 5C3.25 4.58579 3.58579 4.25 4 4.25Z"
          fill="#252324"
          fillRule="evenodd"
        />
      </>
    ) : (
      <path
        d="M1 4L1 16C1 18.7614 3.23858 21 6 21H15M8.8 18H12.2C13.8802 18 14.7202 18 15.362 17.673C15.9265 17.3854 16.3854 16.9265 16.673 16.362C17 15.7202 17 14.8802 17 13.2V5.8C17 4.11984 17 3.27976 16.673 2.63803C16.3854 2.07354 15.9265 1.6146 15.362 1.32698C14.7202 1 13.8802 1 12.2 1H8.8C7.11984 1 6.27976 1 5.63803 1.32698C5.07354 1.6146 4.6146 2.07354 4.32698 2.63803C4 3.27976 4 4.11984 4 5.8V13.2C4 14.8802 4 15.7202 4.32698 16.362C4.6146 16.9265 5.07354 17.3854 5.63803 17.673C6.27976 18 7.11984 18 8.8 18Z"
        id="Vector"
        stroke="#252324"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    )}
  </svg>
)

export default Copy
