import React from 'react'

interface LeftChevronProps {
  className?: string
  filled?: boolean
}

const outlinedPath = (
  <path
    d="M15 6L10.1314 10.8686C9.73535 11.2646 9.53735 11.4627 9.46316 11.691C9.3979 11.8918 9.3979 12.1082 9.46316 12.309C9.53735 12.5373 9.73535 12.7354 10.1314 13.1314L15 18"
    stroke="#252324"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5"
  />
)

const filledPath = (
  <path
    clipRule="evenodd"
    d="M15.5302 5.46967C15.8231 5.76256 15.8231 6.23744 15.5302 6.53033L10.6616 11.399C10.4548 11.6057 10.332 11.7293 10.2482 11.8281C10.197 11.8884 10.1796 11.9178 10.1753 11.926C10.1603 11.9742 10.1603 12.0258 10.1753 12.074C10.1796 12.0822 10.197 12.1116 10.2482 12.1719C10.332 12.2707 10.4548 12.3943 10.6615 12.601L15.5302 17.4697C15.8231 17.7626 15.8231 18.2374 15.5302 18.5303C15.2373 18.8232 14.7624 18.8232 14.4695 18.5303L9.58236 13.6432C9.40027 13.4611 9.23304 13.2939 9.10467 13.1427C8.96628 12.9797 8.82959 12.7866 8.74971 12.5408C8.63551 12.1893 8.63551 11.8107 8.74971 11.4592C8.82959 11.2134 8.96628 11.0203 9.10467 10.8573C9.23304 10.7061 9.40027 10.5389 9.58236 10.3568L14.4695 5.46967C14.7624 5.17678 15.2373 5.17678 15.5302 5.46967Z"
    fill="#252324"
    fillRule="evenodd"
  />
)

const LeftChevron: React.FC<LeftChevronProps> = ({
  className,
  filled,
}: LeftChevronProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {filled ? filledPath : outlinedPath}
  </svg>
)

export default LeftChevron
