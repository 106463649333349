import { FC } from 'react'
import { RegistryBannerVariant } from 'baby-ui/lib/RegistryBannerVariant'
import { BannerProportion } from 'baby-ui/compounds/RegistryBanner/BannerProportion'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import { ChecklistCategoryBreastPumpBanner } from './ChecklistCategoryBreastPumpBanner'
import { SearchBreastPumpBanner } from './SearchBreastPumpBanner'
import { SHOW_HEALTH_REGISTRY_BANNERS_KILL_SWITCH } from './RegistryBanner.constants'
import { RegistryBannerEvents } from './RegistryBannerEvents'

interface RegistryBannerProps extends RegistryBannerEvents {
  query?: string
  variant: RegistryBannerVariant | null
}

export const RegistryBanner: FC<RegistryBannerProps> = ({
  query = '',
  variant,
  ...rest
}) => {
  const { flagValue: showHealthRegistryBanners } = useFeatureFlag(
    SHOW_HEALTH_REGISTRY_BANNERS_KILL_SWITCH,
    false
  )

  if (!showHealthRegistryBanners) return null

  switch (variant) {
    case RegistryBannerVariant.ChecklistBreastPumpBanner:
      return <ChecklistCategoryBreastPumpBanner {...rest} />
    case RegistryBannerVariant.SearchResultBreastPumpBanner:
      return (
        <SearchBreastPumpBanner
          proportion={BannerProportion.Spacious}
          query={query}
          {...rest}
        />
      )
    default:
      return null
  }
}
