import React from 'react'

interface CrowdFundReservationModalContextProps {
  setModalTitle: (title: React.ReactNode | string | undefined) => void
}

const CrowdfundReservationModalContext = React.createContext({
  setModalTitle: () => {},
} as CrowdFundReservationModalContextProps)

export default CrowdfundReservationModalContext
