if (
  !global._babelPolyfill &&
  (typeof window === 'undefined' || !window._babelPolyfill)
) {
  require('core-js/stable')
  require('regenerator-runtime/runtime')
}

const ns = typeof window === 'undefined' ? global : window
ns.React = require('react')
ns.ReactDOM = require('react-dom')
ns.ReactDOMServer = require('react-dom/server')

// global debounce for scroll-top
ns.deprecatedDebounce = require('lodash.debounce')

// Stub Polyfill for matchMedia which is used by enquire.js
// we don't actually care about properly polyfilling it, we just don't want
// an error thrown in IE 9 about it
ns.matchMedia = ns.matchMedia || require('shared/match-media-stub').default

/**
 * Helper function to get the Avo debug param from the URL.
 */
function getAvoDebugUrlParam() {
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('avo_debug')
  }
  return null
}

/**
 * Toggles Avo debug mode based on URL param 'avo_debug'.
 * It sets the mode in localStorage to persist across sessions.
 */
const AVO_DEBUG_LOCAL_STORAGE_KEY = 'avo_debug'
function initializeAvoDebuggerFromURLParam() {
  const avoDebugUrlParam = getAvoDebugUrlParam()
  if (avoDebugUrlParam !== null) {
    localStorage.setItem(
      AVO_DEBUG_LOCAL_STORAGE_KEY,
      avoDebugUrlParam === '1' ? '1' : '0'
    )
  }
}

/**
 * Determines if the Avo debugger is enabled based on url param or localStorage.
 */
function isAvoDebuggerEnabled() {
  if (typeof window !== 'undefined') {
    const avoDebugUrlParam = getAvoDebugUrlParam()
    return (
      // Always enable in dev
      window.__BL_ANALYTICS__.env === 'dev' ||
      // Enable if url param is set
      avoDebugUrlParam === '1' ||
      // Enable if localStorage is set
      localStorage.getItem(AVO_DEBUG_LOCAL_STORAGE_KEY) === '1'
    )
  }
  return false
}

if (typeof window !== 'undefined') {
  /**
   * Override analytics env if Avo debugger is enabled.
   */
  document.addEventListener(
    'DOMContentLoaded',
    initializeAvoDebuggerFromURLParam
  )
  const avoDebuggerEnabled = isAvoDebuggerEnabled()
  const analyticsEnv = avoDebuggerEnabled ? 'dev' : window.__BL_ANALYTICS__.env

  /**
   * We only want to init analytics in clients and suppress analytics
   * side effects in journey testing (env !== 'prod' && noop === true).
   * We also want to noop if user agent is prerender.
   */
  const prerenderUserAgent =
    'Prerender (+https://github.com/prerender/prerender)'
  ns.blAnalytics = require('./lib/analytics')

  if (window.navigator.userAgent.includes(prerenderUserAgent)) {
    ns.blAnalytics.initAnalytics({
      // We do not want to trigger AVO events when the user agent is prerender
      // AVO prevents us from running `noop: false` if env is production
      // Deliberately setting env to `dev` here allows us to bypass that restriction
      strict: false,
      env: 'dev',
      inspectorEnv: 'dev',
      branchId: window.__BL_ANALYTICS__.branch_id,
      appVersion: window.__BL_ANALYTICS__.app_version,
      noop: true,
    })
  } else {
    ns.blAnalytics.initAnalytics({
      // Setting strict to false while we're ramping up our Avo setup.
      // Otherwise the errors would be loud and block rendering/tests, but
      // we just want to use the avo debugging tools.
      strict: false,
      env: analyticsEnv,
      inspectorEnv: window.__BL_ANALYTICS__.inspector_env,
      branchId: window.__BL_ANALYTICS__.branch_id,
      appVersion: window.__BL_ANALYTICS__.app_version,
      noop: window.__BL_ANALYTICS__.env === window.__BL_ANALYTICS__.TEST,
      webDebugger: avoDebuggerEnabled,
    })
  }
}

// global fetch
ns.fetch = ns.fetch || require('./lib/fetch').default

// global pubsub for times that it's needed
ns.PubSub = require('./lib/pubsub').shared()

// intended to be the only way to update the global currentUser
ns.PubSub.subscribe('currentUser.update', (updateObj) => {
  ns.currentUser = { ...ns.currentUser, ...updateObj }
})

ns.blUrls = require('./lib/urls')
ns.blCookies = require('./lib/cookies')
ns.BLConstants = require('./shared/constants.js')

// only include components that are navigational or need to be loaded from bl_registry/layouts/application
// all other React components should be included in the appropriate bundle if referenced from Rails view
ns.AppModal = require('./shared/app-modal').default
ns.UniversalNotificationModal =
  require('./shared/UniversalNotificationModal/UniversalNotificationModal').default
ns.BackBanner = require('./components/banners/back-banner').default
ns.FlashMessage = require('./shared/flash-message')
ns.Footer = require('./shared/Footer').default
ns.GlobalNav = require('./components/global-nav').default
ns.Header = require('./components/header').default
ns.MobileTabBar = require('./shared/mobile-tab-bar').default
ns.MicroUtilityBar = require('./components/MicroUtilityBar').default
ns.Redirect = require('./pages/redirect').default
ns.ReactQueryDevTools = require('./src/ReactQueryDevTools').default
ns.PlacementsGlobalBanner =
  require('./components/banners/PlacementsGlobalBanner').default

// dynamically generated from Contentful content
ns.BLImage = require('./components/image').default
ns.HeartRating = require('./components/heart-rating').default
ns.PinnableImage = require('./shared/pinnable-image').default

ns.trackSession = () => {
  if (!ns.navigator?.cookieEnabled) return

  const lastPageLoad = ns.blCookies.getLastPageLoadCookie()

  if (!lastPageLoad) {
    const currentUrlWithoutQueryString = ns.location?.href.split('?')[0]
    ns.blCookies.setSessionStartedUrl(currentUrlWithoutQueryString)
    ns.blAnalytics.track.sessionStarted({
      eventUrl: currentUrlWithoutQueryString,
      userId: ns.currentUser?.id,
    })
  }
  ns.blCookies.setLastPageLoadCookie()
}

ns.trackFindRegistryView = (eventType = 'Page Viewed') => {
  ns.blAnalytics.track.findRegistryViewed({
    eventLocation: 'Find My Registry',
    eventType,
    eventUrl: ns.location?.href,
  })
}

ns.setExpiringLocalStorage = (key, value, seconds) => {
  if (typeof localStorage === 'undefined') return

  const expiresAt = new Date(Date.now() + 1000 * seconds)
  localStorage.setItem(key, JSON.stringify({ expiresAt, value }))
}

ns.getExpiringLocalStorage = (key) => {
  if (typeof localStorage === 'undefined') return null

  const item = localStorage.getItem(key)
  if (!item) return null

  try {
    const object = JSON.parse(item)
    const today = new Date()
    const expireDate = new Date(object.expiresAt)
    if (today > expireDate) {
      localStorage.removeItem(key)
      return null
    }
    return object.value
  } catch (e) {
    return null
  }
}

ns.removeQueryParameter = (parameter) => {
  if (typeof window === undefined) return

  const urlObj = new URL(window.location.href)
  const queryParams = urlObj.searchParams
  queryParams.delete(parameter)
  urlObj.search = queryParams.toString()

  window.history.replaceState(null, '', urlObj.toString())
}

ns.removeQueryParameter =
  require('./shared/utils/removeQueryParam/removeQueryParam').default

ns.getSearchResultsPageEventType =
  require('./shared/helpers/getSearchResultsPageEventType').default

ns.trackRegistrySearchResultsPageViewed = (
  searchTerm,
  resultCount,
  topResults,
  executedSearch = false,
  registryOwnerId,
  registryId,
  isRegistryOwner = false,
  listType
) => {
  // Key must match the key in useSearchId
  const searchId = ns.getExpiringLocalStorage('bl-registry_searchId')

  ns.blAnalytics.track.searchResultsPageViewed({
    eventLocation: ns.blAnalytics.track.EventLocation.REGISTRY,
    eventType: ns.getSearchResultsPageEventType(
      ns.blAnalytics.track.EventType.REGISTRY
    ),
    eventUrl: ns.location?.href,
    searchId,
    searchTerm,
    resultCount,
    top20Results: topResults,
    executedSearch,
    registryOwnerId,
    registryId,
    isRegistryOwner,
    listType,
  })
}
