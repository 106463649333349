/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, FormButtonGroup } from 'components/forms'
import css from './reg-item-form.scss'
import RegItemFields from './reg-item-fields'
import FavorFields from './favor-fields'
import CashFundFields from './cash-fund-fields' // deprecated
import ExternalRegistryFields from './external-registry-fields'
import GiftCardFields from './gift-card-fields'
import CrowdfundFields from './crowdfund-fields'
import GroupGiftFields from './group-gift-fields'
import { isSecondaryLinkValid } from './secondary-links'

export default class RegItemModal extends Component {
  constructor(props) {
    super(props)

    const regItemFieldDefaults = {
      categoryId: 0,
      quantity: 1,
    }

    const regItemFields = { ...regItemFieldDefaults, ...props.regItem }
    if (props.regItem && props.regItem.category) {
      regItemFields.categoryId = props.regItem.category.id
    }

    this.state = { regItemFields, isFormValid: true }
  }

  componentDidUpdate() {
    if (this.props.error && this.state.isSubmitting) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isSubmitting: false })
    }
  }

  clearSubmitStatus = () => {
    this.setState({ isSubmitting: false })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const regItem = this.state.regItemFields

    if (this.isFormValid()) {
      this.setState({ isSubmitting: true })

      if (this.props.allowAddCategory) {
        this.props.onSubmit({
          regItem,
          newCategory: this.state.newCategoryFields,
        })
      } else {
        this.props.onSubmit(regItem)
      }
    }
  }

  updateFields = (resource, changes) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ [resource]: { ...this.state[resource], ...changes } })
  }

  isFormValid = () => {
    const { title, url } = this.state.regItemFields
    const isSecondaryLinksValid = () => {
      const secondaryLinks = this.state.regItemFields.secondaryLinks
      if (secondaryLinks === undefined) return true

      return secondaryLinks.every((link) =>
        isSecondaryLinkValid(link.url, link.price)
      )
    }

    return (
      title &&
      title.length > 0 &&
      (this.props.regItem.isCashFund ||
        this.props.regItem.isCrowdfund ||
        (url && url.length > 0)) && // Skip url validation for cash funds.
      isSecondaryLinksValid()
    )
  }

  render() {
    if (!this.props.regItem) {
      return null
    }

    let Fields
    if (this.props.regItem.isFavor) {
      Fields = FavorFields
    } else if (this.props.regItem.isCashFund) {
      Fields = CashFundFields
    } else if (this.props.regItem.isExternalRegistry) {
      Fields = ExternalRegistryFields
    } else if (this.props.regItem.isGiftCard) {
      Fields = GiftCardFields
    } else if (this.props.regItem.isCrowdfund) {
      Fields = CrowdfundFields
    } else if (this.props.regItem.isGroupGift) {
      Fields = GroupGiftFields
    } else {
      Fields = RegItemFields
    }

    return (
      <Form
        onSubmit={this.handleSubmit}
        className={css.regItemsForm}
        error={this.props.error ? this.props.error : null}
      >
        <Fields
          cashFundPreferences={this.props.cashFundPreferences}
          regItem={this.state.regItemFields}
          category={this.state.newCategoryFields}
          categories={this.props.categories}
          onRegItemChange={(changes) =>
            this.updateFields('regItemFields', changes)
          }
          onNewCategoryChange={
            this.props.allowAddCategory
              ? (changes) => this.updateFields('newCategoryFields', changes)
              : null
          }
          allowAddCategory={this.props.allowAddCategory}
          openEnableGroupGiftModal={this.props.openEnableGroupGiftModal}
          openDisableGroupGiftModal={this.props.openDisableGroupGiftModal}
        />

        <FormButtonGroup
          onClickSecondary={this.props.onRemove}
          primaryButtonDisabled={!this.isFormValid()}
          submitting={this.state.isSubmitting}
          textPrimary={this.props.submitBtnText}
          textSecondary={this.props.removeBtnText}
        />
      </Form>
    )
  }
}

RegItemModal.propTypes = {
  cashFundPreferences: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  openDisableGroupGiftModal: PropTypes.func.isRequired,
  openEnableGroupGiftModal: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  submitBtnText: PropTypes.string.isRequired,
  removeBtnText: PropTypes.string,
  regItem: PropTypes.object,
  allowAddCategory: PropTypes.bool,
  error: PropTypes.string,
}

RegItemModal.defaultProps = {
  removeBtnText: 'Remove',
}
