import Image from 'components/image'
import Link from 'components/link'
import classNames from 'classnames'
// @ts-ignore
import React, { useContext, useEffect, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import useClickAway from 'registry/components/useClickAway/useClickAway'
import fetch from 'lib/fetch'
import { apiV3MessagesPath } from 'lib/urls'
import { track, useTracking } from 'lib/analytics'
import { MessageCenterContext } from 'components/global-nav/components/MessageCenter/contexts'
import { trackMessageCenterEvent } from 'components/global-nav/components/MessageCenter/MessageCenter.util'
import { Message } from '../types'
import css from './MessageCard.scss'
import ElapsedTime from '../ElapsedTime'
import { useVisibilityTracking } from '../hooks'

interface SwipeActionsMenuProps {
  onMessageDeleted: () => unknown
}

interface MessageCardProps {
  message: Message
  onMessageDeleted?: (m: Message) => unknown
  onMessageRead?: (m: Message) => unknown
  position: number
  rootRef?: React.RefObject<HTMLElement>
  preview?: boolean
}

const DEFAULT_IMAGE =
  'https://images.babylist.com/image/upload/v1702678513/message-center-default-image_lj4rvp.svg'

const SwipeActionsMenu = ({ onMessageDeleted }: SwipeActionsMenuProps) => (
  <div className={css.MessageCardContainer__SwipeActionMenu}>
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
    <div
      className={css.MessageCardDeleteIcon}
      data-testid="message-archive-swipe-menu-icon"
      onClick={() => onMessageDeleted && onMessageDeleted()}
    />
  </div>
)

const MessageCard = ({
  message,
  onMessageDeleted,
  onMessageRead,
  position,
  rootRef,
  preview = false,
}: MessageCardProps) => {
  const tracker = useTracking()
  const { isOpen: messageCenterOpen } = useContext(MessageCenterContext)
  const [showSwipeActions, setShowSwipeActions] = useState(false)
  const clickAwayRef = useClickAway<HTMLDivElement>(() =>
    setShowSwipeActions(false)
  )

  const [contentRef, setEventHasFired] = useVisibilityTracking(
    () => {
      trackMessageCenterEvent((basePayload) => {
        tracker.trackEvent({
          ...basePayload,
          event: track.messageViewed,
          messageId: message.id.toString(),
          positionInList: position + 1,
        })
      })
    },
    rootRef,
    preview
  )

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setShowSwipeActions(true)
    },
    onSwipedRight: () => {
      setShowSwipeActions(false)
    },
  })

  useEffect(() => {
    if (!messageCenterOpen) {
      // @ts-ignore
      setEventHasFired(false)
    }
  }, [messageCenterOpen, setEventHasFired])

  const { createdAt, messageContents, readAt } = message
  const { caption, thumbnailUrl, title, webUrl } = messageContents

  const markAsRead = () => {
    if (preview) return
    fetch(apiV3MessagesPath(message.id), {
      method: 'PATCH',
    }).then(() => onMessageRead && onMessageRead(message))
  }

  const archive = () => {
    if (preview) return
    fetch(apiV3MessagesPath(message.id), {
      method: 'DELETE',
    }).then(() => {
      trackMessageCenterEvent((basePayload) => {
        tracker.trackEvent({
          ...basePayload,
          event: track.messageDeleted,
          messageId: message.id.toString(),
        })
      })

      if (onMessageDeleted) {
        onMessageDeleted(message)
      }
    })
  }

  // @ts-ignore
  const handleArchive = (e) => {
    e.preventDefault()
    e.stopPropagation()
    archive()
  }

  const handleMessageClicked = () => {
    if (preview) return
    trackMessageCenterEvent((basePayload) => {
      tracker.trackEvent({
        ...basePayload,
        event: track.messageClicked,
        messageId: message.id.toString(),
        positionInList: position + 1,
      })
    })
    markAsRead()
  }

  return (
    <div
      className={classNames(css.MessageCardContainer, {
        [css.MessageCardContainer__Unread]: !readAt,
        [css.MessageCardContainer__Archived]: !!message.archivedAt,
      })}
      {...handlers}
    >
      <Link
        className={classNames({
          [css.SwipedLeft]: showSwipeActions,
          [css.SwipedRight]: !showSwipeActions,
        })}
        url={preview ? '#' : webUrl}
        onClick={handleMessageClicked}
      >
        <div
          className={css.MessageCardContainer__ContentContainer}
          data-testid="message-card-content"
          ref={clickAwayRef}
        >
          <div className={css.MessageCardThumbnail}>
            <Image
              lazyLoad
              alt={title}
              data-testid="message-thumbnail"
              src={thumbnailUrl || DEFAULT_IMAGE}
            />
          </div>
          <div
            className={css.MessageCardContent}
            ref={contentRef as React.RefObject<HTMLDivElement>}
          >
            <h6>{title}</h6>
            <p>{caption}</p>
          </div>
          <div className={css.MessageCardContainer__Actions}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className={css.MessageCardDeleteIcon}
              data-testid="message-archive-icon"
              onClick={handleArchive}
            />
          </div>
          <div className={css.MessageCardTimestamp}>
            <ElapsedTime since={createdAt} />
            {!readAt && <div className={css.MessageCardUnreadBadge} />}
          </div>
        </div>
      </Link>
      <SwipeActionsMenu onMessageDeleted={archive} />
    </div>
  )
}

export default MessageCard
