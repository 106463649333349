import PropTypes from 'prop-types'
import classNames from 'classnames'

const RadioGroup = ({
  children,
  horizontal = null,
  title = null,
  className = '',
  ...rest
}) => {
  const renderHorizontal = () => (
    <ul className="radio-group-horizontal">
      {children.map((child, idx) => (
        <li key={idx} className="radio-group-horizontal-item">
          {child}
        </li>
      ))}
    </ul>
  )

  return (
    <div
      role="radiogroup"
      aria-label={rest['aria-label']}
      className={classNames('radio-group', className)}
    >
      {title && <p className="mbm h6 text-bold">{title}</p>}
      {horizontal ? renderHorizontal() : children}
    </div>
  )
}

RadioGroup.propTypes = {
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  title: PropTypes.string,
}

export default RadioGroup
