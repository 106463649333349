import Image from 'components/image'
import { InfoButton } from 'components/buttons'
import { reservationsPath } from 'lib/urls'
import { OfferCard } from './OfferCard'

export interface FavorOfferProps {
  isMobile: boolean
  inCart: boolean
  onClick: () => void
}

export const FavorOffer = ({ isMobile, inCart, onClick }: FavorOfferProps) => (
  <OfferCard isMobile={isMobile}>
    <div className="flex">
      <div>
        <Image
          src="//images.babylist.com/image/upload/w_88/v1679504724/icons/Babylist.png"
          width={44}
        />
      </div>
      <div className="flex-grow col plm">
        <div className="flex">
          <div className="text-bold h5 flex grow mtm mbn">
            Help &amp; Favors
          </div>
        </div>
      </div>
    </div>
    <div>
      {inCart ? (
        <InfoButton
          block
          empty
          pill
          className="mtl pam"
          href={reservationsPath}
        >
          Gifted By You
        </InfoButton>
      ) : (
        <InfoButton block pill className="mtl pam" onClick={onClick}>
          Give This Gift
        </InfoButton>
      )}
    </div>
  </OfferCard>
)
