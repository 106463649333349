import { Component } from 'react'
import { browserName } from 'lib/bowser'
import { instructionsForBrowser } from './browser-specific-instructions'
import css from './instructions-by-browser.scss'
import Image from 'components/image'
import Link from 'components/link'
import classNames from 'classnames'
import { contactUsPath } from '../../lib/urls'

export default class UsageInstructionsByBrowser extends Component {
  render() {
    const browserSpecific = instructionsForBrowser({ browser: browserName })

    return (
      <div className="ms-full-width">
        <div className="mhl">
          <div className="mbm h5 text-muted text-bold">
            1. On any shopping website, find an item you want to add to your
            registry.
          </div>

          <div className="mbm h5 text-muted text-bold">
            2. Click the Add to Babylist button in your{' '}
            {browserSpecific.bookmarksBarTitle} bar.
          </div>

          <p className="mbm">
            In the window that appears, enter any missing information, such as
            as price, size and color. Then, click the red Add to Babylist
            button.
          </p>

          <div className="phl">
            <Image
              alt={`Instructions for browser`}
              className={classNames(css.instructionsGif, 'img-responsive mbxl')}
              src={browserSpecific.usageGif}
            />
          </div>

          <div className="mbm h5 text-muted text-bold">
            3. Rejoice! The item has been added to your registry!
          </div>

          <p>
            <b>Need help?</b>
            <Link newWindow className="phs" url={contactUsPath}>
              Email us
            </Link>
          </p>
        </div>
      </div>
    )
  }
}
