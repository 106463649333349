import PropTypes from 'prop-types'
import { numberToCurrency } from '../../../lib/money'

const FundingInformation = ({
  goalAmount,
  amountContributed,
  displayAmountContributed,
}) => {
  let goalAmtDisplay
  if (goalAmount) {
    const goalRemaining = goalAmount - amountContributed
    goalRemaining < 0 ? (goalAmtDisplay = 0) : (goalAmtDisplay = goalRemaining)
    goalAmtDisplay = (
      <>
        <div className="h4 text-brand-info-light mtm mbn reg-item-qty">
          <strong>
            {numberToCurrency(goalAmtDisplay, { truncateZeroCents: true })}
          </strong>
        </div>
        <div className="text-uppercase mtn">
          <small>Remaining towards goal</small>
        </div>
      </>
    )
  }

  return (
    <>
      {goalAmtDisplay}
      {displayAmountContributed && (
        <div className="mtn">
          <small>
            {numberToCurrency(amountContributed, { truncateZeroCents: true })}{' '}
            Given So Far
          </small>
        </div>
      )}
      <span />
    </>
  )
}

FundingInformation.propTypes = {
  goalAmount: PropTypes.number,
  amountContributed: PropTypes.number,
}

export default FundingInformation
