import * as blUrls from 'lib/urls'
import * as BLConstants from 'shared/constants'

export const onContactUsClick = (e) => {
  if (typeof window.Forethought !== 'undefined') {
    e.preventDefault()
    e.stopPropagation()
    window.Forethought('widget', 'open')
  }
}

export const getNav = (loggedIn) => [
  {
    heading: 'Baby Registry',
    title: 'Best baby registry',
    href: blUrls.indexPath,
    links: [
      { name: 'Create a Babylist', href: blUrls.newRegistryPath },
      { name: 'Find a Babylist', href: blUrls.findPath },
      { name: 'Why Babylist', href: blUrls.whyBabyListPath },
      { name: 'Baby Registry Checklist', href: blUrls.checklistPath },
      {
        name: 'Install Babylist Button',
        href: blUrls.addItemsPath,
        render: loggedIn,
      },
      {
        name: 'Transfer Another Registry',
        href: blUrls.registryExternalPath,
        render: loggedIn,
      },
      {
        name: 'How to Create a Baby Registry',
        href: blUrls.howToCreateABabyRegistryPath,
      },
      {
        name: 'How to Share a Baby Registry',
        href: blUrls.howToShareABabyRegistryPath,
      },
      {
        name: `${BLConstants.CONFIG.store.registryDiscountPctDisplay} Registry Discount`,
        href: blUrls.registryDiscountPath,
      },
      {
        name: 'Welcome Baby Box',
        href: blUrls.helloBabyBoxPostPath,
      },
    ],
  },
  {
    heading: 'About Babylist',
    href: blUrls.aboutPath,
    links: [
      { name: 'About Us', href: blUrls.aboutPath, mobileOnly: true },
      { name: 'Babylist Registry', href: blUrls.indexPath },
      { name: 'Babylist Health', href: blUrls.healthPath },
      { name: 'Babylist Shop', href: blUrls.catalogPath },
      { name: 'Babylist Beverly Hills', href: blUrls.showroomPath },
      {
        name: 'Babylist Experiences',
        href: blUrls.locationsPath,
      },
      { name: 'Expectful', href: blUrls.expectfulUrl },
      { name: 'Hello Baby', href: blUrls.helloBabyPath },
      { name: 'Jobs', href: blUrls.aboutCareersPath },
      { name: 'Our Story', href: blUrls.ourStoryPath },
      { name: 'Press', href: blUrls.aboutPressPath },
    ],
  },
  {
    heading: 'Shop',
    href: blUrls.storePath,
    links: [
      {
        name: 'All Shop Categories',
        href: blUrls.storePath,
        mobileOnly: true,
      },
      {
        name: 'New Arrivals',
        href: 'https://www.babylist.com/store/new',
      },
      {
        name: 'Coming Soon',
        href: 'https://www.babylist.com/store/coming-soon',
      },
      {
        name: 'Newborn Must-Haves',
        href: 'https://www.babylist.com/store/newborn-must-haves',
      },
      {
        name: 'Top Registered',
        href: 'https://www.babylist.com/store/top-registered',
      },
      {
        name: 'FSA & HSA Eligible',
        href: 'https://www.babylist.com/store/fsa-hsa-eligible',
      },
      {
        name: 'Bandana Bibs',
        href: 'https://www.babylist.com/store/bandana-bibs',
      },
      {
        name: 'Bassinet Bedding',
        href: 'https://www.babylist.com/store/bassinet-bedding',
      },
      {
        name: 'Bassinets & Bedside Sleepers',
        href: 'https://www.babylist.com/store/bassinets-and-bedside-sleepers',
      },
      {
        name: 'Bibs',
        href: 'https://www.babylist.com/store/bibs',
      },
      {
        name: 'Bottle Sterilizers & Warmers',
        href: 'https://www.babylist.com/store/bottle-sterilizers-and-warmers',
      },
      {
        name: 'Breast Pumps',
        href: 'https://www.babylist.com/store/breast-pumps',
      },
      {
        name: 'Burp Cloths',
        href: 'https://www.babylist.com/store/burp-cloths',
      },
      {
        name: 'Car Seat Accessories',
        href: 'https://www.babylist.com/store/car-seat-accessories',
      },
      {
        name: 'Car Seat Covers & Shades',
        href: 'https://www.babylist.com/store/car-seat-covers-and-shades',
      },
      {
        name: 'Changing Pads',
        href: 'https://www.babylist.com/store/changing-pads',
      },
      {
        name: 'Cribs',
        href: 'https://www.babylist.com/store/cribs',
      },
      {
        name: 'Diaper Bags & Backpacks',
        href: 'https://www.babylist.com/store/diaper-bags-and-backpacks',
      },
      {
        name: 'Diaper Caddies',
        href: 'https://www.babylist.com/store/diaper-caddies',
      },
      {
        name: 'Disposable Diapers',
        href: 'https://www.babylist.com/store/disposable-diapers',
      },
      {
        name: 'Dressers & Changing Tables',
        href: 'https://www.babylist.com/store/dressers-and-changers',
      },
      {
        name: 'Headbands & Bows',
        href: 'https://www.babylist.com/store/headbands-bows',
      },
      {
        name: 'High Chairs & Boosters',
        href: 'https://www.babylist.com/store/high-chairs',
      },
      {
        name: 'Infant Seats & Loungers',
        href: 'https://www.babylist.com/store/infant-seats-and-loungers',
      },
      {
        name: 'Mattress Pads & Covers',
        href: 'https://www.babylist.com/store/mattress-pads-and-covers',
      },
      {
        name: 'Mittens',
        href: 'https://www.babylist.com/store/mittens',
      },
      {
        name: 'Newborn Must-Haves',
        href: 'https://www.babylist.com/store/newborn-must-haves',
      },
      {
        name: 'Nuna',
        href: 'https://www.babylist.com/store/nuna',
      },
      {
        name: 'Playards',
        href: 'https://www.babylist.com/store/playards',
      },
      {
        name: 'Playmats',
        href: 'https://www.babylist.com/store/play-mats',
      },
      {
        name: 'Stroller & Car Seat Toys',
        href: 'https://www.babylist.com/store/stroller-toys',
      },
      {
        name: 'Stroller Organizers',
        href: 'https://www.babylist.com/store/stroller-organizers',
      },
      {
        name: 'Strollers',
        href: 'https://www.babylist.com/store/strollers',
      },
      {
        name: 'Teethers & Rattles',
        href: 'https://www.babylist.com/store/teethers',
      },
      {
        name: 'Top Registered',
        href: 'https://www.babylist.com/store/top-registered',
      },
      {
        name: 'Wipes Dispensers',
        href: 'https://www.babylist.com/store/wipes-dispensers',
      },
    ],
  },
  {
    heading: 'Product Guides',
    href: blUrls.helloBabyPath,
    links: [
      {
        name: 'All Product Guides',
        href: blUrls.helloBabyPath,
        mobileOnly: true,
      },
      {
        name: 'Car Seat Guides',
        href: 'https://www.babylist.com/hello-baby/car-seat',
      },
      {
        name: 'Best Baby Activity Centers',
        href: 'https://www.babylist.com/hello-baby/best-activity-centers-for-babies',
      },
      {
        name: 'Best Baby Bottles',
        href: 'https://www.babylist.com/hello-baby/best-baby-bottles',
      },
      {
        name: 'Best Baby Carriers',
        href: 'https://www.babylist.com/hello-baby/best-baby-carriers',
      },
      {
        name: 'Best Baby Food',
        href: 'https://www.babylist.com/hello-baby/best-baby-food',
      },
      {
        name: 'Best Baby Formulas',
        href: 'https://www.babylist.com/hello-baby/best-baby-formula',
      },
      {
        name: 'Best Baby Lotions',
        href: 'https://www.babylist.com/hello-baby/best-baby-lotion',
      },
      {
        name: 'Best Baby Monitors',
        href: 'https://www.babylist.com/hello-baby/best-baby-monitor',
      },
      {
        name: 'Best Baby Wraps',
        href: 'https://www.babylist.com/hello-baby/best-baby-wraps',
      },
      {
        name: 'Best Bassinets',
        href: 'https://www.babylist.com/hello-baby/best-bassinets',
      },
      {
        name: 'Best Bottles for Breastfed Babies',
        href: 'https://www.babylist.com/hello-baby/best-bottles-for-breastfed-babies',
      },
      {
        name: 'Best Breast Pumps',
        href: 'https://www.babylist.com/hello-baby/best-breast-pump',
      },
      {
        name: 'Best Changing Tables and Pads',
        href: 'https://www.babylist.com/hello-baby/best-changing-tables',
      },
      {
        name: 'Best Diaper Bags',
        href: 'https://www.babylist.com/hello-baby/best-diaper-bags',
      },
      {
        name: 'Best Double Strollers',
        href: 'https://www.babylist.com/hello-baby/best-double-strollers',
      },
      {
        name: 'Best High Chairs',
        href: 'https://www.babylist.com/hello-baby/best-high-chairs',
      },
      {
        name: 'Best Nursing Pillows',
        href: 'https://www.babylist.com/hello-baby/best-nursing-pillows',
      },
      {
        name: 'Best Playards',
        href: 'https://www.babylist.com/hello-baby/best-playards',
      },
      {
        name: 'Best Portable High Chairs',
        href: 'https://www.babylist.com/hello-baby/best-portable-high-chair',
      },
      {
        name: 'Best Strollers',
        href: 'https://www.babylist.com/hello-baby/best-strollers',
      },
      {
        name: 'Best Swaddles',
        href: 'https://www.babylist.com/hello-baby/best-swaddles',
      },
      {
        name: 'Best Toddler Carriers',
        href: 'https://www.babylist.com/hello-baby/best-carriers-for-toddlers',
      },
      {
        name: 'Best Toys for Babies 0-6 Months',
        href: 'https://www.babylist.com/hello-baby/best-baby-toys-first-6-months',
      },
      {
        name: 'Best Toys for One-Year-Olds',
        href: 'https://www.babylist.com/hello-baby/best-toys-for-one-year-olds',
      },
      {
        name: 'Best Travel Cribs',
        href: 'https://www.babylist.com/hello-baby/best-travel-cribs',
      },
      {
        name: 'Best Travel Strollers',
        href: 'https://www.babylist.com/hello-baby/best-travel-strollers',
      },
      {
        name: 'Best Travel Systems',
        href: 'https://www.babylist.com/hello-baby/best-travel-systems',
      },
      {
        name: 'Best Umbrella Strollers',
        href: 'https://www.babylist.com/hello-baby/best-umbrella-strollers',
      },
      {
        name: 'Best Wearable Breast Pumps',
        href: 'https://www.babylist.com/hello-baby/best-wearable-breast-pumps',
      },
      {
        name: 'Pregnancy Calculator',
        href: 'https://www.babylist.com/due-date-calculator',
      },
    ],
  },
  {
    heading: 'Support',
    href: blUrls.faqPath,
    newWindow: true,
    links: [
      { name: 'Help Center', href: blUrls.faqPath, newWindow: true },
      {
        name: 'Contact Us',
        href: '#',
        onClick: onContactUsClick,
      },
      {
        name: 'Shipping',
        href: 'https://help.babylist.com/hc/en-us/sections/360009762154-Babylist-Shipping',
        newWindow: true,
      },
      { name: 'Returns', href: blUrls.returnsPath, newWindow: true },
      {
        name: 'Babylist Health Advisory Board',
        href: blUrls.healthAdvisoryBoardPath,
      },
      { name: 'Terms of Use', href: blUrls.termsOfUsePath },
      { name: 'Privacy Policy', href: blUrls.privacyPolicyPath },
      {
        name: 'Consumer Health Data Privacy Disclosure',
        href: blUrls.consumerHealthDataPrivacyPath,
      },
      { name: 'Product Registration', href: blUrls.productRegistrationPath },
      { name: 'CCPA Notice', href: blUrls.ccpaPath },
      { name: 'Sitemap', href: blUrls.seoSitemapPath },
      { name: 'Cookie Notice', href: blUrls.cookiesPath },
      { name: 'Editorial Guidelines', href: blUrls.editorialPolicyPath },
      {
        name: 'Product Reviews Policies',
        href: blUrls.productReviewPolicyPath,
      },
    ],
  },
]
