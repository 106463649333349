import React from 'react'
import { cashFundPrintablePath } from 'lib/urls'
import { TextLink } from '../../../../../../../baby-design'
import css from './PrintableLink.styles.scss'

const PrintableLink: React.FC = () => (
  <div className={css.PrintableLink}>
    <span>Include a certificate with your gift</span>
    <TextLink underline href={cashFundPrintablePath} target="_blank">
      View
    </TextLink>
  </div>
)

export default PrintableLink
