import React from 'react'

interface GooglePlayLogoProps {
  className?: string
}

const GooglePlayLogo: React.FC<GooglePlayLogoProps> = ({
  className,
}: GooglePlayLogoProps) => (
  <svg
    className={className}
    fill="none"
    height="21"
    viewBox="0 0 20 21"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.8 3.48992C2.82947 3.32328 2.8442 3.23996 2.95881 3.14275C3.03949 3.07431 3.21587 3.0308 3.31912 3.05385C3.4658 3.0866 3.53743 3.17913 3.6807 3.36418L8.67873 9.81997C8.79178 9.96599 8.8483 10.039 8.87003 10.1196C8.88921 10.1906 8.88921 10.2655 8.87003 10.3366C8.8483 10.4172 8.79178 10.4902 8.67873 10.6362L3.6807 17.092C3.53743 17.2771 3.4658 17.3696 3.31912 17.4023C3.21587 17.4254 3.03949 17.3819 2.95881 17.3134C2.8442 17.2162 2.82947 17.1329 2.8 16.9663C2.77824 16.8432 2.76272 16.717 2.75094 16.5887C2.70848 16.1259 2.70849 15.5331 2.7085 14.8013V5.65488C2.70849 4.92306 2.70848 4.33033 2.75094 3.8675C2.76272 3.73916 2.77824 3.61294 2.8 3.48992Z"
      fill="#252324"
    />
    <path
      d="M4.76253 1.67286C4.71396 1.67792 4.68968 1.68045 4.62794 1.70851C4.46204 1.78391 4.34688 2.05323 4.40699 2.22527C4.42936 2.28929 4.45852 2.32696 4.51686 2.40231L9.25796 8.52624C9.43747 8.7581 9.52722 8.87403 9.63646 8.91575C9.73219 8.95231 9.83803 8.95231 9.93376 8.91575C10.043 8.87403 10.1328 8.7581 10.3123 8.52624L12.0622 6.26593C12.2233 6.05776 12.3039 5.95368 12.3199 5.84592C12.334 5.75132 12.315 5.65476 12.2663 5.57247C12.2108 5.47873 12.0969 5.41278 11.869 5.28087L7.62195 2.82202C6.98861 2.45534 6.47565 2.15836 6.05383 1.96321C5.62893 1.76664 5.20341 1.62689 4.76253 1.67286Z"
      fill="#252324"
    />
    <path
      d="M14.125 6.58692C13.9462 6.48345 13.8569 6.43171 13.7661 6.42317C13.686 6.41565 13.6055 6.43143 13.5343 6.46861C13.4534 6.51081 13.3902 6.59246 13.2638 6.75576L10.8915 9.81997C10.7784 9.96599 10.7219 10.039 10.7002 10.1196C10.681 10.1906 10.681 10.2655 10.7002 10.3366C10.7219 10.4172 10.7784 10.4902 10.8915 10.6362L13.2638 13.7004C13.3902 13.8637 13.4534 13.9454 13.5343 13.9876C13.6055 14.0247 13.686 14.0405 13.7661 14.033C13.8569 14.0245 13.9462 13.9727 14.125 13.8693L15.5212 13.0609C16.1522 12.6956 16.6634 12.3996 17.0415 12.1318C17.4225 11.862 17.7547 11.5633 17.9344 11.1592C18.198 10.5664 18.198 9.88974 17.9344 9.29699C17.7547 8.89284 17.4225 8.59422 17.0415 8.32436C16.6634 8.05653 16.1522 7.76058 15.5212 7.39525L14.125 6.58692Z"
      fill="#252324"
    />
    <path
      d="M11.869 15.1753C12.0969 15.0434 12.2108 14.9775 12.2663 14.8837C12.315 14.8014 12.334 14.7049 12.3199 14.6103C12.3039 14.5025 12.2233 14.3984 12.0622 14.1902L10.3123 11.9299C10.1328 11.6981 10.043 11.5821 9.93376 11.5404C9.83803 11.5039 9.73219 11.5039 9.63646 11.5404C9.52722 11.5821 9.43747 11.6981 9.25796 11.9299L4.51686 18.0539C4.45852 18.1292 4.42935 18.1669 4.40699 18.2309C4.34688 18.403 4.46204 18.6723 4.62794 18.7477C4.68968 18.7757 4.71396 18.7783 4.76253 18.7833C5.20341 18.8293 5.62894 18.6895 6.05383 18.493C6.47567 18.2978 6.98864 18.0008 7.62201 17.6341L11.869 15.1753Z"
      fill="#252324"
    />
  </svg>
)

export default GooglePlayLogo
