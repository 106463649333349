import { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-bootstrap'
import { min as getMin, max as getMax } from 'lodash'
import { Range, getTrackBackground } from 'react-range'
import css from './range-slider.scss'

export const RangeSlider = ({
  step,
  minValue,
  maxValue,
  onChange,
  minOption,
  maxOption,
  labelPrefix,
}) => {
  // set initial values making sure minValue is greater than min and maxValue is less then max
  // this will also set the min or max as the initial values if minValue or maxValue are undefined
  const initMin = getMin([minOption, minValue])
  const initMax = getMax([maxOption, maxValue])
  const [values, setValues] = useState([initMin, initMax])

  const handleChange = (newValues) => {
    setValues(newValues)
    onChange(newValues)
  }

  return (
    <div className={css.rangeSlider}>
      <Range
        step={step}
        min={minOption}
        max={maxOption}
        values={values}
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            className={css.track}
            style={{
              background: getTrackBackground({
                values,
                colors: ['#f9f9f9', '#d6c7d3', '#f9f9f9'],
                min: minOption,
                max: maxOption,
              }),
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props, value, isDragged }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div {...props} key={value}>
            <Tooltip
              placement="top"
              id="price-range-thumb"
              className={`${css.tooltip} in`}
              style={{ marginTop: '-40px', marginLeft: '-5px' }}
            >{`${labelPrefix}${value}`}</Tooltip>
            <div
              className={css.thumb}
              style={{
                boxShadow: `0px 2px ${isDragged ? '8px' : '4px'} #ccc`,
              }}
            />
          </div>
        )}
      />
      <div className="mtl" style={{ width: '100%' }}>
        <span>{`${labelPrefix}${minOption}`}</span>
        <span style={{ float: 'right' }}>{`${labelPrefix}${maxOption}`}</span>
      </div>
    </div>
  )
}

RangeSlider.propTypes = {
  step: PropTypes.number,
  minOption: PropTypes.number, // the min allowed option
  maxOption: PropTypes.number, // the max allowed option
  minValue: PropTypes.number, // the min value
  maxValue: PropTypes.number, // the max value
  onChange: PropTypes.func,
  labelPrefix: PropTypes.string,
}

RangeSlider.defaultProps = {
  step: 1,
  minOption: 0,
  maxOption: 100,
  // allow minValue & maxValue to stay undefined so they fail the getMin & getMax checks when setting initValues
  minValue: undefined,
  maxValue: undefined,
  labelPrefix: '',
  onChange: () => {},
}
