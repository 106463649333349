import React from 'react'

interface MinusSignProps {
  className?: string
}

const MinusSign: React.FC<MinusSignProps> = ({ className }: MinusSignProps) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M3.5415 10C3.5415 9.65482 3.82133 9.375 4.1665 9.375H15.8332C16.1784 9.375 16.4582 9.65482 16.4582 10C16.4582 10.3452 16.1784 10.625 15.8332 10.625H4.1665C3.82133 10.625 3.5415 10.3452 3.5415 10Z"
      fill="#252324"
      fillRule="evenodd"
    />
  </svg>
)

export default MinusSign
