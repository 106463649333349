import css from './SearchBarInput.scss'
import SearchMagnifyingGlass from 'shared/svg/search-magnifying-glass.svg'
import classNames from 'classnames'
import { getInputPlaceholder } from '../SearchBar.utils'

interface SearchBarToggleButtonProps {
  className?: string
  modalOpen: boolean
  onClick: () => void
  searchTerm?: string
  isLoggedIn: boolean
}

const SearchBarToggleButton = ({
  className,
  modalOpen,
  onClick,
  searchTerm,
  isLoggedIn,
}: SearchBarToggleButtonProps) => {
  const inputPlaceholder = getInputPlaceholder(isLoggedIn)

  return (
    <button
      className={classNames(
        css.SearchBarInput,
        css.SearchBarInputButton,
        className
      )}
      aria-expanded={modalOpen}
      onClick={onClick}
    >
      <SearchMagnifyingGlass className={css.SearchBarInput__searchIcon} />
      <div
        className={classNames({
          [css.SearchBarInput__placeholder]: true,
          [css.SearchBarInput__searchTermPresent]: !!searchTerm,
        })}
      >
        {searchTerm || inputPlaceholder}
      </div>
    </button>
  )
}

export default SearchBarToggleButton
