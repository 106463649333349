import React from 'react'

interface PlayProps {
  className?: string
  fill?: string
}

const Play: React.FC<PlayProps> = ({ className, fill = 'black' }) => (
  <svg
    className={className}
    fill="none"
    height="14"
    viewBox="0 0 13 14"
    width="13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Play Icon</title>
    <path
      d="M10.3034 4.76617L4.4847 1.21033C3.97269 0.897426 3.55868 0.644414 3.21813 0.476932C2.8759 0.308626 2.53196 0.186463 2.17357 0.217813C1.64961 0.263647 1.17057 0.532309 0.858244 0.955494C0.64461 1.24495 0.5695 1.60214 0.534659 1.98192C0.499988 2.35984 0.499993 2.84503 0.5 3.44509V10.5551C0.499993 11.1552 0.499988 11.6404 0.534659 12.0183C0.5695 12.3981 0.64461 12.7552 0.858244 13.0447C1.17057 13.4679 1.64961 13.7365 2.17357 13.7824C2.53196 13.8137 2.8759 13.6916 3.21813 13.5233C3.55867 13.3558 3.97266 13.1028 4.48464 12.7899L10.3033 9.23404C10.7776 8.94419 11.1631 8.70862 11.4488 8.496C11.7379 8.2808 11.9885 8.04443 12.1252 7.72903C12.3267 7.26397 12.3267 6.73623 12.1252 6.27116C11.9885 5.95577 11.7379 5.7194 11.4488 5.5042C11.1631 5.29158 10.7776 5.05601 10.3034 4.76617Z"
      fill={fill}
    />
  </svg>
)

export default Play
