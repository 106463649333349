import { Component } from 'react'
import PropTypes from 'prop-types'
import ImageUploadPreviewer from 'shared/image-upload-previewer/image-upload-previewer'
import { CurrencyInput, Input } from 'components/forms'
import Quantity from 'components/quantity'
import FavoriteToggle from 'shared/reg-item-form/components/favorite-toggle'
import GroupGiftToggle from 'shared/reg-item-form/components/group-gift-toggle'
import { REG_ITEM_PLACEHOLDER_TEXT } from 'registry/constants'
import CategorySelector from './CategorySelector'
import css from './reg-item-form.scss'
import SecondaryLinks from './secondary-links'

export default class RegItemFields extends Component {
  constructor(props) {
    super(props)
  }

  handleImageChange = ({ imgUrl }) => {
    const { onRegItemChange } = this.props
    onRegItemChange({ imgUrl })
  }

  handleFavoriteChange = (isOn) => {
    const { onRegItemChange } = this.props
    onRegItemChange({ isFavorite: isOn })
  }

  handleGroupGiftChange = (isOn) => {
    const { openEnableGroupGiftModal, onRegItemChange, regItem } = this.props

    if (regItem.isGroupGift) {
      onRegItemChange({ isGroupGift: isOn })
    } else {
      openEnableGroupGiftModal()
    }
  }

  handleGroupGiftDisabled = () => {
    const { openEnableGroupGiftModal, regItem } = this.props
    const { groupGiftingDisabledMessage } = regItem
    // This modal handles the concern for displaying a message
    // when regItem canEnableGroupGift = false
    if (groupGiftingDisabledMessage) {
      openEnableGroupGiftModal()
    }
  }

  handleQuantityChange = (value) => {
    const { onRegItemChange } = this.props
    onRegItemChange({ quantity: value })
  }

  handleChange = (e) => {
    const { onRegItemChange } = this.props
    onRegItemChange({ [e.target.name]: e.target.value })
  }

  render() {
    const { categories, regItem, onRegItemChange, onNewCategoryChange } =
      this.props
    const regItemFields = regItem
    const {
      addedViaBabylist,
      canEnableGroupGift,
      description,
      imgUrl,
      isFavorite,
      isGroupGift,
      secondUrl,
      secondPrice,
      showGroupGiftToggle,
      price,
      quantity,
      title,
      url,
      secondaryLinks,
    } = regItemFields

    return (
      <>
        <div className={css.item}>
          <div className={css.imageContainer}>
            <ImageUploadPreviewer
              imgUrl={imgUrl}
              onChange={this.handleImageChange}
            />
          </div>

          <div className={css.itemFields}>
            <Input
              disabled={addedViaBabylist}
              error={title ? null : 'Title cannot be blank'}
              label="Title"
              name="title"
              value={title}
              onChange={this.handleChange}
            />

            <div className="inline-form mbl">
              <CurrencyInput
                groupClassName="flex-grow"
                label="Price"
                name="price"
                value={price || ''}
                onChange={this.handleChange}
              />
              <Quantity
                unlimited
                className="flex-grow"
                value={quantity}
                onChange={this.handleQuantityChange}
              />
            </div>

            <div className="flex inline-form">
              <CategorySelector
                categories={categories}
                groupClassName="flex-grow"
                name="categoryId"
                value={regItemFields.categoryId}
                onCategoryAdd={onNewCategoryChange}
                onCategoryChange={onRegItemChange}
              />
              <div className="flex flex-grow form-group">
                <FavoriteToggle
                  defaultOn={isFavorite}
                  onClick={this.handleFavoriteChange}
                />
                {showGroupGiftToggle && (
                  <div className="mll">
                    <GroupGiftToggle
                      defaultOn={isGroupGift}
                      disabled={!canEnableGroupGift}
                      onClick={this.handleGroupGiftChange}
                      onDisabledClick={this.handleGroupGiftDisabled}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Input
          error={url ? null : 'Url cannot be blank'}
          groupClassName="mbs"
          label="Url"
          name="url"
          value={url}
          onChange={this.handleChange}
        />

        <SecondaryLinks
          secondaryLinks={secondaryLinks}
          onSecondaryLinksChange={(links) =>
            onRegItemChange({ secondaryLinks: links })
          }
        />

        <Input
          className={css.notes}
          label="Notes for friends and family"
          name="description"
          placeholder={REG_ITEM_PLACEHOLDER_TEXT}
          type="textarea"
          value={description || ''}
          onChange={this.handleChange}
        />
      </>
    )
  }
}

RegItemFields.propTypes = {
  categories: PropTypes.array,
  regItem: PropTypes.object,
  onRegItemChange: PropTypes.func.isRequired,
  onNewCategoryChange: PropTypes.func.isRequired,
  openEnableGroupGiftModal: PropTypes.func.isRequired,
}
