import React from 'react'
import { TwoColumnResponsiveLayout } from 'components/presentation'
import { InfoButton } from 'components/buttons'
import SecondaryActionLinks from 'registry/components/LegacyCrowdfundReserveForm/components/SecondaryActionLinks/SecondaryActionLinks'
import CashFundPrintableLink from 'registry/components/LegacyCrowdfundReserveForm/components/CashContributionConfirmation/components/CashFundPrintableLink/CashFundPrintableLink'
import ContributionMailingAddress from 'registry/components/LegacyCrowdfundReserveForm/components/CashContributionConfirmation/components/ContributionMailingAddress/ContributionMailingAddress'
import { CashContributionConfirmationProps } from 'registry/components/LegacyCrowdfundReserveForm/components/CashContributionConfirmation/CashContributionConfirmation.types'
import css from './CashContributionConfirmation.styles.scss'

const CashContributionConfirmation: React.FC<
  CashContributionConfirmationProps
> = ({ onCancel, onHide, onReset, preferences }) => {
  return (
    <div>
      <TwoColumnResponsiveLayout className="ban">
        <CashFundPrintableLink />
        <div>
          <ContributionMailingAddress address={preferences?.address} />
          <InfoButton
            pill
            className={css.CashContributionConfirmation__SubmitButton}
            onClick={onHide}
          >
            I'm finished
          </InfoButton>
        </div>
      </TwoColumnResponsiveLayout>
      <SecondaryActionLinks onCancel={onCancel} onReset={onReset} />
    </div>
  )
}

export default CashContributionConfirmation
