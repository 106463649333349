import { SET_REGISTRY } from '../actions'

const registryReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_REGISTRY:
      return action.registry
    default:
      return state
  }
}

export default registryReducer
