import { TextLink } from 'baby-design'
import { Chevron } from 'baby-design/icons'
import { Registry } from 'src/types/registry'
import css from './CashFundPreferences.style.scss'

interface EditItemDetailsCashFundPreferencesProps {
  openCashFundPreferencesDrawer: () => void
  registry?: Registry
}

const EMPTY_PREFERENCES_COPY = 'Choose how you want to receive funds.'

export const EditItemDetailsCashFundPreferences = ({
  openCashFundPreferencesDrawer,
  registry,
}: EditItemDetailsCashFundPreferencesProps) => {
  const selectedProviders = registry?.cashFundPreferences?.paymentPreferences
    ?.filter((pref) => !!pref.selected)
    ?.map((pref) => pref.name)
  return (
    <div className={css.cash_fund_preferences}>
      <div className={css.cash_fund_preferences__label} id="markPurchasedLabel">
        <div className={css.cash_fund_preferences__heading}>
          {selectedProviders && selectedProviders.length > 0
            ? 'Payment preferences'
            : 'Select payment preferences'}
        </div>
        <div className={css.cash_fund_preferences__subheading}>
          {selectedProviders?.join(', ') || EMPTY_PREFERENCES_COPY}
        </div>
      </div>
      <TextLink
        icon={{
          side: 'right',
          icon: <Chevron className={css.cash_fund_preferences__chevron} />,
        }}
        size="md"
        variant="default"
        weight="regular"
        onClick={() => openCashFundPreferencesDrawer()}
      />
    </div>
  )
}
