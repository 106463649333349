import { useState } from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Big from 'big.js'

import {
  MultiStepWizard,
  TwoColumnResponsiveLayout,
} from 'components/presentation'
import { Checkbox, Input } from 'components/forms'
import { InfoButton, LinkButton } from 'components/buttons'
import { ProgressBar } from 'components/progress-bar'
import { DangerAlert } from 'components/alerts'

import {
  MARKETING_EMAIL_CHECKBOX_LABEL,
  NEW_RESERVATION_ID,
  RESERVE_ACTION_CHECKOUT,
  RESERVE_ACTION_SHOP,
} from 'cart/constants'
import { UPDATE_CART_SUMMARY } from 'shared/constants'

import styleMaps from 'shared/style-maps'
import { regItemProps } from 'registry/components/reg-item-card/reg-item-props'
import { between, email as isEmail } from 'lib/form-validation'
import { track, useTracking } from 'lib/analytics'
import { reservationPath } from 'lib/urls'
import { setGiftGiverCookie, useGiftGiverCookie } from 'lib/cookies'
import { isString } from 'lodash'
import { numberToCurrency } from 'lib/money'
import MailcheckedInput from 'components/mailchecked-input'
import GroupGiftPreviewCard from 'registry/components/cards/group-gift-product-preview-card'
import { parseErrors } from 'lib/api-utils'
import css from './group-gift-reserve-form.scss'

const GIFT_GIVER_INFO_STEP = 1
const CONTRIBUTION_SELECTION_STEP = 2

export const GroupGiftReserveForm = ({
  regItem,
  updateReservation = null,
  createReservation = null,
  onHide,
}) => {
  const { fundedAmount, goalAmount, registryId } = regItem
  const tracker = useTracking()

  const percentFunded = Math.min((fundedAmount / goalAmount) * 100, 100)
  const remainingFundingAmount = Math.max(
    Big(goalAmount) - Big(fundedAmount),
    1
  )
  const defaultGiftAmount = (
    remainingFundingAmount < 110 ? remainingFundingAmount : 100
  ).toFixed(2)
  const [activeStep, setActiveStep] = useState(GIFT_GIVER_INFO_STEP)
  const [submitting, setSubmitting] = useState(false)
  const [submitBtnClicked, setSubmitBtnClicked] = useState()
  const [currentReservation, setCurrentReservation] = useState()

  const { name, email, subscribeToMarketingEmail } = useGiftGiverCookie()

  const giftGiverInfoForm = useForm({
    defaultValues: {
      name,
      email,
      subscribeToMarketingEmail,
    },
  })
  const contributionForm = useForm({
    defaultValues: {
      giftAmount: defaultGiftAmount,
    },
  })

  // Gift giver info form submit handler
  const setGiftGiverInfoCookies = ({
    name,
    email,
    subscribeToMarketingEmail,
  }) => {
    setGiftGiverCookie({
      name,
      email,
      subscribeToMarketingEmail,
    })
    setActiveStep(CONTRIBUTION_SELECTION_STEP)
  }

  const updateEmail = (eventOrValue) => {
    const value = isString(eventOrValue)
      ? eventOrValue
      : eventOrValue.target.value
    giftGiverInfoForm.setValue('email', value)
  }

  const handleReservation = (giftAmount) => {
    const data = {
      id: currentReservation?.id || NEW_RESERVATION_ID,
      regItemId: regItem.id,
      regItem,
      registryId: regItem.registryId,
      name,
      email,
      subscribeToMarketingEmail,
      giftAmount: parseFloat(giftAmount),
      quantity: 1,
      store: 'babylist.com',
      isGroupGiftContribution: true,
    }

    return currentReservation
      ? updateReservation({ ...currentReservation, ...data })
      : createReservation(data).then((resp) => {
          setCurrentReservation(resp)
          const { email, userId } = resp
          tracker.trackEvent({
            event: track.emailCaptured,
            eventLocation:
              track.EventLocation.GIFT_GIVER_MINUS_EMAIL_COLLECTION,
            eventUrl: window.location.href,
            email,
            userId,
          })
          return resp
        })
  }

  // Contribution info form submit handlers
  const handleReserveAndCheckout = ({ giftAmount }) => {
    setSubmitting(true)
    setSubmitBtnClicked(RESERVE_ACTION_CHECKOUT)

    handleReservation(giftAmount)
      .then((res) => {
        setSubmitting(false)
        setSubmitBtnClicked(null)
        window.location.href = reservationPath(res.token)
      })
      .catch((res) => {
        setSubmitting(false)
        const msg = res._error || parseErrors(res)._error
        contributionForm.setError('reserve', null, msg)
      })
  }

  // Contribution info form submit handlers
  const handleReserveAndShop = ({ giftAmount }) => {
    setSubmitting(true)
    setSubmitBtnClicked(RESERVE_ACTION_SHOP)

    handleReservation(giftAmount)
      .then(() => {
        setSubmitting(false)
        setSubmitBtnClicked(null)
        onHide()
        PubSub.publish(UPDATE_CART_SUMMARY, {
          showCartDropdown: true,
          animateBadge: true,
        })
      })
      .catch((res) => {
        setSubmitting(false)
        setSubmitBtnClicked(null)
        const msg = res._error || parseErrors(res)._error
        contributionForm.setError('reserve', null, msg)
      })
  }

  return (
    <TwoColumnResponsiveLayout withoutGridBorder>
      <GroupGiftPreviewCard {...regItem} />
      <MultiStepWizard withoutGridBorder activeStep={activeStep}>
        <form
          onSubmit={giftGiverInfoForm.handleSubmit(setGiftGiverInfoCookies)}
        >
          <p className="text-bold h6 mvl">
            Let them know you're funding this gift:
          </p>
          <Input
            className={classNames(css.groupGiftInput, 'mbm')}
            label="Your Name"
            name="name"
            inputRef={giftGiverInfoForm.register({
              required: 'Please add your name',
            })}
            error={giftGiverInfoForm.errors?.name?.message}
          />
          <MailcheckedInput
            className={classNames(css.groupGiftInput, 'mbn')}
            error={giftGiverInfoForm.errors?.email?.message}
            label="Your Email"
            name="email"
            inputRef={giftGiverInfoForm.register({
              required: 'Please add your email',
              validate: isEmail,
            })}
            onChange={updateEmail}
            mailcheckOnBlur={false}
            mailcheckOnChange
            mailcheckDelay={300}
          />

          <Checkbox
            name="subscribeToMarketingEmail"
            label={MARKETING_EMAIL_CHECKBOX_LABEL}
            defaultChecked={subscribeToMarketingEmail}
            type="checkbox"
            value="true"
            inputRef={giftGiverInfoForm.register()}
          />
          <InfoButton className="mtl" pill block type="submit">
            Continue
          </InfoButton>
        </form>
        <form className="h6">
          {percentFunded === 0 && <em>You're the first to fund this gift!</em>}
          <p className="text-bold">
            {numberToCurrency(remainingFundingAmount)} Funding to Go
          </p>
          <ProgressBar
            color={styleMaps.COLORS.salmon}
            backgroundColor="#fff"
            height={8}
            percent={percentFunded}
            radius={24}
          />
          <label htmlFor="giftAmount" className="h6 mtxl mbn text-bold">
            Your Funding Amount
          </label>
          <Input
            name="giftAmount"
            className={classNames(css.groupGiftInput, 'mbn')}
            groupClassName={css.fundingInputWrapper}
            inputRef={contributionForm.register({
              required: 'Please enter a gift amount',
              validate: between(1, 10000, '$'),
              pattern: {
                value: /^\d*(\.\d{0,2})?$/,
                message: 'Please enter a valid goal amount',
              },
            })}
            error={contributionForm.errors?.giftAmount?.message}
            addonBefore={<span className="h3 pan man text-brand-info">$</span>}
            addonAfter={
              <LinkButton
                className="text-bold text-brand-info"
                onClick={() =>
                  contributionForm.setValue(
                    'giftAmount',
                    remainingFundingAmount.toFixed(2)
                  )
                }
              >
                Pay the Rest
              </LinkButton>
            }
            addonStyle={{
              background: 'transparent',
              border: 0,
              borderBottom: `2px solid ${styleMaps.COLORS.eggplant}`,
              padding: 0,
            }}
          />
          <InfoButton
            className="mbm"
            pill
            block
            submitting={
              submitting && submitBtnClicked === RESERVE_ACTION_CHECKOUT
            }
            disabled={submitting}
            onClick={contributionForm.handleSubmit(handleReserveAndCheckout)}
          >
            Add to Cart & Checkout
          </InfoButton>
          <InfoButton
            className="mbm"
            pill
            empty
            block
            submitting={submitting && submitBtnClicked === RESERVE_ACTION_SHOP}
            disabled={submitting}
            onClick={contributionForm.handleSubmit(handleReserveAndShop)}
          >
            Add to Cart &amp; Keep Shopping
          </InfoButton>
          {contributionForm.errors.reserve?.message && (
            <DangerAlert emptyBackground className={css.errorMessage}>
              {contributionForm.errors.reserve.message}
            </DangerAlert>
          )}
        </form>
      </MultiStepWizard>
    </TwoColumnResponsiveLayout>
  )
}

GroupGiftReserveForm.propTypes = {
  createReservation: PropTypes.func.isRequired,
  regItem: PropTypes.shape(regItemProps).isRequired,
  onHide: PropTypes.func.isRequired,
  updateReservation: PropTypes.func.isRequired,
}
