import PropTypes from 'prop-types'
import Link from 'components/link'
import { openRegItemEditModal } from '../../modals/actions'
import { connect } from 'react-redux'

const EditRegItemLink = ({ regItemId, children, openRegItemEditModal }) => (
  <Link className="link-info" onClick={() => openRegItemEditModal(regItemId)}>
    {children}
  </Link>
)

EditRegItemLink.propTypes = {
  regItemId: PropTypes.number.isRequired,
  openRegItemEditModal: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}

export default connect(undefined, { openRegItemEditModal })(EditRegItemLink)
