import PropTypes from 'prop-types'
import { BestOfferLink } from '../best-offer-link/best-offer-link'
import RegItemCardLayout from '../reg-item-card-layout'
import AddOrBuy from '../../add-or-buy/add-or-buy'
import Card from 'components/card'

export const ReservableGiftCardRegItemCard = (
  { regItem, reservationByCurrentVisitor },
  { registry }
) => {
  const { id, offers } = regItem

  const regItemLink = (child) => (
    <BestOfferLink offers={offers}>{child}</BestOfferLink>
  )

  const actions = () => (
    <AddOrBuy
      reservation={reservationByCurrentVisitor}
      regItem={regItem}
      offers={offers}
      registry={registry}
    />
  )

  return (
    <>
      <Card className="visible-xs">
        <RegItemCardLayout
          regItem={regItem}
          regItemLinkDecorator={regItemLink}
          actions={actions()}
        />
      </Card>
      <div className="hidden-xs">
        <RegItemCardLayout
          regItem={regItem}
          regItemLinkDecorator={regItemLink}
          actions={actions()}
        />
      </div>
    </>
  )
}

ReservableGiftCardRegItemCard.contextTypes = {
  registry: PropTypes.object,
}
