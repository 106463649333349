import React, { createContext } from 'react'

interface InProgressReservationProps {
  reservation?: any
  setInProgressReservation: (reservation: any) => void
}

const InProgressReservationContext: React.Context<InProgressReservationProps> =
  createContext({
    reservation: undefined,
    setInProgressReservation: () => {},
  } as InProgressReservationProps)

export default InProgressReservationContext
