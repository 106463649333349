/* eslint-disable import/no-extraneous-dependencies */
import { Button, Image, PriceTag, StoreIcon } from 'baby-design'
import { LinkOut } from 'baby-design/icons'

import { getSearchPreviewIds } from 'components/global-nav/components/SearchBar/SearchBar.utils'
import { withContextualizedTracking, track } from 'lib/analytics'
import { minimumFormattedPriceFromOffers } from 'hello_baby/lib/helpers'
import { PriceDetails } from 'src/types/priceDetails'
import { getPriceTagPropsFromPriceDetails } from 'src/utils/productHelpers'

import Link from 'components/link'

import styles from './ProductCard.styles.scss'

export interface ProductOffers {
  name: string
  normalUrl: string
  price: string | number
  priceDetails: PriceDetails
  storeDisplayName: string
  storeIconName: string
  url: string
  viglinkEnabled: boolean
}

interface ProductImage {
  url: string
  description: string
}

export interface ProductFields {
  brand: string
  genericProductName: string
  image: ProductImage
  name: string
  normalUrl: string
  teaser: string
  url: string
  offers: ProductOffers[]
}

export interface BLProductFields extends ProductFields {
  id: string
  shortName: string
}

export interface ProductLink {
  fields: ProductFields | BLProductFields
  sys: {
    id: string
    type: string
    contentType: {
      sys: {
        id: string
        name: string
      }
    }
  }
}

interface ProductCardProps {
  product: ProductLink
}

const handleProductClick = (product: ProductFields | BLProductFields) => {
  let price =
    product.offers && product.offers.length > 0 ? product.offers[0]?.price : 0
  let parsedPrice = 0
  if (price && typeof price === 'string') {
    price = price.replace('$', '')
    parsedPrice = parseFloat(price)
  }
  const searchPreviewIds = getSearchPreviewIds()
  const productId = (product as BLProductFields).id ?? undefined
  track.productClicked({
    productIds: productId ? [productId] : undefined,
    productPrice: parsedPrice,
    productType: track.ProductType.PRODUCT,
    eventLocation: track.EventLocation.GUIDE,
    searchPreviewIds,
  })
}

export const handleAddToRegistry = (
  product: ProductFields | BLProductFields
) => {
  const productId = (product as BLProductFields).id ?? undefined

  bl.addToRegistry({
    images: product.image.url,
    price:
      product.offers && product.offers.length > 0
        ? product.offers[0]?.price
        : '',
    product_id: productId,
    title: product.name,
    url: product.normalUrl,
    source: 'hello_baby',
  })
}

const processStoreDisplayName = (storeDisplayName: string): string => {
  switch (storeDisplayName) {
    case 'amazon.com':
      return 'Amazon'
    default:
      return storeDisplayName
  }
}

const relBuilder = (
  product: ProductFields | BLProductFields,
  offersIndex = 0
): string => {
  const { offers } = product
  if (!offers || offers.length === 0) {
    return ''
  }
  const offer = offers[offersIndex]
  const linkRel = []

  if (!offer.viglinkEnabled) {
    linkRel.push('norewrite')
  }

  if (offer.url && !offer.url.includes('babylist.com')) {
    linkRel.push('nofollow')
  }

  return linkRel.join(' ')
}

const ActionButtons = (productFields: ProductFields | BLProductFields) => {
  const { url: purchaseUrl } = productFields
  return (
    <>
      <div className={styles.ProductCard__button_wrapper_mobile}>
        <Button
          block
          className={styles.ProductCard__button_add}
          size="md"
          target="_blank"
          variant="primary"
          onClick={() => handleAddToRegistry(productFields)}
        >
          Add To Babylist
        </Button>
        <Button
          block
          className={styles.ProductCard__button_buy}
          href={purchaseUrl}
          icon={{ icon: <LinkOut />, side: 'right', size: 'sm' }}
          rel={relBuilder(productFields)}
          size="md"
          target="_blank"
          variant="tertiary"
        >
          Buy
        </Button>
      </div>
      <div className={styles.ProductCard__button_wrapper_desktop}>
        <Button
          className={styles.ProductCard__button_add}
          size="lg"
          target="_blank"
          variant="primary"
          onClick={() => handleAddToRegistry(productFields)}
        >
          Add To Babylist
        </Button>
        <Button
          className={styles.ProductCard__button_buy}
          href={purchaseUrl}
          icon={{ icon: <LinkOut />, side: 'right', size: 'sm' }}
          rel={relBuilder(productFields)}
          size="lg"
          target="_blank"
          variant="tertiary"
        >
          Buy
        </Button>
      </div>
    </>
  )
}

const ProductCard: React.FC<ProductCardProps> = ({
  product,
}: ProductCardProps) => {
  const productFields = product.fields
  const lowestPrice = minimumFormattedPriceFromOffers(productFields.offers)
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      className={styles.ProductCard}
      onClick={() => handleProductClick(product.fields)}
    >
      <div className={styles.ProductCard__image_wrapper}>
        <Link
          newWindow
          rel={relBuilder(productFields)}
          url={product.fields.normalUrl}
        >
          <Image
            lazyLoad
            alt={product.fields.image.description}
            className={styles.ProductCard__image}
            src={product.fields.image.url}
          />
        </Link>
      </div>
      <div className={styles.ProductCard__content}>
        <h3 className={styles.ProductCard__heading}>{productFields.name}</h3>
        {!!lowestPrice && (
          <span className={styles.ProductCard__price}>From {lowestPrice}</span>
        )}
        <ActionButtons {...productFields} />
        <div className={styles.ProductCard__offers_wrapper}>
          {productFields.offers &&
            productFields.offers.length > 0 &&
            productFields.offers.map((offer, index) => (
              <Link
                className={styles.ProductCard__offer}
                key={offer.url}
                rel={relBuilder(productFields, index)}
                url={offer?.url}
              >
                <div className={styles.ProductCard__offer_wraper}>
                  <div className={styles.ProductCard__store_wrapper}>
                    {' '}
                    <StoreIcon
                      className={styles.ProductCard__store_icon}
                      store={{ name: offer.storeIconName }}
                    />
                    <span className={styles.ProductCard__store_name}>
                      {processStoreDisplayName(offer.storeDisplayName)}
                    </span>
                  </div>

                  <span className={styles.ProductCard__offer_price}>
                    <PriceTag
                      size="xs"
                      variant="compact"
                      {...getPriceTagPropsFromPriceDetails(offer.priceDetails)}
                    />
                  </span>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  )
}

export default withContextualizedTracking({
  eventLocation: track.EventLocation.GUIDE,
})(ProductCard)
