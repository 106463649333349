const CategoryBathing = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 258.35 258.35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M206.83,172.26a47.94,47.94,0,0,1-.05,11.28,15,15,0,0,1-.43,2.27,22.58,22.58,0,0,1-22.89,16.62L173.11,202a17.55,17.55,0,0,1-15.54-10.87,21.49,21.49,0,0,1-1.85-7.59l.05,0a14.41,14.41,0,0,1,.69-4h24.4a6.64,6.64,0,0,0,6.21-4.27c5.44-.3,11.57-3,15.2-5.18A3,3,0,0,1,206.83,172.26Zm-17.5,20.64-20.72-9.28S168.87,199.6,189.33,192.9Z"
      fill="#d8d8d8"
    />
    <path
      d="M168.61,183.62l20.72,9.28C168.87,199.6,168.61,183.62,168.61,183.62Z"
      fill="#d8d8d8"
    />
    <path
      d="M157.85,167.88c-.09-.23-.19-.46-.27-.7s-.16-.44-.23-.67h0a13.12,13.12,0,0,1-.53-2.75c0-.36,0-.72,0-1.08a12.67,12.67,0,0,1,.3-2.78,10,10,0,0,1,.29-1.11c.12-.38.26-.76.41-1.13h0a13.08,13.08,0,0,1,12.09-8.07,12.43,12.43,0,0,1,2.68.28,12.94,12.94,0,0,1,5.07,2.26A13.1,13.1,0,0,1,183,162.68c0,.17,0,.35,0,.53a12.77,12.77,0,0,1-1.16,4.9,13.93,13.93,0,0,1-.67,1.27,8.9,8.9,0,0,1-.49.76,3,3,0,0,0,1.51,4.54,13.3,13.3,0,0,0,4.94.56,6.64,6.64,0,0,1-6.21,4.27h-24.4a14.12,14.12,0,0,1,.54-1.41,14.5,14.5,0,0,1,3-4.44v0a1.62,1.62,0,0,0,.06-2.24A13.24,13.24,0,0,1,157.85,167.88Z"
      fill="#d8d8d8"
    />
    <path
      d="M157.85,167.88l-12.61-3.35a1.42,1.42,0,0,1,0-2.5l12.48-4.39.06,0c-.15.37-.29.75-.41,1.13a10,10,0,0,0-.29,1.11,12.67,12.67,0,0,0-.3,2.78c0,.36,0,.72,0,1.08a13.12,13.12,0,0,0,.53,2.75h0c.07.23.15.45.23.67S157.76,167.65,157.85,167.88Z"
      fill="#d8d8d8"
    />
    <path
      d="M150.86,112.49h.19a7.37,7.37,0,0,0-4,1.33.75.75,0,0,1-.61.11.8.8,0,0,0,.23-.11A7.3,7.3,0,0,1,150.86,112.49Z"
      fill="#f2ece5"
    />
    <path
      d="M134.77,105.79H135a11.36,11.36,0,0,0-4.13.84.7.7,0,0,1-.47,0l.08,0A11.45,11.45,0,0,1,134.77,105.79Z"
      fill="#f2ece5"
    />
    <path
      d="M113.67,94.68h.17a16.59,16.59,0,0,0-13.94,7.9.75.75,0,0,1-.84.33.71.71,0,0,0,.45-.33A16.63,16.63,0,0,1,113.67,94.68Z"
      fill="#f2ece5"
    />
    <path
      d="M89.74,99.67h.19a13.58,13.58,0,0,0-13.24,11.52.77.77,0,0,1-1,.64.78.78,0,0,0,.56-.64A13.6,13.6,0,0,1,89.74,99.67Z"
      fill="#f2ece5"
    />
    <path
      d="M71.34,111h.14a9.59,9.59,0,0,0-9.29,8.62h-.38A9.58,9.58,0,0,1,71.34,111Z"
      fill="#f2ece5"
    />
    <polygon
      fill="#00a1c5"
      points="67.91 55.9 67.91 55.91 67.91 55.91 67.91 55.9"
    />
    <path
      d="M62.19,119.59A9.59,9.59,0,0,1,71.48,111h.25a9.47,9.47,0,0,1,3.88.82l.13,0a.77.77,0,0,0,1-.64A13.58,13.58,0,0,1,89.93,99.67h.19a13.48,13.48,0,0,1,8.64,3.1.69.69,0,0,0,.3.14.75.75,0,0,0,.84-.33,16.59,16.59,0,0,1,13.94-7.9h.21a16.62,16.62,0,0,1,15.82,11.49.74.74,0,0,0,.51.49.7.7,0,0,0,.47,0,11.36,11.36,0,0,1,4.13-.84h.18a11.32,11.32,0,0,1,10.7,7.63.8.8,0,0,0,.55.51.75.75,0,0,0,.61-.11,7.37,7.37,0,0,1,4-1.33h.19a7.36,7.36,0,0,1,7.36,7.1"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M156.46,179.51H94.52a6.64,6.64,0,0,1-5.14-2.43L55.16,135.34a6.57,6.57,0,0,1-1.28-2.45"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M198.46,132.89l-11.17,41.69a4.64,4.64,0,0,1-.22.66"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M53.88,132.89h-9a4.59,4.59,0,0,1-4.59-4.59v-4.13a4.59,4.59,0,0,1,4.59-4.58H207.47a4.58,4.58,0,0,1,4.58,4.58v4.13a4.58,4.58,0,0,1-4.58,4.59H53.88Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M154.75,93a6.49,6.49,0,1,1-7.52-5.26A6.49,6.49,0,0,1,154.75,93Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M147.07,67.28a5.48,5.48,0,1,1-6.35-4.44A5.48,5.48,0,0,1,147.07,67.28Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M130.75,75a9,9,0,1,1-10.39-7.27A9,9,0,0,1,130.75,75Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M187.07,175.24c5.44-.3,11.57-3,15.2-5.18a3,3,0,0,1,4.56,2.2,47.94,47.94,0,0,1-.05,11.28,15,15,0,0,1-.43,2.27,22.58,22.58,0,0,1-22.89,16.62L173.11,202a17.55,17.55,0,0,1-15.54-10.87,21.49,21.49,0,0,1-1.85-7.59l.05,0a14.41,14.41,0,0,1,.69-4,14.12,14.12,0,0,1,.54-1.41,14.5,14.5,0,0,1,3-4.44v0a1.62,1.62,0,0,0,.06-2.24,13.24,13.24,0,0,1-2.26-3.52c-.09-.23-.19-.46-.27-.7s-.16-.44-.23-.67h0a13.12,13.12,0,0,1-.53-2.75c0-.36,0-.72,0-1.08a12.67,12.67,0,0,1,.3-2.78,10,10,0,0,1,.29-1.11c.12-.38.26-.76.41-1.13h0a13.08,13.08,0,0,1,12.09-8.07,12.43,12.43,0,0,1,2.68.28,12.94,12.94,0,0,1,5.07,2.26A13.1,13.1,0,0,1,183,162.68c0,.17,0,.35,0,.53a12.77,12.77,0,0,1-1.16,4.9,13.93,13.93,0,0,1-.67,1.27,8.9,8.9,0,0,1-.49.76,3,3,0,0,0,1.51,4.54A13.3,13.3,0,0,0,187.07,175.24Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M157.9,167.89h-.05l-12.61-3.35a1.42,1.42,0,0,1,0-2.5l12.48-4.39"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
    <path
      d="M168.61,183.62s.26,16,20.72,9.28"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth="4px"
    />
  </svg>
)

export default CategoryBathing
