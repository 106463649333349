import Link from 'components/link'
import { helloBabyTopicPath } from 'lib/urls'
import css from './SubNavLinks.scss'

type Topic = {
  name: string
  slug: string
}

interface SubNavLinksProps {
  topics: Topic[]
}

export default function SubNavLinks({ topics }: SubNavLinksProps) {
  return (
    <ul className={css.subNavLinks}>
      {topics.map((topic) => (
        <li className={css.subNavItem} key={topic.slug}>
          <Link url={helloBabyTopicPath(topic.slug)}>{topic.name}</Link>
        </li>
      ))}
    </ul>
  )
}
