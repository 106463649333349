import { useState } from 'react'
import { connect } from 'react-redux'
import { DismissUI } from 'components/dismiss-ui'
import { Modal, ModalBody } from 'components/modal'
import FormButtonGroup from 'components/forms/form-button-group'
import { InfoButton } from 'components/buttons'
import { ShippingIcon } from 'components/icons'
import { successMessage, errorMessage } from 'lib/flash-message'
import { settingsShippingAddressPath } from 'lib/urls'
import { saveShippingAddress } from 'registry/actions'

interface UnhideAddressModalProps {
  onUnhideAddress: () => Promise<any>
  registryId: number
  shouldShowModal: boolean
}

interface StateValues {
  registry: {
    id: number
    createdAt: string
    isShippingAddressPrivate: boolean
    family: {
      arrivalDate: string
    }
    shippingAddress: any
  }
}

// This is a temporary modal created to address users who have set their shipping
// address to private without understanding the implications. We have since added
// a disclaimer to the address privacy setting so we want to show this modal only
// to affected users. This modal code can be removed after ~Dec. 15, 2023.
export const UnhideAddressModal = ({
  onUnhideAddress,
  registryId,
  shouldShowModal,
}: UnhideAddressModalProps) => {
  const [show, setShow] = useState(shouldShowModal)

  const handleUnhide = () => {
    onUnhideAddress()
      .then(() => {
        setShow(false)
        successMessage('Your address settings have been updated.')
      })
      .catch(() => {
        errorMessage('There was an error updating your address settings.')
      })
  }

  const handleViewAddress = () => {
    window.location.href = settingsShippingAddressPath
  }

  return (
    <DismissUI
      dismissDays={30}
      dismissId={`unhide-address-modal-${registryId}`}
    >
      {(isDismissed: boolean, onDismiss: () => void) =>
        !isDismissed && (
          <Modal
            icon={<ShippingIcon />}
            show={show}
            size="small"
            title="Heads Up! Your shipping address is currently hidden."
            onHide={() => {
              onDismiss()
              setShow(false)
            }}
          >
            <ModalBody>
              <p className="mbl">
                Gift givers &amp; visitors to your registry won't be able to
                ship to your address unless you personally provide it to them.
              </p>

              <FormButtonGroup
                secondaryButtonComponent={InfoButton}
                textPrimary="Unhide My Shipping Address"
                textSecondary="View My Shipping Address"
                onClickPrimary={handleUnhide}
                onClickSecondary={handleViewAddress}
              />
            </ModalBody>
          </Modal>
        )
      }
    </DismissUI>
  )
}

const mapStateToProps = (state: StateValues) => {
  const { registry } = state

  // Show this modal one only if:
  // Has shipping address
  // Registry address is private
  // Registry with a future arrival date
  // Registry created before June 15h, 2023
  const shouldShowModal =
    !!registry.shippingAddress &&
    new Date(registry.createdAt).getTime() <= new Date(2023, 5, 15).getTime() &&
    registry.isShippingAddressPrivate &&
    new Date(registry.family?.arrivalDate).getTime() > new Date().getTime()

  return { registryId: state.registry.id, shouldShowModal }
}

export default connect(mapStateToProps, {
  onUnhideAddress: () => saveShippingAddress({ privateAddress: false }),
})(UnhideAddressModal)
