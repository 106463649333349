import css from './SearchResultComponents.scss'
import { SectionHeading } from '../../types'
import { useTracking } from 'lib/analytics'
import { trackUnifiedSearchEvent } from '../../SearchBar.utils'
import { addToSearchHistory } from 'shared/utils/searchHistory/searchHistory'
import { getSearchHistoryTypeFromSectionHeading } from '../../helpers'

const SearchResultSectionHeading = ({
  result,
  searchQuery,
}: {
  result: SectionHeading
  searchQuery: string
}) => {
  const tracker = useTracking()
  const trackSearchEvents = () => {
    const event = result.tracking_event
    if (!event) return

    trackUnifiedSearchEvent(event, tracker.trackEvent)
  }

  const handleCtaClick = () => {
    trackSearchEvents()
    const searchQueryType = getSearchHistoryTypeFromSectionHeading(result)
    if (searchQueryType) {
      addToSearchHistory(searchQuery, searchQueryType)
    }
    window.location.href = result.url
  }

  return (
    <div className={css.SearchResults__heading}>
      <span className={css.SearchResults__headingText}>{result.title}</span>
      <button
        className={css.SearchResults__headingCta}
        onClick={handleCtaClick}
      >
        <span>{result.cta}</span>
      </button>
    </div>
  )
}

export default SearchResultSectionHeading
