import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { InfoButton } from 'components/buttons'
import { openCrowdfundReservationModal } from 'registry/modals/actions'
import { regItemProps } from '../reg-item-props'

const CrowdfundAction = (
  { regItem, openCrowdfundReservationModal },
  { isMobile }
) => {
  return (
    <InfoButton
      className="mtm pam"
      onClick={() => openCrowdfundReservationModal(regItem)}
      pill
      block
      rel="nofollow"
      size={isMobile ? 'small' : undefined}
    >
      Contribute to cash fund
    </InfoButton>
  )
}

CrowdfundAction.propTypes = {
  openCrowdfundReservationModal: PropTypes.func.isRequired,
  regItem: PropTypes.shape(regItemProps).isRequired,
}

CrowdfundAction.contextTypes = {
  isMobile: PropTypes.bool,
}

export default connect(undefined, { openCrowdfundReservationModal })(
  CrowdfundAction
)
