export const PAYMENT_OPTION_STEP = 1
export const RESERVATION_INFO_STEP = 2
export const THANK_YOU_ADDRESS_STEP = 3
export const CASH_CHECK_INSTRUCTIONS_STEP = 4
export const PAYPALME_INSTRUCTIONS_STEP = 5
export const CASH = 'CASH'
export const PAYPAL = 'PAYPAL'

export const CASH_PAYMENT_OPTION = {
  label: 'I will send cash or check directly',
  name: CASH,
}

export const PAYPAL_PAYMENT_OPTION = {
  label: 'Credit / Debit via PayPal',
  name: PAYPAL,
}
