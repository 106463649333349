import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import {
  CHROME,
  FIREFOX,
  MS_EDGE,
  SAFARI,
  BABYLIST_EXTENSION_URL,
} from 'shared/constants'
import Link from 'components/link'
import { contactUsPath } from 'lib/urls'
import { Helmet } from 'react-helmet'

export default class AlternateInstructions extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    browser: PropTypes.string,
  }

  static defaultProps = {
    onClick: () => {},
  }

  render() {
    const { browser, onClick } = this.props

    return (
      <div>
        <p>
          <b>Need help?</b>
          <Link newWindow className="phs" url={contactUsPath}>
            Email us.
          </Link>
        </p>

        <b>Other options:</b>
        <ul className="mts mll">
          <li className="mbn">
            Get the Babylist button for other browsers:{' '}
            <Link onClick={onClick.bind(this, CHROME)}>Chrome</Link>,{' '}
            <Link className="pls" onClick={onClick.bind(this, FIREFOX)}>
              Firefox
            </Link>
            ,{' '}
            <Link className="pls" onClick={onClick.bind(this, SAFARI)}>
              Safari
            </Link>
            , and{' '}
            <Link className="pls" onClick={onClick.bind(this, MS_EDGE)}>
              Edge.
            </Link>
          </li>

          {browser === CHROME ? (
            <li>
              <Helmet
                link={[
                  {
                    rel: 'chrome-webstore-item',
                    href: BABYLIST_EXTENSION_URL,
                  },
                ]}
              />
              Install our
              <Link className="phs" url={BABYLIST_EXTENSION_URL}>
                Chrome browser plugin.
              </Link>
            </li>
          ) : null}
        </ul>
      </div>
    )
  }
}
