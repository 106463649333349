import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as blUrls from 'lib/urls'
import Navbar, { NavItem } from 'components/navbar'
import useWindowSize from 'lib/hooks/useWindowSize'

import SubNavLinks from '../SubNavLinks/SubNavLinks'
import SubNavGuides from './SubNavGuides/SubNavGuides'
import Link from 'components/link'

import { useFeature } from 'flagged'

import css from './sub-nav.scss'
import { useState, useEffect } from 'react'
import {
  SEARCH_HISTORY_TYPE_GUIDES,
  addToSearchHistory,
} from 'shared/utils/searchHistory/searchHistory'

const onSearch = (searchText) => {
  addToSearchHistory(searchText, SEARCH_HISTORY_TYPE_GUIDES)
  location = blUrls.helloBabySearchPath(searchText)
}

const DynamicNavItem = (props) => {
  return (
    <>
      <li
        className={classNames(props.className, { active: props.active })}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      >
        {props.router ? (
          <Link onClick={props.onClick} to={props.to || props.href}>
            {props.children}
          </Link>
        ) : (
          <a href={props.href} onClick={props.onClick}>
            {props.children}
          </a>
        )}
        {props.subNav}
      </li>
    </>
  )
}

const SubNav = ({ className, searchTerm, topicSlug, topics = [] }) => {
  const [open, setOpen] = useState(false)
  const [top, setTop] = useState('')

  useEffect(() => {
    // get the offset of the subnav and set the top of the modal backdrop
    // to be the same as the subnav, showing at the correct spot offset from
    // the top of the page.
    const getModalBackdropOffset = () => {
      const subnav = document.getElementById('sub-nav')
      const { bottom } = subnav.getBoundingClientRect()

      setTop(bottom + 2)
    }
    getModalBackdropOffset()
    window.addEventListener('scroll', getModalBackdropOffset)

    return () => {
      window.removeEventListener('scroll', getModalBackdropOffset)
    }
  }, [setTop])

  let openTimer
  let closeTimer

  const closePopup = () => {
    clearTimeout(openTimer)
    closeTimer = setTimeout(() => setOpen(false), 100)
  }

  const openPopup = () => {
    clearTimeout(closeTimer)
    openTimer = setTimeout(() => setOpen(true), 250)
  }

  const renderTopic = (topics, topicSlug) =>
    topics.map((topic) => {
      const isActive = topicSlug && topicSlug == topic.slug
      const subNav = isActive && (hasChildren || hasGuides) && (
        <>
          <div
            style={{
              display: open ? 'block' : 'none',
              top: top,
              left: 0,
            }}
            className={`fadeIn ${css.dropdown}`}
            onMouseEnter={openPopup}
            onMouseLeave={closePopup}
          >
            {hasChildren && <SubNavLinks topics={children} />}
            {hasGuides && (
              <SubNavGuides title={selectedTopic.name} guides={guides} />
            )}
          </div>
          <div
            className={`modal-backdrop ${css.dropdownBackdrop}`}
            style={{
              display: open ? 'block' : 'none',
              top: top,
            }}
          />
        </>
      )
      return (
        <DynamicNavItem
          active={isActive}
          className={classNames(css.navItem, { [css.navItemActive]: isActive })}
          href={blUrls.helloBabyPostPath(topic.slug)}
          key={topic.slug}
          router
          onMouseEnter={topic.slug == topicSlug ? openPopup : null}
          onMouseLeave={topic.slug == topicSlug ? closePopup : null}
          subNav={subNav}
        >
          {topic.name}
        </DynamicNavItem>
      )
    })

  const selectedTopic = topics.find((topic) => topic.slug == topicSlug)
  const children = selectedTopic?.children
  const hasChildren = children && children.length > 0
  const guides = selectedTopic?.guides
  const hasGuides = guides && guides.length > 0

  const c1UnifiedSearchEnabled = useFeature('c1_unified_search_phase_2_enabled')

  return (
    <div id="sub-nav">
      <Navbar
        className={classNames(css.navBar, className, {
          [css.removeTopPadding]: c1UnifiedSearchEnabled,
        })}
        collapseAtSize="xs"
        collapseOnClick={true}
        collapseToggleElement={
          <div style={{ height: 52, lineHeight: '52px' }}>
            Topics <i className="fa fa-angle-down"></i>
          </div>
        }
        hasSearch
        searchClassName={css.searchInput}
        secondary
        searchText={searchTerm}
        searchPlaceholder="Search guides"
        globalSearchBarEnabled={c1UnifiedSearchEnabled}
        onSearch={onSearch}
      >
        <NavItem
          active={!topicSlug}
          className={classNames(
            css.navItem,
            !topicSlug ? css.navItemActive : ''
          )}
          href={blUrls.helloBabyPath}
          router
        >
          Home
        </NavItem>
        {renderTopic(topics, topicSlug)}
      </Navbar>
    </div>
  )
}

SubNav.propTypes = {
  topicSlug: PropTypes.string,
}

export default SubNav
