import PropTypes from 'prop-types'

export const AmazonCartItemShape = {
  id: PropTypes.number.isRequired,
  regItemId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
}

export const BundleComponentShape = {
  id: PropTypes.number.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
  }),
  name: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
}

export const RegItemShape = {
  description: PropTypes.any,
  id: PropTypes.number.isRequired,
  imgUrl: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isBonusGift: PropTypes.bool.isRequired,
  isCashGift: PropTypes.bool.isRequired,
  isCrowdfund: PropTypes.bool.isRequired,
  isDigitalItem: PropTypes.bool.isRequired,
  isElectronicGiftCard: PropTypes.bool.isRequired,
  isFavor: PropTypes.bool.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  isGiftCard: PropTypes.bool.isRequired,
  isGroupGift: PropTypes.bool.isRequired,
  isPurchasable: PropTypes.bool.isRequired,
  isPurchasableOnBabylist: PropTypes.bool.isRequired,
  isTaxable: PropTypes.bool.isRequired,
  offers: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  purchaseQuantityLimit: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  quantityNeeded: PropTypes.number.isRequired,
  registry: PropTypes.string.isRequired,
  registryId: PropTypes.number.isRequired,
  showPurchaseQuantityLimit: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

export const ReservationShape = {
  alert: PropTypes.any,
  bundleComponents: PropTypes.arrayOf(PropTypes.shape(BundleComponentShape)),
  canCancelOrder: PropTypes.bool.isRequired,
  createdAt: PropTypes.string.isRequired,
  daysSincePurchase: PropTypes.any,
  displayTitle: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  giftAmount: PropTypes.any,
  giftCardPrintableUrl: PropTypes.any,
  giftMessage: PropTypes.any,
  id: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  isEligibleForRegistryDiscount: PropTypes.bool.isRequired,
  isGroupGiftContribution: PropTypes.bool.isRequired,
  isPurchasableOnBabylist: PropTypes.bool.isRequired,
  isPurchaseVerified: PropTypes.bool.isRequired,
  isPurchased: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  orderCancelUntil: PropTypes.any,
  orderNumber: PropTypes.any,
  orderUuid: PropTypes.any,
  prevStore: PropTypes.any,
  printableUrl: PropTypes.any,
  productId: PropTypes.number.isRequired,
  purchasedAt: PropTypes.any,
  quantity: PropTypes.number.isRequired,
  regItem: PropTypes.shape(RegItemShape).isRequired,
  regItemId: PropTypes.number.isRequired,
  registryId: PropTypes.number.isRequired,
  returnWebUrl: PropTypes.any,
  returnlyDeepLink: PropTypes.any,
  store: PropTypes.string.isRequired,
  storeName: PropTypes.any,
  token: PropTypes.string.isRequired,
  trackingInfos: PropTypes.arrayOf(PropTypes.any).isRequired,
  unpurchasable: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
}

export const RegistryShape = {
  firstName: PropTypes.string,
  id: PropTypes.number,
  ownerId: PropTypes.number,
  shippingAddress: PropTypes.any,
  title: PropTypes.string,
  urlSlug: PropTypes.string,
}

export const CartItemShape = {
  bundleComponents: PropTypes.arrayOf(PropTypes.shape(BundleComponentShape)),
  canPurchaseWithUserCredit: PropTypes.bool,
  createdAtS: PropTypes.number,
  imageUrl: PropTypes.string,
  isElectronicGiftCard: PropTypes.bool,
  isEligibleForFreeShipping: PropTypes.bool,
  isEligibleForRegsitryDiscount: PropTypes.bool,
  isGiftCard: PropTypes.bool,
  isOutOfStock: PropTypes.bool,
  isTaxable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  productId: PropTypes.number,
  quantity: PropTypes.number,
  quantityLimit: PropTypes.number,
  stockAlert: PropTypes.any,
  storeUrl: PropTypes.string,
  uuid: PropTypes.string,
}

// created via buildCartItems()
export const ItemShape = {
  bundleComponents: PropTypes.arrayOf(PropTypes.shape(BundleComponentShape)),
  canPurchaseWithUserCredit: PropTypes.bool,
  createdAtS: PropTypes.number,
  productId: PropTypes.number,
  imageUrl: PropTypes.string,
  isElectronicGiftCard: PropTypes.bool,
  isEligibleForFreeShipping: PropTypes.bool,
  isEligibleForRegistryDiscount: PropTypes.bool,
  isGiftCard: PropTypes.bool,
  isPurchasableOnBabylist: PropTypes.bool,
  isPurchasable: PropTypes.bool,
  isPurchased: PropTypes.bool,
  isTaxable: PropTypes.bool,
  name: PropTypes.string,
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number,
  quantityLimit: PropTypes.number,
  registryDiscountPrice: PropTypes.number,
  storeUrl: PropTypes.string,
  type: PropTypes.string,
  uuid: PropTypes.string,
}

export const CartShape = {
  amazonCart: PropTypes.shape({
    checkoutUrl: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape(AmazonCartItemShape)),
  }),
  applyRegistryDiscount: PropTypes.bool,
  isEligibleForRegistryDiscount: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape(CartItemShape)),
}
