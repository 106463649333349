import React from 'react'

interface ShoppingCartEmptyProps {
  className?: string
  fill?: string
}

const ShoppingCartEmpty: React.FC<ShoppingCartEmptyProps> = ({
  className,
  fill = 'black',
}) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Shopping Cart Empty Icon</title>
    <g id="icon_shopping-cart-empty">
      <path
        clipRule="evenodd"
        d="M1.25 2C1.25 1.58579 1.58579 1.25 2 1.25C4.17637 1.25 6.08905 2.69274 6.68694 4.78537L7.05615 6.07759H19.5751C21.3039 6.07759 22.6038 7.65373 22.2749 9.35085L21.4293 13.7135C21.0876 15.4767 19.5438 16.75 17.7478 16.75H11.374C9.69971 16.75 8.22827 15.6401 7.7683 14.0302L5.24466 5.19745C4.83075 3.74877 3.50664 2.75 2 2.75C1.58579 2.75 1.25 2.41421 1.25 2ZM7.48472 7.57759L9.21059 13.6181C9.48656 14.5841 10.3694 15.25 11.374 15.25H17.7478C18.8254 15.25 19.7517 14.486 19.9567 13.4281L20.8023 9.06543C20.9518 8.29401 20.3609 7.57759 19.5751 7.57759H7.48472ZM8.25 20.5C8.25 19.2574 9.25736 18.25 10.5 18.25C11.7426 18.25 12.75 19.2574 12.75 20.5C12.75 21.7426 11.7426 22.75 10.5 22.75C9.25736 22.75 8.25 21.7426 8.25 20.5ZM10.5 19.75C10.0858 19.75 9.75 20.0858 9.75 20.5C9.75 20.9142 10.0858 21.25 10.5 21.25C10.9142 21.25 11.25 20.9142 11.25 20.5C11.25 20.0858 10.9142 19.75 10.5 19.75ZM16.25 20.5C16.25 19.2574 17.2574 18.25 18.5 18.25C19.7426 18.25 20.75 19.2574 20.75 20.5C20.75 21.7426 19.7426 22.75 18.5 22.75C17.2574 22.75 16.25 21.7426 16.25 20.5ZM18.5 19.75C18.0858 19.75 17.75 20.0858 17.75 20.5C17.75 20.9142 18.0858 21.25 18.5 21.25C18.9142 21.25 19.25 20.9142 19.25 20.5C19.25 20.0858 18.9142 19.75 18.5 19.75Z"
        fill={fill}
        fillRule="evenodd"
        id="Vector"
      />
    </g>
  </svg>
)

export default ShoppingCartEmpty
