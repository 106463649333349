import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { CurrentUser } from '../../global'

type CurrentUserProps = {
  currentUser: CurrentUser | undefined
  setCurrentUser: (currentUser?: CurrentUser) => void
}

function withCurrentUser<P extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<P & CurrentUserProps>
): React.FC<P> {
  return function WithCurrentUser(props: P) {
    const [currentUser, setCurrentUser] = useCurrentUser()

    return (
      <WrappedComponent
        {...(props as P)}
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
      />
    )
  }
}

export default withCurrentUser
