import { Component } from 'react'
import PropTypes from 'prop-types'
import { DangerAlert } from 'components/alerts'

export default class Form extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ref: PropTypes.object,
  }

  render() {
    const { error, className, onSubmit, children, ref } = this.props

    return (
      <form className={className} onSubmit={onSubmit} ref={ref}>
        {error && <DangerAlert>{error}</DangerAlert>}
        {children}
      </form>
    )
  }
}
