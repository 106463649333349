const CategoryNurseryDecor = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 258.35 258.35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M182.82,175.17v22a2.1,2.1,0,0,1-2.09,2.09H115.09a2.1,2.1,0,0,1-2.09-2.09V191.7l1.14,0a35.62,35.62,0,0,0,31.33-18.66,1.23,1.23,0,0,0,.27,0h4.34a3.28,3.28,0,0,0,3.28-3.28v-.41a12,12,0,1,1,24.09.17v.24a3.28,3.28,0,0,0,3.28,3.28A2.1,2.1,0,0,1,182.82,175.17Zm-10.58-5.37v-.41a6.87,6.87,0,0,0-7.07-6.84,6.92,6.92,0,0,0-6.6,7v.29a3.27,3.27,0,0,0,3.27,3.28H169A3.28,3.28,0,0,0,172.24,169.8Z"
      fill="#d8d8d8"
    />
    <path
      d="M142.46,169.8a3.26,3.26,0,0,0,3,3.26,35.62,35.62,0,0,1-31.33,18.66l-1.14,0V175.17a2.12,2.12,0,0,1,.61-1.48,2.09,2.09,0,0,1,1.48-.61,3.28,3.28,0,0,0,3.28-3.28v-.41a12.06,12.06,0,0,1,12.62-12,12,12,0,0,1,11.47,12.21Zm-5.21,0v-.41a6.8,6.8,0,0,0-2-4.83,6.89,6.89,0,0,0-2.45-1.58,6.67,6.67,0,0,0-2.61-.43,6.92,6.92,0,0,0-6.6,7v.29a3.27,3.27,0,0,0,3.27,3.28H134A3.28,3.28,0,0,0,137.25,169.8Z"
      fill="#d8d8d8"
    />
    <path
      d="M109.48,111.4H77.92a2.39,2.39,0,0,1-2.36-2.81l8.28-47.51a2.4,2.4,0,0,1,2.37-2h55.87a2.38,2.38,0,0,1,2.36,2l8.29,47.51a2.39,2.39,0,0,1-2.36,2.81H109.48Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <line
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
      x1="109.48"
      x2="109.48"
      y1="119.34"
      y2="111.4"
    />
    <line
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
      x1="118.81"
      x2="118.81"
      y1="119.34"
      y2="111.4"
    />
    <path
      d="M113,191.7a35.6,35.6,0,1,1,32.47-18.64"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M145.47,173.06a3.26,3.26,0,0,1-3-3.26v-.24A12,12,0,0,0,131,157.35a12.06,12.06,0,0,0-12.62,12v.41a3.28,3.28,0,0,1-3.28,3.28,2.09,2.09,0,0,0-1.48.61,2.12,2.12,0,0,0-.61,1.48v22a2.1,2.1,0,0,0,2.09,2.09h65.64a2.1,2.1,0,0,0,2.09-2.09v-22a2.1,2.1,0,0,0-2.09-2.09,3.28,3.28,0,0,1-3.28-3.28v-.24a12,12,0,1,0-24.09-.17v.41a3.28,3.28,0,0,1-3.28,3.28h-4.34A1.23,1.23,0,0,1,145.47,173.06Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M132.31,163.94a5.52,5.52,0,0,1,2,1.27,5.45,5.45,0,0,1,1.6,3.86v.33a2.62,2.62,0,0,1-2.62,2.62h-5.69A2.62,2.62,0,0,1,125,169.4v-.23a5.46,5.46,0,0,1,7.36-5.23Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M170.87,169.07v.33a2.62,2.62,0,0,1-2.62,2.62h-5.7a2.62,2.62,0,0,1-2.62-2.62v-.23a5.54,5.54,0,0,1,5.29-5.57,5.48,5.48,0,0,1,5.65,5.47Z"
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <line
      fill="none"
      stroke="#adadad"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
      x1="145.69"
      x2="145.69"
      y1="181.36"
      y2="191.47"
    />
  </svg>
)

export default CategoryNurseryDecor
