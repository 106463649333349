import { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'components/forms'
import Link from 'components/link'
import { REG_ITEM_PLACEHOLDER_TEXT } from 'registry/constants'
import ImageUploadPreviewer from 'shared/image-upload-previewer/image-upload-previewer'
import CashFundPreferencesForm from 'registry/forms/cash-fund-preferences-form'
import { settingsCashFundPreferencesPath } from 'lib/urls'
import css from './reg-item-form.scss'
import FavoriteToggle from 'shared/reg-item-form/components/favorite-toggle'

export default class CrowdfundFields extends Component {
  static propTypes = {
    cashFundPreferences: PropTypes.object,
    regItem: PropTypes.object,
    onRegItemChange: PropTypes.func.isRequired,
  }

  handleChange = (e) => {
    this.props.onRegItemChange({ [e.target.name]: e.target.value })
  }

  handleFavoriteChange = (isChecked) => {
    this.props.onRegItemChange({ isFavorite: isChecked })
  }

  handleImageChange = ({ imgUrl }) => {
    this.props.onRegItemChange({ imgUrl })
  }

  render() {
    const regItemFields = this.props.regItem
    const { isRegistryOwner, cashFundPreferences } = this.props

    return (
      <div>
        <div className={css.item}>
          <div className={css.imageContainer}>
            <ImageUploadPreviewer
              imgUrl={regItemFields.imgUrl}
              onChange={this.handleImageChange}
            />
          </div>
          <div className={css.itemFields}>
            <Input
              label="Title"
              name="title"
              value={regItemFields.title}
              onChange={this.handleChange}
            />
            <FavoriteToggle
              defaultOn={this.props.regItem.isFavorite}
              onClick={this.handleFavoriteChange}
            />
            <Input
              label="Notes for friends and family"
              className={css.notes}
              name="description"
              type="textarea"
              value={regItemFields.description || ''}
              onChange={this.handleChange}
              placeholder={REG_ITEM_PLACEHOLDER_TEXT}
            />
            <CashFundPreferencesForm
              preferences={cashFundPreferences}
              isEditable={isRegistryOwner}
            />
            <p className="h6 text-underline">
              <Link url={settingsCashFundPreferencesPath}>
                Manage Cash Fund Preferences
              </Link>
            </p>
          </div>
        </div>
      </div>
    )
  }
}
