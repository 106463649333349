import PropTypes from 'prop-types'
import classNames from 'classnames'
import { numberToCurrency } from 'lib/money'
import styleMaps from 'shared/style-maps'
import { ProgressBar } from 'components/progress-bar'
import { GiftBoxIcon } from 'components/icons'

const FundingStatusText = ({
  color,
  goalAmount,
  fundedAmount,
  isContributorView,
}) => (
  <strong>
    {fundedAmount >= goalAmount && !isContributorView ? (
      <span>Group Gift Fully Funded! 🎉</span>
    ) : (
      <>
        <span style={{ color }}>
          Funded: {numberToCurrency(fundedAmount, { truncateZeroCents: true })}
        </span>
        <span style={{ color: 'black' }}>
          {' '}
          of {numberToCurrency(goalAmount, { truncateZeroCents: true })}
        </span>
      </>
    )}
  </strong>
)

FundingStatusText.propTypes = {
  color: PropTypes.string,
  fundedAmount: PropTypes.number,
  goalAmount: PropTypes.number,
  isContributorView: PropTypes.bool,
}

const FundingProgress = (props) => {
  const { className, goalAmount, fundedAmount } = props

  let percentFunded = 100
  if (goalAmount && goalAmount > 0) {
    percentFunded = Math.min((fundedAmount / goalAmount) * 100, 100)
  }

  const color = styleMaps.COLORS.salmon

  return (
    <div className={classNames(className, 'h6')}>
      <div className="flex mbm">
        <GiftBoxIcon className="mrm" />
        <strong>
          <FundingStatusText color={color} {...props} />
        </strong>
      </div>
      <ProgressBar
        color={color}
        backgroundColor="#eee"
        height={8}
        percent={percentFunded}
        radius={24}
      />
    </div>
  )
}

FundingProgress.propTypes = {
  className: PropTypes.string,
  isContributorView: PropTypes.bool,
  goalAmount: PropTypes.number,
  fundedAmount: PropTypes.number,
}

FundingProgress.defaultProps = {
  fundedAmount: 0,
  goalAmount: 1,
  className: null,
  isContributorView: false,
}

export default FundingProgress
