export default (predicate, jsx) => {
  if (predicate) {
    return jsx
  }
}

export const showWhenReady = (resource, jsx) => {
  if (resource) {
    return jsx(resource)
  }
}
