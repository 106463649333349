/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { SecondaryButton, InfoButton } from 'components/buttons'
import * as blUrls from 'lib/urls'
import onCurrentUserExternalLogin from 'shared/utils/onCurrentUserExternalLogin/onCurrentUserExternalLogin'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { getCurrentUser } from 'shared/utils/getCurrentUser/getCurrentUser'
import { CurrentUser } from '../../../global'

interface AddToRegistryButtonProps {
  addToRegistry: (registryId: number) => Promise<any>
  buttonStyle: any
  onSuccess: (regItem: any, updatedCurrentUser?: CurrentUser) => void
  useInfoButtonStyle: boolean
  style: any
}

const AddToRegistryButton = ({
  addToRegistry,
  buttonStyle,
  onSuccess,
  useInfoButtonStyle,
  style,
}: AddToRegistryButtonProps) => {
  const [currentUser] = useCurrentUser()
  const [addedToRegistry, setAddedToRegistry] = useState(false)
  const [addingItemToRegistry, setAddingItemToRegistry] = useState(false)

  const text = addedToRegistry ? '✓ Added!' : 'Add to Babylist'

  const handleAddToRegistry = () => {
    const updatedCurrentUser = getCurrentUser()
    const registryId = updatedCurrentUser?.registryId

    setAddingItemToRegistry(true)

    addToRegistry(registryId!)
      .then((resp) => {
        setAddedToRegistry(true)
        onSuccess(resp.regItem, updatedCurrentUser)
      })
      .finally(() => setAddingItemToRegistry(false))
  }

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!currentUser) {
      window.open(blUrls.loginPathAsPopup, '_blank')
      onCurrentUserExternalLogin(handleAddToRegistry)
    } else {
      handleAddToRegistry()
    }
  }

  return useInfoButtonStyle ? (
    <InfoButton
      block
      {...buttonStyle}
      onClick={handleClick}
      submitting={addingItemToRegistry}
      style={style}
    >
      {text}
    </InfoButton>
  ) : (
    <SecondaryButton
      block
      {...buttonStyle}
      onClick={handleClick}
      submitting={addingItemToRegistry}
      style={style}
    >
      {text}
    </SecondaryButton>
  )
}

AddToRegistryButton.propTypes = {
  addToRegistry: PropTypes.func,
  buttonStyle: PropTypes.object,
  onSuccess: PropTypes.func,
  useInfoButtonStyle: PropTypes.bool,
  style: PropTypes.object,
}

AddToRegistryButton.defaultProps = {
  addToRegistry: () => Promise.resolve(),
  buttonStyle: {},
  onSuccess: () => {},
  useInfoButtonStyle: false,
  style: {},
}

export default AddToRegistryButton
