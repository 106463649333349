import PropTypes from 'prop-types'
import classNames from 'classnames'
import css from './card.scss'

export const VARIANTS = {
  INFO: 'info',
  HALF_ROUND: 'halfRound',
}

export const COLOR_VARIANTS = {
  DEFAULT: 'white',
  APRICOT: 'apricot',
  BUBBLEGUM: 'bubblegum',
  EGGPLANT: 'eggplant',
  FOREST: 'forest',
  GOLDEN_YELLOW: 'golden-yellow',
  RUBYRED: 'ruby-red',
  SALMON: 'salmon',
  SKY: 'sky',
  BUBBLEGUM_GRADIENT: 'bubblegum-gradient',
  EGGPLANT_GRADIENT: 'eggplant-gradient',
  FOREST_GRADIENT: 'forest-gradient',
  RUBYRED_GRADIENT: 'ruby-red-gradient',
  SALMON_GRADIENT: 'salmon-gradient',
  SKY_GRADIENT: 'sky-gradient',
}

const Card = ({
  bgColor,
  children,
  className,
  onClick,
  variant,
  layout,
  style,
}) => {
  const colorClass = css[bgColor]
  const variantClass = css[variant]

  const cardProps = {
    onClick,
    style,
    className: classNames(css.wrapper, className, colorClass, variantClass),
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...cardProps} data-layout={layout}>
      {children}
    </div>
  )
}

Card.propTypes = {
  bgColor: PropTypes.oneOf(Object.values(COLOR_VARIANTS)),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  // Maintain backwards compatibility by supporting empty string
  layout: PropTypes.oneOf(['', 'vertical', 'horizontal', 'responsive']),
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
}

Card.defaultProps = {
  className: '',
  bgColor: COLOR_VARIANTS.DEFAULT,
  onClick: () => {},
  variant: undefined,
  layout: '',
  style: {},
}

// eslint-disable-next-line react/jsx-props-no-spreading
export const InfoCard = (props) => <Card {...props} variant={VARIANTS.INFO} />
export const FeedProductCard = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Card {...props} variant={VARIANTS.HALF_ROUND} />
)
export const HalfRoundCard = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Card {...props} variant={VARIANTS.HALF_ROUND} />
)

Card.Media = ({ children }) => {
  return <div className={css.media}>{children}</div>
}

Card.Media.propTypes = {
  children: PropTypes.node.isRequired,
}

Card.Content = ({ children }) => {
  return <div className="pal">{children}</div>
}

Card.Content.propTypes = {
  children: PropTypes.node.isRequired,
}

// eslint-disable-next-line import/no-default-export
export default Card

const Footer = ({ background, variant, children }) => {
  const variantClass = css[variant]
  return (
    <div
      className={classNames(variantClass, css.footer)}
      data-background={background}
    >
      {children}
    </div>
  )
}

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.oneOf(['default', 'muted']),
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
}

Footer.defaultProps = {
  background: 'default',
  variant: undefined,
}

Card.Footer = Footer
