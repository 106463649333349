// Removes a query param from the URL without refreshing the page
const removeQueryParam = (queryParam: string) => {
  if (typeof window === undefined) return

  const urlObj = new URL(window.location.href)
  const queryParams = urlObj.searchParams
  queryParams.delete(queryParam)
  urlObj.search = queryParams.toString()

  window.history.replaceState(null, '', urlObj.toString())
}

export default removeQueryParam
