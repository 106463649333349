import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  openGroupGiftRedemptionModal,
  openRedirectModal,
} from 'registry/modals/actions'
import { createReservation, fetchRegItem } from 'registry/actions'
import { tracking } from 'lib/analytics'
import { getFilters } from 'registry/reducers'
import ProductRegItemCard from './product-reg-item-card'
import { ReservedRegItemCard } from './reserved-reg-item-card'
import GiftCardRegItemCard from './gift-card-reg-item-card'
import { FavorRegItemCard } from './favor-reg-item-card'
import ExternalRegistryRegItemCard from './external-registry-reg-item-card'
import CashFundRegItemCard from './cash-fund-reg-item-card'
import { CrowdfundRegItemCard } from './crowdfund-reg-item-card'
import GroupGiftRegItemCard from './group-gift-reg-item-card'
import { regItemProps } from './reg-item-props'

export const RegItemCard = (props, context) => {
  const { isContributorView, regItem, reservationByCurrentVisitor } = props

  let regItemCard

  if (
    !isContributorView &&
    (regItem.isReserved ||
      regItem.isReservedByCurrentVisitor ||
      regItem.isGoalComplete)
  ) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    regItemCard = <ReservedRegItemCard {...props} />
  } else if (regItem.isGiftCard) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    regItemCard = <GiftCardRegItemCard {...props} />
  } else if (regItem.isFavor) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    regItemCard = <FavorRegItemCard {...props} />
  } else if (regItem.isExternalRegistry) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    regItemCard = <ExternalRegistryRegItemCard {...props} />
  } else if (regItem.isCashFund) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    regItemCard = <CashFundRegItemCard {...props} />
  } else if (regItem.isCrowdfund) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    regItemCard = <CrowdfundRegItemCard {...props} />
  } else if (regItem.isGroupGift) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    regItemCard = <GroupGiftRegItemCard {...props} />
  } else {
    // eslint-disable-next-line react/jsx-props-no-spreading
    regItemCard = <ProductRegItemCard {...props} />
  }

  return regItemCard
}

RegItemCard.propTypes = {
  isContributorView: PropTypes.bool,
  isRegistryDiscountView: PropTypes.bool,
  isReservationByCurrentVisitor: PropTypes.bool,
  reservationsByCurrentVisitor: PropTypes.object,
  regItem: PropTypes.shape(regItemProps),
  openRedirectModal: PropTypes.func.isRequired,
}

RegItemCard.contextTypes = {
  registry: PropTypes.object,
}

const mapStateToProps = (state) => ({
  filters: getFilters(state),
})

const mapDispatchToProps = (dispatch, { regItem }) => ({
  openGroupGiftRedemptionModal: (action) =>
    dispatch(openGroupGiftRedemptionModal(regItem, action)),
  openRedirectModal: ({ url, storeDisplayName, onRedirectSuccess }) =>
    dispatch(
      openRedirectModal({
        url,
        storeDisplayName,
        onRedirectSuccess,
      })
    ),
  submitAddToCartForm: (reservation) =>
    dispatch(createReservation(reservation)).then(() => {
      return dispatch(fetchRegItem(reservation.regItemId)).then(() => {
        return reservation
      })
    }),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  tracking(({ regItem }) => ({ regItem }))
)(RegItemCard)
