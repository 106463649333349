import { createRef, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link as ReactRouterLink } from 'react-router'
import { Link as ReactRouterDomLink } from 'react-router-dom'
import {
  Link as ReactRouterCompatLink,
  useInRouterContext,
} from 'react-router-dom-v5-compat'
import { tracking, track, dummyTracking } from 'lib/analytics'

@tracking()
class Link extends PureComponent {
  constructor(props) {
    super(props)
    this.anchor = createRef()
    this.handleClick = this.handleClick.bind(this)
  }

  static defaultProps = {
    rel: '',
    routerVersion: 3,
    tracking: dummyTracking,
  }

  // will only actually track an event if `{event: track.XXX}` context exist
  @tracking()
  handleClick(e) {
    const { _anchor, onClick } = this.props
    _anchor && _anchor.blur()
    if (onClick) {
      onClick(e)
    }
  }

  render() {
    let {
      children,
      className,
      id,
      newWindow,
      style,
      title,
      to,
      url,
      rel,
      routerVersion,
    } = this.props

    // No url, so this is a link with an onClick handler and no
    // actual url to navigate to
    const href = url ? { href: url } : { role: 'button' }

    return (
      // The react-router Link component does NOT fallback to normal link behavior.
      // It is attempting to do a route transition even when there is no router present.
      // Render as standard anchor if the to prop is not present.
      to ? (
        <RouterLink
          aria-label={this.props['aria-label']}
          className={className}
          data-testid={this.props['data-testid']}
          onClick={this.handleClick}
          style={style}
          title={title}
          to={to}
          routerVersion={routerVersion}
        >
          {children}
        </RouterLink>
      ) : (
        <a
          {...href}
          aria-label={this.props['aria-label']}
          className={className}
          data-testid={this.props['data-testid']}
          id={id}
          ref={(component) => (this._anchor = component)}
          style={style}
          target={newWindow ? '_blank' : null}
          title={title}
          onClick={this.handleClick}
          rel={newWindow ? `${rel} noopener noreferrer` : rel}
        >
          {children}
        </a>
      )
    )
  }
}

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  newWindow: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  title: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  url: PropTypes.string,
  rel: PropTypes.string,
  routerVersion: PropTypes.oneOf([3, 4, 6]),
}

// We are using 2 different versions of react-router in our codebase.
// Pass the routerVersion prop to specify using react-router-dom's link component.
// TODO: Migrate all existing react router uses to use the same version.
const RouterLink = (props) => {
  const { routerVersion, ...cleanProps } = props
  switch (routerVersion) {
    case 4:
      return <ReactRouterDomLink {...cleanProps} />
    case 6:
      return <ReactRouterCompatLink {...cleanProps} />
    default:
      return <ReactRouterLink {...cleanProps} />
  }
}

const UniversalLink = (props) => {
  const routerVersion = useInRouterContext() ? 6 : props.routerVersion

  return <Link {...props} routerVersion={routerVersion} />
}

/**
 * Link to navigate to url (`href`) or using react router (`to`)
 * support `onClick` callback
 * support a TrackEventHandlerContext.Provider to be called on click
 *
 */
export default UniversalLink
