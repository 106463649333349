import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { Input } from 'components/forms'
import { InfoButton, LinkButton } from 'components/buttons'
import { numberToCurrency } from 'lib/money'
import { between } from 'lib/form-validation'
import {
  GROUP_GIFT_GOAL_AMOUNT_BUFFER_PERCENT,
  GROUP_GIFT_GOAL_AMOUNT_BUFFER_PERCENT_DISPLAY,
} from 'shared/constants'
import css from './GroupGiftGoalAmountForm.style.scss'

interface GroupGiftGoalAmountFormProps {
  basePrice: number
  onSubmit: (props: object) => void
}

const GroupGiftGoalAmountForm = ({
  basePrice,
  onSubmit,
}: GroupGiftGoalAmountFormProps) => {
  const goalAmountInputRef = useRef<HTMLInputElement | null>(null)
  const [showCustomAmount, setShowCustomAmount] = useState(false)
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      goalAmount: basePrice.toFixed(2),
    },
  })

  const recommendedGoalIncrease =
    basePrice * Number(GROUP_GIFT_GOAL_AMOUNT_BUFFER_PERCENT)

  const basePriceDisplay = numberToCurrency(basePrice)
  const recommendedGoalTotal = basePrice + recommendedGoalIncrease
  const recommendedGoalTotalDisplay = numberToCurrency(recommendedGoalTotal)

  useEffect(() => {
    if (showCustomAmount) {
      goalAmountInputRef.current?.focus()
    }
  })

  const handleSave = (data: { goalAmount: string | number }) => {
    onSubmit({
      isGroupGift: true,
      goalAmount: data.goalAmount,
      private: false,
      quantity: 1,
    })
  }

  return (
    <>
      <div className="mbl">
        <p className="h6">
          This item's base price:
          <span className="pull-right">{basePriceDisplay}</span>
        </p>
        <p className="h6">
          {GROUP_GIFT_GOAL_AMOUNT_BUFFER_PERCENT_DISPLAY}
          <span className="pull-right">
            {numberToCurrency(recommendedGoalIncrease)}
          </span>
        </p>
        <hr style={{ borderColor: '#a5a5a5' }} />
        <p className="h6 text-bold">
          {`Item base price + ${GROUP_GIFT_GOAL_AMOUNT_BUFFER_PERCENT_DISPLAY}:`}
          <span className="pull-right">{recommendedGoalTotalDisplay}</span>
        </p>
      </div>

      {!showCustomAmount && (
        <div className={css.buttonGroup}>
          <InfoButton
            block
            pill
            onClick={() => handleSave({ goalAmount: recommendedGoalTotal })}
          >
            {`Yes, Set Goal to ${recommendedGoalTotalDisplay}`}
          </InfoButton>
          <InfoButton
            block
            empty
            pill
            onClick={() => handleSave({ goalAmount: basePrice })}
          >
            {`No, Set Goal to ${basePriceDisplay}`}
          </InfoButton>
        </div>
      )}

      <form onSubmit={handleSubmit(handleSave)}>
        <Input
          error={errors.goalAmount && errors.goalAmount?.message}
          inputRef={(e: any) => {
            goalAmountInputRef.current = e
            register(e, {
              required: 'Please enter a goal amount',
              validate: between(1, 10000, '$'),
              pattern: {
                value: /^\d*(\.\d{0,2})?$/,
                message: 'Please enter a valid goal amount',
              },
            })
          }}
          label="Group Gift Goal"
          name="goalAmount"
          style={{ backgroundColor: 'transparent' }}
          type={showCustomAmount ? 'text' : 'hidden'}
        />
        {showCustomAmount && (
          <InfoButton block pill disabled={!!errors.goalAmount} type="submit">
            Set Custom Goal
          </InfoButton>
        )}
      </form>

      {!showCustomAmount && (
        <>
          <p className="h6 mtl text-right">
            Item includes a shipping surcharge? Set Custom Goal to cover
            delivery surcharge cost.
          </p>
          <div className={classNames(css.customLink, 'mtm')}>
            <LinkButton
              className="man pan text-brand-info text-bold h7"
              onClick={() => setShowCustomAmount(!showCustomAmount)}
            >
              Set Custom Goal
            </LinkButton>
          </div>
        </>
      )}
    </>
  )
}

export default GroupGiftGoalAmountForm
