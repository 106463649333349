import React from 'react'

interface FilterProps {
  className?: string
}

const Filter: React.FC<FilterProps> = ({ className }: FilterProps) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Filter-1">
      <path
        d="M2 3.99982H10"
        id="Vector 45"
        stroke="#6B6669"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 10L9 10"
        id="Vector 55"
        stroke="#6B6669"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 16.0002H10"
        id="Vector 53"
        stroke="#6B6669"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 3.99982L18 3.99982"
        id="Vector 48"
        stroke="#6B6669"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 10L2 10"
        id="Vector 56"
        stroke="#6B6669"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 16.0002L18 16.0002"
        id="Vector 54"
        stroke="#6B6669"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        height="3"
        id="Rectangle 384"
        rx="1"
        stroke="#6B6669"
        strokeLinecap="round"
        strokeLinejoin="round"
        width="3"
        x="10"
        y="2"
      />
      <rect
        height="3"
        id="Rectangle 389"
        rx="1"
        stroke="#6B6669"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="matrix(-1 0 0 1 9 8)"
        width="3"
      />
      <rect
        height="3"
        id="Rectangle 388"
        rx="1"
        stroke="#6B6669"
        strokeLinecap="round"
        strokeLinejoin="round"
        width="3"
        x="10"
        y="14"
      />
    </g>
  </svg>
)

export default Filter
