const CategoryHealthSafety = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 258.35 258.35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M145.65,132.18V139a3.74,3.74,0,0,1-3.72,3.72h-3.15a1.63,1.63,0,0,0-1.63,1.64v3.14a3.73,3.73,0,0,1-3.73,3.72h-6.81a3.73,3.73,0,0,1-3.72-3.72v-3.14a1.64,1.64,0,0,0-1.64-1.64H118.1a3.74,3.74,0,0,1-3.72-3.72v-6.82a3.73,3.73,0,0,1,3.72-3.72h3.15a1.65,1.65,0,0,0,1.64-1.64v-3.14a3.73,3.73,0,0,1,3.72-3.73h6.81a3.74,3.74,0,0,1,3.73,3.73v3.14a1.64,1.64,0,0,0,1.63,1.64h3.15A3.73,3.73,0,0,1,145.65,132.18Z"
      fill="#d8d8d8"
    />
    <path
      d="M97.78,201.92a.88.88,0,0,1-1.48.93s-2.13-2.46-5.51-6.39l-8.17-9.54-1.76-2.06,7.32-5c.85,2,1.67,3.83,2.46,5.62.21.48.42.95.62,1.42l3.56,8.17Z"
      fill="#d8d8d8"
    />
    <path
      d="M88.18,179.88l-7.32,5C76.09,179.27,70.77,173,65.76,167l-1.17-1.4c-8.55-10.22-16-19.37-18-22.53a11.31,11.31,0,0,1,3.59-15.59,10.54,10.54,0,0,1,1.84-.92,11.31,11.31,0,0,1,11.71,2.09,9.17,9.17,0,0,1,.82.83,10.72,10.72,0,0,1,1.22,1.6c1.37,2.18,4.93,9.75,9.2,19.18,3.68,8.11,7.89,17.6,11.67,26.22ZM59.05,141.62a4.5,4.5,0,1,0-6.2-1.43A4.5,4.5,0,0,0,59.05,141.62Z"
      fill="#d8d8d8"
    />
    <path
      d="M60.48,135.42a4.5,4.5,0,1,1-6.2-1.43A4.5,4.5,0,0,1,60.48,135.42Z"
      fill="#d8d8d8"
    />
    <path
      d="M64.59,129.49V92.41a8.18,8.18,0,0,1,8.16-8.15H89.34A2.65,2.65,0,0,0,92,81.61V73.75a18.6,18.6,0,0,1,18.58-18.58h38.89A18.59,18.59,0,0,1,168,73.75v7.86a2.65,2.65,0,0,0,2.65,2.65h16.6a8.18,8.18,0,0,1,8.15,8.15v86.35a8.18,8.18,0,0,1-8.15,8.16h-96"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M64.59,165.61v13.15a8.18,8.18,0,0,0,8.16,8.16h9.87"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M75,150.27V98.33a3.66,3.66,0,0,1,3.65-3.65H181.37A3.65,3.65,0,0,1,185,98.33v74.52a3.64,3.64,0,0,1-3.65,3.64H86.68"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M157.62,73.75V81.9a2.36,2.36,0,0,1-2.36,2.36H104.77a2.36,2.36,0,0,1-2.35-2.36V73.75a8.18,8.18,0,0,1,8.15-8.16h38.89A8.18,8.18,0,0,1,157.62,73.75Z"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M145.65,132.18V139a3.74,3.74,0,0,1-3.72,3.72h-3.15a1.63,1.63,0,0,0-1.63,1.64v3.14a3.73,3.73,0,0,1-3.73,3.72h-6.81a3.73,3.73,0,0,1-3.72-3.72v-3.14a1.64,1.64,0,0,0-1.64-1.64H118.1a3.74,3.74,0,0,1-3.72-3.72v-6.82a3.73,3.73,0,0,1,3.72-3.72h3.15a1.65,1.65,0,0,0,1.64-1.64v-3.14a3.73,3.73,0,0,1,3.72-3.73h6.81a3.74,3.74,0,0,1,3.73,3.73v3.14a1.64,1.64,0,0,0,1.63,1.64h3.15A3.73,3.73,0,0,1,145.65,132.18Z"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M64.59,165.61c-8.55-10.22-16-19.37-18-22.53a11.31,11.31,0,0,1,3.59-15.59,10.54,10.54,0,0,1,1.84-.92,11.31,11.31,0,0,1,11.71,2.09,9.17,9.17,0,0,1,.82.83,10.72,10.72,0,0,1,1.22,1.6c1.37,2.18,4.93,9.75,9.2,19.18,3.68,8.11,7.89,17.6,11.67,26.22l1.5,3.39c.85,2,1.67,3.83,2.46,5.62.21.48.42.95.62,1.42l3.56,8.17,3,6.83a.88.88,0,0,1-1.48.93s-2.13-2.46-5.51-6.39l-8.17-9.54-1.76-2.06C76.09,179.27,70.77,173,65.76,167Z"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M59.05,141.62a4.5,4.5,0,1,1,1.43-6.2A4.49,4.49,0,0,1,59.05,141.62Z"
      fill="none"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <polyline
      fill="none"
      points="80.64 185 80.86 184.86 88.18 179.88 88.56 179.62"
      stroke="#adadad"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
  </svg>
)

export default CategoryHealthSafety
