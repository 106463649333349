import { FC } from 'react'
import FulfillmentTrackingInfos from '../../shared/orders/fulfillment-tracking-infos'
import { InfoButton } from 'components/buttons'
import { orderPath } from '../../lib/urls'
import withExpiration from '../../shared/with-expiration'

interface GiftActionsProps {
  className?: string
  canCancelOrder: boolean
  orderCancelUntil: string
  orderUuid: string
  trackingInfos: Array<{
    action: string
    number: number | string
    url: string
    isSubscriptionItem: boolean
  }>
}

const GiftActions: FC<GiftActionsProps> = ({
  className = '',
  canCancelOrder,
  orderCancelUntil,
  orderUuid,
  trackingInfos = [],
}) => {
  const InfoButtonWithExpiration = withExpiration(InfoButton)
  const trackingInfoButton = (
    <InfoButton
      className="h7 mts mrm text-brand-info"
      empty
      pill
      newWindow
      size="small"
    ></InfoButton>
  )
  const subscriptionSetupButton = (
    <InfoButton className="h7 mts mrm" pill newWindow size="small"></InfoButton>
  )
  return (
    <div className={className}>
      {canCancelOrder && (
        <InfoButtonWithExpiration
          className="h7 mts mrm text-brand-info"
          empty
          expiresAt={orderCancelUntil}
          href={orderPath(orderUuid)}
          newWindow
          pill
          size="small"
        >
          Cancel order
        </InfoButtonWithExpiration>
      )}
      <FulfillmentTrackingInfos
        trackingInfoButtonComponent={trackingInfoButton}
        trackingInfos={trackingInfos}
        subscriptionSetupButton={subscriptionSetupButton}
      />
    </div>
  )
}

export default GiftActions
