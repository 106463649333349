import { InfoButton } from 'components/buttons'
import css from 'baby-ui/compounds/RegistryBanner/search-banner.styles.scss'
import { healthPath } from 'lib/urls'
import { OpenInNewWindowIcon } from 'components/icons'

export const SearchBannerButton = () => (
  <InfoButton
    pill
    className={css.bannerButton}
    href={healthPath}
    size="small"
    target="_blank"
  >
    <span style={{ marginRight: 8 }}>Check Your Eligibility</span>
    <OpenInNewWindowIcon />
  </InfoButton>
)
