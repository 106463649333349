import { useQueryClient } from '@tanstack/react-query'
import { useCreateRegItemReservation } from 'src/routes/(registry)/api/reservations'
import { Reservation } from 'src/types/reservation'
import { CreateReservationResponse } from 'src/routes/(registry)/api/types'
import {
  invalidateRegItemReservationsQuery,
  invalidateRegItemsQuery,
  optimisticallySaveReservation,
} from 'src/routes/(registry)/list/[slug]/utils/registryHelpers'
import { RegItem } from 'src/types/regItem'
import { track } from 'lib/analytics'

export type MarkAsPurchasedProps = Partial<Reservation> & {
  isSelfPurchase: boolean
  createdBy?: number | null
  cashFundContributionAttributes?: {
    paymentMethod?: string | null
  }
}

interface UseMarkAsPurchasedProps {
  onSuccess?: (reservation: Reservation) => void
  onError?: (errors: any) => void
  regItem: RegItem
}

const useMarkAsPurchased = ({
  regItem,
  onError,
  onSuccess,
}: UseMarkAsPurchasedProps) => {
  const queryClient = useQueryClient()

  const handleSuccess = (payload: CreateReservationResponse) => {
    const { reservation } = payload
    optimisticallySaveReservation(queryClient, reservation)
    invalidateRegItemReservationsQuery(queryClient, reservation.regItemId)
    invalidateRegItemsQuery({ queryClient, registryId: reservation.registryId })
    track.purchaseConfirmed({
      eventLocation: track.EventLocation.REG_ITEM_EDIT_SCREEN,
      eventType: track.EventType.MARK_AS_PURCHASED,
      regItemIds: [regItem.id],
      regItemQuantity: reservation.quantity,
    })
    onSuccess?.(reservation)
  }

  const { mutate: createRegItemReservationMutation, ...rest } =
    useCreateRegItemReservation({
      onError,
      onSuccess: handleSuccess,
    })

  const markAsPurchased = (payload: MarkAsPurchasedProps) => {
    const { createdBy, isSelfPurchase, ...reservationProps } = payload
    createRegItemReservationMutation({
      asOwner: true,
      reservation: {
        isPurchased: true,
        regItemId: regItem.id,
        regItem,
        registryId: regItem.registryId,
        ...reservationProps,
        preferences: {
          purchaseInfo: {
            createdBy,
            isSelfPurchase,
          },
        },
      },
    })
  }

  return {
    markAsPurchased,
    ...rest,
  }
}

export default useMarkAsPurchased
