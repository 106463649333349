import PropTypes from 'prop-types'
import { offerProps } from 'components/offer/offer'

export const regItemProps = {
  id: PropTypes.number.isRequired,
  url: PropTypes.string,
  imgUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  quantity: PropTypes.number,
  quantityNeeded: PropTypes.number,
  fundedAmount: PropTypes.number,
  goalAmount: PropTypes.number,
  amountContributed: PropTypes.number,
  isAvailableOnBabylist: PropTypes.bool,
  isBabylistProductDiscontinued: PropTypes.bool,
  isBonus: PropTypes.bool,
  isCashFund: PropTypes.bool,
  isElectronicGiftCard: PropTypes.bool,
  isExternalRegistry: PropTypes.bool,
  isFavor: PropTypes.bool,
  isGiftCard: PropTypes.bool,
  isReserved: PropTypes.bool,
  offers: PropTypes.arrayOf(PropTypes.shape(offerProps)),
  productId: PropTypes.number,
  addViaBabylist: PropTypes.string,
  isEditable: PropTypes.bool,
  viglinkEnabled: PropTypes.bool,
  registryId: PropTypes.number,
  subTitle: PropTypes.string,
  eligibleForDeferredPayment: PropTypes.bool,
}
