import { FC } from 'react'
import { absoluteUrl, findPath, storePath } from 'lib/urls'
import { TextLink } from 'baby-design'
import css from '../../global-nav.scss'
import ToggleNav from '../../components/ToggleNav'

interface MinimalSiteNavSectionProps {
  className?: string
  handleToggleNav: () => void
  trackClick: (eventCta: string) => void
}

const MinimalSiteNavSection: FC<MinimalSiteNavSectionProps> = ({
  trackClick,
  className,
  handleToggleNav,
}) => (
  <ul className={className}>
    <ToggleNav onClick={handleToggleNav} />
    <li className={css.fullNavOnly}>
      <TextLink
        href={absoluteUrl(storePath)}
        size="md"
        weight="regular"
        onClick={() => trackClick('Shop')}
      >
        Shop
      </TextLink>
    </li>

    <li className={css.fullNavOnly}>
      <TextLink
        href={absoluteUrl(findPath)}
        size="md"
        weight="regular"
        onClick={() => trackClick('Find Registry')}
      >
        Find Registry
      </TextLink>
    </li>
  </ul>
)

export default MinimalSiteNavSection
