import Modal from './modal'

export default class MultiStepModal extends Modal {
  componentDidUpdate(prevProps) {
    if (prevProps.activeStep === this.props.activeStep) return

    this.scrollToTop()
  }

  scrollToTop = () => {
    if (!this.modalContainer) return

    this.modalContainer.scrollTop = 0
  }
}
