import css from './page-animation.scss'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

export default ({ children }) => (
  <ReactCSSTransitionGroup
    transitionName={{
      enter: css.scaleEnter,
      enterActive: css.scaleEnterActive,
      leave: css.scaleLeave,
      leaveActive: css.scaleLeaveActive,
    }}
    transitionAppear={false}
    transitionEnterTimeout={400}
    transitionLeaveTimeout={1}
  >
    {children}
  </ReactCSSTransitionGroup>
)
