import PostCardRow from 'hello_baby/components/post-card-row'
import css from './SubNavGuides.scss'

interface SubNavGuidesProps {
  title: string
  guides: any[]
}

export default function SubNavGuides({ title, guides }: SubNavGuidesProps) {
  const posts = guides
  posts.length = 4

  return (
    <div id={css.subNavGuides}>
      <hr />
      <h3>Featured {title} Guides</h3>
      <PostCardRow columns={4} posts={posts} />
    </div>
  )
}
