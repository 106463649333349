export const formatRegistryCategory = (category) => ({
  id: category.id,
  title: category.title,
  position: category.position,
  isPrivate: category.isPrivate,
  isEditable: category.isEditable,
  description: category.description,
})
export const formatStore = (offer) => ({
  displayName: offer.storeDisplayName,
  name: offer.storeName,
})
export const formatStoreNameToIconName = (storeName) =>
  storeName.replace(/\s+/g, '').toLowerCase()
