import React, { ChangeEvent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Checkbox, Input } from 'components/forms'
import MailcheckedInput from 'components/mailchecked-input'
import { email as isEmail, numeric as isNumeric } from 'lib/form-validation'
import { InfoButton } from 'components/buttons'
import { TwoColumnResponsiveLayout } from 'components/presentation'
import { useGiftGiverCookie } from 'lib/cookies'
import { useForm } from 'react-hook-form'
import { isString } from 'lodash'
import { GiftGiverInfoFormProps } from 'registry/components/LegacyCrowdfundReserveForm/components/GiftGiverInfoForm/GiftGiverInfoForm.types'
import { MARKETING_EMAIL_CHECKBOX_LABEL } from '../../../../../cart/constants'
import RegItem from '../../../../../cart/components/reg-item'
import css from './GiftGiverInfoForm.styles.scss'

const GiftGiverInfoForm: React.FC<GiftGiverInfoFormProps> = ({
  onFormSubmitted,
  regItem,
  reservation,
}) => {
  const { name, email, subscribeToMarketingEmail } = useGiftGiverCookie()

  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: {
      name,
      email,
      subscribeToMarketingEmail,
      giftAmount: reservation?.giftAmount,
    },
  })

  const updateEmail = (
    eventOrValue: ChangeEvent<HTMLInputElement> | string
  ) => {
    const value = isString(eventOrValue)
      ? eventOrValue
      : eventOrValue.target.value
    setValue('email', value)
  }

  return (
    <TwoColumnResponsiveLayout>
      <RegItem className="mbl" regItem={regItem} />
      <form onSubmit={handleSubmit(onFormSubmitted)}>
        <p>Enter the amount you'd like to contribute and your contact info.</p>
        <div className="mbl">
          <Row>
            <Col xs={12}>
              <Input
                error={errors.name && errors.name.message}
                inputRef={register({
                  required: 'Please add your name',
                })}
                label="Name"
                name="name"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <MailcheckedInput
                mailcheckOnChange
                error={errors?.email?.message}
                inputRef={register({
                  required: 'Enter your email',
                  validate: isEmail,
                })}
                label="Email"
                mailcheckDelay={300}
                mailcheckOnBlur={false}
                name="email"
                placeholder="Your email address"
                onChange={updateEmail}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Input
                error={errors.giftAmount && errors.giftAmount.message}
                inputRef={register({
                  required: 'Enter the amount you will gift',
                  validate: isNumeric,
                })}
                label="Gift amount"
                name="giftAmount"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Checkbox
                defaultChecked={subscribeToMarketingEmail}
                inputRef={register()}
                label={MARKETING_EMAIL_CHECKBOX_LABEL}
                name="subscribeToMarketingEmail"
                type="checkbox"
                value="true"
              />
            </Col>
          </Row>
          <InfoButton
            pill
            className={css.GiftGiverInfoForm__SubmitButton}
            type="submit"
          >
            Next
          </InfoButton>
        </div>
      </form>
    </TwoColumnResponsiveLayout>
  )
}

export default GiftGiverInfoForm
