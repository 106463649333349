import { getQueryParam } from 'utils'

const ENTRY_POINT_QUERY_PARAM = 'entry_point'

const ShipmentsEntryPoint = {
  ACCOUNT_MENU: 'account_menu',
  GIFT_TRACKER: 'gift_tracker',
  MOBILE_NAVBAR: 'mobile_navbar',
  FEED_NOTICE_BUNDLE: 'feed_notice_bundle',
  FEED_NOTICE_SHIPMENT: 'feed_notice_shipment',
  ORDERS: 'orders',
}

const validateShipmentsEntryPoint = (entryPoint: string | null) =>
  entryPoint && Object.values(ShipmentsEntryPoint).includes(entryPoint)
    ? entryPoint
    : null

const entryPointQueryParam = () => getQueryParam(ENTRY_POINT_QUERY_PARAM)

export const shipmentsEntryPoint = () =>
  validateShipmentsEntryPoint(entryPointQueryParam())

export const withEntryPointAccountMenu = (path: string): string =>
  `${path}?${ENTRY_POINT_QUERY_PARAM}=${ShipmentsEntryPoint.ACCOUNT_MENU}`
