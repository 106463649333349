import { Component } from 'react'
import PropTypes from 'prop-types'
import ImageUploadPreviewer from 'shared/image-upload-previewer/image-upload-previewer'
import { Input } from 'components/forms'
import FavoriteToggle from 'shared/reg-item-form/components/favorite-toggle'
import { REG_ITEM_PLACEHOLDER_TEXT } from 'registry/constants'
import CategorySelector from './CategorySelector'
import css from './reg-item-form.scss'

export default class GiftCardFields extends Component {
  static propTypes = {
    categories: PropTypes.array,
    regItem: PropTypes.object,
    onRegItemChange: PropTypes.func.isRequired,
    onNewCategoryChange: PropTypes.func.isRequired,
  }

  state = {}

  onImageChange = ({ imgUrl }) => {
    this.props.onRegItemChange({ imgUrl })
  }

  onFavoriteChange = (isChecked) => {
    this.props.onRegItemChange({ isFavorite: isChecked })
  }

  handleChange = (e) => {
    this.props.onRegItemChange({ [e.target.name]: e.target.value })
  }

  render() {
    const regItemFields = this.props.regItem

    return (
      <>
        <div className={css.item}>
          <div className={css.imageContainer}>
            <ImageUploadPreviewer
              imgUrl={regItemFields.imgUrl}
              onChange={this.onImageChange}
            />
          </div>

          <div className={css.itemFields}>
            <Input
              disabled
              label="Title"
              name="title"
              value={regItemFields.title}
            />

            <CategorySelector
              categories={this.props.categories}
              name="categoryId"
              value={regItemFields.categoryId}
              onCategoryAdd={this.props.onNewCategoryChange}
              onCategoryChange={this.props.onRegItemChange}
            />

            <FavoriteToggle
              className={css.flexGrow}
              defaultOn={this.props.regItem.isFavorite}
              onClick={this.onFavoriteChange}
            />
          </div>
        </div>

        <div className="sm-hidden mtl" />

        <Input
          className={css.notes}
          label="Notes for friends and family"
          name="description"
          placeholder={REG_ITEM_PLACEHOLDER_TEXT}
          type="textarea"
          value={regItemFields.description || ''}
          onChange={this.handleChange}
        />
      </>
    )
  }
}
