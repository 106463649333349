import { SET_COLLECTIONS } from '../actions'

const collectionsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_COLLECTIONS:
      return { ...state, ...action.collections }
    default:
      return state
  }
}

export default collectionsReducer
