import React from 'react'

interface SimpleShipping1Props {
  className?: string
  filled?: boolean
}

const outlinedPath = (
  <path
    d="M12 12.4545L20.1 7.45455M12 12.4545L3.9 7.45455M12 12.4545V21.5455M3 10.1582V13.8418C3 14.828 3 15.321 3.14097 15.7645C3.26573 16.157 3.46996 16.5196 3.74093 16.8297C4.04713 17.1801 4.4688 17.4356 5.31215 17.9468L9.51215 20.4922C10.4162 21.0401 10.8682 21.3141 11.3513 21.4211C11.7786 21.5157 12.2214 21.5157 12.6487 21.4211C13.1318 21.3141 13.5838 21.0401 14.4878 20.4922L18.6878 17.9468C19.5312 17.4356 19.9529 17.1801 20.2591 16.8297C20.53 16.5196 20.7343 16.157 20.859 15.7645C21 15.321 21 14.828 21 13.8418V10.1582C21 9.17205 21 8.67898 20.859 8.23549C20.7343 7.84303 20.53 7.48045 20.2591 7.17034C19.9529 6.81992 19.5312 6.56436 18.6878 6.05324L14.4878 3.50779C13.5838 2.95987 13.1318 2.68592 12.6487 2.57893C12.2214 2.4843 11.7786 2.4843 11.3513 2.57893C10.8682 2.68592 10.4162 2.95987 9.51215 3.50779L5.31215 6.05324C4.4688 6.56436 4.04713 6.81992 3.74093 7.17034C3.46996 7.48045 3.26573 7.84303 3.14097 8.23549C3 8.67898 3 9.17205 3 10.1582Z"
    stroke="#252324"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5"
  />
)

const filledPath = (
  <path
    clipRule="evenodd"
    d="M12.656 21.3554C12.656 21.7084 12.656 21.8848 12.7527 22.0168C12.8307 22.1232 12.9772 22.2064 13.1085 22.219C13.2713 22.2345 13.4138 22.1497 13.6987 21.98C13.8614 21.8831 14.0448 21.7724 14.2534 21.646L19.2262 18.6322C20.2746 17.9967 20.6277 17.7712 20.8781 17.4846C21.1187 17.2092 21.3001 16.8873 21.4108 16.5388C21.5261 16.1761 21.536 15.7573 21.536 14.5313V10.1699C21.536 8.94393 21.5261 8.52513 21.4108 8.16243C21.3858 8.08371 21.2932 8.05258 21.2229 8.09596L13.0358 13.1498C12.8972 13.2353 12.8279 13.2781 12.7776 13.3363C12.7331 13.3878 12.6996 13.4479 12.6791 13.5128C12.656 13.5862 12.656 13.6676 12.656 13.8305V21.3554ZM11.3478 11.5858C11.5003 11.68 11.5766 11.7271 11.6583 11.7454C11.7305 11.7617 11.8055 11.7617 11.8777 11.7454C11.9594 11.7271 12.0357 11.68 12.1882 11.5858L19.2255 7.24182C19.462 7.09587 19.5802 7.02289 19.6403 6.93409C19.7539 6.76636 19.7529 6.53685 19.638 6.37005C19.5772 6.28174 19.4602 6.21084 19.2262 6.06904L14.2534 3.05521C13.129 2.3738 12.7389 2.15048 12.344 2.06302C11.9646 1.97899 11.5714 1.97899 11.192 2.06302C10.7971 2.15048 10.407 2.3738 9.28265 3.05521L4.30984 6.06904C4.07587 6.21084 3.95888 6.28174 3.89803 6.37005C3.78308 6.53685 3.78215 6.76635 3.89572 6.93409C3.95585 7.02289 4.07407 7.09587 4.31052 7.24182L11.3478 11.5858ZM2.31309 8.09596C2.24281 8.05258 2.15021 8.08371 2.12519 8.16243C2.00989 8.52513 2 8.94393 2 10.1699V14.5313C2 15.7573 2.00989 16.1761 2.12519 16.5388C2.23597 16.8873 2.41732 17.2092 2.65795 17.4846C2.90838 17.7712 3.26141 17.9967 4.30985 18.6322L9.28265 21.646C9.49126 21.7724 9.6746 21.8831 9.83738 21.98C10.1223 22.1497 10.2647 22.2345 10.4276 22.219C10.5589 22.2064 10.7053 22.1232 10.7833 22.0168C10.88 21.8848 10.88 21.7084 10.88 21.3554V13.8305C10.88 13.6676 10.88 13.5862 10.8569 13.5128C10.8364 13.4479 10.8029 13.3878 10.7584 13.3363C10.7081 13.2781 10.6388 13.2353 10.5002 13.1498L2.31309 8.09596Z"
    fill="#252324"
    fillRule="evenodd"
  />
)

const SimpleShipping1: React.FC<SimpleShipping1Props> = ({
  className,
  filled,
}: SimpleShipping1Props) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {filled ? filledPath : outlinedPath}
  </svg>
)

export default SimpleShipping1
