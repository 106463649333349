import React from 'react'

interface NoteProps {
  className?: string
}

const Note: React.FC<NoteProps> = ({ className }: NoteProps) => (
  <svg
    className={className}
    fill="none"
    height="16"
    viewBox="0 0 17 16"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Notes Icon</title>
    <path
      d="M14 6.8C14 5.11984 14 4.27976 13.673 3.63803C13.3854 3.07354 12.9265 2.6146 12.362 2.32698C11.7202 2 10.8802 2 9.2 2H6.8C5.11984 2 4.27976 2 3.63803 2.32698C3.07354 2.6146 2.6146 3.07354 2.32698 3.63803C2 4.27976 2 5.11984 2 6.8V13.6349C2 14.0413 2 14.2444 2.07464 14.3652C2.16582 14.5128 2.32697 14.6026 2.50044 14.6024C2.64244 14.6023 2.81519 14.4954 3.16068 14.2815V14.2815C3.78556 13.8947 4.09799 13.7012 4.42956 13.5572C4.83613 13.3805 5.2644 13.2586 5.70309 13.1949C6.06085 13.1429 6.42831 13.1429 7.16323 13.1429H9.2C10.8802 13.1429 11.7202 13.1429 12.362 12.8159C12.9265 12.5283 13.3854 12.0693 13.673 11.5048C14 10.8631 14 10.023 14 8.34286V6.8Z"
      stroke="#6B6669"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.11328 7H11.2493M5.11328 10H8.18127"
      stroke="#6B6669"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Note
