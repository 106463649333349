import React from 'react'

interface HeartProps {
  className?: string
  title?: string
}

const Heart: React.FC<HeartProps> = ({ className, title }: HeartProps) => (
  <svg
    className={className}
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title || 'Babylist Heart Icon'}</title>
    <path
      clipRule="evenodd"
      d="M8.4436 2.07455C7.75408 2.38686 7.30934 2.91313 7.00074 3.55605H6.99906C6.69033 2.91313 6.2456 2.38686 5.55607 2.07455C4.4543 1.57542 3.37831 1.64358 2.39346 2.338C1.42252 3.0227 1.05088 3.98878 1.19732 5.14775C1.38669 6.64616 2.22987 7.779 3.32584 8.76237C4.41473 9.73918 5.66796 10.4724 6.99958 11.0833C8.33119 10.4724 9.58508 9.73918 10.6737 8.76237C11.7699 7.779 12.613 6.64616 12.8024 5.14775C12.9488 3.98878 12.5772 3.0227 11.6062 2.338C11.0452 1.94238 10.4544 1.75 9.84637 1.75C9.38695 1.75 8.91772 1.85982 8.4436 2.07455Z"
      fill="#252324"
      fillRule="evenodd"
    />
  </svg>
)

export default Heart
