/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Toast from 'baby-design/components/Toast/Toast'
import { CheckCircle } from 'baby-design/icons'
import { CartQuantityUpdateSuccessToastProps } from './CartQuantityUpdateSuccessToast.types'
import css from './CartQuantityUpdateSuccessToast.styles.scss'

const CartQuantityUpdateSuccessToast: React.FC<
  CartQuantityUpdateSuccessToastProps
> = ({ isOpen, newQuantity }) => {
  const text =
    newQuantity === 0
      ? 'Item successfully removed from cart'
      : 'Cart successfully updated'
  return (
    <Toast
      imageProps={{
        image: (
          <CheckCircle
            filled
            className={css.CartQuantityUpdateSuccessToast__Image}
          />
        ),
      }}
      isOpen={isOpen}
      text={text}
      variant="basic"
    />
  )
}

export default CartQuantityUpdateSuccessToast
