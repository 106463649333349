/* eslint-disable */
import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bowserFlags } from 'lib/bowser'
import classnames from 'classnames'
import { ModalHeader } from 'react-bootstrap'
import { Drawer } from '../transitions'
import { Modal as ROModal } from 'react-overlays'

import css from './modal.scss'

const sizes = ['small', 'large', 'fullscreen']
const sizeProp = PropTypes.oneOf(sizes)
sizeProp.values = sizes

const backdrops = ['static', true, false]
const backdropProp = PropTypes.oneOf(backdrops)
backdropProp.values = backdrops

export const Backdrop = (props) => <div {...props} className="modal-backdrop" />

// the ModalTitle component from our current version of react-bootstrap (0.32.4)
// unfortunately renders an h4, which causes accessibility issues, this is a workaround
export const ModalTitle = ({ children, ...props }) => (
  <div {...props} className="modal-title">
    {children}
  </div>
)

ModalTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default class Modal extends PureComponent {
  static propTypes = {
    affixedFooter: PropTypes.bool,
    animation: PropTypes.bool,
    autoFocus: PropTypes.bool,
    backdrop: backdropProp,
    children: PropTypes.node,
    className: PropTypes.string,
    closeButton: PropTypes.bool,
    enforceFocus: PropTypes.bool,
    icon: PropTypes.object,
    keyboard: PropTypes.bool,
    mobileSize: PropTypes.string,
    onHide: PropTypes.func,
    size: sizeProp,
    show: PropTypes.bool,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    transition: PropTypes.func,
  }

  static defaultProps = {
    affixedFooter: false,
    closeButton: true,
    onHide: () => {},
    transition: null,
  }

  state = {
    animating: true,
    scrollY: null,
  }

  // This will usually be handled by the modal lifecycle methods
  // however there are a few cases where we use modals that do not
  // fire the lifecycle hooks. Run this clean up here to ensure mobile web
  // is in a good state when the modal is unmounted
  componentWillUnmount() {
    this.handleOnExit()
    clearTimeout(this.animationTimer)
    // fixes a react-overlays bug where the modal ALWAYS has aria-hidden=true
    $('div[role="dialog"]').attr('aria-hidden', true)
  }

  // transform animations break fixed css child elements so we
  // want to remove the classnames after the animation completes
  componentDidMount() {
    this.animationTimer = setTimeout(() => {
      this.setState({ animating: false })
    }, 500)
    // fixes a react-overlays bug where the modal ALWAYS has aria-hidden=true
    $('div[role="dialog"]').attr('aria-hidden', false)
  }

  // Scroll to the top of the modal upon open
  // Handles the case where we are opening a modal from another modal
  componentDidUpdate(prevProps) {
    if (this.props.title !== prevProps.title && this.modalContainer) {
      this.modalContainer.scrollTo(0, 0)
    }
  }

  render() {
    const {
      className,
      animation,
      autoFocus,
      backdrop,
      enforceFocus,
      keyboard,
      show,
      size,
      title,
      closeButton,
      subtitle,
      children,
      affixedFooter,
      transition,
    } = this.props
    const currentSize = this.currentSize()

    return (
      <ROModal
        autoFocus
        restoreFocus
        animation={animation}
        backdrop={backdrop}
        renderBackdrop={Backdrop}
        className={classnames(css.modal, className, {
          'modal-fullscreen': currentSize === 'fullscreen',
          'modal--affixed-footer': affixedFooter,
        })}
        enforceFocus={enforceFocus}
        keyboard={keyboard}
        onHide={this.handleOnHide}
        onEnter={this.handleOnEnter}
        onExit={this.handleOnExit}
        show={show}
        transition={transition}
      >
        <div
          className="react-modal"
          ref={(elem) => (this.modalContainer = elem)}
        >
          <div
            className={classnames(
              'modal-dialog',
              size === 'large' ? 'modal-lg' : null
            )}
          >
            <div
              className={classnames(
                'modal-content',
                this.state.animating && this.props.transition !== Drawer
                  ? 'animated nudgeInUp'
                  : null
              )}
            >
              {typeof title === 'string' && (
                <ModalHeader
                  closeButton={closeButton}
                  onHide={this.handleOnHide}
                  className={classnames({
                    'with-title': this.hasTitleContent(),
                  })}
                >
                  {this.renderIcon()}
                  <div className="modal-title h3">
                    {title}
                    {subtitle ? (
                      <p className="subtitle mts mbn h5">{subtitle}</p>
                    ) : null}
                  </div>
                </ModalHeader>
              )}

              {children}
            </div>
          </div>
        </div>
      </ROModal>
    )
  }

  renderIcon = () => {
    if (this.props.icon) {
      return <div className="modal-icon">{this.props.icon}</div>
    }
  }

  currentSize = () => {
    const { mobileSize, size } = this.props

    let currentSize
    if (bowserFlags.mobile && !mobileSize) {
      currentSize = 'fullscreen'
    } else if (bowserFlags.mobile && mobileSize) {
      currentSize = mobileSize
    } else {
      currentSize = size
    }
    return currentSize
  }

  handleOnEnter = () => {
    if (
      this.currentSize() === 'fullscreen' &&
      this.props.transition !== Drawer
    ) {
      this.onEnterFullScreen()
    }
  }

  handleOnExit = () => {
    if (this.currentSize() === 'fullscreen') {
      this.onExitFullscreen()
    }
  }

  handleOnHide = () => {
    this.handleOnExit()
    this.props.onHide()
  }

  onEnterFullScreen = () => {
    setTimeout(() => {
      this.setState({ scrollY: window.scrollY })
      if (document && document.body) {
        document.body.className += document.body.className.length
          ? ' modal-open-fullscreen'
          : 'modal-open-fullscreen'
      }
    }, 500)
  }

  onExitFullscreen = () => {
    if (document && document.body) {
      document.body.className = document.body.className.replace(
        / ?modal-open-fullscreen/,
        ''
      )
    }
    if (this.state.scrollY > 0) {
      window.scrollTo(window.scrollX, this.state.scrollY)
      this.setState({ scrollY: null })
    }
  }

  hasTitleContent = () => this.props.title && this.props.title !== ''
}
