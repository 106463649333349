import classNames from 'classnames'
import PropTypes from 'prop-types'
import Image from 'components/image'

import Pinnable from './pinnable'

const PinnableImage = (props) => (
  <Pinnable
    description={props.description || props.alt}
    media={props.media || props.src}
    pinContainerClassName={props.pinContainerClassName}
    url={props.url}
  >
    <MaybeLinkImage url={props.imageLink}>
      <Image
        className={classNames(props.imageClassName)}
        src={props.src}
        alt={props.alt}
        pinterest={true}
      />
    </MaybeLinkImage>
  </Pinnable>
)

const MaybeLinkImage = (props) =>
  props.url ? (
    <a href={props.url} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  ) : (
    props.children
  )

PinnableImage.propTypes = {
  alt: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageClassName: PropTypes.string,
  imageLink: PropTypes.string,
  media: PropTypes.string,
  pinContainerClassName: PropTypes.string,
  url: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
}

export default PinnableImage
