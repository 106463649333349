import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { NumberSelect } from 'components/forms'

const QuantityField = ({
  className,
  labelClassName,
  name,
  inputRef,
  quantityNeeded,
  purchaseQuantityLimit,
  showPurchaseQuantityLimit,
  ...rest
}) => (
  <Row className={className}>
    <Col xs={12}>
      <div className={labelClassName}>How many did you purchase?</div>
    </Col>
    <Col xs={12}>
      <NumberSelect
        help={
          showPurchaseQuantityLimit
            ? `Limit ${purchaseQuantityLimit} per customer`
            : `${quantityNeeded && `Still needs: ${quantityNeeded}`}`
        }
        size="small"
        min={1}
        max={
          showPurchaseQuantityLimit
            ? Math.min(purchaseQuantityLimit, quantityNeeded)
            : quantityNeeded
        }
        name={name}
        inputRef={inputRef}
        {...rest}
      />
    </Col>
  </Row>
)

QuantityField.defaultProps = {
  className: undefined,
  labelClassName: 'mbs mtn',
  name: undefined,
  inputRef: undefined,
}

QuantityField.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  name: PropTypes.string,
  inputRef: PropTypes.func,
  quantityNeeded: PropTypes.number.isRequired,
  purchaseQuantityLimit: PropTypes.number.isRequired,
  showPurchaseQuantityLimit: PropTypes.bool.isRequired,
}

export default QuantityField
