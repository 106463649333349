import React from 'react'

interface VenmoProps {
  className?: string
  filled?: boolean
}

const Venmo: React.FC<VenmoProps> = ({ className }: VenmoProps) => (
  <svg
    className={className}
    width="61"
    height="12"
    viewBox="0 0 61 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_13328_3384)">
      <path
        d="M9.83901 0.398438C10.2432 1.0658 10.4254 1.75319 10.4254 2.6215C10.4254 5.39097 8.06073 8.98871 6.14156 11.515H1.75804L0 1.0048L3.83825 0.640467L4.76774 8.11915C5.63625 6.70452 6.70799 4.48148 6.70799 2.96582C6.70799 2.1362 6.56586 1.57113 6.34368 1.10585L9.83901 0.398438Z"
        fill="#008CFF"
      />
      <path
        d="M14.8141 5.02566C15.5204 5.02566 17.2986 4.7026 17.2986 3.69216C17.2986 3.20697 16.9555 2.96494 16.5511 2.96494C15.8437 2.96494 14.9153 3.81312 14.8141 5.02566ZM14.7332 7.02683C14.7332 8.26062 15.4194 8.74468 16.329 8.74468C17.3197 8.74468 18.2681 8.50264 19.5009 7.87625L19.0366 11.0287C18.168 11.453 16.8143 11.7362 15.5004 11.7362C12.1675 11.7362 10.9746 9.71526 10.9746 7.18886C10.9746 3.91439 12.9149 0.4375 16.9153 0.4375C19.1177 0.4375 20.3493 1.67119 20.3493 3.38902C20.3495 6.1584 16.7944 7.0068 14.7332 7.02683Z"
        fill="#008CFF"
      />
      <path
        d="M31.4258 2.82463C31.4258 3.22879 31.3647 3.81503 31.3035 4.19815L30.1519 11.4748H26.4144L27.465 4.80442C27.485 4.6235 27.5462 4.25927 27.5462 4.05718C27.5462 3.57196 27.243 3.45092 26.8785 3.45092C26.3943 3.45092 25.909 3.67301 25.5858 3.83518L24.3942 11.4749H20.6357L22.3528 0.580502H25.6057L25.6469 1.45006C26.4144 0.944841 27.4249 0.398438 28.8586 0.398438C30.7583 0.39823 31.4258 1.36873 31.4258 2.82463Z"
        fill="#008CFF"
      />
      <path
        d="M42.5215 1.63011C43.592 0.862838 44.6027 0.4375 45.9962 0.4375C47.9152 0.4375 48.5828 1.408 48.5828 2.8639C48.5828 3.26809 48.5216 3.85433 48.4607 4.23745L47.3103 11.514H43.5718L44.6426 4.70272C44.6622 4.52064 44.7037 4.29854 44.7037 4.15766C44.7037 3.61135 44.4004 3.49018 44.0359 3.49018C43.5718 3.49018 43.1077 3.69227 42.7631 3.87444L41.5717 11.5142H37.8344L38.9048 4.70281C38.9245 4.52075 38.9649 4.29866 38.9649 4.15777C38.9649 3.61146 38.6613 3.4903 38.2982 3.4903C37.813 3.4903 37.3287 3.71239 37.0055 3.87456L35.8129 11.5143H32.0557L33.7727 0.619876H36.9858L37.0868 1.52938C37.8344 0.98432 38.844 0.437915 40.1976 0.437915C41.3696 0.4375 42.1368 0.942823 42.5215 1.63011Z"
        fill="#008CFF"
      />
      <path
        d="M56.0204 4.80374C56.0204 3.91437 55.7979 3.30802 55.1318 3.30802C53.6569 3.30802 53.3539 5.91512 53.3539 7.24882C53.3539 8.26061 53.637 8.88679 54.3031 8.88679C55.6972 8.88679 56.0204 6.13733 56.0204 4.80374ZM49.5557 7.08782C49.5557 3.65221 51.3737 0.4375 55.5563 0.4375C58.708 0.4375 59.8602 2.29747 59.8602 4.86475C59.8602 8.26049 58.0618 11.7771 53.7781 11.7771C50.6062 11.7771 49.5557 9.69504 49.5557 7.08782Z"
        fill="#008CFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_13328_3384">
        <rect
          width="60.0407"
          height="11.6667"
          fill="white"
          transform="translate(0 0.164062)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default Venmo
