/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types'
import AddOrBuy from '../../add-or-buy/add-or-buy'
import Card from 'components/card'
import { regItemProps } from '../reg-item-props'
import RegItemCardLayout from '../reg-item-card-layout'
import GroupGiftOfferPanel from './group-gift-offer-panel'
import { BestOfferLink } from '../best-offer-link/best-offer-link'

const ReservableGroupGiftRegItemCard = (props, context) => {
  const {
    regItem,
    regItem: { fundedAmount, isAvailableOnBabylist, offers },
  } = props
  const { registry, isMobile } = context
  const showPurchaseAction =
    !fundedAmount && !!offers[0] && isAvailableOnBabylist // unfunded and available for purchase
  const actions = (
    <>
      <AddOrBuy offers={offers} regItem={regItem} registry={registry} />
    </>
  )

  const Wrapper = ({ children }) => {
    if (isMobile) {
      return <Card>{children}</Card>
    }
    return children
  }

  return (
    <Wrapper>
      <RegItemCardLayout
        {...props}
        actions={actions}
        regItemLinkDecorator={(child) => (
          <BestOfferLink offers={offers}>{child}</BestOfferLink>
        )}
      />
    </Wrapper>
  )
}

ReservableGroupGiftRegItemCard.propTypes = {
  filters: PropTypes.object,
  regItem: PropTypes.shape(regItemProps).isRequired,
}

ReservableGroupGiftRegItemCard.defaultProps = {
  filters: {},
}

ReservableGroupGiftRegItemCard.contextTypes = {
  isMobile: PropTypes.bool,
  registry: PropTypes.object,
}

export default ReservableGroupGiftRegItemCard
