import React from 'react'

interface NotificationBellProps {
  className?: string
  fill?: string
}

const NotificationBell: React.FC<NotificationBellProps> = ({
  className,
  fill = 'black',
}) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Notification Bell Icon</title>
    <g id="icon_notification">
      <path
        clipRule="evenodd"
        d="M12.0002 1.25C12.4145 1.25 12.7502 1.58579 12.7502 2V3.28582C16.6785 3.66305 19.7502 6.97281 19.7502 11C19.7502 12.4361 20.2098 13.9545 20.7841 15.2802C21.2132 16.2708 21.0789 17.3744 20.5942 18.2232C20.1065 19.0772 19.2146 19.75 18.0777 19.75H15.5408L15.3442 20.3398C14.8644 21.7791 13.5174 22.75 12.0002 22.75C10.483 22.75 9.13605 21.7791 8.65627 20.3398L8.45967 19.75H5.92275C4.78583 19.75 3.89398 19.0772 3.40628 18.2232C2.92153 17.3744 2.78728 16.2708 3.21641 15.2802C3.79064 13.9545 4.25024 12.4361 4.25024 11C4.25024 6.97281 7.32194 3.66305 11.2502 3.28582V2C11.2502 1.58579 11.586 1.25 12.0002 1.25ZM9.01774 18.25C9.00617 18.2497 8.99458 18.2497 8.98295 18.25H5.92275C5.42604 18.25 4.98415 17.9614 4.70885 17.4794C4.4306 16.9921 4.3726 16.3848 4.59282 15.8764C5.20547 14.4621 5.75024 12.7273 5.75024 11C5.75024 7.54822 8.54846 4.75 12.0002 4.75C15.452 4.75 18.2502 7.54822 18.2502 11C18.2502 12.7273 18.795 14.4621 19.4077 15.8764C19.6279 16.3848 19.5699 16.9921 19.2916 17.4794C19.0163 17.9614 18.5744 18.25 18.0777 18.25H15.0175C15.0059 18.2497 14.9943 18.2497 14.9827 18.25H9.01774ZM10.0408 19.75L10.0793 19.8655C10.3549 20.6923 11.1287 21.25 12.0002 21.25C12.8718 21.25 13.6456 20.6923 13.9212 19.8655L13.9597 19.75H10.0408Z"
        fill={fill}
        fillRule="evenodd"
        id="Vector"
      />
    </g>
  </svg>
)

export default NotificationBell
