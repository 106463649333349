// @ts-nocheck
import Modal from 'components/modal/modal'
import { ModalBody } from 'react-bootstrap'
import { TwoColumnResponsiveLayout } from 'components/presentation'
import DidNotBuyCartForm from 'cart/components/did-not-buy-cart-form'
import RegItem from 'cart/components/reg-item'
import { Responses } from '../../../../global'

export interface UnreserveModalProps {
  onHide?: () => void
  onSubmitSuccess?: () => void
  registry: Responses.Registry
  reservation: Responses.Reservation
}

export const UnreserveModal = ({
  onHide,
  onSubmitSuccess,
  registry,
  reservation,
}: UnreserveModalProps) => {
  return (
    <Modal show title="Could you tell us why?" onHide={onHide}>
      <ModalBody>
        <TwoColumnResponsiveLayout>
          <div>
            <RegItem
              className="mbl"
              regItem={reservation.regItem}
              registry={registry}
            />
          </div>
          <div>
            <DidNotBuyCartForm
              registry={registry}
              reservation={reservation}
              onSubmitSuccess={onSubmitSuccess}
            />
          </div>
        </TwoColumnResponsiveLayout>
      </ModalBody>
    </Modal>
  )
}

export default UnreserveModal
