import PropTypes from 'prop-types'

const QuantityInformation = ({ quantity, quantityNeeded }) => {
  switch (quantity) {
    case undefined || null:
      return <span className="mtl reg-item-qty">Qty: No Limit</span>
    case 1:
      return null
    default:
      return (
        <div
          className="text-center pam text-bold"
          style={{ backgroundColor: '#eee', borderRadius: '0 0 8px 8px' }}
        >
          Needs: {quantityNeeded}
        </div>
      )
  }
}

QuantityInformation.propTypes = {
  quantity: PropTypes.number,
  quantityNeeded: PropTypes.number,
}

export default QuantityInformation
