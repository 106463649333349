import { useState } from 'react'
import PropTypes from 'prop-types'

/**
 * Dismissing utility
 * read on mount, otherwise use react state
 */
export const DismissUI = ({ children, dismissDays, dismissId }) => {
  const [isDismissed, setIsDismissed] = useState(false)

  const fullId = `dismiss-ui--${dismissId}`

  const setDismissedDate = () => {
    try {
      const now = new Date()
      localStorage.setItem(fullId, JSON.stringify(now.toLocaleDateString()))
    } catch (e) {
      // noop…we'll just hide the UI for the current page, and it will appear after refresh
    }
  }

  const getDismissedDate = () => {
    try {
      const item = JSON.parse(localStorage.getItem(fullId))

      if (!item) return null

      return new Date(item)
    } catch (e) {
      return null
    }
  }

  const onDismiss = () => {
    setDismissedDate()
    setIsDismissed(true)
  }

  /**
   * Lazily check for the stored dismissed date. It will be updated
   * with new date if user dismisses again.
   */
  const checkDismissedDate = () => {
    const dismissedDate = getDismissedDate()

    if (!dismissedDate) return isDismissed

    const today = new Date()

    return today.getDate() - dismissedDate.getDate() < dismissDays
  }

  return children(checkDismissedDate(), onDismiss)
}

DismissUI.propTypes = {
  children: PropTypes.node.isRequired,
  dismissDays: PropTypes.number.isRequired,
  dismissId: PropTypes.string.isRequired,
}
