import PropTypes from 'prop-types'
import { map } from 'lodash'
import classNames from 'classnames'
import Image from 'components/image'
import css from './thumbnail-selector.scss'

const ThumbnailSelector = ({
  value,
  thumbnails,
  onClick,
  className,
  name,
  hrefFor,
  titleFor,
}) => (
  <div aria-label={`${name} options`} role="radiogroup" className={className}>
    {map(thumbnails, (thumbnail) => (
      <Thumbnail
        key={thumbnail.url}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...thumbnail}
        active={value === thumbnail.value}
        href={hrefFor ? hrefFor(name, thumbnail.value) : null}
        title={titleFor ? titleFor(name, thumbnail.value) : null}
        onClick={onClick}
      />
    ))}
  </div>
)

ThumbnailSelector.propTypes = {
  thumbnails: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  onClick: PropTypes.func,
  hrefFor: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  titleFor: PropTypes.func,
}

ThumbnailSelector.defaultProps = {
  className: '',
  hrefFor: null,
  onClick: null,
  titleFor: null,
}

export const Thumbnail = ({
  value,
  active,
  url,
  onClick,
  href,
  disabled,
  className,
  activeClass,
  title,
}) => {
  const activeClassName = activeClass || css.active

  return (
    <ThumbnailComponent
      aria-checked={active}
      aria-label={value}
      className={classNames(
        css.thumbnail,
        {
          [css.disabled]: disabled,
          [activeClassName]: active,
        },
        className
      )}
      href={href}
      role="radio"
      tabIndex={active ? 0 : -1}
      title={title}
      onClick={(e) => {
        e.preventDefault()
        onClick(value)
      }}
    >
      <Image alt={value} className="img-responsive" src={url} pinterest />
    </ThumbnailComponent>
  )
}

const ThumbnailComponent = ({ className, children, ...props }) => {
  if (props.href)
    return (
      <a className={className} {...props}>
        {children}
      </a>
    )
  else
    return (
      <button
        className={classNames(className, 'btn-unstyled', 'btn-block')}
        {...props}
      >
        {children}
      </button>
    )
}

Thumbnail.propTypes = {
  value: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  href: PropTypes.string,
  title: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  activeClass: PropTypes.string,
}

Thumbnail.defaultProps = {
  active: false,
  disabled: false,
  className: '',
  activeClass: '',
  href: null,
  title: null,
}

export default ThumbnailSelector
