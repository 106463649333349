import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { isNaN } from 'lodash'
import { numberToCurrency } from 'lib/money'
import { submitInaccuracyReport } from 'registry/actions/reg-items-actions'
import {
  FormButtonGroup,
  RadioGroup,
  Radio,
  CurrencyInput,
} from 'components/forms'
import { offerProps } from 'components/offer/offer'
import { INACCURACY_REPORT_REASONS } from 'registry/constants'

export default class InaccuracyReportForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showPriceField: false,
      formTouched: false,
      correctPrice: props.price || null,
      reason: null,
    }
  }

  static propTypes = {
    price: PropTypes.number,
    offer: PropTypes.shape(offerProps),
    regItemId: PropTypes.number,
    onSubmit: PropTypes.func,
  }

  handleSubmit(e) {
    e.preventDefault()
    submitInaccuracyReport(
      this.state.reason,
      this.props.regItemId,
      this.props.offer.normalUrl,
      this.state.correctPrice
    )
    this.props.onSubmit()
  }

  handlePriceChange = (e) => {
    this.setState({
      correctPrice: e.target.value,
    })
  }

  isInvalidPrice = () => isNaN(new Number(this.state.correctPrice))

  render() {
    const { storeDisplayName, price } = this.props.offer

    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <RadioGroup>
          <Radio
            name="flag_offer"
            label={`The price at ${storeDisplayName || 'this store'} is not ${
              numberToCurrency(price) || 'correct'
            }`}
            onChange={() =>
              this.setState({
                showPriceField: true,
                formTouched: true,
                reason: INACCURACY_REPORT_REASONS.INCORRECT_PRICE,
              })
            }
          />

          {this.state.showPriceField && (
            <CurrencyInput
              autoFocus={true}
              name="price"
              error={this.isInvalidPrice() ? 'Invalid Price' : null}
              label="What is the correct price?"
              groupClassName="mtm mbl"
              groupStyle={{ marginLeft: 28 }}
              type="text"
              style={{ width: 150 }}
              value={price}
              onChange={this.handlePriceChange}
            />
          )}

          <Radio
            name="flag_offer"
            label={`The item at the link is not the same as the item on my registry.`}
            onChange={() =>
              this.setState({
                showPriceField: false,
                correctPrice: null,
                formTouched: true,
                reason: INACCURACY_REPORT_REASONS.INCORRECT_PRODUCT,
              })
            }
          />
        </RadioGroup>
        <FormButtonGroup
          onClickSecondary={this.props.onSubmit}
          primaryButtonDisabled={
            !this.state.formTouched || this.isInvalidPrice()
          }
          textPrimary="Send Feedback"
        />
      </form>
    )
  }
}
