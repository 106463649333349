import React from 'react'
import { ProductInfoImage } from 'src/routes/(registry)/list/[slug]/components/ProductInfoDrawer/components/Image'
import CurrencyInput from 'src/routes/(registry)/components/form/CurrencyInput'
import { ProductInfoDrawerFieldsProps } from 'src/routes/(registry)/list/[slug]/components/ProductInfoDrawer/components/ProductInfoDrawerFields/ProductInfoDrawerFields.types'
import { TextInput } from '../../../../../../../../../baby-design'
import css from './ProductInfoDrawerFields.style.scss'

const ProductInfoDrawerFields: React.FC<ProductInfoDrawerFieldsProps> = ({
  allowImageEdit = false,
  isPriceEditable = false,
  isTitleEditable = false,
  showPrice = false,
  regItemFields,
  onUpdateRegItemFields,
}) => (
  <div className={css.product_info_drawer__body}>
    <div className={css.product_info_drawer__img}>
      <ProductInfoImage
        allowEdit={allowImageEdit}
        regItem={regItemFields}
        updateRegItemFields={onUpdateRegItemFields}
      />
    </div>
    {showPrice && (
      <CurrencyInput
        className={css.product_info_drawer__price}
        inputProps={{
          disabled: !isPriceEditable,
          value: regItemFields.price,
          onChange: (e) => onUpdateRegItemFields({ price: e.target.value }),
        }}
        label="Price"
        size="md"
      />
    )}
    <TextInput
      className={css.product_info_drawer__title}
      inputProps={{
        disabled: !isTitleEditable,
        value: regItemFields.title,
        onChange: (e) => onUpdateRegItemFields({ title: e.target.value }),
      }}
      label="Item title"
      size="md"
    />
  </div>
)

export default ProductInfoDrawerFields
