import { ProductOffers } from 'hello_baby/components/Embeds/ProductCardSection/ProductCard'

export const minimumFormattedPriceFromOffers = (offers: ProductOffers[]) => {
  if (!offers || offers.length === 0) {
    return undefined
  }
  const pricesArray = offers.map((offer) =>
    typeof offer.price === 'string' ? parseFloat(offer.price) : offer.price
  )
  const minPrice = Math.min(...pricesArray)

  return minPrice.toLocaleString('us-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
  })
}

export const sectionAnchor = (text: string | undefined) => {
  if (text === undefined) {
    return ''
  }
  return text
    ?.trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/g, '')
    .replace(/\s/g, '-')
}
